<template>
    <div class="card">
        <div class="card-header">
            <p class="d-flex align-items-center justify-content-between w-100 m-0">
                <strong>Additional Speaker {{ index + 1 }}</strong><button class="btn btn-sm btn-danger ml-1" @click="removeSpeaker()"><span class="fa fa-trash"></span><span class="sr-only">&nbsp;Remove Speaker</span></button>
            </p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-md-6 col-lg-4 col-xl">
                    <label :for="`first_name_${index}`">
                        First Name
                        <span class="required">*</span>
                    </label>
                    <input
                        class="form-control"
                        type="text"
                        :id="`first_name_${index}`"
                        :name="`speaker[course][additional_speakers][${index}][user][first_name]`"
                        title="First Name"
                        v-model="speaker.user.first_name"
                        required
                        data-msg="Please enter the speaker's first name."
                    />
                </div>
                <div class="form-group col-md-6 col-lg-4 col-xl">
                    <label :for="`last_name_${index}`">
                        Last Name
                        <span class="required">*</span>
                    </label>
                    <input
                        class="form-control"
                        :id="`last_name_${index}`"
                        :name="`speaker[course][additional_speakers][${index}][user][last_name]`"
                        required
                        title="Last Name"
                        type="text"
                        v-model="speaker.user.last_name"
                        data-msg="Please enter the speaker's last name."
                    />
                </div>
                <div class="form-group col-md-6 col-lg-4 col-xl">
                    <label :for="`email_${index}`">
                        Email
                        <span class="required">*</span>
                    </label>
                    <input
                        class="form-control"
                        :id="`email_${index}`"
                        :name="`speaker[course][additional_speakers][${index}][user][email]`"
                        required
                        title="Email Address"
                        type="email"
                        v-model="speaker.user.email"
                        data-msg="Please enter the speaker's email."
                        data-rule-email="true"
                    />
                </div>
                <div class="form-group col-md-6 col-lg-4 col-xl">
                    <label :for="`phone_${index}`">
                        Cell Phone
                        <span class="required">*</span>
                    </label>
                    <input
                        class="form-control"
                        :id="`phone_${index}`"
                        :name="`speaker[course][additional_speakers][${index}][user][phone]`"
                        required
                        title="(XXX) XXX-XXXX"
                        placeholder="(XXX) XXX-XXXX"
                        type="tel"
                        v-mask="'(###) ###-####'"
                        v-model="speaker.user.phone"
                        data-msg="Please enter the speaker's cell phone number."
                    />
                </div>
                <div class="form-group col-md-6 col-lg-4 col-xl">
                    <label :for="`company_name_${index}`">Company Name</label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`speaker[course][additional_speakers][${index}][speaker_info][company_name]`"
                        :id="`company_name_${index}`"
                        title="Company Name"
                        v-model="speaker.speaker_info.company_name"
                    />
                </div>
                <div class="form-group col-md-6 col-lg-4 col-xl">
                    <label :for="`job_title_${index}`">Job Title <span class="required">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`speaker[course][additional_speakers][${index}][speaker_info][job_title]`"
                        :id="`job_title_${index}`"
                        title="Job Title"
                        required
                        data-msg="Please enter the speaker's job title."
                        v-model="speaker.speaker_info.job_title"
                    />
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-6 col-xl">
                    <label :for="`credentials_${index}`">Credentials</label>
                    <input
                        class="form-control"
                        :id="`credentials_${index}`"
                        :name="`speaker[course][additional_speakers][${index}][speaker_info][credentials]`"
                        title="Credentials"
                        type="text"
                        v-model="speaker.speaker_info.credentials"
                    />
                </div>
                <div class="form-group col-md-6 col-xl">
                    <label for="date_of_birth">Date Of Birth</label>
                    <input
                        class="form-control"
                        id="date_of_birth"
                        :name="`speaker[course][additional_speakers][${index}][user][date_of_birth]`"
                        title="Date of Birth"
                        type="date"
                        v-model="speaker.user.date_of_birth"
                    />
                </div>
                <div class="form-group col-xl mb-0">
                    <label :for="`biography_${index}`">
                        Speaker Biography
                        <span class="required">*</span>
                    </label>
                    <textarea
                        class="form-control"
                        :id="`biography_${index}`"
                        :name="`speaker[course][additional_speakers][${index}][speaker_info][biography]`"
                        required
                        rows="4"
                        title="Speaker Biography"
                        data-msg="Please enter the speaker's biography."
                        v-model="speaker.speaker_info.biography"
                        maxlength="5000"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["speaker", "index"],
        methods: {
            removeSpeaker() {
                this.$emit('remove-speaker', {event: event, index: this.index})
            }
        }
    };
</script>
