<template>
    <vpanel-modal
        modal-id="driver-request-details-modal"
        header-icon="car"
        modal-title="Ride Request Details"
        modal-size="lg"
    >
        <div class="modal-body">
            <div class="vp-status-bar-list">
                <p>
                    Status: <span>{{ selected_request.status }}</span>
                </p>
                <p>
                    Created: <span>{{ createdAt }}</span>
                </p>
                <p>
                    Updated: <span>{{ updatedAt }}</span>
                </p>
                <p>
                    Confirmation:
                    <span
                        :class="[
                            emailSent == 'Sent' ? 'text-success' : 'text-danger'
                        ]"
                        >{{ emailSent }}</span
                    >
                </p>
            </div>
            <div class="card border shadow-none">
                <div class="card-body p-2">
                    <p class="h5 m-0">{{ selected_request.company_name }}</p>
                    <div class="vp-status-bar-list">
                        <p>
                            Attendee:
                            <span>{{ selected_request.attendee_name }}</span>
                        </p>
                        <p>
                            Phone: <span>{{ selected_request.phone }}</span>
                        </p>
                    </div>
                    <p class="lh-sm" v-html="requestText"></p>
                    <p class="m-0 lh-sm" v-html="deadlineText"></p>
                </div>
            </div>
            <form
                data-js-id="update-request-form"
                novalidate
                @submit.prevent="updateRequest()"
            >
                <input type="hidden" name="id" :value="selected_request.id" />
                <div
                    class="alert alert-danger"
                    v-if="formHasError"
                    v-html="formErrorMessage"
                ></div>
                <div class="d-flex mx-n2">
                    <div class="d-flex flex-column px-2 w-50">
                        <div class="form-group" v-if="isDriverAdmin">
                            <label for="assign_driver">Assign Driver</label>
                            <select
                                class="form-control"
                                name="assigned_driver_id"
                                id="assign_driver"
                                title="Assign Driver"
                                v-model="selected_request.assigned_driver_id"
                            >
                                <option :value="null">Unassigned</option>
                                <option
                                    :value="driver.id"
                                    v-for="driver in drivers"
                                    :key="`driver_${driver.id}`"
                                    :data-driver-name="driver.name"
                                    >{{ driver.name }}</option
                                >
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="status">Status</label>
                            <select
                                class="form-control"
                                name="status"
                                id="status"
                                title="Status"
                                required
                                data-msg="Please supply the status of this request"
                                v-model="selected_request.status"
                            >
                                <option value="Pending">Pending</option>
                                <option value="Complete">Complete</option>
                                <option value="Declined">Declined</option>
                            </select>
                        </div>
                    </div>
                    <div class="d-flex flex-column px-2 w-50">
                        <div class="form-group">
                            <label for="comments">Comments</label>
                            <textarea
                                class="form-control"
                                name="comments"
                                id="comments"
                                title="Comments"
                                v-model="selected_request.comments"
                            ></textarea>
                        </div>
                        <button class="btn btn-primary" type="submit">
                            Update Request
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </vpanel-modal>
</template>

<script>
export default {
    props: ["drivers", "requestData", "isDriverAdmin"],
    data() {
        return {
            selected_request: this.requestData,
            formHasError: false,
            formErrorMessage: ""
        };
    },
    computed: {
        form() {
            return document.querySelector('[data-js-id="update-request-form"]');
        },
        createdAt() {
            return moment(
                this.selected_request.created_at,
                "YYYY-MM-DD HH:mm:ss"
            ).format("MM/DD/YYYY h:mm a");
        },
        updatedAt() {
            return moment(
                this.selected_request.updated_at,
                "YYYY-MM-DD HH:mm:ss"
            ).format("MM/DD/YYYY h:mm a");
        },
        emailSent() {
            return this.selected_request.confirmation_message_sent
                ? "Sent"
                : "Not Sent";
        },
        requestText() {
            return `<strong>${
                this.selected_request.attendee_name
            }</strong> needs to get <strong>${
                this.selected_request.number_of_riders
            } rider${
                this.selected_request.number_of_riders > 1 ? "s" : ""
            }</strong> from <strong>${
                this.selected_request.origin
            }</strong> to <strong>${
                this.selected_request.destination
            }</strong>!`;
        },
        readablePickupTime() {
            return moment(
                this.selected_request.requested_pick_up_datetime,
                "MM/DD/YYYY H:mm a"
            ).format("h:mm a [on] dddd [the] do");
        },
        deadlineText() {
            return `They would like to be picked up by <strong>${this.requestData.requested_pick_up_date} at ${this.requestData.requested_pick_up_time_h}!`;
        }
    },
    watch: {
        requestData(val, oldVal) {
            this.selected_request = val;
        }
    },
    methods: {
        updateRequest() {
            const vm = this;
            const button = event.target.querySelector('button[type="submit"]');

            this.formHasError = false;
            this.formErrorMessage = "";
            let originalButtonText = button.innerHTML;

            $(this.form).validate();

            if ($(this.form).valid()) {
                button.disabled = true;
                button.innerHTML = "Processing...";

                let formData = new FormData(this.form);

                axios
                    .post(
                        "/vpanel/modules/driver-portal/update-request",
                        formData
                    )
                    .then(res => {
                        window.location.reload();
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == "422") {
                                const errors = error.response.data.errors;
                                const errorMessage =
                                    error.response.data.message;

                                vm.formHasError = true;

                                let errorsUl = document.createElement("ul");
                                errorsUl.classList.add("list-unstyled");

                                for (error in errors) {
                                    errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                }

                                vm.formErrorMessage =
                                    errorMessage + errorsUl.outerHTML;
                            } else {
                                openApplicationErrorModal(error, "");
                                console.error(error);
                            }
                        }

                        button.disabled = false;
                        button.innerHTML = originalButtonText;

                        console.error(error);
                    });
            }
        }
    }
};
</script>

<style></style>
