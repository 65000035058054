<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtPortalCompanyOptions } from "./options/dtPortalCompanyOptions";

export default {
    mixins: [dataTableMixin],
    data() {
        return {
            moduleDatatableOptions: dtPortalCompanyOptions,
        }
    },
    props: [
        'selectedCompany'
    ],
    mounted() {
        // Add event listeners for buttons
        let dataTable = this.$el;

        const vm = this;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id = elementClicked.dataset.id;

            switch (elementClicked.dataset.jsId) {
                case "portal-company-modify-button":
                    // Show modal
                    vm.$parent.openCompanyModal();

                    axios.get(`/vpanel/modules/portal/company/show/${id}`)
                        .then(res => {
                            try {
                                let company = res.data.company;

                                for (const prop in company) {
                                    if (company.hasOwnProperty(prop)) {
                                        const value = company[prop];

                                        vm.selectedCompany[prop] = value;
                                    }
                                }

                                vm.$parent.showModalForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });

                    break;
            }
        });
    }
}
</script>