<template>
    <div>
		<div class="row">
			<div class="form-group col-md">
				<label for="name">Name<span class="required">*</span></label>
				<input
					class="form-control"
					type="text"
					name="name"
					id="name"
					title="Please enter a name"
					required
					data-msg="Please provide a name"
					v-model="selectedTag.name"
				/>
			</div>
		</div>
        <div class="row">
            <div class="col-md form-group has-label">
				<p class="m-0 lh-xs" v-if="currentAgendaIds.length">
					<small>
						<strong>Selected Agenda<template v-if="currentAgendaIds.length > 1">s</template>:
                        </strong>
						<template v-for="(agendaId, index) in currentAgendaIds">
							{{ selectedAgendas(agendaId, index, currentAgendaIds) }}
						</template>
					</small>
				</p>
				<div class="d-flex justify-content-between align-items-center">
					<label for="agendas">Agenda(s)</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Use Ctrl + Click to select multiple agendas"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<select
					multiple
					class="form-control flex-grow-1"
					name="agenda[]"
					id="agendas"
					title="Select an agenda"
                    v-model="currentTag.agendaIds"
				>
					<option
						v-for="(agenda, index) in agendasByTitle"
						:key="index"
						:value="agenda.id"
					>{{ agenda.title }}</option>
				</select>
			</div>
			<div class="col-md">
				<div class="form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3 required">Type<span class="required">*</span></p>
					</div>
					<fieldset>
						<legend class="label sr-only">Type</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(type, index) in types" :key="index"
						>
							<label class="form-check-label mr-1" :for="`type_${index}`">
								<input
									class="form-check-input"
									type="radio"
									name="tag_type"
									title="Select an option"
									:value="`${type.toLowerCase()}`"
									:id="`type_${index}`"
									required
									data-msg="Please Select an Option"
									v-model="selectedTag.tag_type"
								/>
								<span class="form-check-sign"></span>
								{{ type }}
							</label>
						</div>
					</fieldset>
				</div>
			</div>
        </div>
	</div>
</template>

<script>
	export default {
		name: "AgendaTagsAddModifyForm",
        props: ["selectedTag", "agendas"],
        data() {
            return{
                currentTag: this.selectedTag
            }
        },
        computed: {
			agendasByTitle() {
				return this.agendas.sort((a, b) => a.title.localeCompare(b.title));
			},
			currentAgendaIds() {
				return this.selectedTag.agendaIds;
			},
			types() {
				return new Array("Category", "Track");
            }
		},
		methods: {
			agendaTitleFromId(value) {
				let agendas = this.agendas;

				for (i = 0; i < agendas.length; ++i) {
					if (agendas[i].id == value) {
						return agendas[i].title;
					}
				}
			},
			selectedAgendas(item, index, parent) {
				return this.agendaTitleFromId(item) + (index !== parent.length - 1 ? ', ' : '');
			},
		}
	};
</script>