<template>
  <div class="d-flex justify-content-center">
      <div class="d-flex align-items-center alert alert-success">
          <div class="d-flex border-right border-success p-1">
              <span class="text-success fas fa-check-circle fa-5x"></span>
          </div>
          <div class="p-1">
              <h3>Payment posted successfully!</h3>
              <p class="m-0">
                  Your order confirmation will arrive via email shortly.
              </p>
          </div>
      </div>
  </div>
</template>

<script>
export default {
}
</script>