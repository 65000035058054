<template>
    <form data-js-id="package-selection-form">
        <div class="card my-2">
            <div class="card-header">
                <p class="card-title font-weight-bold m-0">Booth Order Form</p>
            </div>
            <div class="card-body">
                <div class="form-group m-0">
                    <fieldset>
                        <legend class="sr-only">Booth Order Form?</legend>
                        <div class="my-n1">
                            <div
                                class="my-1"
                                v-for="(category, catIndex) in appData.categories_products_options"
                                :key="catIndex"
                            >
                                <div class="card my-2">
                                    <div class="card-header">
                                        <p class="card-title font-weight-bold m-0">{{ category.name }}: {{ category.description}} </p>
                                    </div>
                                    <div class="card-body">
                                        <div class="row my-1">
                                            <strong class="col-md-6">Name:</strong>
                                            <strong class="col-md">Cost:</strong>
                                            <strong class="col-md">Options:</strong>
                                            <strong class="col-md">Qty:</strong>
                                        </div>
                                        <div
                                            class="row my-1 align-items-center"
                                            v-for="(product, prodIndex) in category.products"
                                            :key="prodIndex"
                                        >
                                            <div class="form-group mb-0 col-md-6 flex-shrink-0">{{product.name}}<div v-if="product.description != null && product.description != ''">: {{ product.description }}</div></div>
                                            <div class="form-group mb-0 col-md">
                                                ${{ product.cost }}
                                            </div>

                                            <div
                                                class="form-group mb-0 col-md"
                                                v-if="product.product_options.length == 0"
                                            ></div>

                                            <div
                                                class="form-group mb-0 col-md d-flex align-items-center"
                                                v-for="(productOption, prodOptIndex) in product.product_options"
                                                :key="prodOptIndex"
                                            >
                                                <label :for="`productOption_${productOption.id}`" class="flex-shrink-0 mr-2 mb-0">{{ productOption.name }}:</label>
                                                <select
                                                    class="form-control"
                                                    v-model="appData.selected_products[catIndex].products[prodIndex].product_options[prodOptIndex].value"
                                                    :required="parseInt(appData.selected_products[catIndex].products[prodIndex].quantity) > 0"
                                                    :name="`productSelect_${catIndex}_${prodIndex}_${prodOptIndex}`"
                                                >
                                                    <option
                                                        v-for="option in productOption.product_options"
                                                        class="mr-2 mb-0"
                                                        :key="option.name"
                                                        :value="option.name"
                                                        :title="`${ productOption.name}`"
                                                    >{{option.name}}</option>
                                                </select>
                                            </div>

                                            <div class="form-group mb-0 col-md" v-if="product.maximum_quantity === 1">
                                                <div class="custom-inline-radio-buttons">
                                                    <fieldset>
                                                        <legend class="sr-only">Include in your booth options?</legend>
                                                        <label class="custom-control custom-radio custom-control-inline mb-0">
                                                            <input
                                                                class="custom-control-input"
                                                                type="radio"
                                                                :name="`productQuantity_${product.id}`"
                                                                required
                                                                data-msg="Please select yes or no"
                                                                title="Include in your booth options?"
                                                                value="0"
                                                                checked
                                                                @change="selectOptionsAndUpdateTerms()"
                                                                v-model.number="appData.selected_products[catIndex].products[prodIndex].quantity"
                                                            />
                                                            <span class="custom-control-label">No</span>
                                                        </label>
                                                        <label class="custom-control custom-radio custom-control-inline">
                                                            <input
                                                                class="custom-control-input"
                                                                type="radio"
                                                                :name="`productQuantity_${product.id}`"
                                                                required
                                                                data-msg="Please select yes or no"
                                                                title="Include in your booth options?"
                                                                value="1"
                                                                @change="selectOptionsAndUpdateTerms()"
                                                                v-model.number="appData.selected_products[catIndex].products[prodIndex].quantity"
                                                            />
                                                            <span class="custom-control-label">Yes</span>
                                                        </label>
                                                    </fieldset>
                                                </div>
                                            </div>

                                            <div class="form-group mb-0 col-md d-flex align-items-center" v-else>
                                                <label :for="`productQuantity_${product.id}`" class="flex-shrink-0 mr-2 mb-0 sr-only">Quantity:</label>
                                                <input
                                                    class="form-control"
                                                    type="number"
                                                    :name="`productQuantity_${product.id}`"
                                                    title="Quantity"
                                                    :min="quantityRequired(catIndex, prodIndex) ? 1 : 0"
                                                    v-model.number="appData.selected_products[catIndex].products[prodIndex].quantity"
                                                    @change="selectOptionsAndUpdateTerms()"
                                                    :required="quantityRequired(catIndex, prodIndex)"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>

        <vendor-registration-subtotal></vendor-registration-subtotal>

        <div class="d-flex justify-content-end m-n1">
            <button class="btn btn-outline-primary m-1" @click.prevent="moveToLastStep()">Go Back &amp; Edit</button>
            <button class="btn btn-primary-green m-1" @click.prevent="moveToNextStep()">Continue</button>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData
        };
    },
    computed: {
        form() {
            return document.querySelector(
                '[data-js-id="package-selection-form"]'
            );
        }
    },
    methods: {
        quantityRequired(catIndex, prodIndex) {
            return [undefined, ''].every(failCondition => this.appData.selected_products[catIndex].products[prodIndex]?.product_options?.[0]?.value != failCondition)
        },
        selectOptionsAndUpdateTerms() {
            this.appData.updateBoothSubtotal();
        },
        moveToNextStep() {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            try{
                $(this.form).validate();
            } catch(e) {
                console.error(e);
            }

            if ($(this.form).valid()) {
                this.appData.hideAllSections();
                this.appData.sections.show_registration_confirmation = 1;
                this.appData.current_step = 6;
            }
        },
        moveToLastStep() {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            this.appData.hideAllSections();
            this.appData.sections.show_add_on_form = 1;
            this.appData.current_step = 4;
        }
    }
};
</script>