<template>
    <vue-modal :visible="showWarning" >
        <template v-slot:header>
            Warning!
        </template>
        <div class="modal-body">
            <div class="alert alert-danger p-1">
                <p class="text-center">Your session has expired. The page will be refreshed in</p>
                <p class="h2 m-0 font-weight-bold text-center lh-xs">{{ timeRemaining }}</p>
            </div>
        </div>
    </vue-modal>
</template>
<script>
export default {
    props: ["sessionTimeout"],
    name: 'SessionTimeout',
    data() {
        return {
            inactivityThreshold: this.sessionTimeout, // in minutes
            timeGiven: 1, // in minutes
            timeRemaining: '',
            countdownTime: null,
            showWarning: false
        }
    },
    methods: {
        startCounter() {
            const vm = this;
            setInterval(() => {
                let countdownTo = vm.countdownTime;

                if (!countdownTo) {
                    countdownTo = new Date().getTime() + (vm.timeGiven * 60000);
                    vm.countdownTime = countdownTo;
                }

                let now      = new Date().getTime();
                let distance = countdownTo - now;
                let minutes  = Math.floor(distance / 60000);
                let seconds  = Math.floor((distance - (minutes * 60000)) / 1000)
                seconds      = seconds < 10 ? '0' + seconds : seconds;

                if (distance > 0) {
                    this.timeRemaining = minutes + ':' + seconds;
                } else {
                    location.reload();
                }
            }, 1000);
        },
        startSessionCheck() {
            // Get milliseconds until warning display
            let minutesToWarning = (this.inactivityThreshold) * 60000;
            const vm = this;

            return setTimeout(() => {
                vm.startCounter();
                vm.showWarning = true;
            }, minutesToWarning)
        },
    },
    mounted() {
        this.startSessionCheck();
    },
    destroyed() {
        this.startSessionCheck();
    },
};
</script>
