<template>
	<form class="modal-body" id="speaker-bio-password-form" novalidate>
		<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>

		<div class="form-group">
			<label for="speaker_bio">Speaker Bio</label>
			<textarea
				class="form-control"
				type="text"
				name="speaker_bio"
				id="speaker_bio"
				title="Speaker Bio"
				required
				data-msg="Please enter a bio"
				v-model="speakerBio"
			></textarea>
		</div>

		<div class="d-flex justify-content-end m-n1">
			<button class="btn btn-outline-primary m-1" @click.prevent="closeModal()">Cancel</button>
			<button
				class="btn btn-primary m-1"
				data-js-id="bio-modal-save-change-button"
				:disabled="formProcessing"
				@click.prevent="updateBioChanges()"
			>Save My Changes</button>
		</div>
	</form>
</template>

<script>
	import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
		props: ["user", "closeModal"],
		data() {
			return {
				currentUser: this.user,
				showErrorDiv: false,
				errorMessage: "",
				formProcessing: false,
				saveChangesButton: null,
				formData: {},
				bioContent: ""
			};
		},
		computed: {
			speakerBio() {
				return this.currentUser.speaker && this.currentUser.speaker.biography ? this.currentUser.speaker.biography : '';
			}
		},
		methods: {
			updateBioChanges() {
				const vm = this;
				const thisEvent = event;

				this.bioContent = CKEDITOR.instances["speaker_bio"].getData();
				this.showErrorDiv = false;
				this.errorMessage = "";

				thisEvent.target.disabled = true;
				thisEvent.target.innerHTML = "Processing...";

				axios
					.post("/portal/dashboard/speaker/update-bio-content", {
						bioContent: this.bioContent
					})
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						logApplicationError(error);
						console.error(error);

						thisEvent.target.disabled = false;
						thisEvent.target.innerHTML = "Save My Changes";

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								vm.showErrorDiv = true;
								vm.errorMessage = errorsUl.outerHTML;
							} else {
								vm.showErrorDiv = true;
								vm.errorMessage =
									'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
							}
						}

						console.error(error);
					});
			}
		},
		mounted() {
			let ckeditorInstance = CKEDITOR.replace("speaker_bio", {
				removePlugins: "maximize,resize"
			});
		}
	};
</script>