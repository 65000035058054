<template>
	<div class="d-flex flex-column p-4 flex-grow-1">
		<div class="row mb-2">
      <div class="col">
        <h3 class="mb-1">{{ selectedConference.year }} Conference Management Options</h3>
      </div>
      <div class="col d-flex justify-content-end align-items-center">
        <div class="mr-2">
            <div class="form-group mb-0">
                <select
                    class="form-control"
                    name="change_year"
                    id="change_year"
                    title="Change Year"
                    v-model="currentConference.uuid"
                    @change="changeSelectedYear()"
                    >
                    <option :value="conference.uuid" v-for="(conference, index) in conferences" :key="`con_${index}`">{{ conference.year }}</option>
                </select>
            </div>
        </div>

        <button class="btn btn-secondary mr-1" v-if="! isActiveConference" @click.prevent="showMakeActiveConfirmModal()">Make {{ selectedConference.year }} Active</button>
        <button class="btn btn-primary mr-1" @click="openNewYearModal()">Add New Year</button>
        <button class="btn btn-outline-primary" type="button" @click.prevent="publishSpeakerEvaluations()">Publish Speaker Evaluations</button>
      </div>
		</div>

		<cm-form :current-conference="selectedConference" :sponsorship-levels="selectedConference.sponsorshipLevels"></cm-form>

		<vpanel-modal
			:modal-id="addYearModalId"
			header-icon="calendar"
			modal-title="Add A New Conference Year"
		>
			<div class="modal-body">
				<cm-add-year-form :sponsorship-levels="selectedConference.sponsorshipLevels" :available-years="availableYears" :modal-id="addYearModalId"></cm-add-year-form>
			</div>
		</vpanel-modal>

        <vpanel-modal
            modal-id="confirm-make-active"
            header-icon="info"
            modal-title="Confirm Make Conference Active"
            modal-size="lg"
        >
            <div class="modal-body">
                <div class="d-flex flex-column align-items-center">
                    <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
                    <p class="h4 font-weight-bold">Are you sure you want to make {{ selectedConference.year }} active?</p>

                    <div class="d-flex justify-content-center mt-2">
                        <button type="button" class="btn btn-secondary" @click.prevent="closeActiveModal()">Cancel</button>
                        <button type="button" class="btn btn-primary ml-2" @click.prevent="confirmSetConferenceActive()">Yes, make {{ selectedConference.year }} active</button>
                    </div>
                </div>
            </div>
        </vpanel-modal>
	</div>
</template>

<script>
	export default {
		props: [
			"conferences",
			"currentConference",
			"sponsorshipLevels",
            "availableYears",
            "isActiveConference"
		],
		data() {
			return {
                addYearModalId: "add-year-modal-id",
                confirmModalId: "confirm-make-active",
                selectedConference: this.currentConference
			};
		},
		computed: {
			addYearModal() {
				return document.querySelector(
					`[data-js-id="${this.addYearModalId}"]`
				);
            },
            confirmModal() {
                return document.querySelector(
					`[data-js-id="${this.confirmModalId}"]`
				);
            }
		},
		methods: {
			openNewYearModal() {
                $(this.addYearModal).modal("show");
            },
            changeSelectedYear() {
                // Need to change selected year to the year that gets ... selected
                let newYearUuuid = event.target.value;

                for (let index = 0; index < this.conferences.length; index++) {
                    const conference = this.conferences[index];
                    if (conference.uuid == newYearUuuid) {
                        window.location.href = `/vpanel/modules/conference/${conference.uuid}`;
                    }
                }
            },
            showMakeActiveConfirmModal() {
                $(this.confirmModal).modal('show');
            },
            closeActiveModal() {
                $(this.confirmModal).modal('hide');
            },
            confirmSetConferenceActive() {
                let vm = this;
                let button = event.target;

                button.disabled = true;
                button.innerHTML = 'Processing...';

                axios.post(`/vpanel/modules/conference/make-active/${this.selectedConference.uuid}`)
                    .then(res => {
                        window.location.reload();
                    })
                    .catch(error => {
                        openApplicationErrorModal(error, '');
                        console.error(error);
                    })
            },
            publishSpeakerEvaluations() {
                const vm = this;
                const button = event.target;

                let originalButtonText = event.target.innerHTML;
                button.disabled = true;
                button.innerHTML = 'Processing...';

                axios.post('/vpanel/modules/conference/publish-evaluations')
                    .then(res => {
                    let data = res.data;
                    button.disabled = false;
                    button.innerHTML = originalButtonText;
                    showNotification(data.message, data.result);
                    })
                    .catch(error => {
                    openApplicationErrorModal(error, "");
                    console.error(error);

                    button.disabled = false;
                    button.innerHTML = originalButtonText;
                    });
            }
		},
		errorCaptured(err, vm, info) {
            openApplicationErrorModal(err);

			console.error(err);
		}
	};
</script>