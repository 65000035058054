<template>
	<div>
		<div class="row" v-if="formHasError">
			<div class="col-md">
				<div class="alert alert-danger">{{ formErrorMessge }}</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="first_name">First Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee first name"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="first_name"
					id="first_name"
					title="Enter Attendees First name"
					required
					data-msg="Please provide a firstname"
					v-model="currentAttendee.first_name"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="last_name">Last Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee last name"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="last_name"
					id="last_name"
					title="Enter Attendees last name"
					required
					data-msg="Please provide a last name"
					v-model="currentAttendee.last_name"
				/>
			</div>
            <div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="title">Title</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee title"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="title"
					id="title"
					title="Enter Attendees title"
					v-model="currentAttendee.reservation.title"
				/>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="username">Username</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee username"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="username"
					id="username"
					title="Enter Attendees username"
					required
					data-msg="Please provide a username"
					v-model="currentAttendee.username"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="email">Email</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee Email"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="email"
					id="email"
					title="Enter Attendees Email"
					required
					data-msg="Please provide a Email"
					data-rule-email="true"
					data-msg-email="Please provide a valid email address"
					v-model="currentAttendee.email"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="phone">Phone Number</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee phone number"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="phone"
					id="phone"
					title="Enter Attendees phone number"
					v-mask="'(###) ###-####'"
					v-model="currentAttendee.phone"
				/>
			</div>
		</div>
        <div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="badge_id">Badge ID</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee badge ID"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="badge_id"
					id="badge_id"
					title="Enter Attendees badge ID"
					required
					data-msg="Please provide a badge ID"
					v-mask="'######'"
					v-model="currentAttendee.badge_id"
				/>
			</div>
            <div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="date_of_birth">Date Of Birth</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee date of birth"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control js-flatpickr"
					type="text"
					name="date_of_birth"
					id="date_of_birth"
					title="Enter Attendees date of birth"
					required
					data-msg="Please enter in date of birth"
					v-mask="'##/##/####'"
					v-model="currentAttendee.date_of_birth"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="company">Company</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee company"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company"
					id="company"
					title="Enter Attendees company"
					disabled
					v-model="currentAttendee.company.name"
				/>
			</div>
        </div>
		<div class="form-group has-label">
			<div class="d-flex justify-content-between align-items-center">
				<label for="special_requests">Special Requests</label>
				<a
					href="#"
					data-toggle="tooltip"
					data-placement="right"
					data-boundary="window"
					title="Attendee special requests"
				>
					<span class="fas fa-question-circle text-info cursor-pointer"></span>
				</a>
			</div>
			<textarea
				class="form-control"
				name="special_requests"
				id="special_requests"
				title="Enter Attendees special requests"
				v-model="currentAttendee.reservation.special_requests"
			></textarea>
		</div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";

	export default {
		directives: { mask },
		props: ["selectedAttendee", "portalGroups"],
		data() {
			return {
				saveChangesButton: "Save Changes",
				formProcessing: false,
				formErrorMessge: "",
				formHasError: false,
				customMask: {
					mask: "CCCCCCCCCC",
					tokens: {
						C: {
							pattern: /[0-9a-zA-Z]/,
							transform: v => v.toLocaleUpperCase()
						}
					}
				},
				currentAttendee: this.selectedAttendee
			};
		}
	};
</script>
