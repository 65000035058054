<template>
	<div>
		<div class="row">
			<div class="form-group col-md-4">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="headshot_path">Image</label>
                </div>
                <div class="d-flex flex-column">
                    <img :src="selectedSpeaker.headshot_path" id="image_preview" alt="placeholder">
                    <div class="mt-2">
                        <input
							type="text"
							name="headshot_path"
							id="headshot_path"
							class="form-control"
							data-js-id="headshot_path"
							disabled
						>
                        <div class="mt-2">
                            <button class="btn btn-tertiary mr-1"  id="choose_image" data-input="headshot_path" data-preview="image_preview" title="Opens the file manager to choose to upload a new image or choose from your image library" @click.prevent="openSpeakerFileManager()">Choose Headshot</button>
                            <button class="btn btn-outline-primary" id="remove_image" title="Clears the current image" @click.prevent="removeImage()">Delete Image</button>
                        </div>
                    </div>
                </div>
            </div>
			<div class="col-md">
				<div class="row">
					<div class="form-group col-md">
						<label for="first_name">First Name<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							name="first_name"
							id="first_name"
							title="Please enter a first name"
							required
							data-msg="Please provide a first name"
							v-model="selectedSpeaker.first_name"
						/>
					</div>
					<div class="col-md form-group has-label">
						<label for="last_name">Last Name<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							name="last_name"
							id="last_name"
							title="Please enter a last name"
							required
							data-msg="Please provide a last name"
							v-model="selectedSpeaker.last_name"
						/>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-md">
						<label for="email">Email<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							name="email"
							id="email"
							title="Please enter a email"
							required
							data-msg="Please provide a email"
							v-model="selectedSpeaker.email"
						/>
					</div>
					<div class="col-md form-group has-label">
						<label for="phone">Phone</label>
						<input
							class="form-control"
							type="text"
							name="phone"
							id="phone"
							title="Please enter a phone"
							v-mask="'(###) ###-####'"
							v-model="selectedSpeaker.phone"
						/>
					</div>
					<div class="col-md form-group has-label">
						<label for="date_of_birth">Date of Birth<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							name="date_of_birth"
							id="date_of_birth"
							title="Please enter a date of birth"
							required
							data-msg="Please provide a date of birth"
							v-mask="'##/##/####'"
							v-model="selectedSpeaker.date_of_birth"
						/>
					</div>
				</div>
				<div class="row">
					<div class="form-group col-md">
						<label for="company_name">Company Name</label>
						<input
							class="form-control"
							type="text"
							name="company_name"
							id="company_name"
							title="Please enter a company name"
							v-model="selectedSpeaker.company_name"
						/>
					</div>
					<div class="col-md form-group has-label">
						<label for="job_title">Job Title</label>
						<input
							class="form-control"
							type="text"
							name="job_title"
							id="job_title"
							title="Please enter a job title"
							v-model="selectedSpeaker.job_title"
						/>
					</div>
					<div class="col-md form-group has-label">
						<label for="credentials">Credentials</label>
						<input
							class="form-control"
							type="text"
							name="credentials"
							id="credentials"
							title="Please enter credentials"
							v-model="selectedSpeaker.credentials"
						/>
					</div>
				</div>
			</div>
		</div>

        <div class="row">
            <div class="col-md form-group has-label">
				<p class="m-0 lh-xs" v-if="currentCourseIds.length">
					<small>
						<strong>Selected course
                            <template v-if="currentCourseIds.length > 1">s</template>:
                        </strong>
						<template v-for="(courseId, index) in currentCourseIds">
							{{ selectedCourses(courseId, index, currentCourseIds) }}
						</template>
					</small>
				</p>
				<div class="d-flex justify-content-between align-items-center">
					<label for="speaker_courses">Course(s)</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Use Ctrl + Click to select multiple courses"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<select
					multiple
					class="form-control flex-grow-1"
					name="courses[]"
					id="speaker_courses"
					title="Select a course"
					v-model="currentSpeaker.courseIds"

				>
					<option
						v-for="(course, index) in coursesByTitle"
						:key="index"
						:value="course.id"
					>{{ course.title }}</option>
				</select>
			</div>
			<div class="col-md">
				<div class="form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3 required">Active<span class="required">*</span></p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="This will show/hide this speaker from the front end listing"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Active</legend>
						<div
							class="form-check form-check-inline form-check-radio"
						>
							<label class="form-check-label mr-1" for="active_yes">
								<input
									class="form-check-input"
									type="radio"
									name="active"
									title="Select an option"
									value="1"
									id="active_yes"
									required
									data-msg="Please Select an Option"
									v-model="selectedSpeaker.active"
								/>
								<span class="form-check-sign"></span>
								Yes
							</label>
							<label class="form-check-label" for="active_no">
								<input
									class="form-check-input"
									type="radio"
									name="active"
									title="Select an option"
									value="0"
									id="active_no"
									required
									data-msg="Please Select an Option"
									v-model="selectedSpeaker.active"
								/>
								<span class="form-check-sign"></span>
								No
							</label>
						</div>
					</fieldset>
				</div>
				<div class="form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3 required">Status<span class="required">*</span></p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="This will affect the speaker's dashboard login status"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Status</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(status, index) in statuses" :key="index"
						>
							<label class="form-check-label mr-1" :for="`status_${index}`">
								<input
									class="form-check-input"
									type="radio"
									name="status"
									title="Select an option"
									:value="`${status}`"
									:id="`status_${index}`"
									required
									data-msg="Please Select an Option"
									v-model="selectedSpeaker.status"
								/>
								<span class="form-check-sign"></span>
								{{ status }}
							</label>
						</div>
					</fieldset>
				</div>
			</div>
        </div>
		<div class="row">
			<div class="form-group col-md">
				<label for="biography">Biography</label>
				<textarea
					class="form-control"
					name="biography"
					id="biography"
					title="Please enter a biography"
					v-model="selectedSpeaker.biography"
				></textarea>
			</div>
		</div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	import { openFileManager } from "../../../../../../core/vpanel/vpanel-functions";

	export default {
        directives: { mask },
        props: ["selectedSpeaker", "allCourses"],
        data() {
            return{
				currentSpeaker: this.selectedSpeaker
            }
        },
        computed: {
			coursesByTitle() {
				return this.allCourses.sort((a, b) => a.title.localeCompare(b.title));
			},
			currentCourseIds() {
				return this.currentSpeaker.courseIds;
			},
			chooseImageButton() {
				return document.querySelector('[data-input="headshot_path"]');
			},
			imagePreview() {
				return document.querySelector('#image_preview');
			},
			imageInput() {
				return document.querySelector('[data-js-id="headshot_path"]');
			},
			statuses() {
				return new Array("Active", "Banned", "Inactive", "Locked", "Pending");
			}
        },
        mounted() {
            CKEDITOR.replace('biography', {
                removePlugins: 'maximize,resize',
			});

			if (this.currentSpeaker.headshot_path) {
				document.querySelector('[name="headshot_path"]').value = this.currentSpeaker.headshot_path;
			}
		},
		methods: {
			courseTitleFromId(value) {
				let allCourses = this.allCourses;

				for (i = 0; i < allCourses.length; ++i) {
					if (allCourses[i].id == value) {
						return allCourses[i].title;
					}
				}
			},
			selectedCourses(item, index, parent) {
				return this.courseTitleFromId(item) + (index !== parent.length - 1 ? ', ' : '');
			},
			openSpeakerFileManager() {
				openFileManager(this.chooseImageButton, null);
			},
			removeImage() {
				this.imageInput.value = '';
				this.imagePreview.setAttribute('src', '');
			}
		}
	};
</script>