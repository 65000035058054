<template>
	<div>
		<div v-for="(badge, index) in selectedVendor.badges" :key="index">
			<!-- not an input, just needs displayed... I think? -->
			<h5 class="m-0">Badge #{{ index + 1 }} Number {{ selectedVendor.badges[index].badge_id }}</h5>
			<div class="row">
				<div class="form-group col-md">
					<label :for="`badge_${index}_first_name`">Badge #{{ index + 1 }} First Name<span class="required" v-if="index == 0">*</span></label>
					<input
						class="form-control"
						type="text"
						:name="`badge_${index}_first_name`"
						:id="`badge_${index}_first_name`"
						title="First Name"
						:required="index == 0"
						data-msg="Please provide first name"
						v-model="selectedVendor.badges[index].first_name"
					/>
				</div>
				<div class="form-group col-md">
					<label :for="`badge_${index}_last_name`">Badge #{{ index + 1 }} Last Name<span class="required" v-if="index == 0">*</span></label>
					<input
						class="form-control"
						type="text"
						:name="`badge_${index}_last_name`"
						:id="`badge_${index}_last_name`"
						title="Last Name"
						:required="index == 0"
						data-msg="Please provide last name"
						v-model="selectedVendor.badges[index].last_name"
					/>
				</div>
			</div>
		</div>

		<div v-for="(badge, index) in selectedVendor.additional_badges" :key="index + 10">
			<!-- not an input, just needs displayed... I think? -->
			<h5 clsas="m-0">Additional Badge #{{ index + 1 }} Number {{ selectedVendor.additional_badges[index].badge_id }}</h5>
			<div class="row">
				<div class="form-group col-md">
					<label :for="`additional_badge_${index}_first_name`">Badge #{{ index + 1 }} First Name<span class="required">*</span></label>
					<input
						class="form-control"
						type="text"
						:name="`additional_badge_${index}_first_name`"
						:id="`additional_badge_${index}_first_name`"
						title="First Name"
						required
						data-msg="Please provide first name"
						v-model="selectedVendor.additional_badges[index].first_name"
					/>
				</div>
				<div class="form-group col-md">
					<label :for="`additional_badge_${index}_last_name`">Badge #{{ index + 1 }} Last Name<span class="required">*</span></label>
					<input
						class="form-control"
						type="text"
						:name="`additional_badge_${index}_last_name`"
						:id="`additional_badge_${index}_last_name`"
						title="Last Name"
						required
						data-msg="Please provide last name"
						v-model="selectedVendor.additional_badges[index].last_name"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["selectedVendor", "packages"],
		computed: {
			currentVendor() {
				return this.selectedVendor;
			}
		}
	};
</script>