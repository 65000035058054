export const dtFaqCategoryListOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",

    columns: [
        // Title
        {
            data: 'name',
            className: 'faq-name'
        },
        // FAQs
        {
            data: 'faqs',
            width: '50%',
            render: function(data, type, row) {
                let html = 'N/A';
                if (data.length) {
                    html = '<p class="faq-titles m-0">';
                    data.forEach((faq, index) => {
                        let comma = index != 0 ? ', ' : '';
                        html += `${comma}${faq.title.split(' ').join('&nbsp;')}`;
                    });
                    html += '</p>'
                }

                return html;
            }
        },
        // Options
        {
            data: 'options',
            className: 'faq-options',
            render: function(data, type, row) {
                return  `<div class="faq-category-options">
                            <button class="faq-category-recycle btn btn-outline-primary" data-id="${row.id}" data-js-id="faq-category-recycle-button">Recycle</button>
                            <button class="faq-category-modify btn btn-primary" data-id="${row.id}" data-js-id="faq-category-modify-button">Modify</button>
                        </div>`
            }
        }
    ]
};