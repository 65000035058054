<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtPromoCodeOptions } from "./dtPromoCodeOptions";

export default {
    mixins: [dataTableMixin],
    props: [
        'selectedPromoCode',
    ],
    data() {
        return {
            moduleDatatableOptions: dtPromoCodeOptions,
        }
    },
    mounted() {
        // Add event listeners for buttons
        let dataTable = this.$el;

        const vm = this;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id = elementClicked.parentElement.dataset.id;

            switch (elementClicked.dataset.jsId) {
                case "promo-code-recycle":

                    vm.$parent.openPromoCodeRecycleModal(id);

                    break;
                case "promo-code-modify":

                    this.selectedPromoCode.id = id;

                    // Show modal
                    vm.$parent.openPromoCodeModal();

                    axios.get(`/vpanel/modules/conference/promo-codes/show/${id}`)
                        .then(res => {
                            try {
                                let promoCode = res.data.promoCode;

                                for (const prop in promoCode) {
                                    if (promoCode.hasOwnProperty(prop)) {
                                        const value = promoCode[prop];

                                        vm.selectedPromoCode[prop] = value;
                                    }
                                }

                                vm.$parent.showModalForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })

                    break;
            }
        });
    }
}
</script>