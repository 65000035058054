<template>
    <div class="doc-library-section">
        <div class="doc-library-index-buttons mb-3">
            <button class="btn btn-primary" @click.prevent="openAddModifyModal('add')">Add a Document</button>
            <button class="btn btn-outline-primary js-reorder">Reorder</button>
            <button class="btn btn-outline-primary js-cancel-reorder d-none">Cancel</button>
        </div>

        <document-library-list-datatable
            table-id="documents-list-datatable"
            :column-names='[
                "Name",
                "Categories",
                "Status",
                "Modified",
                "Options"
            ]'
            :ajax-route="ajaxRoute"
            :selected-document="selectedDocument"
        >
        </document-library-list-datatable>

        <document-add-modify-modal
            modal-size="lg"
            header-icon="briefcase"
            :modal-id="documentModalId"
            :modal-title="getModalTitle"
        >
            <div class="modal-body">
				<div class="row" v-if="formHasErrors">
					<div class="col">
						<div class="alert alert-danger" v-html="formErrorMessage"></div>
					</div>
				</div>

                <vpanel-loader v-if="showLoader"></vpanel-loader>

				<form class="d-flex flex-column" v-if="showForm" :data-js-id="documentFormId" novalidate>
                    <document-add-modify-form
                        :action-is-add="actionIsAdd"
                        :portal-groups="portalGroups"
                        :current-module="currentModule"
                        :all-categories="allCategories"
                        :portal-installed="portalInstalled"
                        :selected-document="selectedDocument"
                    >
                    </document-add-modify-form>

                    <div class="row">
                        <div class="modal-footer d-flex justify-content-end">
                            <button
                                type="button"
                                class="btn btn-outline-primary my-0 mr-1"
                                :id="cancelButtonId"
                                :disabled="formProcessing"
                                @click.prevent="closeAddModifyModal()"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary my-0"
                                :data-js-id="saveButtonId"
                                :disabled="formProcessing"
                                @click.prevent="saveDocument()"
                            >
                                {{ getActionVerb }}
                            </button>
                        </div>
                    </div>
				</form>
            </div>
        </document-add-modify-modal>

        <document-library-recycle-modal
            modal-size="lg"
            header-icon="trash"
            modal-title="Confirm Recycle"
            :modal-id="documentRecycleModalId"
        >
            <confirm-recycle-modal-content
                @recycleEvent="recycleRecord"
                :form-processing="formProcessing"
                :document-recycle-modal-id="documentRecycleModalId"
            >
            </confirm-recycle-modal-content>
        </document-library-recycle-modal>
    </div>
</template>
<script>
    import { loaderMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/loaderMixin";
    export default {
        mixins: [loaderMixin],
        props: [
            "ajaxRoute",
            "portalGroups",
            "currentModule",
            "allCategories",
            "portalInstalled",
        ],
        data(){
            return {
                showForm: true,
                actionIsAdd: true,
                showLoader: false,
                formErrorMessage: "",
                formHasErrors: false,
                formProcessing: false,
                saveButtonId: "document-save-button",
                dataTableId: "documents-list-datatable",
                cancelButtonId: "document-cancel-button",
                documentFormId: "document-add-modify-form",
                documentModalId: "document-add-modify-modal",
                confirmRecycleButtonId: 'confirm-recycle-button',
                documentRecycleModalId: "document-recycle-modal",
            }
        },
        computed: {
            recycleModal(){
                return document.querySelector(`[data-js-id="${this.documentRecycleModalId}"]`);
            },
            addModifyModal(){
                return document.querySelector(`[data-js-id="${this.documentModalId}"]`);
            },
            addModifyForm(){
                return document.querySelector(`[data-js-id="${this.documentFormId}"]`);
            },
            saveDocumentButton(){
                return document.querySelector(`[data-js-id="${this.saveButtonId}"]`);
            },
            confirmRecycleButton(){
                return document.querySelector(`[data-js-id="${this.confirmRecycleButtonId}"]`);
            },
            dataTable(){
                return document.querySelector(`#${this.dataTableId}`);
            },
            getActionVerb(){
                if(this.actionIsAdd === true){
                    return 'Save';
                } else {
                    return 'Update';
                }
            },
            getModalTitle(){
                if(this.actionIsAdd){
                    return 'Add a ' + this.currentModule;
                } else {
                    return 'Modify a ' + this.currentModule;
                }
            },
            selectedDocument(){
                return {
                    active: 1,
                    secure: 0,
                    document_path: '',
                    display_description: 0,
                }
            }
        },
        methods: {
            openAddModifyModal(action){
                if(action == 'add'){
                    this.clearFormFields();
                    this.showAddModifyForm();
                    this.actionIsAdd = true;
                } else { //modify
                    this.actionIsAdd = false;
                    this.hideAddModifyForm();
                }

                $(this.addModifyModal).modal('show');
            },
            closeAddModifyModal(){
                $(this.addModifyModal).modal('hide');
            },
            openRecycleModal(){
                $(this.recycleModal).modal('show');
            },
            hideAddModifyForm(){
                this.showForm   = false;
                this.showLoader = true;
            },
            showAddModifyForm(){
                this.showForm   = true;
                this.showLoader = false;
            },
            clearFormFields(){
                //clear form fields
                for(const name of Object.keys(this.selectedDocument)){
                    if(name == 'active' || name == 'secure' || name == 'display_description'){
                        this.selectedDocument[name] = 1
                    } else if(name == 'document_path'){
                        document.querySelector('[name="document_path"]').value = '';
                    } else {
                        this.selectedDocument[name] = '';
                    }
                }

                if(this.formErrorMessage){
                    this.formHasErrors = false;
                    this.errorMessage  = '';
                }
            },
            saveDocument(){
                const dlm			= this;
				const addModifyForm = dlm.addModifyForm;
				const saveButton 	= dlm.saveDocumentButton;
				let url;

                //validate form fields
				$(addModifyForm).validate({
					errorPlacement: function(error, element){
                        //display error messages
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.addClass('text-danger');
							error.prependTo(element.parents("fieldset"));
						} else {
							error.addClass('text-danger');
							error.insertAfter(element);
						}
					}
				});

				if($(addModifyForm).valid()){
                    //disable buttons
                    dlm.formProcessing   = true;
                    //Reset button text
					saveButton.innerText = 'Processing...';

                    //Set url based on if its add or modify
					if(dlm.actionIsAdd === true){
						url = window.vpanel.document_library.store;
					} else {
                        const id = dlm.selectedDocument.id;
						url      = window.vpanel.document_library.update + `/${id}`;
					}

                    //get the value of the document path input
                    let documentSelectedByUser = document.querySelector('[name="document_path"]').value;
                    //set it to the object
					dlm.selectedDocument.document_path = documentSelectedByUser;

                    //make request
					axios.post(url, dlm.selectedDocument)
						.then(response => {
                            try {
                                const data = response.data;

                                //enable buttons
                                dlm.formProcessing 	 = false;
                                //reset button text
                                saveButton.innerText = dlm.getActionVerb;
                                //hide modal
                                $(dlm.addModifyModal).modal("hide");

                                dlm.clearFormFields();

                                //show notification
                                showNotification(data.message, data.result);

                                //redraw datatable
                                $(dlm.dataTable).DataTable().ajax.reload();

                                //update records
                                this.$parent.updateRecords('documents', data.allDocuments);
                            } catch(error){
                                //Record was saved/updated, there was just a js error
                                openApplicationErrorModal(error, "Don't worry, your changes saved properly.");
								console.error(error);
                            }
						})
						.catch(error =>{
							if(error.response){
								if(error.response.status == 422){
                                    //validation error from controller
									const errors 	   = error.response.data[0];
                                    const errorMessage = error.response.data.message;
                                    //displays error message
									dlm.formHasErrors  = true;

                                    //builds html and processes error message
									let errorsUl = document.createElement('ul');
									errorsUl.classList.add("list-styled");

									for(error in errors){
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                    }

                                    //sets error message html
									dlm.formErrorMessage = errorMessage + errorsUl.outerHTML;
								} else {
                                    //other error
									openApplicationErrorModal(error, "");
									console.error(error);
								}
							}

                            //enables buttons
                            dlm.formProcessing 	 = false;
                            //resets button text
							saveButton.innerText = dlm.getActionVerb;
							console.error(error);
						});
				}
            },
            recycleRecord(){
                const dlm = this;
                const id  = dlm.selectedDocument.id;
                const url = window.vpanel.document_library.recycle;

                //disable buttons
                dlm.formProcessing = true;
                //Reset button text
                dlm.confirmRecycleButton.innerText = 'Processing...';

                //Make request to recycle
                axios.post(url, {"id" : id})
                    .then(response => {
                        try {
                            const data = response.data;

                            //enable buttons
                            dlm.formProcessing = false;
                            //Reset the button text
                            dlm.confirmRecycleButton.innerText = 'Yes, please recycle';

                            //Redraw datatable
                            $(dlm.dataTable).DataTable().ajax.reload();

                            //Hide the modal
                            $(dlm.recycleModal).modal('hide');

                            //Show the notification
                            showNotification(data.message, data.result);
                        } catch(error) {
                            //Record was recycled, there was just a js error
                            openApplicationErrorModal(error, "Don't worry, everything recycled properly.");
                            console.error(error);
                        }
                    })
                    .catch(error => {
                        openApplicationErrorModal(error);
                        console.error(error);

                        //enable button text
                        dlm.formProcessing = false;
                        //Reset the button text
                        dlm.confirmRecycleButton.innerText = 'Yes, please recycle';
                    });
            }
        }
    }
</script>