import axios from 'axios'
import {logApplicationError} from "../../../core/global/functions";

export default class PromoCode {
    constructor({
        code = '',
        total = 0,
        groups = [],
        reservationCount = -1
    }) {
        this.code = code
        this.total = total
        this.groups = groups
        this.reservationCount = reservationCount

        this.onSuccess = (res) => console.log(res)
        this.onError = (err) => {
            logApplicationError(err)
            console.error(err)
        }
    }

    getQualifiedPromoCodes({
        onSuccess = this.onSuccess,
        onError = this.onError
    }) {
        axios.post('/heartland/portal/get-qualified-promo-codes', {
            total: this.total,
            reservationCount: this.reservationCount,
            groups: this.groups
        }).then(onSuccess).catch(onError)
    }

    validateAndApplyPromoCode({
        onBefore = () => true,
        onSuccess = this.onSuccess,
        onError = this.onError,
        onFinally = () => true
    }) {
        onBefore()

        axios
            .post("/heartland/portal/validate-promo-code", {
                code: this.code,
                number_of_attendees: this.reservationCount,
                subtotal: this.total,
                groups: this.groups
            })
            .then(onSuccess)
            .catch(onError)
            .finally(onFinally);
    }

    revertUseCount({
        onError = this.onError
    }) {
        axios
            .post("/heartland/portal/revert-use-count", {
                code: this.code,
            })
            .catch(onError);
    }
}