<template>
	<div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="company_name">Company</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Company name"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company_name"
					id="company_name"
					title="Enter company name"
					required
					data-msg="Please provide a company name"
					v-model="currentAdmin.company.name"
				/>
			</div>
			<div class="col-md form-group has-label" v-if="currentAdmin.company.is_vgm_member == 1">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="vgm_member_number">VGM Member Number</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Company VGM Member Number"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="vgm_member_number"
					id="vgm_member_number"
					title="Company VGM Member Number"
					v-model="currentAdmin.company.vgm_member_number"
				/>
			</div>
			<div class="col-md form-group d-flex flex-row align-items-start has-label" v-if="currentAdmin.company.is_vgm_member == 1">
				<div class="custom-control custom-switch mt-1 ml-0">
					<input
						type="checkbox"
						disabled
						class="custom-control-input js-boolean-control module-config-input"
						id="valid_vgm_member"
						name="valid_vgm_member"
						v-model="currentAdmin.company.is_valid_vgm_member"
					/>
					<label class="custom-control-label" for="valid_vgm_member">Valid VGM Member?</label>
				</div>
			</div>
		</div>
        <div class="row">
            <div class="col-6 form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="company_address_1">Address</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Admin company address"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company_address_1"
					id="company_address_1"
					title="Enter company address"
					required
					data-msg="Please provide a company address"
					v-model="currentAdmin.company.address_1"
				/>
			</div>
            <div class="col-6 form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="company_address_2">Address 2</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Admin company address"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company_address_2"
					id="company_address_2"
					title="Enter company address"
					v-model="currentAdmin.company.address_2"
				/>
			</div>
        </div>
        <div class="row">
            <div class="col-4 form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="company_city">City</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Admin company city"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company_city"
					id="company_city"
					title="Enter company city"
					required
					data-msg="Please provide a company city"
					v-model="currentAdmin.company.city"
				/>
			</div>
            <div class="col-4 form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="company_state_province">State/Province</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Admin company State/Province"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company_state_province"
					id="company_state_province"
					title="Enter company State/Province"
					required
					data-msg="Please provide a company State/Province"
					v-model="currentAdmin.company.state_province"
				>
			</div>
            <div class="col-4 form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="company_postal_code">Postal Code</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Admin company postal code"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company_postal_code"
					id="company_postal_code"
					title="Enter company postal code"
					required
					data-msg="Please provide a company postal code"
					v-model="currentAdmin.company.postal_code"
				/>
			</div>
        </div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";

	export default {
		directives: { mask },
		props: ["selectedAdmin"],
		data() {
			return {
				currentAdmin: this.selectedAdmin
			};
		},
	};
</script>