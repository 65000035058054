// Content Variables
const scrollAmount  = 0;
const scrolledClass = 'scrolled';
const loadedClass   = 'loaded';
const header = document.querySelector('header');
const nav    = document.querySelector('nav.header-nav');

// Load Events
window.addEventListener('load', function() {
    headerSectionHeight();
    scrolledPast(scrollAmount);
    document.body.setAttribute(`data-${loadedClass}`, 'true');
})

// Scroll Events with debounce for better performance
gatedScroll = () => {
    scrolledPast(scrollAmount);
}

let scrollTimeout;
window.addEventListener('scroll', function() {
    clearTimeout(scrollTimeout);
    scrollTimeout = setTimeout(gatedScroll, 100);
});

// Resize Events with debounce for better performance
gatedResize = () => {
    headerSectionHeight();
}

let resizeTimeout;
window.addEventListener('resize', function() {
    clearTimeout(resizeTimeout);
    resizeTimeout = setTimeout(gatedResize, 250);
});

// add data-scrolled to the body if have scrolled past scrollAmount
scrolledPast = (dist) => {
    if ( window.pageYOffset > dist ) {
        document.body.setAttribute(`data-${scrolledClass}`, 'true')
    } else {
        document.body.removeAttribute(`data-${scrolledClass}`);
    }
}

// Measure height of the header and apply a css var with the height to the body
headerSectionHeight = () => {
    const headerHeight = header.offsetHeight;
    const navHeight    = nav.offsetHeight;
    const headerTotal  = headerHeight + navHeight + 'px';

    document.body.style.setProperty('--header-height', headerTotal);
}