<template>
    <div class="card">
        <div class="card-footer border-top-0">
            <div class="d-flex justify-content-end">
                <div class="d-flex flex-column align-items-end">
                    <p class="m-0">Subtotal: <strong>${{ parseFloat(appData.subtotal).toFixed(2) }}</strong></p>
                    <p class="m-0" v-if="appData.promo_discount_total">Discount Applied: <strong>-${{ parseFloat(appData.promo_discount_total).toFixed(2) }}</strong></p>
                    <p class="m-0">Total: <strong>${{ parseFloat(appData.total).toFixed(2) }}</strong></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData
        };
    }
};
</script>