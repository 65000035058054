<script>
	import { dataTableMixin } from "../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
	import { dtOptions } from "./dtOptions";

	export default {
		mixins: [dataTableMixin],
		data() {
			return {
				moduleDatatableOptions: dtOptions
			};
		},
	};
</script>