<script>
import { modalMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/modalMixin";

export default {
    mixins: [modalMixin],
    props: [
        'selectedSpeaker',
        'allCourses'
    ]
}
</script>