<script>
import { modalMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/modalMixin";

export default {
    name: "AgendaTagsAddModifyModal",
    mixins: [modalMixin],
    props: [
        'selectedTag',
        'agendas'
    ]
}
</script>