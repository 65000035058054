<template>
    <div class="document-category-section">
        <div class="doc-categories-index-buttons mb-3">
            <button class="btn btn-primary" @click.prevent="openAddModifyModal('add')">Add a Category</button>
        </div>

        <document-categories-list-datatable
            :table-id="dataTableId"
            :column-names='[
                "Name",
                "Documents",
                "Status",
                "Modified",
                "Options"
            ]'
            :ajax-route="ajaxRoute"
            :selected-category="selectedCategory"
        >
        </document-categories-list-datatable>

        <document-category-add-modify-modal
            :modal-id="categoryModalId"
            :modal-title="getModalTitle"
            header-icon="briefcase"
            modal-size="md"
        >
            <div class="modal-body">
				<div class="row" v-if="formHasErrors">
					<div class="col">
						<div class="alert alert-danger" v-html="formErrorMessage"></div>
					</div>
				</div>

                <vpanel-loader v-if="showLoader"></vpanel-loader>

				<form class="d-flex flex-column" v-if="showForm" :data-js-id="categoryFormId" novalidate>
                    <category-add-modify-form
                        :action-is-add="actionIsAdd"
                        :portal-groups="portalGroups"
                        :all-documents="allDocuments"
                        :portal-installed="portalInstalled"
                        :selected-category="selectedCategory"
                    >
                    </category-add-modify-form>

                    <div class="row">
                        <div class="modal-footer d-flex justify-content-end">
                            <button
                                type="button"
                                class="btn btn-outline-primary my-0 mr-1"
                                :id="cancelButtonId"
                                :disabled="formProcessing"
                                @click.prevent="closeAddModifyModal()"
                            >
                                Cancel
                            </button>
                            <button
                                type="submit"
                                class="btn btn-primary my-0"
                                :data-js-id="saveButtonId"
                                :disabled="formProcessing"
                                @click.prevent="saveCategory()"
                            >
                                {{ getActionVerb }}
                            </button>
                        </div>
                    </div>
				</form>
            </div>
        </document-category-add-modify-modal>

        <document-library-recycle-modal
            :modal-id="categoryRecycleModalId"
            modal-title="Confirm Recycle"
            header-icon="trash"
            modal-size="lg"
        >
            <confirm-recycle-modal-content
                :document-recycle-modal-id="categoryRecycleModalId"
                :form-processing="formProcessing"
                @recycleEvent="recycleRecord"
            >
            </confirm-recycle-modal-content>
        </document-library-recycle-modal>
    </div>
</template>
<script>
    export default {
        props: [
            "ajaxRoute",
            "portalGroups",
            "allDocuments",
            "portalInstalled",
        ],
        data(){
            return {
                showForm: true,
                actionIsAdd: true,
                showLoader: false,
                formHasErrors: false,
                formErrorMessage: "",
                formProcessing: false,
                saveButtonId: "category-save-button",
                cancelButtonId: "category-cancel-button",
                categoryFormId: "category-add-modify-form",
                categoryModalId: "category-add-modify-modal",
                dataTableId: "document-category-list-datatable",
                confirmRecycleButtonId: 'confirm-recycle-button',
                categoryRecycleModalId: "category-recycle-modal"
            }
        },
        computed: {
            recycleModal(){
                return document.querySelector(`[data-js-id="${this.categoryRecycleModalId}"]`);
            },
            addModifyModal(){
                return document.querySelector(`[data-js-id="${this.categoryModalId}"]`);
            },
            addModifyForm(){
                return document.querySelector(`[data-js-id="${this.categoryFormId}"]`);
            },
            saveCategoryButton(){
                return document.querySelector(`[data-js-id="${this.saveButtonId}"]`);
            },
            confirmRecycleButton(){
                return document.querySelector(`[data-js-id="${this.confirmRecycleButtonId}"]`);
            },
            dataTable(){
                return document.querySelector(`#${this.dataTableId}`);
            },
            getActionVerb(){
                if(this.actionIsAdd === true){
                    return 'Save';
                } else {
                    return 'Update';
                }
            },
            getModalTitle(){
                if(this.actionIsAdd){
                    return 'Add a Category';
                } else {
                    return 'Modify a Category';
                }
            },
            selectedCategory(){
                return {
                    active: 1,
                }
            }
        },
        methods: {
            openAddModifyModal(action){
                if(action == 'add'){
                    this.clearFormFields();
                    this.showAddModifyForm();
                    this.actionIsAdd = true;
                } else { //modify
                    this.actionIsAdd = false;
                    this.hideAddModifyForm();
                }

                $(this.addModifyModal).modal('show');
            },
            closeAddModifyModal(){
                $(this.addModifyModal).modal('hide');
            },
            openRecycleModal(){
                $(this.recycleModal).modal('show');
            },
            hideAddModifyForm(){
                this.showForm   = false;
                this.showLoader = true;
            },
            showAddModifyForm(){
                this.showForm   = true;
                this.showLoader = false;
            },
            clearFormFields(){
                //clear form fields
                for(const name of Object.keys(this.selectedCategory)){
                    if(name == 'active'){
                        this.selectedCategory[name] = 1
                    } else {
                        this.selectedCategory[name] = '';
                    }
                }

                if(this.formErrorMessage){
                    this.formHasErrors = false;
                    this.errorMessage  = '';
                }
            },
            saveCategory(){
                const dlm			= this;
				const addModifyForm = dlm.addModifyForm;
				const saveButton 	= dlm.saveCategoryButton;
				let url;

                //validate form fields
				$(addModifyForm).validate({
					errorPlacement: function(error, element){
                        //display error messages
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.addClass('text-danger');
							error.prependTo(element.parents("fieldset"));
						} else {
							error.addClass('text-danger');
							error.insertAfter(element);
						}
					}
				});

				if($(addModifyForm).valid()){
                    //disable buttons
                    dlm.formProcessing   = true;
                    //Reset button text
					saveButton.innerText = 'Processing...';

                    //Set url based on if its add or modify
					if(dlm.actionIsAdd === true){
						url = window.vpanel.document_library.category.store;
					} else {
                        const id = dlm.selectedCategory.id;
						url      = window.vpanel.document_library.category.update + `/${id}`;
					}

                    //make request
					axios.post(url, dlm.selectedCategory)
						.then(response => {
                            try {
                                const data = response.data;

                                //enable buttons
                                dlm.formProcessing 	 = false;
                                //reset button text
                                saveButton.innerText = dlm.getActionVerb;
                                //hide modal
                                $(dlm.addModifyModal).modal("hide");

                                dlm.clearFormFields();

                                //show notification
                                showNotification(data.message, data.result);

                                //redraw datatable
                                $(dlm.dataTable).DataTable().ajax.reload();

                                //update records
                                this.$parent.updateRecords('categories', data.allCategories);
                            } catch(error){
                                //Record was saved/updated, there was just a js error
                                openApplicationErrorModal(error, "Don't worry, your changes saved properly.");
								console.error(error);
                            }
						})
						.catch(error =>{
							if(error.response){
								if(error.response.status == 422){
                                    //validation error from controller
									const errors 	   = error.response.data[0];
                                    const errorMessage = error.response.data.message;
                                    //displays error message
									dlm.formHasErrors  = true;

                                    //builds html and processes error message
									let errorsUl = document.createElement('ul');
									errorsUl.classList.add("list-styled");

									for(error in errors){
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                    }

                                    //sets error message html
									dlm.formErrorMessage = errorMessage + errorsUl.outerHTML;
								} else {
                                    //other error
									openApplicationErrorModal(error, "");
									console.error(error);
								}
							}

                            //enables buttons
                            dlm.formProcessing 	 = false;
                            //resets button text
							saveButton.innerText = dlm.getActionVerb;
							console.error(error);
						});
				}
            },
            recycleRecord(){
                const dlm = this;
                const id  = dlm.selectedCategory.id;
                const url = window.vpanel.document_library.category.recycle;

                //disable buttons
                dlm.formProcessing = true;
                //Reset button text
                dlm.confirmRecycleButton.innerText = 'Processing...';

                //Make request to recycle
                axios.post(url, {"id" : id})
                    .then(response => {
                        try {
                            const data = response.data;

                            //enable buttons
                            dlm.formProcessing = false;
                            //Reset the button text
                            dlm.confirmRecycleButton.innerText = 'Yes, please recycle';

                            //Redraw datatable
                            $(dlm.dataTable).DataTable().ajax.reload();

                            //Hide the modal
                            $(dlm.recycleModal).modal('hide');

                            //Show the notification
                            showNotification(data.message, data.result);
                        } catch(error) {
                            //Record was recycled, there was just a js error
                            openApplicationErrorModal(error, "Don't worry, everything recycled properly.");
                            console.error(error);
                        }
                    })
                    .catch(error => {
                        openApplicationErrorModal(error);
                        console.error(error);

                        //enable button text
                        dlm.formProcessing = false;
                        //Reset the button text
                        dlm.confirmRecycleButton.innerText = 'Yes, please recycle';
                    });
            }
        }
    }
</script>