<template>
	<div id="vendor-profile">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1">
			<em>Vendor Profile</em>
		</h4>

		<!-- Contact Info -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Contact Information"
				@click.native="toggleContactInfo()"
				:is-toggled="showContactInfo"
			></portal-dashboard-toggle-bar>
			<div class="card-body d-flex justify-content-between align-items-start" v-if="showContactInfo">
				<div class="my-n2">
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Name on record</small>
						{{ user.first_name }} {{ user.last_name }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm" v-if="user.title">
						<small class="text-uppercase text-primary-green font-weight-bold">Title</small>
						{{ user.title }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Company Name</small>
						{{ company.name }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Address</small>
						{{ address }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Email</small>
						{{ user.email }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Phone</small>
						{{ user.phone }}
					</p>
				</div>

				<button
					class="btn btn-primary"
					v-if="isAfterBadgeCutoff == 0"
					@click="openEditContactModal()"
				>Edit</button>
			</div>

			<vendor-dashboard-contact-info-modal
				:modal-id="contactInfoModalId"
				modal-title="Edit Contact Information"
				header-icon="user"
				modal-size="lg"
			>
				<vendor-dashboard-contact-form
					:user="user"
					:company="company"
					:close-modal="closeEditContactModal"
				></vendor-dashboard-contact-form>
			</vendor-dashboard-contact-info-modal>
		</div>

		<!-- User Id And Password -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="User ID And Password"
				@click.native="toggleUserIdAndPasswordInfo()"
				:is-toggled="showUserIdAndPasswordInfo"
			></portal-dashboard-toggle-bar>
			<div
				class="card-body d-flex justify-content-between align-items-start"
				v-if="showUserIdAndPasswordInfo"
			>
				<div class="my-n2">
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Username</small>
						{{ user.username }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Password</small>
						⚹⚹⚹⚹⚹⚹⚹⚹
					</p>
				</div>

				<button class="btn btn-primary" @click="openEditPasswordModal()">Reset Password</button>
			</div>

			<vendor-dashboard-user-id-password-modal
				:modal-id="userIdAndPasswordModalId"
				modal-title="Change Password"
				header-icon="lock"
				modal-size="lg"
			>
				<vendor-dashboard-user-id-password-form :user="user" :close-modal="closeEditPasswordModal"></vendor-dashboard-user-id-password-form>
			</vendor-dashboard-user-id-password-modal>
		</div>

		<!-- Profile Image -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Profile Image"
				@click.native="openProfileImageModal()"
			></portal-dashboard-toggle-bar>

			<vendor-dashboard-profile-image-modal
				:modal-id="profileImageModalId"
				modal-title="Profile Image"
				header-icon="image"
				modal-size="md"
			>
				<vendor-dashboard-profile-image-form :close-modal="closeProfileImageModal"></vendor-dashboard-profile-image-form>
			</vendor-dashboard-profile-image-modal>
		</div>

		<!-- Package Information -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Booth/Package Information"
				@click.native="togglePackageInformation()"
				:is-toggled="showPackageInfo"
			></portal-dashboard-toggle-bar>
			<div class="card-body d-flex justify-content-between align-items-start" v-if="showPackageInfo">
				<div class="my-n2">
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Booth ID/Location</small>
						{{ registration.booth_id_location || 'Not Assigned Yet' }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Package Name</small>
						{{ packageInfo.name }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Booth Size</small>
						{{ packageInfo.booth_size }}
					</p>
				</div>
			</div>
		</div>

		<!-- Booth Order Form -->
		<div class="card my-2" v-if="orders.length > 0">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Booth Options Order Form"
				@click.native="toggleBoothOrderForm()"
				:is-toggled="showBoothOrderForm"
				:processing-fee="processingFee"
			></portal-dashboard-toggle-bar>
			<div class="card-body d-flex justify-content-between align-items-start" v-if="showBoothOrderForm">
				<template v-for="(order, order_index) in orders">
					<div class="oi-detail">
						<p class="oi-key flex-grow-1">Booth Options</p>
						<table class="oi-value">
							<thead>
								<tr style="text-align: left; vertical-align: top;">
									<th class="p-1">Item</th>
									<th class="p-1">Option</th>
									<th class="p-1">Quantity</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="(order_line_item, order_line_items_index) in order.order_line_items" >
									<tr style="text-align: left; vertical-align: top;" :key="order_line_items_index" v-if="order_line_item.type == 'Vendor'">
										<td class="my-0 p-1">{{ order_line_item.name }}</td>
										<td v-if=" order_line_item.order_line_item_options.length > 0 " class="my-0 p-1">{{ order_line_item.order_line_item_options[0].name }}:&nbsp;{{ order_line_item.order_line_item_options[0].value }}</td>
										<td v-if=" order_line_item.order_line_item_options.length == 0 " class="my-0 p-1">&nbsp;</td>
										<td class="my-0 p-1">{{ order_line_item.qty }}</td>
									</tr>
								</template>
							</tbody>
						</table>
					</div>
				</template>

				<button
					class="btn btn-primary"
					@click="openBoothOptionsOrderFormModal()"
				>Edit</button>
			</div>

			<vendor-dashboard-booth-options-modal
				:modal-id="boothOptionsModalId"
				modal-title="Edit Booth Options"
				header-icon="user"
				:modal-size="1000"
			>
				<vendor-dashboard-booth-options-form
					:user="user"
					:company="company"
					:categories="categories"
					:close-modal="closeBoothOptionsOrderFormModal"
					:processing-fee="processingFee"
				></vendor-dashboard-booth-options-form>
			</vendor-dashboard-booth-options-modal>

		</div>

		<!-- Add Ons & Options -->
		<div class="card my-2" v-if="golfers.length">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Add Ons and Options"
				@click.native="toggleAddonsAndOptions()"
				:is-toggled="showAddonsAndOptions"
			></portal-dashboard-toggle-bar>

			<div
				class="card-body d-flex justify-content-between align-items-start"
				v-if="showAddonsAndOptions"
			>
				<div class="my-n2 d-flex flex-column">
					<div class="my-2 d-inline-flex flex-column lh-sm" v-if="golfers.length">
						<p class="text-uppercase text-primary-green d-flex justify-content-between align-items-center m-0">
							<small class="font-weight-bold">Golfers ({{ golfers.length }})</small>
						</p>
						<p
							class="m-0 d-flex justify-content-between align-items-center"
							v-for="(golfer, index) in golfers"
							:key="index"
						>
							{{ golfer.first_name }} {{ golfer.last_name }}
						</p>
					</div>
				</div>

				<button
					class="btn btn-primary"
					v-if="isAfterBadgeCutoff == 0"
					@click="openGolfersModal()"
				>Edit Golfers</button>

				<vendor-dashboard-golfers-modal
					:modal-id="golfersModalId"
					modal-title="Edit Golfers"
					header-icon="golf-ball"
					modal-size="lg"
				>
					<vendor-dashboard-golfers-form
						:user="user"
						:close-modal="closeGolfersModal"
						:golfers="golfers"
					></vendor-dashboard-golfers-form>
				</vendor-dashboard-golfers-modal>
			</div>
		</div>

		<!-- Badges -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Badges"
				@click.native="toggleBadges()"
				:is-toggled="showBadges"
			></portal-dashboard-toggle-bar>

			<div class="card-body d-flex justify-content-between align-items-start" v-if="showBadges">
				<div class="mb-2 d-inline-flex flex-column lh-sm">
					<p class="text-uppercase text-primary-green m-0">
						<small class="font-weight-bold">Included Badges ({{ packageInfo.number_of_included_badges }})</small>
					</p>
					<p
						class="m-0 d-flex justify-content-between align-items-center"
						v-for="(badge, index) in includedBadges"
						:key="index"
					>{{ badge.first_name }} {{ badge.last_name }}</p>

					<div class="my-2 d-inline-flex flex-column lh-sm" v-if="additionalBadges.length">
						<p class="text-uppercase text-primary-green m-0">
							<small class="font-weight-bold">Additional Badges ({{ additionalBadges.length }})</small>
						</p>
						<p
							class="m-0 d-flex justify-content-between align-items-center"
							v-for="(badge, index) in additionalBadges"
							:key="index"
						>{{ badge.first_name }} {{ badge.last_name }}</p>
					</div>
				</div>

				<button
					class="btn btn-primary"
					v-if="isAfterBadgeCutoff == 0"
					@click="openBadgesModal()"
				>Edit Badges</button>

				<vendor-dashboard-badges-modal
					:modal-id="badgesModalId"
					modal-title="Edit Badges"
					header-icon="id-badge"
					modal-size="lg"
				>
					<vendor-dashboard-badges-form
						:user="user"
						:close-modal="closeBadgesModal"
						:included-badges="includedBadges"
						:additional-badges="additionalBadges"
					></vendor-dashboard-badges-form>
				</vendor-dashboard-badges-modal>
			</div>
		</div>

		<!-- Order Info -->
        <div class="card my-2" v-if="orders.length">
            <portal-dashboard-toggle-bar
                toggle-bar-text="Order Info"
                @click.native="toggleOrderInfo()"
                :is-toggled="showOrderInfo"
            ></portal-dashboard-toggle-bar>
            <template v-for="(order, order_index) in orders">
                <div class="card-body d-flex justify-content-between align-items-start" :class="{'border-top': order_index > 0}" v-if="showOrderInfo" :key="order_index">
                    <div class="orders-section">
                        <div class="order-info">
                            <div class="oi-detail">
                                <p class="oi-key">Order Number:</p>
                                <p class="oi-value">#{{ order.order_number }}</p>
                            </div>
                            <div class="oi-detail">
                                <p class="oi-key">Billing Info:</p>
                                <div class="oi-value">
                                    <p class="m-0">{{ ifUseElseNot(order.billing_first_name) }} {{ ifUseElseNot(order.billing_last_name) }}</p>
                                    <p class="m-0">{{ ifUseElseNot(order.billing_address_1) }} {{ ifUseElseNot(order.billing_address_2) }}</p>
                                    <p class="m-0">{{ ifUseElseNot(order.billing_city) }}, {{ ifUseElseNot(order.billing_state_province) }} {{ ifUseElseNot(order.billing_postal_code) }}</p>
                                    <p class="m-0" v-if="order.vendor_details">VGM #: {{ ifUseElseNot(order.vendor_details.vgm_member_number) }}</p>
                                </div>
                            </div>

                            <template v-if="order.vendor_details">
                                <div class="oi-detail">
                                    <p class="oi-key flex-grow-1">Booth Package:</p>
                                    <p class="oi-value">Package {{ getVendorOrderPackage(order) }}</p>
                                    <p class="oi-value ml-2">{{ getVendorOrderPackagePrice(order) }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Included Badges:</p>
                                    <p class="oi-value">{{ order.vendor_details.package_info.number_of_included_badges }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Booth Size:</p>
                                    <p class="oi-value">{{ order.vendor_details.package_info.booth_size }}</p>
                                </div>
								<div class="oi-detail">
                                    <p class="oi-key flex-grow-1">Booth Options</p>
                                    <table class="oi-value">
										<thead>
											<tr style="text-align: left; vertical-align: top;">
												<th class="p-1">Item</th>
												<th class="p-1">Option</th>
												<th class="p-1">Quantity</th>
												<th class="p-1">Price</th>
											</tr>
										</thead>
										<tbody>
											<template v-for="(order_line_item, order_line_items_index) in order.order_line_items" >
												<tr style="text-align: left; vertical-align: top;" :key="order_line_items_index" v-if="order_line_item.type == 'Vendor'">
													<td class="my-0 p-1">{{ order_line_item.name }}</td>
													<td v-if=" order_line_item.order_line_item_options.length > 0 " class="my-0 p-1">{{ order_line_item.order_line_item_options[0].name }}:&nbsp;{{ order_line_item.order_line_item_options[0].value }}</td>
													<td v-if=" order_line_item.order_line_item_options.length == 0 " class="my-0 p-1">&nbsp;</td>
													<td class="my-0 p-1">{{ order_line_item.qty }}</td>

													<td class="my-0 p-1">${{ (order_line_item.cost * order_line_item.qty).toFixed(2) }}</td>
												</tr>
                                        	</template>
										</tbody>
									</table>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key flex-grow-1">Badges</p>
                                    <div class="oi-value ml-2">
                                        <div class="d-flex justify-content-between">
                                            <p class="my-0 font-weight-bold">Badge Holder</p>
                                            <p class="my-0 font-weight-bold ml-2">Badge ID</p>
                                        </div>
                                        <div class="d-flex justify-content-between" v-for="(badge, included_badge_index) in order.vendor_details.included_badges" :key="included_badge_index">
                                            <p class="my-0">{{ badge.first_name }} {{ badge.last_name }}</p>
                                            <p class="my-0 ml-2">{{ badge.badge_id }}</p>
                                        </div>
                                        <template v-if="order.vendor_details.additional_badges.length">
                                            <div class="d-flex justify-content-between" v-for="(badge, additional_badge_index) in order.vendor_details.additional_badges" :key="order.vendor_details.included_badges.length + additional_badge_index">
                                                <p class="my-0">{{ badge.first_name }} {{ badge.last_name }}</p>
                                                <p class="my-0 ml-2">{{ badge.badge_id }}</p>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="oi-value ml-2">
                                        <div class="d-flex justify-content-between">
                                            <p class="my-0 font-weight-bold text-left">Price</p>
                                        </div>
                                        <div v-for="(badge, included_badge_price_index) in order.vendor_details.included_badges" :key="included_badge_price_index">
                                            <p class="m-0 text-left">Included</p>
                                        </div>
                                        <template v-if="order.vendor_details.additional_badges.length">
                                            <div v-for="(badge, additional_badge_price_index) in order.vendor_details.additional_badges" :key="order.vendor_details.included_badges.length + additional_badge_price_index">
                                                <p class="m-0 text-left">$199.00</p>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <template v-if="order.vendor_details.golfers.length">
                                    <div class="oi-detail">
                                        <p class="oi-key flex-grow-1">Golfers</p>
                                        <div class="oi-value ml-2">
                                            <div class="d-flex justify-content-between">
                                                <p class="my-0 font-weight-bold">Golfer Name</p>
                                            </div>
                                            <div class="d-flex justify-content-between" v-for="(golfer, golfer_index) in order.vendor_details.golfers" :key="golfer_index">
                                                <p class="my-0">{{ golfer.first_name }} {{ golfer.last_name }}</p>
                                            </div>
                                        </div>
                                        <div class="oi-value ml-2">
                                            <div class="d-flex justify-content-between">
                                                <p class="my-0 font-weight-bold text-left">Price</p>
                                            </div>
                                            <div v-for="(golfer, golfer_price_index) in order.vendor_details.golfers" :key="golfer_price_index">
                                                <p class="m-0 text-left">$75.00</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>

                            <template v-if="vendorRegistration.optional_offerings">
                                <div class="oi-detail">
                                    <p class="oi-key flex-shrink-0">Optional Offering(s):</p>
                                    <div>
										<div v-for="(optional_offering) in vendorRegistration.optional_offerings" class="oi-value ml-2 text-left">
											<p class="mt-0 font-weight-bold mb-1">{{ optional_offering.name }}</p>
											
											<p class="mt-0 mb-1 pb-1 border-bottom">{{ optional_offering.description }}</p>
											<template v-for="(input, index) in optional_offering.inputs">
												<p class="mt-0 font-weight-bold mb-1" v-for="(value) in input.additional_input_value">{{ input.additional_input_label }}: {{ value }}</p>
											
												<p class="my-0 text-right">
													{{ input.name }}(${{ parseFloat(input.price_per_unit).toFixed(2) }})
													<span class="fas mx-1 fa-times"></span>
													{{ input.quantity }}
													<span class="fas mx-1 fa-equals"></span>
													${{ parseFloat(input.line_item_total).toFixed(2) }}
												</p>
											</template>
										</div>
									</div>
                                </div>
                            </template>

                            <template v-if="vendorRegistration.sponsorship_levels">
                                <div class="oi-detail">
                                    <p class="oi-key flex-shrink-0">Sponsorship(s):</p>
                                    <div>
										<div v-for="(sponsorship_level) in vendorRegistration.sponsorship_levels" class="oi-value ml-2 text-left d-flex justify-content-between">
											<p class="my-0 mr-2 font-weight-bold">{{ sponsorship_level.level }}</p>
											<p class="m-0">${{ sponsorship_level.cost }}</p>
										</div>
									</div>
                                </div>
                            </template>

                            <div class="align-self-end d-flex flex-column align-items-stretch mt-2 border-top-2 border-wcag-grey">
                                <div class="oi-detail">
                                    <p class="oi-key">Subtotal:</p>
                                    <p class="oi-value ml-2">{{ `$${order.subtotal}` }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Discount:</p>
                                    <p class="oi-value ml-2">{{ order.discount ? `$${order.discount}` : '' }}</p>
                                </div>
								<div class="oi-detail" v-if="order.processing_fee > 0">
                                    <p class="oi-key">{{ processingFee }}% Processing Fee:</p>
                                    <p class="oi-value ml-2">${{ order.processing_fee }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Total:</p>
                                    <p class="oi-value ml-2">{{ `$${order.total}` }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
	</div>
</template>

<script>
	export default {
		props: [
			"company",
			"user",
			"isAfterBadgeCutoff",
			"packageInfo",
			"registration",
			"includedBadges",
			"additionalBadges",
			"golfers",
			"orders",
			"categories",
			"orderLineItems",
			"processingFee",
			"vendorRegistration"

		],
		data() {
			return {
				contactInfoModalId: "vendor-dashboard-edit-info-modal",
				userIdAndPasswordModalId: "vendor-dashboard-user-id-password-modal",
				profileImageModalId: "vendor-dashboard-profile-image-modal",
				badgesModalId: "vendor-dashboard-badges-modal",
				golfersModalId: "vendor-dashboard-golfers-modal",
				showContactInfo: false,
				showUserIdAndPasswordInfo: false,
				showPackageInfo: false,
				showBoothOrderForm: false,
				showAddonsAndOptions: false,
				showBadges: false,
				showOrderInfo: false,
				boothOptionsModalId: "vendor-dashboard-booth-options-modal"
			};
		},
		computed: {
			address() {
				return `${this.company.address_1 || ""} ${this.company.address_2 ||
					""} ${this.company.city || ""}, ${this.company.state_province ||
					""} ${this.company.postal_code || ""}`;
			}
		},
		methods: {
			getVendorOrderPackage(order) {
                return order.vendor_details.package_info.name;
            },
            getVendorOrderPackagePrice(order) {
                return `$${order.vendor_details.package_info.base_package_price}`;
            },
			toggleContactInfo() {
				this.showContactInfo = !this.showContactInfo;
			},
			toggleUserIdAndPasswordInfo() {
				this.showUserIdAndPasswordInfo = !this.showUserIdAndPasswordInfo;
			},
			togglePackageInformation() {
				this.showPackageInfo = !this.showPackageInfo;
			},
			toggleBoothOrderForm() {
				this.showBoothOrderForm = !this.showBoothOrderForm;
			},
			toggleAddonsAndOptions() {
				this.showAddonsAndOptions = !this.showAddonsAndOptions;
			},
			toggleBadges() {
				this.showBadges = !this.showBadges;
			},
			openEditContactModal() {
				$(`[data-js-id="${this.contactInfoModalId}"]`).modal("show");
			},
			closeEditContactModal() {
				$(`[data-js-id="${this.contactInfoModalId}"]`).modal("hide");
			},
			openBoothOptionsOrderFormModal() {
				$(`[data-js-id="${this.boothOptionsModalId}"]`).modal("show");
			},
			closeBoothOptionsOrderFormModal() {
				$(`[data-js-id="${this.boothOptionsModalId}"]`).modal("hide");
			},
			openEditPasswordModal() {
				$(`[data-js-id="${this.userIdAndPasswordModalId}"]`).modal("show");
			},
			closeEditPasswordModal() {
				$(`[data-js-id="${this.userIdAndPasswordModalId}"]`).modal("hide");
			},
			openProfileImageModal() {
				$(`[data-js-id="${this.profileImageModalId}"]`).modal("show");
			},
			closeProfileImageModal() {
				$(`[data-js-id="${this.profileImageModalId}"]`).modal("hide");
			},
			openBadgesModal() {
				$(`[data-js-id="${this.badgesModalId}"]`).modal("show");
			},
			closeBadgesModal() {
				$(`[data-js-id="${this.badgesModalId}"]`).modal("hide");
			},
			openGolfersModal() {
				$(`[data-js-id="${this.golfersModalId}"]`).modal("show");
			},
			closeGolfersModal() {
				$(`[data-js-id="${this.golfersModalId}"]`).modal("hide");
			},
			toggleOrderInfo() {
                this.showOrderInfo = !this.showOrderInfo;
            },
            ifUseElseNot(value) {
                return value ? value : '';
            },
		}
	};
</script>

<style>
</style>