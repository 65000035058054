//== Functionality for DataTable on list view ==//
export const dtDocumentLibraryListOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    order: [],

    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",

    columns: [
        // Name and categories
        {
            data: 'name_description',
            // Combine Title and category information into one cell
            render: function(data, type, row) {
                let name = row.name ? `<p class="m-0 text-truncate" title="${row.name}"><strong>${row.name}</strong></p>` : '';
                let description = row.description ? `<p class="m-0 text-truncate-multi" style="--ttmfs:16px;--ttmlh:1.25;--ttmlc:2;" title="${row.description}">${row.description}</p>` : '';

                return  '<div class="d-flex flex-column">' +
                            name +
                            description +
                        '</div>';
            }
        },
        // Categories
        {
            data: 'categories',
            render: function(data, type, row) {
                let html = 'N/A';
                let categoryList = '';
                if(data.length){
                    html = '<p class="m-0 text-truncate-multi" style="--ttmfs:16px;--ttmlh:1.25;--ttmlc:2;"';
                    data.forEach(function(category, index) {
                        categoryList += `${category.name}`;
                        categoryList += (index !== data.length - 1) ? ', ' : '';
                    });
                    html += ` title="${categoryList}">${categoryList}</p>`
                }
                return html;
            }
        },
        // Status
        {
            data: 'active',
            render: function (data, type, row) {
                return data ? '<p class="text-success m-0"><strong>Active</strong></p>' : '<p class="text-danger m-0"><strong>Inactive</strong></p>';
            }
        },
        //Last Modified
        {
            data: 'updated_at',
            render: function (data, type, row) {
                return data ? moment(data).format('MM/D/YYYY h:mm a') : 'N/A';
            }
        },
        // Options
        {
            data: 'options',
            render: function(data, type, row) {
                return '<div class="doc-library-options" data-id="' + row.id + '">' +
                                '<p class="btn btn-danger" data-js-id="doc-library-recycle">Recycle</p>' +
                                '<p class="btn btn-secondary-blue" data-js-id="doc-library-modify">Modify</p></div>';
            }
        }
    ],
    rowCallback: function (row, type, data) {
        let tbody = document.querySelector('#documents-list-datatable tbody');
        if(tbody){
            tbody.classList.add('dragulable');
            $(row).addClass('dl-item');
        }
    }
}