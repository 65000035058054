<template>
    <div class="modal-body pb-3">
        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you wish to recycle this?</p>

            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary" data-js-id="cancel-recycle-button" :disabled="formProcessing" @click.prevent="closeRecycleModal()">Cancel</button>
                <button type="button" class="btn btn-primary ml-2" data-js-id="confirm-recycle-button" :disabled="formProcessing" @click.prevent="confirmedRecycle()">Yes, please recycle</button>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'formProcessing',
            'documentRecycleModalId'
        ],
        methods: {
            confirmedRecycle(){
                this.$emit('recycleEvent');
            },
            closeRecycleModal(){
                const modalId = document.querySelector(`[data-js-id="${this.documentRecycleModalId}"]`);
                $(modalId).modal('hide');
            }
        }
    }
</script>