<template>
	<form data-js-id="cm-vendor-invite-form" novalidate>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="vendor_vgm_member_number">Vendor VGM Member Number</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Vendors VGM Member Number"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="vendor_vgm_member_number"
					id="vendor_vgm_member_number"
					title="Enter VGM Member Number"
					required
					data-msg="Please provide VGM Member Number"
					v-model="currentVendorInvite.vgm_member_number"
					@blur="validateVgmMemberNumber()"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="vendor_invite_email">Vendor Email</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Email that the invite will be sent to"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="email"
					name="vendor_invite_email"
					id="vendor_invite_email"
					title="Enter an email"
					required
					data-msg="Please provide an email"
					data-rule-email="true"
					data-msg-email="Please provide a valid email"
					v-model="currentVendorInvite.email"
				/>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="packages">Packages</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Package to offer to vendor"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<select
					class="form-control"
					name="packages"
					id="packages"
					title="Select a package"
					required
					data-msg="Select a package"
					v-model="currentVendorInvite.package"
				>
					<option value>-- Select a package --</option>
					<option
						v-for="(availablePackages, index) in packages"
						:key="index"
						:value="availablePackages.id"
					>{{ availablePackages.name }}</option>
				</select>
			</div>

			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="vendor_invite_name">Vendor Company Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Name of the vendors company"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="vendor_invite_name"
					id="vendor_invite_name"
					title="Enter company name"
					required
					data-msg="Please provide company name"
					v-model="currentVendorInvite.company_name"
				/>
			</div>
		</div>

		<div class="form-group justify-content-center has-label">
			<div class="custom-control custom-switch ml-0">
				<input
					type="checkbox"
					class="custom-control-input js-boolean-control module-config-input"
					id="allow_invoice"
					name="allow_invoice"
					v-model="vendorInvite.allow_invoice"
				/>
				<label class="custom-control-label" for="allow_invoice">Allow Vendor to invoice?</label>
			</div>
		</div>
	</form>
</template>

<script>
	export default {
		props: ["vendorInvite", "packages"],
		data() {
			return {
				formErrorMessage: "",
				formHasError: false
			};
		},
		computed: {
			currentVendorInvite() {
				return this.vendorInvite;
			},
			saveButton() {
				return document.querySelector('[data-js-id="vendor-invite-button"]');
			}
		},
		methods: {
			validateVgmMemberNumber() {
				const vm = this;
				const thisEvent = event;

				const vgmMemberNumber = event.target.value;

				this.saveButton.disabled = true;
				this.saveButton.innerText = 'Validating VGM Number...';

				axios
					.post(`/heartland-api/validate-vgm-number`, {
						accountNumber: vgmMemberNumber
					})
					.then(response => {
						const data = JSON.parse(response.data.message);

						vm.currentVendorInvite.company_name = data.companyName;
						vm.currentVendorInvite.ram = data.ram;

						this.saveButton.disabled = false;
						this.saveButton.innerText = 'Send Invite';
					})
					.catch(error => {
						logApplicationError(error);

						this.saveButton.disabled = false;
						this.saveButton.innerText = 'Send Invite';
					});
			}
		}
	};
</script>