<template>
    <div class="row">
        <div class="col-12 col-md-12">
            <div class="row">
                <div class="col form-group">
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="name">Category Name</label>
                    </div>
                    <input
                        class="form-control"
                        type="text"
                        name="name"
                        id="name"
                        title="Please enter name for the category"
                        autocomplete="off"
                        required
                        data-msg="Please enter a category name"
                        v-model="currentCategory.name"
                    />
                </div>
            </div>
            <div class="row">
                <div class="col form-group">
                    <div class="radio-legend d-flex justify-content-between align-items-center">
                        <p class="mr-3">Is This Category Active?<span class="required">*</span></p>
                        <a href="#" data-toggle="tooltip" data-placement="right" data-boundary="window" title="This will switch the Category to active or inactive"><span class="fas fa-question-circle text-info cursor-pointer"></span></a>
                    </div>
                    <fieldset>
                        <legend class="label sr-only">Is this Category Active?</legend>
                        <div class="form-check form-check-inline form-check-radio">
                            <label class="form-check-label" for="active_yes">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="active"
                                    id="active_yes"
                                    title="Show Category"
                                    value="1"
                                    required
                                    data-msg="Please choose an option"
                                    v-model="currentCategory.active"
                                >
                                <span class="form-check-sign"></span>
                                Yes
                            </label>
                        </div>
                        <div class="form-check form-check-inline form-check-radio">
                            <label class="form-check-label" for="active_no">
                                <input
                                    class="form-check-input"
                                    type="radio"
                                    name="active"
                                    id="active_no"
                                    title="Don't show Category"
                                    value="0"
                                    required
                                    data-msg="Please choose an option"
                                    v-model="currentCategory.active"
                                >
                                <span class="form-check-sign"></span>
                                No
                            </label>
                        </div>
                    </fieldset>
                </div>
            </div>
            <div class="row">
                <div class="col-sm form-group" v-if="allDocuments.length">
                    <p class="m-0 lh-xs">
                        <small>
                            <strong>Selected Document<span v-if="selectedDocument.length > 1">s</span>:</strong>
                            <template v-for="(value, index) in selectedDocument"> {{ getSelectedDocument(value, index) }} </template>
                        </small>
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="documents">Documents</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Use Ctrl + Click to select multiple documents"
                        >
                            <span class="fas fa-question-circle text-info cursor-pointer"></span>
                        </a>
                    </div>
                    <select
                        class="form-control flex-grow-1"
                        name="documents[]"
                        id="documents"
                        title="Select a Document"
                        multiple
                        v-model="selectedDocument"
                    >
                        <option
                            v-for="(document, index) in allDocuments"
                            :key="index"
                            :value="document.id"
                        >
                            {{ document.name }}
                        </option>
                    </select>
                </div>
                <div class="col-sm form-group" v-if="portalInstalled">
                    <p class="m-0 lh-xs" v-if="selectedPortalGroups.length">
                        <small>
                            <strong>Selected Group<span v-if="selectedPortalGroups.length > 1">s</span>:</strong>
                            <template v-for="(value, index) in selectedPortalGroups"> {{ getSelectedPortalGroup(value, index) }} </template>
                        </small>
                    </p>
                    <div class="d-flex justify-content-between align-items-center">
                        <label for="document_portal_groups">Portal Groups</label>
                        <a
                            href="#"
                            data-toggle="tooltip"
                            data-placement="right"
                            data-boundary="window"
                            title="Use Ctrl + Click to select multiple groups"
                        >
                            <span class="fas fa-question-circle text-info cursor-pointer"></span>
                        </a>
                    </div>
                    <select
                        class="form-control flex-grow-1"
                        name="portal_groups[]"
                        id="document_portal_groups"
                        title="Select a Portal Group"
                        multiple
                        v-model="selectedPortalGroups"
                    >
                        <option
                            v-for="(group, index) in portalGroups"
                            :key="index"
                            :value="group.id"
                        >
                            {{ group.name }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            "allDocuments",
            "portalGroups",
            "portalInstalled",
            "selectedCategory"
        ],
        data() {
			return {
                selectedDocument: [],
                selectedPortalGroups: [],
                currentCategory: this.selectedCategory,
			}
        },
        mounted(){
            if(this.currentCategory.documents){
                let savedDocuments = this.currentCategory.documents;
                savedDocuments.forEach(document => {
                    this.selectedDocument.push(document.id);
                });
            }

            if(this.currentCategory.portal_groups){
                let savedGroups = this.currentCategory.portal_groups;
                savedGroups.forEach(portalGroups => {
                    this.selectedPortalGroups.push(portalGroups.id);
                });
            }
        },
        methods: {
            documentNameFromId(value){
				let allDocuments = this.allDocuments;

				for(i = 0; i < allDocuments.length; ++i){
					if(allDocuments[i].id == value){
						return allDocuments[i].name;
						break;
					}
				}
			},
            getSelectedDocument(id, index){
                let array = this.selectedDocument;
                this.currentCategory.documents = array;
                return this.documentNameFromId(id) + (index !== array.length -1 ? ', ' : '');
            },
            groupNameFromId(value){
                let portalGroups = this.portalGroups;

                for(i = 0; i < portalGroups.length; ++i){
                    if(portalGroups[i].id == value){
                        return portalGroups[i].name;
                        break;
                    }
                }
            },
            getSelectedPortalGroup(id, index){
                let array = this.selectedPortalGroups;
                this.currentCategory.portal_groups = array;
                return this.groupNameFromId(id) + (index !== array.length -1 ? ', ' : '');
            }
        }
    }
</script>