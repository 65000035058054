<template>
	<vpanel-modal :modal-id="modalId" header-icon="car" modal-title="Add Driver Modal" modal-size="md">
		<div class="modal-body">
			<form data-js-id="add-driver-form" novalidate @submit.prevent="saveForm">
                <div class="alert alert-danger" v-if="formHasError" v-html="formErrorMessage"></div>
				<div class="form-group">
					<label for="name">Name <span class="required">*</span></label>
					<input
						class="form-control"
						type="text"
						name="name"
						id="name"
						title="Name"
						required
						data-msg="Please enter in the drivers name"
					/>
				</div>
				<div class="form-group">
					<label for="username">VGM Username <span class="required">*</span></label>
					<input
						class="form-control"
						type="text"
						name="username"
						id="username"
						title="VGM Username"
						required
						data-msg="Please enter in the username that the user would use to login to their computer"
					/>
				</div>
				<div class="form-group">
					<label for="email">Email <span class="required">*</span></label>
					<input
						class="form-control"
						type="email"
						name="email"
						id="email"
						title="Email"
						required
						data-msg="Please enter in the users email"
					/>
				</div>
                <div class="row">
                    <div class="col text-right">
                        <button class="btn btn-secondary" @click.prevent="closeModal()">Cancel</button>
    			        <button class="btn btn-primary" type="submit">Save Driver</button>
                    </div>
                </div>
			</form>
		</div>
		<div class="modal-footer">

		</div>
	</vpanel-modal>
</template>

<script>
	export default {
		props: ["modalId"],
		data() {
			return {
                formHasError: false,
                formErrorMessage: ""
			};
		},
		computed: {
			addDriverModal() {
				return document.querySelector(`[data-js-id="${this.modalId}"]`);
            },
            form() {
                return document.querySelector(`[data-js-id="add-driver-form"]`);
            }
		},
		methods: {
			closeModal() {
				$(this.addDriverModal).modal("hide");
			},
			saveForm() {
				const vm = this;
                const button = event.target.querySelector('button[type="submit"]');
                const form = this.form;

				this.formHasError = false;
				this.formErrorMessage = "";
				let originalButtonText = button.innerHTML;

				$(form).validate();

				if ($(form).valid()) {
					button.disabled = true;
					button.innerHTML = "Processing...";

					let formData = new FormData(this.form);

					axios
						.post("/vpanel/modules/driver-portal/add-driver", formData)
						.then(res => {
							window.location.reload();
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == "422") {
									const errors = error.response.data.errors;
									const errorMessage =
										error.response.data.message;

									vm.formHasError = true;

									let errorsUl = document.createElement("ul");

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}

									vm.formErrorMessage =
										errorMessage + errorsUl.outerHTML;
								} else {
									openApplicationErrorModal(error, "");
									console.error(error);
								}
							}

							button.disabled = false;
							button.innerHTML = originalButtonText;

							console.error(error);
						});
				}
			}
        },
        mounted() {
            $(this.addDriverModal).on('hidden.bs.modal', e => {
                this.form.reset();

                $(this.form).validate().resetForm();

                let formGroupDivs = this.form.querySelectorAll('.form-control');

                for (let index = 0; index < formGroupDivs.length; index++) {
                    const div = formGroupDivs[index];

                    div.classList.remove('valid', 'error');
                }
            });
        }
	};
</script>
