<template>
    <div class="modal-body">
        <form id="vendor-badges-form" novalidate>
            <div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>
            <input type="hidden" name="id" v-model="currentUser.id">

            <span class="text-uppercase text-primary-green font-weight-bold">Included Badges</span>
            <div class="row" v-for="(badge, index) in includedBadges" :key="index">
                <div class="form-group col-md">
                    <label :for="`badge_${badge.id}_fname`">First Name<span class="required" v-if="index == 0">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`badge_${badge.id}_fname`"
                        :id="`badge_${badge.id}_fname`"
                        title="First Name"
                        v-model="includedBadges[index].first_name"
                        :required="index == 0"
                    />
                </div>
                <div class="form-group col-md">
                    <label :for="`badge_${badge.id}_lname`">Last Name<span class="required" v-if="index == 0">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`badge_${badge.id}_lname`"
                        :id="`badge_${badge.id}_lname`"
                        title="Last Name"
                        v-model="includedBadges[index].last_name"
                        :required="index == 0"
                    />
                </div>
            </div>

            <span v-if="additionalBadges.length > 0" class="text-uppercase text-primary-green font-weight-bold">Additional Badges</span>
            <div class="row" v-for="(badge, index) in additionalBadges" :key="index">
                <div class="form-group col-md">
                    <label :for="`badge_${badge.id}_fname`">First Name</label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`badge_${badge.id}_fname`"
                        :id="`badge_${badge.id}_fname`"
                        title="First Name"
                        v-model="additionalBadges[index].first_name"
                    />
                </div>
                <div class="form-group col-md">
                    <label :for="`badge_${badge.id}_lname`">Last Name</label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`badge_${badge.id}_lname`"
                        :id="`badge_${badge.id}_lname`"
                        title="Last Name"
                        v-model="additionalBadges[index].last_name"
                    />
                </div>
            </div>

            <div class="d-flex justify-content-end m-n1">
                <button class="btn btn-outline-primary m-1" @click.prevent="closeModal()">Cancel</button>
                <button class="btn btn-primary m-1" data-js-id="badges-modal-save-change-button" :disabled="formProcessing" @click.prevent="saveForm()">Save My Changes</button>
            </div>
        </form>
    </div>
</template>

<script>
import { logApplicationError } from '../../../../../../core/global/functions';
	export default {
		props: [
            "user",
            "includedBadges",
			"additionalBadges",
            "closeModal",
        ],
		data() {
			return {
                currentUser: {...this.user},
                showErrorDiv: false,
                errorMessage: "",
                formProcessing: false,
                saveChangesButton: null,
			};
        },
        methods: {
            saveForm() {
                let includedBadges = this.includedBadges;
                let additionalBadges = this.additionalBadges;
                const formData = {id: this.currentUser.id, badges: [...includedBadges, ...additionalBadges]};
                const vm = this;

                this.formProcessing = true;
                this.saveChangesButton.innerText = 'Processing...';

                this.showErrorDiv = false;
                this.errorMessage = '';

                axios.post('/portal/dashboard/vendor/update-badge-info', formData)
                    .then(res => {
                        window.location.reload();
                    })
                    .catch(error => {
                        logApplicationError(error);
                        console.error(error);

                        if (error.response) {
                            if (error.response.status == "422") {
                                const errors = error.response.data.message;

                                let errorsUl = document.createElement('ul');
                                errorsUl.classList.add('list-unstyled');

                                for (error in errors) {
                                    errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                }

                                vm.showErrorDiv = true;
                                vm.errorMessage =  errorsUl.outerHTML;
                            } else {
                                vm.showErrorDiv = true;
                                vm.errorMessage = "There was an error updating your request, please call <a href=\"tel:877-484-6901\">877-484-6901</a> for assistance.";
                            }
                        }

                        vm.formProcessing = false;
                        vm.saveChangesButton.innerText = 'Save My Changes';

                        console.error(error);
                    });
            }
        },
        mounted() {
            this.saveChangesButton = document.querySelector('[data-js-id="badges-modal-save-change-button"]');
        }
	};
</script>

<style>
</style>