<template>
    <div>
        <progress :value="appData.current_step" max="6"></progress>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: "ArpRegistrationProgress",
        computed: {
            ...mapState("registration", ['appData'])
        }
    }
</script>