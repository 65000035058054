export const dtOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom: "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>" +
        "<'dt-body'tr>" +
        "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    order: [[2, "desc"]],
    columns: [
        {
            data: 'title_description',
            width: '60%',
            render: function(data, type, row) {
                return `<div class="p-1">
                            <p class="m-0 font-weight-bold lh-1 text-truncate" title="${row.title}">${row.title}</p>
                            <div class="mt-1 text-truncate" title="${row.description}">${row.description}</div>
                        </div>`
            }
        },
        {
            data: "speakers",
            width: '10%',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "audience_level",
            width: '15%',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "date_submitted",
            type: "date",
            width: '25%',
            render: function(data, type, row) {
                return data ? moment(data).format('MM/DD/YYYY h:mm A') : '-';
            }
        }
    ]
}