<template>
    <form class="d-flex flex-column" data-js-id="company-add-modify-form" novalidate>
        <input type="hidden" name="_token" :value="csrf" />
        <div class="row" v-if="formHasError">
            <div class="col">
                <div class="alert alert-danger">{{ formErrorMessage }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="company_name">Name</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company Name"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <input
                    class="form-control"
                    type="text"
                    name="company_name"
                    id="company_name"
                    title="Enter a name"
                    required
                    data-msg="Please provide a name"
                    v-model="currentCompany.name"
                />
            </div>
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="company_email">Email</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company Email"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <input
                    class="form-control"
                    type="text"
                    name="company_email"
                    id="company_email"
                    title="Enter a email"
                    required
                    data-msg="Please provide a email"
                    data-rule-email="true"
                    data-msg-email="Please provide a valid email address"
                    v-model="currentCompany.email"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="company_vgm_member_number">VGM Member Number</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company VGM Member Number"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <input
                    class="form-control"
                    type="text"
                    name="company_vgm_member_number"
                    id="company_vgm_member_number"
                    title="Enter a vgm member number"
                    v-model="currentCompany.vgm_member_number"
                    @blur="validateVgmMemberNumber()"
                />
            </div>
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="company_primary_industry_focus">Primary Industry Focus</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company Primary Industry Focus"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <select
                    class="form-control"
                    name="company_primary_industry_focus"
                    id="company_primary_industry_focus"
                    title="Primary Industry Focus"
                    v-model="currentCompany.primary_industry_focus"
                    required
                    data-msg="Please enter a primary industry focus"
                >
                    <option selected disabled hidden>-- Select Primary Industry Focus --</option>
                    <option value="No Focus">No Focus</option>
                    <option value="CRT / Mobility">CRT / Mobility</option>
                    <option value="Respiratory / Sleep">Respiratory / Sleep</option>
                    <option value="HME">HME</option>
                    <option value="Specialty (Women's Health, Wound Care, Retail)">Specialty (Women's Health, Wound Care, Retail)</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="company_address_1">Address 1</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company Address 1"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <input
                    class="form-control"
                    type="text"
                    name="company_address_1"
                    id="company_address_1"
                    title="Enter a company address"
                    required
                    data-msg="Please provide an address"
                    v-model="currentCompany.address_1"
                />
            </div>
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="company_address_2">Address 2</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company Address 2"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <input
                    class="form-control"
                    type="text"
                    name="company_address_2"
                    id="company_address_2"
                    title="Enter a company address"
                    v-model="currentCompany.address_2"
                />
            </div>
        </div>
        <div class="row">
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="company_city">City</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company City"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <input
                    class="form-control"
                    type="text"
                    name="company_city"
                    id="company_city"
                    title="Enter a company address"
                    required
                    data-msg="Please provide a city"
                    v-model="currentCompany.city"
                />
            </div>
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="company_state_province">State/Province</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company State/Province"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <input
                    class="form-control"
                    type="text"
                    name="company_state_province"
                    id="company_state_province"
                    title="Enter a company address"
                    v-model="currentCompany.state_province"
                    required
                    data-msg="Please provide a state or province"
                />
            </div>
            <div class="col-md form-group has-label">
                <div class="d-flex justify-content-between align-items-center">
                    <label class="required" for="company_postal_code">Postal Code</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Company Postal Code"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <input
                    class="form-control"
                    type="text"
                    name="company_postal_code"
                    id="company_postal_code"
                    title="Enter a company address"
                    v-model="currentCompany.postal_code"
                    required
                    data-msg="Please provide a postal code"
                />
            </div>
        </div>
    </form>
</template>

<script>
    import { mask } from "vue-the-mask";

    export default {
        directives: { mask },
        props: ["selectedCompany", "csrf"],
        data() {
            return {
                saveChangesButton: "Save Changes",
                formProcessing: false,
                formErrorMessage: "",
                formHasError: false,
                currentCompany: this.selectedCompany
            };
        },
        computed: {
            saveButton() {
                return document.querySelector('[data-js-id="company-save-changes-button"]');
            }
        },
        mounted() {},
        methods: {
            validateVgmMemberNumber() {
                const vm = this;
                const thisEvent = event;

                const vgmMemberNumber = event.target.value;

                this.saveButton.disabled = true;
                this.saveButton.innerText = "Validating VGM Number...";

                axios
                    .post(`/heartland-api/validate-vgm-number`, {
                        accountNumber: vgmMemberNumber
                    })
                    .then(response => {
                        const data = JSON.parse(response.data.message);

                        debugger;

                        vm.currentCompany.is_valid_vgm_member = data.isAMember == "Yes" ? true : false;
                        vm.currentCompany.is_platinum_vgm_member =data.isPlatinumMember == "Yes" ? true : false;

                        vm.saveButton.disabled = false;
                        vm.saveButton.innerText = "Save Changes";
                    })
                    .catch(error => {
                        logApplicationError(error);
                        console.error(error);

                        vm.saveButton.disabled = false;
                        vm.saveButton.innerText = "Save Changes";
                    });
            }
        }
    };
</script>
