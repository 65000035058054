<template>
    <div class="card my-2">
        <portal-dashboard-toggle-bar
            toggle-bar-text="My Courses"
            @click.native="toggleMyCoursesInfo()"
            :is-toggled="showMyCourses"
        ></portal-dashboard-toggle-bar>

        <div v-if="showMyCourses">
            <template v-if="Object.keys(favoriteCourses).length > 0">
                <div
                    class="card-body"
                    :class="{'border-top': index > 0}"
                    v-for="(course, index) in favoriteCourses"
                    :key="index"
                >
                    <div class="d-flex justify-content-between align-items-start">
                        <p class="m-0 text-uppercase font-weight-bold text-dark lh-sm">{{ course.title }}</p>
                        <div class="d-inline-flex align-items-center flex-shrink-0">
                            <div
                                class="mr-1"
                                :title="'View course details for ' + course.title"
                                @click="toggleDetails(index)"
                            ><span class="fas fa-info-circle cursor-pointer text-20 text-info"></span></div>
                            <div
                                class="mc-remove"
                                :title="'Remove ' + course.title + ' from my courses'"
                                :data-id="course.course_id"
                                @click.prevent="unfavorite()"
                            >
                                <span class="fas fa-times-circle cursor-pointer text-20 text-danger"></span>
                                <span class="fas fa-spinner fa-pulse text-20 text-dark"></span>
                            </div>
                        </div>
                    </div>
                    <div class="pt-1 mt-1 border-top" v-if="selectedDetailsArray.includes(index)">
                        <div v-html="course.description"></div>
                        <div class="d-flex flex-column border-top mt-1 pt-1">
                            <p class="lh-xs m-0"><small>Presented By: {{ course.speakers }}</small></p>
                            <p class="lh-xs m-0"><small>{{ course.course_start}} - {{ course.course_end }}</small></p>
                            <p class="lh-xs m-0"><small>{{ course.location }} - #{{ course.room_number }}</small></p>
                        </div>
                    </div>
                </div>
            </template>
            <div class="card-body" v-else>
                <p>You haven't selected any courses yet.</p>
                <a href="/agenda" class="btn btn-primary-green" title="Internal link to the agenda page to view courses">View Courses</a>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'favoriteCourses'
        ],
        data() {
            return {
                showMyCourses: false,
                selectedDetailsArray: []
            }
        },
        methods: {
            toggleMyCoursesInfo() {
                this.showMyCourses = !this.showMyCourses;
            },
            toggleDetails(index) {
                var selectedDetails = this.selectedDetailsArray.indexOf(index);
                if (selectedDetails !== -1) {
                    this.selectedDetailsArray.splice(selectedDetails, 1)
                } else {
                    this.selectedDetailsArray.push(index)
                }
            },
            unfavorite() {
                let target = event.target;
                const courseId = target.closest('[data-id]').dataset.id;

                target.closest('[data-id]').classList.add('processing');

                axios
                    .get("/portal/dashboard/attendee/unfavorite/" + courseId)
                    .then(res => {
                        target.closest('.card-body').classList.add('d-none');
                    })
                    .catch(error => {
                        logApplicationError(error);
                        console.error(error);
                    });
            },
        }
    }
</script>

<style lang="scss">
    .mc-remove {
        svg.fa-spinner { display: none; }

        &.processing {
            svg.fa-times-circle { display: none; }
            svg.fa-spinner { display: block; }
        }
    }
</style>