<template>
	<form
		class="flex-column py-2"
		:class="{'d-flex' : appData.showAdminInfoSection, 'd-none' : !appData.showAdminInfoSection}"
		@submit.prevent
		novalidate
		data-js-id="admin-info-form"
	>
		<div class="my-2">
			<p class="h5">Great! We're glad to have you join us for the next Heartland Conference!</p>
			<hr />
			<p
				class="m-0"
			>We need to collect some additional information from you, starting with your primary contact information and company information. Please be prepared to provide your information, including your company's VGM Member Number (if applicable). If you have any questions along the way, please feel free to reach out to us for assitance at {{ phoneNumber }}</p>
		</div>
		<slot v-if="!activeReservation.multipleUserRegistration"></slot>

		<div class="card my-2">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Please provide your contact information</p>
			</div>
			<div class="card-body">
				<div class="row">
					<!-- First Name -->
					<div class="form-group col-md">
						<label for="first_name">First Name<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							id="first_name"
							name="first_name"
							title="First Name"
							v-model="activeReservation.user.first_name"
							required
							data-msg="Please enter first name"
						/>
					</div>

					<!-- Last Name -->
					<div class="form-group col-md">
						<label for="last_name">Last Name<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							id="last_name"
							name="last_name"
							title="Last Name"
							v-model="activeReservation.user.last_name"
							required
							data-msg="Please enter last name"
						/>
					</div>

					<!-- Title -->
					<div class="form-group col-md-2">
						<label for="user_title">Title/Position</label>
						<input
							class="form-control"
							type="text"
							id="user_title"
							name="user_title"
							title="Title/Position"
							v-model="activeReservation.user.title"
						/>
					</div>
				</div>

				<div class="row">
					<!-- Email -->
					<div class="form-group col-md">
						<label for="email">Email<span class="required">*</span></label>
						<input
							class="form-control"
							type="email"
							name="email"
							id="email"
							title="Email"
							v-model="activeReservation.user.email"
							required
							data-rule-email="true"
							data-msg-email="Please supply a valid email address"
							data-msg="Please enter an email"
						/>
					</div>

					<!-- Phone -->
					<div class="form-group col-md">
						<label for="user_phone">Cell Phone<span class="required">*</span></label>
						<input
							class="form-control"
							type="tel"
							name="user_phone"
							id="user_phone"
							title="Cell Phone"
							v-mask="'(###) ###-####'"
							v-model="activeReservation.user.phone"
							required
							data-msg="Please enter a cell phone number"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="card my-2">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Company Address</p>
			</div>
			<div class="card-body">
				<!-- Company Address -->

				<div class="row">
					<div class="form-group col-md">
						<label for="company.address.name">Company Name<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							id="company.name"
							name="address.name"
							v-model="activeReservation.company.address.name"
							required
							data-msg="Please enter company name"
							maxlength="30"
							data-toggle="tooltip"
							data-placement="bottom"
							title="30 character limit"
						/>

					</div>

					<div class="form-group col-md">
						<label for="company.address.address_1">Address<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							name="address.address_1"
							id="company.address.address_1"
							title="Address"
							v-model="activeReservation.company.address.address_1"
							required
							data-msg="Please enter an address"
						/>
					</div>

					<div class="form-group col-md">
						<label for="company.address.address_2">Address 2</label>
						<input
							class="form-control"
							type="text"
							name="address.address_2"
							id="company.address.address_2"
							title="Address 2"
							v-model="activeReservation.company.address.address_2"
						/>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-md">
						<label for="company.address.city">City<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							name="address.city"
							id="company.address.city"
							title="City"
							v-model="activeReservation.company.address.city"
							required
							data-msg="Please enter a city"
						/>
					</div>

					<div class="form-group col-md">
						<label for="company.address.state_province">State/Province<span class="required">*</span></label>
						<select
							class="form-control"
							name="address.state_province"
							id="company.address.state_province"
							title="State/Province"
							v-model="activeReservation.company.address.state_province"
							required
							data-msg="Please enter a State/Province">
							<option v-for="(state, index) in states" :key="index" :value="state.value">{{state.name}}</option>
						</select>
					</div>

					<div class="form-group col-md">
						<label for="company.address.postal_code">Postal Code<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							name="address.postal_code"
							id="company.address.postal_code"
							title="Postal Code"
							v-model="activeReservation.company.address.postal_code"
							required
							data-msg="Please enter a postal code"
						/>
					</div>
				</div>
			</div>
		</div>

		<div class="card my-2">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Billing Address</p>
			</div>
			<div class="card-body">
				<!-- Billing Address-->
				<div class="row">
					<div class="form-group col-md m-0">
						<div class="custom-control custom-radio">
							<input
								class="custom-control-input"
								type="checkbox"
								id="same_address_as_company"
								checked
								@input="toggleBillingAddress()"
							/>
							<label class="custom-control-label" for="same_address_as_company">Same as company address</label>
						</div>
					</div>
				</div>

				<template v-if="showBillingAddress">
					<div class="row mt-2">
						<div class="form-group col-md">
							<label for="user_address_1">Address<span class="required">*</span></label>
							<input
								class="form-control"
								type="text"
								name="user_address_1"
								id="user_address_1"
								title="Address"
								v-model="activeReservation.user.address.address_1"
								required
								data-msg="Please enter an address"
							/>
						</div>

						<div class="form-group col-md">
							<label for="user_address_2">Address 2</label>
							<input
								class="form-control"
								type="text"
								name="user_address_2"
								id="user_address_2"
								title="Address 2"
								v-model="activeReservation.user.address.address_2"
							/>
						</div>
					</div>

					<div class="row">
						<div class="form-group col-md">
							<label for="user_city">City<span class="required">*</span></label>
							<input
								class="form-control"
								type="text"
								name="user_city"
								id="user_city"
								title="City"
								v-model="activeReservation.user.address.city"
								required
								data-msg="Please enter a city"
							/>
						</div>

						<div class="form-group col-md">
							<label for="user_state_province">State/Province<span class="required">*</span></label>
								<select
									class="form-control"
									type="text"
									name="user_state_province"
									id="user_state_province"
									title="State/Province"
									v-model="activeReservation.user.address.state_province"
									required
									data-msg="Please enter a State/Province"
								>
									<option v-for="(state, index) in states" :key="index" :value="state.value">{{ state.name }}</option>
								</select>
						</div>

						<div class="form-group col-md">
							<label for="user_postal_code">Postal Code<span class="required">*</span></label>
							<input
								class="form-control"
								type="text"
								name="user_postal_code"
								id="user_postal_code"
								title="Postal Code"
								v-model="activeReservation.user.address.postal_code"
								required
								data-msg="Please enter a postal code"
							/>
						</div>
					</div>
				</template>
			</div>
		</div>

		<div class="card my-2">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Other Info</p>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="form-group col-md">
						<label for="company.address.primary_industry_focus">Primary Industry Focus<span class="required">*</span></label>
						<select
							class="form-control"
							name="address.primary_industry_focus"
							id="company.address.primary_industry_focus"
							title="Primary Industry Focus"
							v-model="activeReservation.user.primary_industry_focus"
							required
							data-msg="Please enter a primary industry focus"
						>
							<option selected disabled hidden>-- Select Primary Industry Focus --</option>
							<option value="CRT / Mobility">CRT / Mobility</option>
                            <option value="Respiratory / Sleep">Respiratory / Sleep</option>
                            <option value="HME">HME</option>
                            <option value="Specialty (Women's Health, Wound Care, Retail)">Specialty (Women's Health, Wound Care, Retail)</option>
						</select>
					</div>

					<div class="col-md">
						<div class="row">
							<div class="form-group col-md d-flex flex-column">
								<p class="mb-1">VGM Member?<span class="required">*</span></p>
								<div class="custom-inline-radio-buttons">
									<fieldset>
										<legend class="sr-only">VGM Member?</legend>
										<div class="custom-control custom-radio custom-control-inline">
											<input
												class="custom-control-input"
												type="radio"
												title="VGM Member"
												name="is_vgm_member"
												value="1"
												id="is_vgm_member_yes"
												v-model.number="activeReservation.company.is_vgm_member"
												required
												data-msg="Please specify you are a VGM Member"
											/>
											<label class="custom-control-label" for="is_vgm_member_yes">Yes</label>
										</div>
										<div class="custom-control custom-radio custom-control-inline">
											<input
												class="custom-control-input"
												type="radio"
												title="VGM Member"
												name="is_vgm_member"
												value="0"
												id="is_vgm_member_no"
												v-model.number="activeReservation.company.is_vgm_member"
												required
												data-msg="Please specify you are a VGM Member"
											/>
											<label class="custom-control-label" for="is_vgm_member_no">No</label>
										</div>
									</fieldset>
								</div>
							</div>

							<div class="form-group col-md-8" v-if="activeReservation.company.is_vgm_member">
								<label for="company.address.vgm_member_number">VGM Member Number<span class="required" v-if="activeReservation.company.is_vgm_member">*</span></label>
								<input
									class="form-control"
									type="text"
									name="address.vgm_member_number"
									title="VGM Member Number"
									id="company.address.vgm_member_number"
									v-model="activeReservation.company.vgm_member_number"
									v-mask="vgmMemberMask"
									:required="activeReservation.company.is_vgm_member"
									data-msg="Please enter a VGM Member Number"
								/>
								<small style="margin-top: 0.5rem; display: block; line-height: 1.3; color: #767676;">Please include the leading alpha character in your VGM member number. Example V, U, etc.</small>
							</div>
						</div>
					</div>
				</div>

				<div class="row">
					<div class="form-group col-md">
						<label for="user_special_request">Special Requests</label>
						<textarea
							class="form-control"
							name="user_special_requests"
							id="user_special_requests"
							rows="3"
							v-model="activeReservation.user.special_requests"
						></textarea>
					</div>
				</div>
			</div>
		</div>

		<div class="d-flex justify-content-end my-2 mx-n1">
			<button class="btn btn-outline-primary mx-1" @click="goBack()" v-if="!appData.isValidatedSpeaker">Go Back and Edit</button>
			<button
				class="btn btn-primary mx-1"
				@click.prevent="goToNextStep()"
				:disabled="formIsProcessing"
				v-text="continueButtonText"
			></button>
		</div>
	</form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
	export default {
		props: ["phoneNumber", "currentReservation"],
		data() {
			return {
				continueButtonText: "Continue",
				formIsProcessing: false,
				showBillingAddress: false,
				vgmMemberMask: {
					mask: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCCC",
					tokens: {
						C: {
							pattern: /[0-9a-zA-Z]/,
							transform: v => v.toLocaleUpperCase()
						}
					}
				},
			};
		},
		computed: {
			adminForm() {
				return document.querySelector('[data-js-id="admin-info-form"]');
			},
			...mapState("registration", {
				appData: (state) => state.appData,
				activeReservation: (state) => state.activeReservation,
				states: (state) => state.states
			})
		},
		methods: {
			...mapActions("registration", ["setBillingAddress", "setVar", "setReservation", "removeResIndex", "setNestedResValue"]),
			goBack() {
				this.setVar({ob: "appData", variable: "showStartSection", value: true});
				let back = this.appData.current_step - 1;
				this.setVar({ob: 'appData', variable: 'current_step', value: back})

				this.setVar({ob: "appData", variable: "showAdminInfoSection", value: false});

				$("html, body").animate({ scrollTop: 0 }, "fast");
			},
			goToNextStep() {
				if (this.activeReservation.billingAddressIsSameAsCompanyAddress) {
					this.setBillingAddress();
				}

				if (this.activeReservation.user.is_attendee) {
					this.setReservation({index: 0, attribute: "attendee_information", value: this.activeReservation.user})
				}

				this.activeReservation.company.primary_industry_focus = this.activeReservation.reservations[0].attendee_information.primary_industry_focus;

				// Validate the form
				const form = this.$el;
				$(form).validate({
					errorPlacement: function(error, element) {
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.prependTo(element.parents("fieldset"));
						} else {
							error.insertAfter(element);
						}
					}
				});

				if ($(form).valid()) {
					this.continueButtonText = "Processing...";
					this.formIsProcessing = true;
					// todo figure out why url wont resolve
					this.validateVGMNumber();
					// for now skipping ahead remove this after we resolve url
					// This.continueToReservationInfo();
				} else {
					$("html, body").animate({ scrollTop: 0 }, "fast");
				}
			},
			// todo figure out why url wont resolve
			validateVGMNumber() {
				const vgmMemberNumber = this.activeReservation.company.vgm_member_number;

				if (this.activeReservation.company.is_vgm_member && vgmMemberNumber) {
					axios.post(`/heartland-api/validate-vgm-number`, {accountNumber: vgmMemberNumber})
						.then(res => {
							const data =JSON.parse(res.data.message);
							const result = res.data.result;

							if (result == "success") {
								let isMember =	data.isAMember == "Yes" ? true : false;

								this.setNestedResValue({attribute: "company", target: "is_valid_vgm_member", value: isMember});

								let isPlatinum = data.isPlatinumMember == "Yes" ? true : false;

								this.setNestedResValue({attribute: "company", target: "is_platinum_vgm_member", value: isPlatinum});

								let ram = data.ram

								this.setNestedResValue({attribute: "company", target: "ram", value: ram});

								this.continueToReservationInfo();

								$("html, body").animate({ scrollTop: 0 }, "fast");
							} else {
								logApplicationError(error);
								this.setNestedResValue({attribute: "company", target: "is_vgm_member", value: 0});
								this.setNestedResValue({attribute: "company", target: "is_valid_vgm_member", value: false});
								this.setNestedResValue({attribute: "company", target: "is_platinum_vgm_member", value: false});
							}
						})
						.catch(error => {
							logApplicationError(error);
							this.setNestedResValue({attribute: "company", target: "is_vgm_member", value: 0});
							this.setNestedResValue({attribute: "company", target: "is_valid_vgm_member", value: false});
							this.setNestedResValue({attribute: "company", target: "is_platinum_vgm_member", value: false});

							this.continueToReservationInfo();

							$("html, body").animate({ scrollTop: 0 }, "fast");
						});
				} else {
					this.setNestedResValue({attribute: "company", target: "is_vgm_member", value: 0});
					this.setNestedResValue({attribute: "company", target: "is_valid_vgm_member", value: false});
					this.setNestedResValue({attribute: "company", target: "is_platinum_vgm_member", value: false});
					this.continueToReservationInfo();
					$("html, body").animate({ scrollTop: 0 }, "fast");
				}
			},
			toggleBillingAddress() {
				this.showBillingAddress = !this.showBillingAddress;
				this.$store.dispatch({type: 'registration/setVar', ob: "activeReservation", variable: "billingAddressIsSameAsCompanyAddress", value: this.showBillingAddress});
				this.setBillingAddress();
			},
			continueToReservationInfo() {
				this.formIsProcessing = false;
				this.continueButtonText = "Continue";

				this.setVar({ob: "appData", variable: "showAdminInfoSection", value: false});

				if (this.activeReservation.user.is_attendee) {
					this.setReservation({index: 0, attribute: "attendee_information", value: this.activeReservation.user})
				}

				let next = this.appData.current_step + 1;
				this.setVar({ob: "appData", variable: "current_step", value: next});
				this.setVar({ob: "appData", variable: "showAttendeeReservationInformationSection", value: true});
			}
		}
	};
</script>