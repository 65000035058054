const body =            document.querySelector('body');
const headerNav =       document.querySelector('.header-nav');
const headerNavList =   document.querySelector('.hn-list');
const hnFamilies =      document.querySelectorAll('.hn-family');
const mobileMenuOpen =    document.querySelector('.js-open-mobile-menu');
const mobileMenuClose =   document.querySelector('.js-close-mobile-menu');

mobileMenuOpen.addEventListener('click', function() {
    openMobileMenu();
});

mobileMenuClose.addEventListener('click', function() {
    closeMobileMenu();
});

headerNav.addEventListener('click', function(event) {
    if (event.target.classList.contains('hn-family')) {
        let depth = event.target.getAttribute('data-depth');

        if (event.target.getAttribute('data-expanded') === 'true') {
            removeExpandedFromAll();
            addExpandedToParents(event, depth);
        } else {
            removeExpandedFromAll();
            addExpandedToSelfAndParents(event, depth);
        }
    }
})

for (i = 0; i < hnFamilies.length; ++i) {
    if (hnFamilies[i].querySelector('.hn-children')) {
        hnFamilies[i].setAttribute('data-has-children', 'true');
    }
}

removeExpandedFromAll = () => {
    for (i = 0; i < hnFamilies.length; ++i) {
        hnFamilies[i].removeAttribute('data-expanded');
    }
}

addExpandedToParents = (event, depth) => {
    let ancestorDepth = depth - 1;

    for  (i = 0; i <= ancestorDepth; ++i) {
        event.target.closest(`[data-depth="${i}"].hn-family`).setAttribute('data-expanded', 'true');
    }
}

addExpandedToSelfAndParents = (event, depth) => {
    for  (i = 0; i <= depth; ++i) {
        event.target.closest(`[data-depth="${i}"].hn-family`).setAttribute('data-expanded', 'true');
    }
}

openMobileMenu = () => {
    headerNavList.setAttribute('data-expanded', 'true');
    body.style.overflowY = 'hidden';
}

closeMobileMenu = () => {
    headerNavList.removeAttribute('data-expanded');
    body.removeAttribute('style');
}