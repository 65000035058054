<template>
	<div
        class="card-header bg-primary-green cursor-pointer text-white toggle-bar"
        :class="{'border-bottom-0': !isToggled}"
    >
		<p class="card-title d-flex justify-content-between align-items-center m-0">
            {{ toggleBarText }}
			<span :class="{'d-none': !isToggled}"><span class="fas fa-minus text-primary-green"></span></span>
			<span :class="{'d-none': isToggled}"><span class="fas fa-plus text-primary-green"></span></span>
        </p>
	</div>
</template>

<script>
export default {
	props: ["toggleBarText", "isToggled"]
};
</script>