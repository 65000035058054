<template>
	<form method="POST" data-js-id="driver-request-form" @submit.prevent="submitRequest()" novalidate>
        <div class="alert alert-danger" v-if="formHasError" v-html="formErrorMessage"></div>
		<template v-if="!formSubmissionSuccessful">
			<div class="row">
				<div class="form-group col">
					<label for="drf-attendee_name">
						Attendee Name
						<span class="required">*</span>
					</label>
					<input
						class="form-control"
						type="text"
						name="attendee_name"
						id="drf-attendee_name"
						title="label_text"
						required
						data-msg="Please provide your name"
					/>
				</div>
				<div class="form-group col">
					<label for="drf-phone">
						Phone
						<span class="required">*</span>
					</label>
					<input
						class="form-control"
						type="tel"
						name="phone"
						id="drf-phone"
						title="Phone"
						required
						v-mask="'(###) ###-####'"
						data-msg="Please provide a phone number that you can be reached at"
					/>
				</div>
				<div class="form-group col">
					<label for="drf-company_name">Company Name</label>
					<input class="form-control" type="tel" name="company_name" id="drf-company_name" title="Phone" />
				</div>
				<div class="form-group col">
					<label for="drf-number_of_requested_riders">
						Attendees
						<span class="required">*</span>
					</label>
					<input
						class="form-control"
						type="number"
						name="number_of_riders"
						id="drf-number_of_requested_riders"
						title="Attendees"
						required
						data-msg="Please select how many attendees will need a ride"
						data-msg-number="Please select a number"
						value="1"
					/>
				</div>
			</div>
			<div class="row">
				<div class="form-group col">
					<label for="drf-pick_up_time">
						Requested Pick Up Time
						<span class="required">*</span>
					</label>
					<input
						class="form-control"
						type="time"
						name="requested_pick_up_time"
						id="drf-pick_up_time"
						title="Pick Up Time"
						required
						data-msg="Please select your requested pick up time"
					/>
				</div>
				<div class="form-group col">
					<label for="drf-pick_up_date">
						Requested Pick Up Date
						<span class="required">*</span>
					</label>
					<input
						class="form-control"
						type="date"
						name="requested_pick_up_date"
						id="drf-pick_up_date"
						title="Requested Pick Up Date"
						required
						data-msg="Please enter your requested pick up date"
					/>
				</div>
				<div class="form-group col">
					<label for="drf-origin">
						Origin
						<span class="required">*</span>
					</label>
					<input
						class="form-control"
						type="text"
						name="origin"
						id="drf-origin"
						title="Origin"
						required
						data-msg="Please select where you like to be picked up at"
					/>
				</div>
				<div class="form-group col">
					<label for="drf-destination">
						Destination
						<span class="required">*</span>
					</label>
					<input
						class="form-control"
						type="text"
						name="destination"
						id="drf-destination"
						title="Destination"
						required
						data-msg="Please select where you would like to be dropped off at"
					/>
				</div>
			</div>
			<div class="row">
				<div class="col d-flex justify-content-end">
					<button class="btn btn-primary" type="submit">Submit</button>
				</div>
			</div>
		</template>

		<template v-else>
			<div
				class="alert alert-success"
			>We have received your request! We will reach out to you about your ride details shortly.</div>
		</template>
	</form>
</template>

<script>
	export default {
		data() {
			return {
                formSubmissionSuccessful: false,
                formHasError: false,
                formErrorMessage: ""
			};
		},
		computed: {
			form() {
				return document.querySelector('[data-js-id="driver-request-form"]');
			}
		},
		methods: {
			submitRequest() {
				const vm = this;
                const button = this.form.querySelector("button");

                this.formHasError = false;
                this.formHasError = "";

                let formData = new FormData(this.form);

				$(this.form).validate();

				if ($(this.form).valid()) {
					button.innerHTML = "Processing...";
					button.disabled = true;

					axios
						.post(
							"/driver-portal/submit-pick-up-request",
							formData
						)
						.then(res => {
							vm.formSubmissionSuccessful = true;
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == "422") {
									const errors = error.response.data.errors;
									const errorMessage =
										error.response.data.message;

									vm.formHasError = true;

									let errorsUl = document.createElement("ul");
									errorsUl.classList.add("list-unstyled");

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}

									vm.formErrorMessage =
										errorMessage + errorsUl.outerHTML;
								} else {
									logApplicationError(error);
									console.error(error);
								}
							}

							button.disabled = false;
							button.innerHTML = "Submit";

							console.error(error);
						});
				}
			}
		}
	};
</script>
