<template>
    <div class="card my-2">
        <div class="card-footer d-flex border-top-0 justify-content-end">
            <p class="m-0">Subtotal: <strong>${{ parseFloat(appData.subtotal).toFixed(2) }}</strong></p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData
        };
    }
};
</script>
