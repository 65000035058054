<template>
    <div>
        <small>To modify this optional offering, please go to Optional Offering</small>

        <div class="row">
            <div class="col-12">
                <h6 class="border-top-1 pt-1">{{ orderItems.offering_definition.name}}</h6>
            </div>

            <div class="col" v-for="(input, index) in orderItems.inputs" :key="`input_${index}`">
                {{ input.input_definition.name }}: {{ input.value }} @ ${{ parseFloat(input.input_definition.price_per_unit).toFixed(2) }} per unit
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["orderItems", "selectedOrder"],
        methods: {
            printOptions(selectedOptions) {
                let optionsArray = [];

                for (let index = 0; index < selectedOptions.length; index++) {
                    optionsArray.push(selectedOptions[index].name);
                }

                return optionsArray.join(',');
            }
        }
    };
</script>