export const dtOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    order: [[ 6, "desc" ]],
    columns: [
        {
            data: 'name',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        { data: "badge_id" },
        {
            data: "email",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
         },
        { data: "primary_industry_focus" },
        { data: "vgm_member_number" },
        { data: "ram" },
        {
            data: "created_at",
            type: "Date",
            render: function(data, type, row) {
                return moment(data).format('MM/DD/YYYY');
            }
        },
    ]
}
