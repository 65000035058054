<template>
	<div class="vp-tab-nav d-flex">
		<a
			href="#"
			class="nav-link"
			title="Conference Management"
			@click.prevent="changeActiveTab('cm')"
			:class="{'current': checkIfTabIsActive('cm')}"
		>Conference Management</a>
		<a
			href="#"
			class="nav-link"
			title="Promo Codes"
			@click.prevent="changeActiveTab('pc')"
			:class="{'current': checkIfTabIsActive('pc')}"
		>Promo Codes</a>
		<a
			href="#"
			title="Admins"
			class="nav-link"
			@click.prevent="changeActiveTab('admin')"
			:class="{'current': checkIfTabIsActive('admin')}"
		>Admins</a>
		<a
			href="#"
			class="nav-link"
			title="Attendees"
			@click.prevent="changeActiveTab('atn')"
			:class="{'current': checkIfTabIsActive('atn')}"
		>Attendees</a>
		<a
			href="#"
			title="Vendors"
			class="nav-link"
			@click.prevent="changeActiveTab('ven')"
			:class="{'current': checkIfTabIsActive('ven')}"
		>Vendors</a>
		<a
			href="#"
			title="Manage Vendor Packages"
			class="nav-link"
			@click.prevent="changeActiveTab('manage-ven-packages')"
			:class="{'current': checkIfTabIsActive('manage-ven-packages')}"
		>Manage Vendor Packages</a>
		<a
			href="#"
			class="nav-link"
			title="Sponsors"
			@click.prevent="changeActiveTab('sponsor')"
			:class="{'current': checkIfTabIsActive('sponsor')}"
		>Sponsors</a>
		<a
			href="#"
			class="nav-link"
			title="Speakers"
			@click.prevent="changeActiveTab('speakers')"
			:class="{'current': checkIfTabIsActive('speakers')}"
		>Speakers</a>
		<a
			href="#"
			title="Courses"
			class="nav-link"
			@click.prevent="changeActiveTab('courses')"
			:class="{'current': checkIfTabIsActive('courses')}"
		>Courses</a>
	</div>
</template>

<script>
	export default {
		props: ["appStore"],
		data() {
			return {
				store: this.appStore
			};
		},
		methods: {
			checkIfTabIsActive($tab) {
				return this.store.currentTab == $tab;
			},
			changeActiveTab($tab) {
				return (this.store.currentTab = $tab);
			}
		}
	};
</script>