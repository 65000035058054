export const dtOrderOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        { data: "order_number" },
        {
            data: "order_type",
            render: function(data, type, row) {
                return data ? `<p class="m-0" title="${data}">${data}</p>` : '-'
            }
        },
        {
            data: "username",
            width: "10%",
            render: function(data, type, row) {
                return data ? `<p class="m-0 text-truncate" title="${data}">${data}</p>` : '-'
            }
        },
        {
            data: "ram",
            render: function(data, type, row) {
                return data ? `<p class="m-0 text-truncate" title="${data}">${data}</p>` : '-'
            }
        },
        {
            data: "status",
            render: function(data, type, row) {
                return data ? `<p class="m-0" title="${data}">${data}</p>` : '-'
            }
        },
        { data: "total" },
        {
            data: "created_at",
            type: "date",
            render: function(data, type, row) {
                return moment(data).format('M/D/YYYY h:mm A');
            }
        },
        {
            data: "id",
            orderable: false,
            searchable: false,
            render: function(data, type, row) {
                let status = row.status ? row.status.toLocaleLowerCase() : '';

                let modifyStatues = ['approval', 'approved', 'partially refunded'];

                let modifyButton = modifyStatues.indexOf(status) !== -1 ? `<button class="btn btn-secondary my-1" data-js-id="order-modify">Modify Order</button>` : '';

                return  `<div class="admin-options" data-id="${row.id}">
                            <button class="btn btn-secondary-blue" data-js-id="order-show">View Details</button>
                            ${modifyButton}
                        </div>`;
            }
        }
    ]
}

