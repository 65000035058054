<template>
	<div class="d-flex flex-column cm-sponsor-section dt-section">
		<div class="row mb-2">
			<div class="col">
				<h3 class="mb-1">{{ currentConference.year }} Sponsors</h3>
			</div>
			<div class="col d-flex justify-content-end align-items-center">
				<button type="button" class="btn btn-secondary-blue mr-1" @click.prevent="openSponsorImportModal" v-if="previousYearSponsors">Import</button>
				<button type="button" class="btn btn-primary" @click.prevent="openSponsorAddModifyModal('add')">Add New Sponsor</button>
			</div>
		</div>

		<CmSponsorDatatable
			table-id="sponsor-datatable"
			:column-names="[
				'Company Name',
                'Sponsorship Level',
				'Status',
				'Options',
			]"
			:ajax-route="ajaxRoute"
			:selected-sponsor="currentSponsor"
		></CmSponsorDatatable>

        <CmSponsorAddModifyModal
            :show-loader="showLoader"
            :all-vendors="allVendors"
            :is-modal-open="isModalOpen"
            :action-is-add="actionIsAdd"
            :current-sponsor="currentSponsor"
            :sponsorship-levels="sponsorshipLevels"
            @close-sponsor-modal="closeSponsorAddModifyModal"
        />

        <CmSponsorImportModal
            :is-modal-open="isImportModalOpen"
            :current-conference="currentConference"
            :previous-year-sponsors="previousYearSponsors"
            @close-sponsor-import-modal="closeSponsorImportModal"
        />

        <CmSponsorConfirmDeleteModal
            :current-sponsor="currentSponsor"
            :is-modal-open="isDeleteModalOpen"
            @close-sponsor-delete-modal="closeSponsorDeleteModal"
        />

	</div>
</template>

<script>
	export default {
        name: 'CmSponsorSection',
		props: [
			"ajaxRoute",
			"allVendors",
            "sponsorshipLevels",
			"currentConference",
            "previousYearSponsors"
		],
		data() {
			return {
                showLoader: false,
                isModalOpen: false,
                actionIsAdd: false,
                currentSponsor: {
                    active: 1,
                    sort_order: '99999',
                    conference_uuid: this.currentConference.uuid
                },
                isDeleteModalOpen: false,
                isImportModalOpen: false,
			};
		},
		methods: {
            openSponsorAddModifyModal(action) {
                this.actionIsAdd = action == 'add';
                this.isModalOpen = true;
                this.showLoader  = action == 'modify';
            },
            closeSponsorAddModifyModal() {
                this.actionIsAdd = false;
                this.isModalOpen = false;

                this.setCurrentSponsor({
                    active: 1,
                    sort_order: '99999',
                    conference_uuid: this.currentConference.uuid
                });
            },
            openSponsorDeleteModal(action) {
                this.isDeleteModalOpen = true;
            },
            closeSponsorDeleteModal() {
                this.isDeleteModalOpen = false;
            },
            openSponsorImportModal() {
                this.isImportModalOpen = true;
            },
            closeSponsorImportModal() {
                this.isImportModalOpen = false;
            },
            setCurrentSponsor(sponsor) {
                this.showLoader     = false;
                this.currentSponsor = sponsor;
            }
		}
	};
</script>