<template>
    <div>
        <form method="POST" @submit.prevent="refundOrders()" novalidate>
            <div class="row">
                <div class="form-group col-3 mr-2">
                    <label for="refund_amount">Refund Amount</label>
                    <input
                        class="form-control"
                        id="refund_amount"
                        title="Amount to be refunded"
                        type="number"
                        required
                        min=".01"
                        :max="maxRefundable"
                        v-model="refundAmount"
                        data-rule-min="0.01"
                        data-msg-min="Please enter an amount higher than $.01"
                        :data-rule-max="maxRefundable"
                        :data-msg-max="`Please enter a value equal to or less than ${maxRefundable}`"
                    />
                </div>

                <div class="form-group col">
                    <label for="refund_comment">Comments</label>
                    <textarea
                        class="form-control"
                        type="text"
                        name="refund_comment"
                        id="refund_comment"
                        title="Comments"
                        v-model="comments"
                        cols="20"
                    ></textarea>
                </div>
            </div>

            <button class="btn btn-primary-blue" data-js-id="refund-button">Modify Order</button>
        </form>
    </div>
</template>

<script>
    export default {
        props: ["selectedOrder"],
        data() {
            return {
                showRefundOptions: false,
                refundIsProcessing: false,
                refundAmount: 0.0,
                comments: "",
                maxRefundable: this.selectedOrder.total.replace("$", "")
            };
        },
        computed: {
            refundModal() {
                return document.querySelector(
                    '[data-js-id="order-refund-warning-modal"]'
                );
            },
            refundButton() {
                return document.querySelector('[data-js-id="refund-button"]');
            }
        },
        methods: {
            refundOrders() {
                const vm = this;
                const thisEvent = event;
                let button = this.refundButton;

                $(thisEvent.target).validate();

                if ($(thisEvent.target).valid()) {
                    this.refundIsProcessing = true;

                    button.innerText = "Processing...";
                    button.disabled = true;

                    axios
                        .post("/vpanel/modules/order-management/refund", {
                            order_id: this.selectedOrder.id,
                            amount: this.refundAmount,
                            comments: this.comments
                        })
                        .then(res => {
                            button.innerText = "Modify Order";
                            button.disabled = false;

                            showNotification(res.data.message, res.data.result);

                            vm.maxRefundable = res.data.order.total;

                            vm.$parent.closeModifyOrder();
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == "422") {
                                    button.innerText = "Modify Order";
                                    button.disabled = false;
                                    showNotification(
                                        error.response.data.message,
                                        error.response.data.result
                                    );
                                } else {
                                    openApplicationErrorModal(error, "");
                                }
                            } else {
                                openApplicationErrorModal(error, "");
                                console.error(error);
                            }
                        });
                }
            }
        }
    };
</script>
