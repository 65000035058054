export const bindOptionalOfferingInputModelObject = (source, target) => {
    target.id = source.id
    target.name = source.name;
    target.description = source.description;
    target.inputs = [];

    source.inputs.forEach(input => {
        target.inputs.push({
            id: input.id,
            name: input.name,
            maximum_available: input.maximum_available,
            quantity: 0,
            price_per_unit: input.price_per_unit,
            additional_input_label: input.additional_input_label,
            additional_input_value: []
        })
    })
}

export const updateOptionalOfferingSubtotal = (context) => {
    let subtotal = 0;

    for (let index = 0; index < context.optional_offering.inputs.length; index++) {
        const input = context.optional_offering.inputs[index];

        if (input.line_item_total) {
            subtotal += parseFloat(input.line_item_total);
        }
    }

    context.subtotal = subtotal;
}

export default { bindOptionalOfferingInputModelObject, updateOptionalOfferingSubtotal }