<template>
	<div class="card-body">
		<div class="row">
			<div class="col">
				<div class="alert alert-info">Only files up to 8MB are allowed. If you required anything larger, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance. File types accepted include: Documents: (.ppt, .ppx, doc, .docx, .xls, .xlsx, pdf)</div>
			</div>
		</div>
		<div class="row" v-if="showErrorDiv">
			<div class="col">
				<div class="alert alert-danger" v-html="errorMessage"></div>
			</div>
		</div>
		<form action enctype="multipart/form-data" novalidate data-js-id="handout-form">
			<input type="hidden" name="course_id" id="course_id" v-model="courseId">
			<div class="row align-items-end">
				<div class="col-md form-group">
					<label for="display_name">Name of file</label>
					<input
						class="form-control"
						type="text"
						name="display_name"
						id="display_name"
						title="Enter name of file"
						required
						data-msg="Please provide the name of this file"
					/>
				</div>
				<div class="col-md form-group">
					<label for="course_handout">Choose Course Materials</label>
					<input
						class="form-control-file cursor-pointer"
						type="file"
						name="course_handout"
						id="course_handout"
						title="Upload course materials"
						required
						data-msg="Please provide course materials"
					/>
				</div>
			</div>
			<div class="d-flex w-100 justify-content-end">
				<button class="btn btn-primary-green upload-btn" @click.prevent="uploadCourseMaterials()">Upload</button>
			</div>
		</form>
	</div>
</template>

<script>
	export default {
		props: [ "courseId",  ],
		data() {
			return {
				showErrorDiv: false,
				errorMessage: ''
			}
		},
		computed: {
			formId() {
				return document.querySelector('[data-js-id="handout-form"]');
			},
			uploadBtn() {
				return document.querySelector('.upload-btn');
			}
		},
		methods: {
			uploadCourseMaterials() {
				const cm 	= this;
				const input = document.querySelector("#course_handout");
				const form	= cm.formId;

				let formData = new FormData(form);

				event.target.disabled  = true;
				event.target.innerHTML = "Processing...";

				if (cm.fileIsEmpty(input) || cm.checkFileType(input)) {
					cm.showErrorDiv = true;
					cm.errorMessage = "This is not an accepted file type";

					event.target.disabled  = false;
					event.target.innerHTML = "Upload";

					return false;
				}

				axios
					.post("/portal/dashboard/speaker/add-course-handout", formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						logApplicationError(error);
						console.error(error);

						cm.uploadBtn.disabled  = false;
						cm.uploadBtn.innerHTML = "Upload";

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								cm.showErrorDiv = true;
								cm.errorMessage = errorsUl.outerHTML;
							} else {
								cm.showErrorDiv = true;
								cm.errorMessage = 'There was an error updating your request.';
							}
						}
					});
			},
			fileIsEmpty(input) {
				if (input.files.length == 0) {
					return true;
				}
			},
			checkFileType(input) {
				let acceptedFileTypes = ["application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "application/pdf", "application/vnd.ms-powerpoint", "application/vnd.openxmlformats-officedocument.presentationml.presentation", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

				return !acceptedFileTypes.includes(input.files[0]["type"]);
			}
		}
	};
</script>