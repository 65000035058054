<template>
	<div>
        <div class="row">
                <div class="form-group col-md">
                    <label for="objective_1">Objective 1<span class="required">*</span></label>
                    <textarea
                        class="form-control"
                        name="objective_1"
                        id="objective_1"
                        title="Objective 1"
                        required
                        data-msg="Please provide objective 1"
                        v-model="selectedCourse.objective_1"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
                    <label for="objective_2">Objective 2</label>
                    <textarea
                        class="form-control"
                        name="objective_2"
                        id="objective_2"
                        title="Objective 2"
                        v-model="selectedCourse.objective_2"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
                    <label for="objective_3">Objective 3</label>
                    <textarea
                        class="form-control"
                        name="objective_3"
                        id="objective_3"
                        title="Objective 3"
                        v-model="selectedCourse.objective_3"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
                    <label for="objective_4">Objective 4</label>
                    <textarea
                        class="form-control"
                        name="objective_4"
                        id="objective_4"
                        title="Objective 4"
                        v-model="selectedCourse.objective_4"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	export default {
		props: ["selectedCourse"],
		computed: {
			currentCourse() {
				return this.selectedCourse;
			}
		}
	};
</script>