<template>
	<div>
		<div class="card mb-2">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Choose which conference options you prefer.</p>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="form-group col-md m-md-0">
						<p class="mb-1">
							Conference Pass Options
							<span class="required">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Conference Pass Options</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Conference Pass Options'].options"
									:key="index"
								>
									<input
										class="custom-control-input"
										type="radio"
										name="conference_pass"
										required
										data-msg="Please select a conference pass"
										@change="updateSubtotalConferencePassOption(getCurrentPrice(option))"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`Conference Pass Options`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}} - ${{ getCurrentPrice(option) }}</label>
								</div>
							</fieldset>
						</div>
					</div>

					<!-- Commented out because they might utilize this next year?
					<div class="form-group col-md border-md-left m-md-0" v-if="oneDayPassSelected">
						<p class="mb-1">
							Date Attending
							<span class="required" v-if="oneDayPassSelected">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Date Attending</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['1-Day Pass Date'].options"
									:key="index"
								>
									<input
										class="custom-control-input"
										type="radio"
										name="one_day_pass_date"
										:required="oneDayPassSelected"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`1-Day Pass Date`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}} {{ getCurrentPrice(option) ? `- $${getCurrentPrice(option)}` : '' }}</label>
								</div>
							</fieldset>
						</div>
					</div> -->

					<div class="form-group col-md border-md-left m-md-0" v-if="techTrainingPassSelected">
						<p class="mb-1">
							Tech Training Level
							<span class="required" v-if="techTrainingPassSelected">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Tech Training Level</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Tech Training Level'].options"
									:key="index"
								>
									<input
										class="custom-control-input"
										type="radio"
										name="tech_training_level"
										data-msg="Please select tech training level"
										:required="techTrainingPassSelected"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`Tech Training Level`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}}</label>
								</div>
							</fieldset>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Live At Home Training Sessions -->
		<div class="card mb-2" v-if="lahPassSelected">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Please select which training sessions you would like to attend.</p>
			</div>

			<div class="card-body">
				<div class="form-group m-md-0" v-if="displayLahSavaria">
					<p class="my-1 font-weight-bold">Monday, Sept. 13th</p>
					<div class="custom-inline-checkbox-buttons">
						<div
							class="custom-control custom-checkbox custom-control-inline"
						>
							<input
								class="custom-control-input"
								type="checkbox"
								name="lah_options[]"
								@change="updateLahOptions($event, lahSpanAmericaOption)"
								:title="lahSpanAmericaOption.option_name"
								:value="lahSpanAmericaOption.id"
								:id="`option_id_${lahSpanAmericaOption.id}-${attendeeUniqueIndex}`"
							/>
							<label
								class="custom-control-label"
								:for="`option_id_${lahSpanAmericaOption.id}-${attendeeUniqueIndex}`"
							>{{lahSpanAmericaOption.option_name}} - 8:00am to 5:00pm</label>
						</div>
					</div>
				</div>

				<div class="form-group m-md-0">
					<p class="my-1 font-weight-bold">Tuesday, Sept. 14th</p>
					<div class="custom-inline-checkbox-buttons">
						<div
							class="custom-control custom-checkbox custom-control-inline"
						>
							<input
								class="custom-control-input"
								type="checkbox"
								name="lah_options[]"
								@change="updateLahOptions($event, lahManufacturerCertForPathwayOption)"
								:title="lahManufacturerCertForPathwayOption.option_name"
								:value="lahManufacturerCertForPathwayOption.id"
								:id="`option_id_${lahManufacturerCertForPathwayOption.id}-${attendeeUniqueIndex}`"
							/>
							<label
								class="custom-control-label"
								:for="`option_id_${lahManufacturerCertForPathwayOption.id}-${attendeeUniqueIndex}`"
							>{{lahManufacturerCertForPathwayOption.option_name}} - 8:00am to 12:00pm</label>
						</div>
						<div
							class="custom-control custom-checkbox custom-control-inline"
						>
							<input
								class="custom-control-input"
								type="checkbox"
								name="lah_options[]"
								@change="updateLahOptions($event, lahManufacturerCertForPassportOption)"
								:title="lahManufacturerCertForPassportOption.option_name"
								:value="lahManufacturerCertForPassportOption.id"
								:id="`option_id_${lahManufacturerCertForPassportOption.id}-${attendeeUniqueIndex}`"
							/>
							<label
								class="custom-control-label"
								:for="`option_id_${lahManufacturerCertForPassportOption.id}-${attendeeUniqueIndex}`"
							>{{lahManufacturerCertForPassportOption.option_name}} - 1:00pm to 5:00pm</label>
						</div>
					</div>
				</div>

				<div class="form-group m-md-0" v-if="displayLahHarmarStairlift || displayLahHarmarHelix">
					<p class="my-1 font-weight-bold">Wednesday, Sept. 15th</p>
					<div class="custom-inline-checkbox-buttons">
						<div
							class="custom-control custom-checkbox custom-control-inline"
							v-if="displayLahHarmarStairlift"
						>
							<input
								class="custom-control-input"
								type="checkbox"
								name="lah_options[]"
								@change="updateLahOptions($event, lahEntrematicOption)"
								:title="lahEntrematicOption.option_name"
								:value="lahEntrematicOption.id"
								:id="`option_id_${lahEntrematicOption.id}-${attendeeUniqueIndex}`"
							/>
							<label
								class="custom-control-label"
								:for="`option_id_${lahEntrematicOption.id}-${attendeeUniqueIndex}`"
							>{{lahEntrematicOption.option_name}} - 8:00am to 10:00am</label>
						</div>

						<div
							class="custom-control custom-checkbox custom-control-inline"
							v-if="displayLahHarmarHelix"
						>
							<input
								class="custom-control-input"
								type="checkbox"
								name="lah_options[]"
								@change="updateLahOptions($event, lahHarmarHelixOption)"
								:title="lahHarmarHelixOption.option_name"
								:value="lahHarmarHelixOption.id"
								:id="`option_id_${lahHarmarHelixOption.id}-${attendeeUniqueIndex}`"
							/>
							<label
								class="custom-control-label"
								:for="`option_id_${lahHarmarHelixOption.id}-${attendeeUniqueIndex}`"
							>{{lahHarmarHelixOption.option_name}} - 10:00am to 3:00pm</label>
						</div>

						<div
							class="custom-control custom-checkbox custom-control-inline"
							v-if="displayLahHarmarHelix"
						>
							<input
								class="custom-control-input"
								type="checkbox"
								name="lah_options[]"
								@change="updateLahOptions($event, lahBestBathOption)"
								:title="lahBestBathOption.option_name"
								:value="lahBestBathOption.id"
								:id="`option_id_${lahBestBathOption.id}-${attendeeUniqueIndex}`"
							/>
							<label
								class="custom-control-label"
								:for="`option_id_${lahBestBathOption.id}-${attendeeUniqueIndex}`"
							>{{lahBestBathOption.option_name}} - 3:00pm to 5:00pm</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card">
			<div class="card-header">
				<p
					class="card-title font-weight-bold m-0"
				>What additional options would you like to include in your registration?</p>
			</div>
			<div class="card-body">
				<div class="form-group m-md-0">
					<p class="mb-1">Additional Options</p>
					<div class="custom-inline-checkbox-buttons">
						<div
							class="custom-control custom-checkbox custom-control-inline"
							v-for="(option, index) in reservationProps['Additional Options'].options"
							:key="index"
						>
							<input
								class="custom-control-input"
								type="checkbox"
								name="additional_options[]"
								@change="updateAdditionalOptions($event, option)"
								:title="option.option_name"
								:value="option.id"
								:id="`option_id_${option.id}-${option.name}`"
							/>
							<label
								class="custom-control-label"
								:for="`option_id_${option.id}-${option.name}`"
							>{{option.option_name}} {{ getCurrentPrice(option) ? `- $${getCurrentPrice(option)}` : '' }}</label>
						</div>
					</div>
				</div>

				<div class="mt-2 mb-0" v-if="showSpouseGuestName">
					<div class="form-group">
						<label for="spouse_guest_name">
							Spouse/Guest Name
							<span class="required" v-if="showSpouseGuestName">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							id="spouse_guest_name"
							name="spouse_guest_name"
							title="First Name"
							data-msg="Please enter spouse/guest name"
							:required="showSpouseGuestName"
							v-model="registration.reservation_options[reservationProps[`Spouse/Guest Name`].id]"
						/>
					</div>
				</div>
			</div>

			<div class="card-body border-top">
				<div class="d-flex flex-column flex-md-row mx-n3 my-n1">
					<div class="form-group px-3 my-1">
						<p class="mb-1">
							Golf Outing?
							<span class="required">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Golf Outing?</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Golf Outing'].options"
									:key="index"
								>
									<input
										class="custom-control-input"
										type="radio"
										name="golf_outing"
										required
										data-msg="Please select if attending golf outing"
										@change="updateSubtotalGolfOutingOption(getCurrentPrice(option))"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`Golf Outing`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}} {{ getCurrentPrice(option) ? `- $${getCurrentPrice(option)}` : '' }}</label>
								</div>
							</fieldset>
						</div>
					</div>

					<div class="form-group px-3 my-1 border-md-left" v-if="attendeeWantsGolfOuting">
						<p class="mb-1">
							Golf Club Rental?
							<span class="required" v-if="attendeeWantsGolfOuting">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Golf Club Rental?</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Golf Club Rental'].options"
									:key="index"
								>
									<input
										type="radio"
										class="custom-control-input"
										name="golf_club_rental"
										data-msg="Please specify if golf club rental is needed"
										:required="attendeeWantsGolfOuting"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`Golf Club Rental`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}}</label>
								</div>
							</fieldset>
						</div>
					</div>

					<div
						class="form-group px-3 my-1 border-md-left"
						v-if="attendeeWantsGolfOuting && attendeeWantsToRentGolfClubs"
					>
						<p class="mb-1">
							Golf Club Rental Options
							<span class="required" v-if="attendeeWantsToRentGolfClubs">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Golf Club Rental Options</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Golf Club Rental Options'].options"
									:key="index"
								>
									<input
										type="radio"
										class="custom-control-input"
										name="golf_club_rental_options"
										data-msg="Please select rental club option"
										:required="attendeeWantsToRentGolfClubs"
										:title="option.option_name"
										:id="`option_id_${option.id}-${option.name}`"
										:value="option.id"
										:checked="index == 0"
										v-model="registration.reservation_options[reservationProps[`Golf Club Rental Options`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}}</label>
								</div>
							</fieldset>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card mt-2" v-if="showPlatinumRegistrationOptions">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Platinum Member Options</p>
			</div>
			<div class="card-body alert alert-info text-14 rounded-0">
				As a VIP member, your company is eligible to attend the VGM Platinum/212 events at Heartland Conference. These events are reserved for the executive level staff from your company. Please check with your company prior to registering for the Platinum/212 portion of the conference. Heartland Conference will review all registrations for the Platinum/212 events for accuracy as seats are limited.
			</div>
			<div class="card-body">
				<div class="d-flex flex-column flex-md-row mx-n3 my-n1">
					<!-- Sunday Arrival? -->
					<div class="form-group px-3 my-1">
						<p class="mb-1">
							Sunday Arrival?
							<span class="required">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Sunday Arrival?</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Sunday Arrival'].options"
									:key="index"
								>
									<input
										class="custom-control-input"
										type="radio"
										name="sunday_arrival"
										required
										data-msg="Please select if arriving Sunday"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`Sunday Arrival`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}}</label>
								</div>
							</fieldset>
						</div>
					</div>

					<!-- Which event will you be attending? -->
					<div class="form-group px-3 my-1 border-md-left" v-if="attendeeArrivingSunday">
						<p class="mb-1">
							Which social event will you be attending? All events start at 1 PM.
							<span class="required">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Sunday Arrival Options?</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Sunday Arrival Options'].options"
									:key="index"
								>
									<input
										class="custom-control-input"
										type="radio"
										name="sunday_arrival_options"
										required
										data-msg="Please select an option"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`Sunday Arrival Options`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}}</label>
								</div>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div
				class="card-body border-top"
				v-if="attendeeArrivingSunday && attendeeWantsGolfOutingAtSunnySide"
			>
				<!-- If golf, then ask for golf options -->
				<div class="d-flex flex-column flex-md-row mx-n3 my-n1">
					<div class="form-group px-3 my-1">
						<p class="mb-1">
							Will you need rental clubs?
							<span class="required">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Golf at Sunnyside Club Rental?</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Golf at Sunnyside Club Rental'].options"
									:key="index"
								>
									<input
										class="custom-control-input"
										type="radio"
										name="golf_club_sunnyside_rental"
										required
										data-msg="Please select if you require rental clubs"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`Golf at Sunnyside Club Rental`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}}</label>
								</div>
							</fieldset>
						</div>
					</div>

					<div class="form-group px-3 my-1 border-md-left" v-if="attendeeNeedsClubRentalAtSunnyside">
						<p class="mb-1">
							Rental club options
							<span class="required">*</span>
						</p>
						<div class="custom-inline-radio-buttons">
							<fieldset>
								<legend class="sr-only">Golf at Sunnyside Club Rental Options</legend>
								<div
									class="custom-control custom-radio custom-control-inline"
									v-for="(option, index) in reservationProps['Golf at Sunnyside Club Rental Options'].options"
									:key="index"
								>
									<input
										type="radio"
										class="custom-control-input"
										name="golf_club_sunnyside_rental_options"
										required
										data-msg="Please select an option for rental club"
										:title="option.option_name"
										:value="option.id"
										:id="`option_id_${option.id}-${option.name}`"
										v-model="registration.reservation_options[reservationProps[`Golf at Sunnyside Club Rental Options`].id]"
									/>
									<label
										class="custom-control-label"
										:for="`option_id_${option.id}-${option.name}`"
									>{{option.option_name}}</label>
								</div>
							</fieldset>
						</div>
					</div>
				</div>
			</div>

			<div class="card-body border-top" v-if="attendeeArrivingSunday">
				<div class="d-flex">
					<div class="d-flex flex-column flex-md-row mx-n3 my-n1">
						<!-- If arriving Sunday, ask if joining dinner -->
						<div class="form-group px-3 my-1">
							<p class="mb-1">
								Will you be joining us for dinner?
								<span class="required">*</span>
							</p>
							<div class="custom-inline-radio-buttons">
								<fieldset>
									<legend class="sr-only">Group Dinner?</legend>
									<div
										class="custom-control custom-radio custom-control-inline"
										v-for="(option, index) in reservationProps['Group Dinner'].options"
										:key="index"
									>
										<input
											type="radio"
											class="custom-control-input"
											name="group_dinner"
											required
											data-msg="Please select if joining dinner"
											:title="option.option_name"
											:value="option.id"
											:id="`option_id_${option.id}-${option.name}`"
											v-model="registration.reservation_options[reservationProps[`Group Dinner`].id]"
										/>
										<label
											class="custom-control-label"
											:for="`option_id_${option.id}-${option.name}`"
										>{{option.option_name}}</label>
									</div>
								</fieldset>
							</div>
						</div>

						<!-- If joining for dinner, ask if they are bringing a guest -->
						<div
							class="form-group px-3 my-1 border-md-left"
							v-if="attendeeArrivingSunday && attendeeJoiningDinner"
						>
							<p class="mb-1">
								Will you be bringing a spouse or a guest?
								<span class="required">*</span>
							</p>
							<div class="custom-inline-radio-buttons">
								<fieldset>
									<legend class="sr-only">Spouse/Guest for Dinner?</legend>
									<div
										class="custom-control custom-radio custom-control-inline"
										v-for="(option, index) in reservationProps['Spouse/Guest for Dinner'].options"
										:key="index"
									>
										<input
											class="custom-control-input"
											type="radio"
											name="spouse_guest_for_dinner"
											required
											data-msg="Please select if arriving Sunday"
											:title="option.option_name"
											:value="option.id"
											:id="`option_id_${option.id}-${option.name}`"
											v-model="registration.reservation_options[reservationProps[`Spouse/Guest for Dinner`].id]"
										/>
										<label
											class="custom-control-label"
											:for="`option_id_${option.id}-${option.name}`"
										>{{option.option_name}}</label>
									</div>
								</fieldset>
							</div>
							<small>Please do not include co-workers as your dinner guests as they will be requested the same information at conference registration.</small>
						</div>
					</div>
				</div>

				<div class="form-group" v-if="attendeeJoiningDinner && attendeeBringingSpouseGuestForDinner">
					<label for="spouse_guest_name">
						Spouse/Guest Name
						<span class="required" v-if="showSpouseGuestName">*</span>
					</label>
					<input
						class="form-control"
						type="text"
						id="spouse_guest_name_dinner"
						name="spouse_guest_name_dinner"
						title="Spouse/Guest Name"
						data-msg="Please enter spouse/guest name"
						required
						v-model="registration.reservation_options[reservationProps[`Spouse/Guest Name for Dinner`].id]"
					/>
				</div>
			</div>

			<div class="card-body border-top">
				<!-- Ask if attending monday meeting -->
				<div class="form-group m-md-0">
					<p class="mb-1">
						Will you be attending the Monday meeting?
						<span class="required">*</span>
					</p>
					<div class="custom-inline-radio-buttons">
						<fieldset>
							<legend class="sr-only">Monday Meeting Attendance?</legend>
							<div
								class="custom-control custom-radio custom-control-inline"
								v-for="(option, index) in reservationProps['Monday Meeting Attendance'].options"
								:key="index"
							>
								<input
									type="radio"
									class="custom-control-input"
									name="monday_meeting"
									required
									data-msg="Please select if attending meeting"
									:title="option.option_name"
									:value="option.id"
									:id="`option_id_${option.id}-${option.name}`"
									v-model="registration.reservation_options[reservationProps[`Monday Meeting Attendance`].id]"
								/>
								<label
									class="custom-control-label"
									:for="`option_id_${option.id}-${option.name}`"
								>{{option.option_name}}</label>
							</div>
						</fieldset>
					</div>
					<small>Meeting is from 8am to 12pm</small>
				</div>
			</div>

			<div class="card-body border-top">
				<!-- Ask if need hotel -->
				<div class="form-group d-flex flex-column mb-0">
					<p class="mb-1">
						Do you need a hotel room?
						<span class="required">*</span>
					</p>
					<div class="custom-inline-radio-buttons">
						<fieldset>
							<legend class="sr-only">Hotel Room?</legend>
							<div
								class="custom-control custom-radio custom-control-inline"
								v-for="(option, index) in reservationProps['Hotel Room'].options"
								:key="index"
							>
								<input
									class="custom-control-input"
									type="radio"
									name="hotel_room"
									required
									data-msg="Please select if attendee needs hotel room"
									:title="option.option_name"
									:value="option.id"
									:id="`option_id_${option.id}-${option.name}`"
									v-model="registration.reservation_options[reservationProps[`Hotel Room`].id]"
								/>
								<label
									class="custom-control-label"
									:for="`option_id_${option.id}-${option.name}`"
								>{{option.option_name}}</label>
							</div>
						</fieldset>
					</div>
				</div>

				<!-- If yes, then ask them to provide hotel info -->
				<div class="form-group mt-2 mb-0" v-if="attendeeNeedsHotel">
					<p class="mb-1">
						Please provide the dates you need the room for and any other information regarding your hotel stay.
						<span class="required">*</span>
					</p>
					<label class="sr-only" for="hotel_room_reservation_notes">Hotel Room Reservation Notes</label>
					<textarea
						class="form-control"
						title="Hotel Room Reservation Notes"
						id="hotel_room_reservation_notes"
						name="hotel_room_reservation_notes"
						v-model="registration.reservation_options[reservationProps[`Hotel Room Reservation Notes`].id]"
					></textarea>
					<small>This request doesn't ensure your registration for a room. A team member will reach out via phone or email listed on this registration to discuss your request.</small>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["reservationProps", "company", "user", 'registration'],
		data() {
			return {
				showSpouseGuestName: false
			};
		},
		computed: {
			oneDayPassSelected() {
				const oneDayPassOption = this.findResPropByOptionName(
					"Conference Pass Options",
					"1-Day Pass"
				);

				return (
					this.registration.reservation_options[this.conferencePassId] ==
					oneDayPassOption.id
				);
			},
			lahPassSelected() {
				const lahPassOption = this.findResPropByOptionName(
					"Conference Pass Options",
					"Live At Home Training"
				);

				return (
					this.registration.reservation_options[
						this.conferencePassId
					] == lahPassOption.id
				);
			},
			techTrainingPassSelected() {
				const techTrainingPassOption = this.findResPropByOptionName(
					"Conference Pass Options",
					"Tech Training"
				);

				return (
					this.registration.reservation_options[this.conferencePassId] ==
					techTrainingPassOption.id
				);
			},
			attendeeWantsGolfOuting() {
				const yesOption = this.findResPropByOptionName("Golf Outing", "Yes");

				return (
					this.registration.reservation_options[this.golfOutingId] == yesOption.id
				);
			},
			attendeeWantsGolfOutingAtSunnySide() {
				const golfAtSunnySideOption = this.findResPropByOptionName(
					"Sunday Arrival Options",
					"Golf at Sunnyside"
				);

				return (
					this.registration.reservation_options[this.sundayArrivalOptionsId] ==
					golfAtSunnySideOption.id
				);
			},
			attendeeWantsToRentGolfClubs() {
				const yesOption = this.findResPropByOptionName("Golf Club Rental", "Yes");

				return (
					this.registration.reservation_options[this.golfClubRentalId] ==
					yesOption.id
				);
			},
			attendeeNeedsClubRentalAtSunnyside() {
				const yesOption = this.findResPropByOptionName(
					"Golf at Sunnyside Club Rental",
					"Yes"
				);

				return (
					this.registration.reservation_options[this.golfSunnySideClubRentalId] ==
					yesOption.id
				);
			},
			attendeeArrivingSunday() {
				const yesOption = this.findResPropByOptionName("Sunday Arrival", "Yes");

				return (
					this.registration.reservation_options[this.sundayArrivalId] ==
					yesOption.id
				);
			},
			attendeeJoiningDinner() {
				const yesOption = this.findResPropByOptionName("Group Dinner", "Yes");

				return (
					this.registration.reservation_options[this.groupDinnerId] == yesOption.id
				);
			},
			attendeeBringingSpouseGuestForDinner() {
				const yesOption = this.findResPropByOptionName(
					"Spouse/Guest for Dinner",
					"Yes"
				);

				return (
					this.registration.reservation_options[this.spouseGuestForDinnerId] ==
					yesOption.id
				);
			},
			attendeeNeedsHotel() {
				const yesOption = this.findResPropByOptionName("Hotel Room", "Yes");

				return (
					this.registration.reservation_options[this.hotelRoomId] == yesOption.id
				);
			},
			attendeeUniqueIndex() {
				return this.attendeeIndex || 0;
			},
			showForm() {
				return typeof this.show == "undefined" ? true : this.show;
			},
			conferencePassId() {
				return this.reservationProps[`Conference Pass Options`].id;
			},
			golfOutingId() {
				return this.reservationProps[`Golf Outing`].id;
			},
			golfClubRentalId() {
				return this.reservationProps[`Golf Club Rental`].id;
			},
			sundayArrivalId() {
				return this.reservationProps[`Sunday Arrival`].id;
			},
			sundayArrivalOptionsId() {
				return this.reservationProps[`Sunday Arrival Options`].id;
			},
			golfSunnySideClubRentalId() {
				return this.reservationProps[`Golf at Sunnyside Club Rental`].id;
			},
			spouseGuestId() {
				return this.findResPropByOptionName("Additional Options", "Spouse/Guest")
					.id;
			},
			groupDinnerId() {
				return this.reservationProps[`Group Dinner`].id;
			},
			spouseGuestForDinnerId() {
				return this.reservationProps[`Spouse/Guest for Dinner`].id;
			},
			hotelRoomId() {
				return this.reservationProps[`Hotel Room`].id;
			},
			additionalOptionsId() {
				return this.reservationProps[`Additional Options`].id;
			},
			showPlatinumRegistrationOptions() {
				return (
					this.company.is_valid_vgm_member && this.company.is_platinum_vgm_member
				);
			},
			lahOptionsId() {
				return this.reservationProps[`Live At Home Training Sessions`].id;
			},
			lahSpanAmericaOption() {
                return this.getLiveAtHomeOption('Span America Installation Training Class');
            },
            displayLahSavaria() {
                return this.lahSpanAmericaOption.id;
            },
            lahEntrematicOption() {
                return this.getLiveAtHomeOption('Entrematic - Enter a New Era of Door Automation');
            },
            displayLahHarmarStairlift() {
                return this.lahEntrematicOption.id;
            },
            lahHarmarHelixOption() {
                return this.getLiveAtHomeOption('Harmar Accessibility Products');
            },
            displayLahHarmarHelix() {
                return this.lahHarmarHelixOption.id;
            },
            lahBestBathOption() {
                return this.getLiveAtHomeOption('Best Bath');
            },
            displayLahBestBath() {
                return this.lahBestBathOption.id;
            },
            lahManufacturerCertForPathwayOption() {
                return this.getLiveAtHomeOption('Manufacturer Certification for Pathway 3G Ramp System by EZ-ACCESS');
            },
            lahManufacturerCertForPassportOption() {
                return this.getLiveAtHomeOption('Manufacturer Certification for Passport&trade; Vertical Platform Lift by EZ-ACCESS');
            }
		},
		methods: {
			updateSubtotalConferencePassOption(price) {
				this.registration.conference_pass_subtotal = parseFloat(price);
				this.updateReservationSubtotal();
			},
			updateSubtotalGolfOutingOption(price) {
				this.registration.golf_outing_subtotal = parseFloat(price);
				this.updateReservationSubtotal();
			},
			updateSubtotalSpouseGuest(price) {
				this.registration.spouse_guest_subtotal = parseFloat(price);

				this.updateReservationSubtotal();
			},
			updateReservationSubtotal() {
				let conferencePassSubtotal = this.registration.conference_pass_subtotal;
				let golfOutingSubtotal = this.registration.golf_outing_subtotal;
				let spouseGuestPrice = this.registration.spouse_guest_subtotal;

				this.registration.subtotal =
					parseFloat(conferencePassSubtotal) +
					parseFloat(spouseGuestPrice) +
					parseFloat(golfOutingSubtotal);
			},
			getCurrentPrice(option) {
				return this.company.is_valid_vgm_member &&
					this.company.is_vgm_member
					? parseFloat(option.vgm_member_price)
					: parseFloat(option.price);
			},
			findResPropByOptionName(propName, optionName) {
				const resProp = this.reservationProps[`${propName}`].options.filter(
					option => {
						return option.option_name == `${optionName}`;
					}
				);

				return { ...resProp[0] };
			},
			updateAdditionalOptions(event, option) {
				let additionalOptionsProp = this.registration.reservation_options[
					this.additionalOptionsId
				];
				
				// Check to see if the additional options object exists
				if (!additionalOptionsProp) {
					additionalOptionsProp = [];
				}

				const indexOfOption = additionalOptionsProp.indexOf(option.id);

				if (event.target.checked) {
					if (indexOfOption == -1) {
						additionalOptionsProp.push(option.id);

						if (option.id == this.spouseGuestId) {
							this.updateSubtotalSpouseGuest(option.price);
							this.showSpouseGuestName = true;
						}
					}
				} else {
					if (indexOfOption !== -1) {
						additionalOptionsProp.splice(indexOfOption, 1);

						if (option.id == this.spouseGuestId) {
							this.updateSubtotalSpouseGuest(0);
							this.showSpouseGuestName = false;
						}
					}
				}

				this.registration.reservation_options[
					this.additionalOptionsId
				] = additionalOptionsProp;
			},
			updateLahOptions(event, option) {
				let liveAtHomeOptions = this.registration.reservation_options[this.lahOptionsId];

				// Check to see if the additional options object exists
				if (!liveAtHomeOptions) {
					liveAtHomeOptions = [];
				}

				const indexOfOption = liveAtHomeOptions.indexOf(option.id);

				if (event.target.checked) {
					if (indexOfOption == -1) {
						liveAtHomeOptions.push(option.id);
					}
				} else {
					if (indexOfOption !== -1) {
						liveAtHomeOptions.splice(indexOfOption, 1);
					}
				}

				this.registration.reservation_options[
					this.lahOptionsId
				] = liveAtHomeOptions;
            },
			findResPropByOptionId(propName, optionId) {
				const resProp = this.reservationProps[`${propName}`].options.filter(
					option => {
						return option.id == optionId;
					}
				);

				return { ...resProp[0] };
			},
			getLiveAtHomeOption(name) {
                return this.findResPropByOptionName("Live At Home Training Sessions", name);
            }
		}
	};
</script>