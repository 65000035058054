<script>
import { dataTableMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtFaqCategoryListOptions } from "./options/dtFaqCategoryListOptions";

export default {
    mixins: [dataTableMixin],
    data() {
        return {
            moduleDatatableOptions: dtFaqCategoryListOptions
        }
    }
}
</script>

<style>

</style>