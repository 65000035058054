<template>
    <div>
        <div class="d-flex justify-content-end mb-1">
            <button class="btn btn-outline-primary mr-1" @click.prevent="exportVendorBoothReport">Vendor Booth Report</button>
            <button class="btn btn-primary" @click.prevent="exportReport">Export Full Report</button>
        </div>
        <vrvr-datatable
            table-id="order-report"
            :column-names='[
                "Company",
                "Email",
                "VGM #",
                "Package",
                "Invite Date",
                "Registration Date",
            ]'
            :ajax-route="ajaxRoute"
        ></vrvr-datatable>
    </div>
</template>

<script>
    export default {
        props: [
            'ajaxRoute'
        ],
        methods: {
            exportReport() {
                const vm = this;
                const thisEvent = event;

                let button = thisEvent.target;

                button.innerText = 'Processing...';
                button.disabled = true;

                axios({
                    url: "/vpanel/modules/reporting/export/vendor-report",
                    method: "GET",
                    responseType: "blob"
                })
                    .then(response => {
                        let blob = new Blob([response.data]);
                        let date = moment().format('L');

                        // For IE
                        if (typeof window.navigator.msSaveBlob !== "undefined") {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            // Creates a link with a url to the created blob, clicks that link, then removes it from the page
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${date}-vendor-report.xlsx`);
                            document.body.appendChild(link);
                            link.click();

                            link.remove();
                        }

                        button.innerText = "Export Full Report";
                        button.disabled = false;
                    })
                    .catch(error => {
                        openApplicationErrorModal(error, "");
                        console.error(error);
                        button.innerText = "Export Full Report";
                        button.disabled = false;
                    });
            },
            exportVendorBoothReport() {
                const button = event.target
                let originalText = button.innerText

                button.innerText = 'Processing...';
                button.disabled = true;

                axios({
                    url: "/vpanel/modules/reporting/export/vendor-booth-report",
                    method: "GET",
                    responseType: "blob"
                })
                    .then(response => {
                        let blob = new Blob([response.data]);
                        let date = moment().format('L');

                        // For IE
                        if (typeof window.navigator.msSaveBlob !== "undefined") {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            // Creates a link with a url to the created blob, clicks that link, then removes it from the page
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${date}-vendor-booth-report.pdf`);
                            document.body.appendChild(link);
                            link.click();

                            link.remove();
                        }
                    })
                    .catch(error => {
                        openApplicationErrorModal(error, "");
                        console.error(error);
                    })
                    .finally(_ => {
                        button.innerText = originalText;
                        button.disabled = false;
                    });
            }
        }
    };
</script>
