var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right"},[_c('button',{staticClass:"btn btn-primary mb-1",on:{"click":function($event){$event.preventDefault();return _vm.exportReport($event)}}},[_vm._v("Export Full Report")])]),_vm._v(" "),_c('vrbr-datatable',{attrs:{"table-id":"registration-report","column-names":[
                "Name",
                "Badge ID",
                "Email",
				"Industry Focus",
                "VGM #",
                "RAM",
                "Registration Date" ],"ajax-route":_vm.ajaxRoute}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }