<template>
    <div class="dl-list-item" :data-doctype="doc.file_type" :data-categories="docCategoryList">
        <div class="dl-li-details" @click="toggleDocDesc">
            <p class="dl-li-icon" :class="docTypeClass"><span class="fas" :class="docIconClass"></span></p>
            <p class="dl-li-name text-truncate">
                <a class="dl-li-link" :href="'/view-secure-document/' + doc.id" :title="'Downloads ' + doc.name + ' ' + doc.file_type + ' document'" target="_blank">{{ doc.name }}</a>
            </p>
            <div class="dl-li-toggle" :class="docDescClass" v-if="doc.display_description" title="Toggle Description"></div>
        </div>
        <p class="dl-li-description" v-if="doc.description && doc.display_description && showDocDescVal">{{ doc.description }}</p>
    </div>
</template>

<script>
    export default {
        props: ['doc'],
        data() {
            return {
                showDocDescVal: false
            }
        },
        computed: {
            docCategoryList() {
                let list = '';
                if(this.doc.categories.length){
                    this.doc.categories.forEach((category, index) => {
                        list = list + category.name;
                        list = index + 1 < this.doc.categories.length ? list + ', ' : list;
                    });
                }
                return list;
            },
            docDescClass() {
                return this.showDocDescVal ? 'expanded' : 'collapsed';
            },
            docIconClass() {
                return this.doc.file_type_icon !== 'fa-file-other' ? this.doc.file_type_icon : 'fa-file-alt';
            },
            docTypeClass() {
                return this.doc.file_type_class !== 'dl-li-other' ? this.doc.file_type_class : 'dl-li-alt';
            },
        },
        methods: {
            toggleDocDesc(event) {
                this.showDocDescVal = event.target.matches('.dl-li-link') ? this.showDocDescVal : !this.showDocDescVal;
            }
        }
    }
</script>

<style lang="scss">
    $pdf-color: var(--pdf);
    $excel-color: var(--excel);
    $word-color: var(--word);

    .dl-list {
        .dl-list-item {
            flex: 1 1 25%;
            min-width: 250px;
            margin: .5rem;
            border: 1px solid var(--primary-green);
            border-radius: 4px;
            overflow: hidden;

            .dl-li-details {
                display: flex;
                align-items: center;
                position: relative;
                background-color: var(--secondary-yellow);
            }

            .dl-li-description {
                margin: 0;
                padding: 8px;
                border-top: 1px solid var(--primary-green);
            }

            .dl-li-icon {
                flex: 0 0 40px;
                margin: 0;
                font-size: 28px;
                line-height: 1;
                height: 40px;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: center;

                &.dl-li-pdf { color: $pdf-color; }
                &.dl-li-excel { color: $excel-color; }
                &.dl-li-word { color: $word-color; }
            }

            .dl-li-name {
                margin: 0;
                padding: .5rem;
                flex-grow: 1;
                flex-shrink: 1;
            }

            .dl-li-toggle {
                margin: 0;
                padding: .5rem;
                flex: 0 0 40px;
                height: 40px;
                position: relative;
                cursor: pointer;

                &:before, &:after {
                    content: '';
                    height: 50%;
                    width: 4px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    background-color: #000;
                    transition: 200ms cubic-bezier(0, 0.55, 0.45, 1);
                }

                &:hover:before, &:hover:after { background-color: #333; }

                &.collapsed:before { transform: translate(-50%, -50%) rotate(0); }
                &.collapsed:after { transform: translate(-50%, -50%) rotate(90deg); }

                &.expanded:before { transform: translate(-50%, -50%) rotate(90deg); }
                &.expanded:after { transform: translate(-50%, -50%) rotate(-90deg); }
            }

            .dl-li-link { font-weight: bold; }
        }
    }
</style>