<template>
    <div>
        <div class="card my-2">
            <div class="card-header">
                <p class="card-title font-weight-bold m-0">Registration Confirmation</p>
            </div>
            <div class="card-body">
                <div class="card-group">
                    <!-- Company Info -->
                    <vendor-registration-company-info-card></vendor-registration-company-info-card>

                    <!-- Booth Package & Badge Info -->
                    <vendor-registration-booth-package-card></vendor-registration-booth-package-card>

                    <!-- Add Ons -->
                    <vendor-registration-add-on-card></vendor-registration-add-on-card>
                </div>

                <!-- Subtotal -->
                <vendor-registration-subtotal></vendor-registration-subtotal>

                <!-- Promo Code -->
                <vendor-registration-promo-code></vendor-registration-promo-code>

                <!-- Total -->
                <vendor-registration-total></vendor-registration-total>
            </div>
        </div>
        <div class="d-flex justify-content-end m-n1">
            <button class="btn btn-outline-primary m-1" @click.prevent="moveToLastStep()">Go Back &amp; Edit</button>
            <button
                class="btn btn-primary m-1"
                @click.prevent="moveToNextStep()"
                :disabled="registrationProcessing"
            >{{ appData.payment_button_text }}</button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                appData: this.$root.appData,
                registrationProcessing: false,
            };
        },
        methods: {
            moveToNextStep() {
                if (this.appData.total <= 0) {
                    this.registerVendor();
                } else {
                    $("html, body").animate({ scrollTop: 0 }, "fast");

                    this.appData.hideAllSections();
                    this.appData.sections.show_payment_form = 1;
                    this.appData.current_step = 5;
                }
            },
            moveToLastStep() {
                $("html, body").animate({ scrollTop: 0 }, "fast");
                this.appData.hideAllSections();
                this.appData.sections.show_booth_order_form = 1;
                this.appData.current_step = 5;
                // this.appData.hideAllSections();
                // this.appData.sections.show_add_on_form = 1;
                // this.appData.current_step = 3;
            },
            registerVendor() {
                this.registrationProcessing = true;
                this.appData.payment_button_text = "Processing...";
                this.registrationProcessError = false;

                let data = {
                    company: this.appData.company,
                    registration: this.appData.registration,
                    badges: this.appData.badges,
                    add_ons: this.appData.add_ons,
                    additional_badges: this.appData.additional_badges,
                    selected_sponsorship_ids: this.appData.selected_sponsorship_ids,
                    total: this.appData.total,
                    subtotal: this.appData.subtotal,
                    discount: this.appData.promo_discount_total,
                    promo_code: this.appData.promoCodeUsed,
                    additional_badge_price: this.appData.additional_badge_price,
                    golfers_price: this.appData.golfers_price,
                    selected_products: this.appData.selected_products
                };

                const vm = this;

                // Make request to process payment
                axios
                    .post("/heartland/portal/register-vendor", data)
                    .then(res => {
                        $("html, body").animate({ scrollTop: 0 }, "fast");
                        this.appData.hideAllSections();
                        this.appData.sections.show_no_payment_confirmation = 1;
                        this.appData.current_step = 6;

                        vm.registrationProcessing = false;
                        vm.appData.payment_button_text = "Complete Registration";
                    })
                    .catch(error => {
                        if (error.response) {
                            vm.appData.hideAllSections();

                            if (error.response.status == 422) {
                                const data = error.response.data;
                                const errors = data.errors;

                                let errorsUl = document.createElement("ul");

                                const section = data.message.section;

                                switch (section) {
                                    case "VendorRegistrationContactInformationForm":
                                        vm.appData.sections.show_contact_info_form = 1;
                                        vm.appData.sections.show_validation_error_section = 1;
                                        vm.appData.current_step = 1;

                                        errorsUl.classList.add("list-unstyled");

                                        for (error in errors) {
                                            errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                        }

                                        vm.appData.validation_message = errorsUl.outerHTML;

                                        break;
                                    case "VendorRegistrationPackageSelectionForm":
                                        vm.appData.sections.show_package_selection_form = 1;
                                        vm.appData.sections.show_validation_error_section = 1;
                                        vm.appData.current_step = 2;

                                        errorsUl.classList.add("list-unstyled");

                                        for (error in errors) {
                                            errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                        }

                                        vm.appData.validation_message = errorsUl.outerHTML;

                                        break;
                                    case "VendorRegistrationSponsorshipSelectionForm":
                                        vm.appData.sections.show_sponsorship_selection_form = 1;
                                        vm.appData.sections.show_validation_error_section = 1;
                                        vm.appData.current_step = 3;

                                        errorsUl.classList.add("list-unstyled");

                                        for (error in errors) {
                                            errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                        }

                                        vm.appData.validation_message = errorsUl.outerHTML;

                                        break;

                                    default:
                                        break;
                                }
                            } else if (error.response.status == 400) {
                                vm.appData.sections.show_registration_confirmation = 1;
                                vm.registrationProcessErrorMessage = error.response.data.message;
                                vm.fatalProcessError = false;
                                console.error(error);
                            } else {
                                vm.appData.sections.show_registration_confirmation = 1;
                                vm.fatalProcessError = true;
                                logApplicationError(error);
                                console.error(error);
                            }
                        }

                        $("html, body").animate({ scrollTop: 0 }, "fast");

                        this.registrationProcessError = true;

                        logApplicationError(error);
                        console.error(error);

                        this.registrationProcessing = false;
                        this.appData.payment_button_text = "Complete Registration";
                    });
            }
        }
    };
</script>
