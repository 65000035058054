<template>
	<div
		class="attendee-reservation-information-container"
		:class="{'d-none': !appData.showAttendeeReservationInformationSection}"
	>
		<slot></slot>

        <div class="alert alert-danger" v-if="appData.showAttendeeReservationInformationSectionError" v-html="appData.showAttendeeReservationInformationSectionErrorMsg"></div>

		<div class="card">
			<div class="card-header pb-0">
				<arp-attendee-reservation-tabs-nav></arp-attendee-reservation-tabs-nav>
			</div>
			<div class="card-body">
				<arp-attendee-reservation-tabs-content

				></arp-attendee-reservation-tabs-content>
			</div>
		</div>

		<arp-subtotal v-if="appData.showAttendeeReservationInformationSection"></arp-subtotal>

		<div class="d-flex justify-content-end mx-n1">
			<button class="btn btn-outline-primary mx-1" @click="goBack()">Go Back and Edit</button>
			<button class="btn btn-primary mx-1" @click="goToNextStep()">Continue</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	computed: {
		...mapState("registration", {
			appData: (state) => state.appData,
			activeReservation: (state) => state.activeReservation,
			states: (state) => state.states,
			reservationProperties: (state) => state.appData
		})
	},
	methods: {
		...mapActions("registration", ["setVar", "setReservation", "removeResIndex", "setSpeakerDiscount"]),
		goBack() {
			this.setVar({ob: 'appData', variable: 'showAttendeeReservationInformationSection', value: false})
			this.setVar({ob: 'appData', variable: 'showRegistrationConfirmation', value: false})
			this.setVar({ob: "appData", variable: "showAdminInfoSection", value: true});
			let back = this.appData.current_step -1;
			this.setVar({ob: 'appData', variable: 'current_step', value: back})
			$('html, body').animate({ scrollTop: 0 }, 'fast');
		},
		goToNextStep() {
			let allFormsValid = true;

			// validate the Attendee Information form by making sure that all required fields have correct values
			let form = document.querySelector(`[data-form-index="${this.appData.currentReservation}"]`);
			$(form).validate({
                ignore: []
            });

			// If Attendee Information form is invalid
			if (!$(form).valid()) {
				allFormsValid = false;
				$('html, body').animate({scrollTop: 0}, 'fast');
			}

			// validate the Reservation Options form 'reservation-form' by making sure that all required fields have correct values
			let forms = document.querySelectorAll(".reservation-form");

			for (let index = 0; index < forms.length; index++) {
				const form = forms[index];

				$(form).validate({
					ignore: []
				});

				let formIndex = form.dataset.formIndex;
				let tab = document.querySelector(
					`[data-js-id="attendee-tab-navs-${formIndex}"]`
				);

				if ($(form).valid()) {
					tab.querySelector(".text-success").classList.replace(
						"d-none",
						"d-flex"
					);
					tab.querySelector(".text-danger").classList.replace(
						"d-flex",
						"d-none"
					);
				} else {
					allFormsValid = false;

					tab.querySelector(".text-danger").classList.replace(
						"d-none",
						"d-flex"
					);

					tab.querySelector(".text-success").classList.replace(
						"d-flex",
						"d-none"
					);

					if (formIndex == this.appData.currentReservation) {
						form.classList.replace("d-none", "d-flex");
					}

					$('html, body').animate({ scrollTop: 0 }, 'fast');
				}
			}

			if (allFormsValid) {
				this.setVar({ob: 'appData', variable: 'showAttendeeReservationInformationSection', value: false});

				this.setVar({ob: 'appData', variable: 'showRegistrationConfirmation', value: true});

				this.setVar({ob: 'appData', variable: 'current_step', value: 4});

				$('html, body').animate({scrollTop: 0}, 'fast');
			}
		},
	}
}
</script>