<script>
import { dataTableMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtPortalUserOptions } from "./options/dtPortalUserOptions";

export default {
    mixins: [dataTableMixin],
    data() {
        return {
            moduleDatatableOptions: dtPortalUserOptions
        }
    }
}
</script>

<style>

</style>