<script>
import { modalMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/modalMixin";

export default {
    mixins: [modalMixin],
    props: [
        'selectedAttendee',
        'attendeeForm',
        'attendeeButton'
    ],
    mounted() {
        const vm = this;

        $('.nav-link').on('hide.bs.tab', evt => {
            const attendeeForm = document.querySelector(vm.attendeeForm);
            const attendeeButton = document.querySelector(vm.attendeeButton);

            // Validate the form as is
            $(attendeeForm).validate({
                errorPlacement: function(error, element) {
                    if (element.is(":radio")) {
                        error.addClass('d-flex');
                        error.prependTo(element.parents('fieldset'));
                    } else {
                        error.insertAfter(element);
                    }
                }
            });

            if (evt.relatedTarget.id !== 'attendee-reservation-tab') {
                attendeeButton.innerHTML = 'Next';
            } else {
                attendeeButton.innerHTML = 'Save Changes';
            }

            // Validate the data
            if (!$(attendeeForm).valid()) {
                return false;
            }
        });
    }
}
</script>