<template>
	<div class="cm-admin-section dt-section d-flex flex-column p-4 flex-grow-1">
		<div class="row mb-2">
			<div class="col">
				<h3 class="mb-1">{{ selectedConference.year }} Admins</h3>
			</div>
		</div>

		<cm-admin-datatable
			table-id="admin-datatable"
			:column-names="[
				'Name',
				'Username',
				'Company',
				'VGM #',
				'Reg. Date',
				'Options',
			]"
			:ajax-route="ajaxRoute"
			:selected-admin="selectedAdmin"
		></cm-admin-datatable>

		<cm-admin-modal
			modal-id="cm-admin-add-modify-modal"
			modal-title="Attendee"
			header-icon="user"
			modal-size="lg"
			:selected-admin="selectedAdmin"
			:modal-nav-options="[
        		{'name': 'Contact Information', 'active': true, 'id': 'admin-contact-tab', 'tabId': 'admin-contact'},
        		{'name': 'Company Information', 'active': false, 'id': 'admin-company-tab', 'tabId': 'admin-company'},
    		]"
			:admin-form="form"
			:admin-button="button"
		>
			<div class="modal-body">
				<div class="row" v-if="formHasError">
					<div class="col">
						<div class="alert alert-danger">{{ formErrorMessage }}</div>
					</div>
				</div>

				<div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showLoader">
					<p>Loading...</p>
					<progress></progress>
				</div>

				<form
					class="d-flex flex-column"
					v-if="showForm"
					data-js-id="cm-admin-add-modify-form"
					novalidate
				>
					<div class="tab-content">
						<div
							class="tab-pane fade show active"
							id="admin-contact"
							role="tab-panel"
							aria-labelledby="admin-contact-tab"
						>
							<cm-admin-form :selected-admin="selectedAdmin"></cm-admin-form>
						</div>
						<div
							class="tab-pane fade"
							id="admin-company"
							role="tabl-panel"
							aria-labelledby="admin-company-tab"
						>
							<cm-admin-company-form
								:selected-admin="selectedAdmin">
							</cm-admin-company-form>
						</div>
					</div>
				</form>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeAttendeeModal()">Cancel</p>
				<p
					class="btn btn-primary m-1"
					data-js-id="cm-admin-add-modify-button"
					:disabled="formProcessing"
					@click.prevent="saveAdmin($event)"
				>Next</p>
			</div>
		</cm-admin-modal>
	</div>
</template>

<script>
	export default {
		props: ["conferences", "currentConference", "ajaxRoute", "crsf"],
		data() {
			return {
				formProcessing: false,
				formErrorMessage: "",
				formHasError: false,
				showForm: true,
				showLoader: false,
				recyclePromoCodeId: null,
				form: '[data-js-id="cm-admin-add-modify-form"]',
				button: '[data-js-id="cm-admin-add-modify-button"]'
			};
		},
		computed: {
			selectedConference() {
				if (this.length) {
				} else {
					return this.currentConference;
				}
			},
			selectedAdmin() {
				return {
						id: null,
						badge_id: 1,
						reservation: {},
						company: {}
					}
			},
			modal() {
				return document.querySelector(
					'[data-js-id="cm-admin-add-modify-modal"]'
				);
			},
			datatable() {
				return document.querySelector("#admin-datatable");
			}
		},
		methods: {
			openAdminModal(type) {
				if (type == "new") {
					this.showModalForm();
				} else {
					this.hideModalForm();
				}

				$(this.modal).modal("show");
			},
			closeAttendeeModal() {
				$(this.modal).modal("hide");
			},
			saveAdmin(event) {
				const adminForm = document.querySelector(this.form);
				const adminButton = document.querySelector(this.button);

				$(adminForm).validate({
					errorPlacement: function(error, element) {
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.prependTo(element.parents("fieldset"));
						} else {
							error.insertAfter(element);
						}
					}
				});

				if ($(adminForm).valid()) {
					// Need to make sure we are on the reservation tab

					if (
						event.target.closest(".modal").querySelector(".nav-link.active").id ==
						"admin-reservation-tab"
					) {
						this.formProcessing = true;
						adminButton.innerText = "Processing...";

						axios
							.post(
								"/vpanel/modules/conference/admin/update",
								this.selectedAdmin
							)
							.then(res => {
								const data = res.data;
								showNotification(data.message, data.result);

								vm.formProcessing = false;
								adminButton.innerText = "Save Changes";

								$(vm.modal).modal("hide");

								$(vm.datatable)
									.DataTable()
									.ajax.reload();
							})
							.catch(error => {
								if (error.response) {
									if (error.response.status == "422") {
										vm.formHasError = true;
										vm.formErrorMessage = error.response.data.message;
									} else {
										openApplicationErrorModal(error, "");
									}
								}

								vm.formProcessing = false;
								adminButton.innerText = "Save Changes";

								console.error(error);
							});
					} else {
						$("#admin-reservation-tab").click();
					}
				}
			},
			showModalForm() {
				this.showForm = true;
				this.showLoader = false;
			},
			hideModalForm() {
				$("#admin-contact-tab").click();

				this.showForm = false;
				this.showLoader = true;
			}
		},
		errorCaptured(err, vm, info) {
			console.error(err);
		},
		mounted() {
			const vm = this;
			const adminForm = document.querySelector(this.form);

			$(this.modal).on("hidden.bs.modal", e => {
				if (adminForm) {
					adminForm.reset();
				}
			});
		}
	};
</script>