<template>
    <div class="card">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">Please choose your payment method.</p>
        </div>
        <div class="card-body">

            <p class="alert alert-info">
                Registration cancellations must be received via email to <a href="mailto:events@vgm.com" title="Opens an email conversation with events@vgm.com in your default mailing application">events@vgm.com</a> and will be subject to any payment processing fees already charged. Refund requests made before 30 days of event will receive a full refund minus processing fees.  Refund requests made within 30 days of the event will receive 50% refund, minus any processing fees.
            </p>

            <div class="row">
                <div class="col-md">
                    <div class="alert alert-danger" v-if="registrationProcessError">
                        <p class="m-0" v-if="fatalProcessError">
                            <strong>There was an error processing your registration</strong>
                            <br> Please try again later. If you need assistance, call
                            <a :href="`tel:+${appData.phone_number}`">{{appData.phone_number}}</a>
                        </p>
                        <p class="m-0" v-else>{{ registrationProcessErrorMessage }}</p>
                    </div>

                    <div v-if="showPaymentOptions">
                        <div class="form-group">
                            <p class="m-0">Payment Options<span class="required">*</span></p>
                            <div class="custom-inline-radio-buttons">
                                <fieldset>
                                    <legend class="sr-only">Payment Options</legend>
                                        <div
                                            class="custom-control custom-radio custom-control-inline"
                                        >
                                        <input
                                            class="custom-control-input"
                                            type="radio"
                                            name="payment_option"
                                            required
                                            data-msg="Please select a payment option"
                                            title="Pay with invoice"
                                            value="invoice"
                                            id="payment_option_invoice"
                                            v-model="appData.payment_method.method"
                                        />
                                        <label
                                            class="custom-control-label"
                                            for="payment_option_invoice"
                                        >Invoice</label>
                                    </div>
                                        <div
                                            class="custom-control custom-radio custom-control-inline"
                                        >
                                        <input
                                            class="custom-control-input"
                                            type="radio"
                                            name="payment_option"
                                            required
                                            data-msg="Please select a payment option"
                                            title="Pay with card"
                                            value="credit_card"
                                            id="payment_option_credit_card"
                                            v-model="appData.payment_method.method"
                                        />
                                        <label
                                            class="custom-control-label"
                                            for="payment_option_credit_card"
                                        >Credit Card</label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <form data-js-id="payment-form">
                        <template v-if="showCreditCardInputs">
                            <!-- Card Type -->

                            <div class="form-group">
                                <p class="m-0 required">Card Type<span class="required">*</span></p>
                                <div class="custom-inline-radio-buttons">
                                    <fieldset>
                                        <legend class="sr-only">Card Type</legend>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                class="custom-control-input"
                                                type="radio"
                                                name="card_type"
                                                required
                                                data-msg="Please select a card type"
                                                title="Card Type"
                                                value="Visa"
                                                id="card_type_visa"
                                                v-model="appData.payment_method.card_type"
                                            />
                                            <label class="custom-control-label" for="card_type_visa">Visa</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                class="custom-control-input"
                                                type="radio"
                                                name="card_type"
                                                required
                                                data-msg="Please select a card type"
                                                title="Card Type"
                                                value="Mastercard"
                                                id="card_type_mastercard"
                                                v-model="appData.payment_method.card_type"
                                            />
                                            <label class="custom-control-label" for="card_type_mastercard">Mastercard</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                class="custom-control-input"
                                                type="radio"
                                                name="card_type"
                                                required
                                                data-msg="Please select a card type"
                                                title="Card Type"
                                                value="Discover"
                                                id="card_type_discover"
                                                v-model="appData.payment_method.card_type"
                                            />
                                            <label class="custom-control-label" for="card_type_discover">Discover</label>
                                        </div>
                                        <div class="custom-control custom-radio custom-control-inline">
                                            <input
                                                class="custom-control-input"
                                                type="radio"
                                                name="card_type"
                                                required
                                                data-msg="Please select a card type"
                                                title="Card Type"
                                                value="American Express"
                                                id="card_type_amex"
                                                v-model="appData.payment_method.card_type"
                                            />
                                            <label class="custom-control-label" for="card_type_amex">American Express</label>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>

                            <div class="row">
                                <!-- Credit Card Number -->
                                <div class="form-group col-md">
                                    <label for="credit_card">Credit Card Number<span class="required">*</span></label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="credit_card"
                                        title="Credit Card Number"
                                        id="credit_card"
                                        v-model="appData.payment_method.credit_card_number"
                                        v-mask="'#### #### #### ####'"
                                        required
                                        data-msg="Please enter a Credit card Number"
                                        maxlength="19"
                                    />
                                </div>

                                <!-- CVV Code -->
                                <div class="form-group col-md">
                                    <label for="cvv_code">CVV Code<span class="required">*</span></label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="cvv_code"
                                        title="CVV Code"
                                        id="cvv_code"
                                        v-model="appData.payment_method.cvv_code"
                                        v-mask="'####'"
                                        required
                                        data-msg="Please enter a CVV Code"
                                        maxlength="4"
                                    />
                                </div>

                                <!-- Expiration Date -->
                                <div class="form-group col-md">
                                    <label for="expiration_date">Expiration Date<span class="required">*</span></label>
                                    <input
                                        class="form-control js-flatpickr"
                                        type="text"
                                        id="expiration_date"
                                        name="expiration_date"
                                        title="Expiration Date"
                                        v-model="appData.payment_method.expiration_date"
                                        required
                                        v-mask="'##/##'"
                                        data-msg-required="Please enter a date"
                                        data-msg-expdate="Please enter valid date"
                                        data-rule-expdate="true"
                                        placeholder="mm/yy"
                                        maxlength="5"
                                    />
                                </div>
                            </div>
                        </template>

                        <div class="row">
                            <!-- First Name -->
                            <div class="form-group col-md">
                                <label for="card_first_name">First Name<span class="required">*</span></label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="card_first_name"
                                    title="first name"
                                    id="card_first_name"
                                    v-model="appData.payment_method.first_name"
                                    required
                                    data-msg="Please enter a first name"
                                    maxlength="50"
                                />
                            </div>

                            <!-- Last Name -->
                            <div class="form-group col-md">
                                <label for="card_last_name">Last Name<span class="required">*</span></label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="card_last_name"
                                    title="ast name"
                                    id="card_last_name"
                                    v-model="appData.payment_method.last_name"
                                    required
                                    data-msg="Please enter a last name"
                                    maxlength="50"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md">
                                <label for="card_address_1">Address<span class="required">*</span></label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="card_address_1"
                                    title="Address"
                                    id="card_address_1"
                                    v-model="appData.payment_method.address.address_1"
                                    required
                                    data-msg="Please enter a Address"
                                    maxlength="30"
                                />
                            </div>
                            <div class="form-group col-md">
                                <label for="card_address_2">Address 2</label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="card_address_2"
                                    title="Address"
                                    id="card_address_2"
                                    v-model="appData.payment_method.address.address_2"
                                    maxlength="30"
                                />
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-group col-md">
                                <label for="card_city">City<span class="required">*</span></label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="card_city"
                                    title="City"
                                    id="card_city"
                                    v-model="appData.payment_method.address.city"
                                    required
                                    data-msg="Please enter a City"
                                    maxlength="30"
                                />
                            </div>
                            <div class="form-group col-md">
                                <label for="card_state_province">State/Province<span class="required">*</span></label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="card_state_province"
                                    id="card_state_province"
                                    title="State/Province"
                                    v-model="appData.payment_method.address.state_province"
                                    required
                                    data-msg="Please enter a State/Province"
                                    maxlength="2"
                                />
                            </div>

                            <div class="form-group col-md">
                                <label for="card_postal_code">Postal Code<span class="required">*</span></label>
                                <input
                                    class="form-control"
                                    type="text"
                                    name="card_postal_code"
                                    id="card_postal_code"
                                    title="Postal Code"
                                    v-model="appData.payment_method.address.postal_code"
                                    required
                                    data-msg="Please enter a postal code"
                                    maxlength="9"
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-md-4">
                    <div class="card">
                        <div class="card-header">
                            <p class="card-title font-weight-bold m-0">Your Order</p>
                        </div>
                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <p class="m-0"><strong>Subtotal</strong></p>
                                <p class="m-0">${{ parseFloat(appData.subtotal).toFixed(2) }}</p>
                            </div>
                            <div class="d-flex justify-content-between">
                                <p class="m-0"><strong>Discount</strong></p>
                                <p class="m-0">$-{{ parseFloat(appData.promo_discount_total).toFixed(2) }}</p>
                            </div>
                            <div class="d-flex justify-content-between" v-if="appData.payment_method.method == 'credit_card'">
                                <p class="m-0"><strong>{{ appData.processingFee }}% Processing Fee</strong></p>
                                <p class="m-0">${{ parseFloat(appData.total * (appData.processingFee / 100)).toFixed(2) }}</p>
                            </div>
                            <div class="d-flex justify-content-between border-top mt-1 pt-1">
                                <p class="m-0"><strong>Total</strong></p>
                                <p class="m-0" v-if="appData.payment_method.method == 'credit_card'">${{ parseFloat(appData.total * ((appData.processingFee / 100) + 1)).toFixed(2) }}</p>
                                <p class="m-0" v-else>${{ parseFloat(appData.total).toFixed(2) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mt-1 mx-n1">
                        <button
                            class="btn btn-outline-primary m-1 flex-grow-1"
                            @click="goBack()"
                        >Go Back and Edit</button>

                        <button
                            class="btn btn-primary m-1 flex-grow-1"
                            :disabled="paymentProcessing || !terms_agreement"
                            @click.prevent="processPayment()"
                        >{{ confirmButtonText }}</button>
                    </div>
                    <p class="small">A {{ appData.processingFee }}% credit card processing fee will be added to your order.</p>
                    <div class="custom-control custom-checkbox custom-control-inline">
                        <input
                            class="custom-control-input"
                            type="checkbox"
                            id="terms_agreement"
                            v-model="terms_agreement"
                        />
                        <label class="custom-control-label" for="terms_agreement">I agree to the terms and conditions below.</label>
                    </div>
                    
                    <small>
                        <p class="text-wcag-grey" style="line-height: 1.25; margin-top: .5rem;">
                            Once the project free is paid in full, any elements of text, graphics, photos, video, trademarks, or other content furnished for inclusion in projects or activities related to branding are owned by VGM Group Inc. VGM assumes permission from the rightful owner to use any code, scripts data, reports, or other content provided by VGM for inclusion in its website or other activities in conjunction with this branding project and any related materials, and will hold harmless, protect, and defend VGM from any claim or suit arising from the use of such work. VGM retains the right to display graphics and other creative content elements related to this branding project as examples of its work in its services portfolio. This agreement becomes effective only when signed by agents of your company and VGM Group Inc. Regardless of the place of signing of this agreement, you agree that for purposes of venue, this contract was entered into in Iowa and any dispute will litigated or arbitrated in Iowa. The agreement contained in the contract constitutes the sole agreement between your organization and VGM regarding all items included in this agreement. Cancellation:  Heartland Conference attendees and exhibitors may cancel their registration, booth fees, and sponsorships without penalty at any time. The full sum will be refunded minus any fees for processing; including credit card fees. Please contact <a href="mailto:events@vgm.com" title="Opens an email conversation with events@vgm.com in your default mailing application">events@vgm.com</a> for assistance. <label class="text-primary cursor-pointer d-inline" for="terms_agreement" style="text-decoration: underline;">I agree to all terms and conditions, cancellation policy, and to the fees outlined above</label>.
                        </p>
                    </small>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData,
            registrationProcessError: false,
            registrationProcessErrorMessage: "",
            confirmButtonText: "Confirm Purchase",
            paymentProcessing: false,
            fatalProcessError: false,
            terms_agreement: false
        };
    },
    computed: {
        showPaymentOptions() {
            return this.appData.registration.allow_invoice;
        },
        showCreditCardInputs() {
            return this.appData.payment_method.method == 'credit_card';
        },
        showInvoiceInput() {
            return this.appData.payment_method.method == 'invoice';
        }
    },
    methods: {
        processPayment() {
            let form = document.querySelector(`[data-js-id="payment-form"]`);

            if ($(form).valid()) {
                this.paymentProcessing = true;
                this.confirmButtonText = "Processing...";
                this.registrationProcessError = false;

                let data = {
                    company: this.appData.company,
                    payment_method: this.appData.payment_method,
                    registration: this.appData.registration,
                    badges: this.appData.badges,
                    add_ons: this.appData.add_ons,
                    additional_badges: this.appData.additional_badges,
                    selected_sponsorship_ids: this.appData.selected_sponsorship_ids,
                    total: this.appData.total,
                    subtotal: this.appData.subtotal,
                    discount: this.appData.promo_discount_total,
                    promo_code: this.appData.promoCodeUsed,
                    additional_badge_price: this.appData.additional_badge_price,
                    golfers_price: this.appData.golfers_price,
                    selected_products: this.appData.selected_products,
                };

                const vm = this;

                // Make request to process payment
                axios
                    .post("/heartland/portal/register-vendor", data)
                    .then(res => {
                        $('html, body').animate({ scrollTop: 0 }, 'fast');
                        this.appData.hideAllSections();
                        this.appData.sections.show_payment_confirmation = 1
                        this.appData.current_step = 6;

                        this.paymentProcessing = false;
                        this.confirmButtonText = "Confirm Purchase";
                    })
                    .catch(error => {
                        if (error.response) {
                            vm.appData.hideAllSections();

                            if (error.response.status == 422) {
                                const data = error.response.data;
                                const errors = data.errors;

                                let errorsUl = document.createElement("ul");

                                const section = data.message.section;

                                switch (section) {
                                    case "VendorRegistrationContactInformationForm":
                                        vm.appData.sections.show_contact_info_form = 1;
                                        vm.appData.sections.show_validation_error_section = 1;
                                        vm.appData.current_step = 1;

                                        errorsUl.classList.add("list-unstyled");

                                        for (error in errors) {
                                            errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                        }

                                        vm.appData.validation_message = errorsUl.outerHTML;

                                        break;
                                    case "VendorRegistrationPackageSelectionForm":
                                        vm.appData.sections.show_package_selection_form = 1;
                                        vm.appData.sections.show_validation_error_section = 1;
                                        vm.appData.current_step = 2;

                                        errorsUl.classList.add("list-unstyled");

                                        for (error in errors) {
                                            errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                        }

                                        vm.appData.validation_message = errorsUl.outerHTML;

                                        break;
                                        case "VendorRegistrationSponsorshipSelectionForm":
                                        vm.appData.sections.show_sponsorship_selection_form = 1;
                                        vm.appData.sections.show_validation_error_section = 1;
                                        vm.appData.current_step = 3;

                                        errorsUl.classList.add("list-unstyled");

                                        for (error in errors) {
                                            errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                        }

                                        vm.appData.validation_message = errorsUl.outerHTML;

                                        break;

                                    default:
                                        vm.appData.sections.show_payment_form = 1;
                                        vm.registrationProcessErrorMessage =
                                            error.response.data.message;
                                        break;
                                }
                            } else if (error.response.status == 400) {
                                vm.appData.sections.show_payment_form = 1;
                                vm.registrationProcessErrorMessage =
                                    error.response.data.message;
                                vm.fatalProcessError = false;
                                console.error(error);
                            } else {
                                vm.appData.sections.show_payment_form = 1;
                                vm.fatalProcessError = true;
                                logApplicationError(error);
                                console.error(error);
                            }
                        }

                        $('html, body').animate({ scrollTop: 0 }, 'fast');

                        this.registrationProcessError = true;

                        logApplicationError(error);
                        console.error(error);

                        this.paymentProcessing = false;
                        this.confirmButtonText = "Confirm Purchase";
                    });
            }
        },
        goBack() {
            this.appData.hideAllSections();
            this.appData.sections.show_registration_confirmation = 1;
            this.appData.current_step = 5;
            $('html, body').animate({ scrollTop: 0 }, 'fast');
        }
    },
    mounted() {
    }
};
</script>
