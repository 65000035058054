var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cm-admin-section dt-section d-flex flex-column p-4 flex-grow-1"},[_c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col"},[_c('h3',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.selectedConference.year)+" Admins")])])]),_vm._v(" "),_c('cm-admin-datatable',{attrs:{"table-id":"admin-datatable","column-names":[
				'Name',
				'Username',
				'Company',
				'VGM #',
				'Reg. Date',
				'Options' ],"ajax-route":_vm.ajaxRoute,"selected-admin":_vm.selectedAdmin}}),_vm._v(" "),_c('cm-admin-modal',{attrs:{"modal-id":"cm-admin-add-modify-modal","modal-title":"Attendee","header-icon":"user","modal-size":"lg","selected-admin":_vm.selectedAdmin,"modal-nav-options":[
        		{'name': 'Contact Information', 'active': true, 'id': 'admin-contact-tab', 'tabId': 'admin-contact'},
        		{'name': 'Company Information', 'active': false, 'id': 'admin-company-tab', 'tabId': 'admin-company'} ],"admin-form":_vm.form,"admin-button":_vm.button}},[_c('div',{staticClass:"modal-body"},[(_vm.formHasError)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"alert alert-danger"},[_vm._v(_vm._s(_vm.formErrorMessage))])])]):_vm._e(),_vm._v(" "),(_vm.showLoader)?_c('div',{staticClass:"vp-loader d-flex flex-column align-items-center text-center"},[_c('p',[_vm._v("Loading...")]),_vm._v(" "),_c('progress')]):_vm._e(),_vm._v(" "),(_vm.showForm)?_c('form',{staticClass:"d-flex flex-column",attrs:{"data-js-id":"cm-admin-add-modify-form","novalidate":""}},[_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"admin-contact","role":"tab-panel","aria-labelledby":"admin-contact-tab"}},[_c('cm-admin-form',{attrs:{"selected-admin":_vm.selectedAdmin}})],1),_vm._v(" "),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"admin-company","role":"tabl-panel","aria-labelledby":"admin-company-tab"}},[_c('cm-admin-company-form',{attrs:{"selected-admin":_vm.selectedAdmin}})],1)])]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"modal-footer d-flex justify-content-end mx-n1"},[_c('p',{staticClass:"btn btn-outline-primary m-1 cancel-button",on:{"click":function($event){return _vm.closeAttendeeModal()}}},[_vm._v("Cancel")]),_vm._v(" "),_c('p',{staticClass:"btn btn-primary m-1",attrs:{"data-js-id":"cm-admin-add-modify-button","disabled":_vm.formProcessing},on:{"click":function($event){$event.preventDefault();return _vm.saveAdmin($event)}}},[_vm._v("Next")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }