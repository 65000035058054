<template>
	<div
        class="card-body"
        :class="{'d-block': show, 'd-none' : !show}"
    >
		<form
            class="my-n2"
			@submit.prevent
            novalidate
			:data-form-index="`${attendeeIndex}`"
		>
            <div class="card my-2">
                <div class="card-header">
                    <p class="card-title font-weight-bold m-0">Please provide attendee contact information</p>
                </div>
                <div class="card-body">
                    <div class="row">
                        <!-- First Name -->
                        <div class="form-group col">
                            <label :for="`attendee_first_name_${attendeeIndex}`">First Name<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                name="attendee_first_name"
                                title="First Name"
                                required
                                data-msg="Please enter first name"
                                :id="`attendee_first_name_${attendeeIndex}`"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.first_name"
                            />
                        </div>

                        <!-- Last Name -->
                        <div class="form-group col">
                            <label :for="`attendee_last_name_${attendeeIndex}`">Last Name<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                name="attendee_last_name"
                                title="Last Name"
                                required
                                data-msg="Please enter last name"
                                :id="`attendee_last_name_${attendeeIndex}`"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.last_name"
                            />
                        </div>

                        <!-- Title -->
                        <div class="form-group col-md-2">
                            <label :for="`attendee_title_name_${attendeeIndex}`">Title/Position</label>
                            <input
                                class="form-control"
                                type="text"
                                name="attendee_title_name"
                                title="Title/Position"
                                :id="`attendee_title_name_${attendeeIndex}`"
                                data-msg="Please enter title/position"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.title"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <!-- Email -->
                        <div class="form-group col">
                            <label :for="`attendee_email_${attendeeIndex}`">Email<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="email"
                                name="attendee_email"
                                title="Email"
                                required
                                data-rule-email="true"
                                data-msg-email="Please supply a valid email address"
                                data-msg="Please enter an email"
                                :id="`attendee_email_${attendeeIndex}`"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.email"
                            />
                        </div>

                        <!-- Phone -->
                        <div class="form-group col">
                            <label :for="`attendee_phone_${attendeeIndex}`">Cell Phone<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="tel"
                                name="attendee_phone"
                                title="Cell Phone"
                                required
                                data-msg="Please enter a cell phone number"
                                :id="`attendee_phone_${attendeeIndex}`"
                                v-mask="'(###) ###-####'"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.phone"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card my-2">
                <div class="card-header">
                    <p class="card-title font-weight-bold m-0">Address</p>
                </div>
                <div class="card-body">
                    <div class="row">
                        <!-- Address 1 -->
                        <div class="form-group col">
                            <label :for="`attendee_address_1_${attendeeIndex}`">Address<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                name="attendee_address_1"
                                title="Address"
                                required
                                data-msg="Please enter an address"
                                :id="`attendee_address_1_${attendeeIndex}`"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.address.address_1"
                            />
                        </div>

                        <!-- Address 2 -->
                        <div class="form-group col">
                            <label :for="`attendee_address_2_${attendeeIndex}`">Address 2</label>
                            <input
                                class="form-control"
                                type="text"
                                name="attendee_address_2"
                                title="Address 2"
                                :id="`attendee_address_2_${attendeeIndex}`"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.address.address_2"
                            />
                        </div>
                    </div>

                    <div class="row">
                        <!-- City -->
                        <div class="form-group col">
                            <label :for="`attendee_city_${attendeeIndex}`">City<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                name="attendee_city"
                                title="City"
                                required
                                :id="`attendee_city_${attendeeIndex}`"
                                data-msg="Please enter a city"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.address.city"
                            />
                        </div>

                        <!-- State/Province -->
                        <div class="form-group col">
                            <label :for="`attendee_state_province_${attendeeIndex}`">State/Province<span class="required">*</span></label>
                            <select
							    class="form-control"
						        name="attendee_state_province"
                                :id="`attendee_state_province_${attendeeIndex}`"
                                title="State/Province"
                                required
                                data-msg="Please enter a State/Province"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.address.state_province"
                                >
							<option v-for="(state, index) in states" :key="index" :value="state.value">{{state.name}}</option>
						</select>
                        </div>

                        <!-- Postal Code -->
                        <div class="form-group col">
                            <label :for="`attendee_postal_code_${attendeeIndex}`">Postal Code<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                name="attendee_postal_code"
                                title="Postal Code"
                                required
                                data-msg="Please enter a postal code"
                                :id="`attendee_postal_code_${attendeeIndex}`"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.address.postal_code"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="card my-2">
                <div class="card-header">
                    <p class="card-title font-weight-bold m-0">Other Info</p>
                </div>
                <div class="card-body">
                    <div class="row">
                        <!-- DOB -->
                        <div class="form-group col">
                            <label :for="`attendee_date_of_birth_${attendeeIndex}`">Date of Birth</label>
                            <input
                                class="form-control js-flatpickr-date-of-birth"
                                type="text"
                                name="attendee_date_of_birth"
                                title="Date of Birth"
                                :id="`attendee_date_of_birth_${attendeeIndex}`"
                                v-mask="'##/##/####'"
                                data-msg-dob="Please enter valid date"
                                data-rule-dob="false"
                                placeholder="mm/dd/yyyy"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.date_of_birth"
                            />
                        </div>

                        <!-- First Time Attending -->
                        <div class="form-group col-md d-flex flex-column">
								<p class="mb-1">First Time Attendee?<span class="required">*</span></p>
								<div class="custom-inline-radio-buttons">
									<fieldset>
										<legend class="sr-only">First Time Attendee?</legend>
										<div class="custom-control custom-radio custom-control-inline">
											<input
												class="custom-control-input"
												type="radio"
												title="First time attendee"
												name="first_time_attendee"
												:value="1"
												:id="`first_time_attendee_yes__${attendeeIndex}`"
												required
												data-msg="Please specify if you are a first time attendee"
                                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.first_time_attendee"
											/>
											<label class="custom-control-label" :for="`first_time_attendee_yes__${attendeeIndex}`">Yes</label>
										</div>
										<div class="custom-control custom-radio custom-control-inline">
											<input
												class="custom-control-input"
												type="radio"
												title="First time attendee"
												name="first_time_attendee"
												:value="0"
												:id="`first_time_attendee_no_${attendeeIndex}`"
                                                required
                                                data-msg="Please specify if you are a first time attendee"
                                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.first_time_attendee"
											/>
											<label class="custom-control-label" :for="`first_time_attendee_no_${attendeeIndex}`">No</label>
										</div>
									</fieldset>
								</div>
							</div>
                    </div>
                   <!-- primary_industry_focus -->
                     <div class="row">
                            <div class="form-group col-md">
                            <label for="address.primary_industry_focus">Primary Industry Focus<span class="required">*</span></label>
                            <select
                                class="form-control"
                                name="address.primary_industry_focus"
                                :id="`address.primary_industry_focus${attendeeIndex}`"
                                title="Primary Industry Focus"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.primary_industry_focus"
                                required
                                data-msg="Please enter a primary industry focus"
                            >
                                <option selected disabled hidden>-- Select Primary Industry Focus --</option>
                                <option value="CRT / Mobility">CRT / Mobility</option>
                                <option value="Respiratory / Sleep">Respiratory / Sleep</option>
                                <option value="HME">HME</option>
                                <option value="Specialty (Women's Health, Wound Care, Retail)">Specialty (Women's Health, Wound Care, Retail)</option>
                            </select>
                        </div>
                   </div>
                    <div class="row">
                        <!-- Special Requests -->
                        <div class="form-group col">
                            <label :for="`attendee_special_request_${attendeeIndex}`">Special Requests</label>
                            <textarea
                                class="form-control"
                                name="attendee_special_request"
                                rows="3"
                                :id="`attendee_special_request_${attendeeIndex}`"
                                v-model="activeReservation.reservations[attendeeIndex].attendee_information.special_requests"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
		</form>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	props: [
		"phoneNumber",
		"attendeeIndex",
		"show"
    ],
    computed: {
        ...mapState("registration", [
            'activeReservation',
            'appData',
            'states',
        ]),
        reservationProperties() {
            return this.appData.reservationProperties
        },
        currentReservation() {
            return this.appData.currentReservation
        },
    },
};
</script>
