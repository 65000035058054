<template>
	<form class="modal-body" id="attendee-certification-form" novalidate>
		<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>

		<div class="row">
			<div class="form-group col-md" v-for="(cert, index) in certifications" :key="index">
				<label :for="`cert_${cert.id}`">{{ cert.name }}</label>
				<input
					class="form-control"
					type="text"
					:name="`cert_${cert.id}`"
					:id="`cert_${cert.id}`"
					:title="cert.name"
					v-model="currentUser.certifications[cert.id]"
				/>
			</div>
		</div>
		<div class="d-flex justify-content-end m-n1">
			<button class="btn btn-outline-primary m-1" @click.prevent="closeModal()">Cancel</button>
			<button class="btn btn-primary m-1" data-js-id="cert-modal-save-change-button" @click.prevent="saveForm()">Save My Changes</button>
		</div>
	</form>
</template>

<script>
	import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
		props: ["user", "closeModal", "certifications"],
		data() {
			return {
				currentUser: this.user,
				showErrorDiv: false,
				errorMessage: "",
				formProcessing: false,
                saveChangesButton: null,
                formData: {}
			};
		},
		methods: {
			saveForm() {
				const vm = this;
				const thisEvent = event;

				this.formData.id = this.currentUser.id;
				this.formData.certifications = {...this.currentUser.certifications};

				event.target.disabled = true;
				event.target.innerText = "Processing...";

				this.showErrorDiv = false;
				this.errorMessage = "";

				axios
					.post("/portal/dashboard/attendee/update-certifications", this.formData)
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						logApplicationError(error);
						console.error(error);

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								vm.showErrorDiv = true;
								vm.errorMessage = errorsUl.outerHTML;
							} else {
								vm.showErrorDiv = true;
								vm.errorMessage =
									'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
							}
						}

						thisEvent.target.disabled = false;
						thisEvent.target.innerText = "Save My Changes";

						console.error(error);
					});
			}
		}
	};
</script>