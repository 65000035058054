<template>
    <div class="alert alert-warning my-2">
        Badge name may be edited until {{ appData.currentConference.badge_info_lockdown_date }}, after which a {{ appData.currentConference.name }} staff member will have to assist you.
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState("registration", {
            appData: (state) => state.appData
        })
    }
}
</script>