export const dtFaqListOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",

    columns: [
        // Title
        {
            data: 'title',
            className: 'faq-title'
        },
        // Question
        {
            data: 'question',
            width: '30%',
            className: 'faq-question',
            render: function(data, type, row) {
                return `<div class="faq-question"><p class="m-0">${row.question}</p></div>`;
            }
        },
        // Answer
        {
            data: 'answer',
            width: '30%',
            className: 'faq-answer',
            render: function(data, type, row) {
                return `<div class="faq-answer"><p class="m-0">${row.answer}</p></div>`;
            }
        },
        // Categories
        {
            data: 'categories',
            className: 'faq-categories',
            render: function(data, type, row) {
                let html = 'N/A';
                if (data.length) {
                    html = '';
                    data.forEach(category => {
                        html += `<p class="m-0">${category.name}</p>`;
                    });
                }

                return html;
            }
        },
        // Options
        {
            data: 'options',
            className: 'faq-options',
            render: function(data, type, row) {
                return  `<div class="faq-options">
                            <button class="faq-recycle btn btn-outline-primary" data-id="${row.id}" data-js-id="faq-recycle-button">Recycle</button>
                            <button class="faq-modify btn btn-primary" data-id="${row.id}" data-js-id="faq-modify-button">Modify</button>
                        </div>`
            }
        }
    ]
}