<template>
	<div v-if="appData.showAttendeeReservationInformationSection">
		<div
			class="tab-content my-n2"
			:class="{active: appData.currentReservation == index, 'd-none' : appData.currentReservation != index}"
			:key="index"
			v-for="(reservation, index) in activeReservation.reservations"
			@click="updateCurrentIndex(index)"
		>
			<div class="card my-2">
				<arp-toggle-bar
					:attendee-index="index"
					toggle-bar-text="Attendee Information"
					:is-toggled="showContactForm"
					@click.native="openContactForm(index)"
				></arp-toggle-bar>

				<arp-attendee-info-form
					:current-reservation="appData.currentReservation"
					:attendee-index="index"
					:show="showContactForm"
				></arp-attendee-info-form>
			</div>

			<div class="card my-2">
				<arp-toggle-bar
					:attendee-index="index"
					toggle-bar-text="Reservation Options"
					:is-toggled="showReservationForm"
					@click.native="openRegistrationForm(index)"
				></arp-toggle-bar>

                <arp-reservation-options-form
					:attendee-index="index"
                    :show="showReservationForm"
                    :inMulti="true"
                ></arp-reservation-options-form>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
	data() {
		return {
			showContactForm: true,
			showReservationForm: true
		};
	},
		computed: {
			...mapState("registration", {
				appData: (state) => state.appData,
				reservationProperties: (state) => state.appData.reservationProperties,
				activeReservation: (state) => state.activeReservation,
				states: (state) => state.states,
				optionalOfferingsList: (state) => state.appData.optionalOfferingsList,
			})
		},
	methods: {
		...mapActions("registration", ["setVar", "setReservation", "removeResIndex"]),
		openContactForm(index) {
			this.showContactForm = !this.showContactForm;
		},
		openRegistrationForm(index) {
			this.showReservationForm = !this.showReservationForm;
		},
		updateCurrentIndex(index) {
			this.setVar({ob: "appData", variable: "currentReservation", value: index});
		}
	}
};
</script>