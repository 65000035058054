<template>
    <div
        :class="{
            'card-body d-block': inMulti && show,
            'card-body d-none': inMulti && !show
        }"
    >
        <form
            class="reservation-form"
            :class="{'d-block': showForm, 'd-none' : !showForm}"
            :data-form-index="`${attendeeIndex}`"
            novalidate
            :data-js-id="`reservations_${attendeeIndex}`"
        >
            <div class="card mb-2">
                <div class="card-header">
                    <p class="card-title font-weight-bold m-0">Choose which conference options you prefer.</p>
                </div>

                <div class="card-body">
                    <div class="row">
                        <div class="form-group col-md m-md-0">
                            <p class="mb-1">Conference Pass Options<span class="required">*</span></p>
                            <div class="custom-inline-radio-buttons">
                                <fieldset>
                                    <legend class="sr-only">Conference Pass Options for</legend>
                                    <div
                                        class="custom-control custom-radio custom-control-inline"
                                        v-for="(option, index) in reservationProperties['Conference Pass Options'].options"
                                        :key="index"
                                    >
                                        <input
                                            class="custom-control-input"
                                            type="radio"
                                            name="conference_pass for "
                                            required
                                            data-msg="Please select a conference pass"
                                            :title="option.option_name"
                                            :value="option"
                                            :id="`option_id_${option.id}-${attendeeIndex}`"
                                            v-model="reservations[attendeeIndex].reservation_options.conference_pass_option"
                                        />
                                        <label class="custom-control-label m-2" :for="`option_id_${option.id}-${attendeeIndex}`">
                                            {{option.option_name}} - {{(isVgmMember) ? option.vgm_member_price : option.price}}
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-2">
                        <div class="card-header">
                            <p class="card-title font-weight-bold m-0">What are your dietary restrictions?<span class="required">*</span></p>
                        </div>
                        <div class="card-body">
                            <div class="form-group m-md-0">
                                <p class="mb-1">Dietary Options<span class="required">*</span></p>
                                <div class="custom-inline-checkbox-buttons">
                                    <fieldset>
                                        <legend class="sr-only">Dietary Options</legend>
                                        <div
                                            class="custom-control custom-checkbox custom-control-inline"
                                            v-for="(option, index) in reservationProperties['Dietary'].options"
                                            :key="index"
                                        >
                                            <input
                                                class="custom-control-input"
                                                type="checkbox"
                                                name="dietary_options"
                                                data-msg="Please select a dietary option"
                                                required
                                                :title="option.option_name"
                                                :value="option"
                                                :id="`option_id_${option.id}-${attendeeIndex}`"
                                                v-model="reservations[attendeeIndex].reservation_options.dietary_options"
                                            />
                                            <label class="custom-control-label m-2" :for="`option_id_${option.id}-${attendeeIndex}`">
                                                {{option.option_name}}
                                            </label>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-2">
                        <div class="card-header">
                            <p class="card-title font-weight-bold m-0">What additional options would you like to include in your registration?</p>
                        </div>
                        <template v-for="(offering, offeringIndex) in optionalOfferings">
                            <div class="card-body border-bottom" v-if="showPlatinumRegistrationOptions || offering.platinum_members == 0">
                                <details
                                    class="optional-offering-detail"
                                    @toggle="handleDetailsToggle(activeReservation.reservations[attendeeIndex].optional_offerings[offeringIndex])"
                                    :open="activeReservation.reservations[attendeeIndex].optional_offerings[offeringIndex].selected"
                                >
                                    <summary class="mb-0 font-weight-bold">{{ offering.name }}</summary>

                                    <optional-offering-input
                                        v-for="(input, inputIndex) in offering.inputs"
                                        :key="`${attendeeIndex}_${offeringIndex}_${inputIndex}_${updateInc}`"
                                        :disable-required="!activeReservation.reservations[attendeeIndex].optional_offerings[offeringIndex].selected"
                                        :input="input"
                                        :index="inputIndex"
                                        :inputs="activeReservation.reservations[attendeeIndex].optional_offerings[offeringIndex].inputs"
                                        :update-subtotal="setModifiedMaximumAvailable"
                                    ></optional-offering-input>
                                </details>
                            </div>
                        </template>
                    </div>
                </div>

                <div class="card mt-2" v-if="showPlatinumRegistrationOptions">
                    <div class="card-header">
                        <p class="card-title font-weight-bold m-0">Platinum Member Options</p>
                    </div>

                    <div class="card-body alert alert-info text-14 rounded-0">
                        <p>As a VIP member, your company is eligible to attend the VGM Platinum/212 events at Heartland Conference. These events are reserved for the executive level staff from your company. Please check with your company prior to registering for the Platinum/212 portion of the conference. Heartland Conference will review all registrations for the Platinum/212 events for accuracy as seats are limited.</p>
                        <p>Platinum /212 events will begin on Sunday, with lunch at 11:30 a.m, social events at 12:30 p.m and dinner approximately 6:30 p.m.  Times subject to change.</p>
                    </div>

                    <div class="card-body">
                        <div class="d-flex flex-column flex-md-row mx-n3 my-n1">
                            <div class="form-group px-3 my-1">
                                <p class="mb-1">Sunday Arrival?<span class="required">*</span></p>
                                <div class="custom-inline-radio-buttons">
                                    <fieldset>
                                        <legend class="sr-only">Sunday Arrival?</legend>
                                        <div
                                            class="custom-control custom-radio custom-control-inline"
                                            v-for="(option, index) in reservationProperties['Sunday Arrival'].options"
                                            :key="index"
                                        >
                                            <input
                                                class="custom-control-input"
                                                type="radio"
                                                name="sunday_arrival"
                                                required
                                                data-msg="Please select if arriving Sunday"
                                                @change="updateOption(index, option)"
                                                :title="option.option_name"
                                                :value="option"
                                                :id="`option_id_${option.id}-${attendeeIndex}`"
                                                v-model="reservations[attendeeIndex].reservation_options.sunday_arrival"
                                            />
                                            <label class="custom-control-label" :for="`option_id_${option.id}-${attendeeIndex}`">
                                                {{option.option_name}}
                                            </label>
                                        </div>

                                    </fieldset>
                                </div>
                            </div>

                            <div class="form-group px-3 my-1 border-md-left" v-if="attendeeArrivingSunday">
                                <p class="mb-1">Will you be joining us for lunch?<span class="required">*</span></p>
                                <div class="custom-inline-radio-buttons">
                                    <fieldset>
                                        <legend class="sr-only">Sunday Lunch?</legend>
                                        <div
                                            class="custom-control custom-radio custom-control-inline"
                                            v-for="(option, index) in reservationProperties['Sunday Lunch'].options"
                                            :key="index"
                                        >
                                            <input
                                                class="custom-control-input"
                                                type="radio"
                                                name="sunday_lunch"
                                                required
                                                data-msg="Please select a Sunday Lunch Option"
                                                @change="updateOption(index, option)"
                                                :title="option.option_name"
                                                :value="option"
                                                :id="`option_id_${option.id}-${attendeeIndex}`"
                                                v-model="reservations[attendeeIndex].reservation_options.sunday_lunch"
                                            />
                                            <label class="custom-control-label" :for="`option_id_${option.id}-${attendeeIndex}`">
                                                {{option.option_name}}
                                            </label>
                                        </div>

                                    </fieldset>
                                </div>
                            </div>

                            <div class="form-group px-3 my-1 border-md-left" v-if="attendeeArrivingSunday">
                                <p class="mb-1">Which social event will you be attending?<span class="required">*</span></p>
                                <div class="custom-inline-radio-buttons">
                                    <fieldset>
                                        <legend class="sr-only">Sunday Arrival Options?</legend>
                                        <div
                                            class="custom-control custom-radio custom-control-inline"
                                            v-for="(option, index) in reservationProperties['Sunday Arrival Options'].options"
                                            :key="index"
                                        >
                                            <input
                                                class="custom-control-input"
                                                type="radio"
                                                name="sunday_arrival_options"
                                                required
                                                data-msg="Please select an option"
                                                @change="updateOption(index, option)"
                                                :title="option.option_name"
                                                :value="option"
                                                :id="`option_id_${option.id}-${attendeeIndex}`"
                                                v-model="reservations[attendeeIndex].reservation_options.sunday_arrival_options"
                                                :disabled="noGolfSunday && option.option_name == 'Golf'"
                                            />
                                            <label class="custom-control-label" :for="`option_id_${option.id}-${attendeeIndex}`">
                                                {{option.option_name}}
                                            </label>
                                        </div>
                                    </fieldset>

                                </div>
                                <p class="small" v-if="noGolfSunday">We are currently full for the Golf event, please reach out to VGM Events at events@vgm.com to inquire about more openings.</p><p class="small" v-else> {{golfRemainingSunday}} golf spots left </p>
                                <div class="form-group px-3 my-1"  v-if="attendeeArrivingSunday && attendeeWantsGolfOutingAtSunnySide">
                                    <p class="mb-1">Thank you for signing up to join us for golf. Due to difficulties in securing rental clubs over the last few years, we would like to encourage you to bring your own clubs or rent a set through www.clublender.com or www.clubhub.com. If that is not an option, please reach out to <a href="mailto:heather.johnson@vgm.com" title="Opens a new email to heather.johnson@vgm.com in your default mailing application">heather.johnson@vgm.com</a> and they will work to track down a set for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- If arriving Sunday, ask if joining dinner-->
                    <div class="card-body border-top" v-if="attendeeArrivingSunday">
                        <div class="d-flex">
                            <div class="d-flex flex-column flex-md-row mx-n3 my-n1">
                                <div class="form-group px-3 my-1">
                                    <p class="mb-1">Will you be joining us for dinner?<span class="required">*</span></p>
                                    <div class="custom-inline-radio-buttons">
                                        <fieldset>
                                            <legend class="sr-only">Group Dinner?</legend>
                                            <div
                                                class="custom-control custom-radio custom-control-inline"
                                                v-for="(option, index) in reservationProperties['Group Dinner'].options"
                                                :key="index"
                                            >
                                                <input
                                                    type="radio"
                                                    class="custom-control-input"
                                                    name="group_dinner"
                                                    required
                                                    data-msg="Please select if joining dinner"
                                                    @change="updateOption(index, option)"
                                                    :title="option.option_name"
                                                    :value="option"
                                                    :id="`option_id_${option.id}-${attendeeIndex}`"
                                                    v-model="reservations[attendeeIndex].reservation_options.group_dinner"
                                                />
                                                <label class="custom-control-label" :for="`option_id_${option.id}-${attendeeIndex}`">
                                                    {{option.option_name}}
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div>
                                </div>

                                <!-- If joining for dinner, ask if they are bringing a guest -->
                                <div class="form-group px-3 my-1 border-md-left" v-if="attendeeArrivingSunday && attendeeJoiningDinner">
                                    <p class="mb-1">Will you be bringing a spouse or a guest?<span class="required">*</span></p>
                                    <div class="custom-inline-radio-buttons">
                                        <fieldset>
                                            <legend class="sr-only">Spouse/Guest for Dinner?</legend>
                                            <div
                                                class="custom-control custom-radio custom-control-inline"
                                                v-for="(option, index) in reservationProperties['Spouse/Guest for Dinner'].options"
                                                :key="index"
                                            >
                                                <input
                                                    class="custom-control-input"
                                                    type="radio"
                                                    name="spouse_guest_for_dinner"
                                                    required
                                                    data-msg="Please select if arriving Sunday"
                                                    @change="updateOption(index, option); dinnerGuest()"
                                                    :title="option.option_name"
                                                    :value="option"
                                                    :id="`option_id_${option.id}-${attendeeIndex}`"
                                                    v-model="reservations[attendeeIndex].reservation_options.guest_for_dinner"
                                                />
                                                <label class="custom-control-label" :for="`option_id_${option.id}-${attendeeIndex}`">
                                                    {{option.option_name}}
                                                </label>
                                            </div>
                                        </fieldset>
                                    </div>
                                    <small>Please do not include co-workers as your dinner guests as they will be requested the same information at conference registration.</small>
                                </div>
                            </div>
                        </div>

                        <div class="form-group" v-if="attendeeJoiningDinner && attendeeBringingSpouseGuestForDinner">
                            <label for="spouse_guest_name">Spouse/Guest Name<span class="required">*</span></label>
                            <input
                                class="form-control"
                                type="text"
                                id="spouse_guest_name_dinner"
                                name="spouse_guest_name_dinner"
                                title="Spouse/Guest Name"
                                data-msg="Please enter spouse/guest name"
                                required
                                v-model="reservations[attendeeIndex].reservation_options.guest_name_for_dinner.value"
                            />
                        </div>
                    </div>

                    <!-- Ask if attending monday meeting -->
                    <div class="card-body border-top">
                        <div class="form-group m-md-0">
                            <p class="mb-1">Will you be attending the Monday meeting?<span class="required">*</span></p>
                            <div class="custom-inline-radio-buttons">
                                <fieldset>
                                    <legend class="sr-only">Monday Meeting Attendance?</legend>
                                    <div
                                        class="custom-control custom-radio custom-control-inline"
                                        v-for="(option, index) in reservationProperties['Monday Meeting Attendance'].options"
                                        :key="index"
                                    >
                                        <input
                                            type="radio"
                                            class="custom-control-input"
                                            name="monday_meeting"
                                            required
                                            data-msg="Please select if attending meeting"
                                            :title="option.option_name"
                                            :value="option"
                                            :id="`option_id_${option.id}-${attendeeIndex}`"
                                            v-model="reservations[attendeeIndex].reservation_options.monday_meeting_attendance"
                                        />
                                        <label class="custom-control-label" :for="`option_id_${option.id}-${attendeeIndex}`">
                                            {{option.option_name}}
                                        </label>
                                    </div>
                                </fieldset>
                            </div>
                            <small>Meeting is from 8am to 12pm</small>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
    export default {
        props: [
            "attendeeIndex",
            "show",
            "inMulti"
        ],
        data() {
            return {
                showSpouseGuestName: false,
                spliceIndex: null,
                updateInc: 0
            };
        },
        computed: {
            ...mapState("registration", [
                'appData',
                'activeReservation',
            ]),
            reservations() {
                return this.activeReservation.reservations
            },
            currentReservation() {
                return this.activeReservation.currentReservation
            },
            reservationProperties() {
                return this.appData.reservationProperties
            },
            showPlatinumRegistrationOptions() {
                return this.activeReservation.company.is_platinum_vgm_member
            },
            isVgmMember() {
                return this.activeReservation.company.is_vgm_member
            },
            optionalOfferings() {
                return this.appData.optionalOfferings
            },
            attendeeUniqueIndex() {
                return this.attendeeIndex || 0
            },
            hasGuest() {
                return this.reservations[this.attendeeIndex].reservation_options.additional_options.some(option => option.option_name == 'Spouse/Guest')
            },
            noGolf() {
                return this.reservationProperties['Golf Outing'].options[0].remaining <= 0
            },
            golfRemaining() {
                return this.reservationProperties['Golf Outing'].options[0].remaining;
            },
            noGolfSunday() {
                return this.reservationProperties['Sunday Arrival Options'].options[0].remaining <= 0;
            },
            golfRemainingSunday() {
                return this.reservationProperties['Sunday Arrival Options'].options[0].remaining;
            },
            techTrainingPassSelected() {
                const conferencePassOption = this.reservations[this.attendeeIndex].reservation_options.conference_pass_option
                return conferencePassOption && conferencePassOption.option_name == "Tech Training"
            },
            golfOutingIsSelected() {
                const golfOuting = this.reservations[this.attendeeIndex].reservation_options.golf_outing
                return golfOuting && golfOuting.option_name == "Yes"
            },
            attendeeWantsToRentGolfClubs() {
                const golfClubRental = this.reservations[this.attendeeIndex].reservation_options.golf_club_rental
                return golfClubRental && golfClubRental.option_name == "Yes"
            },
            attendeeArrivingSunday() {
                if (this.reservations[this.attendeeIndex].reservation_options.sunday_arrival) {
                    return (this.reservations[this.attendeeIndex].reservation_options.sunday_arrival.option_name =="Yes") ? true : false
                } else {
                    return false;
                }
            },
            attendeeWantsGolfOutingAtSunnySide() {
                if (this.reservations[this.attendeeIndex].reservation_options.sunday_arrival_options) {
                    return (this.reservations[this.attendeeIndex].reservation_options.sunday_arrival_options.option_name =="Golf") ? true : false
                } else {
                    return false;
                }
            },
            attendeeNeedsClubRentalAtSunnyside() {
                if (this.reservations[this.attendeeIndex].reservation_options.golf_at_sunnyside_club_rental) {
                    return (this.reservations[this.attendeeIndex].reservation_options.golf_at_sunnyside_club_rental.option_name =="Yes") ? true : false
                } else {
                    return false;
                }
            },
            attendeeJoiningDinner() {
                if (this.reservations[this.attendeeIndex].reservation_options.group_dinner) {
                    return (this.reservations[this.attendeeIndex].reservation_options.group_dinner.option_name == "Yes") ? true : false
                } else {
                    return false;
                }
            },
            attendeeBringingSpouseGuestForDinner() {
                if (this.reservations[this.attendeeIndex].reservation_options.guest_for_dinner) {
                    return (this.reservations[this.attendeeIndex].reservation_options.guest_for_dinner.option_name =="Yes") ? true : false
                } else {
                    return false;
                }
            },
            attendeeNeedsHotel() {
                if (this.reservations[this.attendeeIndex].reservation_options.hotel_room) {
                    return (this.reservations[this.attendeeIndex].reservation_options.hotel_room.option_name =="Yes") ? true : false
                } else {
                    return false;
                }
            },
            showForm() {
                return typeof this.show == "undefined" ? true : this.show;
            },
        },
        methods: {
            ...mapActions("registration", [
                "setModifiedMaximumAvailable",
                "setReservation",
                "setResOptionVal",
                "removeResOptionVal",
                "setResOptionVal"
            ]),
            handleDetailsToggle(offering) {
                offering.selected = event.target.open
                this.setModifiedMaximumAvailable()
                this.updateInc++
            },
            addGuestObject(option) {
                if (this.hasGuest && option.option_name != "Pre-Conference Pass") {
                    let field = {};

                    let guest = this.reservationProperties["Spouse/Guest Name"];
                    field.id = guest.options[0].id;
                    field.item_id = guest.options[0].item_id;
                    field.option_name = guest.options[0].option_name;
                    field.option_name = guest.options[0].option_name;
                    field.price = guest.options[0].price;
                    field.vgm_member_price = guest.options[0].vgm_member_price;
                    field.value = '';

                    this.setResOptionVal({index: this.attendeeIndex, attribute: 'guest_name', value: field})
                }
            },
            dinnerGuest() {
                if (this.attendeeJoiningDinner) {
                    let field = {};

                    let guest = this.reservationProperties["Spouse/Guest Name for Dinner"];
                    field.id = guest.options[0].id;
                    field.item_id = guest.options[0].item_id;
                    field.option_name = guest.options[0].option_name;
                    field.option_name = guest.options[0].option_name;
                    field.price = guest.options[0].price;
                    field.vgm_member_price = guest.options[0].vgm_member_price;
                    field.value = '';

                    this.setResOptionVal({index: this.attendeeIndex, attribute: 'guest_name_for_dinner', value: field})
                }
            },
            hotelNotes() {
                if (this.attendeeNeedsHotel) {
                    let field = {};

                    let guest = this.reservationProperties["Hotel Room Reservation Notes"];
                    field.id = guest.options[0].id;
                    field.item_id = guest.options[0].item_id;
                    field.option_name = guest.options[0].option_name;
                    field.option_name = guest.options[0].option_name;
                    field.price = guest.options[0].price;
                    field.vgm_member_price = guest.options[0].vgm_member_price;
                    field.value = '';

                    this.setResOptionVal({index: this.attendeeIndex, attribute: 'hotel_room_reservation_notes', value: field})
                }
            },
            updateOption(index, option) {
                if (option.option_name == "No") {
                    switch (option.item_name) {
                        case "Golf Outing":
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'golf_club_rental', id: 0});
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'golf_club_rental_options', id: 0});
                            break;
                        case "Golf Club Rental":
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'golf_club_rental_options', id: 0});
                            break;
                        case "Sunday Arrival" :
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'sunday_arrival_options', id: 0});
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'sunday_arrival_options', id: 0});
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'golf_at_sunnyside_club_rental', id: 0});
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'golf_at_sunnyside_club_rental_options', id: 0});
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'group_dinner', id: 0});
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'guest_for_dinner', id: 0});
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'guest_name_for_dinner', id: 0});
                            break;
                        case "Golf at Sunnyside Club Rental":
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'golf_at_sunnyside_club_rental_options', id: 0});
                            break;
                        case "Group Dinner":
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'guest_for_dinner', id: 0});
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'guest_name_for_dinner', id: 0});
                            break;
                        case "Spouse/Guest for Dinner":
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'guest_name_for_dinner', id: 0});
                            break;
                        case "Hotel Room":
                            this.removeResOptionVal({index: this.attendeeIndex, attribute: 'hotel_room_reservation_notes', id: 0});
                            break;
                        default:
                    }
                }
            }
        },
        watch: {
            currentReservation(n,o) {
                this.updateInc++
            }
        }
    }
</script>

<style lang="scss" scoped>
    .optional-offering-detail {
        summary {
            list-style-type: none;
            position: relative;
            padding-left: 1.5rem;

            &::-webkit-details-marker { display: none; } /* Chrome */
            &::marker { display: none; }

            &:before {
                content: "";
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                height: 1rem;
                width: 1rem;
                border-radius: .25rem;
                border: 1px solid #adb5bd;
                transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                background: no-repeat 50% 50% / 56% 56%; // Magic numbers to position the check as similarly as possible to a real checkbox
            }
        }
        
        &[open] summary:before {
            color: #fff;
            border-color: var(--primary);
            background-color: var(--primary);
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E")
        }
    }
</style>