<template>
	<div>
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1">
			<em>Attendees Management</em>
		</h4>
		<div>
			<admin-dashboard-attendee-info-card
				v-for="(reservation, index) in reservations"
				:key="index"
				:index="index"
				:reservation="reservation"
				:company="company"
				:is-after-badge-cutoff="isAfterBadgeCutoff"
				:open-modal="openModal"
				:orders="orders"
			></admin-dashboard-attendee-info-card>

			<admin-dashboard-attendee-info-modal
				:modal-id="attendeeContactInfoModalId"
				modal-title="Edit Attendee Contact Information"
				header-icon="user"
				modal-size="lg"
			>
				<admin-dashboard-attendee-contact-form
					:reservation="currentReservation"
					:close-modal="closeModal"
				></admin-dashboard-attendee-contact-form>
			</admin-dashboard-attendee-info-modal>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			"user",
			"company",
			"reservations",
			"isAfterBadgeCutoff",
			"orders",
			"optionalOfferings"
		],
		data() {
			return {
				attendeeContactInfoModalId: "attendee-info-modal",
				currentReservation: {address: {}}
			};
		},
		methods: {
			openModal(reservation) {
				$(`[data-js-id="${this.attendeeContactInfoModalId}"]`).modal("show");

				this.currentReservation = reservation;
			},
			closeModal() {
				$(`[data-js-id="${this.attendeeContactInfoModalId}"]`).modal("hide");

				this.currentReservation = {};
			},
		}
	};
</script>