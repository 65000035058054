<template>
	<div class="row">
		<div class="col">
			<admin-dashboard-my-profile
				:user="user"
				:company="company"
				:is-after-badge-cutoff="isAfterBadgeCutoff"
				:orders="orders"
			></admin-dashboard-my-profile>
				
			<admin-dashboard-optional-offerings
				:user="user"
                :optional-offerings="optionalOfferings"
				v-if="optionalOfferings.length"
            ></admin-dashboard-optional-offerings>

		</div>

		<div class="col">
			<admin-dashboard-attendees-management
				:reservations="reservations"
				:company="company"
				:is-after-badge-cutoff="isAfterBadgeCutoff"
				:orders="orders"
			></admin-dashboard-attendees-management>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["user", "company", "reservations", "isAfterBadgeCutoff", "orders", "optionalOfferings"],
		mounted() {
			// Add date of birth validation
			$.validator.addMethod(
				"dob",
				function(value, element) {
					let isDateFormatValid = moment(value, "MM/DD/YYYY", true).isValid();

					let isDateAfter = moment(value).isAfter("01/01/1900");

					let isAtLeast18 = moment().diff(moment(value), "years") >= 18;

					return isDateFormatValid && isDateAfter && isAtLeast18;
				},
				"Please enter valid date"
			);

			$.validator.addMethod("expdate", function(value, element) {
				let isDateFormatValid = moment(value, "MM/YY", true).isValid();

				let submittedMonth = moment(value, "MM/YY", true).month();
				let currentMonth = moment().month();

				let submittedYear = moment(value, "MM/YY", true).year();
				let currentYear = moment().year();

				let isDateAfter = false;

				let yearIsValid = submittedYear >= currentYear;

				if (yearIsValid) {
					if (submittedYear > currentYear) {
						isDateAfter = true;
					} else if (submittedMonth >= currentMonth) {
                        isDateAfter = true;
                    } else {
                        isDateAfter = false;
                    }
				} else {
					isDateAfter = false;
				}

				return isDateFormatValid && isDateAfter;
			});
		}
	};
</script>
