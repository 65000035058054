<template>
    <div>
        <vendor-registration-contact-information-form v-if="appData.sections.show_contact_info_form"></vendor-registration-contact-information-form>

        <vendor-registration-package-selection-form v-if="appData.sections.show_package_selection_form"></vendor-registration-package-selection-form>
        
        <vendor-registration-sponsorship-selection-form v-if="appData.sections.show_sponsorship_selection_form"></vendor-registration-sponsorship-selection-form>

        <vendor-registration-add-on-form v-if="appData.sections.show_add_on_form"></vendor-registration-add-on-form>

        <vendor-registration-booth-order-form v-if="appData.sections.show_booth_order_form"></vendor-registration-booth-order-form>
        
        <vendor-registration-confirmation-section v-if="appData.sections.show_registration_confirmation"></vendor-registration-confirmation-section>

        <vendor-registration-payment-form v-if="appData.sections.show_payment_form"></vendor-registration-payment-form>

        <vendor-registration-payment-confirmation v-if="appData.sections.show_payment_confirmation"></vendor-registration-payment-confirmation>

        <vendor-registration-no-payment-confirmation v-if="appData.sections.show_no_payment_confirmation"></vendor-registration-no-payment-confirmation>

        <vendor-registration-application-error-section v-if="appData.sections.show_application_error_section" :phone-number="phoneNumber"></vendor-registration-application-error-section>
    </div>
</template>

<script>
    export default {
        props: [
            'phoneNumber'
        ],
        data() {
            return {
                appData: this.$root.appData
            };
        }
    };
</script>
