<template>
	<div class="form-group pt-2 mb-0" v-if="input.maximum_available">
		<label class="d-block m-0">
			<span class="d-flex justify-content-between">
				<span>{{ input.name }} <span class="required" v-if="requiredWithOverride">*</span> <sup>({{ computedMaximumAvailableText }})</sup></span>
				<span class="d-flex align-items-center lh-md m-0">
					<span class="fas fa-at mr-1"></span>
					<span v-if="parseFloat(input.price_per_unit) > 0">
						{{ `$${parseFloat(input.price_per_unit).toFixed(2)}` }} / Unit
					</span>
					<span v-else>
						No Additional Cost
					</span>
				</span>
			</span>

			<input
				class="form-control"
				type="number"
				:required="requiredWithOverride"
				:name="`input_${input.id}_${index}`"
				:max="computedMaximumAvailableValue"
				:min="requiredWithOverride ? 1 : 0"
				v-model.number="inputs[index].quantity"
				:data-msg="`Please enter an amount${requiredWithOverride ? ' between 1 and ' + computedMaximumAvailableValue : ''}`"
				@input="modifyLineItemTotal(inputs[index])"
			>
		</label>

		<small class="m-0" v-if="parseFloat(input.price_per_unit) > 0">Line Item total: ${{ lineItemTotal }}</small>

		<template v-if="input.additional_input_label">
			<div class="form-group mb-0 mt-2" v-for="n in inputs[index].quantity">
				<label class="d-block">
					<span class="m-0">{{ input.additional_input_label }} {{ n }}<span class="required">*</span></span>

					<input
						class="form-control"
						type="text"
						:name="`input_${input.id}_${index}_additional_input_${n - 1}`"
						v-model="inputs[index].additional_input_value[n - 1]"
						:data-msg="`Please enter a value for ${input.additional_input_label}`"
						:required="!disableRequired && inputs[index].quantity"
					>
				</label>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	props: {
		input: {
			required: true
		},
		index: {
			required: true
		},
		inputs: {
			required: true
		},
		updateSubtotal: {
			required: true
		},
		disableRequired: {
			default: false
		}
	},
	data() {
		return {
			lineItemTotal: 0,
			updateInc: 0
		};
	},
	computed: {
		computedMaximumAvailableText() {
			let forceUpdate = this.updateInc

			if (this.input.hasOwnProperty('modified_maximum_available')) {
				return this.input.modified_maximum_available ? `${this.input.modified_maximum_available} Available` : 'Sold Out'
			} else {
				return this.input.maximum_available ? `${this.input.maximum_available} Available` : 'Sold Out'
			}
		},
		computedMaximumAvailableValue() {
			let forceUpdate = this.updateInc

			if (this.input.hasOwnProperty('modified_maximum_available')) {
				return this.input.modified_maximum_available + this.inputs[this.index].quantity
			} else {
				return this.input.maximum_available
			}
		},
		requiredWithOverride() {
			return !this.disableRequired && this.input.required;
		}
	},
	methods: {
		modifyLineItemTotal(input) {
			this.updateAdditionalInputValueModel(input)

			this.lineItemTotal = parseFloat(input.quantity * parseFloat(input.price_per_unit)).toFixed(2);

			input.line_item_total = this.lineItemTotal

			this.updateSubtotal();

			this.updateInc++
		},
		updateAdditionalInputValueModel(input) {			
			if (input.additional_input_label) {
				let lessItems = input.additional_input_value.length < input.quantity;
				
				if (lessItems) {
					let diff = input.quantity - input.additional_input_value.length
					
					for (let i = 0; i < diff; i++) {
						input.additional_input_value.push("")
					}
				} else {
					input.additional_input_value.splice(input.quantity, input.additional_input_value.length - input.quantity)
				}
			}
		}
	}
};
</script>
