<template>
  	<form class="d-flex flex-column" data-js-id="cm-promo-codes-add-modify-form" novalidate>
		<div class="row" v-if="formHasError">
			<div class="col">
				<div class="alert alert-danger">{{ formErrorMessage }}</div>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="code">Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Please use uppercase letters, up to 30"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="code"
					id="code"
					title="Enter a code"
					required
					data-msg="Please provide a name"
					v-model="ro.name"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="price">Price</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Please use uppercase letters, up to 10"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="number"
					step=".01"
					min="0.00"
					name="price"
					id="price"
					title="Enter Price"
					required
					data-msg="Enter Price"
					v-model="ro.price"
				/>
			</div>
            <div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="vgm-price">VGM Price</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Please use uppercase letters, up to 10"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="number"
					step=".01"
					min="0.00"
					name="vgm-price"
					id="vgm-price"
					title="Enter Price"
					required
					data-msg="Enter Price"
					v-model="ro.vgmPrice"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="max_number_of_uses">Max Number of Uses</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="The maximum number of times the code can be used for promo"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="number"
					step="1"
					min="1"
					name="max_number_of_uses"
					id="max_number_of_uses"
					title="The maximum number of times the code can be used for promo"
					required
					data-msg="Please provide a minimum amount"
					v-model="ro.max_number_of_uses"
				/>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="required_number_of_attendees">Required Number of Attendees</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="The required number of attendees"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="required_number_of_attendees"
					id="required_number_of_attendees"
					title="The required number of attendees"
					v-mask="['##']"
					required
					data-msg="Please provide an amount attendees"
					v-model="currentPromoCode.required_number_of_attendees"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="exipration_date">Expiration Date</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Last day that the promo will be valid"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control js-flatpickr"
					type="text"
					name="exipration_date"
					id="exipration_date"
					title="Enter expiration date"
					required
					data-msg="Please provide a expiration date"
					v-mask="'##/##/####'"
					v-model="currentPromoCode.expiration_date"
				/>
			</div>
		</div>
		<div class="row d-none" :class="{'d-flex': isAppliedAutomatically}">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="conference_pass">Conference Pass Options</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="This will apply a group discount and associate it with a pass"
					></a>
				</div>
				<select
                    class="form-control"
                    name="reservation_property_definition_option_id"
                    id="conference_pass"
                    title="Conference Pass Option"
                    v-model="currentPromoCode.reservation_property_definition_option_id"
                    required
                    data-msg="Please select a conference pass option"
                >
                    <option value="0">Not Applied to Pass</option>
                    <option
						v-for="(pass, index) in conferencePassOptions"
						:key="index"
						:value="pass.id"
					>{{ pass.option_name }}</option>
                </select>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<p class="label mr-3 my-0">
						Active?
						<span class="required">*</span>
					</p>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Is the promo code active"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Active</legend>
					<div class="form-check form-check-inline form-check-radio">
						<label class="form-check-label" for="active_yes">
							<input
								class="form-check-input"
								required
								type="radio"
								title="Select if promo code is active or not"
								name="active"
								id="active_yes"
								value="1"
								data-msg="Please select yes or no"
								v-model="currentPromoCode.active"
							/>
							<span class="form-check-sign"></span>
							Yes
						</label>
					</div>
					<div class="form-check form-check-inline form-check-radio">
						<label class="form-check-label" for="active_no">
							<input
								class="form-check-input"
								required
								type="radio"
								title="Select if promo code is active or not"
								name="active"
								id="active_no"
								value="0"
								data-msg="Please select yes or no"
								v-model="currentPromoCode.active"
							/>
							<span class="form-check-sign"></span>
							No
						</label>
					</div>
				</fieldset>
			</div>
			<div class="col-md form-group">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<p class="label mr-3 my-0">
						Ability to Apply Automatically?
						<span class="required">*</span>
					</p>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="This will only work when a conference pass is selected"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Ability to Apply Automatically</legend>
					<div class="form-check form-check-inline form-check-radio">
						<label class="form-check-label" for="apply_auto_yes">
							<input
								class="form-check-input"
								required
								type="radio"
								title="Select if promo code is automatically applied or not"
								name="apply_automaically"
								id="apply_auto_yes"
								value="1"
								data-msg="Please select yes or no"
								v-model="currentPromoCode.apply_automatically"
							/>
							<span class="form-check-sign"></span>
							Yes
						</label>
					</div>
					<div class="form-check form-check-inline form-check-radio">
						<label class="form-check-label" for="apply_auto_no">
							<input
								class="form-check-input"
								required
								type="radio"
								title="Select if promo code is automatically applied or not"
								name="apply_automaically"
								id="apply_auto_no"
								value="0"
								data-msg="Please select yes or no"
								v-model="currentPromoCode.apply_automatically"
							/>
							<span class="form-check-sign"></span>
							No
						</label>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="form-group">
			<div class="radio-legend d-flex justify-content-between align-items-center">
				<p class="label mr-3 my-0">
					Groups
					<span class="required">*</span>
				</p>
				<a
					href="#"
					data-toggle="tooltip"
					data-placement="right"
					data-boundary="window"
					title="User group that the coupon will apply to"
				>
					<span class="fas fa-question-circle text-info cursor-pointer"></span>
				</a>
			</div>
			<fieldset>
				<legend class="label sr-only">Groups</legend>
				<div
					class="form-check form-check-inline form-check-radio"
					v-for="(group, index) in portalGroups"
					:key="index"
				>
					<label class="form-check-label" :for="`portal_group-${group.id}`">
						<input
							class="form-check-input"
							required
							type="radio"
							title="Please specify a group the promo will apply to"
							name="portal_group"
							:id="`portal_group-${group.id}`"
							:value="`${group.id}`"
							data-required-text="Please select a portal group"
							v-model="currentPromoCode.portal_group_id"
						/>
						<span class="form-check-sign"></span>
						{{ group.name }}
					</label>
				</div>
			</fieldset>
		</div>
	</form>
</template>

<script>
import RegistrationOptionIndexVue from './RegistrationOptionIndex.vue'
export default {
data() {
    return {
        ro: {
        }
    };
}
}
</script>