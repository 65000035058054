<script>
import { modalMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/modalMixin";

export default {
    mixins: [modalMixin],
    props: [
        'selectedAdmin',
        'adminForm',
        'adminButton'
    ],
    mounted() {
        const vm = this;

        $('.nav-link').on('hide.bs.tab', evt => {
            const adminForm = document.querySelector(vm.adminForm);
            const adminButton = document.querySelector(vm.adminButton);

            // Validate the form as is
            $(adminForm).validate({
                errorPlacement: function(error, element) {
                    if (element.is(":radio")) {
                        error.addClass('d-flex');
                        error.prependTo(element.parents('fieldset'));
                    } else {
                        error.insertAfter(element);
                    }
                }
            });

            if (evt.relatedTarget.id !== 'admin-company-tab') {
                adminButton.innerHTML = 'Next';
            } else {
                adminButton.innerHTML = 'Save Changes';
            }

            // Validate the data
            if (!$(adminForm).valid()) {
                return false;
            }
        });
    }
}
</script>