export const dtOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    order: [[ 7, "desc" ]],
    columns: [
        {
            data: 'order_number',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        { data: "status" },
        {
            data: "billing_last_name",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
         },
        {
            data: "company",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
         },
        { data: "group" },
        { data: "promo_code" },
        {
            data: "total",
            render: function(data, type, row) {
                return `$${data}`;
            }
        },
        {
            data: "created_at",
            type: "date",
            render: function(data, type, row) {
                return moment(data).format('MM/DD/YYYY h:mm A');
            }
        },
    ]
}
