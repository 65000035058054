<template>
	<div class="mt-6" id="vendor-messages">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1">
			<em>Messages From The Heartland</em>
		</h4>

        <div class="card my-2">
            <portal-dashboard-toggle-bar
                :toggle-bar-text="message.date"
                @click.native="toggleMessage()"
                :is-toggled="showMessage"
            ></portal-dashboard-toggle-bar>
            <div class="card-body" v-if="showMessage" v-html="message.content"></div>
        </div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			showMessage: true
		};
	},
	props: ["message"],
	methods: {
		toggleMessage() {
			return (this.showMessage = !this.showMessage);
		}
	}
};
</script>