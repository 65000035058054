
<template>
    <div class="modal fade" tabindex="-1" role="dialog" :aria-labelledby="modalId" aria-hidden="true" :data-js-id="modalId">
        <div class="modal-dialog modal-dialog-centered" :class="modalSizeClass" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title"><span :class="headerClass"></span>{{ modalTitle }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <nav class="modal-nav px-3 pt-3" aria-label="Portal User tabs" v-if="modalNavOptions">
                    <div class="nav nav-pills" id="portal-user-tab" role="tablist">
                        <a v-for="(navOption, index) in modalNavOptions" :key="index"
                            class="nav-link btn"
                            :class="{active: navOption.active, 'ml-1': !navOption.active}"
                            :title="modalTitle + ' ' + navOption.name"
                            :id="navOption.id"
                            data-toggle="pill"
                            :href="'#' + navOption.tabId"
                            role="tab"
                            :aria-controls="navOption.tabId"
                            aria-selected="true"
                        >{{ navOption.name }}</a>
                    </div>
                </nav>
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        modalId: String,
        headerIcon: String,
        modalTitle: String,
        modalSize: {
            type: String,
            default: 'xl'
        },
        modalNavOptions: Array,
    },
    computed: {
        headerClass() {
            return `fas fa-${this.headerIcon} mr-1 text-tertiary`;
        },
        modalSizeClass() {
            return `modal-${this.modalSize}`
        }
    }
}

</script>
