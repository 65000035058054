<template>
    <div class="card my-2">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0" v-if="!promoIsApplied">Have a promo code?</p>
            <p class="card-title font-weight-bold m-0  d-flex justify-content-between align-items-center" v-else>
                Promo code: {{ appData.promoCodeUsed }} has been applied!
                <button class="btn btn-danger" @click.prevent="removePromoCode">
                    <span class="fa-icon fas-close">&times;</span>
                    <div class="sr-only">Remove Code</div>
                </button>
            </p>
        </div>
        <div class="card-body" v-if="!promoIsApplied">
            <div class="form-group m-0">
                <div class="alert" :class="promoCodeErrorClass" v-if="promoCodeError">{{ promoCodeError }}</div>
                <label for="promo_code">Promo Code</label>
                <div class="input-group">
                    <input
                        class="form-control"
                        type="text"
                        name="promo_code"
                        id="promo_code"
                        v-mask="promoCodeMask"
                        title="Promo Code"
                        v-model="promoCode"
                    />
                    <div class="input-group-append">
                        <button
                            class="btn btn-primary"
                            :disabled="promoCodeValidationProcessing"
                            @click="validateAndApplyPromoCode()"
                        >{{ promoCodeButton }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import PromoCode from '../../../promo-codes';

    export default {
        data() {
            return {
                appData: this.$root.appData,
                promoCode: "",
                promoCodeButton: "Add Promo Code",
                promoCodeValidationProcessing: false,
                promoCodeMask: {
                    mask: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC",
                    tokens: {
                        C: {
                            pattern: /[0-9a-zA-Z]/,
                            transform: v => v.toLocaleUpperCase()
                        }
                    }
                },
                promoCodeError: null,
                promoCodeErrorClass: "warning"
            };
        },
        computed: {
            promoIsApplied() {
                return parseFloat(this.appData.promo_discount_total) > 0;
            },
        },
        mounted() {
            this.removePromoCode()
            this.getQualifiedPromoCodes()
        },
        methods: {
            buildPromoCode() {
                return new PromoCode({
                    code: this.promoCode || this.appData.promoCodeUsed,
                    total: this.appData.subtotal,
                    reservationCount: this.appData.badges.length + this.appData.additional_badges.length,
                    groups: [3]
                })
            },
            getQualifiedPromoCodes() {
				this.buildPromoCode()
					.getQualifiedPromoCodes({
						onSuccess: (res) => {
							if (res.data.data) {
								this.appData.promoCodeUsed = res.data.data.code
								this.validateAndApplyPromoCode()
							}
						}
					})
			},
            validateAndApplyPromoCode() {
                this.buildPromoCode()
                    .validateAndApplyPromoCode({
                        onBefore: () => {
                            this.promoCodeButton = "Validating Promo Code...";
                            this.promoCodeValidationProcessing = true;
                            this.promoCodeError = null;
                        },
                        onSuccess: (res) => {
                            this.resetPromoCodeForm();

                            const data = res.data;

                            if (data.result != "success") {
                                this.promoCodeError = data.message;
                                this.promoCodeErrorClass = `alert-${data.result}`;
                            } else {
                                // If valid update subtotal
                                this.appData.promo_discount_total = data.discount_amount;
                                this.appData.promoCodeUsed = data.promo_code;

                                this.appData.updateTotal();
                            }
                        },
                        onError: (err) => {
                            this.resetPromoCodeForm();
                            logApplicationError(err);
                            console.error(err);
                        }
                    })
            },
            removePromoCode() {
                this.resetPromoCodeForm();

                this.appData.promo_discount_total = 0;
                this.appData.promoCodeUsed = "";

                this.appData.updateTotal();
            },
            resetPromoCodeForm() {
                this.promoCodeButton = "Add Promo Code";
                this.promoCodeValidationProcessing = false;
                this.promoCode = "";
            }
        }
    };
</script>
