<template>
    <div class="card d-inline-flex" style="flex-basis: 200px;">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">Booth Package Information</p>
        </div>
        <div class="card-body">
            <div class="d-flex flex-column text-14">
                <div class="m-0 d-flex justify-content-between flex-wrap">
                    Booth Package
                    <div class="d-flex flex-column ml-2 flex-grow-1">
                        <div class="d-flex justify-content-between flex-wrap border-bottom">
                            <p class="mb-0 text-14 text-quaternary">Name</p>
                            <p class="mb-0 text-14 text-quaternary ml-2">Price</p>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap">
                            <p class="mb-0 text-14 font-weight-bold text-primary">{{selectedPackage.name}}</p>
                            <p class="mb-0 text-14 font-weight-bold text-primary ml-2">${{parseFloat(selectedPackage.base_package_price).toFixed(2)}}</p>
                        </div>
                    </div>
                </div>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    Included Badges
                    <span class="ml-2 text-primary font-weight-bold">{{ selectedPackage.number_of_included_badges }}</span>
                </p>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    Booth Size
                    <span class="ml-2 text-primary font-weight-bold">{{ selectedPackage.booth_size }}</span>
                </p>
                <div class="m-0 d-flex justify-content-between flex-wrap">
                    <p class="m-0">Badges</p>
                    <div class="d-flex flex-column ml-2 flex-grow-1">
                        <div class="d-flex justify-content-between flex-wrap border-bottom">
                            <p class="mb-0 text-14 text-quaternary">Name</p>
                            <p class="mb-0 text-14 text-quaternary ml-2">Price</p>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap" v-for="(badge, index) in appData.badges" :key="index">
                            <p class="mb-0 text-14 font-weight-bold text-primary">{{ getBadgeName(badge) }}</p>
                            <p class="mb-0 text-14 font-weight-bold text-primary ml-2">Included</p>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap" v-for="(badge, index) in appData.additional_badges" :key="index + 10">
                            <p class="mb-0 text-14 font-weight-bold text-primary">{{ getBadgeName(badge) }}</p>
                            <p class="mb-0 text-14 font-weight-bold text-primary ml-2">${{ parseFloat(appData.additional_badge_price).toFixed(2) }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                appData: this.$root.appData
            };
        },
        computed: {
            selectedPackage() {
                return this.appData.eligible_packages.filter(vendorPackage => {
                    return (
                        vendorPackage.id == this.appData.registration.selected_package_id
                    );
                })[0];
            },
        },
        methods: {
            getBadgeName(badge) {
                let firstName = badge.first_name;
                let lastName = badge.last_name;

                if (!firstName && !lastName) {
                    return `-`;
                } else {
                    return `${firstName} ${lastName}`;
                }
            }
        }
    };
</script>