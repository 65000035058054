<template>
    <div class="card my-2">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">Presentation References/Resources</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-md">
                    <label for="content_reference_1">
                        1st content presentation references/resources (not personal references).
                        <br />You can use yourself and years of experience in the topic as one of your resources.
                        <span
                            class="required"
                        >*</span>
                    </label>
                    <textarea
                        id="content_reference_1"
                        name="speaker[course][content_reference_1]"
                        class="form-control"
                        required
                        rows="7"
                        title="Content Reference 1"
                        data-msg="Please enter 1st presentation references."
                        maxlength="5000"
                        v-model="speaker.course.content_reference_1"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
                    <label for="content_reference_2">
                        2nd content presentation references/resources (not personal references).
                        <br />You can use yourself and years of experience in the topic as one of your resources.
                        <span
                            class="required"
                        >*</span>
                    </label>
                    <textarea
                        id="content_reference_2"
                        name="speaker[course][content_reference_2]"
                        class="form-control"
                        required
                        rows="7"
                        title="Content Reference 2"
                        data-msg="Please enter 2nd presentation references."
                        maxlength="5000"
                        v-model="speaker.course.content_reference_2"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
                    <label for="content_reference_3">
                        3rd content presentation references/resources (not personal references).
                        <br />You can use yourself and years of experience in the topic as one of your resources.
                        <span
                            class="required"
                        >*</span>
                    </label>
                    <textarea
                        id="content_reference_3"
                        name="speaker[course][content_reference_3]"
                        class="form-control"
                        required
                        rows="7"
                        title="Content Reference 3"
                        data-msg="Please enter 3rd presentation references."
                        maxlength="5000"
                        v-model="speaker.course.content_reference_3"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'speaker'
        ],
    };
</script>
