<template>

	 <form class="modal-body password-criteria-form" id="attendee-dashboard-diet-edit-form" novalidate>
		 <div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>
		 <div class="card mt-2">
				<div class="card-header">
					<p class="card-title font-weight-bold m-0">What are your dietary restrictions?<span class="required">*</span></p>
				</div>
					<div class="card-body">
					<div class="form-group m-md-0">
						<fieldset>
						<p class="my-2 d-flex flex-column lh-sm">Dietary Options</p>
							<div
								class="custom-control custom-checkbox custom-control-inline"
								v-for="option in props['Dietary'].options"
								:key="option.id"
							>
								<input
									class="custom-control-input"
									type="checkbox"
									name="dietary_options"
									data-msg="Please select a dietary option"
									required
									:title="option.option_name"
									:value="option"
									:id="`option_id_${option.id}`"
									v-model="formData.reservation_options.dietary_options"

								/>
							<label class="custom-control-label m-2" :for="`option_id_${option.id}`">
									{{option.option_name}}
							</label>
							</div>
						</fieldset>
					</div>
					  <button class="btn btn-primary m-1" data-js-id="save-diet-button" :disabled="formProcessing" @click.prevent="saveForm()">{{saveChangesButton}}</button>
				</div>
			</div>

	</form>
</template>

<script>
	import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
 		props: ["reservation", "user", "company", "order", "closeModal", "isAdmin", "props"],
		data() {
			return {
				// currentUser: this.user,
				showErrorDiv: false,
				errorMessage: "",
				formProcessing: false,
                saveChangesButton: "update",
				dietary_options: '',
                formData: {
					reservation: this.reservation,
					user: this.user,
					company: this.company,
					order: this.order,
					isAdmin: this.isAdmin,
					requestIsVpanel: false,
					reservation_options: {dietary_options: {}}
				}
			};
		},
		created() {
			this.formData.reservation_options.dietary_options = [];
		},
		methods: {
			saveForm() {
                const vm = this;

				this.formProcessing = true;
				this.saveChangesButton = "Processing...";
				this.showErrorDiv = false;
				this.errorMessage = "";

				axios
					.post("/heartland/portal/update-diet", this.formData)
					.then(res => {
						this.saveChangesButton="update";
					    window.location.reload();
					})
					.catch(error => {
						this.saveChangesButton="update",
						logApplicationError(error);
						console.error(error);

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								vm.showErrorDiv = true;
								vm.errorMessage = errorsUl.outerHTML;
							} else {
								vm.showErrorDiv = true;
								vm.errorMessage =
									'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
							}
						}

						vm.formProcessing = false;

						console.error(error);
					});
			}
		},
	};
</script>