<script>
import { modalMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/modalMixin";

export default {
    mixins: [modalMixin],
    props: [
        'selectedCourse',
        'modal',
        'allSpeakers',
        'allCategories',
    ],
    mounted() {
        $(`[data-js-id="${this.modalId}"]`).on("hidden.bs.modal", e => {
            document.querySelector('#course-general-tab').click();
        });

        $(`[data-js-id="${this.modalId}"] .nav-link`).on('hide.bs.tab', evt => {
            // Validate the form as is
            $(`[data-js-id="${this.modalId}"] form`).validate({
                errorPlacement: function(error, element) {
                    if (element.is(":radio")) {
                        error.addClass('d-flex');
                        error.prependTo(element.parents('fieldset'));
                    } else {
                        error.insertAfter(element);
                    }
                }
            });

            let button = document.querySelector(`[data-js-id="${this.modalId}"] [data-js-id="save-course-button"]`);

            if (evt.relatedTarget.id !== 'course-ceus-tab') {
                button.innerHTML = 'Next';
            } else {
                button.innerHTML = 'Save';
            }

            // Validate the data
            if (!$(`[data-js-id="${this.modalId}"] form`).valid()) {
                return false;
            }
        });
    }
}
</script>