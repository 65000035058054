<template>
  <div class="alert alert-danger" v-html="appData.validation_message"></div>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData,
        }
    }
}
</script>