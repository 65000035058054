<script>
	import { dataTableMixin } from "../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
	import { dtOfferingOptions } from "./dtOfferingOptions";

	export default {
		mixins: [dataTableMixin],
		props: ["selectedOffering"],
		data() {
			return {
				moduleDatatableOptions: dtOfferingOptions
			};
		},
		mounted() {
			// Add event listeners for buttons
			let dataTable = this.$el;

			const vm = this;

			dataTable.addEventListener("click", evt => {
				let elementClicked = evt.target;
				let id = elementClicked.parentElement.dataset.id;

				switch (elementClicked.dataset.jsId) {
					case "offering-view":
						this.selectedOffering.id = id;

						// Show modal
						// vm.$parent.openOfferingViewModal();
						vm.$parent.openOfferingModal();

						axios
							.get(`/vpanel/modules/optional-offerings/show/${id}`)
							.then(res => {
								try {
									let offering = res.data.offering;

									for (const prop in offering) {
										if (offering.hasOwnProperty(prop)) {
											const value = offering[prop];

											vm.selectedOffering[prop] = value;
										}
									}

									vm.$parent.showViewContent();

									// Update the values in the add/edit modal
									vm.$parent.newOffering = offering;
									vm.$parent.updateInc ++;
								} catch (error) {}
							})
							.catch(error => {
								openApplicationErrorModal(error);
								console.error(error);
							});

						break;
					case "offering-inactivate":
						this.selectedOffering.id = id;

						elementClicked.disabled = true;
						elementClicked.innerText = 'Processing...';

						axios
							.post(`/vpanel/modules/optional-offerings/inactivate/${id}`)
							.then(res => {
                                vm.$parent.reloadDatatable();
                            })
							.catch(error => {
								elementClicked.disabled = false;
								elementClicked.innerText = 'Make Inactive';
								openApplicationErrorModal(error);
								console.error(error);
                            });

                            break;
					case "offering-activate":
						this.selectedOffering.id = id;

						elementClicked.disabled = true;
						elementClicked.innerText = 'Processing...';

						axios
							.post(`/vpanel/modules/optional-offerings/activate/${id}`)
							.then(res => {
                                vm.$parent.reloadDatatable();
                            })
							.catch(error => {
								elementClicked.disabled = false;
								elementClicked.innerText = 'Make Active';
								openApplicationErrorModal(error);
								console.error(error);
                            });

                        break;
				}
			});
		}
	};
</script>
