<template>
    <div>
        <div class="row">
            <div class="col-md">
                <vendor-dashboard-vendor-profile
                    :company="company"
                    :user="user"
                    :is-after-badge-cutoff="isAfterBadgeCutoff"
                    :package-info="packageInfo"
                    :registration="registration"
                    :included-badges="includedBadges"
                    :additional-badges="additionalBadges"
                    :golfers="golfers"
                    :orders="orders"
                    :categories="categories"
                    :order-line-items="orderLineItems"
					:processing-fee="processingFee"
					:vendor-registration="vendorRegistration"
                ></vendor-dashboard-vendor-profile>

                <vendor-dashboard-documents
                    :documents="documents"
            		v-if="documents.length"
				></vendor-dashboard-documents>
			</div>
			<div class="col-md">
				<div v-html="exhibitorMapAsset"></div>

				<vendor-dashboard-agenda-schedule
					:is-attendee="isAttendee"
					:is-after-registration-date="isAfterRegistrationDate"
					:reservation-props="reservationProps"
					:company="company"
					:user="user"
				></vendor-dashboard-agenda-schedule>

				<vendor-dashboard-messages-from-heartland :message="message"></vendor-dashboard-messages-from-heartland>

				<vendor-dashboard-optional-offerings
					:optional-offerings="optionalOfferings"
					:processing-fee="processingFee"
					:user="user"
					v-if="optionalOfferings.length"
				></vendor-dashboard-optional-offerings>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		props: [
			"company",
			"user",
			"isAfterBadgeCutoff",
			"isAfterRegistrationDate",
			"packageInfo",
			"registration",
			"includedBadges",
			"additionalBadges",
			"golfers",
			"documents",
			"message",
			"isAttendee",
			"reservationProps",
            "orders",
            "optionalOfferings",
			"categories",
			"orderLineItems",
			"processingFee",
			"vendorRegistration",
			"exhibitorMapAsset"
		],
		methods: {
			initializeDriverJoyride() {
				// Set up the Driver
				const vendorDriver = new Driver({
					allowClose: false,
					onReset: (Element) => {
						if (!this.registration.joyride_seen) {
							axios.post('/portal/dashboard/vendor/update-joyride-seen')
								.then(res=>{
									console.log("Joyride has successfully been seen for the first time. Congrats!");
								})
								.catch(error=>{
									logApplicationError(error);
									console.error(error);
								})
						}
					}
				});

				// Define Driver steps
				vendorDriver.defineSteps([
					{
						element: '#vendor-site-statistics',
						popover: {
							title: 'Site Statistics',
							description: 'Stay up to date on all the statistics, including who how much traffic your vendor page is receiving',
						}
					},
					{
						element: '#vendor-profile',
						popover: {
							title: 'Vendor Profile',
							description: 'Update your vendor information and upload your company logo so everything is up-to-date on your profile page',
						}
					},
					{
						element: '#vendor-documents',
						popover: {
							title: 'Documents',
							description: 'Find and download the necessary documents that will allow your VGM Heartland experience to run smoothly',
						}
					},
					{
						element: '#vendor-agenda-schedule',
						popover: {
							title: 'Agenda/Schedule',
							description: 'Check out your agenda or switch to the attendee view',
						}
					},
					{
						element: '#vendor-messages',
						popover: {
							title: 'Messages From The Heartland',
							description: 'Get the latest news on the latest developments with VGM Heartland',
						}
					},
					{
						element: '#vendor-optional-offerings',
						popover: {
							title: 'Optional Offerings',
							description: 'Find out the latest offerings available to you to enhance your experience at VGM Heartland',
						}
					},
				]);

				// Init Driver if vendor has not seen it yet after waiting for the page's styling to take place
				if (!this.registration.joyride_seen) {
					setTimeout(function() {
						vendorDriver.start();
					}, 1000)
				}

				// Init Driver if user clicks Driver button after giving fontawesome some time to convert span elements into svgs
				setTimeout(function() {
					const joyrideTriggers = document.querySelectorAll('.js-vendor-driver');

					for (i = 0; i < joyrideTriggers.length; ++i) {
						joyrideTriggers[i].addEventListener('click', function(evt) {
							vendorDriver.start();
							evt.stopPropagation();
						})
					}
				}, 250);
			}
		},
		mounted() {
			// Add date of birth validation
			$.validator.addMethod(
				"dob",
				function(value, element) {
					let isDateFormatValid = moment(value, "MM/DD/YYYY", true).isValid();

					let isDateAfter = moment(value).isAfter("01/01/1900");

					let isAtLeast18 = moment().diff(moment(value), "years") >= 18;

					return isDateFormatValid && isDateAfter && isAtLeast18;
				},
				"Please enter valid date"
			);

			$.validator.addMethod("expdate", function(value, element) {
				let isDateFormatValid = moment(value, "MM/YY", true).isValid();

				let submittedMonth = moment(value, "MM/YY", true).month();
				let currentMonth = moment().month();

				let submittedYear = moment(value, "MM/YY", true).year();
				let currentYear = moment().year();

				let isDateAfter = false;

				let yearIsValid = submittedYear >= currentYear;

				if (yearIsValid) {
					if (submittedYear > currentYear) {
						isDateAfter = true;
					} else if (submittedMonth >= currentMonth) {
                        isDateAfter = true;
                    } else {
                        isDateAfter = false;
                    }
				} else {
					isDateAfter = false;
				}

				return isDateFormatValid && isDateAfter;
			});

			// this.initializeDriverJoyride();
		}
	};
</script>

<style>
</style>
