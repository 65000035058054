<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtAdminOptions } from "./dtAdminOptions";

export default {
    mixins: [dataTableMixin],
    props: [
        'selectedAdmin',
    ],
    data() {
        return {
            moduleDatatableOptions: dtAdminOptions,
        }
    },
    mounted() {
        // Add event listeners for buttons
        let dataTable = this.$el;

        const vm = this;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id = elementClicked.parentElement.dataset.id;

            switch (elementClicked.dataset.jsId) {
                case "admin-modify":

                    vm.selectedAdmin.id = id;

                    // Show modal
                    vm.$parent.openAdminModal();

                    axios.get(`/vpanel/modules/conference/admin/show/${id}`)
                        .then(res => {
                            try {
                                let attendee = res.data.attendee;

                                for (const prop in attendee) {
                                    if (attendee.hasOwnProperty(prop)) {
                                        const value = attendee[prop];

                                        vm.selectedAdmin[prop] = value;
                                    }
                                }

                                vm.$parent.showModalForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                        });

                    break;
            }
        });
    }
}
</script>