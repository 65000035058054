<template>
	<div class="card-body" v-if="showThisForm">
		<form class="password-criteria-form" novalidate data-js-id="complete-profile-form">
			<div v-if="showErrors">
				<div class="alert alert-danger" v-html="formErrors">
				</div>
			</div>

			<div class="row">
				<div class="col">
					<div class="form-group">
						<label for="username">Username</label>
						<input
							class="form-control"
							type="text"
							name="username"
							id="username"
							required
							data-msg-required="Please enter a username"
							data-rule-minlength="5"
							data-msg-minlength="Please use at least 5 characters"
							v-model="formData.username"
						/>
					</div>
					<div class="form-group">
						<label for="password">Password</label>
						<input
							class="form-control"
							type="password"
							name="password"
							id="password"
							required
							data-msg="Please enter password"
							v-model="formData.password"
						/>
					</div>

					<div class="form-group">
						<label for="password_confirmation">Confirm Password</label>
						<input
							class="form-control"
							type="password"
							name="password_confirmation"
							id="password-confirm"
							required
							data-msg="Please confirm password"
							v-model="formData.password_confirmation"
						/>
					</div>
				</div>

				<div class="col d-flex flex-column align-items-end">
					<div class="card bg-light prs-password-requirements">
						<div class="card-header">
							<p class="card-title font-weight-bold m-0">Passwords must contain:</p>
						</div>
						<div class="card-body">
							<ul class="password-criteria-list m-0 text-14">
								<li class="un-met uppercase">One uppercase letter</li>
								<li class="un-met lowercase">One lowercase letter</li>
								<li class="un-met number">One number</li>
								<li class="un-met special-char">One special character: !@#$%^&*()+</li>
								<li class="un-met character-length">Be at least 8 characters</li>
								<li class="un-met passwords-match">Passwords must match</li>
							</ul>
						</div>
					</div>
					<div>
						<button
							class="btn btn-primary mt-2"
							type="submit"
							disabled
							@click.prevent="submitForm()"
						>{{ logInButtonText }}</button>
					</div>
				</div>
			</div>
		</form>

	</div>
	<div class="card-body" v-else>
		<div class="alert alert-danger">
			The link to complete your account creation is invalid. Please call
			<a href="tel:+877-484-6901">877-484-6901</a> for assistance.
		</div>
	</div>
</template>

<script>
	export default {
		props: ["email", "linkCode", "crsfToken", "showForm"],
		data() {
			return {
				formData: {
					email: this.email,
					link_code: this.linkCode,
					_token: this.crsfToken
				},
				logInButtonText: 'Log In',
				showErrors: false,
				formErrors: '',
				showThisForm: true
			};
		},
		methods: {
			submitForm() {
				const vm = this;
				const form = document.querySelector('[data-js-id="complete-profile-form"'); // This needs to be updated because it throws an error after the form element

				vm.logInButtonText = 'Processing...';

				vm.showErrors = false;
				vm.formErrors = '';

				if ($(form).valid()) {
					axios.post('/heartland/portal/complete-profile', this.formData)
						.then(res => {
							window.location.href = res.data.redirect_url;
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == 422) {
									const errors = error.response.data.message;

									let errorsUl = document.createElement('ul');
                    				errorsUl.classList.add('list-unstyled');

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}

									vm.formErrors = errorsUl.outerHTML;
								} else if (error.response.status == 404) {
									vm.showThisForm = false;
								}
							} else {
								vm.formErrors = error.message;
							}

							vm.logInButtonText = 'Log In';
							vm.showErrors = true;

							logApplicationError(error);

							console.error(error);
						})
				} else {
					this.logInButtonText = 'Log In';
				}
			}
		},
		mounted() {
			require("../../../../../core/modules/portal/functions/password-criteria-check");
		}
	};
</script>