var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column h-100"},[_c('div',{staticClass:"dt-section d-flex flex-column p-4 flex-grow-1"},[_c('div',{staticClass:"row mb-2"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"col d-flex justify-content-end align-items-center"},[(!_vm.showDatatable)?_c('button',{staticClass:"btn btn-secondary",on:{"click":function($event){$event.preventDefault();return _vm.closeModifyOrder()}}},[_vm._v("Back To Orders")]):_vm._e(),_vm._v(" "),(_vm.showDatatable)?_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.exportOrders()}}},[_vm._v("Export All Orders")]):_vm._e()])]),_vm._v(" "),(_vm.showDatatable)?_c('div',{staticClass:"dt-section p-0"},[_c('vom-datatable',{attrs:{"table-id":"order-datatable","column-names":[
                    'Order Number',
                    'Order Type',
                    'Username',
                    'RAM',
                    'Status',
                    'Total',
                    'Order Date',
                    'Options' ],"ajax-route":_vm.ajaxRoute,"selected-order":_vm.selectedOrder,"modify-order":_vm.openModifyForm}})],1):_vm._e(),_vm._v(" "),_c('vom-order-modal',{attrs:{"modal-id":"order-modify-modal","modal-title":"Order Details","header-icon":"receipt","modal-size":"lg"}},[_c('div',{staticClass:"modal-body"},[(_vm.showLoader)?_c('div',{staticClass:"vp-loader d-flex flex-column align-items-center text-center"},[_c('p',[_vm._v("Loading...")]),_vm._v(" "),_c('progress')]):_vm._e(),_vm._v(" "),(_vm.showForm)?_c('vom-order-details',{attrs:{"selected-order":_vm.selectedOrder}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"row justify-content-end"},[_c('button',{staticClass:"btn btn-outline-primary cancel-btn",attrs:{"type":"button"},on:{"click":function($event){return _vm.closeOrderModal()}}},[_vm._v("Cancel")])])],1)]),_vm._v(" "),(_vm.modifyOrder)?[_c('vom-modify-order',{attrs:{"selected-order":_vm.selectedOrder,"show-modify-form":_vm.showModifyForm,"close-modify-order":_vm.closeModifyOrder}})]:_vm._e()],2)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col"},[_c('h3',{staticClass:"mb-1"},[_vm._v("Orders")])])}]

export { render, staticRenderFns }