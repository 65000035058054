export const dtManageVendorPackagesOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        { data: "name" },
        {
            data: "booth_size",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "number_of_included_badges",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "base_package_price",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "is_custom",
            render: function(data, type, row) {
                if (data == 1)
                    return `<p class="m-0 text-truncate" title="Yes">Yes</p>`
                else
                    return `<p class="m-0 text-truncate" title="No">No</p>`
            }
        },
        {
            data: "id",
            width: "275px",
            orderable: false,
            searchable: false,
            render: function(data, type, row) {
                let html = `<div class="vendor-options d-flex justify-content-left" data-id="${row.id}">`;

                html += `<button class="btn btn-secondary-blue mr-1" data-js-id="vendor-package-modify">Modify</button>`;
                html += `<button class="btn btn-primary" data-js-id="vendor-package-delete">Delete</button>`;

                html += `</div>`;
                return html;
            }
        }
    ]
}

