<template>
    <div class="dt-section d-flex flex-column p-4 flex-grow-1">
        <div class="row mb-2">
            <div class="col">
                <h3 class="mb-1">Optional Offering Submissions</h3>
            </div>
        </div>

        <voo-submissions-datatable
            table-id="offering-submissions-datatable"
            :column-names='[
                    "Offering Name",
                    "Username",
                    "Order Number",
                    "Submitted On",
                    "Options",
                ]'
            :ajax-route="submissionAjaxRoute"
            :viewing-offering="viewingOffering"
        ></voo-submissions-datatable>

        <voo-view-modal
			modal-id="oo-view-modal"
			modal-title="View Optional Offering"
			header-icon="table"
			modal-size="lg"
		>
			<div class="modal-body">
				<div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showViewLoader">
					<p>Loading...</p>
					<progress></progress>
				</div>
				<voo-submission-view-content
					:selected-offering="viewingOffering"
					v-if="displayViewContent"
				>

				</voo-submission-view-content>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeOfferingViewModal()">Cancel</p>
				<p class="btn btn-primary m-1 modify-button" @click="updateOffering()">Modify</p>
			</div>
		</voo-view-modal>
    </div>
</template>

<script>
export default {
    props: [
        'submissionAjaxRoute'
    ],
    data() {
        return {
            viewingOffering: {},
            showViewLoader: true,
            displayViewContent: false,
        }
    },
    computed: {
        viewModal() {
            return document.querySelector('[data-js-id="oo-view-modal"]');
        }
    },
    methods: {
        openOfferingViewModal() {
            $(this.viewModal).modal('show');
        },
        closeOfferingViewModal() {
            $(this.viewModal).modal('hide');
        },
        showViewContent() {
            this.showViewLoader = false;
            this.displayViewContent = true;
        },
        hideViewContent() {
            this.showViewLoader = true;
            this.displayViewContent = false;
        },
        updateOffering() {
            const vm = this;
            const target = event.target;

            target.innerText = 'Processing...';
            target.disabled = true;

            axios.post(`/vpanel/modules/optional-offerings/update/${this.viewingOffering.id}`, this.viewingOffering)
                .then(res => {
                    target.innerText = 'Modify';
                    target.disabled = false;

                    vm.closeOfferingViewModal();

                    showNotification(res.data.message, res.data.result);
                })
                .catch(error => {
                    openApplicationErrorModal(error, '');
                    console.error(error);
                });
        }
    },
    mounted() {
        $(this.viewModal).on("hidden.bs.modal", e => {
            this.hideViewContent();
        });
    }
}
</script>
