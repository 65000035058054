<script>
import { dataTableMixin } from "../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtOrderOptions } from "./dtOrderOptions";

export default {
    mixins: [dataTableMixin],
    props: [
        'selectedOrder',
        'modifyOrder'
    ],
    data() {
        return {
            moduleDatatableOptions: dtOrderOptions,
        }
    },
    mounted() {
        // Add event listeners for buttons
        let dataTable = this.$el;

        const vm = this;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id = elementClicked.parentElement.dataset.id;

            switch (elementClicked.dataset.jsId) {
                case "order-show":

                    vm.selectedOrder.id = id;

                    // Show modal
                    vm.$parent.openOrderModal();

                    axios.get(`/vpanel/modules/order-management/show/${id}`)
                        .then(res => {
                            try {
                                let order = res.data.order;

                                for (const prop in order) {
                                    if (order.hasOwnProperty(prop)) {
                                        const value = order[prop];

                                        vm.selectedOrder[prop] = value;
                                    }
                                }

                                vm.$parent.showModalForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                        });

                    break;

                case "order-modify":
                    vm.selectedOrder.id = id;

                    vm.$parent.openModifyForm();

                    axios.get(`/vpanel/modules/order-management/show/${id}`)
                        .then(res => {
                            try {
                                let order = res.data.order;

                                for (const prop in order) {
                                    if (order.hasOwnProperty(prop)) {
                                        const value = order[prop];

                                        vm.selectedOrder[prop] = value;
                                    }
                                }

                                vm.$parent.showModifyForm = true;
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                        });
                    break;
            }
        });
    }
}
</script>