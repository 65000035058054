<template>
    <div class="agenda-section dt-section">
        <div class="agenda-index-buttons mb-3">
            <button class="btn btn-primary" id="js-new-agenda-tag-button" @click.prevent="openAddModifyModal('add')">Add {{ currentModule }}</button>
        </div>
        <VpanelAgendaTagsListDatatable
            table-id="agenda-tags-list-datatable"
            :column-names='[
                "Name",
                "Type",
                "Agenda",
                "Status",
                "Options",
            ]'
            :ajax-route="ajaxRoute"
            :agendas="agendas"
            :selected-tag="selectedTag"
		/>

        <AgendaTagsAddModifyModal
			modal-id="agenda-tags-add-modify-modal"
			modal-title="Manage Categories/Tracks"
			header-icon="tags"
			modal-size="md"
			:agendas="agendas"
            :selected-tag="selectedTag"
		>
			<div class="modal-body">
				<div class="row" v-if="formHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="formErrorMessage"></div>
					</div>
				</div>

				<div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showLoader">
					<p>Loading...</p>
					<progress></progress>
				</div>
				<form class="d-flex flex-column" v-if="showForm" data-js-id="agenda-tags-add-modify-form" novalidate>
					<AgendaTagsAddModifyForm :selected-tag="selectedTag" :agendas="agendas" />
				</form>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" :disabled="formProcessing" @click="closeAddModifyModal()">Cancel</p>
				<p class="btn btn-primary m-1" data-js-id="add-modify-agenda-tag-button" @click.prevent="addModifyTag()">Save</p>
			</div>
		</AgendaTagsAddModifyModal>

    </div>
</template>

<script>
	export default {
		name: "AgendaTagsSection",
        props: [ "currentModule", "agendas", "ajaxRoute" ],
        data() {
			return {
				showForm: false,
				showLoader: true,
				formErrorMessage: "",
				formHasError: false,
				actionIsAdd: false,
				formProcessing: false
			};
		},
        computed: {
			selectedTag() {
				return{
					agendaIds: []
				};
			},
			saveTagButon() {
				return document.querySelector('[data-js-id="add-modify-agenda-tag-button"]');
			},
			modifyButton() {
				return document.querySelector('[data-js-id="agenda-tags-modify"]');
			},
			addModifyModal() {
				return document.querySelector('[data-js-id="agenda-tags-add-modify-modal"]');
			},
			addModifyForm() {
				return document.querySelector('[data-js-id="agenda-tags-add-modify-form"]');
			},
			dataTable() {
				return document.querySelector('#agenda-tags-list-datatable');
			}
		},
		methods: {
			openAddModifyModal(action) {
				if (action == 'add') {
					this.showAddModifyForm();
					this.actionIsAdd = true;
				} else {
					this.actionIsAdd = false;
					this.hideForm();
				}

				$(this.addModifyModal).modal('show');
			},
			closeAddModifyModal() {
				this.hideForm();
				if (this.actionIsAdd === false) {
					// Clear form fields
					for (const name of Object.keys(this.selectedTag)) {
						this.selectedTag[name] = '';
					}
				}
				$(this.addModifyModal).modal('hide');
			},
			hideForm() {
				this.showForm 	= false;
				this.showLoader = true;
			},
			showAddModifyForm() {
				this.showForm	= true;
				this.showLoader = false;
			},
			addModifyTag() {
				const sm			= this;
				const addModifyForm = sm.addModifyForm;
				const saveButton 	= sm.saveTagButon;
				let url;

				$(addModifyForm).validate({
					errorPlacement: function(error, element) {
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.addClass('text-danger');
							error.prependTo(element.parents("fieldset"));
						} else {
							error.addClass('text-danger');
							error.insertAfter(element);
						}
					}
				});

				if ($(addModifyForm).valid()) {
					sm.formProcessing  = true;
					saveButton.innerText = 'Processing...';

					if (sm.actionIsAdd === true) {
						url = '/vpanel/modules/agenda/tags/store';
					} else {
						url = '/vpanel/modules/agenda/tags/update/${id}';
					}

					axios.post(url, sm.selectedTag)
						.then(res => {
							const data = res.data;

							showNotification(data.message, data.result);
							sm.formProcessing 	 = false;
							saveButton.innerText = 'Save';

							$(sm.addModifyModal).modal("hide");

							// Clear form fields
							for (const name of Object.keys(sm.selectedTag)) {
								if (name != 'courseIds') {
									sm.selectedTag[name] = '';
								}
							}

							$(sm.dataTable).DataTable().ajax.reload();
						})
						.catch(error =>{
							if (error.response) {
								if (error.response.status == '422') {
									const errors 	   = error.response.data[0];
									const errorMessage = error.response.data.message;
									sm.formHasError    = true;

									let errorsUl = document.createElement('ul');
									errorsUl.classList.add("list-styled");

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}
									sm.formErrorMessage = errorMessage + errorsUl.outerHTML;
								} else {
									openApplicationErrorModal(error, "");
									console.error(error);
								}
							}

							sm.formProcessing 	 = false;
							saveButton.innerText = 'Save';

							console.error(error);
						});
				}
            }
        }
	};
</script>