<template>
	<div>
		<div class="row">
			<div class="col-md">
				<div class="row">
					<!-- Credit Card Number -->
					<div class="form-group col-md">
						<label for="credit_card">
							Credit Card Number
							<span class="required">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="credit_card"
							title="Credit Card Number"
							id="credit_card"
							v-model="paymentDetails.credit_card_number"
							v-mask="'#### #### #### ####'"
							required
							data-msg="Please enter a Credit card Number"
						/>
					</div>

					<!-- CVV Code -->
					<div class="form-group col-md">
						<label for="cvv_code">
							CVV Code
							<span class="required">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="cvv_code"
							title="CVV Code"
							id="cvv_code"
							v-model="paymentDetails.cvv_code"
							v-mask="'####'"
							required
							data-msg="Please enter a CVV Code"
						/>
					</div>

					<!-- Expiration Date -->
					<div class="form-group col-md">
						<label for="expiration_date">
							Expiration Date
							<span class="required">*</span>
						</label>
						<input
							class="form-control js-flatpickr-exp-date"
							type="text"
							id="expiration_date"
							name="expiration_date"
							title="Expiration Date"
							v-model="paymentDetails.expiration_date"
							v-mask="'##/##'"
							data-msg-expdate="Please enter valid date"
							data-rule-expdate="true"
							placeholder="mm/yy"
							required
							data-msg-required="Please enter a date"
						/>
					</div>
				</div>

				<div class="row">
					<!-- First Name -->
					<div class="form-group col">
						<label for="card_first_name">
							First Name
							<span class="required">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="card_first_name"
							title="first name"
							id="card_first_name"
							v-model="paymentDetails.first_name"
							required
							data-msg="Please enter a first name"
						/>
					</div>

					<!-- Last Name -->
					<div class="form-group col">
						<label for="card_last_name">
							Last Name
							<span class="required">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="card_last_name"
							title="ast name"
							id="card_last_name"
							v-model="paymentDetails.last_name"
							required
							data-msg="Please enter a last name"
						/>
					</div>
				</div>

				<div class="row">
					<div class="form-group col">
						<label for="card_address_1">
							Address
							<span class="required">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="card_address_1"
							title="Address"
							id="card_address_1"
							v-model="paymentDetails.address.address_1"
							required
							data-msg="Please enter a Address"
						/>
					</div>
					<div class="form-group col">
						<label for="card_address_2">Address 2</label>
						<input
							class="form-control"
							type="text"
							name="card_address_2"
							title="Address"
							id="card_address_2"
							v-model="paymentDetails.address.address_2"
						/>
					</div>
				</div>

				<div class="row">
					<div class="form-group col">
						<label for="card_city">
							City
							<span class="required">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="card_city"
							title="City"
							id="card_city"
							v-model="paymentDetails.address.city"
							required
							data-msg="Please enter a City"
						/>
					</div>
					<div class="form-group col">
						<label for="card_state_province">
							State/Province
							<span class="required">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="card_state_province"
							id="card_state_province"
							title="State/Province"
							v-model="paymentDetails.address.state_province"
							required
							data-msg="Please enter a State/Province"
						/>
					</div>

					<div class="form-group col">
						<label for="card_postal_code">
							Postal Code
							<span class="required">*</span>
						</label>
						<input
							class="form-control"
							type="text"
							name="card_postal_code"
							id="card_postal_code"
							title="Postal Code"
							v-model="paymentDetails.address.postal_code"
							required
							data-msg="Please enter a postal code"
						/>
					</div>
				</div>
			</div>
			<div class="col-md-4 flex-column">
				<p class="font-weight-bold m-0">Order Info</p>
				<div class="d-flex justify-content-between">
					<p class="m-0">
						<strong>Subtotal</strong>
					</p>
					<p class="m-0">${{ parseFloat(subtotal).toFixed(2) }}</p>
				</div>
                <div class="d-flex justify-content-between">
					<p class="m-0">
						<strong>Discount</strong>
					</p>
					<p class="m-0">
						$
						<input
							type="number"
							step="0.01"
							min="0.00"
							v-model="expoDiscount"
						/>
					</p>
				</div>
				<div class="d-flex justify-content-between border-top mt-1 pt-1">
					<p class="m-0">
						<strong>Total</strong>
					</p>
					<p class="m-0">${{ parseFloat(displayTotal).toFixed(2) }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
        props: ["paymentDetails", "subtotal", "total", "discount"],
        data() {
            return {
                expoDiscount: this.discount
            }
        },
		computed: {
            displayTotal() {
                let newTotal = this.subtotal - this.expoDiscount;
                return newTotal >= 0 ? parseFloat(newTotal).toFixed(2) : 0.0;
            }
        }
	};
</script>
