<template>
    <div>
        <small>To modify any of these reservations, please go to Conference Management > Attendees</small>

        <div class="row" v-for="(item, index) in orderItems" :key="index">
            <div class="col-12">
                <h6 class="border-top-1 pt-1">Attendee #{{ index + 1 }} | Badge # {{ item.portal_user ? item.portal_user.badge_id : '-' }}</h6>
            </div>
            <div class="col-3" v-for="(property, i) in item.formatted_properties" :key="`prop_${i}`">
                <div class="row flex-column">
                    <div class="col">
                        <strong>{{ i }}</strong>:
                    </div>

                    <div class="col">
                        <template v-if="property.options_selected.length">
                            {{ printOptions(property.options_selected) }}
                        </template>
                        {{ propertyValue(property) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["orderItems", "selectedOrder"],
        methods: {
            propertyValue(property) {
                return property.options_selected.length ? property.options_selected.flat().map(item => item.name).join(', ') : property.value
            },
            printOptions(selectedOptions) {
                let optionsArray = [];

                for (let index = 0; index < selectedOptions.length; index++) {
                    optionsArray.push(selectedOptions[index].name);
                }

                return optionsArray.join(',');
            }
        }
    };
</script>