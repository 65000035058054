<template>
    <div>
        <div class="text-right my-2">
            <button class="btn btn-secondary-blue" @click.prevent="exportTranscriptsAsPDF">Batch Transcripts PDF</button>
            <button class="btn btn-primary" @click.prevent="exportReport">Export Full Report</button>
        </div>
        <vrtr-datatable
            table-id="transcript-report"
            :column-names='[
                "Badge ID",
                "First Name",
                "Last Name",
                "Session Title",
                "Attendance Met",
                "Evaluation"
            ]'
            :ajax-route="ajaxRoute"
        ></vrtr-datatable>
    </div>
</template>

<script>
    export default {
        props: [
            'ajaxRoute'
        ],
        methods: {
            exportTranscriptsAsPDF() {
                const vm = this;
                const thisEvent = event;

                let button = thisEvent.target;

                button.innerText = 'Processing...';
                button.disabled = true;

                axios.get("/vpanel/modules/reporting/export/all-transcript-report-as-pdf")
                    .then(response => {
                        showNotification('We\'re processing your request. An email will be sent upon completion.', 'info');

                        button.innerText = "Batch Transcripts PDF";
                        button.disabled = false;
                    })
                    .catch(error => {
                        openApplicationErrorModal(error, "");
                        console.error(error);
                        button.innerText = "Batch Transcripts PDF";
                        button.disabled = false;
                    });
            },
            exportReport() {
                const vm = this;
                const thisEvent = event;

                let button = thisEvent.target;

                button.innerText = 'Processing...';
                button.disabled = true;

                axios({url: "/vpanel/modules/reporting/export/transcript-report", method: "GET", responseType: "blob"})
                    .then(response => {
                        let blob = new Blob([response.data]);
                        let date = moment().format('L');

                        // For IE
                        if (typeof window.navigator.msSaveBlob !== "undefined") {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            // Creates a link with a url to the created blob, clicks that link, then removes it from the page
                            const url  = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href  = url;
                            link.setAttribute("download", `${date}-transcript-report.xlsx`);
                            document.body.appendChild(link);
                            link.click();

                            link.remove();
                        }

                        button.innerText = "Export Full Report";
                        button.disabled = false;
                    })
                    .catch(error => {
                        openApplicationErrorModal(error, "");
                        console.error(error);
                        button.innerText = "Export Full Report";
                        button.disabled = false;
                    });
            }
        }
    };
</script>