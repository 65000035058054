<template>
	<div class="card my-2 mb-2">
		<div class="card-footer d-flex justify-content-end border-top-0">
			<p class="m-0"><strong>Subtotal =</strong> {{ (getSubTotal).toFixed(2) }}</p>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	computed: {
		...mapGetters("registration", ['getSubTotal'])
	},
};
</script>