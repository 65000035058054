//== Functionality for DataTable on list view ==//
export const dtAgendaListOptions = {
    scrollY: '0',
    scrollCollapse: 'true',

    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",

    columns: [
        // Title
        {
            data: 'title',
            width: '30%',
        },
        // Start Date/Time
        {
            data: 'start_date_time',
            render: function(data, type, row) {
                return data ? moment(data).format('M/D/YYYY h:mm A') : 'N/A';
            }
        },
        // Categories (hardcoded ones aka event types)
        {
            data: 'event_type_id',
            render: function(data, type, row) {
                let html = 'N/A';
                if (data.length) {
                    html = '';
                    data.forEach(type => {
                        html = `<p class="m-0">${type.name}</p>`;
                    });
                }
                return html;
            }
        },

        // Status
        {
            data: 'active',
            render: function(data, type, row) {
                return data ? '<p class="text-success m-0"><strong>Active</strong></p>' : '<p class="text-danger m-0"><strong>Inactive</strong></p>';
            }
        },
        // Options
        {
            data: 'options',
            width: "160px",
            render: function(data, type, row) {
                return  '<div class="agenda-options" data-id="' + row.id + '">' +
                            '<p class="agenda-recycle btn btn-danger mr-1">Recycle</p>' +
                            '<p class="agenda-modify btn btn-secondary-blue">Modify</p>' +
                        '</div>';
            }
        }
    ],
}