export const dtOfferingOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        { data: 'name' },
        { data: "portal_group_name" },
        {
            data: "description",
            width: "30%",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "active",
            render: function(data, type, row) {
                return data == 1 ? `<span class="text-success"><strong>Active</strong></span>` : `<span class="text-danger"><strong>Inactive</strong></span>`;
            }
        },
        {
            data: "created_at",
            render: function(data, type, row) {
                return moment(data).format('M/D/YYYY h:mm A');
            }
        },
        {
            data: "id",
            orderable: false,
            searchable: false,
            render: function(data, type, row) {
                let activeButton = `<button class="btn btn-secondary" data-js-id="` + (row.active == 1 ? `offering-inactivate">Make Inactive` : `offering-activate">Make Active`) + `</button>`;
                
                let afterRegistration = ((new Date()).getTime() - (new Date(row.registration_start_date)).getTime()) > 0

                return  `<div class="offering-options d-flex" data-id="${row.id}">
                            <button class="btn btn-secondary-blue mr-1" data-js-id="offering-view">${afterRegistration ? 'View' : 'Edit'}</button>
                            ${activeButton}
                        </div>`;
            }
        }
    ]
}

