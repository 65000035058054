<template>
  <div>
      <h4 class="font-weight-bold mt-0">Modify Order</h4>

      <template v-if="showModifyForm">
          <h5>Details</h5>
          <vom-order-details :selected-order="currentOrder"></vom-order-details>
          <div class="my-2 border p-2">
              <h5>Order Items</h5>
              <form data-js-id="order-items-form">
                  <input type="hidden" name="type" :value="currentOrder.order_type">
                  <input type="hidden" name="id" :value="currentOrder.id">
                  <vom-order-items :selected-order="currentOrder"></vom-order-items>
              </form>
          </div>
          <div class="my-2 border p-2">
              <h5>Refunds</h5>
              <vom-order-actions :selected-order="currentOrder"></vom-order-actions>
          </div>
      </template>

      <template v-else>
          <vpanel-loader loader-message="Getting Order Details..."></vpanel-loader>
      </template>
  </div>
</template>

<script>
export default {
    props: ['selectedOrder', 'showModifyForm', 'closeModifyOrder'],
    data() {
        return {
            currentOrder: this.selectedOrder,
        }
    }
}
</script>
