<template>
    <div class="row">
        <div class="col-md">
            <p class="h6">
                Upload Image
                <span class="required" v-if="fieldsRequired">*</span>
            </p>
            <div class="d-flex flex-column">
                <div class="border rounded sponsor-image d-flex align-items-center justify-content-center p-1 bg-white" style="width:100%">
                    <img :src="currentSponsor.logo_path" id="image_preview" alt="placeholder" v-if="currentSponsor.logo_path">
                    <div class="no-image" v-else id="image_preview">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M464 448H48c-26.51 0-48-21.49-48-48V112c0-26.51 21.49-48 48-48h416c26.51 0 48 21.49 48 48v288c0 26.51-21.49 48-48 48zM112 120c-30.928 0-56 25.072-56 56s25.072 56 56 56 56-25.072 56-56-25.072-56-56-56zM64 384h384V272l-87.515-87.515c-4.686-4.686-12.284-4.686-16.971 0L208 320l-55.515-55.515c-4.686-4.686-12.284-4.686-16.971 0L64 336v48z"/></svg>
                    </div>
                </div>
                <div class="mt-2">
                    <input
                        disabled
                        type="text"
                        id="logo_image_path"
                        class="form-control"
                        name="logo_image_path"
                        data-js-id="logo_image_path"
                        v-model="currentSponsor.logo_path"
                    >
                    <div class="my-1">
                        <button class="btn btn-tertiary mr-1"  id="choose_image" data-input="logo_image_path" data-preview="image_preview" title="Opens the file manager to choose to upload a new image or choose from your image library" @click.prevent="openSponsorFileManager()">Choose Logo</button>
                        <button class="btn btn-outline-primary" id="remove_image" title="Clears the current image" @click.prevent="removeImage()">Delete Image</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md">
            <div class="form-group" v-if="actionIsAdd">
                <label for="name">Company Name<span class="required">*</span></label>
                <select
                    required
                    id="name"
                    name="name"
                    class="form-control"
                    title="Select a company"
                    data-msg="Select a company"
                    @change="checkForAddNewValue"
                    v-model="currentSponsor.vendor"
                >
                    <option value>-- Select a company --</option>
                    <option value="new">Add New</option>
                    <option
                        v-for="(vendor, index) in allVendors"
                        :key="index"
                        :value="index"
                    >{{ vendor }}</option>
                </select>
            </div>
            <div class="form-group" v-if="showAddNewCompanyField || !actionIsAdd">
                <label for="new_name">{{ actionIsAdd ? "Add New Company" : 'Company Name' }}<span class="required">*</span></label>
                <input
                    required
                    type="text"
                    id="new_name"
                    name="new_name"
                    class="form-control"
                    v-model="currentSponsor.name"
                    title="Please enter a new company name"
                    data-msg="Please provide a new company name"
                />
            </div>
            <div class="form-group">
                <label for="website">
                    Website
                    <span class="required">*</span>
                </label>
                <input
                    required
                    type="text"
                    id="website"
                    name="website"
                    class="form-control"
                    title="Please enter a website"
                    v-model="currentSponsor.website"
                    data-msg="Please provide a website"
                />
            </div>
            <div class="form-group">
                <label for="sponsorship_level">
                    Sponsorship Level
                    <span class="required">*</span>
                </label>
                <select
                    required
                    class="form-control"
                    id="sponsorship_level"
                    name="sponsorship_level"
                    title="Select a  sponsorship level"
                    data-msg="Select a sponsorship level"
                    v-model="currentSponsor.sponsorship_level_id"
                >
                    <option value>-- Select a Sponsorship Level --</option>
                    <option
                        v-for="(sponsorship, index) in sponsorshipLevels"
                        :key="index"
                        :value="sponsorship.id"
                    >{{ sponsorship.level }}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="sort_order">Sort Order</label>
                <input
                    type="text"
                    id="sort_order"
                    name="sort_order"
                    class="form-control"
                    v-model="currentSponsor.sort_order"
                    title="Please enter a sort order"
                />
            </div>
            <div class="form-group">
                <div class="radio-legend d-flex justify-content-between align-items-center">
                    <label>Active<span class="required">*</span></label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="This will show/hide this sponsor from the front end listing"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <fieldset>
                    <legend class="label sr-only">Active</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label mr-1" for="active_yes">
                            <input
                                required
                                value="1"
                                type="radio"
                                name="active"
                                id="active_yes"
                                class="form-check-input"
                                title="Select an option"
                                v-model="currentSponsor.active"
                                data-msg="Please Select an Option"
                            />
                            <span class="form-check-sign"></span>
                            Yes
                        </label>
                        <label class="form-check-label" for="active_no">
                            <input
                                required
                                value="0"
                                type="radio"
                                name="active"
                                id="active_no"
                                class="form-check-input"
                                title="Select an option"
                                v-model="currentSponsor.active"
                                data-msg="Please Select an Option"
                            />
                            <span class="form-check-sign"></span>
                            No
                        </label>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>
	import { openFileManager } from "../../../../../core/vpanel/vpanel-functions";

	export default {
        props: [
            "allVendors",
            "actionIsAdd",
            "currentSponsor",
            "sponsorshipLevels"
        ],
        data() {
            return{
                fieldsRequired: true,
                showAddNewCompanyField: false
            }
        },
        computed: {
			chooseImageButton() {
				return document.querySelector('#choose_image');
			},
			imagePreview() {
				return document.querySelector('#image_preview');
			},
			imageInput() {
				return document.querySelector('[data-js-id="logo_image_path"]');
			}
        },
		methods: {
			openSponsorFileManager() {
				openFileManager(this.chooseImageButton, (url, file_path) => {
				    this.imageInput.value = url;
                    Vue.set(this.currentSponsor, 'logo_path', url);
                });
			},
			removeImage() {
				this.imageInput.value = '';
                Vue.set(this.currentSponsor, 'logo_path', null);
			},
            checkForAddNewValue() {
                if (this.currentSponsor.vendor == 'new') {
                    this.fieldsRequired         = true;
                    this.showAddNewCompanyField = true;
                } else {
                    this.showAddNewCompanyField = false;
                    this.fieldsRequired         = false;
                }
            }
		}
	};
</script>
<style lang="scss" scoped>
    .sponsor-image {
        margin-right: 1rem;

        img, svg {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }

        .no-image {
            font-size: 32px;
            position: relative;
            width: 100%;

            > span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
</style>