<template>
    <div class="cm-promo-code-setcion dt-section d-flex flex-column p-4 flex-grow-1">
        <div class="row mb-2">
            <div class="col">
                <h3 class="mb-1">{{ selectedConference.year }} Promo Codes</h3>
            </div>
            <div class="col d-flex justify-content-end align-items-center">
                <button class="btn btn-primary" @click.prevent="openPromoCodeModal('new')">Add New Promo Code</button>
            </div>
        </div>

        <cm-pc-datatable
            table-id="promo-code-datatable"
            :column-names='[
                "Code",
                "Group",
                "Active",
                "Expiration Date",
                "Created On",
                "Options",
            ]'
            :ajax-route="ajaxRoute"
            :selected-promo-code="selectedPromoCode"
        ></cm-pc-datatable>

        <cm-pc-modal
            modal-id="cm-promo-codes-add-modify-modal"
            modal-title="Promo Codes"
            header-icon="tag"
            modal-size="lg"
            :selected-promo-code="selectedPromoCode"
        >
            <div class="modal-body">
                <div class="row" v-if="formHasError">
                    <div class="col">
                        <div class="alert alert-danger" v-html="formErrorMessage"></div>
                    </div>
                </div>

                <div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showLoader">
                    <p>Loading...</p>
                    <progress></progress>
                </div>

                <cm-pc-form v-if="showForm" :selected-promo-code="selectedPromoCode" :portal-groups="portalGroups"
                    :conference-pass-options="conferencePassOptions">
                </cm-pc-form>
            </div>

            <div class="modal-footer d-flex justify-content-end mx-n1">
                <p class="btn btn-outline-primary m-1 cancel-button" @click="closePromoCodeModal()">Cancel</p>
                <p class="btn btn-primary m-1" :disabled="formProcessing" @click.prevent="savePromoCode()">
                    {{ saveChangesButton }}
                </p>
            </div>
        </cm-pc-modal>

        <cm-pc-recycle-modal modal-id="cm-promo-codes-recycle-modal" modal-title="Recycle Promo Code" header-icon="tag">
            <div class="modal-body pb-3">
                <div class="d-flex flex-column align-items-center">
                    <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
                    <p class="h4 font-weight-bold">Are you sure you wish to recycle this Promo Code?</p>
                    <p>This action <strong>cannot be undone</strong>.</p>

                    <div class="d-flex justify-content-center">
                        <button type="button" class="btn btn-outline-primary cancel-btn"
                            @click.prevent="closePromoCodeRecycleModal()">Cancel</button>
                        <button type="button" class="btn btn-primary confirm-btn ml-2" :disabled="recycleFormProcessing"
                            @click.prevent="recyclePromoCode()">{{ recycleCodeButton }}</button>
                    </div>
                </div>
            </div>
        </cm-pc-recycle-modal>
    </div>
</template>

<script>
export default {
    props: ["conferences", "currentConference", "ajaxRoute", "crsf", "portalGroups", "conferencePassOptions"],
    data() {
        return {
            saveChangesButton: "Save Changes",
            formProcessing: false,
            recycleCodeButton: "Yes, please recycle this Promo Code",
            recycleFormProcessing: false,
            formErrorMessage: "",
            formHasError: false,
            showForm: true,
            showLoader: false,
            recyclePromoCodeId: null,
        }
    },
    computed: {
        selectedConference() {
            return this.currentConference;
        },
        selectedPromoCode() {
            return {
                conference_uuid: this.currentConference.uuid,
                required_number_of_attendees: 1,
                max_required_number_of_attendees: null,
                reservation_property_definition_option_id: 0,
                dollar_amount_off: "0.00",
                minimum_subtotal_required: "0.00",
            };
        },
        modal() {
            return document.querySelector('[data-js-id="cm-promo-codes-add-modify-modal"]');
        },
        recycleModal() {
            return document.querySelector('[data-js-id="cm-promo-codes-recycle-modal"]');
        },
        form() {
            return document.querySelector('[data-js-id="cm-promo-codes-add-modify-form"]');
        },
        datatable() {
            return document.querySelector('#promo-code-datatable');
        },
    },
    methods: {
        openPromoCodeModal(promoCodeType) {
            if (promoCodeType == 'new') {
                this.showModalForm();
            } else {
                this.hideModalForm();
            }

            $(this.modal).modal('show');
        },
        closePromoCodeModal() {
            $(this.modal).modal('hide');
        },
        savePromoCode() {
            // Need to determine if this is new or update
            let url = '';

            const vm = this;

            if (this.selectedPromoCode.id) {
                url = '/vpanel/modules/conference/promo-codes/update'
            } else {
                url = '/vpanel/modules/conference/promo-codes/create'
            }

            $(this.form).validate({
                errorPlacement: function (error, element) {
                    if (element.is(":radio")) {
                        error.addClass('d-flex');
                        error.prependTo(element.parents('fieldset'));
                    } else {
                        error.insertAfter(element);
                    }
                }
            });

            if ($(this.form).valid()) {
                this.formProcessing = true;
                this.saveChangesButton = 'Processing...';
                this.formErrorMessage = '';
                this.formHasError = false;

                axios.post(url, Object.assign(this.selectedPromoCode, {reservation_property_definition_option_id: Number(this.selectedPromoCode.reservation_property_definition_option_id)}))
                    .then(res => {
                        const data = res.data;
                        showNotification(data.message, data.result);

                        vm.formProcessing = false;
                        vm.saveChangesButton = 'Save Changes';

                        $(vm.modal).modal('hide');

                        $(vm.datatable).DataTable().ajax.reload();
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == '422') {
                                vm.formHasError = true;
                                vm.formErrorMessage = error.response.data.message;

                                let errorsUl = document.createElement('ul');
                                errorsUl.classList.add('list-unstyled');

                                for (const errorObj in error.response.data[0]) {
                                    let prop = `${errorObj}`;
                                    errorsUl.innerHTML += `<li>${error.response.data[0][prop]}</li>`;
                                    document.querySelector(`[name="${errorObj}"]`).parentElement.classList.add('has-danger');
                                    $(vm.form).validate().showErrors({
                                        [prop]: `${error.response.data[0][prop]}`
                                    })
                                }

                                vm.formErrorMessage += errorsUl.outerHTML;
                            } else {
                                openApplicationErrorModal(error, '');
                            }
                        }

                        vm.formProcessing = false;
                        vm.saveChangesButton = 'Save Changes';

                        console.error(error);
                    });
            }
        },
        recyclePromoCode() {
            const vm = this;

            axios.post(`/vpanel/modules/conference/promo-codes/recycle/${vm.recyclePromoCodeId}`)
                .then(res => {
                    const data = res.data;

                    $(vm.datatable).DataTable().ajax.reload();
                    vm.closePromoCodeRecycleModal();

                    showNotification(data.message, data.result);
                })
                .catch(error => {
                    openApplicationErrorModal(error, '');

                    console.error(error);
                })
        },
        showModalForm() {
            this.showForm = true;
            this.showLoader = false;
        },
        hideModalForm() {
            this.showForm = false;
            this.showLoader = true;
        },
        openPromoCodeRecycleModal(id) {
            this.recyclePromoCodeId = id;
            $(this.recycleModal).modal('show');
        },
        closePromoCodeRecycleModal() {
            this.recyclePromoCodeId = null;
            $(this.recycleModal).modal('hide');

            this.recycleFormProcessing = false;
            this.recycleCodeButton = 'Yes, please recycle this Promo Code';
        },
    },
    errorCaptured(err, vm, info) {
        openApplicationErrorModal(err);

        console.error(err);
    },
    mounted() {
        const vm = this;

        $(this.modal).on('hidden.bs.modal', e => {
            if (vm.form) {
                vm.form.reset();
            }

            vm.selectedPromoCode.id = null;
            vm.selectedPromoCode.portal_group = null;
            vm.selectedPromoCode.code = "";
            vm.selectedPromoCode.dollar_amount_off = "";
            vm.selectedPromoCode.exipration_date = "";
            vm.selectedPromoCode.minimum_subtotal_required = "";
            vm.selectedPromoCode.portal_group_id = null;
            vm.selectedPromoCode.reservation_property_definition_option_id = 0;
            vm.selectedPromoCode.max_required_number_of_attendees = null;
            vm.selectedPromoCode.notes = null;
        })
    }
};
</script>
