<template>
	<div class="row">
		<div class="col-md">
			<speaker-dashboard-my-profile :user="user" :is-after-badge-cutoff="isAfterBadgeCutoff"></speaker-dashboard-my-profile>

			<speaker-dashboard-my-sessions :user="user" :is-course-evaluations-published="isCourseEvaluationsPublished"></speaker-dashboard-my-sessions>
		</div>
		<div class="col-md">
			<speaker-dashboard-agenda-schedule
				:is-attendee="isAttendee"
				:is-after-registration-date="isAfterRegistrationDate"
				:reservation-props="reservationProps"
				:user="user"
				:company="company"
			></speaker-dashboard-agenda-schedule>

			<speaker-dashboard-optional-offerings
				:optional-offerings="optionalOfferings"
				:user="user"
				:processing-fee="processingFee"
				v-if="optionalOfferings.length"
			></speaker-dashboard-optional-offerings>
		</div>
	</div>
</template>

<script>
export default {
	props: [
		"user",
		"orders",
		"company",
		"message",
		"isAttendee",
		"reservationProps",
		"optionalOfferings",
		"isAfterBadgeCutoff",
		"isAfterRegistrationDate",
		"isCourseEvaluationsPublished",
		"processingFee"
	],
    methods: {
        forceUpdateProfilePic() {
            var timestamp = new Date().getTime();
            var image = document.getElementById("headshot-dash");
            if (image) image.style.backgroundImage = image.style.backgroundImage + '?t=' + timestamp + ')'
        }
    },
    beforeUpdate() {
        this.forceUpdateProfilePic();
    },
	mounted() {
        this.forceUpdateProfilePic();
			// Add date of birth validation
			$.validator.addMethod(
				"dob",
				function(value, element) {
					let isDateFormatValid = moment(value, "MM/DD/YYYY", true).isValid();

					let isDateAfter = moment(value).isAfter("01/01/1900");

					let isAtLeast18 = moment().diff(moment(value), "years") >= 18;

					return isDateFormatValid && isDateAfter && isAtLeast18;
				},
				"Please enter valid date"
			);

			$.validator.addMethod("expdate", function(value, element) {
				let isDateFormatValid = moment(value, "MM/YY", true).isValid();

				let submittedMonth = moment(value, "MM/YY", true).month();
				let currentMonth = moment().month();

				let submittedYear = moment(value, "MM/YY", true).year();
				let currentYear = moment().year();

				let isDateAfter = false;

				let yearIsValid = submittedYear >= currentYear;

				if (yearIsValid) {
					if (submittedYear > currentYear) {
						isDateAfter = true;
					} else if (submittedMonth >= currentMonth) {
                        isDateAfter = true;
                    } else {
                        isDateAfter = false;
                    }
				} else {
					isDateAfter = false;
				}

				return isDateFormatValid && isDateAfter;
			});
		}
};
</script>
