
export function logApplicationError(error) {
    let errorObj = {};

    let type = error.name ? error.name.substr(0, 255) : 'TypeUnknown';

    let message = error.message ? error.message.substr(0, 255) : 'No message provided';

    let stack = error.stack ? error.stack.substr(0, 255) : null;

    errorObj.code = 42;
    errorObj.type = type;
    errorObj.message = message;
    errorObj.url = location.href;
    errorObj.file_path = location.href;
    errorObj.line_number = 0;
    errorObj.stack_trace = stack || message;

    axios.post('/error-log/store', errorObj)
        .catch(error => {
            //Just let it ride
            console.error(error);
        });
}

export function getPropertyIfExists(obj, key) {
    return key.split(".").reduce(function(o, x) {
        return (typeof o == "undefined" || o === null) ? o : o[x];
    }, obj);
}