<template>
	<div class="row">
		<div class="col">
			<attendee-dashboard-my-profile
				:user="user"
				:company="company"
				:is-after-badge-cutoff="isAfterBadgeCutoff"
				:reservation="reservation"
				:props="props"
				:certifications="certifications"
				:orders="orders"
			></attendee-dashboard-my-profile>
		</div>

		<div class="col">
			<attendee-dashboard-courses
				:attendee-id="attendeeId"
				:attendee-name="attendeeName"
				:favorite-courses="favoriteCourses"
				:attended-courses="attendedCourses"
				:evaluation-questions="evaluationQuestions"
				:is-evaluation-expired="isEvaluationExpired"
				:is-transcript-published="isTranscriptPublished"
				:conferences="conferences"
			></attendee-dashboard-courses>

			<attendee-dashboard-optional-offerings
				v-if="optionalOfferings.length"
				:optional-offerings="optionalOfferings"
				:is-platinum="company.is_platinum_vgm_member"
				:processing-fee="processingFee"
				:user="user"
				:allow-checkout="orders.length"
			></attendee-dashboard-optional-offerings>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			"user",
			"orders",
			"company",
			"reservation",
			"props",
			"certifications",
			"favoriteCourses",
			"attendedCourses",
			"optionalOfferings",
			"isAfterBadgeCutoff",
			'isEvaluationExpired',
			'evaluationQuestions',
			"isTranscriptPublished",
			"conferences",
			"processingFee"
		],
		data() {
            return {
				attendeeId: this.user.id,
				attendeeName: this.user.first_name + ' ' + this.user.last_name,
			}
		},
		mounted() {
			// Add date of birth validation
			$.validator.addMethod(
				"dob",
				function(value, element) {
					let isDateFormatValid = moment(value, "MM/DD/YYYY", true).isValid();

					let isDateAfter = moment(value).isAfter("01/01/1900");

					let isAtLeast18 = moment().diff(moment(value), "years") >= 18;

					return isDateFormatValid && isDateAfter && isAtLeast18;
				},
				"Please enter valid date"
			);

			$.validator.addMethod("expdate", function(value, element) {
				let isDateFormatValid = moment(value, "MM/YY", true).isValid();

				let submittedMonth = moment(value, "MM/YY", true).month();
				let currentMonth = moment().month();

				let submittedYear = moment(value, "MM/YY", true).year();
				let currentYear = moment().year();

				let isDateAfter = false;

				let yearIsValid = submittedYear >= currentYear;

				if (yearIsValid) {
					if (submittedYear > currentYear) {
						isDateAfter = true;
					} else if (submittedMonth >= currentMonth) {
                        isDateAfter = true;
                    } else {
                        isDateAfter = false;
                    }
				} else {
					isDateAfter = false;
				}

				return isDateFormatValid && isDateAfter;
			});
		}
	};
</script>
