<template>
	<form class="cma-options-form d-flex flex-column h-100" data-js-id="cma-options-form" novalidate>
		<div class="flex-grow-1">
			<div class="row" v-if="formHasError">
				<div class="col">
					<div class="alert alert-danger">{{ formErrorMessge }}</div>
				</div>
			</div>
			<div class="row">
                <div class="form-group col-md">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="conference_start_date">Conference Start Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Conference Start Date"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="conference_start_date"
						id="conference_start_date"
						title="Enter conference start date"
						required
						data-msg="Please provide a start date"
						v-mask="'##/##/####'"
						v-model="selectedConference.conference_start_date"
					/>
				</div>
				<div class="form-group col-md">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="conference_end_date">Conference End Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date conference ends"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="conference_end_date"
						id="conference_end_date"
						title="Enter conference end date"
						required
						data-msg="Please provide an end date"
						v-mask="'##/##/####'"
						v-model="selectedConference.conference_end_date"
					/>
				</div>
			</div>
            <div class="row">
				<div class="form-group col-md">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="registration_start_date">Registration Start Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date attendees can start registering"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="registration_start_date"
						id="registration_start_date"
						title="Enter registration start date"
						required
						data-msg="Please provide a start date"
						v-mask="'##/##/####'"
						v-model="selectedConference.registration_start_date"
					/>
				</div>
				<div class="form-group col-md">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="registration_end_date">Registration End Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date registration ends"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="registration_end_date"
						id="registration_end_date"
						title="Enter registration end date"
						required
						data-msg="Please provide a end date"
						v-mask="'##/##/####'"
						v-model="selectedConference.registration_end_date"
					/>
				</div>
			</div>
			<div class="row">
				<div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="badge_info_lockdown_date">Badge Info Lockdown Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date that badge information must be in by"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="badge_info_lockdown_date"
						id="badge_info_lockdown_date"
						title="Enter badge info lockdown date"
						required
						data-msg="Please provide a lockdown date"
						v-mask="'##/##/####'"
						v-model="selectedConference.badge_info_lockdown_date"
					/>
				</div>

				<div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="course_approval_end_date">Course Approval End Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date Course Approval ends"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="course_approval_end_date"
						id="course_approval_end_date"
						title="Enter a course approval end date"
						required
						data-msg="Please provide a course approval end date"
						v-mask="'##/##/####'"
						v-model="selectedConference.course_approval_lockdown_date"
					/>
				</div>
			</div>
			<div class="row">
				<div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="course_attendance_percentage">Course Attendance Percentage</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Course Transcript Attendance Percentage Override"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="course_attendance_percentage"
						id="course_attendance_percentage"
						title="Enter course attendance percentage override"
						required
						data-msg="Please provide a course attendance percentage override"
						v-mask="'##.##%'"
						v-model="selectedConference.course_attendance_percentage"
					/>
				</div>

				<div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="course_evaluation_expiration_date">Course Evaluation Expiration Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date Course Evaluations are no longer available"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="course_evaluation_expiration_date"
						id="course_evaluation_expiration_date"
						title="Enter a course evaluation expiration date"
						required
						data-msg="Please provide a course evaluation expiration date"
						v-mask="'##/##/####'"
						v-model="selectedConference.course_evaluation_expiration_date"
					/>
				</div>
			</div>

            <h4>Registration Notification Emails</h4>
            <div class="row">
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="registration_notification_email">Attendee Registration Notification Email(s)</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="An email will be sent to these addresses when an attendee registers for the conference or if an optional offering is submitted. If entering more than one email, please separate with a semicolon(;)."
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						name="registration_notification_email"
						id="registration_notification_email"
						title="Enter Attendee registration notification emails"
						required
						data-msg="Please provide at least one email"
						v-model="selectedConference.registration_notification_email"
					/>
				</div>
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="vendor_registration_notification_email">Vendor Registration Notification Email(s)</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="An email will be sent to these addresses when an vendor registers for the conference. If entering more than one email, please separate with a semicolon(;)."
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						name="vendor_registration_notification_email"
						id="vendor_registration_notification_email"
						title="Enter Vendor registration notification emails"
						required
						data-msg="Please provide at least one email"
						v-model="selectedConference.vendor_registration_notification_email"
					/>
				</div>
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="vendor_registration_notification_email">Speaker Registration Notification Email(s)</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="An email will be sent to these addresses when an vendor registers for the conference. If entering more than one email, please separate with a semicolon(;)."
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						name="speaker_registration_notification_email"
						id="speaker_registration_notification_email"
						title="Enter Speaker registration notification emails"
						required
						data-msg="Please provide at least one email"
						v-model="selectedConference.speaker_registration_notification_email"
					/>
				</div>
            </div>

			<!-- Conference Pricing -->
			<h4>Conference Pricing</h4>
            <div class="row"
				v-for="(pass, index) in selectedConference.conference_pricing"
				:key="index"
			>
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" :for="`${pass.option_name}_price`">{{ pass.option_name }} Price</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							:title="`This is the price of the ${pass.option_name}`"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="number"
						:name="`${pass.option_name}_price`"
						:id="`${pass.option_name}_price`"
						:title="`Enter ${pass.option_name} price`"
						required
						step=".01"
						min="0.00"
						:data-msg="`Please provide an ${pass.option_name} price`"
						v-model="selectedConference.conference_pricing[index].price"
					/>
				</div>
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" :for="`${pass.option_name}_vgm_member_price`">{{ pass.option_name }} VGM Member Price</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							:title="`This is the price of the ${pass.option_name}`"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="number"
						:name="`${pass.option_name}_vgm_member_price`"
						:id="`${pass.option_name}_vgm_member_price`"
						:title="`Enter ${pass.option_name} vgm member price`"
						required
						step=".01"
						min="0.00"
						:data-msg="`Please provide an ${pass.option_name} vgm member price`"
						v-model="selectedConference.conference_pricing[index].vgm_member_price"
					/>
				</div>
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" :for="`${pass.option_name}_maximum_available`">{{ pass.option_name }} Maximum Available</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							:title="`This is the amount of conference tickets for the ${pass.option_name} pass`"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="number"
						:name="`${pass.option_name}_maximum_available`"
						:id="`${pass.option_name}_maximum_available`"
						:title="`Enter ${pass.option_name} maximum tickets available`"
						min="0"
						v-model="selectedConference.conference_pricing[index].maximum_available"
					/>
				</div>
            </div>

			<!-- Early Bird Pricing -->
			<h4>Early Bird Pricing</h4>
            <div class="row">
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="early_bird_dollar_off">Discount to Apply</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="This is the dollar amount of the discount that will be appplied."
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="number"
						name="early_bird_dollar_off"
						id="early_bird_dollar_off"
						title="Enter Attendee registration early bird pricing"
						required
						step=".01"
						min="0.00"
						data-msg="Please provide an early bird price"
						v-model="selectedConference.early_bird_dollar_off"
					/>
				</div>
                <div class="col-md form-group has-label">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="early_bird_start_date">Early Bird Start Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="First day that the early bird pricing will be valid"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="early_bird_start_date"
						id="early_bird_start_date"
						title="Enter early bird start date"
						required
						data-msg="Please provide a early bird start date"
						v-mask="'##/##/####'"
						v-model="selectedConference.early_bird_start_date"
					/>
				</div>
                <div class="col-md form-group has-label">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="early_bird_end_date">Early Bird End Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Last day that the early bird pricing will be valid"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="early_bird_end_date"
						id="early_bird_end_date"
						title="Enter early bird end date"
						required
						data-msg="Please provide a early bird end date"
						v-mask="'##/##/####'"
						v-model="selectedConference.early_bird_end_date"
					/>
				</div>
            </div>

			<!-- We are adding bullets to sponsorship levels statically so these can't be modified -->

            <!-- Sponsorship Levels -->
            <!-- <h4>Sponsorship Levels</h4>
            <div class="row">
                <div class="form-group col-3" v-for="(level, index) in newSponsorshipLevels" :key="index">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" :for="`${level.id}`">Sponsor Level {{ level.sort_order }}</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							:title="`The Sponsorship Level ${level.sort_order}`"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						:name="`${level.id}`"
						:id="`${level.id}`"
						title="Enter sponsorship level name"
						required
						data-msg="Please provide a name"
						v-model="level.level"
					/>
				</div>
            </div> -->
		</div>
		<div class="d-flex justify-content-end">
			<button
				class="btn btn-primary"
				:disabled="formProcessing"
				@click.prevent="submitChanges()"
			>{{ saveChangesButton }}</button>
		</div>
	</form>
</template>

<script>
export default {
	props: ["currentConference", "sponsorshipLevels"],
	data() {
		return {
			saveChangesButton: "Save Changes",
			formProcessing: false,
			formErrorMessge: "",
            formHasError: false,
            sponsorship_levels: {}
		};
	},
	computed: {
		selectedConference() {
			if (this.length) {
			} else {
				return this.currentConference;
			}
		},
		newSponsorshipLevels() {
			if (this.length) {
			} else {
				return this.sponsorshipLevels;
			}
		},
		registrationForm() {
			return document.querySelector('[data-js-id="cma-options-form"]');
		}
	},
	methods: {
		submitChanges() {
			$(this.registrationForm).validate();

			this.formHasError = false;
			this.formErrorMessge = "";

			const vm = this;

			if ($(this.registrationForm).valid()) {
				this.formProcessing = true;
				this.saveChangesButton = "Processing...";

				// Get the data and send a request to update
                let data = this.selectedConference;

                data = {...data, sponsorship_levels: {...this.newSponsorshipLevels}};

				axios
					.post("/vpanel/modules/conference/update", data)
					.then(res => {
						const data = res.data;
						showNotification(data.message, data.result);

						vm.formProcessing = false;
						vm.saveChangesButton = "Save Changes";
					})
					.catch(error => {
						if (error.response) {
							if (error.response.status == "422") {
								vm.formHasError = true;
								vm.formErrorMessge =
									error.response.data.message;
							} else {
								openApplicationErrorModal(error, "");
							}
						}

						vm.formProcessing = false;
						vm.saveChangesButton = "Save Changes";

						console.error(error);
					});
			}
		}
	},
	errorCaptured(err, vm, info) {
		openApplicationErrorModal(err);

		console.error(err);
	}
};
</script>