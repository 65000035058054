export const dtAdminOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        {
            data: "name",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "username",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "company.name",
            render: function(data, type, row) {
                return data ? `<p class="m-0 text-truncate" title="${data}">${data}</p>` : '-'
            }
        },
        {
            data: "company.vgm_member_number",
            width: "232px",
            render: function(data, type, row) {
                return row.company.is_valid_vgm_member == 1 ? `<strong class="text-success">${data}</strong>` : `<strong class="text-danger">${data}</strong>`;
            }
        },
        {
            data: "registration_date",
            width: "174px",
            render: function(data, type, row) {
                return moment(data).format('M/D/YYYY h:mm A');
            }
        },
        {
            data: "id",
            orderable: false,
            searchable: false,
            width: '243px',
            render: function(data, type, row) {
                let link = row.create_account_link_url ? `<a class="btn btn-outline-secondary-blue ml-1" href="${row.create_account_link_url}" target="_blank">Complete Account</a>` : '';

                return `<div class="admin-options" data-id="${row.id}">
                            <button class="btn btn-secondary-blue" data-js-id="admin-modify">Modify</button>${link}
                        </div>`;
            }
        }
    ]
}

