<template>
    <div>
        <div class="form-group">
            <label for="message_from_the_heartland">Message</label>
            <textarea
                class="form-control"
                type="text"
                name="message_from_the_heartland"
                id="message_from_the_heartland"
                title="Message From The Heartland"
                v-model="message.message"
            ></textarea>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'messageFromTheHeartland'
    ],
    data() {
        return {
            message: this.messageFromTheHeartland
        }
    },
    mounted() {
        CKEDITOR.replace('message_from_the_heartland', {
            removePlugins: 'maximize,resize',
        });
    }
}
</script>