<template>
    <div class="portal-company-section dt-section">
        <vpanel-portal-company-datatable
            table-id="company-datatable"
            :column-names="[
                'Name',
                'Email',
                'Industry Focus',
                'VGM Member Number',
                'Options',
            ]"
            :ajax-route="ajaxRoute"
            :selected-company="selectedCompany"
        ></vpanel-portal-company-datatable>

        <vpanel-portal-company-modal
            modal-id="company-add-modify-modal"
            modal-title="Company"
            header-icon="building"
            modal-size="lg"
        >
            <div class="modal-body">
                <div class="row" v-if="formHasError">
                    <div class="col">
                        <div class="alert alert-danger">{{ formErrorMessage }}</div>
                    </div>
                </div>

                <div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showLoader">
                    <p>Loading...</p>
                    <progress></progress>
                </div>

                <vpanel-portal-company-form v-if="showForm" :selected-company="selectedCompany" :csrf="csrf"></vpanel-portal-company-form>
            </div>

            <div class="modal-footer d-flex justify-content-end mx-n1">
                <p class="btn btn-outline-primary m-1 cancel-button" @click="closeCompanyModal()">Cancel</p>
                <button
                    class="btn btn-primary m-1"
                    :disabled="formProcessing"
                    @click.prevent="saveChanges()"
                    data-js-id="company-save-changes-button"
                >Save Changes</button>
            </div>
        </vpanel-portal-company-modal>
    </div>
</template>

<script>
    export default {
        props: ["ajaxRoute", "csrf"],
        data() {
            return {
                selectedCompany: {},
                formProcessing: false,
                formErrorMessage: "",
                formHasError: false,
                showForm: true,
                showLoader: false
            };
        },
        computed: {
            modal() {
                return document.querySelector('[data-js-id="company-add-modify-modal"]');
            },
            form() {
                return document.querySelector('[data-js-id="company-add-modify-form"]');
            },
            datatable() {
                return document.querySelector('#company-datatable');
            },
        },
        methods: {
            openCompanyModal() {
                this.showLoader = true;
                this.showForm = false;
                $(this.modal).modal("show");
            },
            showModalForm() {
                this.showLoader = false;
                this.showForm = true;
            },
            saveChanges() {
                let url = "/vpanel/modules/portal/company/update";
                const vm = this;

                $(this.form).validate({
                    errorPlacement: function(error, element) {
                        if (element.is(":radio")) {
                            error.addClass("d-flex");
                            error.prependTo(element.parents("fieldset"));
                        } else {
                            error.insertAfter(element);
                        }
                    }
                });

                if ($(this.form).valid()) {
                    this.formProcessing = true;
                    this.saveChangesButton = "Processing...";

                    axios
                        .post(url, this.selectedCompany)
                        .then(res => {
                            const data = res.data;
                            showNotification(data.message, data.result);

                            vm.formProcessing = false;
                            vm.saveChangesButton = "Save Changes";

                            $(vm.modal).modal("hide");

                            $(vm.datatable)
                                .DataTable()
                                .ajax.reload();
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == "422") {
                                    vm.formHasError = true;
                                    vm.formErrorMessage = error.response.data.message;
                                } else {
                                    openApplicationErrorModal(error, "");
                                }
                            }

                            vm.formProcessing = false;
                            vm.saveChangesButton = "Save Changes";

                            console.error(error);
                        });
                }
            }
        }
    };
</script>
