<template>
	<div class="d-flex flex-column h-100">
		<cm-nav :app-store="store"></cm-nav>
		<cm-management-section
			:conferences="conferences"
			v-if="store.currentTab == 'cm'"
            :available-years="availableYears"
			:sponsorship-levels="sponsorshipLevels"
			:current-conference="selectedConference"
            :is-active-conference="isActiveConference"
            @change-conference-year="changeCurrentConference"
		></cm-management-section>

		<cm-promo-code-section
			:crsf="crsf"
			:portal-groups="portalGroups"
			v-if="store.currentTab == 'pc'"
			:ajax-route="promoCodeAjaxRoute"
			:current-conference="selectedConference"
			:conference-pass-options="getConferencePassOptions"
		></cm-promo-code-section>

        <registration-option-index
			:crsf="crsf"
			:portal-groups="portalGroups"
			v-if="store.currentTab == 'ro'"
			:current-conference="selectedConference"
			:conference-pass-options="getConferencePassOptions"
		></registration-option-index>

		<cm-attendee-section
			:crsf="crsf"
			:portal-groups="portalGroups"
			:ajax-route="attendeeAjaxRoute"
			v-if="store.currentTab == 'atn'"
			:current-conference="selectedConference"
            :reservation-properties="reservationProperties"
		></cm-attendee-section>

		<cm-admin-section
			:crsf="crsf"
			:ajax-route="adminAjaxRoute"
			:portal-groups="portalGroups"
			v-if="store.currentTab == 'admin'"
			:current-conference="selectedConference"
			:reservation-properties="reservationProperties"
		></cm-admin-section>

		<cm-sponsor-section
			:crsf="crsf"
            :all-vendors="allVendors"
			:ajax-route="sponsorAjaxRoute"
			v-if="store.currentTab == 'sponsor'"
			:sponsorship-levels="sponsorshipLevels"
			:current-conference="selectedConference"
            :previous-year-sponsors="previousYearSponsors"
		></cm-sponsor-section>

		<cm-vendor-section
			:crsf="crsf"
			:packages="packages"
			:ajax-route="vendorAjaxRoute"
			v-if="store.currentTab == 'ven'"
			:sponsorship-levels="sponsorshipLevels"
			:current-conference="selectedConference"
			:message-from-the-heartland="messageFromTheHeartland"
		></cm-vendor-section>

		<cm-manage-vendor-packages-section
			:crsf="crsf"
			:packages="packages"
			:ajax-route="vendorPackagesAjaxRoute"
			v-if="store.currentTab == 'manage-ven-packages'"
			:sponsorship-levels="sponsorshipLevels"
			:current-conference="selectedConference"
			:message-from-the-heartland="messageFromTheHeartland"
			@update-all-packages="updateAllPackages"
		></cm-manage-vendor-packages-section>

		<cm-speaker-section
			:crsf="crsf"
			:all-courses="selectedCourses"
			:ajax-route="speakersAjaxRoute"
			v-if="store.currentTab == 'speakers'"
            :current-conference="selectedConference"
			@update-all-speakers="updateAllSpeakers"
		>
		</cm-speaker-section>

		<cm-course-section
			:crsf="crsf"
			:ceu-types="ceuTypes"
			:ajax-route="coursesAjaxRoute"
			:all-categories="allCategories"
			:all-speakers="selectedSpeakers"
			v-if="store.currentTab == 'courses'"
			@update-all-courses="updateAllCourses"
			:current-conference="selectedConference"
			:is-after-course-approval-date="isAfterCourseApprovalDate"
		></cm-course-section>
	</div>
</template>

<script>
	export default {
		props: [
            "crsf",
			"ceuTypes",
			"packages",
            "allCourses",
			"allVendors",
			"allSpeakers",
			"conferences",
			"portalGroups",
			"allCategories",
            "availableYears",
			"currentConference",
			"sponsorshipLevels",
            "isActiveConference",
            "previousYearSponsors",
			"reservationProperties",
			"messageFromTheHeartland",
            "isAfterCourseApprovalDate",
		],
		data() {
			return {
				store: {
					currentTab: "cm"
                },
                selectedConference: this.currentConference,
				selectedCourses: this.allCourses,
				selectedSpeakers: this.allSpeakers
			};
        },
        computed: {
            promoCodeAjaxRoute() {
                return "/vpanel/modules/conference/promo-codes/list/" + this.selectedConference.uuid
            },
            attendeeAjaxRoute() {
                return "/vpanel/modules/conference/attendees/list/" + this.selectedConference.uuid
            },
            adminAjaxRoute() {
                return "/vpanel/modules/conference/admin/list/" + this.selectedConference.uuid
            },
            vendorAjaxRoute() {
                return "/vpanel/modules/conference/vendor/list/" + this.selectedConference.uuid
            },
			vendorPackagesAjaxRoute() {
				return "/vpanel/modules/conference/manage-vendor-packages/list/" + this.selectedConference.uuid
			},
            speakersAjaxRoute() {
                return "/vpanel/modules/conference/speakers/list/" + this.selectedConference.uuid
            },
            coursesAjaxRoute() {
                return "/vpanel/modules/conference/course/list/" + this.selectedConference.uuid
            },
            sponsorAjaxRoute() {
                return "/vpanel/modules/conference/sponsors/list/" + this.selectedConference.uuid
            },
			getConferencePassOptions() {
				return this.reservationProperties['Conference Pass Options'].options;
			}
        },
        methods: {
            changeCurrentConference(event) {
                this.selectedConference = event.conference;
                this.selectedCourses = event.courses;
			},
			updateAllCourses(payload) {
				let allCourses = this.selectedCourses;
				allCourses.push({id: payload.id, title: payload.title });
			},
			updateAllSpeakers(payload) {
				let allSpeakers = this.selectedSpeakers;
				allSpeakers.push({speakerId: {id: payload.id}, name: payload.name });
			},
			updateAllPackages(payload) {
				// Mutating props...
				this.packages = payload;
			}
        }
	};
</script>