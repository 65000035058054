<template>
    <p
        class="btn my-1 btn-block text-left cursor-pointer"
        :class="filterClass"
        @click="filterBy(filter, filtering)"
    >
        {{ filter }}
    </p>
</template>

<script>

export default {
    props: ['filter', 'filtering', 'filterBy', 'filteredBy'],
    computed: {
        filterClass() {
            return this.filteredBy == this.filter ? 'btn-primary-green' : 'btn-primary-yellow';
        }
    }
}

</script>

<style>
</style>