<template>
	<div>
		<div class="card" v-if="showForm">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Provide your desired Login information</p>
			</div>

			<complete-profile-form
				:email="email"
				:link-code="linkCode"
				:crsf-token="crsf"
				:show-form="showForm">

			</complete-profile-form>
			<div class="card-footer">
				<p class="m-0"><small>Protected by reCAPTCHA and subject to the Google Privacy Policy and Terms of Service.</small></p>
			</div>
		</div>

		<div class="card" v-else>
			<div class="card-body">
				<div class="alert alert-danger m-0">
					The link to complete your account creation is invalid. Please call
					<a href="tel:+877-484-6901">877-484-6901</a> for assistance.
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: [
			"showForm", "email", "linkCode", "crsf"
		]
	};
</script>