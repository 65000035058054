export const dtSubmissionsOfferingOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        { data: 'name' },
        { data: "username" },
        { data: "order_number"},
        {
            data: "created_at",
            render: function(data, type, row) {
                return moment(data).format('M/D/YYYY h:mm A');
            }
        },
        {
            data: "id",
            orderable: false,
            searchable: false,
            render: function(data, type, row) {
                return  `<div class="offering-options d-flex" data-id="${row.id}">
                            <button class="btn btn-secondary-blue mr-1" data-js-id="offering-view">View/Modify</button>
                        </div>`;
            }
        }
    ]
}

