<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtSponsorOptions } from "./dtSponsorOptions";

export default {
    mixins: [dataTableMixin],
    props: [
        'currentSponsor'
    ],
    data() {
        return {
            moduleDatatableOptions: dtSponsorOptions,
        }
    },
    mounted() {
        // Add event listeners to datatable buttons
        let dataTable = this.$el;
        const sm      = this;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id             = elementClicked.parentElement.dataset.id;

            switch (elementClicked.dataset.jsId) {
                case 'sponsor-modify':
                    // Show modal
                    sm.$parent.openSponsorAddModifyModal('modify');
                    axios.get(`/vpanel/modules/conference/sponsors/show/${id}`)
                        .then(res => {
                            try {
                                let sponsor = res.data.record;

                                // Fill data
                                sm.$parent.setCurrentSponsor(sponsor);
                                // Show form
                                sm.$parent.openSponsorAddModifyModal();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });
                    break;

                case 'sponsor-delete':
                    sm.$parent.setCurrentSponsor({id: id});
                    sm.$parent.openSponsorDeleteModal();
                    break;
            }
        });
    }
}
</script>