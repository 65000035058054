<template>
<div class="card my-2">
    <portal-dashboard-toggle-bar toggle-bar-text="My Credits" @click.native="toggleMyCreditsInfo()" :is-toggled="showMyCredits"></portal-dashboard-toggle-bar>

    <div class="card-body" v-if="showMyCredits">
        <div class="d-flex justify-content-between align-items-center">
            <label for="agendas">Select Conference</label>
            <a href="#" data-placement="right" data-boundary="window">

            </a>
        </div>

        <select class="form-control flex-grow-1" name="conference" @change="getAttendedCourses($event)" aria-placeholder="select conference year">
            <option v-for="(conference, index ) in conferences" :key="index" :value="index">{{conference.year}}</option>
        </select>

        <template v-if="Object.keys(localAttendedCourses).length > 0 ">
            <div class="card-body" v-if="localAttendedCourses">
                <div class="d-flex justify-content-between align-items-center">
                    <p class="m-0 text-uppercase font-weight-bold text-dark text-16 lh-sm">Course Credit</p>
                    <button class="btn btn-primary" @click.prevent="downloadTranscript()">View Document</button>
                </div>
            </div>

            <div class="card-body" :class="{'border-top': index > 0}" v-for="(course, index) in localAttendedCourses" :key="index">
                <div class="d-flex flex-column justify-content-between align-items-start">
                    <p class="m-0 text-uppercase font-weight-bold text-dark text-16 lh-sm">{{ course.title }}</p>

                    <div class="mt-1 w-100 d-flex flex-column flex-lg-row justify-content-around">
                        <div class="d-inline-flex align-items-center justify-content-between flex-shrink-0">
                            <span class="fas fa-check-circle text-20 text-success mr-1" v-if="course.attendance_requirement_met"></span>
                            <span class="fas fa-times-circle text-20 text-danger mr-1" v-else></span>
                            <p class="m-0">Attendance Requirement Met</p>
                        </div>

                        <div class="d-inline-flex align-items-center justify-content-between flex-shrink-0">
                            <span class="fas fa-check-circle text-20 text-success mr-1" v-if="course.evaluation_complete"></span>
                            <span class="fas fa-times-circle text-20 text-danger mr-1" v-else></span>
                            <p class="m-0">Evaluation Requirement Met</p>
                        </div>
                    </div>
                </div>

                <div class="d-flex justify-content-end align-items-end pt-3" v-if="!isEvaluationExpired && !course.evaluation_complete">
                    <button class="btn btn-primary" :data-js-id="`${course.course_id}`" @click.prevent="startEvaluation()">Start Evaluation</button>
                </div>
            </div>
        </template>

        <div class="card-body" v-else>
            <div v-if="hasError">
                Sorry, we didn't find any courses for this conference year.
            </div>
            <vue-loader v-if="showLoader" />
        </div>
    </div>
</div>
</template>

<script>
import VueLoader from '../../../../../../../Vue/Core/Global/Components/VueLoader.vue';
import {logApplicationError} from "../../../../../../core/global/functions";
export default {
    components: {
        VueLoader
    },
    props: [
        'attendeeId',
        'attendeeName',
        'attendedCourses',
        'isTranscriptPublished',
        'selectedCourseEvaluation',
        'conferences'
    ],
    data() {
        return {
            showMyCredits: false,
            currentCourseEvaluation: this.selectedCourseEvaluation,
            localAttendedCourses: [],
            localIsTranscriptPublished: this.conferences[0].transcripts_published, // This.isTranscriptPublished,
            activeUUID: this.conferences[0].uuid,
            showLoader: false,
            hasError: false,
            isEvaluationExpired: null
        }
    },

    methods: {
        toggleMyCreditsInfo() {
            this.showMyCredits = !this.showMyCredits;
        },
        startEvaluation() {
            let courseId = event.target.dataset.jsId;
            this.$parent.openCourseEvaluationModal(courseId);
        },
        downloadTranscript() {
            const vm = this;
            const thisEvent = event;
            const attendeeId = vm.attendeeId;
            const uuid = vm.activeUUID;

            let button = thisEvent.target;

            button.innerText = 'Processing...';
            button.disabled = true;

            axios({
                    url: `/portal/dashboard/attendee/download-transcript/${attendeeId}/${uuid}`,
                    method: "GET",
                    responseType: "blob"
                })
                .then(response => {
                    let blob = new Blob([response.data], {
                        type: 'application/pdf'
                    });
                    let date = moment().format('L');

                    // For IE
                    if (typeof window.navigator.msSaveBlob !== "undefined") {
                        window.navigator.msSaveBlob(blob, filename);
                    } else {
                        // Creates a link with a url to the created blob, clicks that link, then removes it from the page
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = `${date}-${vm.attendeeName}-transcript.pdf`;
                        link.click();

                        link.remove();
                    }

                    button.innerText = "View Document";
                    button.disabled = false;
                })
                .catch(error => {
                    logApplicationError(error);
                    console.error(error);
                    this.hasError = true;
                    button.innerText = "View Document";
                    button.disabled = false;
                });
        },
        getAttendedCourses(event) {
            this.showLoader = true;
            this.hasError = false;
            const index = event.target.value;
            const current = new Date();
            const expiration = new Date(this.conferences[index].course_evaluation_expiration_date);

            if (current.getTime() > expiration.getTime()) {
                this.isEvaluationExpired = 1;
            } else {
                this.isEvaluationExpired = 0;
            }

            let uuid = this.conferences[index].uuid;

            this.activeUUID = uuid;
            this.localAttendedCourses = '';

            let formData = {
                'user_id': this.attendeeId,
                'uuid': uuid
            };

            axios.post('/portal/dashboard/attendee/attended-course', formData)
                .then(response => {
                    this.showLoader != this.showLoader;
                    this.localAttendedCourses = response.data.attendedCourses;
                })
                .catch(error => {
                    this.showLoader = false;
                    this.hasError = true;
                    logApplicationError(error);
                    console.error(error);
                });
        },
    },
    mounted() {
        this.localAttendedCourses = this.attendedCourses;
        Object.keys(this.attendedCourses).length == 0 ? this.hasError = true : this.hasError = false;
        const current = new Date();
        const expiration = new Date(this.conferences[0].course_evaluation_expiration_date);

        if (current.getTime() > expiration.getTime()) {
            this.isEvaluationExpired = 1;
        } else {
            this.isEvaluationExpired = 0;
        }
    }
}
</script>
