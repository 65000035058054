<template>
	<div
		class="base-image-input"
		:style="{ 'background-image': `url(${imageData})` }"
		@click="chooseImage"
	>
		<div v-if="!imageData" class="placeholder">
			<p>Choose an image</p>
			<small class="text-center">(preferred image size 250x250)</small>
		</div>
        <div class="form-control d-none">
            <label for="profile_image">Profile Image</label>
            <input
                class="form-control-file"
                ref="fileInput"
                type="file"
                name="profile_image"
                id="profile_image"
                title="Profile Image"
                required
                data-msg="Please provide an image"
				data-rule-accept="image/*"
                @input="onSelectFile"
            />
        </div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			imageData: null
		};
	},
	methods: {
		chooseImage() {
			this.$refs.fileInput.click();
		},
		onSelectFile() {
			const input = this.$refs.fileInput;
			const files = input.files;

			if (this.inputIsNotAnImage(input)) {
				return false;
			}

			if (files && files[0]) {
				const reader = new FileReader();
				reader.onload = e => {
					this.imageData = e.target.result;
				};
				reader.readAsDataURL(files[0]);
				this.$emit("input", files[0]);
			}
		},
		inputIsNotAnImage(input) {
			let acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

			return !acceptedImageTypes.includes(input.files[0]['type']);
		}
	}
};
</script>

<style scoped>
.base-image-input {
	display: block;
	width: 200px;
	height: 200px;
	cursor: pointer;
	background-size: cover;
	background-position: center center;
}
.placeholder {
	background: #f0f0f0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #333;
	font-size: 18px;
	flex-direction: column;
}
.placeholder:hover {
	background: #e0e0e0;
}
</style>