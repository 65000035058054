<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtCourseOptions } from "./dtCourseOptions";

export default {
    mixins: [dataTableMixin],
    props: [
        'selectedCourse'
    ],
    data() {
        return {
            moduleDatatableOptions: dtCourseOptions,
        }
    },
    computed: {
        datatable() {
            return document.querySelector("#course-datatable");
        }
	},
    mounted() {
        // Add event listeners for buttons
        let dataTable = this.$el;

        const vm = this;

        dataTable.addEventListener('click', evt => {
            evt.stopPropagation()
            let elementClicked = evt.target;
            let id = elementClicked.parentElement.dataset.id;
            let form;

            switch (elementClicked.dataset.jsId) {
                case "course-approve-button":
                    form = {
                        'id'      : id,
                        'approved': 1
                    }

                    axios.post(`/vpanel/modules/conference/course/update/${id}`, form)
                        .then(res => {
                            try {
								const data = res.data;
								showNotification(data.message, data.result);
                                $(vm.datatable).DataTable().ajax.reload();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                    break;

                case "course-deny-button":
                    form = {
                        'id'      : id,
                        'approved': 0
                    }

                    axios.post(`/vpanel/modules/conference/course/update/${id}`, form)
                        .then(res => {
                            try {
								const data = res.data;
								showNotification(data.message, data.result);
                                $(vm.datatable).DataTable().ajax.reload();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                break;

                case "course-modify":

                    vm.selectedCourse.id = id;

                    // Show modal
                    vm.$parent.openCourseModifyModal();

                    axios.get(`/vpanel/modules/conference/course/show/${id}`)
                        .then(res => {
                            try {
                                let course = res.data.course;

                                for (const prop in course) {
                                    if (course.hasOwnProperty(prop)) {
                                        let value = course[prop];

                                        vm.selectedCourse[prop] = value;
                                    }
                                }

                                vm.$parent.showModalForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })

                        break;

                case "course-manage-attendees":
                    // Show modal
                    vm.$parent.openManageAttendeesModal(id);

                    break;
            }
        });
    }
}
</script>