export const dtOptions = {
    scrollY: "0",
    scrollCollapse: "true",
    dom:
        "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>" +
        "<'dt-body'tr>" +
        "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    order: [[6, "desc"]],
    columns: [
        {
            data: "first_name"
        },
        {
            data: "last_name"
        },
        {
            data: "badge"
        },
        {
            data: "company"
        },
        {
            data: "city"
        },
        { data: "state" },
        {
            data: "registration_date",
            type: "Date",
            render: function(data, type, row) {
                return moment(data).format('MM/DD/YYYY');
            }
        }
    ]
};
