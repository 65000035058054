<template>
    <div v-if="orderItems.length">
    <p class="oi-key flex-grow-1">Itemized Receipt</p>
    <div class="oi-detail" v-for="(item, index) in orderItems" :key="index">
        <div class="oi-value ml-2">
            <div class="d-flex justify-content-between">
                <p class="my-0 font-weight-bold">Item {{ index + 1 }}</p>
            </div>
            <div
                class="d-flex justify-content-between"
                v-for="name in item.name.split(':')"
                :key="name"
            >
                <p class="m-0 font-weight-bold">{{ name }}</p>
            </div>
        </div>
        <div class="oi-value ml-2">
            <div class="d-flex justify-content-between">
                <p class="my-0 font-weight-bold text-left">Price</p>
            </div>
            <div>
                <p class="m-0 text-left">
                    {{ item.line_total ? `$${item.line_total}` : "-" }}
                </p>
            </div>
        </div>
    </div>
    </div>
</template>
<script>
export default {
    props: [
        'order',
    ],
    computed: {
        orderItems() {
            if (!this.order) return []
            return this.order.order_items.filter(item => item.line_total > 0 || item.line_total < 0);
        }
    }
}
</script>
