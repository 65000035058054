<template>
	<div class="d-flex flex-column h-100">
        <voo-nav :app-store="store"></voo-nav>
		<template v-if="store.currentTab == 'home'">
            <div class="dt-section d-flex flex-column p-4 flex-grow-1">
				<div class="alert alert-warning" v-if="afterRegistrationStartDate">
					Optional Offerings are no longer editable as it is after the Registration Start Date – {{ conference.registration_start_date }}.
				</div>
				
				<div class="alert alert-info" v-else>
					Optional Offerings will no longer be editable after the Registration Start Date – {{ conference.registration_start_date }}.
				</div>

				<div class="row mb-2">
					<div class="col">
						<h3 class="mb-1">Optional Offerings</h3>
					</div>
					<div class="col d-flex justify-content-end align-items-center">
						<button
							class="btn btn-primary"
							@click.prevent="openOfferingModal()"
						>Create New Optional Offering</button>
					</div>
				</div>

				<voo-datatable v-if="store.currentTab == 'home'"
					table-id="offering-datatable"
					:column-names='[
						"Name",
						"Group",
						"Description",
						"Active",
						"Created On",
						"Options",
					]'
					:ajax-route="ajaxRoute"
					:selected-offering="selectedOffering"
				></voo-datatable>
			</div>
        </template>

        <voo-submissions
            v-if="store.currentTab == 'subs'"
            :submission-ajax-route="submissionAjaxRoute"
        ></voo-submissions>

		<voo-modal
			modal-id="oo-add-modal"
			:modal-title="newOffering.id > 0 ? 'Edit Optional Offering' : 'Create New Optional Offering'"
			header-icon="table"
			modal-size="lg"
		>
			<div class="modal-body">
				<voo-form
					:after-registration-start-date="afterRegistrationStartDate"
					:new-offering="newOffering"
					:portal-groups="portalGroups"
					:offering-form-id="offeringFormId"
					:selected-offering="selectedOffering"
					:key="updateInc"
				></voo-form>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeOfferingModal()">Cancel</p>
				<button
					v-if="!afterRegistrationStartDate || !newOffering.id"
					type="button"
					class="btn btn-primary m-1"
					data-js-id="oo-save-button"
					@click.prevent="saveOffering()"
				>Save Offering</button>
			</div>
		</voo-modal>

		<voo-view-modal
			modal-id="oo-view-modal"
			modal-title="View Optional Offering"
			header-icon="table"
			modal-size="lg"
		>
			<div class="modal-body">
				<div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showViewLoader">
					<p>Loading...</p>
					<progress></progress>
				</div>
				<voo-view-content
					:selected-offering="selectedOffering"
					v-if="displayViewContent"
				>
				</voo-view-content>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeOfferingViewModal()">Cancel</p>
			</div>
		</voo-view-modal>
	</div>
</template>

<script>
	export default {
		props: ["conference", "ajaxRoute", "portalGroups", "submissionAjaxRoute"],
		data() {
			return {
				newOffering: {
					name: "",
					description: "",
					platinum_members: false,
					portal_group_id: 0,
					inputs: [
						{
							name: "",
							price_per_unit: 0,
							required: false,
							maximum_available: 1,
							additional_input: null,
						}
					]
				},
				offeringFormId: "oo-form",
                selectedOffering: {},
                viewingOffering: {},
				showViewLoader: true,
                displayViewContent: false,
                store: {
					currentTab: "home"
				},
				updateInc: 0,
			};
		},
		computed: {
			afterRegistrationStartDate() {
				return ((new Date()).getTime() - (new Date(this.conference.registration_start_date)).getTime()) > 0
			},
			offeringModal() {
				return document.querySelector('[data-js-id="oo-add-modal"]');
			},
			offeringForm() {
				return document.querySelector(`[data-js-id="${this.offeringFormId}"]`);
			},
			offeringSaveButton() {
				return document.querySelector('[data-js-id="oo-save-button"]');
			},
			offeringDatatable() {
				return document.querySelector('#offering-datatable');
			},
			offeringViewModal() {
				return document.querySelector('[data-js-id="oo-view-modal"]');
			},
		},
		methods: {
			openOfferingModal() {
				this.newOffering = {
					name: "",
					description: "",
					platinum_members: false,
					portal_group_id: 0,
					inputs: [
						{
							name: "",
							price_per_unit: 0,
							required: false,
							maximum_available: 1,
							additional_input: null,
						}
					]
				}
				this.updateInc ++;
				$(this.offeringModal).modal("show");
			},
			closeOfferingModal() {
				$(this.offeringModal).modal("hide");
			},
			saveOffering() {
				const vm = this;
				const thisEvent = event;

				if ($(this.offeringForm).valid()) {
					thisEvent.target.innerText = "Processing....";
					thisEvent.target.disabled = true;

					axios
						.post("/vpanel/modules/optional-offerings/store", {
							offering: this.newOffering
						})
						.then(res => {
							const data = res.data;
							showNotification(data.message, data.result);

							$(vm.offeringModal).modal("hide");

							$(vm.offeringDatatable)
									.DataTable()
									.ajax.reload();
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == "422") {
									vm.formHasError = true;
									vm.formErrorMessage = error.response.data.message;
								} else {
									openApplicationErrorModal(error, "");
								}
							}

							thisEvent.target.innerText = "Save Offering";
							thisEvent.target.disabled = false;

							console.error(error);
						});
				}
			},
			openOfferingViewModal() {
				$(this.offeringViewModal).modal('show');
			},
			closeOfferingViewModal() {
				$(this.offeringViewModal).modal('hide');
			},
			showViewContent() {
				this.showViewLoader = false;
				this.displayViewContent = true;
			},
			hideViewContent() {
				this.showViewLoader = true;
				this.displayViewContent = false;
			},
			reloadDatatable() {
				$(this.offeringDatatable).DataTable().ajax.reload();
			}
		},
		mounted() {
			$(this.offeringModal).on("hidden.bs.modal", e => {
				if (this.offeringForm) {
					this.offeringForm.reset();
					$(this.offeringForm)
						.validate()
						.resetForm();
				}

				if (this.offeringSaveButton) {
					this.offeringSaveButton.innerText = "Save Offering";
					this.offeringSaveButton.disabled = false;
				}
			});

			$(this.offeringViewModal).on("hidden.bs.modal", e => {
				this.hideViewContent();
			});
		}
	};
</script>
