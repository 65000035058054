<script>
import { dataTableMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtFaqListOptions } from "./options/dtFaqListOptions";
import {
  faqAddModifyModal,
  faqAddModifyForm,
  faqAddModifyApply
} from "../../../faq/components/list/elements";

export default {
  mixins: [dataTableMixin],
  data() {
    return {
      moduleDatatableOptions: dtFaqListOptions
    };
  }
};
</script>

<style>
</style>