<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtSpeakerOptions } from "./dtSpeakerOptions";

export default {
    mixins: [dataTableMixin],
    props: [
        'selectedSpeaker'
    ],
    data() {
        return {
            moduleDatatableOptions: dtSpeakerOptions,
        }
    },
    mounted() {
        // Add event listeners to datatable buttons
        let dataTable = this.$el;
        const sm      = this;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id             = elementClicked.parentElement.dataset.id;

            switch (elementClicked.dataset.jsId) {
                case 'speaker-modify':
                    sm.selectedSpeaker.id = id;
                    // Show modal
                    sm.$parent.openAddModifyModal();
                    axios.get(`/vpanel/modules/conference/speakers/show/${id}`)
                        .then(res => {
                            try {
                                let speaker = res.data.speaker;

                                // Fill data
                                for (const prop in speaker) {
                                    if (speaker.hasOwnProperty(prop)) {
                                        let value = speaker[prop];

                                        sm.selectedSpeaker[prop] = value;
                                    }
                                }
                                // Show form
                                sm.$parent.showAddModifyForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });
                    break;

                case 'speaker-recycle':
                    sm.selectedSpeaker.id = id;

                    axios.post(`/vpanel/modules/conference/speakers/recycle/${id}`)
                        .then(res => {
                            try {
                                const data = res.data;

                                showNotification(data.message, data.result);

                                $(dataTable).DataTable().ajax.reload();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });
                    break;

                case 'speaker-resend-email':
                    sm.selectedSpeaker.id = id;

                    axios.post(`/vpanel/modules/conference/speakers/resend/${id}`)
                        .then(res => {
                            try {
                                const data = res.data;

                                showNotification(data.message, data.result);

                                $(dataTable).DataTable().ajax.reload();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });
                    break;
            }
        });
    }
}
</script>