<template>
    <div v-if="appData.showApplicationErrorSection">
        <div class="alert alert-danger">
            There has been an error. Please try again later. If you need assistance, call <a :href="`tel:+${phoneNumber}`">{{phoneNumber}}.</a>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    
    export default {
        name: "ArpApplicationErrorSection",
        props: [
            'phoneNumber'
        ],
        computed: {
            ...mapState("registration", ['appData'])
        }
    }
</script>