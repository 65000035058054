<template>
    <vue-modal :visible="isModalOpen" @on-close="$emit('close-sponsor-modal')" data-js-id="cm-sponsor-add-modify-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-handshake mr-1 text-tertiary"></span>{{ actionIsAdd ? 'Add' : 'Modify' }} Sponsor</h5>
        </template>

        <div class="modal-body">
            <div class="alert alert-danger" v-if="formHasError" v-html="formErrorMessage"></div>

            <VueLoader v-if="showLoader" />

            <form class="d-flex flex-column" v-else id="cm-sponsor-add-modify-form" novalidate @submit.prevent="saveSponsorForm">
                <CmSponsorAddModifyForm
                    :all-vendors="allVendors"
                    :action-is-add="actionIsAdd"
                    :current-sponsor="currentSponsor"
                    :sponsorship-levels="sponsorshipLevels"
                />
            </form>
        </div>

        <template #footer>
            <div class="d-flex justify-content-end mx-n1">
                <button type="button" class="btn btn-outline-primary mr-1" @click.prevent="$emit('close-sponsor-modal')">Cancel</button>
                <button type="submit" form="cm-sponsor-add-modify-form" class="btn btn-primary" :disabled="isFormProcessing">{{ isFormProcessing ? 'Processing...' : 'Save' }}</button>
            </div>
        </template>
    </vue-modal>
</template>
<script>
export default {
    name: 'CmSponsorAddModifyModal',
    props: [
        'showLoader',
        'allVendors',
        'actionIsAdd',
        'isModalOpen',
        'currentSponsor',
        'sponsorshipLevels'
    ],
    data() {
        return {
            formHasError: false,
            formErrorMessage: null,
            isFormProcessing: false
        };
    },
    watch: {
        isModalOpen(value) {
            if (!value) {
                if (this.formHasError) {
                    this.formHasError     = false;
                    this.formErrorMessage = null;
                }

                // Go through each input and remove validation classes
                let inputs = document.querySelectorAll('.valid, .error');
                inputs.forEach(input => {
                    if (input.classList.contains('valid')) {
                        input.classList.remove('valid');
                    }

                    if (input.classList.contains('error')) {
                        input.classList.remove('error');

                        if (input.nextElementSibling) input.nextElementSibling.remove();
                    }
                });
            }
        }
    },
    methods: {
        saveSponsorForm() {
            const sm			= this;
            const addModifyForm = document.getElementById('cm-sponsor-add-modify-form');
            let url             = '/vpanel/modules/conference/sponsors/';

            $(addModifyForm).validate({
                errorPlacement: function(error, element) {
                    if (element.is(":radio")) {
                        error.addClass("d-flex");
                        error.addClass('text-danger');
                        error.prependTo(element.parents("fieldset"));
                    } else {
                        error.addClass('text-danger');
                        error.insertAfter(element);
                    }
                }
            });

            if ($(addModifyForm).valid()) {
                sm.isFormProcessing   = true;
                let action          = sm.actionIsAdd ? 'store' : `update/${sm.currentSponsor.id}`;

                axios.post(`${url}${action}`, sm.currentSponsor)
                    .then(res => {
                        const data = res.data;

                        sm.isFormProcessing = false;

                        this.$emit('close-sponsor-modal');

                        $(document.getElementById('sponsor-datatable')).DataTable().ajax.reload();

                        showNotification(data.message, data.result);

                        if (sm.formHasError) {
                            sm.formHasError     = false;
                            sm.formErrorMessage = null;
                        }
                    })
                    .catch(error =>{
                        if (error.response) {
                            if (error.response.status == '422') {
                                const errors 	   = JSON.parse(error.response.data[0]);
                                const errorMessage = error.response.data.message;
                                sm.formHasError    = true;

                                let errorsUl = document.createElement('ul');
                                errorsUl.classList.add("list-styled");

                                for (error in errors) {
                                    errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                }

                                sm.formErrorMessage = errorMessage + errorsUl.outerHTML;
                            } else {
                                openApplicationErrorModal(error, "");
                                console.error(error);
                            }
                        }

                        sm.isFormProcessing = false;

                        console.error(error);
                    });
            }
        }
    }
}
</script>