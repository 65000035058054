export const dtPortalUserOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        // Name
        {
            data: 'name',
            render: function (data, type, row) {
                let fullName = row.first_name + ' ' + row.last_name;
                return `<p class="m-0 text-truncate" title="${fullName}">${fullName}</p>`
            }
        },
        // Username
        {
            data: 'username',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        // Groups
        {
            data: 'groups',
            render: function (data, type, row) {
                return `<p class="m-0 text-truncate" title="${data.join(', ')}">${data.join(', ')}</p>`;
            }
        },
        // Registration Date
        {
            data: 'created_at',
            type: 'date',
            render: function (data, type, row) {
                return data ? moment(data).format('M/D/YYYY h:mm A') : '-';
            }
        },
        {
            data: 'status',
            render: function (data, type, row) {
                let textClass = 'text-danger';

                if (data == 'Pending') {
                    textClass = 'text-warning';
                } else if (data == 'Active') {
                    textClass = 'text-success';
                }
                let statusText = `<p class="${textClass} m-0"><strong>${data}</strong></p>`;

                return statusText;
            }
        },
        // Options
        {
            data: 'options',
            orderable: false,
            searchable: false,
            width: '25%',
            render: function (data, type, row) {
                return `<div class="portal-user-options">
                                <button class="portal-user-impersonate btn btn-info text-white" data-id="${row.id}" data-js-id="portal-user-impersonate-button">Impersonate</button>
                                <button class="portal-user-recycle btn btn-outline-primary" data-id="${row.id}" data-js-id="portal-user-recycle-button">Recycle</button>
                                <button class="portal-user-modify btn btn-primary" data-id="${row.id}" data-js-id="portal-user-modify-button">Modify</button>
                            </div>`
            }
        }
    ]
}