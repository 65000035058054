<template>
    <div class="dl-row">
        <div class="dl-filter-col" v-if="allCategoriesUsed.length > 1 && allFiletypesUsed.length > 1">
            <div class="card bg-secondary-yellow border-primary-green mb-2" v-if="allCategoriesUsed.length > 1">
                <div class="card-body">
                    <p class="d-flex align-items-center m-0 text-20 font-weight-bold"><span class="fas fa-sliders-h mr-2"></span> Categories</p>
                    <div class="dl-filters">
                        <document-library-filter-button
                            v-for="(category, index) in allCategoriesUsed"
                            :key="'cat-filter' + index"
                            :filter="category"
                            filtering="categories"
                            :filter-by="filterBy"
                            :filtered-by="filteredBy.categories"
                        ></document-library-filter-button>
                    </div>
                </div>
            </div>
            <div class="card bg-secondary-yellow border-primary-green mb-2" v-if="allFiletypesUsed.length > 1">
                <div class="card-body">
                    <p class="d-flex align-items-center m-0 text-20 font-weight-bold"><span class="fas fa-sliders-h mr-2"></span> File Types</p>
                    <div class="dl-filters">
                        <document-library-filter-button
                            v-for="(file, index) in allFiletypesUsed"
                            :key="'file-filter' + index"
                            :filter="file"
                            filtering="doctype"
                            :filter-by="filterBy"
                            :filtered-by="filteredBy.doctype"
                        ></document-library-filter-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="dl-list-col">
            <div class="input-group form-group">
                <div class="input-group-prepend">
                    <div class="input-group-text"><span class="fas fa-search"></span></div>
                </div>
                <input type="search" class="form-control search" placeholder="Search documents...">
            </div>
            <div class="dl-list" v-if="documents.length">
                <document-library-list-item
                    v-for="(doc, index) in documents"
                    :key="'dl-li-' + index"
                    :doc="doc"
                ></document-library-list-item>
            </div>
            <div class="dl-no-results" v-else>
                <p class="m-0">No Documents to view</p>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: ['documents'],
    data() {
        return {
            docLibList: {},
            filteredBy: {
                categories: '',
                doctype: ''
            }
        }
    },
    computed: {
        allCategoriesUsed() {
            let categoryList = [];
            this.documents.forEach(doc => {
                doc.categories.forEach(cat => {
                    if (categoryList.indexOf(cat.name) === -1) {
                        categoryList.push(cat.name);
                    }
                })
            })
            return categoryList;
        },
        allFiletypesUsed() {
            let typeList = [];
            this.documents.forEach(doc => {
                if (typeList.indexOf(doc.file_type) === -1) {
                    typeList.push(doc.file_type);
                }
            })
            return typeList;
        }
    },
    methods: {
        initDocList() {
            const options = {
                item: 'dl-list-item',
                listClass: 'dl-list',
                valueNames: [
                    'dl-li-name',
                    { data: ['doctype', 'categories'] },
                ]
            }

            this.docLibList = new List('document-library', options)
        },
        clearAllFilters() {
            this.docLibList.filter();

            for (let key in this.filteredBy) {
                this.filteredBy[key] = '';
            }
        },
        filterBy(filter, col) {
            if (this.docLibList.filtered && this.filteredBy[col] == filter) {
                this.clearAllFilters();
            } else {
                this.clearAllFilters();

                this.filteredBy[col] = filter;
                this.docLibList.filter(item => {
                    return item.values()[col].includes(filter);
                })
            }
        }
    },
    mounted() {
        this.initDocList();
    }
}

</script>

<style lang="scss">
    .dl-row {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem;
    }

    .dl-filter-col, .dl-list-col { padding: .5rem; }

    .dl-filter-col {
        flex: 1 1 25%;
        min-width: 250px;
    }

    .dl-list-col { flex: 1 1 75%; }

    .dl-list {
        display: flex;
        flex-wrap: wrap;
        margin: -.5rem;
        align-items: flex-start;
    }
</style>