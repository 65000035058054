<template>
    <div>
        <template v-if="showForm">
            <h2>VGM Heartland Vendor Registration Form</h2>
            <vendor-registration-progress></vendor-registration-progress>

            <vendor-registration-validation-error-section
                v-if="appData.sections.show_validation_error_section"
            ></vendor-registration-validation-error-section>

            <vendor-registration-form-sections :phone-number="phoneNumber"></vendor-registration-form-sections>
        </template>

        <template v-else>
            <div class="alert alert-warning">
                The link you have used is invalid, please contact
                <a :href="`tel:+${phoneNumber}`">Events Hotline</a>
                if you need assistance.
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: [
            "crsf",
            "email",
            "showForm",
            "registration",
            "eligiblePackages",
            "sponsorshipPackages",
            "phoneNumber",
            "categoriesProductsOptions",
            "processingFee"
        ],
        data() {
            return {
                appData: this.$root.appData
            };
        },
        created() {
            this.appData.registration = this.registration;
            this.appData.registration.company_name = this.registration
                ? this.registration.company
                : "";
            this.appData.eligible_packages = this.eligiblePackages;
            this.appData.sponsorship_packages = this.sponsorshipPackages;
            this.appData.categories_products_options = this.categoriesProductsOptions;
            this.appData.processingFee = this.processingFee;

            this.appData.populateSelectedBoothItems();
        },
        mounted() {
            this.appData.phone_number = this.phoneNumber;

            // Add date of birth validation
            $.validator.addMethod(
                "dob",
                function(value, element) {
                    let isDateFormatValid = moment(value, "MM/DD/YYYY", true).isValid();

                    let isDateAfter = moment(value).isAfter("01/01/1920");

                    let isAtLeast18 = moment().diff(moment(value), "years") >= 18;

                    return isDateFormatValid && isDateAfter && isAtLeast18;
                },
                "Please enter valid date"
            );

            $.validator.addMethod("expdate", function(value, element) {
                let isDateFormatValid = moment(value, "MM/YY", true).isValid();

                let submittedMonth = moment(value, "MM/YY", true).month();
                let currentMonth = moment().month();

                let submittedYear = moment(value, "MM/YY", true).year();
                let currentYear = moment().year();

                let isDateAfter = false;

                let yearIsValid = submittedYear >= currentYear;

                if (yearIsValid) {
                    if (submittedYear > currentYear) {
                        isDateAfter = true;
                    } else if (submittedMonth >= currentMonth) {
                        isDateAfter = true;
                    } else {
                        isDateAfter = false;
                    }
                } else {
                    isDateAfter = false;
                }

                return isDateFormatValid && isDateAfter;
            });
        }
    };
</script>

<style>

input[type="checkbox"]+label.error {
    display: block;
}

</style>