<template>
	<div class="d-flex justify-content-center" v-if="appData.showNoPaymentConfirmation">
		<div class="d-flex alert alert-success">
			<div class="d-flex border-right border-success p-1">
				<span class="text-success fas fa-check-circle fa-10x"></span>
			</div>
			<div class="d-flex flex-column p-1">
				<h2>You have registered successfully!</h2>
				<p>Your registration confirmation will arrive via email shortly.</p>
				<p><strong>IMPORTANT:</strong> The confirmation email includes instructions on how to access your unique portal. Portal setup must be completed for CEU credits, conference handouts and other important information related to your time at Heartland Conference. If you do not receive an registration confirmation, please check your spam folder. Any questions should be directed to VGM Events at <a :href="`tel:+${appData.phoneNumber}`">{{ appData.phoneNumber }}</a>.</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from 'vuex';

	export default {
		computed: {
			...mapState("registration", {
				appData: (state) => state.appData
			})
		}
	}
</script>