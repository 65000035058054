<template>
	<div>
		<div v-for="(golfer, index) in selectedVendor.golfers" :key="index">
			<!-- not an input, just needs displayed... I think? -->
			<h5 class="m-0">Golfer #{{ index + 1 }}</h5>
			<div class="row">
				<div class="form-group col-md">
					<label :for="`golfer_${index}_first_name`">Golfer #{{ index + 1 }} First Name</label>
					<input
						class="form-control"
						type="text"
						:name="`golfer_${index}_first_name`"
						:id="`golfer_${index}_first_name`"
						title="First Name"
						required
						data-msg="Please provide first name"
						v-model="selectedVendor.golfers[index].first_name"
					/>
				</div>
				<div class="form-group col-md">
					<label :for="`golfer_${index}_last_name`">Golfer #{{ index + 1 }} Last Name</label>
					<input
						class="form-control"
						type="text"
						:name="`golfer_${index}_last_name`"
						:id="`golfer_${index}_last_name`"
						title="Last Name"
						required
						data-msg="Please provide last name"
						v-model="selectedVendor.golfers[index].last_name"
					/>
				</div>
			</div>

			<div class="d-flex flex-wrap mx-n2">
				<div class="form-group mx-2">
					<div class="radio-legend d-flex justify-content-start align-items-center">
						<p class="mr-3">Golf Club Rental<span class="required">*</span></p>
					</div>
					<fieldset>
						<legend class="label sr-only">Golf Club Rental</legend>
						<div class="form-check form-check-inline form-check-radio">
							<label class="form-check-label" :for="`golf_club_${index}_rental_1`">
								<input
									class="form-check-input"
									type="radio"
									:name="`golfer_${index}club_rental`"
									required
									data-msg="Please indicate if the golfer needs clubs"
									title="Golf Club Rental"
									value="1"
									:id="`golf_club_${index}_rental_1`"
									v-model="selectedVendor.golfers[index].club_rental"
								/>
								<span class="form-check-sign"></span>
								Yes
							</label>
						</div>
						<div class="form-check form-check-inline form-check-radio">
							<label class="form-check-label" :for="`golf_club_${index}_rental_0`">
								<input
									class="form-check-input"
									type="radio"
									:name="`golfer_${index}club_rental`"
									required
									data-msg="Please indicate if the golfer needs clubs"
									title="Golf Club Rental"
									value="0"
									:id="`golf_club_${index}_rental_0`"
									v-model="selectedVendor.golfers[index].club_rental"
								/>
								<span class="form-check-sign"></span>
								No
							</label>
						</div>
					</fieldset>
				</div>

				<div class="form-group mx-2 flex-grow-1" v-if="selectedVendor.golfers[index].club_rental == 1">
					<div class="radio-legend d-flex justify-content-start align-items-center">
						<p class="mr-3">Golf Club Rental Option{{index}}<span class="required">*</span></p>
					</div>
					<fieldset>
						<legend class="label sr-only">Golf Club Rental Option</legend>
						<div class="form-check form-check-inline form-check-radio">
							<label class="form-check-label" :for="`golf_club_${index}_rental_fm_left`">
								<input
									class="form-check-input"
									type="radio"
									:name="`golfer_${index}club_rental_option`"
									required
									data-msg="Please indicate what kind of clubs the golf needs"
									title="Golf Club Rental Option"
									value="FM Left"
									:id="`golf_club_${index}_rental_fm_left`"
									v-model="selectedVendor.golfers[index].club_rental_option"
								/>
								<span class="form-check-sign"></span>
								FM Left
							</label>
						</div>
						<div class="form-check form-check-inline form-check-radio">
							<label class="form-check-label" :for="`golf_club_${index}_rental_fm_right`">
								<input
									class="form-check-input"
									type="radio"
									:name="`golfer_${index}club_rental_option`"
									required
									data-msg="Please indicate what kind of clubs the golf needs"
									title="Golf Club Rental Option"
									value="FM Right"
									:id="`golf_club_${index}_rental_fm_right`"
									v-model="selectedVendor.golfers[index].club_rental_option"
								/>
								<span class="form-check-sign"></span>
								FM Right
							</label>
						</div>
						<div class="form-check form-check-inline form-check-radio">
							<label class="form-check-label" :for="`golf_club_${index}_rental_m_left`">
								<input
									class="form-check-input"
									type="radio"
									:name="`golfer_${index}club_rental_option`"
									required
									data-msg="Please indicate what kind of clubs the golf needs"
									title="Golf Club Rental Option"
									value="M Left"
									:id="`golf_club_${index}_rental_m_left`"
									v-model="selectedVendor.golfers[index].club_rental_option"
								/>
								<span class="form-check-sign"></span>
								M Left
							</label>
						</div>
						<div class="form-check form-check-inline form-check-radio">
							<label class="form-check-label" :for="`golf_club_${index}_rental_m_right`">
								<input
									class="form-check-input"
									type="radio"
									:name="`golfer_${index}club_rental_option`"
									required
									data-msg="Please indicate what kind of clubs the golf needs"
									title="Golf Club Rental Option"
									value="M Right"
									:id="`golf_club_${index}_rental_m_right`"
									v-model="selectedVendor.golfers[index].club_rental_option"
								/>
								<span class="form-check-sign"></span>
								M Right
							</label>
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["selectedVendor", "packages"],
		computed: {
			currentVendor() {
				return this.selectedVendor;
			}
		}
	};
</script>