<template>
	<div>
        <div class="row">
                <div class="form-group col-md">
                    <label for="content_reference_1">Content Reference 1</label>
                    <textarea
                        class="form-control"
                        name="content_reference_1"
                        id="content_reference_1"
                        title="Content Reference 1"
                        rows="5"
                        v-model="selectedCourse.content_reference_1"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
                    <label for="content_reference_2">Content Reference 2</label>
                    <textarea
                        class="form-control"
                        name="content_reference_2"
                        id="content_reference_2"
                        title="Content Reference 2"
                        rows="5"
                        v-model="selectedCourse.content_reference_2"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
                    <label for="content_reference_3">Content Reference 3</label>
                    <textarea
                        class="form-control"
                        name="content_reference_3"
                        id="content_reference_3"
                        title="Content Reference 3"
                        rows="5"
                        v-model="selectedCourse.content_reference_3"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
	export default {
		props: ["selectedCourse"],
		computed: {
			currentCourse() {
				return this.selectedCourse;
			}
		}
	};
</script>