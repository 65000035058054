<template>
	<div class="cm-attendee-section dt-section d-flex flex-column p-4 flex-grow-1">
		<div class="row mb-2">
			<div class="col">
				<h3 class="mb-1">{{ selectedConference.year }} Attendees</h3>
			</div>
			<div class="col d-flex justify-content-end align-items-center">
				<button class="btn btn-secondary-blue mr-1" @click.prevent="openMassCompleteModal()">Send Mass Complete Account Email</button>
				<button class="btn btn-outline-primary mr-1" @click.prevent="publishTranscripts()">Publish Transcripts</button>
				<button class="btn btn-primary" @click.prevent="openExpoPassModal()">Expo Pass Registration</button>
			</div>
		</div>

		<!-- Datatable -->
		<cm-atn-datatable
			table-id="attendees-datatable"
			:column-names="[
				'Name',
				'Username',
				'Badge ID',
				'Company',
				'Reg. Date',
				'Options',
			]"
			:ajax-route="ajaxRoute"
			:selected-attendee="selectedAttendee"
		></cm-atn-datatable>

		<!-- Modify Modal -->
		<cm-atn-modal
			modal-id="cm-attendees-add-modify-modal"
			modal-title="Attendee"
			header-icon="user"
			:selected-attendee="selectedAttendee"
			:modal-nav-options="[
				{'name': 'Contact Information', 'active': true, 'id': 'attendee-contact-tab', 'tabId': 'attendee-contact'},
				{'name': 'Reservation Information', 'active': false, 'id': 'attendee-reservation-tab', 'tabId': 'attendee-reservation'},
			]"
			:attendee-form="form"
			:attendee-button="button"
		>
			<div class="modal-body">
				<div class="row" v-if="formHasError">
					<div class="col">
						<div class="alert alert-danger">{{ formErrorMessage }}</div>
					</div>
				</div>

				<div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showLoader">
					<p>Loading...</p>
					<progress></progress>
				</div>

				<form
					class="d-flex flex-column"
					v-if="showForm"
					data-js-id="cm-attendee-add-modify-form"
					novalidate
				>
					<div class="tab-content">
						<div
							class="tab-pane fade show active"
							id="attendee-contact"
							role="tab-panel"
							aria-labelledby="attendee-contact-tab"
						>
							<cm-atn-form :selected-attendee="selectedAttendee"></cm-atn-form>
						</div>
						<div
							class="tab-pane fade"
							id="attendee-reservation"
							role="tab-panel"
							aria-labelledby="attendee-reservation-tab"
						>
							<cm-reservation-information-form
								:selected-attendee="selectedAttendee"
								:reservation-properties="reservationProperties"
							></cm-reservation-information-form>
						</div>
					</div>
				</form>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeAttendeeModal()">Cancel</p>
				<p
					class="btn btn-primary m-1"
					data-js-id="cm-attendee-add-modify-button"
					:disabled="formProcessing"
					@click.prevent="saveAttendee($event)"
				>Next</p>
			</div>
		</cm-atn-modal>

		<!-- Send Mass Complete Account Emaill Modal -->
		<cm-atn-send-mass-complete-account-confirmation-modal />

		<!-- Expo Pass Modal -->
		<vpanel-modal
			modal-id="expo-pass-registration"
			modal-title="Expo Pass Registration"
			header-icon="user"
			:modal-nav-options="[
				{'name': 'Attendee Information', 'active': true, 'id': 'expo-attendee-info-tab', 'tabId': 'expo-attendee-info'},
				{'name': 'Payment Information', 'active': false, 'id': 'expo-attendee-payment-tab', 'tabId': 'expo-attendee-payment'},
			]"
		>
			<div class="modal-body">
				<div class="row" v-if="expoFormHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="expoFormErrorMessage"></div>
					</div>
				</div>
				<form class="d-flex flex-column" action data-js-id="cm-expo-form">
					<div class="tab-content">
						<cm-atn-expo-pass-form
							class="tab-pane fade show active"
							role="tab-panel"
							id="expo-attendee-info"
							:reservation-properties="reservationProperties"
							:expo-details="expoDetails"
							aria-labelledby="expo-attendee-info-tab"
						></cm-atn-expo-pass-form>
						<cm-atn-expo-pass-payment-form
							class="tab-pane fade"
							role="tab-panel"
							id="expo-attendee-payment"
							:payment-details="expoDetails.payment_method"
							:subtotal="expoDetails.subtotal"
							:total="expoDetails.total"
							:discount="expoDetails.discount"
							aria-labelledby="expo-attendee-payment-tab"
						></cm-atn-expo-pass-payment-form>
					</div>
				</form>
				<div class="modal-footer d-flex justify-content-end pt-0">
					<p class="btn btn-outline-primary m-1 cancel-button" @click="closeExpoPassModal()">Cancel</p>
					<p
						class="btn btn-primary m-1"
						data-js-id="cm-expo-add-button"
						:disabled="expoPassFormProcessing"
						@click.prevent="saveExpoReg($event)"
					>Next</p>
				</div>
			</div>
		</vpanel-modal>

		<!-- Attendee Courses Modal -->
		<cm-atn-courses-modal
			modal-id="cm-atn-courses-modal"
			modal-title="Attendee's Course(s)"
			header-icon="apple-alt"
			modal-size="lg"
		>

			<div class="modal-body">
				<div class="row" v-if="formHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="formErrorMessage"></div>
					</div>
				</div>
				<vpanel-loader v-if="showLoader"></vpanel-loader>
				<form class="d-flex flex-column" data-js-id="cm-atn-courses-form" novalidate v-if="showForm">
					<cm-atn-courses-form :selected-attendee="selectedAttendee"></cm-atn-courses-form>

					<div class="modal-footer d-flex justify-content-end mx-n1">
						<button class="btn btn-outline-primary m-1 cancel-button" @click="closeAttendeeCourseModal()" :disabled="formProcessing">Cancel</button>
						<button class="btn btn-primary m-1" data-js-id="cm-atn-courses-update-button" @click.prevent="updateAttendeeCourses()" :disabled="formProcessing">Update</button>
					</div>
				</form>
			</div>
		</cm-atn-courses-modal>
		<cm-atn-delete-modal
			modal-id="cm-atn-delete-modal"
			modal-title="Delete Attendee"
			header-icon="apple-alt"
			modal-size="lg"
		>
		 <cm-atn-delete-form :selected-attendee="selectedAttendee">
			 </cm-atn-delete-form >
		</cm-atn-delete-modal>
	</div>
</template>

<script>
	export default {
		props: [
			"conferences",
			"currentConference",
			"ajaxRoute",
			"crsf",
			"reservationProperties"
		],
		data() {
			return {
				formProcessing: false,
				formErrorMessage: "",
				formHasError: false,
				expoFormErrorMessage: "",
				expoFormHasError: false,
				showForm: true,
				showLoader: false,
				recyclePromoCodeId: null,
				expoPassFormProcessing: false,
				form: '[data-js-id="cm-attendee-add-modify-form"]',
				button: '[data-js-id="cm-attendee-add-modify-button"]',
				expoDetails: {
                    conference_uuid: this.currentConference.uuid,
					user: {},
					reservations: [
						{
							attendee_information: {},
							reservation_options: {}
						}
					],
					company: {
						vgm_member_number: "",
						is_valid_vgm_member: false,
						is_vgm_member: 0,
						is_platinum_vgm_member: false,
						address: {},
						primary_industry_focus: "No Focus"
					},
					is_expo_pass_registration: true,
					payment_method: {
						address: {}
					},
					subtotal: 0.0,
					discount: 0.0,
					total: 0.0
				}
			};
		},
		computed: {
			selectedConference() {
				if (this.length) {
				} else {
					return this.currentConference;
				}
			},
			selectedAttendee() {
				if (this.length) {
				} else {
					return {
						id: null,
						badge_id: 1,
						reservation: {},
						reservation_options: {},
						company: {},
						courses: {}
					};
				}
			},
			modal() {
				return document.querySelector(
					'[data-js-id="cm-attendees-add-modify-modal"]'
				);
			},
			attendeeDeleteModal() {
				return document.querySelector('[data-js-id="cm-atn-delete-modal"]');
			},
			attendeeCoursesModal() {
				return document.querySelector('[data-js-id="cm-atn-courses-modal"]');
			},
			attendeeCoursesForm() {
				return document.querySelector('[data-js-id="cm-atn-courses-form"]');
			},
			attendeeCoursesUpdateButton() {
				return document.querySelector('[data-js-id="cm-atn-courses-update-button"]');
			},
			datatable() {
				return document.querySelector("#attendees-datatable");
			},
			expoPassModal() {
				return document.querySelector('[data-js-id="expo-pass-registration"]');
			},
			massCompleteModal() {
				return document.querySelector('[data-js-id="complete-account-email"]');
			},
			expoPassButton() {
				return document.querySelector('[data-js-id="cm-expo-add-button"]');
			},
			expoPassForm() {
				return document.querySelector('[data-js-id="cm-expo-form"]');
			}
		},
		methods: {
			openMassCompleteModal() {
				$(this.massCompleteModal).modal("show");
			},
			openAttendeeModal(type) {
				if (type == "new") {
					this.showModalForm();
				} else {
					this.hideModalForm();
				}

				$(this.modal).modal("show");
			},
			closeAttendeeModal() {
				$(this.modal).modal("hide");
			},
			openAttendeeDeleteModal() {
				$(this.attendeeDeleteModal).modal("show");
			},
			closeAttendeeDeleteModal() {
				$(this.attendeeDeleteModal).modal("hide");
			},
			openAttendeeCourseModal() {
				$(this.attendeeCoursesModal).modal("show");
			},
			closeAttendeeCourseModal() {
				$(this.attendeeCoursesModal).modal("hide");
				this.formHasError = false;
			},
			updateAttendeeCourses() {
				const vm = this;
				const attendeeCoursesForm = this.attendeeCoursesForm;
				const attendeeCoursesButton = this.attendeeCoursesUpdateButton;

				$(attendeeCoursesForm).validate({
					errorPlacement: function(error, element) {
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.prependTo(element.parents("fieldset"));
						} else {
							error.insertAfter(element);
						}
					}
				});

				if ($(attendeeCoursesForm).valid()) {
					vm.formProcessing = true;
					attendeeCoursesButton.innerText = "Processing...";

					axios.post("/vpanel/modules/conference/attendees/courses/update", vm.selectedAttendee)
						.then(res => {
							const data = res.data;
							showNotification(data.message, data.result);

							vm.formProcessing = false;
							vm.formHasError	  = false;
							attendeeCoursesButton.innerText = "Update";

							$(vm.attendeeCoursesModal).modal("hide");

							$(vm.datatable).DataTable().ajax.reload();
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == "422") {
									vm.formHasError = true;
									const errors 	   = error.response.data[0];
									const errorMessage = error.response.data.message;

									let errorsUl = document.createElement("ul");
									errorsUl.classList.add("list-styled");

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}
									vm.formErrorMessage = errorMessage + errorsUl.outerHTML;
								} else {
									openApplicationErrorModal(error, "");
								}
							}

							vm.formProcessing = false;
							attendeeCoursesButton.innerText = "Update";

							console.error(error);
						});
				}
			},
			saveAttendee(event) {
				const attendeeForm = document.querySelector(this.form);
				const attendeeButton = document.querySelector(this.button);

				$(attendeeForm).validate({
					errorPlacement: function(error, element) {
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.prependTo(element.parents("fieldset"));
						} else {
							error.insertAfter(element);
						}
					}
				});

				if ($(attendeeForm).valid()) {
					// Need to make sure we are on the reservation tab

					if (
						event.target.closest(".modal").querySelector(".nav-link.active").id ==
						"attendee-reservation-tab"
					) {
						this.formProcessing = true;
						attendeeButton.innerText = "Processing...";

						const vm = this;

						axios
							.post(
								"/vpanel/modules/conference/attendees/update",
								this.selectedAttendee
							)
							.then(res => {
								const data = res.data;
								showNotification(data.message, data.result);

								vm.formProcessing = false;
								attendeeButton.innerText = "Save Changes";

								$(vm.modal).modal("hide");

								$(vm.datatable)
									.DataTable()
									.ajax.reload();
							})
							.catch(error => {
								if (error.response) {
									if (error.response.status == "422") {
										vm.formHasError = true;
										vm.formErrorMessage = error.response.data.message;
									} else {
										openApplicationErrorModal(error, "");
									}
								}

								vm.formProcessing = false;
								attendeeButton.innerText = "Save Changes";

								console.error(error);
							});
					} else {
						$("#attendee-reservation-tab").click();
					}
				}
			},
			publishTranscripts() {
				const vm = this;
				const button = event.target;

				let originalButtonText = event.target.innerHTML;
				button.disabled = true;
				button.innerHTML = 'Processing...';

				axios.get('/vpanel/modules/conference/attendees/publish-transcripts')
					.then(res => {
						let data = res.data;
						button.disabled = false;
						button.innerHTML = originalButtonText;
						showNotification(data.message, data.result);
					})
					.catch(error => {
						openApplicationErrorModal(error, "");
						console.error(error);

						button.disabled = false;
						button.innerHTML = originalButtonText;
					});
			},
			saveExpoReg() {
				const expoPassForm = this.expoPassForm;
				const expoPassButton = this.expoPassButton;

				$(expoPassForm).validate({
					errorPlacement: function(error, element) {
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.prependTo(element.parents("fieldset"));
						} else {
							error.insertAfter(element);
						}
					}
				});

				if ($(expoPassForm).valid()) {
					// Need to make sure we are on the reservation tab
					if (
						event.target.closest(".modal").querySelector(".nav-link.active").id ==
						"expo-attendee-payment-tab"
					) {
						this.formProcessing = true;
						expoPassButton.innerText = "Processing...";

                        const vm = this;

                        vm.expoFormHasError = false;

						this.expoDetails.total =
							this.expoDetails.subtotal - this.expoDetails.discount;
						this.expoDetails.reservations[0].attendee_information = this.expoDetails.user;

						axios
							.post("/heartland/portal/register-attendees", this.expoDetails)
							.then(res => {
								const data = res.data;
                                showNotification("Expo pass registration complete.", data.result);

                                vm.expoFormHasError = false;

								vm.expoPassFormProcessing = false;

								$(vm.expoPassModal).modal("hide");

								$(vm.datatable)
									.DataTable()
									.ajax.reload();
							})
							.catch(error => {
								if (error.response) {
									if (error.response.status == "422") {
										vm.expoFormHasError = true;

                                        vm.expoFormErrorMessage = "<ul>";

                                        for (const key in error.response.data.errors) {
                                            if (error.response.data.errors.hasOwnProperty(key)) {
                                                const errorField = error.response.data.errors[key];

                                                vm.expoFormErrorMessage += `<li>${errorField[0]}</li>`;
                                            }
                                        }

										vm.expoFormErrorMessage += "</ul>";
									} else {
										openApplicationErrorModal(error, "");
									}
								}

								vm.formProcessing = false;
								expoPassButton.innerText = "Process Registration";

								console.error(error);
							});
					} else {
						$("#expo-attendee-payment-tab").click();
						expoPassButton.innerText = "Submit";
					}
				}
			},
			showModalForm() {
				this.showForm = true;
				this.showLoader = false;
			},
			hideModalForm() {
				$("#attendee-contact-tab").click();

				this.showForm = false;
				this.showLoader = true;
			},
			openExpoPassModal() {
				$(this.expoPassModal).modal("show");
			},
			closeExpoPassModal() {
				$(this.expoPassModal).modal("hide");
			}
		},
		mounted() {
			const vm = this;
			const attendeeForm = document.querySelector(this.form);

			$(this.modal).on("hidden.bs.modal", e => {
				if (attendeeForm) {
					attendeeForm.reset();
				}
			});

			$(this.expoPassModal).on("hidden.bs.modal", e => {
				if (vm.expoPassForm) {
					vm.expoPassForm.reset();
					$("#expo-attendee-info-tab").click();
					vm.expoDetails = {
						user: {},
						reservations: [
							{
								attendee_information: {},
								reservation_options: {}
							}
						],
						company: {
							vgm_member_number: "",
							is_valid_vgm_member: false,
							is_platinum_vgm_member: false,
							address: {}
						},
						is_expo_pass_registration: true,
						payment_method: {
							address: {}
						},
						subtotal: 0.0,
						discount: 0.0,
						total: 0.0,
						pass_total: 0.0
					};
				}
			});

			this.expoPassModal.addEventListener("click", e => {
				if (e.target.classList.contains("nav-link")) {
					if (e.target.id == "expo-attendee-payment-tab") {
						this.expoPassButton.innerText = "Process Registration";
					} else {
						this.expoPassButton.innerText = "Next";
					}
				}
			});
		}
	};
</script>
