<template>
    <div class="card my-2">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">Golfer #{{ index + 1 }}</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-md">
                    <label :for="`golfer_${index}_first_name`">First Name<span class="required">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`golfer_${index}_first_name`"
                        :id="`golfer_${index}_first_name`"
                        title="First Name"
                        required
                        data-msg="Please provide golfer's first name"
                        v-model="appData.add_ons.golfers[index].first_name"
                    />
                </div>

                <div class="form-group col-md">
                    <label :for="`golfer_${index}_last_name`">Last Name<span class="required">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`golfer_${index}_last_name`"
                        :id="`golfer_${index}_last_name`"
                        title="Last Name"
                        required
                        data-msg="Please provide golfer's last name"
                        v-model="appData.add_ons.golfers[index].last_name"
                    />
                </div>
            </div>
            <div class="row mb-n2"></div>

            <!-- <div class="row mb-n2">
                <div class="form-group col-md">
                    <p class="m-0">Golf Club Rental?<span class="required">*</span></p>
                    <div class="custom-inline-radio-buttons">
                        <fieldset>
                            <legend class="sr-only">Golf Club Rental?</legend>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    value="1"
                                    required
                                    data-msg="Please select if golfer needs clubs"
                                    :name="`golfer_${index}_club_rental`"
                                    :title="`golfer_${index}_club_rental`"
                                    :id="`golfer_${index}_club_rental_id_1`"
                                    v-model="appData.add_ons.golfers[index].club_rental"
                                />
                                <label class="custom-control-label" :for="`golfer_${index}_club_rental_id_1`">Yes</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    value="0"
                                    required
                                    data-msg="Please select if golfer needs clubs"
                                    :name="`golfer_${index}_club_rental`"
                                    :title="`golfer_${index}_club_rental`"
                                    :id="`golfer_${index}_club_rental_id_0`"
                                    v-model="appData.add_ons.golfers[index].club_rental"
                                />
                                <label class="custom-control-label" :for="`golfer_${index}_club_rental_id_0`">No</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="form-group col-md" v-if="showRentalOptions">
                    <p class="m-0">Golf Club Rental Options<span class="required">*</span></p>
                    <div class="custom-inline-radio-buttons">
                        <fieldset>
                            <legend class="sr-only">Golf Club Rental Options</legend>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    value="FM Left"
                                    required
                                    data-msg="Please select rental options"
                                    :name="`golfer_${index}_club_rental_option`"
                                    :title="`golfer_${index}_club_rental_option`"
                                    :id="`golfer_${index}_club_rental_option_1`"
                                    v-model="appData.add_ons.golfers[index].club_rental_option"
                                />
                                <label class="custom-control-label" :for="`golfer_${index}_club_rental_option_1`">FM Left</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    value="FM Right"
                                    required
                                    data-msg="Please select rental options"
                                    :name="`golfer_${index}_club_rental_option`"
                                    :title="`golfer_${index}_club_rental_option`"
                                    :id="`golfer_${index}_club_rental_option_2`"
                                    v-model="appData.add_ons.golfers[index].club_rental_option"
                                />
                                <label class="custom-control-label" :for="`golfer_${index}_club_rental_option_2`">FM Right</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    value="M Left"
                                    required
                                    data-msg="Please select rental options"
                                    :name="`golfer_${index}_club_rental_option`"
                                    :title="`golfer_${index}_club_rental_option`"
                                    :id="`golfer_${index}_club_rental_option_3`"
                                    v-model="appData.add_ons.golfers[index].club_rental_option"
                                />
                                <label class="custom-control-label" :for="`golfer_${index}_club_rental_option_3`">M Left</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    value="M Right"
                                    required
                                    data-msg="Please select rental options"
                                    :name="`golfer_${index}_club_rental_option`"
                                    :title="`golfer_${index}_club_rental_option`"
                                    :id="`golfer_${index}_club_rental_option_4`"
                                    v-model="appData.add_ons.golfers[index].club_rental_option"
                                />
                                <label class="custom-control-label" :for="`golfer_${index}_club_rental_option_4`">M Right</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div> -->
        </div>

    </div>
</template>

<script>
    export default {
        props: ["index"],
        data() {
            return {
                appData: this.$root.appData
            };
        },
        computed: {
            showRentalOptions() {
                return parseInt(this.appData.add_ons.golfers[this.index].club_rental);
            }
        }
    };
</script>