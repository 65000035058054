<template>
	<div class="my-2" id="vendor-site-statistics">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1 mb-2"><em>Site Statistics</em></h4>

		<div class="row">
			<div class="col-md">
				<button class="btn btn-outline-primary w-100" @click.prevent="showEditPageModal()">Edit Your Vendor Page</button>
			</div>
			<div class="col-md">
				<button class="btn btn-primary w-100" @click.prevent="viewVendorPage()">View Your Vendor Page</button>
			</div>
		</div>

		<vendor-dashboard-edit-vendor-page-modal
			:modal-id="editVendorPageModalId"
			modal-title="Edit Vendor Page"
			header-icon="align-justify"
			modal-size="xl"
		>
			<vendor-dashboard-edit-vendor-page-form :hide-edit-page-modal="hideEditPageModal" :company="company"></vendor-dashboard-edit-vendor-page-form>
		</vendor-dashboard-edit-vendor-page-modal>
	</div>
</template>

<script>
	export default {
		props: ["company", "registration"],
		data() {
			return {
				editVendorPageModalId: "edit-vendor-page-modal"
			};
		},
		computed: {
			modal() {
				return document.querySelector(
					`[data-js-id="${this.editVendorPageModalId}"]`
				);
			}
		},
		methods: {
			showEditPageModal() {
				$(this.modal).modal("show");
			},
			hideEditPageModal() {
				$(this.modal).modal("hide");
			},
			viewVendorPage() {
				window.open(`/exhibitors/${this.company.page_url}`);
			}
		}
	};
</script>