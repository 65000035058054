<template>
    <form data-js-id="vendor-registration-add-on-form">
        <div class="card my-2">
            <div class="card-header">
                <p class="card-title font-weight-bold m-0">Golf Outing?</p>
            </div>
            <div class="card-body">
                <div class="alert alert-danger">
                    Unfortunately, we are no longer accepting golf outing reservations, click <a href="#" @click.prevent="moveToNextStep()">here</a> or the Continue button below to move to the next step.
                </div>
                <div class="row">
                    <div class="form-group d-flex flex-column justify-content-center col-md" :class="{'mb-0': !showAddGolfers}">
                        <p class="m-0">Will your company be attending the golf outing?<span class="required">*</span></p>
                        <div class="custom-inline-radio-buttons">
                            <fieldset>
                                <legend class="sr-only">Golf Outing?</legend>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input
                                        class="custom-control-input"
                                        type="radio"
                                        name="golf_outing"
                                        required
                                        data-msg="Please indicate if going on golf outing"
                                        title="Golf Outing"
                                        value="1"
                                        id="golf_outing_yes"
                                        v-model.number="appData.add_ons.golf_outing"
                                        disabled
                                        @change="editGolfers($event)"
                                    />
                                    <label class="custom-control-label" for="golf_outing_yes">Yes - ${{appData.golfers_price}}</label>
                                </div>
                                <div class="custom-control custom-radio custom-control-inline">
                                    <input
                                        class="custom-control-input"
                                        type="radio"
                                        name="golf_outing"
                                        required
                                        data-msg="Please indicate if going on golf outing"
                                        title="Golf Outing"
                                        id="golf_outing_no"
                                        value="0"
                                        v-model.number="appData.add_ons.golf_outing"
                                        checked
                                    />
                                    <label class="custom-control-label" for="golf_outing_no">No</label>
                                </div>
                            </fieldset>
                        </div>
                    </div>

                    <template v-if="showAddGolfers">
                        <div class="form-group col-md">
                            <label for="number_of_golfers">How Many People?<span class="required">*</span></label>
                            <select
                                class="form-control"
                                name="number_of_golfers"
                                id="number_of_golfers"
                                title="How Many?"
                                required
                                data-msg="Please select a number of golfers"
                                @change="editGolfers($event)"
                            >
                                <option
                                    v-for="(number, index) in appData.add_ons.number_of_allowed_golfers"
                                    :key="index"
                                    :value="number"
                                    :selected="appData.add_ons.golfers.length == number"
                                >{{ number }}</option>
                            </select>
                        </div>
                    </template>
                </div>

                <div class="alert alert-warning">
                    Thank you for signing up to join us for golf. Due to difficulties in securing rental clubs over the last few years, we would like to encourage you to bring your own clubs or rent a set through <a href="http://www.clublender.com" target="_blank" title="External link to the Club Lender webiste in a new tab">www.clublender.com</a> or <a href="http://www.clubhub.com" target="_blank" title="External link to the Club Hub webiste in a new tab">www.clubhub.com</a>. If that is not an option, please reach out to <a href="mailto:heather.johnson@vgm.com" title="Opens a new email to heather.johnson@vgm.com in your default mailing application">heather.johnson@vgm.com</a> and they will work to track down a set for you.
                </div>

                <template v-if="showAddGolfers">
                    <vendor-registration-golfer-info
                        v-for="(badge, index) in appData.add_ons.golfers.length"
                        :key="index"
                        :index="index"
                    ></vendor-registration-golfer-info>
                </template>
            </div>
        </div>

        <vendor-registration-subtotal></vendor-registration-subtotal>

        <div class="d-flex justify-content-end m-n1">
            <button class="btn btn-outline-primary m-1" @click.prevent="moveToLastStep()">Go Back &amp; Edit</button>
            <button class="btn btn-primary-green m-1" @click.prevent="moveToNextStep()">Continue</button>
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                appData: this.$root.appData
            };
        },
        computed: {
            form() {
                return document.querySelector(
                    '[data-js-id="vendor-registration-add-on-form"]'
                );
            },
            showAddGolfers() {
                return parseInt(this.appData.add_ons.golf_outing);
            },
            numberOfGolfers() {
                return this.appData.add_ons.golfers.length;
            }
        },
        methods: {
            editGolfers(event) {
                const number = event.target.value;

                if (this.numberOfGolfers < number) {
                    const diff = number - this.numberOfGolfers;

                    for (let index = 0; index < diff; index++) {
                        this.appData.add_ons.golfers.push({
                            first_name: "",
                            last_name: "",
                            club_rental: 0,
                            club_rental_option: ""
                        });
                    }
                } else {
                    const diff = this.numberOfGolfers - number;

                    if (this.numberOfGolfers >= 1 && number) {
                        for (let index = 0; index < diff; index++) {
                            this.appData.add_ons.golfers.pop();
                        }
                    }
                }

                this.appData.updateGolferSubtotal();
            },
            moveToNextStep() {
                $('html, body').animate({ scrollTop: 0 }, 'fast');

                if ($(this.form).valid()) {
                    this.appData.hideAllSections();
                    this.appData.sections.show_booth_order_form = 1;
                    this.appData.current_step = 5;
                }
            },
            moveToLastStep() {
                this.appData.hideAllSections();
                this.appData.sections.show_sponsorship_selection_form = 1;
                this.appData.current_step = 3;
            }
        }
    };
</script>