<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtAgendaTagsListOptions } from "./options/dtAgendaTagsListOptions";

export default {
    name: "VpanelAgendaTagsListDatatable",
    mixins: [ dataTableMixin ],
    props: [ 'selectedTag' ],
    data() {
        return {
            moduleDatatableOptions: dtAgendaTagsListOptions
        };
    },
    mounted() {
        // Add event listeners to datatable buttons
        let dataTable = this.$el;
        const sm      = this;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id             = elementClicked.parentElement.dataset.id;
            let form;

            switch (elementClicked.dataset.jsId) {
                case 'agenda-tags-modify':
                    sm.selectedTag.id = id;
                    // Show modal
                    sm.$parent.openAddModifyModal();
                    axios.get(`/vpanel/modules/agenda/tags/show/${id}`)
                        .then(res => {
                            try {
                                let tag = res.data.data;

                                // Fill data
                                for (const prop in tag) {
                                    if (tag.hasOwnProperty(prop)) {
                                        let value = tag[prop];

                                        sm.selectedTag[prop] = value;
                                    }
                                }

                                // Show form
                                sm.$parent.showAddModifyForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });
                    break;

                case 'agenda-tags-recycle':
                    sm.selectedTag.id = id;

                    axios.post(`/vpanel/modules/agenda/tags/recycle/${id}`)
                        .then(res => {
                            try {
                                const data = res.data;

                                showNotification(data.message, data.result);

                                $(dataTable).DataTable().ajax.reload();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });
                    break;
            }
        });
    }
};
</script>