<template>
	<div>
		<div class="row">
			<div class="col-md form-group">
				<div class="radio-legend d-flex justify-content-start align-items-center">
					<p class="mr-3">
						Package Options
						<span class="required">*</span>
					</p>
				</div>
				<fieldset>
					<legend class="label sr-only">Package Options</legend>
					<div
						class="form-check form-check-inline form-check-radio"
						v-for="(vendorPackage, index) in packages"
						:key="index"
					>
						<label class="form-check-label" :for="`vendor_package_${vendorPackage.id}`">
							<input
								class="form-check-input"
								type="radio"
								name="vendor_package"
								required
								data-msg="Please select a package"
								:title="vendorPackage.name"
								:value="vendorPackage.id"
								:id="`vendor_package_${vendorPackage.id}`"
								v-model="selectedVendor.registration.selected_package_id"
							/>
							<span class="form-check-sign"></span>
							{{ vendorPackage.name }}
						</label>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="vendor_booth_id_location">Booth ID/Location</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Booth ID/Location"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					name="vendor_booth_id_location"
					id="vendor_booth_id_location"
					title="Enter Booth ID/Location"
					v-model="selectedVendor.registration.booth_id_location"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["selectedVendor", "packages"],
		computed: {
			currentVendor() {
				return this.selectedVendor;
			}
		},
		mounted() {}
	};
</script>