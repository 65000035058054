<template>
    <VueModal :visible="isModalOpen" @on-close="closeImportModal" id="sponsor-import-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-handshake mr-1 text-tertiary"></span>Import Previous Year's Sponsor</h5>
        </template>

        <div class="d-flex flex-column align-items-center" v-if="Object.keys(previousYearSponsors).length">
            <div class="alert alert-info" v-if="!hasError">
                <p class="m-0">Some previous year sponsors might not be listed here because you might have a similarly named sponsor already in your system.</p>
            </div>
            <div class="alert alert-danger" v-else>
                <p class="m-0">{{ errorMessage }}</p>
            </div>
            <form class="d-flex flex-column" id="cm-sponsor-import-form" novalidate @submit.prevent="saveForm">
                <div class="form-group has-label flex-row flex-wrap m-0">
                    <div
                        :key="`sponsor_${index}`"
                        class="custom-control custom-switch m-1"
                        v-for="(sponsor, index) in previousYearSponsors"
                    >
                        <input
                            :value="index"
                            type="checkbox"
                            :title="sponsor"
                            :name="`sponsors[]`"
                            :id="`sponsor_${index}`"
                            class="custom-control-input js-boolean-control"
                        />
                        <label class="custom-control-label" :for="`sponsor_${index}`">{{ sponsor }}</label>
                    </div>
                </div>
            </form>
        </div>

        <div class="alert alert-info" v-else>
            <p class="m-0">There are no Sponsors from the previous year.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-end">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="closeImportModal">Cancel</button>
                <button type="submit" form="cm-sponsor-import-form" class="btn btn-primary confirm-btn ml-2" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Confirm' }}</button>
            </div>
        </template>
    </VueModal>
</template>
<script>
    export default {
        name: 'CmSponsorImportModal',
        props: [
            "isModalOpen",
            "currentConference",
            "previousYearSponsors"
        ],
		data() {
			return {
                hasError: false,
                errorMessage: '',
                previousSponsorIds: [],
                isModalProcessing: false,
			};
		},
        methods: {
            saveForm() {
                this.isModalProcessing = true;

                let formData = new FormData(document.querySelector('#cm-sponsor-import-form'));

                axios.post(`/vpanel/modules/conference/sponsors/import/${this.currentConference.uuid}`, formData)
                    .then(res => {
                        const data             = res.data;
                        this.isModalProcessing = false;

                        showNotification(data.message, data.result);

                        $(document.getElementById('sponsor-datatable')).DataTable().ajax.reload();

                        this.closeImportModal();
                    })
                    .catch(error => {
                        if (error.response.status == '422') {
                            this.hasError     = true;
                            this.errorMessage = error.response.data.message;
                        } else {
                            openApplicationErrorModal(error);
                            console.error(error);
                        }

                        this.isModalProcessing = false;
                    });
            },
            closeImportModal() {
                this.hasError          = false;
                this.errorMessage      = '';
                this.isModalProcessing = false;
                this.$emit('close-sponsor-import-modal');
            }
        }
    }
</script>