<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtAgendaListOptions } from "./options/dtAgendaListOptions";

export default {
    name: "VpanelAgendaListDatatable",
    mixins: [dataTableMixin],
    data() {
        return {
            moduleDatatableOptions: dtAgendaListOptions
        };
    }
};
</script>