<template>
	<div>
		<div class="row">
			<div class="form-group col-md">
				<label for="vendor_first_name">First Name<span class="required">*</span></label>
				<input
					class="form-control"
					type="text"
					name="vendor_first_name"
					id="vendor_first_name"
					title="First Name"
					required
					data-msg="Please provide first name"
					v-model="selectedVendor.first_name"
				/>
			</div>
			<div class="form-group col-md">
				<label for="vendor_last_name">Last Name<span class="required">*</span></label>
				<input
					class="form-control"
					type="text"
					name="vendor_last_name"
					id="vendor_last_name"
					title="Last Name"
					required
					data-msg="Please provide last name"
					v-model="selectedVendor.last_name"
				/>
			</div>
			<div class="form-group col-md">
				<label for="vendor_title">Title/Position</label>
				<input
					class="form-control"
					type="text"
					name="vendor_title"
					id="vendor_title"
					title="Title/Position"
					v-model="selectedVendor.title"
				/>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-md">
				<label for="vendor_date_of_birth">Date Of Birth</label>
				<input
					class="form-control js-flatpickr"
					type="text"
					name="vendor_date_of_birth"
					id="vendor_date_of_birth"
					title="Date Of Birth"
					required
					data-msg="Please provide date of birth"
					v-mask="'##/##/####'"
					v-model="selectedVendor.date_of_birth"
				/>
			</div>
			<div class="form-group col-md">
				<label for="vendor_vgm_member_number">VGM Member Number</label>
				<input
					class="form-control"
					type="text"
					name="vendor_vgm_member_number"
					id="vendor_vgm_member_number"
					title="Company Name"
					required
					data-msg="Please provide company vgm member number"
					v-model="selectedVendor.company.vgm_member_number"
				/>
			</div>
			<div class="form-group col-md">
				<label for="vendor_company_name">Company Name</label>
				<input
					class="form-control"
					type="text"
					name="vendor_company_name"
					id="vendor_company_name"
					title="Company Name"
					required
					data-msg="Please provide company name"
					v-model="selectedVendor.company.name"
				/>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-md">
				<label for="vendor_address_1">Address<span class="required">*</span></label>
				<input
					class="form-control"
					type="text"
					name="vendor_address_1"
					id="vendor_address_1"
					title="Address"
					required
					data-msg="Please provide an address"
					v-model="selectedVendor.company.address_1"
				/>
			</div>
			<div class="form-group col-md">
				<label for="vendor_address_2">Address 2</label>
				<input
					class="form-control"
					type="text"
					name="vendor_address_2"
					id="vendor_address_2"
					title="Address 2"
					v-model="selectedVendor.company.address_2"
				/>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-md">
				<label for="vendor_city">City<span class="required">*</span></label>
				<input
					class="form-control"
					type="text"
					name="vendor_city"
					id="vendor_city"
					title="City"
					required
					data-msg="Please provide a city"
					v-model="selectedVendor.company.city"
				/>
			</div>
			<div class="form-group col-md">
				<label for="vendor_state_province">State/Province<span class="required">*</span></label>
				<input
					class="form-control"
					type="text"
					name="vendor_state_province"
					id="vendor_state_province"
					title="State/Province"
					required
					data-msg="Please provide a state/province"
					v-model="selectedVendor.company.state_province"
				/>
			</div>
			<div class="form-group col-md">
				<label for="vendor_postal_code">Postal Code<span class="required">*</span></label>
				<input
					class="form-control"
					type="text"
					name="vendor_postal_code"
					id="vendor_postal_code"
					title="Postal Code"
					required
					data-msg="Please provide a postal code"
					v-model="selectedVendor.company.postal_code"
				/>
			</div>
		</div>
		<div class="form-group">
			<label for="vendor_special_requests">Special Requests</label>
			<textarea
				class="form-control"
				type="text"
				name="vendor_special_requests"
				id="vendor_special_requests"
				title="Special Requests"
				v-model="selectedVendor.special_requests"
			></textarea>
		</div>
        <template v-if="sponsorshipLevels.length">
            <p><strong>Sponsorship Level(s)</strong></p>
            <div class="row">
                <div class="col-md form-group d-flex flex-row align-items-start has-label" v-for="(level, index) in sponsorshipLevels" :key="index">
                    <div class="custom-control custom-switch mt-1 ml-0">
                        <input
                            type="checkbox"
                            class="custom-control-input js-boolean-control module-config-input"
                            :id="`sponsorship_${level.id}`"
                            :name="`sponsorship_${level.id}`"
                            :value="`${level.id}`"
                            v-model="currentVendor.sponsorship_levels"
                        />
                        <label class="custom-control-label" :for="`sponsorship_${level.id}`">{{ level.level }}</label>
                    </div>
                </div>
            </div>
        </template>
	</div>
</template>

<script>
	export default {
        props: [
			'selectedVendor',
            "sponsorshipLevels"
        ],
		computed: {
			currentVendor() {
				return this.selectedVendor;
			}
		}
	};
</script>