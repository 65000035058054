//== Functionality for DataTable on list view ==//
export const dtDocumentCategoriesListOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    order: [],

    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        // Name
        {
            data: 'name',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}"><strong>${data}</strong></p>`
            }
        },
        // Documents
        {
            data: 'documents',
            render: function(data, type, row) {
                let html = 'N/A';
                let docList = '';
                if(data.length){
                    html = '<p class="m-0 text-truncate-multi" style="--ttmfs:16px;--ttmlh:1.25;--ttmlc:3;"';
                    data.forEach(function(doc, index) {
                        docList += `${doc.name}`;
                        docList += (index !== data.length - 1) ? ', ' : '';
                    });
                    html += ` title="${docList}">${docList}</p>`
                }
                return html;
            }
        },
        // Status
        {
            data: 'active',
            render: function (data, type, row) {
                return data ? '<p class="text-success m-0"><strong>Active</strong></p>' : '<p class="text-danger m-0"><strong>Inactive</strong></p>';
            }
        },
        //Last Modified
        {
            data: 'updated_at',
            render: function (data, type, row) {
                return data ? moment(data).format('MM/D/YYYY h:mm a') : 'N/A';
            }
        },
        // Options
        {
            data: 'options',
            render: function(data, type, row) {
                return '<div class="category-options" data-id="' + row.id + '">' +
                            '<p class="btn btn-danger" data-js-id="document-category-recycle">Recycle</p>' +
                            '<p class="btn btn-secondary-blue" data-js-id="document-category-modify">Modify</p></div>';
            }
        }
    ]
}