export const dtPortalCompanyOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    order: [[1, "desc"]],
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        {
            data: 'name',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: 'email',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "primary_industry_focus",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        { data: 'vgm_member_number' },
        // Options
        {
            data: 'options',
            width: "10%",
            orderable: false,
            searchable: false,
            render: function(data, type, row) {
                return `<div class="portal-groups-options">
                            <button class="portal-groups-modify btn btn-primary" data-id="${row.id}" data-js-id="portal-company-modify-button">Modify</button>
                        </div>`
            }
        }
    ]
};