<template>
    <div class="card" v-if="appData.showPaymentSection">
        <div class="card-header" v-if="getTotal">
            <p class="card-title font-weight-bold m-0">Please choose your payment method.

            </p>
        </div>
        <div class="card-header" v-else>
            <p class="card-title font-weight-bold m-0"  >Please confirm your purchase.</p>
        </div>
        <div class="card-body">
            <div class="alert alert-danger" v-if="registrationProcessError">
                <p class="m-0" v-if="fatalProcessError">
                    <strong>There was an error processing your registration.</strong>
                    <br />Please try again later. If you need assistance, call
                    <a :href="`tel:+${phoneNumber}`">{{ phoneNumber }}</a>
                </p>
                <p class="m-0" v-if="registrationProcessErrorMessage">{{ registrationProcessErrorMessage }}</p>
            </div>

            <div class="row">
                <div class="col-md" v-if="getTotal">
                    <form data-js-id="payment-form" autocomplete="off" >
                        <!-- Card Type -->
                        <div class="form-group">
                            <p class="mb-1">Card Type <span class="required">*</span></p>
                            <div class="custom-inline-radio-buttons">
                                <fieldset :disabled="hasZeroTotal" autocomplete="off">
                                    <legend class="sr-only">Card Type</legend>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input
                                            class="custom-control-input"
                                            type="radio"
                                            name="card_type"
                                            required
                                            data-msg="Please select a card type"
                                            title="Card Type"
                                            value="Visa"
                                            id="card_type_visa"
                                            v-model="activeReservation.payment_method.card_type"
                                        />
                                        <label class="custom-control-label" for="card_type_visa">Visa</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input
                                            class="custom-control-input"
                                            type="radio"
                                            name="card_type"
                                            required
                                            data-msg="Please select a card type"
                                            title="Card Type"
                                            value="Mastercard"
                                            id="card_type_mastercard"
                                            v-model="activeReservation.payment_method.card_type"
                                        />
                                        <label class="custom-control-label" for="card_type_mastercard">Mastercard</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input
                                            class="custom-control-input"
                                            type="radio"
                                            name="card_type"
                                            required
                                            data-msg="Please select a card type"
                                            title="Card Type"
                                            value="Discover"
                                            id="card_type_discover"
                                            v-model="activeReservation.payment_method.card_type"
                                        />
                                        <label class="custom-control-label" for="card_type_discover">Discover</label>
                                    </div>
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input
                                            class="custom-control-input"
                                            type="radio"
                                            name="card_type"
                                            required
                                            data-msg="Please select a card type"
                                            title="Card Type"
                                            value="American Express"
                                            id="card_type_amex"
                                            v-model="activeReservation.payment_method.card_type"
                                        />
                                        <label class="custom-control-label" for="card_type_amex">American Express</label>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <fieldset :disabled="hasZeroTotal" autocomplete="off">
                            <div class="row">
                                <!-- Credit Card Number -->
                                <div class="form-group col-md">
                                    <label for="credit_card">Credit Card Number <span class="required">*</span></label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="credit_card"
                                        title="Credit Card Number"
                                        id="credit_card"
                                        v-model="activeReservation.payment_method.credit_card_number"
                                        v-mask="'#### #### #### ####'"
                                        required
                                        data-msg="Please enter a Credit card Number"
                                        maxlength="19"
                                    />
                                </div>

                                <!-- CVV Code -->
                                <div class="form-group col-md">
                                    <label for="cvv_code">
                                        CVV Code
                                        <span class="required">*</span>
                                    </label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="cvv_code"
                                        title="CVV Code"
                                        id="cvv_code"
                                        v-model="activeReservation.payment_method.cvv_code"
                                        v-mask="'####'"
                                        required
                                        data-msg="Please enter a CVV Code"
                                        maxlength="4"
                                    />
                                </div>

                                <!-- Expiration Date -->
                                <div class="form-group col-md">
                                    <label for="expiration_date">
                                        Expiration Date
                                        <span class="required">*</span>
                                    </label>
                                    <input
                                        class="form-control js-flatpickr-exp-date"
                                        type="text"
                                        id="expiration_date"
                                        name="expiration_date"
                                        title="Expiration Date"
                                        v-model="activeReservation.payment_method.expiration_date"
                                        v-mask="'##/##'"
                                        data-msg-expdate="Please enter valid date"
                                        data-rule-expdate="true"
                                        placeholder="mm/yy"
                                        required
                                        data-msg-required="Please enter a date"
                                        maxlength="5"
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <!-- First Name -->
                                <div class="form-group col">
                                    <label for="card_first_name">
                                        First Name
                                        <span class="required">*</span>
                                    </label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="card_first_name"
                                        title="first name"
                                        id="card_first_name"
                                        v-model="activeReservation.payment_method.first_name"
                                        required
                                        data-msg="Please enter a first name"
                                        maxlength="50"
                                    />
                                </div>

                                <!-- Last Name -->
                                <div class="form-group col">
                                    <label for="card_last_name">
                                        Last Name
                                        <span class="required">*</span>
                                    </label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="card_last_name"
                                        title="ast name"
                                        id="card_last_name"
                                        v-model="activeReservation.payment_method.last_name"
                                        required
                                        data-msg="Please enter a last name"
                                        maxlength="50"
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col">
                                    <label for="card_address_1">
                                        Address
                                        <span class="required">*</span>
                                    </label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="card_address_1"
                                        title="Address"
                                        id="card_address_1"
                                        v-model="activeReservation.payment_method.address.address_1"
                                        required
                                        data-msg="Please enter a Address"
                                        maxlength="30"
                                    />
                                </div>
                                <div class="form-group col">
                                    <label for="card_address_2">Address 2</label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="card_address_2"
                                        title="Address"
                                        id="card_address_2"
                                        v-model="activeReservation.payment_method.address.address_2"
                                        maxlength="30"
                                    />
                                </div>
                            </div>

                            <div class="row">
                                <div class="form-group col">
                                    <label for="card_city">
                                        City
                                        <span class="required">*</span>
                                    </label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="card_city"
                                        title="City"
                                        id="card_city"
                                        v-model="activeReservation.payment_method.address.city"
                                        required
                                        data-msg="Please enter a City"
                                        maxlength="30"
                                    />
                                </div>
                                
                                <div class="form-group col-md">
                                    <label for="card_state_province">State/Province<span class="required">*</span></label>
                                    <select
                                        class="form-control"
                                        type="text"
                                        name="card_state_province"
                                        id="card_state_province"
                                        title="State/Province"
                                        v-model="activeReservation.payment_method.address.state_province"
                                        required
                                        data-msg="Please enter a State/Province"
                                        maxlength="2"
                                    >
                                        <option v-for="(state, index) in states" :key="index" :value="state.value">{{state.name}}</option>
                                    </select>
                                </div>

                                <div class="form-group col">
                                    <label for="card_postal_code">
                                        Postal Code
                                        <span class="required">*</span>
                                    </label>
                                    <input
                                        class="form-control"
                                        type="text"
                                        name="card_postal_code"
                                        id="card_postal_code"
                                        title="Postal Code"
                                        v-model="activeReservation.payment_method.address.postal_code"
                                        required
                                        data-msg="Please enter a postal code"
                                        maxlength="9"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div class="col-md-4">
                    <div class="card mb-2" v-if="getEarlyBirdDiscount || getGroupDiscount">
                        <div class="card-header">
                            <p class="card-title font-weight-bold m-0">Your Order Discount(s)</p>
                        </div>
                        <div class="card-body">
                            <p class="font-weight-bold">The following discount(s) were auto-applied:</p>
                            <div class="d-flex justify-content-between" v-if="getEarlyBirdDiscount">
                                <p class="m-0"><strong>Early Bird Discount</strong></p>
                                <p class="m-0">${{ getEarlyBirdDiscount.toFixed(2) }}</p>
                            </div>
                            <div class="d-flex justify-content-between" v-if="getGroupDiscount">
                                <p class="m-0"><strong>Group Discount</strong></p>
                                <p class="m-0">${{ getGroupDiscount.toFixed(2) }}</p>
                            </div>
                            <div class="d-flex justify-content-between" v-if="activeReservation.promoCodeUsed">
                                <p class="m-0"><strong>Promo Code: {{ activeReservation.promoCodeUsed }}</strong></p>
                                <p class="m-0">${{ activeReservation.promoDiscount.toFixed(2) }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="card-header">
                            <p class="card-title font-weight-bold m-0">Your Order</p>
                        </div>

                        <div class="card-body">
                            <div class="d-flex justify-content-between">
                                <p class="m-0">
                                    <strong>Subtotal</strong>
                                </p>
                                <p class="m-0">${{ (getSubTotal).toFixed(2) }}</p>
                            </div>

                            <div class="d-flex justify-content-between">
                                <p class="m-0">
                                    <strong>Discount</strong>
                                </p>
                                <p class="m-0">${{ getDiscount.toFixed(2) }}</p>
                            </div>

                            <div class="d-flex justify-content-between">
                                <p class="m-0">
                                    <strong>Processing Fee</strong>
                                </p>
                                <p class="m-0">${{ (getTotal * (processingFee / 100)).toFixed(2) }}</p>
                            </div>

                            <div class="d-flex justify-content-between border-top mt-1 pt-1">
                                <p class="m-0">
                                    <strong>Total</strong>
                                </p>
                                <p class="m-0">${{ (getTotal * ((processingFee / 100) + 1)).toFixed(2) }}</p>
                            </div>

                            <div class="d-flex justify-content-between border-top mt-1 pt-1" v-if="getTotal">
                                <p class="small">A {{ processingFee }}% credit card processing fee will be added to your order.</p>
                            </div>
                        </div>
                    </div>

                    <div class="d-flex justify-content-end mt-1 mx-n1">
                        <button class="btn btn-outline-primary m-1" @click="goBack()">Go Back and Edit</button>
                        <button
                            class="btn btn-primary m-1"
                            :disabled="paymentProcessing"
                            @click.prevent="processPayment()"
                        >{{ confirmButtonText }}</button>
                    </div>
                    <p class="alert alert-info">
                        Registration cancellations must be received via email to <a href="mailto:events@vgm.com" title="Opens an email conversation with events@vgm.com in your default mailing application">events@vgm.com</a> and will be subject to any payment processing fees already charged. Refund requests made before 30 days of event will receive a full refund minus processing fees.  Refund requests made within 30 days of the event will receive 50% refund, minus any processing fees.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapState, mapGetters } from 'vuex';
    import * as _ from 'lodash'
    export default {
        props: [ "processingFee" ],
        data() {
            return {
                registrationProcessError: false,
                registrationProcessErrorMessage: "",
                confirmButtonText: "Confirm Purchase",
                paymentProcessing: false,
                fatalProcessError: false
            };
        },
        computed: {
            ...mapState("registration", [
                'activeReservation',
                'appData',
                'states',
            ]),
            ...mapGetters('registration', [
                'getEarlyBirdDiscount',
                'getGroupDiscount',
                'getSubTotal',
                'getDiscount',
                'getTotal'
            ]),
            reservationProperties() {
                return this.appData.reservationProperties
            },
            currentReservation() {
                return this.appData.currentReservation
            },
            phoneNumber() {
                return this.appData.phoneNumber;
            },
            hasZeroTotal() {
                return this.getTotal == 0;
            }
        },
        methods: {
            ...mapActions("registration", ["setVar", "hideAllSections"]),
            processPayment() {
                let form = document.querySelector(`[data-js-id="payment-form"]`);
                let formIsValid = true;
                this.paymentProcessing = true;
                this.confirmButtonText = "Processing...";
                this.registrationProcessError = false;

                if (!this.hasZeroTotal) {
                    $(form).validate();
                    formIsValid = $(form).valid()
                }

                if (formIsValid) {
                    let data = _.cloneDeep(this.activeReservation)
                    data['subtotal'] = this.getSubTotal
                    data['earlyBirdDiscount'] = this.getEarlyBirdDiscount
                    data['groupDiscount'] = this.getGroupDiscount
                    data['discount'] = this.getDiscount
                    
                    this.setVar({ob: "appData", variable: "showAttendeeReservationInformationSectionError", value: false});
                    this.setVar({ob: "appData", variable: "showAttendeeReservationInformationSectionErrorMsg", value: ""});

                    data.reservations.forEach(reservation => {
                        reservation.optional_offerings = reservation.optional_offerings.filter(offerings => offerings.selected)
                    })

                    // Make request to process payment
                    axios
                        .post("/heartland/portal/register-attendees", data)
                        .then(res => {
                            this.setVar({ob: "appData", variable: "showPaymentConfirmation", value: true});
                            this.setVar({ob: "appData", variable: "showPaymentSection", value: false});
                            this.setVar({ob: "appData", variable: "current_step", value: 6});
                            this.paymentProcessing = false;
                            this.confirmButtonText = "Confirm Purchase";
                            $("html, body").animate({ scrollTop: 0 }, "fast");
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == 422) {
                                    const data = error.response.data;

                                    const section = data.message.section;
                                    this.paymentProcessing = false;
                                    this.confirmButtonText = "Confirm Purchase";
                                    this.hideAllSections();

                                    switch (section) {
                                        case "AdminInfoSection":
                                            let adminForm = document.querySelector(
                                                `[data-js-id="admin-info-form"]`
                                            );
                                            this.setVar({ob: "appData", variable: "showAdminInfoSection", value: true});
                                            for (const error in data.errors) {
                                                let prop = `${error}`;

                                                $(adminForm)
                                                    .validate()
                                                    .showErrors({
                                                        [prop]: `${data.errors[error]}`
                                                    });
                                            }
                                            this.setVar({ob: "appData", variable: "current_step", value: 2});
                                            break;

                                        case "AttendeeReservationInformationSection":
                                            this.setVar({ob: "appData", variable: "showAttendeeReservationInformationSection", value: true});
                                            this.setVar({ob: "appData", variable: "showAttendeeReservationInformationSectionError", value: true});
                                            if (data.errors.lah_option_error) {
                                                let showAttendeeReservationInformationSectionErrorMsg = "<ul>";

                                                data.errors.lah_option_error.forEach(error => {
                                                    showAttendeeReservationInformationSectionErrorMsg += `<li>${error}</li>`
                                                })

                                                showAttendeeReservationInformationSectionErrorMsg += "</ul>";
                                                this.setVar({ob: "appData", variable: "showAttendeeReservationInformationSectionErrorMsg", value: showAttendeeReservationInformationSectionErrorMsg});
                                            } else if (data.errors) {
                                                let showAttendeeReservationInformationSectionErrorMsg =
                                                    "<ul>";

                                                for (const error in data.errors[0][0]) {
                                                    showAttendeeReservationInformationSectionErrorMsg += `<li>${data.errors[0][0][error]}</li>`
                                                }

                                                showAttendeeReservationInformationSectionErrorMsg += "</ul>";
                                                this.setVar({ob: "appData", variable: "showAttendeeReservationInformationSectionErrorMsg", value: showAttendeeReservationInformationSectionErrorMsg});
                                            }
                                            break;

                                        default:
                                            this.setVar({ob: "appData", variable: "showPaymentSection", value: true});
                                            this.registrationProcessError = true
                                            this.registrationProcessErrorMessage = data.message
                                            break;
                                    }
                                } else if (error.response.status == 400) {
                                    this.registrationProcessError = true;
                                    this.registrationProcessErrorMessage = error.response.data.message;
                                    this.fatalProcessError = false;
                                } else {
                                    this.registrationProcessError = true;
                                    this.fatalProcessError = true;
                                }
                            }

                            this.paymentProcessing = false;
                            this.confirmButtonText = "Confirm Purchase";

                            $("html, body").animate({scrollTop: 0}, "fast");
                        });
                } else {
                    this.paymentProcessing = false;
                    this.confirmButtonText = "Confirm Purchase";

                    $("html, body").animate({ scrollTop: 0 }, "fast");
                }
            },
            goBack() {
                this.setVar({ob: "appData", variable: "showPaymentSection", value: false});
                this.setVar({ob: "appData", variable: "showRegistrationConfirmation", value: true});
                this.setVar({ob: "appData", variable: "current_step", value: 4});
                $("html, body").animate({scrollTop: 0}, "fast");
            }
        }
    };
</script>
