export const dtOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom: "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>" +
        "<'dt-body'tr>" +
        "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    order: [[2, "desc"]],
    columns: [
        {
            data: 'title',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "type",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "tracks",
            render: function(data, type, row) {
                let html = 'N/A';
                if (data.length) {
                    html = '<p class="m-0">';
                    data.forEach(function(tracks, index) {
                        html += `${tracks}`;
                        html += (index !== data.length - 1) ? ', ' : '';
                    });
                    html += '</p>'
                }
                return html;
            }
        },
        {
            data: "session_time",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        }
    ]
}