<template>
    <div class="p-4">
        <p class="h5 font-weight-bold">Driver Pick Up Requests</p>

        <template v-if="pickUpRequests.length">
            <input type="search" class="form-control" placeholder="Search requests" v-model="listSearch">
            <div class="form-table">
                <div class="ft-row ft-header">
                    <p class="ft-cell justify-content-between">Company Name<span class="ft-sort" :class="sortClass('company_name')" @click="sortMethod('company_name')"></span></p>
                    <p class="ft-cell justify-content-between">Assignee<span class="ft-sort" :class="sortClass('assigned_driver_name')" @click="sortMethod('assigned_driver_name')"></span></p>
                    <p class="ft-cell ftw-1 justify-content-between">Status<span class="ft-sort" :class="sortClass('status')" @click="sortMethod('status')"></span></p>
                    <p class="ft-cell justify-content-between">Pickup Time<span class="ft-sort" :class="sortClass('created_at')" @click="sortMethod('requested_pick_up_datetime', 'date')"></span></p>
                    <p class="ft-cell ftw-3">Options</p>
                </div>
                <div
                    v-for="(request, index) in sortedList"
                    :key="index"
                    class="ft-row"
                >
                    <p class="ft-cell">{{ request.company_name }}</p>
                    <p class="ft-cell">{{ request.assigned_driver_name }}</p>
                    <p class="ft-cell ftw-1">{{ request.status }}</p>
                    <p class="ft-cell"> {{ request.requested_pick_up_date }} {{ request.requested_pick_up_time_h }}</p>
                    <div class="ft-cell ftw-3">
                        <button class="mr-1 btn btn-primary" @click="openRideRequestModal(index)">View Request</button>
                        <button class="mr-1 btn btn-secondary" @click="assignRequestToUser(request)" v-if="!request.assigned_driver_id">Assign to me</button>
                        <button class="mr-1 btn btn-info" @click="markRequestComplete(request)" v-if="request.status != 'Complete' && request.assigned_driver_id == user.id">Mark Complete</button>
                    </div>
                </div>
            </div>

            <vdpm-ride-request-modal
                :request-data="selectedRequest"
                :drivers="drivers"
                :is-driver-admin="isDriverAdmin"
            ></vdpm-ride-request-modal>
        </template>

        <template v-else>
            No requests found.
        </template>
    </div>
</template>

<script>
import { showNotification } from "./../../../../core/vpanel/vpanel-functions";
export default {
    props: [
        "pickUpRequests",
        "drivers",
        "isDriverAdmin",
        "displayAlert",
        "alertMessage",
        "alertClass",
        "user"
    ],
    data() {
        return {
            selectedIndex: 0,
            sort: 'assigned_driver_name',
            sortFormat: 'string',
            sortDir: 'asc',
            listSearch: ''
        };
    },
    computed: {
        rideRequestModal() {
            return document.querySelector(
                '[data-js-id="driver-request-details-modal"]'
            );
        },
        selectedRequest() {
            return this.sortedList[this.selectedIndex];
        },
        searchedList() {
            let newArray = [...this.pickUpRequests];

            if (this.listSearch) {
                let companyName = newArray.filter(item => {
                   return item['company_name'].toLowerCase().search(this.listSearch.toLowerCase()) !== -1
                });

                if (companyName.length > 0) {
                    return companyName;
                } else {
                    let driverName = newArray.filter(item => {
                        return item['assigned_driver_name'].toLowerCase().search(this.listSearch.toLowerCase()) !== -1
                    });
                    
                    if (driverName.length > 0) {
                        return driverName;
                    } else {
                        let status = newArray.filter(item => {
                            return item['status'].toLowerCase().search(this.listSearch.toLowerCase()) !== -1
                        });

                        if (status.length > 0) {
                            return status;
                        }
                    }
                }
                
                return newArray;
            } else {
                return newArray;
            }
        },
        sortedList() {
            let cs = this.sort;
            let newArray = [...this.searchedList];

            return newArray.sort((a, b) => {
                if (this.sortDir == 'asc') {
                    if (this.sortFormat == 'date') {
                        return this.getTimestamp(a[cs]) - this.getTimestamp(b[cs]);
                    } else {
                        return a[cs].localeCompare(b[cs], 'en', { sensitivity: 'base' });
                    }
                } else {
                    if (this.sortFormat == 'date') {
                        return this.getTimestamp(b[cs]) - this.getTimestamp(a[cs]);
                    } else {
                        return b[cs].localeCompare(a[cs], 'en', { sensitivity: 'base' });
                    }
                }
            });
        },
    },
    methods: {
        sortMethod(sort, format = 'string') {
            if (this.sort === sort) {
                this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort = sort;
                this.sortDir = 'asc';
                this.sortFormat = format;
            }
        },
        sortClass(sort) {
            if (this.sort === sort) {
                return this.sortDir === 'asc' ? 'asc' : 'desc';
            }
        },
        openRideRequestModal(index) {
            this.selectedIndex = index;

            $(this.rideRequestModal).modal("show");
        },
        markRequestComplete(request) {
            const button = event.target;

            let originalButtonText = button.innerHTML;

            button.disabled = true;
            button.innerHTML = "Processing...";

            let data = { id: request.id };

            axios
                .post(
                    "/vpanel/modules/driver-portal/mark-request-complete",
                    data
                )
                .then(res => {
                    window.location.reload();
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == "422") {
                            showNotification(
                                "There was an error processing your request.",
                                "danger"
                            );
                        } else {
                            openApplicationErrorModal(error, "");
                            console.error(error);
                        }
                    }

                    button.disabled = false;
                    button.innerHTML = originalButtonText;

                    console.error(error);
                });
        },
        getTimestamp(date, format = "YYYY-MM-DD HH:mm:ss") {
            return moment(date, format).format("YYYYMMDDHHmmss");
        },
        assignRequestToUser(request) {
             const button = event.target;

            let originalButtonText = button.innerHTML;

            button.disabled = true;
            button.innerHTML = "Processing...";

            let data = { id: request.id, user_id: this.user.id };

            axios
                .post(
                    "/vpanel/modules/driver-portal/assign-to-driver",
                    data
                )
                .then(res => {
                    window.location.reload();
                })
                .catch(error => {
                    if (error.response) {
                        if (error.response.status == "422") {
                            showNotification(
                                "There was an error processing your request.",
                                "danger"
                            );
                        } else {
                            openApplicationErrorModal(error, "");
                            console.error(error);
                        }
                    }

                    button.disabled = false;
                    button.innerHTML = originalButtonText;

                    console.error(error);
                });
        }
    },
    mounted() {
        if (this.displayAlert) {
            showNotification(this.alertMessage, this.alertClass);
        }
    }
};
</script>

<style lang="scss" scoped>
    .ft-sort { cursor: pointer; }
</style>
