//== Functionality for DataTable on list view ==//
export const dtAgendaTagsListOptions = {
    scrollY: '0',
    scrollCollapse: 'true',

    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",

    columns: [
        // Name
        {
            data: 'name',
            width: '30%',
        },
        // Type
        {
            data: 'tag_type',
            width: '10%',
        },
        // agendas
        {
            data: 'agenda',
            width: '30%',
            render: function(data, type, row) {
                let html = 'N/A';
                if (data.length) {
                    html = '';
                    data.forEach(type => {
                        html += `<p class="m-0">${type.title}</p>`;
                    });
                }
                return html;
            }
        },
        // Status
        {
            data: 'active',
            render: function(data, type, row) {
                return data ? '<p class="text-success m-0"><strong>Active</strong></p>' : '<p class="text-danger m-0"><strong>Inactive</strong></p>';
            }
        },
        // Options
        {
            data: 'options',
            render: function(data, type, row) {
                return  `<div class="speaker-options" data-id="${row.id}">
                            <button class="btn btn-outline-danger" data-js-id="agenda-tags-recycle">Recycle</button>
                            <button class="btn btn-secondary-blue" data-js-id="agenda-tags-modify">Modify</button>
                        </div>`;
            }
        }
    ],
}