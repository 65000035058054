<template>
	<div class="modal-body">
		<form :data-js-id="offeringFormId" novalidate>
			<h5 class="mb-1">{{ offering.name }}</h5>
			<p>{{ offering.description }}</p>

			<div class="border-top">
				<optional-offering-input
					v-for="(input, index) in offerings[offeringIndex].inputs"
					:key="index"
					:input="input"
					:index="index"
					:inputs="offering.inputs"
					:update-subtotal="updateSubtotal"
				></optional-offering-input>
			</div>
			<p class="text-right m-0 font-weight-bold" v-if="showSubtotal">Subtotal: ${{ parseFloat(this.subtotal).toFixed(2) }}</p>
		</form>
	</div>
</template>

<script>
export default {
	props: [
		"isPlatinum",
		"offering",
		"offerings",
		"offeringIndex",
		"offeringFormId",
		"subtotal",
		"updateSubtotal",
		"showSubtotal"
	],
};
</script>
