<template>
	<div class="card-body">
		<form action>
			<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>
			<div class="form-group">
				<label for="vendor_page_description">Company Description</label>
				<textarea
					class="form-control"
					type="text"
					name="vendor_page_description"
					id="vendor_page_description"
					title="Company Description"
                    v-model="page.description"
				></textarea>
			</div>

            <div class="form-group">
				<label for="vendor_page_promotional_offering">Promotional Offering</label>
				<textarea
					class="form-control"
					type="text"
					name="vendor_page_promotional_offering"
					id="vendor_page_promotional_offering"
					title="Promotional Offering"
                    v-model="page.promotional_offering"
				></textarea>
			</div>

            <div class="form-group">
				<label for="vendor_page_heartland_giveaway">Heartland Giveaway</label>
				<textarea
					class="form-control"
					type="text"
					name="vendor_page_heartland_giveaway"
					id="vendor_page_heartland_giveaway"
					title="Heartland Giveaway"
                    v-model="page.heartland_giveaway"
				></textarea>
			</div>

			<div class="d-flex justify-content-end m-n1">
				<button class="btn btn-outline-primary m-1" @click.prevent="hideEditPageModal()">Cancel</button>
				<button class="btn btn-primary m-1" @click.prevent="publishPageChanges()">Publish Page Changes</button>
			</div>
		</form>
	</div>
</template>

<script>
	export default {
		props: ["hideEditPageModal", "company"],
		data() {
			return {
				pageContent: null,
				showErrorDiv: false,
                errorMessage: false,
			};
        },
        computed: {
            page() {
                return this.company.page;
            }
        },
		methods: {
			publishPageChanges() {
				const vm = this;
				const thisEvent = event;

				this.page.description = CKEDITOR.instances["vendor_page_description"].getData();
				this.page.promotional_offering = CKEDITOR.instances["vendor_page_promotional_offering"].getData();
                this.page.heartland_giveaway = CKEDITOR.instances["vendor_page_heartland_giveaway"].getData();

				this.showErrorDiv = false;
                this.errorMessage = "";

				thisEvent.target.disabled = true;
				thisEvent.target.innerHTML = 'Processing...';

				axios
					.post("/portal/dashboard/vendor/update-page-content", {page: this.page})
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						logApplicationError(error);
						console.error(error);

						thisEvent.target.disabled = false;
						thisEvent.target.innerHTML = 'Publish Page Changes';

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								vm.showErrorDiv = true;
								vm.errorMessage = errorsUl.outerHTML;
							} else {
								vm.showErrorDiv = true;
								vm.errorMessage =
									'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
							}
						};

						console.error(error);
					});
			}
		},
		mounted() {
			CKEDITOR.replace("vendor_page_description", {
				removePlugins: "maximize,resize"
			});
			CKEDITOR.replace("vendor_page_promotional_offering", {
				removePlugins: "maximize,resize"
			});
            CKEDITOR.replace("vendor_page_heartland_giveaway", {
				removePlugins: "maximize,resize"
			});

			CKEDITOR.instances['vendor_page_description'].setData(this.page.description);
			CKEDITOR.instances['vendor_page_promotional_offering'].setData(this.page.promotional_offering);
			CKEDITOR.instances['vendor_page_heartland_giveaway'].setData(this.page.heartland_giveaway);
		}
	};
</script>

<style>
</style>
