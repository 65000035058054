<template>
	<div class="d-flex flex-column cm-vendor-section dt-section">
		<div class="row mb-2">
			<div class="col">
				<h3 class="mb-1">{{ selectedConference.year }} Vendors</h3>
			</div>
			<div class="col d-flex justify-content-end align-items-center">
				<button class="btn btn-primary" @click.prevent="openVendorInviteModal()">Invite Vendor</button>
				<button class="btn btn-secondary-blue ml-1" @click.prevent="openMessageEditorModal()">Edit Message From the Heartland</button>

				<cm-vendor-message-editor-modal
					modal-id="cm-vendor-message-editor-modal"
					modal-title="Edit Message From The Heartland"
					header-icon="edit"
					modal-size="lg"
				>
					<div class="modal-body">
						<div class="row" v-if="messageFormHasError">
							<div class="col">
								<div class="alert alert-danger" v-html="messageFormErrorMessage"></div>
							</div>
						</div>
						<cm-vendor-message-editor-form
						:message-from-the-heartland="messageFromTheHeartland">

						</cm-vendor-message-editor-form>
					</div>

					<div class="modal-footer d-flex justify-content-end mx-n1">
						<p class="btn btn-outline-primary m-1 cancel-button" @click="closeMessageEditorModal()">Cancel</p>
						<p class="btn btn-primary m-1" @click.prevent="updateMessage()">Edit Message</p>
					</div>

				</cm-vendor-message-editor-modal>
			</div>
		</div>

		<cm-vendor-datatable
			table-id="vendor-datatable"
			:column-names="[
				'Company',
				'Username',
				'Email',
				'VGM #',
				'Inv. Date',
				'Reg. Date',
				'Options',
			]"
			:ajax-route="ajaxRoute"
			:selected-vendor="selectedVendor"
		></cm-vendor-datatable>

		<cm-vendor-modify-modal
			modal-id="cm-vendor-modify-modal"
			modal-title="Modify Vendor"
			header-icon="address-card"
			modal-size="lg"
			:modal-nav-options="[
        		{'name': 'Contact Information', 'active': true, 'id': 'vendor-contact-tab', 'tabId': 'vendor-contact'},
        		{'name': 'Package/Booth Information', 'active': false, 'id': 'vendor-package-tab', 'tabId': 'vendor-package'},
        		{'name': 'Badge Information', 'active': false, 'id': 'vendor-badges-tab', 'tabId': 'vendor-badges'},
        		{'name': 'Golfer Information', 'active': false, 'id': 'vendor-golfer-tab', 'tabId': 'vendor-golfer'},
    		]"
		>
			<div class="modal-body">
				<div class="row" v-if="modifyFormHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="modifyFormErrorMessage"></div>
					</div>
				</div>

				<div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showLoader">
					<p>Loading...</p>
					<progress></progress>
				</div>
				<form class="d-flex flex-column" v-if="showForm" data-js-id="cm-vendor-modify-form" novalidate>
					<div class="tab-content">
						<div
							class="tab-pane fade show active"
							id="vendor-contact"
							role="tab-panel"
							aria-labelledby="vendor-contact-tab"
						>
							<cm-vendor-modify-contact-form :selected-vendor="selectedVendor" :sponsorship-levels="sponsorshipLevels"></cm-vendor-modify-contact-form>
						</div>
						<div
							class="tab-pane fade"
							id="vendor-package"
							role="tab-panel"
							aria-labelledby="vendor-package-tab"
						>
							<cm-vendor-modify-package-form :selected-vendor="selectedVendor" :packages="packages"></cm-vendor-modify-package-form>
						</div>
						<div
							class="tab-pane fade"
							id="vendor-badges"
							role="tab-panel"
							aria-labelledby="vendor-badges-tab"
						><cm-vendor-modify-badges-form :selected-vendor="selectedVendor"></cm-vendor-modify-badges-form></div>
						<div
							class="tab-pane fade"
							id="vendor-golfer"
							role="tab-panel"
							aria-labelledby="vendor-golfer-tab"
						><cm-vendor-modify-golfers-form :selected-vendor="selectedVendor"></cm-vendor-modify-golfers-form></div>
					</div>
				</form>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeVendorModifyModal()">Cancel</p>
				<p class="btn btn-primary m-1" data-js-id="vendor-modify-button" @click.prevent="saveVendor()">Next</p>
			</div>
		</cm-vendor-modify-modal>

		<cm-vendor-invite-modal
			modal-id="cm-vendor-invite-modal"
			modal-title="Invite New Vendor"
			header-icon="address-card"
			modal-size="lg"
			:packages="packages"
		>
			<div class="modal-body">
				<div class="row" v-if="inviteFormHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="inviteFormErrorMessage"></div>
					</div>
				</div>
				<cm-vendor-invite-form :vendor-invite="vendorInvite" :packages="packages"></cm-vendor-invite-form>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeVendorInviteModal()">Cancel</p>
				<button class="btn btn-primary m-1" data-js-id="vendor-invite-button" @click.prevent="sendInvite()">Send Invite</button>
			</div>
		</cm-vendor-invite-modal>

		<cm-vendor-cancel-invite-modal
			modal-id="cm-vendor-cancel-invite-modal"
			modal-title="Cancel Vendor Invite"
			header-icon="address-card"
			modal-size="lg"
		>
			<div class="modal-body">
				<div class="row" v-if="cancelInviteFormHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="cancelInviteFormErrorMessage"></div>
					</div>
				</div>
				<div class="row align-items-center flex-column">
					<span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
                    <p class="h4 font-weight-bold">Are you sure you want to cancel this invite?</p>
				</div>
				<div class="row justify-content-center">
					<button type="button" class="btn btn-outline-primary cancel-btn" @click="closeVendorCancelInviteModal()">Cancel</button>
					<button type="button" class="btn btn-primary confirm-btn ml-2" @click="cancelVendorInvite()">Yes, please cancel this invite</button>
				</div>
			</div>
		</cm-vendor-cancel-invite-modal>
	</div>
</template>

<script>
	export default {
		props: ["currentConference", "packages", "ajaxRoute", "messageFromTheHeartland", "sponsorshipLevels"],
		data() {
			return {
				showForm: true,
				showLoader: false,
				formErrorMessage: "",
				inviteFormHasError: false,
				inviteFormErrorMessage: false,
				cancelInviteFormHasError: false,
				cancelInviteFormErrorMessage: false,
				vendorInvite: {
                    conference_uuid: this.currentConference.uuid,
					package: "",
					company_name: ""
				},
				modifyFormHasError: false,
				modifyFormErrorMessage: false,
				messageFormHasError: false,
				messageFormErrorMessage: false,
			};
		},
		computed: {
			selectedConference() {
				if (this.length) {
				} else {
					return this.currentConference;
				}
			},
			selectedVendor() {
				return {
					company: {},
					registration: {},
					badges: [],
					golfers: [],
					package_info: {}
				};
			},
			inviteModal() {
				return document.querySelector('[data-js-id="cm-vendor-invite-modal"]');
			},
			modifyModal() {
				return document.querySelector('[data-js-id="cm-vendor-modify-modal"]');
			},
			messageEditorModal() {
				return document.querySelector('[data-js-id="cm-vendor-message-editor-modal"]');
			},
			inviteForm() {
				return document.querySelector('[data-js-id="cm-vendor-invite-form"]');
			},
			cancelInviteModal() {
				return document.querySelector('[data-js-id="cm-vendor-cancel-invite-modal"]');
			},
			modifyForm() {
				return document.querySelector('[data-js-id="cm-vendor-modify-form"]');
			},
			modifyButton() {
				return document.querySelector('[data-js-id="vendor-modify-button"]');
			},
			datatable() {
				return document.querySelector("#vendor-datatable");
			}
		},
		methods: {
			openVendorInviteModal() {
				$(this.inviteModal).modal("show");
			},
			closeVendorInviteModal() {
				$(this.inviteModal).modal("hide");
				this.vendorInvite = {
                    conference_uuid: this.currentConference.uuid,
					package: ""
				};
			},
			openVendorModifyModal() {
				this.hideModalForm();
				$(this.modifyModal).modal("show");
			},
			openMessageEditorModal() {
				$(this.messageEditorModal).modal('show');
			},
			closeVendorModifyModal() {
				this.hideModalForm();
				this.selectedVendor = {
					company: {},
					registration: {},
					badges: [],
					golfers: [],
					package_info: {}
				};

				$(this.modifyModal).modal("hide");
				document.querySelector('#vendor-contact-tab').click();
			},
			closeMessageEditorModal() {
				$(this.messageEditorModal).modal('hide');
			},
			openVendorCancelInviteModal() {
				$(this.cancelInviteModal).modal('show');
			},
			closeVendorCancelInviteModal() {
				$(this.cancelInviteModal).modal('hide');
			},
			sendInvite() {
				const vm = this;
				const thisEvent = event;

				event.target.innerText = "Processing...";
				event.target.disabled = true;

				this.inviteFormHasError = false;
				this.inviteFormErrorMessage = "";

				if ($(this.inviteForm).valid()) {
					axios
						.post(
							"/vpanel/modules/conference/vendor/send-invite",
							this.vendorInvite
						)
						.then(res => {
							vm.closeVendorInviteModal();

							showNotification(res.data.message, res.data.result);

							$(vm.datatable)
									.DataTable()
									.ajax.reload();

							thisEvent.target.innerText = "Send Invite";
							thisEvent.target.disabled = false;
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == 422) {
									const errors = error.response.data.message;

									let errorsUl = document.createElement("ul");
									errorsUl.classList.add("list-unstyled");

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}

									vm.inviteFormHasError = true;
									vm.inviteFormErrorMessage = errorsUl.outerHTML;
								} else {
									openApplicationErrorModal(error, "");
									console.error(error);
								}
							} else {
								openApplicationErrorModal(error, "");
								console.error(error);
							}
							thisEvent.target.innerText = "Send Invite";
							thisEvent.target.disabled = false;
						});
				} else {
					thisEvent.target.innerText = "Send Invite";
					thisEvent.target.disabled = false;
				}
			},
			cancelVendorInvite() {
				const vm = this;
				const thisEvent = event;

				event.target.innerText = "Processing...";
				event.target.disabled = true;

				if ($(this.inviteForm).valid()) {
					axios
						.post(
							"/vpanel/modules/conference/vendor/cancel-invite",
							this.selectedVendor
						)
						.then(res => {
							vm.closeVendorCancelInviteModal();

							showNotification(res.data.message, res.data.result);

							$(vm.datatable)
									.DataTable()
									.ajax.reload();

							thisEvent.target.innerText = "Yes, please cancel this invite";
							thisEvent.target.disabled = false;
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == 422) {
									const errors = error.response.data.message;

									let errorsUl = document.createElement("ul");
									errorsUl.classList.add("list-unstyled");

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}

									vm.cancelInviteFormHasError = true;
									vm.cancelInviteFormErrorMessage = errorsUl.outerHTML;
								} else if (error.response.status == 404) {
									vm.cancelInviteFormHasError = true;
									vm.cancelInviteFormErrorMessage = errorsUl.outerHTML
								} else {
									openApplicationErrorModal(error, "");
									console.error(error);
								}
							} else {
								openApplicationErrorModal(error, "");
								console.error(error);
							}
							thisEvent.target.innerText = "Yes, please cancel this invite";
							thisEvent.target.disabled = false;
						});
				} else {
					thisEvent.target.innerText = "Yes, please cancel this invite";
					thisEvent.target.disabled = false;
				}
			},
			saveVendor() {
				const vendorModifyForm = this.modifyForm;
				const vendorModifyBtn = this.modifyButton;

				$(vendorModifyForm).validate({
					errorPlacement: function(error, element) {
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.prependTo(element.parents("fieldset"));
						} else {
							error.insertAfter(element);
						}
					}
				});

				if ($(vendorModifyForm).valid()) {
					// Need to make sure we are on the golfers tab

					if (event.target.closest(".modal").querySelector(".nav-link.active").id == "vendor-golfer-tab") {
						this.formProcessing = true;
						vendorModifyBtn.innerText = "Processing...";

						const vm = this;

						axios
							.post(
								"/vpanel/modules/conference/vendor/update",
								this.selectedVendor
							)
							.then(res => {
								const data = res.data;
								showNotification(data.message, data.result);

								vm.formProcessing = false;
								vendorModifyBtn.innerText = "Save Changes";

								$(vm.modifyModal).modal("hide");

								$(vm.datatable)
									.DataTable()
									.ajax.reload();
							})
							.catch(error => {
								if (error.response) {
									if (error.response.status == "422") {
										vm.formHasError = true;
										vm.formErrorMessage = error.response.data.message;
									} else {
										openApplicationErrorModal(error, "");
									}
								}

								vm.formProcessing = false;
								vendorModifyBtn.innerText = "Save Changes";

								console.error(error);
							});
					} else {
						let currentActiveTab = event.target.closest(".modal").querySelector(".nav-link.active");

						currentActiveTab.nextElementSibling.click();
					}
				}
			},
			updateMessage() {
				const vm = this;

				const thisEvent = event;

				event.target.innerText = "Processing...";
				event.target.disabled = true;

				let message = CKEDITOR.instances['message_from_the_heartland'].getData();

				axios.post('/vpanel/modules/conference/vendor/update-message-from-heartland', {message: message})
				.then(res => {
					const data = res.data;

					vm.closeMessageEditorModal();

					showNotification(data.message, data.result);

					thisEvent.target.innerText = "Edit message from the heartland";
					thisEvent.target.disabled = false;
				})
				.catch(error => {
					if (error.response) {
						if (error.response.status == "422") {
							vm.messageFormHasError = true;
							vm.messageFormErrorMessage = error.response.data.message;
						} else {
							openApplicationErrorModal(error, "");
						}
					} else {
						openApplicationErrorModal(error, "");
					}

					thisEvent.target.innerText = "Edit message from the heartland";
					thisEvent.target.disabled = false;
				})
			},
			hideModalForm() {
				this.showForm = false;
				this.showLoader = true;
			},
			showModalForm() {
				this.showForm = true;
				this.showLoader = false;
			},
		}
	};
</script>
