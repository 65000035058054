<template>
    <div>
        <div class="vp-status-bar-list">
            <p v-if="currentOrder.order_number">Order: <span>#{{ currentOrder.order_number }}</span></p>
            <p v-if="currentOrder.created_at">Created At: <span>{{ orderCreatedDate }}</span></p>
            <p v-if="currentOrder.status">Order Status: <span :class="{'text-success' : orderStatusApproved, 'text-danger': orderStatusDeclined}">{{ currentOrder.status }}</span></p>
            <p v-if="currentOrder.ram">RAM: <span>{{ currentOrder.ram }}</span></p>
            <p v-if="currentOrder.is_invoice == 1" class="text-warning">Paying by Invoice</p>
        </div>

        <div class="card-group border border-bottom-0">
            <div class="card shadow-none">
                <div class="card-body pb-2">
                    <h5>Billing Info</h5>
                    <p class="m-0 lh-xs">{{ ifUseElseNot(currentOrder.billing_first_name) }} {{ ifUseElseNot(currentOrder.billing_last_name) }}</p>
                    <p class="m-0 lh-xs">{{ ifUseElseNot(currentOrder.billing_address_1) }} {{ ifUseElseNot(currentOrder.billing_address_2) }}</p>
                    <p class="m-0 lh-xs">{{ ifUseElseNot(currentOrder.billing_city) }}, {{ ifUseElseNot(currentOrder.billing_state_province) }} {{ ifUseElseNot(currentOrder.billing_postal_code) }}</p>
                </div>
            </div>

            <div class="card shadow-none border-left">
                <div class="card-body pb-2">
                    <div class="d-inline-flex flex-column align-items-stretch">
                        <h5>Totals</h5>
                        <p class="m-0 lh-xs d-flex justify-content-between"><strong>Subtotal:</strong> <span class="ml-2">{{ currentOrder.subtotal }}</span></p>
                        <p class="m-0 lh-xs d-flex justify-content-between" v-if="currentOrder.discount"><strong>Discount{{ currentOrder.promo_code ? ` (${currentOrder.promo_code})` : '' }}:</strong> <span class="ml-2">{{ currentOrder.discount == '$0.00' ? '-' : currentOrder.discount }}</span></p>
                        <p class="m-0 lh-xs d-flex justify-content-between" v-if="currentOrder.refund_amount !== '$0.00'"><strong>Refunded Amount:</strong> <span class="ml-2">{{ currentOrder.refund_amount == '$0.00' ? '-' : currentOrder.refund_amount }}</span></p>
                        <p class="m-0 lh-xs d-flex justify-content-between"><strong>Total:</strong> <span class="ml-2">{{ currentOrder.total }}</span></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="p-1 border border">
            <div class="vp-status-bar-list pb-0">
                <p v-if="currentOrder.id">Database ID: <span>{{ currentOrder.id }}</span></p>
                <p v-if="currentOrder.converge_transaction_id">Converge Transaction Id: <span>{{ currentOrder.converge_transaction_id }}</span></p>
            </div>
        </div>

        <div class="my-2 border p-2">
            <h5>Order History</h5>

            <div v-for="(historyItem, index) in currentOrder.order_history" :key="index">
                <div class="d-flex align-items-center justify-content-start px-1" :class="{ 'bg-light': index % 2 == 0 }">
                    <p class="my-0">{{ orderHistoryCreatedDate(historyItem) }}</p>
                    <p class="my-0 ml-2">{{ historyItem.comment }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["selectedOrder"],
        computed: {
            orderStatusApproved() {
                return this.currentOrder.status == "APPROVAL";
            },
            orderStatusDeclined() {
                return this.currentOrder.status == "DECLINED";
            },
            orderCreatedDate() {
                return moment(this.currentOrder.created_at).format('M/D/YYYY h:mm A');
            },
            currentOrder() {
                return this.selectedOrder;
            }
        },
        methods: {
            ifUseElseNot(val) {
                return val ? val : ''
            },
            orderHistoryCreatedDate(history) {
                return moment(history.created_at).format('M/D/YYYY h:mm A');
            }
        }
    };
</script>