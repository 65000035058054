<template>
    <div>
        <div class="card my-2">
            <div class="card-header">
                <p class="card-title font-weight-bold m-0">Please provide your contact information</p>
            </div>
            <div class="card-body">
                <div class="row">
                    <!-- First Name -->
                    <div class="form-group col-md">
                        <label for="first_name">
                            First Name
                            <span class="required">*</span>
                        </label>
                        <input
                            class="form-control"
                            type="text"
                            id="first_name"
                            name="speaker[user][first_name]"
                            title="First Name"
                            v-model="speaker.user.first_name"
                            required
                            data-msg="Please enter the speaker's first name."
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="last_name">
                            Last Name
                            <span class="required">*</span>
                        </label>
                        <input
                            class="form-control"
                            id="last_name"
                            name="speaker[user][last_name]"
                            required
                            title="Last Name"
                            type="text"
                            v-model="speaker.user.last_name"
                            data-msg="Please enter the speaker's last name."
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md">
                        <label for="email">
                            Email
                            <span class="required">*</span>
                        </label>
                        <input
                            class="form-control"
                            id="email"
                            name="speaker[user][email]"
                            required
                            title="Email Address"
                            type="email"
                            v-model="speaker.user.email"
                            data-msg="Please enter the speaker's email."
                            data-rule-email="true"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="phone">
                            Cell Phone
                            <span class="required">*</span>
                        </label>
                        <input
                            class="form-control"
                            id="phone"
                            name="speaker[user][phone]"
                            required
                            title="(XXX) XXX-XXXX"
                            placeholder="(XXX) XXX-XXXX"
                            type="tel"
                            v-mask="'(###) ###-####'"
                            v-model="speaker.user.phone"
                            data-msg="Please enter the speaker's cell phone number."
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md">
                        <label for="company_name">Company Name</label>
                        <input
                            class="form-control"
                            type="text"
                            name="speaker[speaker_info][company_name]"
                            id="company_name"
                            title="Company Name"
                            v-model="speaker.speaker_info.company_name"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="job_title">Job Title <span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="speaker[speaker_info][job_title]"
                            id="job_title"
                            title="Job Title"
                            required
                            data-msg="Please enter the speaker's job title."
                            v-model="speaker.speaker_info.job_title"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="card my-2">
            <div class="card-header">
                <p class="card-title font-weight-bold m-0">Address</p>
            </div>
            <div class="card-body">
                <div class="row">
                    <!-- Address 1 -->
                    <div class="form-group col-md">
                        <label for="address_1">
                            Address
                            <span class="required">*</span>
                        </label>
                        <input
                            class="form-control"
                            type="text"
                            name="speaker[address][address_1]"
                            title="Address"
                            required
                            data-msg="Please enter an address"
                            id="address_1"
                            v-model="speaker.address.address_1"
                        />
                    </div>

                    <!-- Address 2 -->
                    <div class="form-group col-md">
                        <label for="speaker_address_2">Address 2</label>
                        <input
                            class="form-control"
                            type="text"
                            name="speaker[address][address_2]"
                            title="Address 2"
                            id="speaker_address_2"
                            v-model="speaker.address.address_2"
                        />
                    </div>
                </div>

                <div class="row">
                    <!-- City -->
                    <div class="form-group col-md">
                        <label for="speaker_city">
                            City
                            <span class="required">*</span>
                        </label>
                        <input
                            class="form-control"
                            type="text"
                            name="speaker[address][city]"
                            title="City"
                            required
                            id="speaker_city"
                            data-msg="Please enter a city"
                            v-model="speaker.address.city"
                        />
                    </div>

                    <!-- State/Province -->
                    <div class="form-group col-md">
                        <label for="speaker_state_province">
                            State/Province
                            <span class="required">*</span>
                        </label>
                        <input
                            class="form-control"
                            type="text"
                            name="speaker[address][state_province]"
                            id="speaker_state_province"
                            title="State/Province"
                            required
                            data-msg="Please enter a State/Province"
                            v-model="speaker.address.state_province"
                            maxlength="2"
                        />
                    </div>

                    <!-- Postal Code -->
                    <div class="form-group col-md">
                        <label for="speaker_postal_code">
                            Postal Code
                            <span class="required">*</span>
                        </label>
                        <input
                            class="form-control"
                            type="text"
                            name="speaker[address][postal_code]"
                            title="Postal Code"
                            required
                            data-msg="Please enter a postal code"
                            id="speaker_postal_code"
                            v-model="speaker.address.postal_code"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["speaker"]
    };
</script>
