export const dtSponsorOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        {
            data: "name"
        },
        {
            data: "sponsorship_level"
        },
        {
            data: "active",
            render: function(data, type, row) {
                return data  ? '<p class="text-success m-0"><strong>Active</strong></p>' : '<p class="text-danger m-0"><strong>Inactive</strong></p>';
            }
        },
        {
            data: "id",
            width: '30%',
            render: function(data, type, row) {
                return `<div class="sponsor-options" data-id="${row.id}">
                            <button class="btn btn-secondary-blue" data-js-id="sponsor-modify">Modify</button>
                            <button class="btn btn-outline-danger" data-js-id="sponsor-delete">Delete</button>
                        </div>`;
            }
        }
    ]
}

