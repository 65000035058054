<template>
	<form :data-js-id="offeringFormId" novalidate>
		<div class="form-group">
			<label for="oo_name">Name</label>
			<input
				:disabled="disableInput"
				class="form-control"
				type="text"
				name="oo_name"
				id="oo_name"
				title="Name"
				required
				data-msg="Please enter a title for the optional offering"
				v-model="offering.name"
			/>
		</div>
		<div class="form-group">
			<label for="oo_description">Description</label>
			<textarea
				:disabled="disableInput"
				class="form-control"
				name="oo_description"
				id="oo_description"
				title="Description"
				required
				data-msg="Please enter description of optional offering"
				v-model="offering.description"
			></textarea>
		</div>

		<div>
			<div class="form-group m-0">
				<div class="custom-control custom-switch mt-1 ml-0">
					<input
						:disabled="disableInput"
						type="checkbox"
						class="custom-control-input js-boolean-control"
						:id="`oo_input_platinum_members`"
						:name="`oo_input_platinum_members`"
						v-model="offering.platinum_members"
					/>
					<label class="custom-control-label" :for="`oo_input_platinum_members`">Platinum Members Only</label>
				</div>
			</div>
		</div>

        <div class="form-group">
			<div class="radio-legend d-flex justify-content-between align-items-center">
				<p class="label mr-3 my-0">
					Groups
					<span class="required">*</span>
				</p>
				<a
					href="#"
					data-toggle="tooltip"
					data-placement="right"
					data-boundary="window"
					title="User group that the offering will be available to"
				>
					<span class="fas fa-question-circle text-info cursor-pointer"></span>
				</a>
			</div>
			<fieldset>
				<legend class="label sr-only">Groups</legend>
				<div
					class="form-check form-check-inline form-check-radio"
					v-for="(group, index) in portalGroups"
					:key="index"
				>
					<label class="form-check-label" :for="`portal_group-${group.id}`">
						<input
							:disabled="disableInput"
							class="form-check-input"
							required
							type="radio"
							title="Please specify a group the promo will apply to"
							name="portal_group"
							:id="`portal_group-${group.id}`"
							:value="`${group.id}`"
							data-required-text="Please select a portal group"
							v-model.number="offering.portal_group_id"
						/>
						<span class="form-check-sign"></span>
						{{ group.name }}
					</label>
				</div>
			</fieldset>
		</div>

		

		<div class="mt-2" v-for="(input, index) in offering.inputs" :key="index" :class="{'mb-2 pb-1 border-bottom': index < offering.inputs.length - 1}">
			<div class="row align-items-start">
				<div class="form-group col m-0">
					<label class="required" :for="`oo_input_${index}_name`">Name of Input</label>
					<input
						:disabled="disableInput"
						class="form-control"
						type="text"
						:name="`oo_input_${index}_name`"
						:id="`oo_input_${index}_name`"
						title="Name of Input"
						required
						data-msg="Please enter name of input"
						v-model="offering.inputs[index].name"
					/>
				</div>
				<div class="form-group col m-0">
					<label class="required" :for="`oo_input_${index}_price_per_unit`">Price Per Unit</label>
					<input
						:disabled="disableInput"
						class="form-control"
						type="number"
						step="0.01"
						:name="`oo_input_${index}_price_per_unit`"
						:id="`oo_input_${index}_price_per_unit`"
						title="Price Per Unit"
						required
						data-msg="Please enter price per unit"
						v-model.number="offering.inputs[index].price_per_unit"
					/>
				</div>
				<div class="form-group col m-0">
					<label class="required" :for="`oo_input_${index}_maximum_available`">Maximum Available</label>
					<input
						:disabled="disableInput"
						class="form-control"
						type="text"
						:name="`oo_input_${index}_maximum_available`"
						:id="`oo_input_${index}_maximum_available`"
						title="Maximum Available"
						required
						v-mask="'#######'"
						data-msg="Please enter maximum available"
						v-model.number="offering.inputs[index].maximum_available"
					/>
				</div>
				<div class="form-group col m-0">
					<label :for="`oo_input_${index}_additional_input`">Additional Input Label</label>
					<input
						:disabled="disableInput"
						class="form-control"
						type="text"
						:name="`oo_input_${index}_additional_input`"
						:id="`oo_input_${index}_additional_input`"
						title="Name of Label"
						v-model="offering.inputs[index].additional_input"
					/>
				</div>
				<div class="col m-0">
					<button class="btn btn-tertiary form-control" type="button" @click.prevent="removeInput(index)" v-if="offering.inputs.length > 1">Remove</button>
					<div class="form-group m-0">
						<div class="custom-control custom-switch mt-1 ml-0">
							<input
								:disabled="disableInput"
								type="checkbox"
								class="custom-control-input js-boolean-control"
								:id="`oo_input_${index}_required`"
								:name="`oo_input_${index}_required`"
								v-model="offering.inputs[index].required"
							/>
							<label class="custom-control-label" :for="`oo_input_${index}_required`">Required?</label>
						</div>
					</div>
				</div>
			</div>
		</div>

		<button class="btn btn-primary mt-2" type="button" @click="addInput()" v-if="!disableInput">Add Input</button>
	</form>
</template>

<script>
	export default {
        props: ["newOffering", "portalGroups", "offeringFormId", "selectedOffering", "afterRegistrationStartDate"],
        data() {
            return {
                offering: this.newOffering
            }
        },
		methods: {
			addInput() {
				this.offering.inputs.push({
					name: "",
					price_per_unit: 0,
					required: false,
					maximum_available: 1
				});
            },
            removeInput(index) {
                if (this.offering.inputs.length > 1) {
                    this.offering.inputs.splice(index, 1)
                }
            }
		},
		computed: {
			disableInput() {
				return this.afterRegistrationStartDate && this.newOffering.id;
			}
		}
	};
</script>

<style>
	.form-check-radio input[type=radio]:checked~.form-check-sign:after {
		opacity: 1;
	}
</style>