var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"text-right my-2"},[_c('button',{staticClass:"btn btn-secondary-blue",on:{"click":function($event){$event.preventDefault();return _vm.exportTranscriptsAsPDF($event)}}},[_vm._v("Batch Transcripts PDF")]),_vm._v(" "),_c('button',{staticClass:"btn btn-primary",on:{"click":function($event){$event.preventDefault();return _vm.exportReport($event)}}},[_vm._v("Export Full Report")])]),_vm._v(" "),_c('vrtr-datatable',{attrs:{"table-id":"transcript-report","column-names":[
            "Badge ID",
            "First Name",
            "Last Name",
            "Session Title",
            "Attendance Met",
            "Evaluation"
        ],"ajax-route":_vm.ajaxRoute}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }