<template>
	<form class="modal-body password-criteria-form" id="speaker-user-id-password-form" novalidate>
		<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>

		<div class="row">
			<div class="col">
				<div class="form-group">
					<label for="password">Password</label>
					<input
						class="form-control"
						type="password"
						name="password"
						id="password"
						required
						data-msg="Please enter password"
						v-model="formData.password"
					/>
				</div>

				<div class="form-group">
					<label for="password_confirmation">Confirm Password</label>
					<input
						class="form-control"
						type="password"
						name="password_confirmation"
						id="password-confirm"
						required
						data-msg="Please confirm password"
						v-model="formData.password_confirmation"
					/>
				</div>

				<div class="m-n1">
					<button class="btn btn-outline-primary m-1" @click.prevent="closeModal()">Cancel</button>
					<button
						class="btn btn-primary m-1"
						type="submit"
						data-js-id="user-id-password-modal-change-password-button"
						disabled
						@click.prevent="saveForm()"
					>Change Password</button>
				</div>
			</div>

			<div class="col">
				<div class="card bg-light prs-password-requirements">
					<div class="card-header">
						<p class="card-title font-weight-bold m-0">Passwords must contain:</p>
					</div>
					<div class="card-body">
						<ul class="password-criteria-list m-0 text-14">
							<li class="un-met uppercase">One uppercase letter</li>
							<li class="un-met lowercase">One lowercase letter</li>
							<li class="un-met number">One number</li>
							<li class="un-met special-char">One special character: !@#$%^&*()+</li>
							<li class="un-met character-length">Be at least 8 characters</li>
							<li class="un-met passwords-match">Passwords must match</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>

<script>
	import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
		props: ["user", "closeModal"],
		data() {
			return {
				currentUser: this.user,
				showErrorDiv: false,
				errorMessage: "",
				formProcessing: false,
                saveChangesButton: null,
                formData: {}
			};
		},
		methods: {
			saveForm() {
                const vm = this;

                this.formData.id = this.currentUser.id;

				this.formProcessing = true;
				this.saveChangesButton.innerText = "Processing...";

				this.showErrorDiv = false;
				this.errorMessage = "";

				axios
					.post("/portal/dashboard/speaker/update-password", this.formData)
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						logApplicationError(error);
						console.error(error);

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								vm.showErrorDiv = true;
								vm.errorMessage = errorsUl.outerHTML;
							} else {
								vm.showErrorDiv = true;
								vm.errorMessage =
									'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
							}
						}

						vm.formProcessing = false;
						vm.saveChangesButton.innerText = "Change Password";

						console.error(error);
					});
			}
		},
		mounted() {
			this.saveChangesButton = document.querySelector(
				'[data-js-id="user-id-password-modal-change-password-button"]'
            );

            require("../../../../../../core/modules/portal/functions/password-criteria-check");
        }
	};
</script>