<script>
	import { dataTableMixin } from "../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
	import { dtSubmissionsOfferingOptions } from "./dtSubmissionsOfferingOptions";

	export default {
		mixins: [dataTableMixin],
		props: ["viewingOffering"],
		data() {
			return {
				moduleDatatableOptions: dtSubmissionsOfferingOptions
			};
		},
		mounted() {
			// Add event listeners for buttons
			let dataTable = this.$el;

			const vm = this;

			dataTable.addEventListener("click", evt => {
				let elementClicked = evt.target;
				let id = elementClicked.parentElement.dataset.id;

				switch (elementClicked.dataset.jsId) {
					case "offering-view":
						this.viewingOffering.id = id;

						// Show modal
                        vm.$parent.openOfferingViewModal();

						axios
							.get(`/vpanel/modules/optional-offerings/submissions/show/${id}`)
							.then(res => {
								try {
									let offering = res.data.offering;

									for (const prop in offering) {
										if (offering.hasOwnProperty(prop)) {
											const value = offering[prop];

											vm.$parent.viewingOffering[prop] = value;
										}
									}

									vm.$parent.showViewContent();
								} catch (error) {}
							})
							.catch(error => {
								openApplicationErrorModal(error);
								console.error(error);
							});

						break;
				}
			});
		}
	};
</script>
