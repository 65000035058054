<template>
    <div class="card my-2">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">Presentation Information</p>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="form-group col-lg">
                    <label for="presentation_title">
                        Proposed Presentation Title
                        <span class="required">*</span>
                    </label>
                    <textarea
                        id="presentation_title"
                        name="speaker[course][title]"
                        class="form-control"
                        required
                        rows="2"
                        maxlength="80"
                        title="Proposed Presentation Title"
                        data-msg="Please enter the proposed course title."
                        v-model="speaker.course.title"
                    ></textarea>
                </div>
                <div class="form-group col-lg">
                    <label for="presentation_desc">
                        Proposed Presentation Description
                        <span class="required">*</span>
                    </label>
                    <textarea
                        id="presentation_desc"
                        name="speaker[course][description]"
                        class="form-control"
                        required
                        rows="2"
                        maxlength="5000"
                        title="Proposed Presentation Description"
                        data-msg="Please enter the proposed presentation description."
                        v-model="speaker.course.description"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg-6 col-xl">
                    <label for="learning_obj_1">
                        Proposed Learning Objective (1)
                        <span class="required">*</span>
                    </label>
                    <textarea
                        id="learning_obj_1"
                        name="speaker[course][objective_1]"
                        required
                        rows="2"
                        class="form-control"
                        title="Proposed Learning Objectives"
                        data-msg="Please enter the proposed presentation learning objective (1)."
                        maxlength="5000"
                        v-model="speaker.course.objective_1"
                    ></textarea>
                </div>
                <div class="form-group col-lg-6 col-xl">
                    <label for="learning_obj_2">
                        Proposed Learning Objective (2)
                        <span class="required">*</span>
                    </label>
                    <textarea
                        id="learning_obj_2"
                        name="speaker[course][objective_2]"
                        required
                        rows="2"
                        class="form-control"
                        title="Proposed Learning Objectives"
                        data-msg="Please enter the proposed presentation learning objective (2)."
                        maxlength="5000"
                        v-model="speaker.course.objective_2"
                    ></textarea>
                </div>
                <div class="form-group col-lg-6 col-xl">
                    <label for="learning_obj_3">
                        Proposed Learning Objective (3)
                        <span class="required">*</span>
                    </label>
                    <textarea
                        id="learning_obj_3"
                        name="speaker[course][objective_3]"
                        required
                        rows="2"
                        class="form-control"
                        title="Proposed Learning Objectives"
                        data-msg="Please enter the proposed presentation learning objective (3)."
                        maxlength="5000"
                        v-model="speaker.course.objective_3"
                    ></textarea>
                </div>
                <div class="form-group col-lg-6 col-xl">
                    <label for="learning_obj_4">
                        Proposed Learning Objective (4)
                        <span class="required"></span>
                    </label>
                    <textarea
                        id="learning_obj_4"
                        name="speaker[course][objective_4]"
                        rows="2"
                        class="form-control"
                        title="Proposed Learning Objectives"
                        data-msg="Please enter the proposed presentation learning objective (4)."
                        maxlength="5000"
                        v-model="speaker.course.objective_4"
                    ></textarea>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg">
                    <p>Audience Level</p>
                    <div class="custom-inline-radio-buttons">
                        <fieldset>
                            <legend class="sr-only">Audience Level</legend>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                                v-for="(level, index) in audience_levels"
                                :key="`audience_level_${index}`"
                            >
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="speaker[course][audience_level]"
                                    required
                                    data-msg="Please select audience level"
                                    title="audience_level"
                                    :value="`${titleCase(level)}`"
                                    :id="`audience_level_${level}`"
                                    v-model="speaker.course.audience_level"
                                />
                                <label class="custom-control-label" :for="`audience_level_${level}`">{{ titleCase(level) }}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="form-group col-lg" style="display: none;">
                    <p>Presentation Preference</p>
                    <div class="custom-inline-radio-buttons">
                        <fieldset>
                            <legend class="sr-only">Presentation Preference</legend>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                                v-for="(preference, index) in presentation_preference"
                                :key="`presentation_preference_${index}`"
                            >
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="speaker[course][presentation_preference]"
                                    data-msg="Please select presentation preference"
                                    title="Please select presentation preference"
                                    :value="`${preference}`"
                                    :id="`presentation_preference_${preference}`"
                                    v-model="speaker.course.presentation_preference"
                                />
                                <label class="custom-control-label" :for="`presentation_preference_${preference}`">{{ titleCase(preference) }}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="form-group col-lg">
                    <label for="cboCategory">
                        Category
                        <span class="required">*</span>
                    </label>
                    <select
                        id="cboCategory"
                        name="speaker[course][category]"
                        title="Category"
                        required
                        class="form-control"
                        data-msg="Please select the presentation's category."
                        v-model="speaker.course.category"
                    >
                        <option value>-- Select --</option>
                        <option
                            v-for="(category, index) in categories"
                            :key="`category_${index}`"
                            :value="category.id"
                        >{{ category.name }}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-lg">
                    <p>Is the presentation a panel?</p>
                    <div class="custom-inline-radio-buttons">
                        <fieldset>
                            <legend class="sr-only">Is the presentation a panel?</legend>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                                v-for="(answer, index) in ['Yes', 'No']"
                                :key="`is_presentation_a_panel_${index}`"
                            >
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="speaker[course][panel]"
                                    required
                                    data-msg="Please select if the presentation a panel"
                                    title="is_presentation_panel"
                                    :value="`${answer}`"
                                    v-model="speaker.course.panel"
                                    :id="`is_presentation_a_panel_${answer}`"
                                />
                                <label class="custom-control-label" :for="`is_presentation_a_panel_${answer}`">{{ answer }}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div class="form-group col-lg">
                    <p>Are there any additional speakers?</p>
                    <div class="custom-inline-radio-buttons">
                        <fieldset>
                            <legend class="sr-only">Are there any additional speakers?</legend>
                            <div
                                class="custom-control custom-radio custom-control-inline"
                                v-for="(answer, index) in ['Yes', 'No']"
                                :key="`additional_speakers_${index}`"
                            >
                                <input
                                    class="custom-control-input"
                                    type="radio"
                                    name="additional_speakers"
                                    required
                                    data-msg="Please select if there are any additional"
                                    title="additional_speakers"
                                    :value="`${answer}`"
                                    v-model="if_additional_speakers"
                                    :id="`additional_speakers_${answer}`"
                                    @change="changeAdditionalSpeakers(answer)"
                                />
                                <label class="custom-control-label" :for="`additional_speakers_${answer}`">{{ answer }}</label>
                            </div>
                        </fieldset>
                    </div>
                </div>
            </div>

            <!-- Additional Speakers -->
            <template v-if="if_additional_speakers == 'Yes'">
                <srp-additional-speaker-row
                    v-for="(additionalSpeaker, index) in additionalSpeakers"
                    :key="`additional_speaker_${index}`"
                    :speaker="additionalSpeaker"
                    :index="index"
                    @remove-speaker="removeSpeaker($event)"
                ></srp-additional-speaker-row>
                <div class="d-flex justify-content-end mt-2" v-if="additionalSpeakers.length">
                    <button class="btn btn-sm btn-success" @click="addAnotherSpeaker()">
                        <span class="fa fa-plus"></span>&nbsp;Add Another Speaker
                    </button>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: [
            'additionalSpeakers',
            'speaker',
        ],
        data() {
            return {
                audience_levels: ["introductory", "intermediate", "advanced"],
                presentation_preference: ["In-person"],
                categories: [],
                if_additional_speakers: "No",
            }
        },
        methods: {
            changeAdditionalSpeakers(answer) {
                if (answer == "Yes") {
                    if (!this.additionalSpeakers.length) {
                        this.additionalSpeakers.push({
                            user: {},
                            speaker_info: {},
                            address: {}
                        });
                    }
                }
            },
            addAnotherSpeaker() {
                this.additionalSpeakers.push({
                    user: {},
                    speaker_info: {},
                    address: {}
                });
            },
            removeSpeaker(event) {
                let index = event.index;

                this.additionalSpeakers.splice(index, 1);

                if (this.additionalSpeakers.length == 0) {
                    this.if_additional_speakers = "No";
                }
            },
            titleCase(string) {
                return string[0].toUpperCase() + string.substr(1).toLowerCase();
            },
            getSpeakerCategories() {
                let vm = this;

                axios
                    .get(
                        "/heartland/portal/get-speaker-course-categories"
                    )
                    .then(res => {
                        const data = res.data;
                        this.categories = res.data.data;
                    })
                    .catch(error => {
                        console.error(error);
                    });
            },
        },
        mounted() {
            this.getSpeakerCategories();
        }
    };
</script>
