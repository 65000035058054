<template>
	<div class="my-2">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1"><em>My Profile</em></h4>
		<!-- Contact Info -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Contact Information"
				@click.native="toggleContactInfo()"
				:is-toggled="showContactInfo"
			></portal-dashboard-toggle-bar>
            <div class="card-body d-flex justify-content-between align-items-start" v-if="showContactInfo">
                <div class="my-n2">
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Name on record</small>
                        {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm" v-if="user.title">
                        <small class="text-uppercase text-primary-green font-weight-bold">Title</small>
                        {{ user.title }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Address</small>
                        {{ address }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Email</small>
                        {{ user.email }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Phone</small>
                        {{ user.phone }}
                    </p>
                </div>

                <button class="btn btn-primary" v-if="isAfterBadgeCutoff == 0" @click="openEditContactModal()">Edit</button>
            </div>

			<speaker-dashboard-contact-info-modal
				:modal-id="contactInfoModalId"
				modal-title="Edit Contact Information"
				header-icon="user"
				modal-size="lg"
			>
				<speaker-dashboard-contact-form
					:user="user"
					:close-modal="closeEditContactModal"
				></speaker-dashboard-contact-form>
			</speaker-dashboard-contact-info-modal>
		</div>

		<!-- User Id And Password -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="User ID And Password"
				@click.native="toggleUserIdAndPasswordInfo()"
				:is-toggled="showUserIdAndPasswordInfo"
			></portal-dashboard-toggle-bar>
			<div class="card-body d-flex justify-content-between align-items-start" v-if="showUserIdAndPasswordInfo">
                <div class="my-n2">
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Username</small>
                        {{ user.username }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Password</small>
                        ⚹⚹⚹⚹⚹⚹⚹⚹
                    </p>
                </div>

				<button class="btn btn-primary" @click="openEditPasswordModal()">Reset Password</button>
			</div>

			<speaker-dashboard-user-id-password-modal
				:modal-id="userIdAndPasswordModalId"
				modal-title="Change Password"
				header-icon="lock"
				modal-size="lg"
			>
				<speaker-dashboard-user-id-password-form :user="user" :close-modal="closeEditPasswordModal"></speaker-dashboard-user-id-password-form>
			</speaker-dashboard-user-id-password-modal>
		</div>

		<!-- Speaker Profile Information -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Speaker Profile Information"
				@click.native="toggleSpeakerProfileInfo()"
				:is-toggled="showSpeakerProfileInfo"
			></portal-dashboard-toggle-bar>
			<div class="card-body d-flex justify-content-between align-items-start" v-if="showSpeakerProfileInfo">
				<div class="my-n2">
					<div class="my-2 d-flex flex-column lh-sm">
						<p class="text-uppercase text-primary-green m-0"><small class="font-weight-bold">Speaker Profile</small></p>
						<div v-if="user.speaker && user.speaker.biography" v-html="user.speaker.biography"></div>
						<div v-else>Please add a new bio</div>
					</div>
				</div>

				<button class="btn btn-primary" @click="openEditBioModal()">Edit</button>
			</div>

			<speaker-dashboard-bio-modal
				:modal-id="speakerBioModalId"
				modal-title="Change Speaker Bio"
				header-icon="lock"
				modal-size="lg"
			>
				<speaker-dashboard-bio-form :user="user" :close-modal="closeEditBioModal"></speaker-dashboard-bio-form>
			</speaker-dashboard-bio-modal>
		</div>

		<!-- Profile Image -->
		<div class="card my-2">
			<portal-dashboard-toggle-bar
				toggle-bar-text="Profile Image"
				@click.native="openProfileImageModal()"
			></portal-dashboard-toggle-bar>

			<speaker-dashboard-profile-image-modal
				:modal-id="profileImageModalId"
				modal-title="Profile Image"
				header-icon="image"
				modal-size="md"
			>
			<speaker-dashboard-profile-image-form   :user="user" :close-modal="closeProfileImageModal"></speaker-dashboard-profile-image-form>
			</speaker-dashboard-profile-image-modal>
		</div>

	</div>
</template>

<script>
	export default {
		props: [
			"user",
			"isAfterBadgeCutoff",
		],
		data() {
			return {
				contactInfoModalId: "speaker-dashboard-edit-info-modal",
				userIdAndPasswordModalId: "speaker-dashboard-user-id-password-modal",
				speakerBioModalId: "speaker-dashboard-bio-password-modal",
				profileImageModalId: "speaker-dashboard-update-image-modal",
				showContactInfo: false,
				showUserIdAndPasswordInfo: false,
				showSpeakerProfileInfo: false,
			};
		},
		computed: {
			address() {
				return `${this.user.address.address_1 || ""} ${this.user.address.address_2 ||
					""} ${this.user.address.city || ""}, ${this.user.address.state_province ||
					""} ${this.user.address.postal_code || ""}`;
			}
		},
		methods: {
			toggleContactInfo() {
				this.showContactInfo = !this.showContactInfo;
			},
			toggleUserIdAndPasswordInfo() {
				this.showUserIdAndPasswordInfo = !this.showUserIdAndPasswordInfo;
			},
			toggleSpeakerProfileInfo() {
				this.showSpeakerProfileInfo = !this.showSpeakerProfileInfo;
			},
			openEditContactModal() {
				$(`[data-js-id="${this.contactInfoModalId}"]`).modal("show");
			},
			closeEditContactModal() {
				$(`[data-js-id="${this.contactInfoModalId}"]`).modal("hide");
			},
			openEditPasswordModal() {
				$(`[data-js-id="${this.userIdAndPasswordModalId}"]`).modal("show");
			},
			openEditBioModal() {
				$(`[data-js-id="${this.speakerBioModalId}"]`).modal("show");
			},
			closeEditBioModal() {
				$(`[data-js-id="${this.speakerBioModalId}"]`).modal("hide");
			},
			closeEditPasswordModal() {
				$(`[data-js-id="${this.userIdAndPasswordModalId}"]`).modal("hide");
			},
			openProfileImageModal() {
				$(`[data-js-id="${this.profileImageModalId}"]`).modal("show");
			},
			closeProfileImageModal() {
				$(`[data-js-id="${this.profileImageModalId}"]`).modal("hide");
			}
		}
	};
</script>