<template>
    <div id="sponsor-list" v-if="tierList">
        <div class="sl-level" v-for="(tier, tierIndex) in tierList" :key="tierIndex">
            <template v-for="level in tier">
                <p class="h4 tk-benton-sans-condensed font-weight-bold text-primary-green text-center border-top-3 border-theme-grey py-1 mb-1">{{ level.level }} Sponsors</p>
    
                <div class="sl-vendors">
                    <div :class="`sl-vendor sponsor-level-${tierIndex}`" v-for="(sponsor, index) in level.sponsors" :key="index">
                        <a v-if="sponsor.external_website" :href="sponsor.external_website" :title="'Link to ' + sponsor.name + '\'s Website - ' + level.level + ' Sponsorship'" target="_blank">
                            <div class="sl-img" :style="'background-image: url(' + sponsor.logo_image_path + ');'"></div>
                        </a>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    props: ["tierList"],
};
</script>

<style lang="scss" scoped>
    .sl-vendor{
        align-items: center;

        &.sponsor-level-1 a { width: 100%; }
        &.sponsor-level-2 a { width: 85%; }
        &.sponsor-level-3 a { width: 70%; }
        &.sponsor-level-4 a { width: 55%; }
        &.sponsor-level-5 a { width: 40%; }
    }
</style>