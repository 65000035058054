<template>
	<div class="modal-body">
		<form id="admin-contact-form" novalidate>
			<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>
			<input type="hidden" name="id" v-model="currentUser.id" />

			<div class="row">
				<div class="form-group col-md">
					<label for="admin_first_name">First Name</label>
					<input
						class="form-control"
						type="text"
						name="admin_first_name"
						id="admin_first_name"
						title="First Name"
						required
						data-msg="Please supply a first name"
						v-model="currentUser.first_name"
					/>
				</div>
				<div class="form-group col-md">
					<label for="admin_last_name">Last Name</label>
					<input
						class="form-control"
						type="text"
						name="admin_last_name"
						id="admin_last_name"
						title="Last Name"
						required
						data-msg="Please supply a last name"
						v-model="currentUser.last_name"
					/>
				</div>
				<div class="form-group col-md">
					<label for="title">Title/Position</label>
					<input
						class="form-control"
						type="text"
						name="title"
						id="title"
						title="Title/Position"
						v-model="currentUser.title"
					/>
				</div>
			</div>

			<div class="row">
				<div class="form-group col-md">
					<label for="phone">Phone</label>
					<input
						class="form-control"
						type="text"
						name="phone"
						id="phone"
						title="Phone"
						required
						data-msg="Please provide a phone number"
						v-mask="'(###) ###-####'"
						v-model="currentUser.phone"
					/>
				</div>
				<div class="form-group col-md">
					<label for="date_of_birth">Date Of Birth</label>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="date_of_birth"
						id="date_of_birth"
						title="Date Of Birth"
						required
						v-model="currentUser.date_of_birth"
						v-mask="'##/##/####'"
						data-msg-required="Please enter a date"
						data-msg-dob="Please enter valid date"
						data-rule-dob="true"
						placeholder="mm/dd/yyyy"
					/>
				</div>
			</div>

			<div class="row">
				<div class="form-group col-md">
					<label for="name">Company Name</label>
					<input
						class="form-control"
						type="text"
						name="name"
						id="name"
						title="name"
						required
						data-msg="Please enter name"
						v-model="currentCompany.name"
					/>
				</div>
				<div class="form-group col-md">
					<label for="website">Website</label>
					<input
						class="form-control"
						type="text"
						name="website"
						id="website"
						title="Address"
						required
						data-msg="Please enter address"
						v-model="currentCompany.website"
					/>
				</div>
			</div>

			<div class="row">
				<div class="form-group col-md">
					<label for="address_1">Address</label>
					<input
						class="form-control"
						type="text"
						name="address_1"
						id="address_1"
						title="Address"
						required
						data-msg="Please enter address"
						v-model="currentCompany.address_1"
					/>
				</div>
				<div class="form-group col-md">
					<label for="address_2">Address 2</label>
					<input
						class="form-control"
						type="text"
						name="address_2"
						id="address_2"
						title="Address 2"
						v-model="currentCompany.address_2"
					/>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-md">
					<label for="city">City</label>
					<input
						class="form-control"
						type="text"
						name="city"
						id="city"
						title="City"
						required
						data-msg="Please provide a city"
						v-model="currentCompany.city"
					/>
				</div>
				<div class="form-group col-md">
					<label for="state_province">State/Province</label>
					<input
						class="form-control"
						type="text"
						name="state_province"
						id="state_province"
						title="State/Province"
						required
						data-msg="Please provide a state/province"
						v-model="currentCompany.state_province"
					/>
				</div>
				<div class="form-group col-md">
					<label for="postal_code">Postal Code</label>
					<input
						class="form-control"
						type="text"
						name="postal_code"
						id="postal_code"
						title="Postal"
						required
						data-msg="Please provide a postal code"
						v-model="currentCompany.postal_code"
					/>
				</div>
			</div>

			<div class="d-flex justify-content-end m-n1">
				<button class="btn btn-outline-primary m-1" @click.prevent="closeModal()">Cancel</button>
				<button
					class="btn btn-primary m-1"
					data-js-id="contact-modal-save-change-button"
					:disabled="formProcessing"
					@click.prevent="saveForm()"
				>Save My Changes</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
		props: ["user", "company", "closeModal"],
		data() {
			return {
				currentUser: this.user,
				showErrorDiv: false,
				errorMessage: "",
				formProcessing: false,
				saveChangesButton: null,
				currentCompany: { ...this.company }
			};
		},
		methods: {
			saveForm() {
				const formData = {...this.currentUser, company: this.currentCompany};
				const vm = this;

				this.formProcessing = true;
				this.saveChangesButton.innerText = "Processing...";

				this.showErrorDiv = false;
				this.errorMessage = "";

				axios
					.post("/portal/dashboard/admin/update", formData)
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						logApplicationError(error);
						console.error(error);

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								vm.showErrorDiv = true;
								vm.errorMessage = errorsUl.outerHTML;
							} else {
								vm.showErrorDiv = true;
								vm.errorMessage =
									'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
							}
						}

						vm.formProcessing = false;
						vm.saveChangesButton.innerText = "Save My Changes";

						console.error(error);
					});
			}
		},
		mounted() {
			this.saveChangesButton = document.querySelector(
				'[data-js-id="contact-modal-save-change-button"]'
			);
		}
	};
</script>