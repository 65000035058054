<template>
	<form method="POST" action="/vpanel/test-converge-submit" data-js-id="payment-form">
		<button class="btn btn-primary" @click.prevent="godMode()">God Mode</button>
		<input type="hidden" name="_token" :value="csrf" />
		<!-- Card Type -->
		<div class="form-group">
			<p class="m-0 required">
				Card Type
				<span class="required">*</span>
			</p>
			<div class="custom-inline-radio-buttons">
				<fieldset>
					<legend class="sr-only">Card Type</legend>
					<div class="custom-control custom-radio custom-control-inline">
						<input
							class="custom-control-input"
							type="radio"
							name="type"
							required
							data-msg="Please select a card type"
							title="Card Type"
							value="Visa"
							id="type_visa"
							v-model="request.payment_method.type"
						/>
						<label class="custom-control-label" for="type_visa">Visa</label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input
							class="custom-control-input"
							type="radio"
							name="type"
							required
							data-msg="Please select a card type"
							title="Card Type"
							value="Mastercard"
							id="type_mastercard"
							v-model="request.payment_method.type"
						/>
						<label class="custom-control-label" for="type_mastercard">Mastercard</label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input
							class="custom-control-input"
							type="radio"
							name="type"
							required
							data-msg="Please select a card type"
							title="Card Type"
							value="Discover"
							id="type_discover"
							v-model="request.payment_method.type"
						/>
						<label class="custom-control-label" for="type_discover">Discover</label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input
							class="custom-control-input"
							type="radio"
							name="type"
							required
							data-msg="Please select a card type"
							title="Card Type"
							value="American Express"
							id="type_amex"
							v-model="request.payment_method.type"
						/>
						<label class="custom-control-label" for="type_amex">American Express</label>
					</div>
				</fieldset>
			</div>
		</div>

		<div class="row">
			<!-- Credit Card Number -->
			<div class="form-group col-md">
				<label for="credit_card">
					Credit Card Number
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="credit_card"
					title="Credit Card Number"
					id="credit_card"
					v-model="request.payment_method.credit_number"
					v-mask="'#### #### #### ####'"
					required
					data-msg="Please enter a Credit card Number"
				/>
			</div>

			<!-- CVV Code -->
			<div class="form-group col-md">
				<label for="cvv_code">
					CVV Code
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="cvv_code"
					title="CVV Code"
					id="cvv_code"
					v-model="request.payment_method.cvv_code"
					v-mask="'####'"
					required
					data-msg="Please enter a CVV Code"
				/>
			</div>

			<!-- Expiration Date -->
			<div class="form-group col-md">
				<label for="expiration_date">
					Expiration Date
					<span class="required">*</span>
				</label>
				<input
					class="form-control js-flatpickr"
					type="text"
					id="expiration_date"
					name="expiration_date"
					title="Expiration Date"
					v-model="request.payment_method.expiration_date"
					required
					v-mask="'##/##'"
					data-msg-required="Please enter a date"
					data-msg-expdate="Please enter valid date"
					data-rule-expdate="true"
					placeholder="mm/yy"
				/>
			</div>
		</div>

		<div class="row">
			<!-- First Name -->
			<div class="form-group col-md">
				<label for="first_name">
					First Name
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="first_name"
					title="first name"
					id="first_name"
					v-model="request.payment_method.first_name"
					required
					data-msg="Please enter a first name"
				/>
			</div>

			<!-- Last Name -->
			<div class="form-group col-md">
				<label for="last_name">
					Last Name
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="last_name"
					title="ast name"
					id="last_name"
					v-model="request.payment_method.last_name"
					required
					data-msg="Please enter a last name"
				/>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-md">
				<label for="address_1">
					Address
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="address_1"
					title="Address"
					id="address_1"
					v-model="request.payment_method.address.address_1"
					required
					data-msg="Please enter a Address"
				/>
			</div>
			<div class="form-group col-md">
				<label for="address_2">Address 2</label>
				<input
					class="form-control"
					type="text"
					name="address_2"
					title="Address"
					id="address_2"
					v-model="request.payment_method.address.address_2"
				/>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-md">
				<label for="city">
					City
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="city"
					title="City"
					id="city"
					v-model="request.payment_method.address.city"
					required
					data-msg="Please enter a City"
				/>
			</div>
			<div class="form-group col-md">
				<label for="state_province">
					State/Province
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="state_province"
					id="state_province"
					title="State/Province"
					v-model="request.payment_method.address.state_province"
					required
					data-msg="Please enter a State/Province"
				/>
			</div>

			<div class="form-group col-md">
				<label for="postal_code">
					Postal Code
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="postal_code"
					id="postal_code"
					title="Postal Code"
					v-model="request.payment_method.address.postal_code"
					required
					data-msg="Please enter a postal code"
				/>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-md-2">
				<label for="amount">
					Amount
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="number"
					step="0.01"
					name="total"
					id="amount"
					title="Amount"
					v-model="request.total"
					required
					data-msg="Please enter an amount"
				/>
			</div>
		</div>

		<button type="submit">Submit Test</button>
	</form>
</template>

<script>
	export default {
		props: ["csrf"],
		data() {
			return {
				request: {
					payment_method: {
						address: {}
					}
				}
			};
		},
		methods: {
			godMode() {
				this.request = {
					total: "1.00",
					payment_method: {
                        type: 'Visa',
                        credit_number: "4000 0000 0000 0002",
                        cvv_code: 123,
                        expiration_date: '02/2030',
                        first_name: 'Test',
                        last_name: 'Testerson',
						address: {
                            address_1: '123 Fake St',
                            address_2: 'PO Box 456',
                            city: 'Testington',
                            state_province: 'IA',
                            postal_code: 52402
                        }
					}
				};
			}
		}
	};
</script>