<template>
    <div>
        <div class="vp-status-bar-list">
            <p>Created: <span>{{ selectedOfferingCreatedDate }}</span></p>
            <p>Group: <span>{{ selectedOffering.portal_group_name }}</span></p>
            <p>Status: <span :class="{'text-success': selectedOffering.active == 1, 'text-danger': selectedOffering.active == 0}">{{ selectedOffering.active = 1 ? 'Active' : 'Inactive' }}</span></p>
        </div>

        <h5 class="m-0">{{ selectedOffering.name }}</h5>
        <p>{{ selectedOffering.description }}</p>

        <hr>
        <h5>Input<template v-if="selectedOffering.inputs.length > 1">s</template></h5>
        <div class="row" v-for="(input, index) in selectedOffering.inputs" :key="index">
            <div class="form-group col-md-9">
                <div class="d-flex justify-content-between align-items-center">
                    <label :for="selectedOffering.id + '_' + index" class="form-label" v-html="inputLabelHtml(input.name, input.required)"></label>
                    <p class="lh-xs m-0 font-weight-bold"><small>Remaining: <span class="text-secondary">{{ input.number_left }}</span> / {{ input.maximum_available }}</small></p>
                </div>
                <input
                    class="form-control"
                    readonly
                    type="number"
                    :name="selectedOffering.id + '_' + index"
                    :id="selectedOffering.id + '_' + index"
                    title="Name of Input"
                />
            </div>
            <p class="col-md py-1 m-0 lh-md"><span class="fas fa-at mr-2"></span>${{ input.price_per_unit }} / Unit</p>

        </div>
    </div>
</template>

<script>
export default {
    props: [
        'selectedOffering'
    ],
    computed: {
        selectedOfferingCreatedDate() {
            return moment(this.selectedOffering.created_at, 'YYYY-MM-DD HH:mm:ss').format('M/D/YYYY h:mm A');
        }
    },
    methods: {
        inputLabelHtml(name, required) {
            return name + (required == 0 ? '<span class="required">*</span>' : '');
        }
    }
}
</script>
