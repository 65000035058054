<template>
	<div class="mt-6">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1">
			<em>Optional Offerings</em>
		</h4>

        <div class="card my-2" v-for="(offering, index) in optionalOfferings" :key="index">
            <div class="card-header bg-primary-green text-white">
                <p class="card-title d-flex justify-content-between align-items-center m-0">
                    {{ offering.name }}
                </p>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-start">
                    <p class="m-0">{{ offering.description }}</p>
                    <button class="btn btn-primary" type="button" @click.prevent="openOfferingModal(offering)">View</button>
                </div>
            </div>
        </div>

		<portal-dashboard-optional-offering-modal
			:modal-id="optionalOfferingModal"
			modal-title="Optional Offering"
			header-icon="stream"
			modal-size="xl"
		>
            <div class="alert alert-danger" v-if="formProcessError">
				<p class="m-0" v-if="fatalProcessError">
					<strong>There was an error processing your </strong>
					<br />Please try again later. If you need assistance, call
					<a
						:href="`tel:+877-484-6901`"
					>877-484-6901</a>
				</p>
				<p class="m-0" v-if="formProcessErrorMessage">{{ formProcessErrorMessage }}</p>
			</div>

            <portal-dashboard-optional-offering-form
                :offering-form-id="offeringFormId"
                :active-optional-offering="activeOptionalOffering"
                :selected-inputs="optional_offering.inputs"
                :offering="optional_offering"
                :subtotal="subtotal"
                :update-subtotal="updateSubtotal"
                :show-subtotal="hasPaymentOptions"
                v-if="showOptionalForm"
            ></portal-dashboard-optional-offering-form>

            <portal-dashboard-optional-offerings-payment-form
                v-if="showPaymentForm"
                :optional-offering="optional_offering"
                :subtotal="subtotal"
                :go-back="goBack"
                promo-code-type="attendee"
            >
            </portal-dashboard-optional-offerings-payment-form>
            <div class="modal-footer justify-content-end" v-if="showOptionalForm">
                <button class="btn btn-primary" @click="goToPayment()" v-if="hasPaymentOptions" :disabled="!subtotal">Continue to payment</button>
                <button class="btn btn-primary" @click="submitForm()" v-if="!hasPaymentOptions" :disabled="!hasSelectedOptions">Submit form</button>
            </div>

        </portal-dashboard-optional-offering-modal>
	</div>
</template>

<script>
    import { bindOptionalOfferingInputModelObject, updateOptionalOfferingSubtotal } from '../../portal-functions.js'

	export default {
        props: ["optionalOfferings", "user"],
        data() {
            return {
                optionalOfferingModal: 'optional-offering-modal',
                offeringFormId: 'offering-form',
                activeOptionalOffering: {
                    inputs: {}
                },
                optional_offering: {
                    inputs: [],
                },
                showPaymentForm: false,
                showOptionalForm: true,
                subtotal: 0,
                formProcessError: false,
                formProcessErrorMessage: ''
            }
        },
        computed: {
            offeringModal() {
                return document.querySelector(`[data-js-id="${this.optionalOfferingModal}"]`);
            },
            offeringForm() {
                return document.querySelector(`[data-js-id="${this.offeringFormId}"]`);
            },
            hasPaymentOptions() {
                return !!this.optional_offering?.inputs?.some(input => parseInt(input.quantity) && parseFloat(input.price_per_unit))
            },
            hasSelectedOptions() {
                return !!this.optional_offering?.inputs?.some(input => parseInt(input.quantity))
            }
        },
        methods: {
            openOfferingModal(offering) {
                $(this.offeringModal).modal('show');

                this.activeOptionalOffering = offering;

                bindOptionalOfferingInputModelObject(offering, this.optional_offering)
            },
            goToPayment() {
                if ($(this.offeringForm).valid()) {
                    this.showPaymentForm = true;
                    this.showOptionalForm = false;
                }
            },
            goBack() {
                this.showPaymentForm = false;
                this.showOptionalForm = true;
            },
            updateSubtotal() {
                updateOptionalOfferingSubtotal(this)
            },
            submitForm() {
                const vm = this;
                let thisEvent = event;

                let data = {
                    optional_offering: this.optional_offering,
                    promoCodeType: 'admin',
                    user: this.user
                };

                // Make request to process form
                if ( $(this.offeringForm).valid() ) {
                    thisEvent.target.disabled = true;
                    thisEvent.target.innerText = 'Processing...';

                    axios
						.post("/heartland/portal/submit-optional-offering", data)
						.then(res => {
                            window.location.reload();
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == 422) {
									const data = error.response.data;

									for (const error in data.errors) {
										let prop = `${error}`;

										$(vm.offeringForm)
											.validate()
											.showErrors({
												[prop]: `${data.errors[error]}`
											});
									}
								} else if (error.response.status == 400) {
									vm.formProcessError = true;
									vm.formProcessErrorMessage =
										error.response.data.message;
								} else {
									vm.formProcessError = true;
									vm.fatalProcessError = true;
								}
                            }

                            thisEvent.target.disabled = false;
                            thisEvent.target.innerText = 'Submit form';

							$("html, body").animate({ scrollTop: 0 }, "fast");
						});
                }
            },
        },
        mounted() {
            $(this.offeringModal).on('hidden.bs.modal', evt => {
                if (this.offeringForm) {
                    this.offeringForm.reset();
                    $(this.offeringForm).validate().resetForm();
                    this.subtotal = 0;
                    this.showPaymentForm = false;
                    this.showOptionalForm = true;
                }
            });
        }
	};
</script>
