<template>
    <div class="d-flex flex-column h-100">
        <div class="dt-section d-flex flex-column p-4 flex-grow-1">
            <div class="row mb-2">
                <div class="col">
                    <h3 class="mb-1">Orders</h3>
                </div>

                <div class="col d-flex justify-content-end align-items-center">
                    <button
                        v-if="!showDatatable"
                        @click.prevent="closeModifyOrder()"
                        class="btn btn-secondary"
                    >Back To Orders</button>
                    <button
                        v-if="showDatatable"
                        @click.prevent="exportOrders()"
                        class="btn btn-primary"
                    >Export All Orders</button>
                </div>
            </div>

            <div v-if="showDatatable" class="dt-section p-0">
                <vom-datatable
                    table-id="order-datatable"
                    :column-names="[
                        'Order Number',
                        'Order Type',
                        'Username',
                        'RAM',
                        'Status',
                        'Total',
                        'Order Date',
                        'Options',
                    ]"
                    :ajax-route="ajaxRoute"
                    :selected-order="selectedOrder"
                    :modify-order="openModifyForm"
                ></vom-datatable>
            </div>

            <vom-order-modal
                modal-id="order-modify-modal"
                modal-title="Order Details"
                header-icon="receipt"
                modal-size="lg"
            >
                <div class="modal-body">
                    <div
                        class="vp-loader d-flex flex-column align-items-center text-center"
                        v-if="showLoader"
                    >
                        <p>Loading...</p>
                        <progress></progress>
                    </div>

                    <vom-order-details
                        :selected-order="selectedOrder"
                        v-if="showForm"
                    ></vom-order-details>

                    <div class="row justify-content-end">
                        <button
                            type="button"
                            class="btn btn-outline-primary cancel-btn"
                            @click="closeOrderModal()"
                        >Cancel</button>
                    </div>
                </div>
            </vom-order-modal>

            <template v-if="modifyOrder">
                <vom-modify-order
                    :selected-order="selectedOrder"
                    :show-modify-form="showModifyForm"
                    :close-modify-order="closeModifyOrder"
                ></vom-modify-order>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["ajaxRoute", "csrf"],
        data() {
            return {
                selectedOrder: {},
                showLoader: false,
                showForm: false,
                modifyOrder: false,
                showDatatable: true,
                showModifyForm: false,
            };
        },
        computed: {
            orderModifyModal() {
                return document.querySelector('[data-js-id="order-modify-modal"]');
            }
        },
        methods: {
            openOrderModal() {
                $(this.orderModifyModal).modal("show");
                this.hideModalForm();
            },
            closeOrderModal() {
                $(this.orderModifyModal).modal("hide");
                this.hideModalForm();
            },
            hideModalForm() {
                this.showForm = false;
                this.showLoader = true;
            },
            showModalForm() {
                this.showForm = true;
                this.showLoader = false;
            },
            openModifyForm() {
                this.modifyOrder = true;
                this.showDatatable = false;
            },
            closeModifyOrder() {
                this.modifyOrder = false;
                this.showDatatable = true;
                this.showModifyForm = false;
            },
            exportOrders() {
                let button = event.target;

                button.innerText = "Processing...";
                button.disabled = true;

                axios({
                    url: "/vpanel/modules/order-management/export",
                    method: "GET",
                    responseType: "blob"
                })
                    .then(response => {
                        let blob = new Blob([response.data]);
                        let date = moment().format("L");

                        // For IE
                        if (typeof window.navigator.msSaveBlob !== "undefined") {
                            window.navigator.msSaveBlob(blob, filename);
                        } else {
                            // Creates a link with a url to the created blob, clicks that link, then removes it from the page
                            const url = window.URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = url;
                            link.setAttribute("download", `${date}-orders-export.xlsx`);
                            document.body.appendChild(link);
                            link.click();

                            link.remove();
                        }

                        button.innerText = "Export All Orders";
                        button.disabled = false;
                    })
                    .catch(error => {
                        openApplicationErrorModal(error, "");
                        console.error(error);
                        button.innerText = "Export All Orders";
                        button.disabled = false;
                    });
            }
        }
    };
</script>
