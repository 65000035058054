<template>
    <div
        data-js-id="myself-reservation-options"
        v-if="appData.showMyselfReservationOptionsSection">

            <arp-reservation-options-form>
            </arp-reservation-options-form>

            <arp-subtotal
                v-if="appData.showMyselfReservationOptionsSection">
            </arp-subtotal>

            <div class="d-flex d-flex justify-content-end m-n1">
                <button
                    class="btn btn-outline-primary m-1"
                    @click="goBack()">
                    Go Back and Edit
                </button>
                <button
                    class="btn btn-primary m-1"
                    @click="goToNextStep()">
                    Continue
                </button>
            </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    computed: {
        ...mapState("registration", {
            reservationProperties: (state) => state.appData.reservationProperties,
            activeReservation: (state) => state.activeReservation,
            currentReservation: (state) => state.appData.currentReservation,
            appData: (state) => state.appData
        })
    },
    methods: {
        ...mapActions("registration", ["setVar"]),
        goBack() {
            this.setVar({ob: 'appData', variable: 'showMyselfReservationOptionsSection', value: false})
            this.setVar({ob: 'appData', variable: 'showAdminInfoSection', value: false})
            $('html, body').animate({ scrollTop: 0 }, 'fast');
        },
        goToNextStep() {
            let form = document.querySelector('[data-js-id="myself-reservation-options"] form');

            $(form).validate({
                errorPlacement: function(error, element) {
                    if (element.is(":radio")) {
                        error.addClass('d-flex');
                        error.prependTo(element.parents('fieldset'));
                    } else {
                        error.insertAfter(element);
                    }
                }
            });

            if ($(form).valid()) {
                this.setVar({ob: 'appData', variable: 'showMyselfReservationOptionsSection', value: false})
                this.setVar({ob: 'appData', variable: 'showAdminInfoSection', value: true})
                $('html, body').animate({ scrollTop: 0 }, 'fast');
            }
        }
    }
}
</script>
