<template>
    <form data-js-id="vendor-contact-form" @submit.prevent novalidate>
        <div class="card my-2">
            <div class="card-header">
                <p class="card-title font-weight-bold m-0">Vendor Contact Information</p>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group col-md">
                        <label for="first_name">First Name<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="first_name"
                            id="first_name"
                            title="First Name"
                            required
                            data-msg="Please enter first name"
                            v-model="appData.registration.first_name"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="last_name">Last Name<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="last_name"
                            id="last_name"
                            title="Last Name"
                            required
                            data-msg="Please enter last name"
                            v-model="appData.registration.last_name"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="title">Title/Position</label>
                        <input
                            class="form-control"
                            type="text"
                            name="title"
                            id="title"
                            title="Title/Position"
                            data-msg="Please provide a title/position"
                            v-model="appData.registration.title"
                        />
                    </div>
                </div>
                <div class="row">
                    <!-- <div class="form-group col-md">
                        <label for="date_of_birth">Date Of Birth</label>
                        <input
                            class="form-control js-flatpickr"
                            type="text"
                            name="date_of_birth"
                            id="date_of_birth"
                            title="Date Of Birth"
                            required
                            v-mask="'##/##/####'"
                            data-msg-dob="Please enter valid date"
                            data-rule-dob="true"
                            placeholder="mm/dd/yyyy"
                            v-model="appData.registration.date_of_birth"
                        />
                    </div> -->
                    <div class="form-group col-md">
                        <label for="phone">Cell Phone<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="phone"
                            id="phone"
                            title="Cell Phone"
                            required
                            data-msg="Please provide a cell phone number"
                            v-mask="'(###) ###-####'"
                            v-model="appData.registration.phone"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="company_name">Company Name<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="company_name"
                            id="company_name"
                            title="Company Name"
                            required
                            data-msg="Please provide company name"
                            v-model="appData.company.name"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md">
                        <label for="company_website">Company Website<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="company_website"
                            id="company_website"
                            title="Company Website"
                            v-model="appData.company.website"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="company_address_1">Address 1<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="company_address_1"
                            id="company_address_1"
                            title="Address 1"
                            required
                            data-msg="Please provide address"
                            v-model="appData.company.address_1"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="company_address_2">Address 2</label>
                        <input
                            class="form-control"
                            type="text"
                            name="company_address_2"
                            id="company_address_2"
                            title="Address 2"
                            v-model="appData.company.address_2"
                        />
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md">
                        <label for="company_city">City<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="company_city"
                            id="company_city"
                            title="City"
                            required
                            data-msg="Please provide city"
                            v-model="appData.company.city"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="company_state_province">State/Province<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="company_state_province"
                            id="company_state_province"
                            title="State/Province"
                            required
                            data-msg="Please provide state/province"
                            v-model="appData.company.state_province"
                        />
                    </div>
                    <div class="form-group col-md">
                        <label for="company_postal_code">Postal Code<span class="required">*</span></label>
                        <input
                            class="form-control"
                            type="text"
                            name="company_postal_code"
                            id="company_postal_code"
                            title="Postal Code"
                            required
                            data-msg="Please provide postal code"
                            v-model="appData.company.postal_code"
                        />
                    </div>
                </div>
                <div class="form-group">
                    <label for="special_requests">Special Requests</label>
                    <textarea
                        class="form-control"
                        name="special_requests"
                        id="special_requests"
                        title="Special Requests"
                        v-model="appData.registration.special_requests"
                    ></textarea>
                </div>

            </div>
        </div>
        <div class="d-flex justify-content-end my-2">
            <button class="btn btn-primary-green" @click.prevent="moveToNextStep()">Continue</button>
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return {
                appData: this.$root.appData
            };
        },
        computed: {
            form() {
                return document.querySelector('[data-js-id="vendor-contact-form"]');
            }
        },
        methods: {
            moveToNextStep() {
                if ($(this.form).valid()) {
                    this.appData.hideAllSections();
                    this.appData.sections.show_package_selection_form = 1;
                    this.appData.current_step = 2;
                    $('html, body').animate({ scrollTop: 0 }, 'fast');
                }
            }
        }
    };
</script>
