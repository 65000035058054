<template>
    <div>
        <registration-option-form
            :currentConference="currentConference"
            :csrf="crsf"
        />
    </div>
</template>

<script>
import RegistrationOptionForm from './RegistrationOptionForm.vue'
export default {
    components: { RegistrationOptionForm },
    props: ["conferences", "currentConference", "ajaxRoute", "crsf", "portalGroups", "conferencePassOptions"],
}
</script>