<template>
	<form class="modal-body" data-js-id="attendee-contact-form" novalidate>
		<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>
		<input type="hidden" name="id" v-model="currentReservation.id" />

		<div class="row">
			<div class="form-group col-md">
				<label for="attendee_first_name">First Name</label>
				<input
					class="form-control"
					type="text"
					name="attendee_first_name"
					id="attendee_first_name"
					title="First Name"
					required
					data-msg="Please provide a first name"
					v-model="currentReservation.first_name"
				/>
			</div>
			<div class="form-group col-md">
				<label for="attendee_last_name">Last Name</label>
				<input
					class="form-control"
					type="text"
					name="attendee_last_name"
					id="attendee_last_name"
					title="Last Name"
					required
					data-msg="Please supply a last name"
					v-model="currentReservation.last_name"
				/>
			</div>
			<div class="form-group col-md">
				<label for="title">Title/Position</label>
				<input
					class="form-control"
					type="text"
					name="attendee_title"
					id="attendee_title"
					title="Title/Position"
					v-model="currentReservation.title"
				/>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-md">
				<label for="attendee_email">Email</label>
				<input
					class="form-control"
					type="email"
					name="attendee_email"
					id="attendee_email"
					title="Email"
					required
					data-msg="An email is required"
					v-model="currentReservation.email"
				/>
			</div>
			<div class="form-group col-md">
				<label for="phone">Phone</label>
				<input
					class="form-control"
					type="text"
					name="attendee_phone"
					id="attendee_phone"
					title="Phone"
					required
					data-msg="Please provide a phone number"
					v-mask="'(###) ###-####'"
					v-model="currentReservation.phone"
				/>
			</div>
			<div class="form-group col-md">
				<label for="date_of_birth">Date Of Birth</label>
				<input
					class="form-control js-flatpickr"
					type="text"
					name="attendee_date_of_birth"
					id="attendee_date_of_birth"
					title="Date Of Birth"
					v-model="currentReservation.date_of_birth"
					v-mask="'##/##/####'"
					placeholder="mm/dd/yyyy"
				/>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-md">
				<label for="address_1">Address</label>
				<input
					class="form-control"
					type="text"
					name="address_1"
					id="address_1"
					title="Address"
					required
					data-msg="Please enter address"
					v-model="currentReservation.address.address_1"
				/>
			</div>
			<div class="form-group col-md">
				<label for="address_2">Address 2</label>
				<input
					class="form-control"
					type="text"
					name="address_2"
					id="address_2"
					title="Address 2"
					v-model="currentReservation.address.address_2"
				/>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-md">
				<label for="city">City</label>
				<input
					class="form-control"
					type="text"
					name="city"
					id="city"
					title="City"
					required
					data-msg="Please provide a city"
					v-model="currentReservation.address.city"
				/>
			</div>
			<div class="form-group col-md">
				<label for="state_province">State/Province</label>
				<input
					class="form-control"
					type="text"
					name="state_province"
					id="state_province"
					title="State/Province"
					required
					data-msg="Please provide a state/province"
					v-model="currentReservation.address.state_province"
				/>
			</div>
			<div class="form-group col-md">
				<label for="postal_code">Postal Code</label>
				<input
					class="form-control"
					type="text"
					name="postal_code"
					id="postal_code"
					title="Postal"
					required
					data-msg="Please provide a postal code"
					v-model="currentReservation.address.postal_code"
				/>
			</div>
		</div>

		<div class="d-flex justify-content-end m-n1">
			<button class="btn btn-outline-primary m-1" @click.prevent="closeModal()">Cancel</button>
			<button
				class="btn btn-primary m-1"
				data-js-id="attendee-contact-modal-save-change-button"
				:disabled="formProcessing"
				@click.prevent="saveForm()"
			>Save My Changes</button>
		</div>
	</form>
</template>

<script>
	import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
		props: ["reservation", "company", "closeModal"],
		data() {
			return {
				showErrorDiv: false,
				errorMessage: "",
				formProcessing: false,
			};
		},
		computed: {
			currentReservation() {
				if (this.reservation) {
					return { ...this.reservation };
				} else {
					return {
						address: {}
					}
				}
			},
			form() {
				return document.querySelector('[data-js-id="attendee-contact-form"]');
			}
		},
		methods: {
			saveForm() {
				const formData = this.currentReservation;
				const vm = this;

				const thisEvent = event;

				if ($(this.form).valid()) {
					this.formProcessing = true;
					thisEvent.target.disabled = true;
					thisEvent.target.innerText = "Processing...";

					this.showErrorDiv = false;
					this.errorMessage = "";

					axios
						.post("/portal/dashboard/admin/update-attendee", formData)
						.then(res => {
							window.location.reload();
						})
						.catch(error => {
							logApplicationError(error);
							console.error(error);

							if (error.response) {
								if (error.response.status == "422") {
									const errors = error.response.data.message;

									let errorsUl = document.createElement("ul");
									errorsUl.classList.add("list-unstyled");

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}

									vm.showErrorDiv = true;
									vm.errorMessage = errorsUl.outerHTML;
								} else {
									vm.showErrorDiv = true;
									vm.errorMessage =
										'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
								}
							}

							vm.formProcessing = false;
							vm.saveChangesButton.innerText = "Save My Changes";

							thisEvent.target.disabled = false;
							thisEvent.target.innerText = "Save My Changes";

							console.error(error);
						});
				}
			}
		}
	};
</script>