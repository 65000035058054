<template>
    <div>
        <div>
            <h4>
                <strong>Presentation Title, Description, Content, A/V, and Slides:</strong>
            </h4>
            <ul>
                <li class="ml-2">
                    VGM Heartland will use the information you submitted in your proposal for marketing activities.
                    VGM Heartland will edit your session description and bio for clarity and length, as needed.
                    Your objectives will be edited to meet the standards set forth by the accrediting bodies.
                    Revisions will not change the purpose of the original stated objectives. Revised objectives will be
                    provided to you by VGM Heartland staff prior to your presentation date.
                </li>
                <li class="ml-2">If you identified additional A/V needs in your proposal, VGM Heartland staff will contact you with questions or concerns, as needed.</li>
                <li class="ml-2">Learning objectives should be clear, concise, specific and measurable. Please refer to the <a href="/storage/shares/documents/Speakers/Bloom's_Taxonomy_Verbs.pdf" title="Internal link in a new tab to download the Bloom's Taxonomy Verbs PDF" target="_blank">Bloom’s Taxonomy Verbs</a> to create learning objectives.</li>
                <li class="ml-2">
                    As a presenter, you agree to:
                    <ul>
                        <li class="ml-2">
                            <em>
                                <strong>Refrain from attempting to persuade attendees to purchase</strong>
                            </em>
                            or use a specific product, service, piece of equipment, or device.
                        </li>
                        <li class="ml-2">
                            <em>
                                <strong>Refrain from partisan political views</strong>
                            </em>
                            and instead ensure a balanced representation of perspectives to reflect our community’s diversity.
                        </li>
                        <li class="ml-2">
                            <em>
                                <strong>Give a presentation that fits within a 60- or 90-minute timeslot</strong>
                            </em>
                            that includes adequate time for audience questions (e.g.,
                            <span
                                class="border-bottom"
                            >45-minute presentation plus 15-minutes for Q&A</span>) and a review of the presentation learning objectives.
                        </li>
                        <li class="ml-2">
                            <em>
                                <strong>Provide your own handouts, if required.</strong>
                            </em>
                            <ul>
                                <li class="ml-2">
                                    VGM Heartland provides electronic copies of your slides to all attendees via the conference website.
                                    Please submit your slides to the Education chair by the deadline so they can be provided to all attendees via Heartland Conference website.
                                </li>
                            </ul>
                        </li>
                        <li class="ml-2">
                            Submit presentation slides by the agreed upon time outlined on the Speaker Application:
                            <ul>
                                <li class="ml-2">
                                    <span class="border-bottom">NOTE:</span> VGM Heartland will provide presentation guidelines regarding PowerPoint slides format, submission instructions, and accessibility requirements.
                                    All speakers must follow this format for their presentations.
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>

            <h4>
                <strong>Conference Registration - Speaker Discount:</strong>
            </h4>
            <ul>
                <li class="ml-2">Each presenter will be entitled to admission to the conference at no charge.</li>
                <li class="ml-2">
                    Any additional guests will need to register for the conference if they wish to attend sessions or purchase a guest pass for admittance to social events.
                </li>
            </ul>

            <h4 class="mb-0">
                <strong>Presentation Materials</strong>
            </h4>
            <ul>
                <li>Copies of any visual aids and other materials used or provided by the Speaker may be made and distributed by VGM Heartland in connection with the presentation.</li>
            </ul>

            <h4 class="mb-0">
                <strong>Video and Photo Release</strong>
            </h4>
            <ul>
                <li>
                    Photographs, videos, direct quotes, and/or audio clips of speakers and attendees participating in the VGM Heartland Conference are the sole property of VGM Heartland,
                    for use for a variety of purposes including, but not limited to, publications; promotional brochures; promotions or showcase of programs on our website;
                    showcase of activities in local and/or national newspapers or programming; and other similar lawful purposes.
                    Speaker releases VGM Heartland from any and all claims whatsoever in connection with the use, reproduction, publication of the images thereof.
                    Speakers may also request copies of audio/visual materials from the presentation for their own use.
                </li>
            </ul>

            <h4 class="mb-0">
                <strong>Substitutions</strong>
            </h4>
            <ul>
                <li>
                    No substantive changes in content or presenters are made without prior written approval from VGM Heartland conference staff.
                    <strong>In the event the original presenter is unable to attend, a substitute presenter of equal expertise will be provided and VGM Heartland staff will be contacted immediately.</strong>
                </li>
            </ul>

            <h4 class="mb-0">
                <strong>Conference Format</strong>
            </h4>
            <ul>
                <li>
                    I acknowledge I am submitting for a live event. However, the Heartland Conference Planning Committee reserves the right to change the event to a virtual event if conditions deem it necessary. I also understand that presentations may be presented live or recorded and may be used as an on-demand course for Heartland Conference.
                </li>
                <li>
                    If the event becomes a virtual event, I understand that a test webinar will be required to ensure technology works appropriately.
                </li>
            </ul>

        </div>

        <div class="d-flex align-items-center justify-content-between pt-2 mt-2 border-top">
            <div class="form-check">
                <input class="form-check-input" type="checkbox" v-model="accepted_terms" id="accept_terms" />
                <label class="form-check-label" for="accept_terms">
                    I accept the terms as defined above
                    <span class="required">*</span>
                </label>
            </div>

            <button
                type="button"
                class="btn btn-primary ml-2"
                @click="submitSpeakerForm()"
                :disabled="!userAcceptedTerms"
            >Submit</button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                accepted_terms: false,
            }
        },
        computed: {
            userAcceptedTerms() {
                return this.accepted_terms;
            }
        },
        methods: {
            submitSpeakerForm() {
                this.$emit('submit-speaker-form', {event: event, accepted_terms: this.accepted_terms});
            }
        }
    };
</script>

<style lang="scss" scoped>
    ul {
        padding-left: 1.5rem;
    }

    li {
        line-height: 1.25;
        padding: .5rem 0;
    }
</style>
