<template>
	<div v-if="appData.showStartSection">
		<div v-if="attendeeRegistrationNotificationAsset" v-html="attendeeRegistrationNotificationAsset.html"></div>
		<div class="card my-2">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Are you one of the attendees, or are you registering for others?</p>
			</div>
			<div class="card-body">
				<fieldset>
					<legend class="sr-only">Are you one of the attendees, or are you registering for others?</legend>
					<div class="custom-control custom-radio custom-control-inline">
						<input
							class="custom-control-input"
							type="radio"
							name="one-of-the-attendees-or-others"
							id="user-is-one-of-the-attendees"
							:value="true"
							checked
							v-model="activeReservation.user.is_attendee"
							@change="updateNumberOfRes(1)"
						/>
						<label
							class="custom-control-label"
							for="user-is-one-of-the-attendees"
						>Yes, I am one of the attendees</label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input
							class="custom-control-input"
							type="radio"
							name="one-of-the-attendees-or-others"
							id="user-is-not-one-of-the-attendees"
							:value="false"
							v-model="activeReservation.user.is_attendee"

						/>
						<label
							class="custom-control-label"
							for="user-is-not-one-of-the-attendees"
						>No. I am registering others &ndash; not including myself</label>
					</div>
				</fieldset>
			</div>
		</div>

        <div class="card my-2">
			<div class="card-header">
				<p
					class="card-title font-weight-bold m-0"
				>Number Of Attendees</p>
			</div>
			<div class="card-body">
				<div class="form-group m-0 w-100">
					<label class="sr-only" for="number_of_attendees">Number Of Attendees</label>
					<select
						class="form-control"
						name="number_of_attendees"
						id="number_of_attendees"
						@change="changeNumberOfReservations($event.target.value)"
					>
						<option
							v-for="(count, index) in 20"
							:value="index + 1"
							:key="index"
							:selected="index + 1 == activeReservation.reservations.length"
						>{{ index + 1 }}</option>
					</select>
                    <button class="btn btn-primary my-2" @click="advanceToNextStep()">Continue</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapActions, mapState} from 'vuex';

	export default {
		name: "ArpStartSection",
		props: [
			"attendeeRegistrationNotificationAsset"
		],
		computed: {
			...mapState("registration", [
				'appData',
				'activeReservation',
			])
		},
		methods: {
			...mapActions("registration", [
				"updateNumberOfReservations"
			]),
			changeNumberOfReservations(number) {
				this.updateNumberOfReservations(number);
			},
			advanceToNextStep() {
				if (!this.activeReservation.reservations.length) {
                    let input = document.querySelector('#number_of_attendees');
                    let length = 1;

                    if (input) {
                        length = input.value;
                    }

                    this.updateNumberOfReservations(length);
                }
			    this.$store.dispatch({type: 'registration/setVar', ob: 'appData', variable: "showStartSection", value: false});
				// Show Admin Info Section
				this.$store.dispatch({type: 'registration/setVar', ob: 'appData', variable: "showAdminInfoSection", value: true});
				this.$store.dispatch({type: 'registration/setVar', ob: 'appData', variable: "current_step", value: 2});
				$("html, body").animate({ scrollTop: 0 }, "fast");
			},
			updateNumberOfRes(number) {
				this.updateNumberOfReservations(number);
			}
		},
		created() {
			this.updateNumberOfReservations(1)
		}
	}
</script>