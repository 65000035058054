export const dtAttendeeOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        { data: "name" },
        {
            data: "username",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        { data: "badge_id" },
        {
            data: "company.name",
            render: function(data, type, row) {
                return data ? data: '-';
            }
        },
        {
            data: "registration_date",
            type: "date",
            render: function(data, type, row) {
                return moment(data).format('M/D/YYYY h:mm A');
            }
        },
        {
            data: "id",
            orderable: false,
            searchable: false,
            width: "250px",
            render: function(data, type, row) {
                let link = row.create_account_link_url ? `<a class="btn btn-outline-secondary-blue ml-1" href="${row.create_account_link_url}" target="_blank">Complete Account</a>` : '';
                let courseButton = row.hasAttendeeCourses ? `<button class="btn btn-outline-primary ml-1" data-js-id="attendee-courses">Courses</button>` : '';
                let impersonate = row.id ? `<a class="btn btn-warning" href="/vpanel/modules/impersonate/attendee/${row.id}" target="_blank">Impersonate</a>` : '';

                return  `<div class="attendee-options d-flex" data-id="${row.id}">
                            <button class="btn btn-secondary-blue" data-js-id="attendee-modify">Modify</button>
                            ${courseButton}
                            ${link}
                            ${impersonate}
                            <button class="btn btn-outline-warning ml-1" data-js-id="attendee-delete">Delete</button>
                        </div>`;
            }
        }
    ]
}
