<template>
    <div class="p-4">
        <div class="d-flex justify-content-between">
            <p class="h5 font-weight-bold my-0 mr-2">Driver Management</p>
            <button class="btn btn-primary m-0" @click.prevent="openAddDriverModal">Add New Driver</button>
        </div>

        <div class="form-group">
            <div class="custom-control custom-switch">
                <input
                    type="checkbox"
                    class="custom-control-input js-boolean-control"
                    :id="`admin_is_driver`"
                    :name="`admin_is_driver`"
                    @change="makeAdminADriver()"
                    :checked="admin_is_driver"
                />
                <label class="custom-control-label" :for="`admin_is_driver`">Make me available as a driver</label>
            </div>
        </div>

        <template v-if="drivers.length">
            <input type="search" class="form-control" placeholder="Search drivers" v-model="listSearch">
            <div class="form-table">
                <div class="ft-row ft-header">
                    <p class="ft-cell ftw-4 justify-content-between">Name<span class="ft-sort" :class="sortClass('name')" @click="sortMethod('name')"></span></p>
                    <p class="ft-cell ftw-4 justify-content-between">Username<span class="ft-sort" :class="sortClass('username')" @click="sortMethod('username')"></span></p>
                    <p class="ft-cell ftw-4 justify-content-between">Email<span class="ft-sort" :class="sortClass('email')" @click="sortMethod('email')"></span></p>
                </div>
                <div
                    v-for="driver in sortedList"
                    :key="`driver_${driver.id}`"
                    class="ft-row"
                >
                    <p class="ft-cell ftw-4">{{ driver.name }}</p>
                    <p class="ft-cell ftw-4">{{ driver.username }}</p>
                    <p class="ft-cell ftw-4">{{ driver.email }}</p>
                </div>
            </div>
        </template>

        <template v-else>
            No drivers found.
        </template>

        <vdpm-add-driver-modal
            :modal-id="modalId"
        ></vdpm-add-driver-modal>
    </div>
</template>

<script>
import { showNotification } from "./../../../../core/vpanel/vpanel-functions";
export default {
    props: [
        'drivers',
        'displayAlert',
        'alertMessage',
        'adminIsADriver'
    ],
    data() {
        return {
            modalId: "add-driver-modal",
            admin_is_driver: this.adminIsADriver,
            sort: 'name',
            sortFormat: 'string',
            sortDir: 'asc',
            listSearch: ''
        }
    },
    computed: {
        addDriverModal() {
            return document.querySelector(`[data-js-id="${this.modalId}"]`);
        },
        searchedList() {
            if (this.listSearch) {
                return Array.from(this.drivers).filter(item => {
                    // Can only search these two columns for now
                    return item['name'].toLowerCase().search(this.listSearch.toLowerCase()) !== -1
                        || item['username'].toLowerCase().search(this.listSearch.toLowerCase()) !== -1
                        || item['email'].toLowerCase().search(this.listSearch.toLowerCase()) !== -1;
                })
            } else {
                return Array.from(this.drivers);
            }
        },
        sortedList() {
            let cs = this.sort;
            let origArray = this.searchedList;
            return Array.from(origArray).sort((a, b) => {
                if (this.sortDir == 'asc') {
                    if (this.sortFormat == 'date') {
                        return this.getTimestamp(a[cs]) - this.getTimestamp(b[cs]);
                    } else {
                        return a[cs].localeCompare(b[cs], 'en', { sensitivity: 'base' });
                    }
                } else {
                    if (this.sortFormat == 'date') {
                        return this.getTimestamp(b[cs]) - this.getTimestamp(a[cs]);
                    } else {
                        return b[cs].localeCompare(a[cs], 'en', { sensitivity: 'base' });
                    }
                }
            });
        },
    },
    methods: {
        sortMethod(sort, format = 'string') {
            if (this.sort === sort) {
                this.sortDir = this.sortDir === 'asc' ? 'desc' : 'asc';
            } else {
                this.sort = sort;
                this.sortDir = 'asc';
                this.sortFormat = format;
            }
        },
        sortClass(sort) {
            if (this.sort === sort) {
                return this.sortDir === 'asc' ? 'asc' : 'desc';
            }
        },
        openAddDriverModal() {
            $(this.addDriverModal).modal('show');
        },
        makeAdminADriver() {
            let input = event.target;
            let makeDriver = input.checked;

            input.disabled = true;

            axios.post('/vpanel/modules/driver-portal/make-admin-a-driver', {make_driver: makeDriver})
            .then(res => {
                let message = '';
                if (res.data.admin_is_driver) {
                    message = 'You can now be assigned pickup requests';
                    this.admin_is_driver = true;
                } else {
                    message = 'You now cannot be assigned pickup requests';
                    this.admin_is_driver = false;
                }

                input.disabled = false;
                showNotification(message, 'success')
            })
            .catch(error => {
                openApplicationErrorModal(error, '');
                console.error(error);
            });
        },
        getTimestamp(date, format = "YYYY-MM-DD HH:mm:ss") {
            return moment(date, format).format("YYYYMMDDHHmmss");
        }
    },
    mounted() {
        if (this.displayAlert) {
            showNotification(this.alertMessage, 'success');
        }
    }
}
</script>
