<template>
    <div id="vendor-list">
        <div class="agenda-filters">
            <div class="input-group">
                <div class="input-group-prepend">
                    <div class="input-group-text">
                        <span class="fas fa-search"></span>
                    </div>
                </div>
                <input class="form-control" type="search" placeholder="Search Exhibitors">
            </div>
            <div class="agenda-sorts">
                <p class="mr-2">Sort By</p>
                <p class="sort" data-sort="vl-company">Company Name</p>
            </div>
        </div>
        <div class="vl-items list row align-items-stretch">
            <div class="vl-item col-lg-6 mb-2" v-for="(vendor, index) in vendorList" :key="index">
                <div class="pb-2 border-bottom-2 border-secondary-green h-100">
                    <div class="row">
                        <div class="col-md-3" v-if="vendor.profile_image_path">
                            <div class="w-100 h-auto">
                                <div :style="`background-image: url(${vendor.profile_image_path}); background-size: cover; background-position: center; padding-top: 100%;`"></div>
                            </div>
                        </div>
                        <div class="col-md">
                            <h4 class="vl-company text-primary-green mb-1">{{ vendor.company_name }}</h4>
                            <div class="hl-status-bar-list mb-1" v-if="vendor.booth_id_location">
                                <p>Location / Booth #: <span>{{ vendor.booth_id_location }}</span></p>
                            </div>
                            <p class="vl-desc text-truncate-multi mb-1" style="--ttmfs: 16px; --ttmlh: 1.5; --ttmlc: 2;" v-if="vendor.page_content" v-html="vendor.page_content"></p>
                            <a
                                v-if="vendor.details_url"
                                class="btn btn-primary m-0"
                                :href="vendor.details_url"
                                :title="`View ${vendor.company_name}'s Exhibitor page`"
                            >Exhibitor Page Link</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["vendorList"],
    mounted() {
        const options = {
            item: 'vl-item',
            valueNames: [ 'vl-company', 'vl-desc' ]
        };

        const vendorSortableList = new List('vendor-list', options);
    }
};
</script>
