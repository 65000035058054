<template>
	<div class="card-body">
		<form
			action
			enctype="multipart/form-data"
			novalidate
			data-js-id="form-image"
		>
			<div>
				<div
					class="base-image-input"
					@click="toggleShow"
				>
				</div>
                <button
                    class="btn btn-primary-green"
                    @click.prevent="toggleShow()"
			    >Choose An Image</button>
				<div class="form-control d-none">
					<label for="profile_image">Profile Image</label>
					<input
						ref="fileInput"
						type="file"
						name="profile_image"
						id="profile_image"
						title="Profile Image"
						required
						data-msg="Please provide an image"
					/>
				</div>
				<div>
					<my-upload
						field="file"
						@crop-success="cropSuccess"
						@crop-upload-success="cropUploadSuccess"
						@crop-upload-fail="cropUploadFail"
						v-bind:modelValue.sync="show"
						:width="250"
						:height="250"
                        :noSquare="true"
						url="/portal/dashboard/speaker/update-profile-image"
						method="POST"
						:params="params"
						:headers="headers"
						:langExt="langExt"
						img-format="png"
					></my-upload>
					<img
						id="img"
						:src="imgDataUrl"
					>
				</div>
			</div>
		</form>
	</div>
</template>
<script>
import "babel-polyfill";
import myUpload from "vue-image-crop-upload";
export default {
    props: ["user"],
	components: {
		"my-upload": myUpload,
	},
	data() {
		return {
			imageFile: null,
			show: false,
			params: {
				_token: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
			},
			headers: {
				smail: "*_~",
			},
			imgDataUrl: null,

			langExt: {
				hint: "Click or drag the file here to upload",
				loading: "Uploading…",
				noSupported:
					"Browser is not supported, please use IE10+ or other browsers",
				success: "Upload success",
				fail: "Upload failed",
				preview: "Preview",
				btn: {
					off: "Cancel",
					close: "Close",
					back: "Back",
					save: "Save",
				},
				error: {
					onlyImg: "Image only",
					outOfSize: "Image exceeds size limit: ",
					lowestPx: "Image's size is too small. Expected at least: ",
				},
			},
		};
	},
	methods: {
		toggleShow() {
			this.show = !this.show;
		},
		/**
		 * crop success
		 *
		 * [param] imgDataUrl
		 * [param] field
		 */
		cropSuccess(imgDataUrl, field) {
			this.$emit("input", this.imgDataUrl);
			this.imgDataUrl = imgDataUrl;
		},
		/**
		 * upload success
		 *
		 * [param] jsonData  server api return data, already json encode
		 * [param] field
		 */
		cropUploadSuccess(jsonData, field) {
            if (this.user != null) {
                location.reload(true);
              // Create a timestamp
              var timestamp = new Date().getTime();

              // Get the image element
              var image = document.getElementById("headshot-dash");

              // Adding the timestamp parameter to image src
              image.style.backgroundImage='url(/storage/shares/speakers/'+jsonData.path +'?t='+timestamp+')';
            } else {
                document.getElementById("headshot").value =jsonData.path
            }
		},
        /**
		 * upload fail
		 *
		 * [param] status    server api return error status, like 500
		 * [param] field
		 */
		cropUploadFail(status, field) {
			console.error('-------- upload fail --------');
			console.error(status);
			console.error('field: ' + field);
		}
	},
};
</script>