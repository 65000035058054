<template>
	<div class="d-flex flex-column h-100">
		<div class="dt-section d-flex flex-column p-4 flex-grow-1">
			<div class="row mb-2">
				<div class="col">
					<h3 class="mb-1">Reporting</h3>
				</div>
			</div>
			<div class="row">
				<!-- Badge Report -->
				<div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.badge_report}"
					@click.prevent="showReport('badge_report')"
				>
					<h5>Badge Report</h5>
					<p>
						<span class="fa fa-users fa-2x"></span>
						<span class="sr-only">Click to open badge report</span>
					</p>
				</div>

				<!-- Speaker Badge Report -->
				<div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.speaker_badge_report}"
					@click.prevent="showReport('speaker_badge_report')"
				>
					<h5>Speaker Badge Report</h5>
					<p>
						<span class="fa fa-users fa-2x"></span>
						<span class="sr-only">Click to open speaker badge report</span>
					</p>
				</div>

				<!-- Order Report -->
                <div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.order_report}"
					@click.prevent="showReport('order_report')"
				>
					<h5>Orders Report</h5>
					<p>
						<span class="fa fa-receipt fa-2x"></span>
						<span class="sr-only">Click to open orders report</span>
					</p>
				</div>

				<!-- Vendor Report -->
                <div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.vendor_report}"
					@click.prevent="showReport('vendor_report')"
				>
					<h5>Vendor Report</h5>
					<p>
						<span class="fa fa-address-card fa-2x"></span>
						<span class="sr-only">Click to open vendor report</span>
					</p>
				</div>

				<!-- Session Summary Report -->
                <div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.session_summary_report}"
					@click.prevent="showReport('session_summary_report')"
				>
					<h5>Session Summary Report</h5>
					<p>
						<span class="fa fa-chalkboard-teacher fa-2x"></span>
						<span class="sr-only">Click to open session summary report</span>
					</p>
				</div>

				<!-- Course Report -->
                <div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.course_report}"
					@click.prevent="showReport('course_report')"
				>
					<h5>Course Report<br>Session Attendance</h5>
					<p>
						<span class="fa fa-apple-alt fa-2x"></span>
						<span class="sr-only">Click to open course report</span>
					</p>
				</div>

				<!-- Transcript Report -->
                <div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.transcript_report}"
					@click.prevent="showReport('transcript_report')"
				>
					<h5>Transcript Report</h5>
					<p>
						<span class="fa fa-scroll fa-2x"></span>
						<span class="sr-only">Click to open transcript report</span>
					</p>
				</div>

				<!-- Evaluation Report -->
                <div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.evaluation_report}"
					@click.prevent="showReport('evaluation_report')"
				>
					<h5>Evaluation Report</h5>
					<p>
						<span class="fas fa-file-signature fa-2x"></span>
						<span class="sr-only">Click to open evaluation report</span>
					</p>
				</div>

				<!-- CEU Report -->
                <div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.ceu_report}"
					@click.prevent="showReport('ceu_report')"
				>
					<h5>CEU Report</h5>
					<p>
						<span class="fas fa-file-invoice fa-2x"></span>
						<span class="sr-only">Click to open CEU report</span>
					</p>
				</div>

				<!-- Speaker Submission Report -->
                <div
					class="p-2 mr-1 mb-1 col-2 border text-center cursor-pointer"
					:class="{'border-primary': reports.speaker_submission_report}"
					@click.prevent="showReport('speaker_submission_report')"
				>
					<h5>Speaker Submission Report</h5>
					<p>
						<span class="fas fa-microphone-alt fa-2x"></span>
						<span class="sr-only">Click to open speaker submission report</span>
					</p>
				</div>
			</div>

            <!-- Badge Report -->
            <template v-if="reports.badge_report">
				<vpanel-reporting-badge-report :ajax-route="ajaxBadgeReport"></vpanel-reporting-badge-report>
			</template>

			<!--Speaker Badge Report -->
            <template v-if="reports.speaker_badge_report">
				<vpanel-reporting-speaker-badge-report :ajax-route="ajaxSpeakerBadgeReport"></vpanel-reporting-speaker-badge-report>
			</template>

            <!-- Order Report -->
            <template v-if="reports.order_report">
				<vpanel-reporting-order-report :ajax-route="ajaxOrderReport"></vpanel-reporting-order-report>
			</template>

            <!-- Vendor Report -->
            <template v-if="reports.vendor_report">
                <vpanel-reporting-vendor-report :ajax-route="ajaxVendorReport"></vpanel-reporting-vendor-report>
            </template>

            <!-- Session Summary Report -->
            <template v-if="reports.session_summary_report">
                <vpanel-reporting-session-summary-report :ajax-route="ajaxSessionSummaryReport"></vpanel-reporting-session-summary-report>
            </template>

            <!-- Course Report -->
            <template v-if="reports.course_report">
                <vpanel-reporting-course-report :ajax-route="ajaxCourseReport"></vpanel-reporting-course-report>
            </template>

            <!-- Transcript Report -->
            <template v-if="reports.transcript_report">
                <vpanel-reporting-transcript-report :ajax-route="ajaxTranscriptReport"></vpanel-reporting-transcript-report>
            </template>

            <!-- Evaluation Report -->
            <template v-if="reports.evaluation_report">
                <vpanel-reporting-evaluation-report :ajax-route="ajaxEvaluationReport"></vpanel-reporting-evaluation-report>
            </template>

            <!-- CEU Report -->
            <template v-if="reports.ceu_report">
                <vpanel-reporting-ceu-report :ajax-route="ajaxCeuReport"></vpanel-reporting-ceu-report>
            </template>

            <!-- Speaker Submission Report -->
            <template v-if="reports.speaker_submission_report">
                <vpanel-reporting-speaker-submission-report :ajax-route="ajaxSpeakerSubmissionReport"></vpanel-reporting-speaker-submission-report>
            </template>
		</div>
	</div>
</template>

<script>
	export default {
        props: [
            "ajaxCeuReport",
            "ajaxBadgeReport",
			"ajaxSpeakerBadgeReport",
            "ajaxOrderReport",
            "ajaxVendorReport",
            "ajaxCourseReport",
            "ajaxTranscriptReport",
            "ajaxEvaluationReport",
            "ajaxSessionSummaryReport",
            "ajaxSpeakerSubmissionReport",
        ],
		data() {
			return {
                reports: {
					ceu_report: 0,
                    badge_report: 0,
					speaker_badge_report: 0,
                    order_report: 0,
                    vendor_report: 0,
					course_report: 0,
					evaluation_report: 0,
					transcript_report: 0,
                    session_summary_report: 0,
                    speaker_submission_report: 0,
                }
			};
		},
        methods: {
            hideAllReports() {
                for (const key in this.reports) {
                    if (this.reports.hasOwnProperty(key)) {
                        this.reports[key] = 0;
                    }
                }
            },
            showReport(report) {
                this.hideAllReports();

                this.reports[report] = 1;
            }
        }
	};
</script>
