<template>
	<div class="d-flex flex-column cm-manage-vendor-packages-section dt-section">
        <div class="row mb-2">
			<div class="col">
				<h3 class="mb-1">{{ selectedConference.year }} Manage Vendor Packages</h3>
			</div>
            <div class="col d-flex justify-content-end align-items-center">
				<button class="btn btn-primary" @click.prevent="openVendorPackageAddModal()">Create Vendor Package</button>
			</div>
        </div>

		<cm-manage-vendor-packages-delete-modal
			modal-id="cm-vendor-packages-delete-modal"
			modal-title="Delete Vendor Package"
			header-icon="address-card"
			modal-size="lg"
		>
			<div class="modal-body">
				<!-- <div class="row" v-if="cancelInviteFormHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="cancelInviteFormErrorMessage"></div>
					</div>
				</div> -->
				<div class="row align-items-center flex-column">
					<span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
					<p class="h4 font-weight-bold">Are you sure you want to delete this Vendor Package?</p>
				</div>
				<div class="row justify-content-center">
					<button type="button" class="btn btn-outline-primary cancel-btn" @click="closeVendorPackageDeleteModal()">Cancel</button>
					<button type="button" class="btn btn-primary confirm-btn ml-2" @click="deleteVendorPackage()">Yes, please delete this Vendor Package</button>
				</div>
			</div>
		</cm-manage-vendor-packages-delete-modal>

		<cm-manage-vendor-packages-edit-modal
			modal-id="cm-vendor-packages-edit-modal"
			:modal-title="editModalTitle"
			header-icon="address-card"
			:modal-size="500"
		>
			<div class="modal-body">
				<div class="row" v-if="inviteFormHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="inviteFormErrorMessage"></div>
					</div>
				</div>
				<cm-vendor-package-edit-form :vendor-package-data="vendorPackageData"></cm-vendor-package-edit-form>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeVendorPackageEditModal()">Cancel</p>
				<button class="btn btn-primary m-1" type="submit" form="cm-vendor-package-form">Save</button>
			</div>
		</cm-manage-vendor-packages-edit-modal>

        <cm-manage-vendor-packages-datatable
			table-id="vendor-packages-datatable"
			:column-names="[
				'Name',
				'Booth Size',
				'# of Badges',
				'Price',
				'Custom',
				'Options',
			]"
			:ajax-route="ajaxRoute"
			:selected-vendor-package="selectedVendorPackage"
			:packages="packages"
		></cm-manage-vendor-packages-datatable>

    </div>
</template>

<script>
	

	export default {
		props: ["currentConference", "packages", "ajaxRoute", "messageFromTheHeartland", "sponsorshipLevels"],
		data() {
			return {
				editModalTitle: '',
				updateInc: 0,
				showForm: true,
				showLoader: false,
				formErrorMessage: "",
				inviteFormHasError: false,
				inviteFormErrorMessage: false,
				cancelInviteFormHasError: false,
				cancelInviteFormErrorMessage: false,
				vendorInvite: {
                    conference_uuid: this.currentConference.uuid,
					package: "",
					company_name: ""
				},
				modifyFormHasError: false,
				modifyFormErrorMessage: false,
				messageFormHasError: false,
				messageFormErrorMessage: false,
				vendorPackageData: {
					id: 0,
					base_package_price: 0,
					booth_size : '',
					conference_uuid : this.currentConference.uuid,
					is_custom : 0,
					details: [],
					name : '',
					number_of_included_badges: 0,
					created_at : '',
					updated_at : '',
					is_deleted : 0,
				},
				initialPackageData: {
					id: 0,
					base_package_price: 0,
					booth_size : '',
					conference_uuid : this.currentConference.uuid,
					is_custom : 0,
					details: [],
					name : '',
					number_of_included_badges: 0,
					created_at : '',
					updated_at : '',
					is_deleted : 0,
				}
			};
		},
		computed: {
			selectedConference() {
				if (this.length) {
				} else {
					return this.currentConference;
				}
			},
			selectedVendorPackage() {
				return [];
			},
			selectedVendor() {
				return {
					company: {},
					registration: {},
					badges: [],
					golfers: [],
					package_info: {}
				};
			},
			vendorPackageDeleteModal() {
				return document.querySelector('[data-js-id="cm-vendor-packages-delete-modal"]');
			},
			vendorPackageEditModal() {
				return document.querySelector('[data-js-id="cm-vendor-packages-edit-modal"]');
			},
			datatable() {
				return document.querySelector("#vendor-packages-datatable");
			}
		},
		methods: {
			openVendorPackageDeleteModal() {
				$(this.vendorPackageDeleteModal).modal("show");
			},
			closeVendorPackageDeleteModal() {
				$(this.vendorPackageDeleteModal).modal("hide");
				this.selectedVendorPackage['id'] = 0;
				Object.assign(this.vendorPackageData, {...this.initialPackageData})
			},
			openVendorPackageAddModal() {
				Object.assign(this.vendorPackageData, {...this.initialPackageData})
				this.openVendorPackageEditModal();
			},
			openVendorPackageEditModal() {
				if (this.selectedVendorPackage['id'] > 0) {
					this.getVendorPackage();
					this.editModalTitle = "Edit Vendor Package"
				} else {
					this.editModalTitle = "Add Vendor Package"
				}

				$(this.vendorPackageEditModal).modal("show");
			},
			closeVendorPackageEditModal() {
				$(this.vendorPackageEditModal).modal('hide')
			},
			deleteVendorPackage() {
				const id = this.selectedVendorPackage['id'];
				const vm = this;
				// Send delete command
				axios
					.post(
						"/vpanel/modules/conference/manage-vendor-packages/delete",
						{id: id}
					)
					.then(res => {
						vm.closeVendorPackageDeleteModal();
						this.updateVendorPackageStore(res.data.data);
					})
					// .catch(error => {
					// 	if (error.response) {
					// })
					;

				this.closeVendorPackageDeleteModal();
			},
			getVendorPackage() {
				const id = this.selectedVendorPackage['id'];
				const vm = this;
				axios
					.get(
						"/vpanel/modules/conference/manage-vendor-packages/get/" + id
					)
					.then(res => {
						let details = res.data.data.details

						delete res.data.data.details
						Object.assign(vm.vendorPackageData, res.data.data)

						vm.vendorPackageData.details = [...details]
					});
			},
			saveVendorPackage() {
				// No validation??
				// Added rudimentary client notifications for now
				// TODO: Add proper validation and error handling
				const vm = this;
				axios
					.post(
						"/vpanel/modules/conference/manage-vendor-packages/save",
						{data: vm.vendorPackageData}
					)
					.then(res => {
						this.updateVendorPackageStore(res.data.data);
						window.showNotification('Saved vendor package', 'success')
					})
					.catch(err => {
						console.error(err)
						window.showNotification('Couldn\'t save vendor package', 'error')
					})
				this.closeVendorPackageEditModal();
			},
			updateVendorPackageStore(data) {
				this.$emit('update-all-packages', data);
				let myTable = document.querySelector('#vendor-packages-datatable');
				if (myTable) $(myTable).DataTable().ajax.reload();
			},
			hideModalForm() {
				this.showForm = false;
				this.showLoader = true;
			},
			showModalForm() {
				this.showForm = true;
				this.showLoader = false;
			},
		},
		mounted() {
			$(this.vendorPackageEditModal).on('hidden.bs.modal', e => {
				this.selectedVendorPackage['id'] = 0;
				Object.assign(this.vendorPackageData, {...this.initialPackageData})
				this.vendorPackageData.details.splice(0, this.vendorPackageData.details.length)
			})
		}
	};
</script>
