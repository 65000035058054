<template>
	<div class="my-2" id="vendor-agenda-schedule">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1 mb-2"><em>Agenda / Schedule</em></h4>

		<div class="row">
			<div class="col-md">
				<button
					class="btn btn-primary w-100"
					@click.prevent="viewExhibitorAgenda()"
				>View The Exhibitor Agenda</button>
			</div>
			<!-- Hiding for now as this form is old af and full of hardcoded nonsense -->
			<!-- <div class="col-md">
				<a
					class="btn btn-outline-primary w-100"
					href="/portal/dashboard/attendee"
					v-if="isAttendee"
				>Switch to view as an attendee</a>
				<button
					class="btn btn-outline-primary w-100"
					v-if="!isAttendee && !isAfterRegistrationDate"
					@click="openRegModal()"
				>Register as an attendee</button>
			</div> -->
		</div>

		<!-- <portal-dashboard-attendee-registration-modal
			:modal-id="registrationModalId"
			modal-title="Register To Attend Conference"
			header-icon="user"
			modal-size="xl"
		>
			<div class="modal-body">
				<form class="reservation-form" data-js-id="registration-form" novalidate>
					<portal-dashboard-attendee-registration-form
						:reservation-props="reservationProps"
						:company="company"
						:user="user"
						:registration="attendeeRegistration"
						v-if="showRegForm"
					>

					</portal-dashboard-attendee-registration-form>
				</form>

					<portal-dashboard-registration-payment-form
						:company="company"
						:user="user"
						:registration="attendeeRegistration"
						v-if="showPaymentForm"
						:go-back="goBack"
						:reg-form="form"
					>
					</portal-dashboard-registration-payment-form>
					<div class="modal-footer justify-content-end" v-if="showRegForm">
						<button class="btn btn-primary" @click="goToPayment()">Continue to payment</button>
					</div>
			</div>
		</portal-dashboard-attendee-registration-modal> -->
	</div>
</template>

<script>
	export default {
		props: ["isAttendee", "isAfterRegistrationDate", "reservationProps", "company", "user"],
		data() {
			return {
				registrationModalId: "registration-modal",
				attendeeRegistration: {
					reservation_options: {},
					conference_pass_subtotal: 0.0,
					golf_outing_subtotal: 0.0,
					spouse_guest_subtotal: 0.0,
					subtotal: 0.0,
					discount: 0.0,
					payment_method: {
						address: {}
					}
				},
				showRegForm: true,
				showPaymentForm: false
			};
		},
		computed: {
			regModal() {
				return document.querySelector(`[data-js-id="${this.registrationModalId}"]`);
			},
			form() {
				return document.querySelector('[data-js-id="registration-form"]');
			}
		},
		methods: {
			viewExhibitorAgenda() {
				return window.open("/agenda");
			},
			openRegModal() {
				$(this.regModal).modal("show");
			},
			toggleForms() {
				this.showRegForm = !this.showRegForm;
				this.showPaymentForm = !this.showPaymentForm;
			},
			goToPayment() {
				const vm = this;

				if ($(this.form).valid()) {
					this.toggleForms();
				}
			},
			goBack() {
				this.toggleForms();
			}
		}
	};
</script>