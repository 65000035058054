<script>
import { dataTableMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtPortalAuditLogsOptions } from "./options/dtPortalAuditLogsOptions";

export default {
    mixins: [dataTableMixin],
    data() {
        return {
            moduleDatatableOptions: dtPortalAuditLogsOptions
        }
    }
}
</script>

<style>

</style>