<script>
import { dataTableMixin } from "../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtAttendeeOptions } from "./dtAttendeeOptions";

export default {
    mixins: [dataTableMixin],
    props: [
        'selectedAttendee',
    ],
    data() {
        return {
            moduleDatatableOptions: dtAttendeeOptions
        }
    },
    mounted() {
        // Add event listeners for buttons
        let dataTable = this.$el;

        const vm = this;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id = elementClicked.parentElement.dataset.id;

            switch (elementClicked.dataset.jsId) {
                case "attendee-modify":

                    vm.selectedAttendee.id = id;

                    // Show modal
                    vm.$parent.openAttendeeModal();

                    axios.get(`/vpanel/modules/conference/attendees/show/${id}`)
                        .then(res => {
                            try {
                                let attendee = res.data.attendee;

                                for (const prop in attendee) {
                                    if (attendee.hasOwnProperty(prop)) {
                                        let value = attendee[prop];

                                        if (prop == 'reservation_options') {
                                            value = JSON.parse(value);
                                        }

                                        vm.selectedAttendee[prop] = value;
                                    }
                                }

                                vm.$parent.showModalForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })

                    break;

                case "attendee-courses":

                    vm.selectedAttendee.id = id;

                    // Show modal
                    vm.$parent.hideModalForm();
                    vm.$parent.openAttendeeCourseModal();

                    axios.get(`/vpanel/modules/conference/attendees/courses/show/${id}`)
                        .then(res => {
                            try {
                                let attendeeCourses = res.data.courses;
                                vm.selectedAttendee['courses'] = attendeeCourses;

                                vm.$parent.showModalForm();
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });

                    break;

                case "attendee-delete":
                    vm.selectedAttendee.id = id;

                    // Show modal
                    vm.$parent.openAttendeeDeleteModal();

                    axios.get(`/vpanel/modules/conference/attendees/show/${id}`)
                        .then(res => {
                            try {
                                let attendee = res.data.attendee;

                                for (const prop in attendee) {
                                    if (attendee.hasOwnProperty(prop)) {
                                        let value = attendee[prop];

                                        if (prop == 'reservation_options') {
                                            value = JSON.parse(value);
                                        }

                                        vm.selectedAttendee[prop] = value;
                                    }
                                }
                            } catch(error) {
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })

                    break;
            }
        });
    }
}
</script>