<template>
	<div class="my-2">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1 mb-2"><em>Register as Conference Attendee</em></h4>

		<!-- Putting method here because I am not sure if this needs to go the agenda page or to the logged in speakers agenda -->
		<div class="row">
			<div class="col-md">
				<a
					class="btn btn-outline-primary w-100"
					href="/portal/dashboard/attendee"
					v-if="isAttendee"
				>Switch to view as an attendee</a>
				<button
					class="btn btn-outline-primary w-100"
					v-if="!isAttendee && !isAfterRegistrationDate"
					@click="linkToAttendeeRegistration()"
				>Register as an attendee</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["isAttendee", "isAfterRegistrationDate", "company", "user", "reservationProps"],
		data() {
			return {
				registrationModalId: "registration-modal",
				attendeeRegistration: {
					reservation_options: {},
					conference_pass_subtotal: 0.0,
					golf_outing_subtotal: 0.0,
					spouse_guest_subtotal: 0.0,
					subtotal: 0.0,
					discount: 0.0,
					payment_method: {
						address: {}
					}
				},
				showRegForm: true,
				showPaymentForm: false
			};
		},
		computed: {
			regModal() {
				return document.querySelector(`[data-js-id="${this.registrationModalId}"]`);
			},
			form() {
				return document.querySelector('[data-js-id="registration-form"]');
			}
		},
		methods: {
			viewExhibitorAgenda() {
				return window.open("/agenda");
			},
			openRegModal() {
				$(this.regModal).modal("show");
			},
			toggleForms() {
				this.showRegForm = !this.showRegForm;
				this.showPaymentForm = !this.showPaymentForm;
			},
			goToPayment() {
				if ($(this.form).valid()) {
					this.toggleForms();
				}
			},
			goBack() {
				this.toggleForms();
			},
            linkToAttendeeRegistration() {
                let id = this.user.speaker.id;
                document.location.href = '/portal/register/attendees/?id=' + id;
            }
		}
	};
</script>
