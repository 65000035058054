<template>
	<div>
        <div class="text-right">
            <button class="btn btn-primary mb-1" @click.prevent="exportReport">Export Full Report</button>
        </div>
		<vpanel-reporting-speaker-submission-datatable
			table-id="speaker-submission-report"
			:column-names='[
                "Session Title/Description",
                "Presenter(s)",
                "Audience Level",
                "Date Submitted"
            ]'
			:ajax-route="ajaxRoute"
		></vpanel-reporting-speaker-submission-datatable>
	</div>
</template>

<script>
	export default {
        props: [
            'ajaxRoute'
        ],
        methods: {
            exportReport() {
                const vm = this;
                const thisEvent = event;

                let button = thisEvent.target;

                button.innerText = 'Processing...';
                button.disabled = true;

                axios({url: "/vpanel/modules/reporting/export/speaker-submission-report", method: "GET", responseType: "blob"})
					.then(response => {
                        let blob = new Blob([response.data]);
                        let date = moment().format('L');

						// For IE
						if (typeof window.navigator.msSaveBlob !== "undefined") {
							window.navigator.msSaveBlob(blob, filename);
						} else {
                            // Creates a link with a url to the created blob, clicks that link, then removes it from the page
							const url  = window.URL.createObjectURL(blob);
							const link = document.createElement("a");
							link.href  = url;
							link.setAttribute("download", `${date}-speaker-submission-report.xlsx`);
							document.body.appendChild(link);
							link.click();

							link.remove();
						}

						button.innerText = "Export Full Report";
						button.disabled = false;
					})
					.catch(error => {
						openApplicationErrorModal(error, "");
						console.error(error);
						button.innerText = "Export Full Report";
						button.disabled = false;
					});
            }
        }
    };
</script>