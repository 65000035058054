<template>
    <form data-js-id="package-selection-form">
        <div class="card my-2">
            <div class="card-header">
                <p class="card-title font-weight-bold m-0">Sponsorship Options<span class="required">*</span></p>
            </div>
            <div class="card-body">
                <p class="font-weight-bold text-primary">Make an impact at the premier HME Conference of the year and get noticed at the Heartland Conference with one of the following sponsorship packages:</p>

                <div class="form-group m-0">
                    <fieldset>
                        <legend class="sr-only">Sponsorship Options?</legend>
                        <div
                            style="column-width: 400px;"
                        >
                            <div
                                class="mb-2"
                                style="break-inside: avoid;"
                                v-for="(sponsorshipPackage, index) in appData.sponsorship_packages"
                            >
                                <div
                                    class="custom-control custom-checkbox"
                                    :key="index"
                                >
                                    <input
                                        class="custom-control-input"
                                        type="checkbox"
                                        name="sponsorship_selection[]"
                                        data-msg="Please select which sponsorship you would like"
                                        :title="sponsorshipPackage.level"
                                        :value="sponsorshipPackage.id"
                                        :id="`sponsorship_id_${sponsorshipPackage.id}`"
                                        v-model="appData.selected_sponsorship_ids"
                                        :required="!appData.noSelected"
                                    />
                                    <label
                                        class="custom-control-label"
                                        :for="`sponsorship_id_${sponsorshipPackage.id}`"
                                    >{{ sponsorshipPackage.level }} - ${{ sponsorshipPackage.cost }}</label>
                                </div>
                                <ul style="font-size: small">
                                    <li v-for="detail in sponsorshipPackage.details">{{ detail }}</li>
                                </ul>
                            </div>
                            <div
                                class="mb-2"
                                style="break-inside: avoid;"
                            >
                                <div
                                    class="custom-control custom-checkbox"
                                >
                                    <input
                                        class="custom-control-input"
                                        type="checkbox"
                                        name="sponsorship_selection_no"
                                        data-msg="Please select which sponsorship you would like"
                                        title="No thanks"
                                        id="sponsorship_id_no"
                                        v-model="appData.noSelected"
                                        :required="!appData.noSelected && !appData.selected_sponsorship_ids.length"
                                    />
                                    <label
                                        class="custom-control-label"
                                        for="sponsorship_id_no"
                                    >No Thanks</label>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </div>
            </div>
        </div>

        <vendor-registration-subtotal></vendor-registration-subtotal>

        <div class="d-flex justify-content-end m-n1">
            <button class="btn btn-outline-primary m-1" @click.prevent="moveToLastStep()">Go Back &amp; Edit</button>
            <button class="btn btn-primary-green m-1" @click.prevent="moveToNextStep()">Continue</button>
        </div>
    </form>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData,
        };
    },
    computed: {
        form() {
            return document.querySelector(
                '[data-js-id="package-selection-form"]'
            );
        },
        selectedSponsorships() {
            return this.appData.selected_sponsorship_ids;
        },
        selectedPackageTotal() {
            let packages = this.appData.sponsorship_packages
            let selected = this.appData.selected_sponsorship_ids

            return packages
                .filter(sponsorship_package => {
                    return selected.some(id => {
                        return id === sponsorship_package.id
                    })
                })
                .reduce((a,c) => {
                    a += Number(c.cost || 0)
                    return a
                }, 0)
        },
        computedNoSelected() {
            return this.appData.noSelected;
        }
    },
    watch: {
        selectedPackageTotal(n, o) {
            this.appData.updateSponsorship(n)
        },
        computedNoSelected(n,o) {
            if (n) {
                this.appData.selected_sponsorship_ids.splice(0, this.appData.selected_sponsorship_ids.length);
                Array.from(document.querySelectorAll('[id^="sponsorship_selection"].error')).forEach(label => label.remove())
            }
        },
        selectedSponsorships: {
            deep: true,
            handler(n,o) {
                if (n.length) {
                    this.appData.noSelected = false
                    Array.from(document.querySelectorAll('[id^="sponsorship_selection"].error')).forEach(label => label.remove())
                }
            }
        }
    },
    methods: {
        moveToNextStep() {
            $('html, body').animate({ scrollTop: 0 }, 'fast');
            if ($(this.form).valid()) {
                this.appData.hideAllSections();
                this.appData.sections.show_add_on_form = 1;
                this.appData.current_step = 4;
            }
        },
        moveToLastStep() {
            $('html, body').animate({ scrollTop: 0 }, 'fast');

            this.appData.hideAllSections();
            this.appData.sections.show_package_selection_form = 1;
            this.appData.current_step = 2;
        }
    }
};
</script>

<!--
    Original
    Step 1 - Vendor Contact Information
    Step 2 - Booth Package Options
    Step 3 - Golf Outing
    Step 4 - Registration Confirmation - If promo code is applied to make it free it skips the next step and finishes
    Step 5 - Please choose your payment method

    New
    Step 1 - Vendor Contact Information
    Step 2 - Booth Package Options
    Step 3 - Sponsorship Selection
    Step 4 - Golf Outing
    Step 5 - Registration Confirmation - If promo code is applied to make it free it skips the next step and finishes
    Step 6 - Please choose your payment method
-->