<template>
	<form id="cm-vendor-package-form" novalidate @submit.prevent="$parent.$parent.saveVendorPackage($event)">
		<div class="row">
			<div class="col-md form-group has-label">
				<label class="required" for="name">Name</label>
				<input
					class="form-control"
					type="text"
					name="name"
					id="name"
					title="Enter Vendor Package Name"
					required
					data-msg="Please Enter Vendor Package Name"
					v-model="vendorPackageData.name"
				/>
			</div>

			<div class="col-md form-group has-label">
				<label class="required" for="name">Booth Size</label>
				<input
					class="form-control"
					type="text"
					name="booth_size"
					id="booth_size"
					title="Enter Booth Size"
					required
					data-msg="Please Enter Booth Size"
					v-model="vendorPackageData.booth_size"
				/>
			</div>
		</div>

		<div class="row">
			<div class="col-md form-group has-label">
				<label class="required" for="name"># of Badges</label>
				<input
					class="form-control"
					type="number"
					name="number_of_included_badges"
					id="number_of_included_badges"
					title="Enter # of Badges"
					required
					data-msg="Please Enter # of Badges"
					v-model="vendorPackageData.number_of_included_badges"
				/>
			</div>

			<div class="col-md form-group has-label">
				<label class="required" for="name">Price</label>
				<input
					class="form-control"
					type="number"
					name="base_package_price"
					id="base_package_price"
					title="Enter Price"
					required
					data-msg="Please Enter Price"
					v-model="vendorPackageData.base_package_price"
				/>
			</div>
		</div>

		<div class="form-group justify-content-center has-label">
			<div class="custom-control custom-switch ml-0">
				<input
					type="checkbox"
					class="custom-control-input js-boolean-control module-config-input"
					id="is_custom"
					name="is_custom"
					v-model="vendorPackageData.is_custom"
				/>
				<label class="custom-control-label" for="is_custom">Custom Vendor Package?</label>
			</div>
		</div>

		<div v-if="vendorPackageData.is_custom">
			<div
				v-for="(detail, index) in vendorPackageData.details"
				:key="`detail_${index}`"
			>
				<label class="form-group">
					<span>
						Detail #{{ index + 1 }}<span class="required">*</span>
					</span>
					<div class="d-flex">
						<input
							class="form-control"
							type="text"
							name="details[]"
							required
							v-model="vendorPackageData.details[index]"
						>

						<button
							type="button"
							class="btn btn-danger ml-1"
							@click="removeDetail(index)"
						><span class="fas fa-trash"></span></button>
					</div>
				</label>
			</div>

			<button class="btn btn-primary" type="button" @click="addDetail()">Add Detail</button>
		</div>
	</form>
</template>

<script>
	export default {
		props: ["vendorPackageData"],
		data() {
			return {
				formErrorMessage: "",
				formHasError: false,
			};
		},
		computed: {
			currentVendorInvite() {
				return '';//this.vendorInvite;
			},
			saveButton() {
				return document.querySelector('[data-js-id="vendor-invite-button"]');
			},
			customToggle() {
				return this.vendorPackageData.is_custom
			}
		},
		watch: {
			customToggle(n,o) {
				if (!this.vendorPackageData.details.length) {
					if (n) this.addDetail()
					else this.vendorPackageData.details.splice(0, this.vendorPackageData.details.length)
				}
			}
		},
		methods: {
			removeDetail(index) {
				this.vendorPackageData.details.splice(index, 1)
			},
			addDetail() {
				this.vendorPackageData.details.push('')
			},
			validateVgmMemberNumber() {
				const vm = this;
				const thisEvent = event;

				const vgmMemberNumber = event.target.value;

				this.saveButton.disabled = true;
				this.saveButton.innerText = 'Validating VGM Number...';

				axios
					.post(`/heartland-api/validate-vgm-number`, {
						accountNumber: vgmMemberNumber
					})
					.then(response => {
						const data = JSON.parse(response.data.message);

						vm.currentVendorInvite.company_name = data.companyName;
						vm.currentVendorInvite.ram = data.ram;

						this.saveButton.disabled = false;
						this.saveButton.innerText = 'Send Invite';
					})
					.catch(error => {
						logApplicationError(error);

						this.saveButton.disabled = false;
						this.saveButton.innerText = 'Send Invite';
					});
			}
		}
	};
</script>

<style scoped>
	label.form-group > span {
		line-height: 1;
		padding-top: 4px;
		padding-bottom: 4px;
		font-size: .875rem;
		font-size: 14px;
		color: #767676;
		font-weight: 900;
		margin: 0;
	}
</style>