<template>
    <div class="nav nav-tabs border-bottom-0" v-if="appData.showAttendeeReservationInformationSection">
        <div
            class="nav-item"
            :data-js-id="`attendee-tab-navs-${index}`"
            v-for="(reservation, index) in reservations" :key="index"
            @click="tabClicked(index)"
        >
            <div
                class="nav-link cursor-pointer"
                :class="{active: appData.currentReservation == index}"
            >
                <p class="m-0 d-flex align-items-center">
                    Att. {{ index + 1 }}
                    <span class="text-danger fas fa-exclamation-triangle d-none ml-1"></span>
                    <span class="text-success fas fa-check d-none ml-1"></span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
    computed: {
        ...mapState("registration", {
            reservations: (state) => state.activeReservation.reservations,
            appData : (state) => state.appData
        })
    },
    methods: {
        ...mapActions("registration", ["setVar"]),
        tabClicked(index) {
            // Get current form
            let form = document.querySelector(`.reservation-form[data-form-index="${this.appData.currentReservation}"]`);
            let tab = document.querySelector(`[data-js-id="attendee-tab-navs-${this.appData.currentReservation}"]`);

            $(form).validate({
                ignore: []
            });

            if ($(form).valid()) {
                tab.querySelector('.text-danger').classList.replace('d-flex', 'd-none');
                tab.querySelector('.text-success').classList.replace('d-none', 'd-flex');
                this.setVar({ob: 'appData', variable: 'currentReservation', value: index});
            } else {
                tab.querySelector('.text-success').classList.replace('d-flex', 'd-none');
                tab.querySelector('.text-danger').classList.replace('d-none', 'd-flex');
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.nav-link {
    cursor: pointer;
}
</style>