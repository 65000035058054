<template>
	<div>
        <div class="text-right">
            <button class="btn btn-outline-primary mb-1" @click.prevent="openDownloadModal">Download Template</button>
            <button class="btn btn-outline-primary mb-1" @click.prevent="openImportModal">Session Attend. Import</button>
            <button class="btn btn-primary mb-1" @click.prevent="exportReport">Export Full Report</button>
        </div>

		<!-- DataTable -->
		<vrcr-datatable
			table-id="course-report"
			:column-names='[
                "Badge ID",
                "Name",
                "Company",
                "Pass Type",
                "Courses",
            ]'
			:ajax-route="ajaxRoute"
		></vrcr-datatable>

		<!-- Import Modal -->
		<vrcr-event-hero-import-modal
			modal-id="vrcr-event-hero-import-modal"
			modal-title="Session Attendance Import"
			header-icon="file-import"
			modal-size="md"
		>

			<div class="modal-body">
				<div class="row" v-if="formHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="formErrorMessage"></div>
					</div>
				</div>
				<form class="d-flex flex-column" data-js-id="vrcr-event-hero-import-form" novalidate>
					<vrcr-event-hero-import-form></vrcr-event-hero-import-form>

					<div class="modal-footer d-flex justify-content-end mx-n1">
						<p class="btn btn-outline-primary m-1 cancel-button" @click="closeImportModal()" :disabled="formProcessing">Cancel</p>
						<p class="btn btn-primary m-1" data-js-id="event-hero-import-button" @click.prevent="eventHeroImport()" :disabled="formProcessing">Import</p>
					</div>
				</form>
			</div>
		</vrcr-event-hero-import-modal>
        <vrcr-download-template-modal
            modal-id="vrcr-download-template-modal"
			modal-title="Download Session Attend. Template"
			header-icon="file-import"
			modal-size="md"
        >
        <div class="modal-body">
            <hr/>
            <div class="row" v-if="downloadHasError">
                <div class="col">
                    <div class="alert alert-danger" v-html="downloadErrorMessage"></div>
                </div>
            </div>
                <div class="modal-footer d-flex justify-content-end mx-n1">
                </div>
                <p class="btn btn-outline-primary m-1 cancel-button" @click="closeDownloadModal()" :disabled="formProcessing">Cancel</p>
                <p class="btn btn-primary m-1" data-js-id="template-download-button" @click.prevent="downloadTemplate()" :disabled="formProcessing">download</p>
			</div>
        </vrcr-download-template-modal>
        </div>
</template>

<script>
import VrcrDownloadTemplateModal from './components/Course/Modals/VrcrDownloadTemplateModal.vue'
	export default {
        components: {VrcrDownloadTemplateModal},
        props: [
            'ajaxRoute'
		],
		data() {
			return {
				formHasError: false,
				formErrorMessage: "",
				formProcessing: false,
                downloadHasError: false,
                downloadErrorMessage: null,
			};
		},
		computed: {
			importButton() {
				return document.querySelector('[data-js-id="event-hero-import-button"]');
			},
			importForm() {
				return document.querySelector('[data-js-id="vrcr-event-hero-import-form"]');
			},
			importModal() {
				return document.querySelector('[data-js-id="vrcr-event-hero-import-modal"]');
			},
            downloadModal() {
				return document.querySelector('[data-js-id="vrcr-download-template-modal"]');
			},
		},
        methods: {
			openImportModal() {
				$(this.importModal).modal("show");
			},
			closeImportModal() {
				$(this.importModal).modal("hide");
			},
            openDownloadModal() {
				$(this.downloadModal).modal("show");
			},
			closeDownloadModal() {
				$(this.downloadModal).modal("hide");
			},
			eventHeroImport() {
				const vm = this;

				$(vm.importForm).validate({
					errorPlacement: function(error, element) {
						error.addClass('text-danger');
						error.insertAfter(element);
					}
				});

				if ($(vm.importForm).valid()) {
					vm.formProcessing = true;
					vm.importButton.innerText = "Processing...";

					let formData = new FormData(vm.importForm);

					axios.post('/vpanel/modules/reporting/import/course-report', formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					}).then(res => {
						const data = res.data;
						showNotification(data.message, data.result);

						axios.get('/vpanel/modules/conference/attendees/publish-transcripts')
							.then(res2 => {
								const data2 = res2.data;
								showNotification(data2.message, data2.result);

								vm.formProcessing = false;
								vm.importButton.innerText = "Import";
								vm.importForm.querySelector('[name="import_file"]').value = '';
		
								vm.closeImportModal();
		
								if (vm.formHasError === true) {
									vm.formHasError = false;
									vm.formErrorMessage = '';
								}
							})
							.catch(error => {
								if (error.response) {
									if (error.response.status == "422") {
										vm.formHasError = true;
										const errors 	= error.response.data;

										let errorsUl = document.createElement("ul");
										errorsUl.classList.add("list-styled");

										for (error in errors) {
											errorsUl.innerHTML += `<li>${errors[error]}</li>`;
										}
										vm.formErrorMessage = errorsUl.outerHTML;
									} else {
										openApplicationErrorModal(error, "");
									}
								}

								vm.formProcessing = false;
								vm.importButton.innerText = "Import";

								console.error(error);
							})
					})
					.catch(error => {
						if (error.response) {
							if (error.response.status == "422") {
								vm.formHasError = true;
								const errors 	= error.response.data;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-styled");

								console.log(errors);

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}
								vm.formErrorMessage = errorsUl.outerHTML;
							} else {
								openApplicationErrorModal(error, "");
							}
						}

						vm.formProcessing = false;
						vm.importButton.innerText = "Import";

						console.error(error);
					});
				}
			},
            exportReport() {
                const vm = this;
                const thisEvent = event;

                let button = thisEvent.target;

                button.innerText = 'Processing...';
                button.disabled = true;

                axios({
					url: "/vpanel/modules/reporting/export/course-report",
					method: "GET",
					responseType: "blob"
				})
					.then(response => {
                        let blob = new Blob([response.data]);
                        let date = moment().format('L');

						// For IE
						if (typeof window.navigator.msSaveBlob !== "undefined") {
							window.navigator.msSaveBlob(blob, filename);
						} else {
                            // Creates a link with a url to the created blob, clicks that link, then removes it from the page
							const url  = window.URL.createObjectURL(blob);
							const link = document.createElement("a");
							link.href  = url;
							link.setAttribute("download", `${date}-course-report.xlsx`);
							document.body.appendChild(link);
							link.click();

							link.remove();
						}

						button.innerText = "Export Full Report";
						button.disabled = false;
					})
					.catch(error => {
						openApplicationErrorModal(error, "");
						console.error(error);
						button.innerText = "Export Full Report";
						button.disabled = false;
					});
            },
            downloadTemplate() {
                const vm = this;
                const thisEvent = event;

                let button = thisEvent.target;

                button.innerText = 'Processing...';
                button.disabled = true;

                axios({
					url: "/vpanel/modules/reporting/download-template",
					method: "GET",
					responseType: "blob"
				})
					.then(response => {
                        let blob = new Blob([response.data]);
                        let date = moment().format('L');

						// For IE
						if (typeof window.navigator.msSaveBlob !== "undefined") {
							window.navigator.msSaveBlob(blob, filename);
						} else {
                            // Creates a link with a url to the created blob, clicks that link, then removes it from the page
							const url  = window.URL.createObjectURL(blob);
							const link = document.createElement("a");
							link.href  = url;
							link.setAttribute("download", `${date}-import_file.xlsx`);
							document.body.appendChild(link);
							link.click();

							link.remove();
						}

						button.innerText = "download";
						button.disabled = false;
					})
					.catch(error => {
						openApplicationErrorModal(error, "");
						console.error(error);
						button.innerText = "download";
						button.disabled = false;
					});
            }
        }
        }
</script>