<template>
	<div>
		<div class="row align-items-start">
			<div class="col-md form-group">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<p class="mr-3">
						Conference Pass Options
						<span class="required">*</span>
					</p>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Conference Pass Options"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Conference Pass Options</legend>
					<div
						:key="index"
						class="form-check form-check-inline form-check-radio"
						v-for="(option, index) in reservationProperties['Conference Pass Options'].options"
					>
						<label class="form-check-label" :for="`option_id_${option.id}`">
							<input
								required
								type="radio"
								:value="option.id"
								name="conference_pass"
								class="form-check-input"
								:title="option.option_name"
								:id="`option_id_${option.id}`"
								data-msg="Please select a conference pass"
								v-model="currentAttendee.reservation_options[conferencePassId]"
							/>
							<span class="form-check-sign"></span>
							{{option.option_name}}
						</label>
					</div>
				</fieldset>
			</div>
			<div class="col-md form-group" v-if="techTrainingSelected">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<span class="mr-3">
						Tech Training Level
						<span class="required">*</span>
					</span>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Tech Training Level"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Tech Training Level</legend>
					<div
						class="form-check form-check-inline form-check-radio"
						v-for="(option, index) in reservationProperties['Tech Training Level'].options"
						:key="index"
					>
						<label class="form-check-label" :for="`option_id_${option.id}`">
							<input
								required
								type="radio"
								:value="option.id"
								class="form-check-input"
								name="tech_training_level"
								:title="option.option_name"
								:id="`option_id_${option.id}`"
								data-msg="Please select a tech training level"
								v-model="currentAttendee.reservation_options[reservationProperties['Tech Training Level'].id]"
							/>
							<span class="form-check-sign"></span>
							{{option.option_name}}
						</label>
					</div>
				</fieldset>
			</div>
		</div>

        <!-- Live At Home Training Options -->

		<div class="row mb-2" v-if="lahPassSelected">
			<div class="form-group m-md-0 flex-column col-12">
                <h5 class="mb-0">Live At Home Training Options</h5>
				<p class="my-1 font-weight-bold">Monday, Sept. 13th</p>
				<div class="custom-inline-checkbox-buttons">
					<div class="custom-control custom-checkbox custom-control-inline">
						<input
							type="checkbox"
							class="custom-control-input"
							:value="lahSpanAmericaOption.id"
							:title="lahSpanAmericaOption.option_name"
							:id="`lah_option_id_${lahSpanAmericaOption.id}`"
							:name="`lah_option_id_${lahSpanAmericaOption.id}`"
							v-model="currentAttendee.reservation_options[lahOptionsId]"
						/>
						<label
							class="custom-control-label"
							:for="`lah_option_id_${lahSpanAmericaOption.id}`"
						>{{lahSpanAmericaOption.option_name}} - 8:00am to 5:00pm</label>
					</div>
				</div>
			</div>

			<div class="form-group m-md-0 flex-column col-12">
				<p class="my-1 font-weight-bold">Tuesday, Sept. 14th</p>
				<div class="custom-inline-checkbox-buttons">
					<div class="custom-control custom-checkbox custom-control-inline">
						<input
							type="checkbox"
							class="custom-control-input"
							:value="lahManufacturerCertForPathwayOption.id"
							:title="lahManufacturerCertForPathwayOption.option_name"
							v-model="currentAttendee.reservation_options[lahOptionsId]"
							:id="`lah_option_id_${lahManufacturerCertForPathwayOption.id}`"
							:name="`lah_option_id_${lahManufacturerCertForPathwayOption.id}`"
						/>
						<label
							class="custom-control-label"
							:for="`lah_option_id_${lahManufacturerCertForPathwayOption.id}`"
						>{{lahManufacturerCertForPathwayOption.option_name}} - 8:00am to 12:00pm</label>
					</div>

					<div class="custom-control custom-checkbox custom-control-inline">
						<input
							type="checkbox"
							class="custom-control-input"
							:value="lahManufacturerCertForPassportOption.id"
							:title="lahManufacturerCertForPassportOption.option_name"
							v-model="currentAttendee.reservation_options[lahOptionsId]"
							:id="`lah_option_id_${lahManufacturerCertForPassportOption.id}`"
							:name="`lah_option_id_${lahManufacturerCertForPassportOption.id}`"
						/>
						<label
							class="custom-control-label"
							:for="`lah_option_id_${lahManufacturerCertForPassportOption.id}`"
						>{{lahManufacturerCertForPassportOption.option_name}} - 1:00pm to 5:00pm</label>
					</div>
				</div>
			</div>

			<div class="form-group m-md-0 flex-column col-12">
				<p class="my-1 font-weight-bold">Wednesday, Sept. 15th</p>
				<div class="custom-inline-checkbox-buttons">
					<div class="custom-control custom-checkbox custom-control-inline">
						<input
							type="checkbox"
							class="custom-control-input"
							:value="lahEntrematicOption.id"
							:title="lahEntrematicOption.option_name"
							:id="`lah_option_id_${lahEntrematicOption.id}`"
							:name="`lah_option_id_${lahEntrematicOption.id}`"
							v-model="currentAttendee.reservation_options[lahOptionsId]"
						/>
						<label
							class="custom-control-label"
							:for="`lah_option_id_${lahEntrematicOption.id}`"
						>{{lahEntrematicOption.option_name}} - 8:00am to 10:00am</label>
					</div>
					<div class="custom-control custom-checkbox custom-control-inline">
						<input
							type="checkbox"
							class="custom-control-input"
							:value="lahHarmarHelixOption.id"
							:title="lahHarmarHelixOption.option_name"
							:id="`lah_option_id_${lahHarmarHelixOption.id}`"
							:name="`lah_option_id_${lahHarmarHelixOption.id}`"
							v-model="currentAttendee.reservation_options[lahOptionsId]"
						/>
						<label
							class="custom-control-label"
							:for="`lah_option_id_${lahHarmarHelixOption.id}`"
						>{{lahHarmarHelixOption.option_name}} - 10:00am to 3:00pm</label>
					</div>
					<div class="custom-control custom-checkbox custom-control-inline">
						<input
							type="checkbox"
							class="custom-control-input"
							:value="lahBestBathOption.id"
							:title="lahBestBathOption.option_name"
							:id="`lah_option_id_${lahBestBathOption.id}`"
							:name="`lah_option_id_${lahBestBathOption.id}`"
							v-model="currentAttendee.reservation_options[lahOptionsId]"
						/>
						<label
							class="custom-control-label"
							:for="`lah_option_id_${lahBestBathOption.id}`"
						>{{lahBestBathOption.option_name}} - 3:00pm to 5:00pm</label>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col-md form-group">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<span class="mr-3">
						Golf Outing
						<span class="required">*</span>
					</span>
					<a
						href="#"
						title="Golf Outing"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Golf Outing</legend>
					<div
						class="form-check form-check-inline form-check-radio"
						v-for="(option, index) in reservationProperties['Golf Outing'].options"
						:key="index"
					>
						<label class="form-check-label" :for="`option_id_${option.id}`">
							<input
								required
								type="radio"
								name="golf_outing"
								:value="option.id"
								class="form-check-input"
								:title="option.option_name"
								:id="`option_id_${option.id}`"
								data-msg="Please select a if attending golf outing"
								v-model="currentAttendee.reservation_options[golfOutingId]"
							/>
							<span class="form-check-sign"></span>
							{{option.option_name}}
						</label>
					</div>
				</fieldset>
			</div>
			<div class="col-md form-group" v-if="golfOutingSelected">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<span class="mr-3">
						Golf Club Rental
						<span class="required">*</span>
					</span>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Golf Club Rental"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Golf Club Rental</legend>
					<div
						class="form-check form-check-inline form-check-radio"
						v-for="(option, index) in reservationProperties['Golf Club Rental'].options"
						:key="index"
					>
						<label class="form-check-label" :for="`option_id_${option.id}`">
							<input
								required
								type="radio"
								:value="option.id"
								class="form-check-input"
								name="golf_outing_rental"
								:title="option.option_name"
								:id="`option_id_${option.id}`"
								data-msg="Please select a if need golf club rental"
								v-model="currentAttendee.reservation_options[golfClubRentalId]"
							/>
							<span class="form-check-sign"></span>
							{{option.option_name}}
						</label>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="row" v-if="golfOutingSelected && golfClubRentalSelected">
			<div class="col-md form-group">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<span class="mr-3">
						Golf Club Rental Options
						<span class="required">*</span>
					</span>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Golf Club Rental Options"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Golf Club Rental Options</legend>
					<div
						class="form-check form-check-inline form-check-radio"
						v-for="(option, index) in reservationProperties['Golf Club Rental Options'].options"
						:key="index"
					>
						<label class="form-check-label" :for="`option_id_${option.id}`">
							<input
								required
								type="radio"
								:value="option.id"
								class="form-check-input"
								:title="option.option_name"
								:id="`option_id_${option.id}`"
								name="golf_outing_rental_options"
								data-msg="Please select golf club rental option"
								v-model="currentAttendee.reservation_options[reservationProperties['Golf Club Rental Options'].id]"
							/>
							<span class="form-check-sign"></span>
							{{option.option_name}}
						</label>
					</div>
				</fieldset>
			</div>
		</div>

		<div class="form-group has-label d-flex flex-row align-items-center mx-n1">
			<div
				class="custom-control custom-switch m-1"
				v-for="(option, index) in reservationProperties['Additional Options'].options"
				:key="index"
			>
				<input
					type="checkbox"
					:value="option.id"
					:title="option.option_name"
					:id="`option_id_${option.id}`"
					:name="`option_id_${option.id}`"
					@change="toggleSpouseField($event, option)"
					class="custom-control-input js-boolean-control"
					v-model="currentAttendee.reservation_options[additionalOptionsId]"
				/>
				<label class="custom-control-label" :for="`option_id_${option.id}`">{{option.option_name}}</label>
			</div>
		</div>

		<div class="form-group has-label" v-if="showSpouseGuestName || spouseOptionSelected">
			<div class="d-flex justify-content-between align-items-center">
				<label class="required" for="spouse_guest_name">Spouse/Guest Name</label>
				<a
					href="#"
					data-toggle="tooltip"
					data-placement="right"
					data-boundary="window"
					title="Spouse/Guest Name"
				>
					<span class="fas fa-question-circle text-info cursor-pointer"></span>
				</a>
			</div>
			<input
				required
				type="text"
				class="form-control"
				id="spouse_guest_name"
				name="spouse_guest_name"
				title="Spouse/Guest Name"
				data-msg="Please provide a spouse guest name"
				v-model="currentAttendee.reservation_options[reservationProperties['Spouse/Guest Name'].id]"
			/>
		</div>

		<!-- Platinum Member options -->
		<template v-if="showPlatinumRegistrationOptions">
			<h5>Platinum Member Options</h5>
			<!-- Sunday Arrival and Options -->
			<div class="row">
				<div class="col-md form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Sunday Arrival
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Sunday Arrival"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Sunday Arrival</legend>
						<div
							:key="index"
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Sunday Arrival'].options"
						>
							<label class="form-check-label" :for="`option_id_${option.id}`">
								<input
									required
									type="radio"
									:value="option.id"
									name="sunday_arrival"
									class="form-check-input"
									:title="option.option_name"
									:id="`option_id_${option.id}`"
									data-msg="Please select if arriving Sunday"
									v-model="currentAttendee.reservation_options[reservationProperties[`Sunday Arrival`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>

                <div class="col-md-8 form-group" v-if="attendeeArrivingSunday">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Sunday Lunch
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Sunday Lunch"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Sunday Lunch?</legend>
						<div
							:key="index"
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Sunday Lunch'].options"
						>
							<label class="form-check-label" :for="`option_id_${option.id}`">
								<input
									required
									type="radio"
									:value="option.id"
									name="sunday_lunch"
									class="form-check-input"
									:title="option.option_name"
									:id="`option_id_${option.id}`"
									data-msg="Please select an option"
									v-model="currentAttendee.reservation_options[reservationProperties['Sunday Lunch'].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>

				<div class="col-md-8 form-group" v-if="attendeeArrivingSunday">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Sunday Arrival Options
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Sunday Arrival Options"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Sunday Arrival Options?</legend>
						<div
							:key="index"
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Sunday Arrival Options'].options"
						>
							<label class="form-check-label" :for="`option_id_${option.id}`">
								<input
									required
									type="radio"
									:value="option.id"
									class="form-check-input"
									:title="option.option_name"
									name="sunday_arrival_options"
									:id="`option_id_${option.id}`"
									data-msg="Please select an option"
									v-model="currentAttendee.reservation_options[reservationProperties['Sunday Arrival Options'].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>
			</div>

			<!-- If arriving Sunday, ask if joining dinner -->
			<div class="row" v-if="attendeeArrivingSunday">
				<div class="col-md form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Group Dinner
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Group Dinner"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Group Dinner</legend>
						<div
							:key="index"
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Group Dinner'].options"
						>
							<label class="form-check-label" :for="`option_id_${option.id}`">
								<input
									required
									type="radio"
									:value="option.id"
									name="group_dinner"
									class="form-check-input"
									:title="option.option_name"
									:id="`option_id_${option.id}`"
									data-msg="Please select if joining dinner"
									v-model="currentAttendee.reservation_options[reservationProperties[`Group Dinner`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>

				<!-- If joining for dinner, ask if they are bringing a guest -->
				<div class="col-md-8 form-group" v-if="attendeeArrivingSunday && attendeeJoiningDinner">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Spouse/Guest for Dinner
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Spouse/Guest for Dinner"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Spouse/Guest for Dinner</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Spouse/Guest for Dinner'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`option_id_${option.id}`">
								<input
									required
									type="radio"
									:value="option.id"
									class="form-check-input"
									name="spouse/guest_dinner"
									:title="option.option_name"
									:id="`option_id_${option.id}`"
									data-msg="Please select if attendee is bringing spouse or guest for dinner"
									v-model="currentAttendee.reservation_options[reservationProperties[`Spouse/Guest for Dinner`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>
			</div>

			<div
				class="form-group"
				v-if="attendeeArrivingSunday && attendeeJoiningDinner && attendeeBringingSpouseGuestForDinner"
			>
				<div class="d-flex justify-content-between align-items-center">
					<label :class="{required: showSpouseGuestName}" for="spouse_guest_name">Spouse/Guest Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Spouse guest name"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					required
					type="text"
					class="form-control"
					title="Spouse/Guest Name"
					id="spouse_guest_name_dinner"
					name="spouse_guest_name_dinner"
					data-msg="Please enter spouse/guest name"
					v-model="currentAttendee.reservation_options[reservationProperties[`Spouse/Guest Name for Dinner`].id]"
				/>
			</div>

			<!-- Ask if attending monday meeting -->
			<div class="row">
				<div class="col-md form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Monday Meeting Attendance
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Monday Meeting Attendance"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Monday Meeting Attendance</legend>
						<div
							:key="index"
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Monday Meeting Attendance'].options"
						>
							<label class="form-check-label" :for="`option_id_${option.id}`">
								<input
									required
									type="radio"
									:value="option.id"
									class="form-check-input"
									:title="option.option_name"
									:id="`option_id_${option.id}`"
									name="monday_meeting_attendance"
									data-msg="Please select if attendee is going to monday meeting"
									v-model="currentAttendee.reservation_options[reservationProperties[`Monday Meeting Attendance`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";

	export default {
		directives: { mask },
		props: ["selectedAttendee", "reservationProperties"],
		data() {
			return {
                showSpouseGuestName: false,
				currentAttendee: this.selectedAttendee,
			};
		},
		computed: {
			oneDayPassSelected() {
				const oneDayPassOption = this.findResPropByOptionName(
					"Conference Pass Options",
					"1-Day Pass"
				);

				return (
					this.currentAttendee.reservation_options[this.conferencePassId] ==
					oneDayPassOption.id
				);
			},
			techTrainingSelected() {
				const techTrainingOption = this.findResPropByOptionName(
					"Conference Pass Options",
					"Tech Training"
				);

				return (
					this.currentAttendee.reservation_options[this.conferencePassId] ==
					techTrainingOption.id
				);
            },
            lahPassSelected() {
				const lahOption = this.findResPropByOptionName(
					"Conference Pass Options",
					"Live At Home Training"
                );

				return (
					this.currentAttendee.reservation_options[this.conferencePassId] ==
					lahOption.id
				);
			},
			golfOutingSelected() {
				const golfOutingYesId = this.findResPropByOptionName(
					"Golf Outing",
					"Yes"
				);

				return (
					this.currentAttendee.reservation_options[this.golfOutingId] ==
					golfOutingYesId.id
				);
			},
			golfClubRentalSelected() {
				const golfOutingYesId = this.findResPropByOptionName(
					"Golf Club Rental",
					"Yes"
				);

				return (
					this.currentAttendee.reservation_options[this.golfClubRentalId] ==
					golfOutingYesId.id
				);
			},
			conferencePassId() {
				return this.reservationProperties[`Conference Pass Options`].id;
			},
			golfOutingId() {
				return this.reservationProperties[`Golf Outing`].id;
			},
			golfClubRentalId() {
				return this.reservationProperties[`Golf Club Rental`].id;
			},
			spouseGuestId() {
				return this.findResPropByOptionName("Additional Options", "Spouse/Guest")
					.id;
			},
			additionalOptionsId() {
				return this.reservationProperties[`Additional Options`].id;
			},
			spouseOptionSelected() {
				let additionalOptionsProp = this.currentAttendee.reservation_options[
					this.additionalOptionsId
				];

				if (additionalOptionsProp) {
					const indexOfOption = additionalOptionsProp.indexOf(this.spouseGuestId);

					if (indexOfOption !== -1) {
						return true;
					} else {
						return false;
					}
				}
			},
			showPlatinumRegistrationOptions() {
				return (
					this.currentAttendee.company &&
					this.currentAttendee.company.is_valid_vgm_member &&
					this.currentAttendee.company.is_platinum_vgm_member
				);
			},
			sundayArrivalId() {
				return this.reservationProperties[`Sunday Arrival`].id;
			},
			attendeeArrivingSunday() {
				const yesOption = this.findResPropByOptionName("Sunday Arrival", "Yes");

				return (
					this.currentAttendee.reservation_options[this.sundayArrivalId] ==
					yesOption.id
				);
			},
			sundayArrivalOptionsId() {
				return this.reservationProperties[`Sunday Arrival Options`].id;
			},
			groupDinnerId() {
				return this.reservationProperties[`Group Dinner`].id;
			},
			attendeeJoiningDinner() {
				const yesOption = this.findResPropByOptionName("Group Dinner", "Yes");

				return (
					this.currentAttendee.reservation_options[this.groupDinnerId] ==
					yesOption.id
				);
			},
			spouseGuestForDinnerId() {
				return this.reservationProperties[`Spouse/Guest for Dinner`].id;
			},
			attendeeBringingSpouseGuestForDinner() {
				const yesOption = this.findResPropByOptionName(
					"Spouse/Guest for Dinner",
					"Yes"
				);

				return (
					this.currentAttendee.reservation_options[this.spouseGuestForDinnerId] ==
					yesOption.id
				);
			},
			lahOptionsId() {
				return this.reservationProperties[`Live At Home Training Sessions`].id;
			},
			lahSpanAmericaOption() {
				return this.getLiveAtHomeOption(
					"Span America Installation Training Class"
				);
			},
			lahEntrematicOption() {
                return this.getLiveAtHomeOption('Entrematic - Enter a New Era of Door Automation');
            },
			lahBestBathOption() {
				return this.getLiveAtHomeOption("Best Bath");
			},
			lahHarmarHelixOption() {
				return this.getLiveAtHomeOption("Harmar Accessibility Products");
			},
			lahManufacturerCertForPathwayOption() {
				return this.getLiveAtHomeOption(
					"Manufacturer Certification for Pathway 3G Ramp System by EZ-ACCESS"
				);
			},
			lahManufacturerCertForPassportOption() {
				return this.getLiveAtHomeOption(
					"Manufacturer Certification for Passport&trade; Vertical Platform Lift by EZ-ACCESS"
				);
			}
		},
		methods: {
			findResPropByOptionName(propName, optionName) {
				const resProp = this.reservationProperties[`${propName}`].options.filter(
					option => {
						return option.option_name == `${optionName}`;
					}
				);

				return { ...resProp[0] };
			},
			toggleSpouseField(event, option) {
				let additionalOptionsProp = this.currentAttendee.reservation_options[
					this.additionalOptionsId
				];

				const indexOfOption = additionalOptionsProp.indexOf(option.id);

				if (event.target.checked) {
					if (indexOfOption !== -1) {
						if (option.id == this.spouseGuestId) {
							this.showSpouseGuestName = true;
						}
					}
				} else {
					if (indexOfOption == -1) {
						if (option.id == this.spouseGuestId) {
							this.showSpouseGuestName = false;
						}
					}
				}
			},
			updateLahOptions(event, option) {
				let liveAtHomeOptions = this.currentAttendee.reservation_options.reservation_options[this.lahOptionsId];

				// Check to see if the additional options object exists
				if (!liveAtHomeOptions) {
					liveAtHomeOptions = [];
				}

				const indexOfOption = liveAtHomeOptions.indexOf(option.id);

				if (event.target.checked) {
					if (indexOfOption == -1) {
						liveAtHomeOptions.push(option.id);
					}
				} else {
					if (indexOfOption !== -1) {
						liveAtHomeOptions.splice(indexOfOption, 1);
					}
				}

				this.currentAttendee.reservation_options[this.attendeeUniqueIndex].reservation_options[
					this.lahOptionsId
				] = liveAtHomeOptions;
			},
			getLiveAtHomeOption(name) {
				return this.findResPropByOptionName(
					"Live At Home Training Sessions",
					name
				);
			}
		},
		mounted() {
			if (
				typeof this.currentAttendee.reservation_options[
					this.additionalOptionsId
				] == "undefined"
			) {
				this.currentAttendee.reservation_options[this.additionalOptionsId] = [];
				this.currentAttendee.reservation_options[this.lahOptionsId] = [];
			}
		}
	};
</script>