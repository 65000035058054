<template>
	<div class="mt-6" id="vendor-documents">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1"><em>Documents</em></h4>

        <div class="d-flex flex-wrap mx-n2">
            <p class="d-flex align-items-center m-2">
                <span class="fas fa-file-image fa-lg mr-1 text-image"></span>
                Images
            </p>
            <p class="d-flex align-items-center m-2">
                <span class="fas fa-file-pdf fa-lg mr-1 text-pdf"></span>
                PDF
            </p>
            <p class="d-flex align-items-center m-2">
                <span class="fas fa-file-word fa-lg mr-1 text-word"></span>
                Word
            </p>
            <p class="d-flex align-items-center m-2">
                <span class="fas fa-file-word fa-lg mr-1 text-excel"></span>
                Excel
            </p>
            <p class="d-flex align-items-center m-2">
                <span class="fas fa-file-word fa-lg mr-1 text-powerpoint"></span>
                PowerPoint
            </p>
        </div>
		<div class="row my-n1">
            <div class="col-md-6 my-1" v-for="(document, index) in documents" :key="index">
                <p class="m-0 d-flex align-items-stretch"><span :class="`fas fa-file-${document.type} fa-3x text-${document.type} mr-1`"></span><a class="d-flex align-items-center px-1 bg-secondary-yellow flex-grow-1" :href="document.path" :title="`Downloads the document named ${document.title}`" target="_self" download>{{ document.title }}</a></p>
            </div>
		</div>
	</div>
</template>

<script>
export default {
	props: ["documents"]
};
</script>

<style>
</style>