<template>
	<form class="save-new-conference-form d-flex flex-column h-100" data-js-id="save-new-conference-form" novalidate>
		<div class="flex-grow-1">
			<div class="row" v-if="formHasError">
				<div class="col">
					<div class="alert alert-danger" v-html="formErrorMessage"></div>
				</div>
			</div>
            <div class="row">
                <div class="form-group col-3">
                    <label for="name">Conference Name</label>
                    <input
                        class="form-control"
                        type="text"
                        name="name"
                        id="name"
                        title="Conference Name"
                        v-model="new_conference.name"
                    />
                </div>
                <div class="form-group col-3">
                    <label for="year">Conference Year</label>
                    <select
                        class="form-control"
                        name="year"
                        id="year"
                        title="Conference Year"
                        required
                        data-msg="Please select year"
                        v-model="new_conference.year"
                        >
                        <option value="">-- Select a year --</option>
                        <option v-for="(year, index) in availableYears" :key="`${index}`" :value="year">{{ year }}</option>
                    </select>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="conference_start_date">Conference Start Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Conference Start Date"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="conference_start_date"
						id="conference_start_date"
						title="Enter conference start date"
						required
						data-msg="Please provide a start date"
						v-mask="'##/##/####'"
						v-model="new_conference.conference_start_date"
					/>
				</div>
				<div class="form-group col-md">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="conference_end_date">Conference End Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date conference ends"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="conference_end_date"
						id="conference_end_date"
						title="Enter conference end date"
						required
						data-msg="Please provide an end date"
						v-mask="'##/##/####'"
						v-model="new_conference.conference_end_date"
					/>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-md">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="registration_start_date">Registration Start Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date attendees can start registering"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="registration_start_date"
                        v-model="new_conference.registration_start_date"
						id="registration_start_date"
						title="Enter registration start date"
						required
						data-msg="Please provide a start date"
						v-mask="'##/##/####'"
					/>
				</div>
				<div class="form-group col-md">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="registration_end_date">Registration End Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date registration ends"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="registration_end_date"
                        v-model="new_conference.registration_end_date"
						id="registration_end_date"
						title="Enter registration end date"
						required
						data-msg="Please provide a end date"
						v-mask="'##/##/####'"
					/>
				</div>
			</div>
			<div class="row">
				<div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="badge_info_lockdown_date">Badge Info Lockdown Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date that badge information must be in by"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="badge_info_lockdown_date"
                        v-model="new_conference.badge_info_lockdown_date"
						id="badge_info_lockdown_date"
						title="Enter badge info lockdown date"
						required
						data-msg="Please provide a lockdown date"
						v-mask="'##/##/####'"
					/>
				</div>

				<div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="course_approval_end_date">Course Approval End Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Date Course Approval ends"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="course_approval_end_date"
                        v-model="new_conference.course_approval_lockdown_date"
						id="course_approval_end_date"
						title="Enter a course approval end date"
						required
						data-msg="Please provide a course approval end date"
						v-mask="'##/##/####'"
					/>
				</div>
			</div>

			<h4>Registration Notification Emails</h4>
			<div class="row">
				<div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label
							class="required"
							for="registration_notification_email"
						>Attendee Registration Notification Email(s)</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="An email will be sent to these addresses when an attendee registers for the conference or if an optional offering is submitted. If entering more than one email, please separate with a semicolon(;)."
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						name="registration_notification_email"
                        v-model="new_conference.registration_notification_email"
						id="registration_notification_email"
						title="Enter Attendee registration notification emails"
						required
						data-msg="Please provide at least one email"
					/>
				</div>
				<div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label
							class="required"
							for="vendor_registration_notification_email"
						>Vendor Registration Notification Email(s)</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="An email will be sent to these addresses when an vendor registers for the conference. If entering more than one email, please separate with a semicolon(;)."
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						name="vendor_registration_notification_email"
                        v-model="new_conference.vendor_registration_notification_email"
						id="vendor_registration_notification_email"
						title="Enter Vendor registration notification emails"
						required
						data-msg="Please provide at least one email"
					/>
				</div>
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label
							class="required"
							for="vendor_registration_notification_email"
						>Speaker Registration Notification Email(s)</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="An email will be sent to these addresses when an speaker registers for the conference. If entering more than one email, please separate with a semicolon(;)."
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						name="speaker_registration_notification_email"
                        v-model="new_conference.speaker_registration_notification_email"
						id="speaker_registration_notification_email"
						title="Enter Speaker registration notification emails"
						required
						data-msg="Please provide at least one email"
					/>
				</div>
			</div>

			<!-- Sponsorship Levels -->
			<!-- <h4>Sponsorship Levels</h4>
			<div class="row">
				<div class="form-group col-3" v-for="(level, index) in newSponsorshipLevels" :key="index">
					<div class="d-flex justify-content-between align-items-center">
						<label :for="`${level.id}`">Sponsor Level {{ level.sort_order }}</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							:title="`The Sponsorship Level ${level.sort_order}`"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						name="sponsorship_levels[]"
						:id="`${level.id}`"
						title="Enter sponsorship level name"
                        v-model="sponsorship_levels[level.id]"
					/>
				</div>
			</div> -->
		</div>
			<!-- Early Bird Pricing -->
			<h4>Early Bird Pricing</h4>
            <div class="row">
                <div class="form-group col">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="early_bird_dollar_off">Discount to Apply</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="This is the dollar amount of the discount that will be appplied."
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="number"
						name="early_bird_dollar_off"
						id="early_bird_dollar_off"
						title="Enter Attendee registration early bird pricing"
						required
						step=".01"
						min="0.00"
						data-msg="Please provide an early bird price"
						v-model="new_conference.early_bird_dollar_off"
					/>
				</div>
                <div class="col-md form-group has-label">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="early_bird_start_date">Early Bird Start Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="First day that the early bird pricing will be valid"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="early_bird_start_date"
						id="early_bird_start_date"
						title="Enter early bird start date"
						required
						data-msg="Please provide a early bird start date"
						v-mask="'##/##/####'"
						v-model="new_conference.early_bird_start_date"
					/>
				</div>
                <div class="col-md form-group has-label">
					<div class="d-flex justify-content-between align-items-center">
						<label class="required" for="early_bird_end_date">Early Bird End Date</label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Last day that the early bird pricing will be valid"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control js-flatpickr"
						type="text"
						name="early_bird_end_date"
						id="early_bird_end_date"
						title="Enter early bird end date"
						required
						data-msg="Please provide a early bird end date"
						v-mask="'##/##/####'"
						v-model="new_conference.early_bird_end_date"
					/>
				</div>
            </div>
		<div class="d-flex justify-content-end">
			<button class="btn btn-primary" @click.prevent="saveNewConference()">Create New Year</button>
		</div>
	</form>
</template>

<script>
	export default {
		props: ["sponsorshipLevels", "availableYears", "modalId"],
		data() {
			return {
				formHasError: false,
				formErrorMessage: "",
                sponsorship_levels: {
                    1: "",
                    2: "",
                    3: "",
                    4: "",
                    5: "",
                    6: "",
                    7: "",
                },
                new_conference: {
                    name: "VGM Heartland Conference",
                    year: this.availableYears[0]
                }
			};
		},
		computed: {
			newSponsorshipLevels() {
				if (this.length) {
				} else {
					let mySponsorshipLevels = [];
					for (let i = 0; i < 7; i++) {
						let tempArr = [];
						tempArr['conference_uuid'] = ''; 
						tempArr['created_at'] = '';
						tempArr['id'] = i+1;
						tempArr['level'] = '';
						tempArr['sort_order'] = i+1;
						tempArr['updated_at'] = '';
						mySponsorshipLevels.push(tempArr);
					}
					return mySponsorshipLevels;
				}
            },
            form() {
                return document.querySelector('[data-js-id="save-new-conference-form"]');
            },
            modal() {
                return document.querySelector(`[data-js-id="${this.modalId}"]`)
            }
        },
        methods: {
            saveNewConference() {
                const vm = this;
                const button = event.target;

                let originalButtonText = event.target.innerHTML;

                this.formHasError = false;
                this.formErrorMessage = "";

                $(this.form).validate();

                if ($(this.form).valid()) {
                    button.disabled = true;
                    button.innerHTML = 'Processing...';

                    let formData = {
                        sponsorship_levels: {
                            ...this.sponsorship_levels
                        },
                        new_conference: {
                            ...this.new_conference
                        }
                    };

                    axios.post('/vpanel/modules/conference/store', formData)
                        .then(res => {
                            button.disabled = false;
                            button.innerHTML = originalButtonText;

                            $(vm.modal).modal('hide');

                            showNotification(res.data.message, 'success');
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == '422') {
                                    const errors 	   = error.response.data.errors;
									const errorMessage = error.response.data.message;

                                    vm.formHasError    = true;

									let errorsUl = document.createElement("ul");
									errorsUl.classList.add("list-unstyled");

									for (error in errors) {
										errorsUl.innerHTML += `<li>${errors[error]}</li>`;
									}

                                    vm.formErrorMessage = errorMessage + errorsUl.outerHTML;
                                } else {
                                    openApplicationErrorModal(error, "");
                                    console.error(error);
                                }
                            }

                            button.disabled = false;
                            button.innerHTML = originalButtonText;

                            console.error(error);
                        });
                }
            }
        },
        mounted() {
            $(this.modal).on('hidden.bs.modal', e => {
                this.form.reset();

                $(this.form).validate().resetForm();

                let formGroupDivs = this.form.querySelectorAll('.form-control');

                for (let index = 0; index < formGroupDivs.length; index++) {
                    const div = formGroupDivs[index];

                    div.classList.remove('valid', 'error');
                }
            });
        }
	};
</script>
