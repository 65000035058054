<template>
	<div class="d-flex flex-column cm-course-section dt-section">
		<div class="row mb-2">
			<div class="col">
				<h3 class="mb-1">{{ selectedConference.year }} Courses</h3>
			</div>
			<div class="col d-flex justify-content-end align-items-center">
				<button class="btn btn-outline-secondary-blue mr-1" @click.prevent="openImportCeusModal()">Import CEU's</button>
				<button class="btn btn-outline-primary mr-1" @click.prevent="openCourseModifyModal('add')">Add New Course</button>
				<button class="btn btn-primary" @click.prevent="openFinalizeModal()" v-if="!isAfterCourseApprovalDate">Finalize Courses</button>
			</div>
		</div>

		<cm-course-datatable
			table-id="course-datatable"
			:column-names="[
				'Session #',
				'Title',
				'Speakers',
				'Status',
				'Attendee Count',
				'Options',
			]"
			:ajax-route="ajaxRoute"
			:selected-course="selectedCourse"
		></cm-course-datatable>

		<cm-course-finalize-modal
			modal-id="cm-course-finalize-modal"
			modal-title="Confirm Finalization"
			header-icon="chalkboard-teacher"
			modal-size="lg"
		>
			<div class="modal-body">
				<p>You are about to finalize the courses and speakers for the {{ selectedConference.year }} Conference. This will send out denial emails to the speakers that were not approved.</p>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" @click="closeFinalizeModal()">Cancel</p>
				<p class="btn btn-primary m-1" data-js-id="course-finalize-button" @click.prevent="finalize()">Yes, Finalize these courses/speakers</p>
			</div>
		</cm-course-finalize-modal>

		<cm-course-ceu-import-modal
			modal-id="cm-course-import-ceu-modal"
			modal-title="Import CEUs"
			header-icon="file-import"
			modal-size="md"
		>

			<div class="modal-body">
				<div class="row" v-if="formHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="formErrorMessage"></div>
					</div>
				</div>
				<form class="d-flex flex-column" data-js-id="cm-course-import-ceus-form" novalidate>
					<cm-course-import-ceu-form></cm-course-import-ceu-form>

					<div class="modal-footer d-flex justify-content-end mx-n1">
						<p class="btn btn-outline-primary m-1 cancel-button" @click="closeImportCeuModal()" :disabled="formProcessing">Cancel</p>
						<p class="btn btn-primary m-1" data-js-id="course-import-ceus-button" @click.prevent="importCeus()" :disabled="formProcessing">Import</p>
					</div>
				</form>
			</div>
		</cm-course-ceu-import-modal>

		<cm-course-modify-modal
			modal-id="cm-course-modify-modal"
			modal-title="Manage Course"
			header-icon="graduation-cap"
			modal-size="lg"
			:all-speakers="allSpeakers"
			:all-categories="allCategories"
			:modal-nav-options="[
        		{'name': 'General Information', 'active': true, 'id': 'course-general-tab', 'tabId': 'course-general'},
        		{'name': 'Content References', 'active': false, 'id': 'course-references-tab', 'tabId': 'course-references'},
        		{'name': 'Objectives', 'active': false, 'id': 'course-objectives-tab', 'tabId': 'course-objectives'},
        		{'name': 'CEUs', 'active': false, 'id': 'course-ceus-tab', 'tabId': 'course-ceus'},
    		]"
		>
			<div class="modal-body">
				<div class="row" v-if="formHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="formErrorMessage"></div>
					</div>
				</div>

				<div class="vp-loader d-flex flex-column align-items-center text-center" v-if="showLoader">
					<p>Loading...</p>
					<progress></progress>
				</div>
				<form class="d-flex flex-column" v-if="showForm" data-js-id="cm-course-modify-form" novalidate>
					<div class="tab-content">
						<div
							class="tab-pane fade show active"
							id="course-general"
							role="tab-panel"
							aria-labelledby="course-general-tab"
						>
							<cm-course-modify-general-form :selected-course="selectedCourse" :all-speakers="allSpeakers" :all-categories="allCategories"></cm-course-modify-general-form>
						</div>
						<div
							class="tab-pane fade"
							id="course-references"
							role="tab-panel"
							aria-labelledby="course-references-tab"
						>
							<cm-course-modify-references-form :selected-course="selectedCourse"></cm-course-modify-references-form>
						</div>
						<div
							class="tab-pane fade"
							id="course-objectives"
							role="tab-panel"
							aria-labelledby="course-objectives-tab"
						><cm-course-modify-objectives-form :selected-course="selectedCourse"></cm-course-modify-objectives-form>
						</div>
						<div
							class="tab-pane fade"
							id="course-ceus"
							role="tab-panel"
							aria-labelledby="course-ceus-tab"
						><cm-course-modify-ceu-form :selected-course="selectedCourse" :ceu-types="ceuTypes"></cm-course-modify-ceu-form>
						</div>
					</div>
				</form>
			</div>

			<div class="modal-footer d-flex justify-content-end mx-n1">
				<p class="btn btn-outline-primary m-1 cancel-button" :disabled="formProcessing" @click="closeCourseModifyModal()">Cancel</p>
				<p class="btn btn-primary m-1" data-js-id="save-course-button" @click.prevent="saveCourse()">Next</p>
			</div>
		</cm-course-modify-modal>

		<cm-course-manage-attendees-modal
			modal-id="cm-course-manage-attendees-modal"
			:modal-title="`Manage Attendees`"
			header-icon="user-circle"
			modal-size="lg"
		>
			<div class="modal-body">
				<div class="vp-status-bar-list">
					<p>Course Start Time: <span>{{ currentAgenda.start_date_time }}</span></p>
					<p>Course End Time: <span>{{ currentAgenda.end_date_time }}</span></p>
				</div>

				<ErrorAlert
					:message="courseAttendeesErrorMessage"
					:errors="courseAttendeesErrors"
				/>

				<div ref="courseAttendeesGrid">
					<div style="display: flex;" :style="{justifyContent: attendeesInSelectedCourse.length ? 'space-between' : 'flex-end'}">
						<button v-if="attendeesInSelectedCourse.length" class="btn btn-outline-secondary" type="submit" form="remove-attendees-form">Remove selected attendees</button>
						<button class="btn btn-primary" type="button" @click="addAttendeeToCourse(currentCourse.course_id)">Add attendee</button>
					</div>
					<form class="border border-left-0 border-right-0 my-2 pb-2" id="attendee-add-form" v-if="addingAttendeeToCourse" @submit.prevent="submitAddAttendee($event)">
						<div class="vp-loader flex-column align-items-center text-center" v-if="fetchingAttendeesInConference">
							<p>Loading...</p>
							<progress></progress>
						</div>
						<template v-else>
							<div v-if="attendeesInConference.length">
								<div class="row mt-2">
									<div class="form-group col-md-12">
										<label class="m-0">
											<select name="portal_user_id" class="form-control" required>
												<option value="" selected hidden>Select an attendee</option>
												<option
													v-for="attendee in attendeesInConference"
													:value="attendee.id"
												>{{ attendee.first_name }} {{ attendee.last_name }} ({{ attendee.username }})</option>
											</select>
											<span class="mt-1 d-block">Attendee<span class="required">*</span></span>
										</label>
									</div>
									<div class="form-group col-md-4">
										<label class="m-0">
											<input name="scanned_in_timestamp" type="datetime-local" class="form-control" required :value="currentAgenda.start_date_time" />
											<span class="mt-1 d-block">Course Check-In<span class="required">*</span></span>
										</label>
									</div>
									<div class="form-group col-md-4">
										<label class="m-0">
											<input name="scanned_out_timestamp" type="datetime-local" class="form-control" required :value="currentAgenda.end_date_time" />
											<span class="mt-1 d-block">Course Check-Out<span class="required">*</span></span>
										</label>
									</div>
									<div class="form-group col-md-4">
										<label class="m-0">
											<input name="attendance_percentage" type="number" min="0" max="100" class="form-control" required value="100" />
											<span class="mt-1 d-block">Attendance Percentage<span class="required">*</span></span>
										</label>
									</div>
								</div>
	
								<div class="d-flex justify-content-end">
									<button type="button" class="btn btn-outline-primary" @click.prevent="addingAttendeeToCourse = false">Cancel</button>
									<button class="btn btn-primary ml-1" type="submit">Save Attendee</button>
								</div>
							</div>
							<p class="text-center mt-2 mb-0" v-else>All attendees in this conference are already enrolled in this course.</p>
						</template>
					</form>
					<template v-if="attendeesInSelectedCourse.length">
						<form id="attendee-edit-form" @submit.prevent="submitEditAttendee($event)" enctype="multipart/form-data"></form>
						<form id="remove-attendees-form" @submit.prevent="bulkRemoveAttendees($event)"></form>

						<table :key="`force-update-${updateInc}`">
							<thead>
								<tr>
									<th>
										<label style="margin: 4px;" title="Toggle all for bulk removal">
											<span
												style="
													border: 0;
													clip: rect(0 0 0 0);
													height: 1px;
													margin: -1px;
													overflow: hidden;
													padding: 0;
													position: absolute;
													width: 1px;
												"
											>
												Toggle all for bulk removal
											</span>
											<input ref="selectAllAttendees" type="checkbox" @change="handleSelectAll()" form="remove-attendees-form" />
										</label>
									</th>
									<th style="width: 100%;">Attendee</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								<template v-for="attendee in attendeesInSelectedCourse">
									<tr style="border-top: 1px solid #eee; padding-block: 2px;" >
										<td>
											<label style="margin: 4px;" :title="`Select ${attendee.full_name} for bulk removal`">
												<span
													style="
														border: 0;
														clip: rect(0 0 0 0);
														height: 1px;
														margin: -1px;
														overflow: hidden;
														padding: 0;
														position: absolute;
														width: 1px;
													"
												>
													Select {{ attendee.full_name }} for bulk removal
												</span>
												<input
													ref="attendeeCheckboxes"
													type="checkbox"
													:name="`removeSelected[${attendee.portal_user_id}]`"
													@change="updateSelectAllToggle()"
													:key="attendee.portal_user_id"
													form="remove-attendees-form"
												/>
											</label>
										</td>
										<td>{{ attendee.full_name }}</td>
										<td style="padding-block: 4px;">
											<div style="width: max-content">
												<button class="btn btn-primary" type="button" @click="editAttendeeInCourse(attendee)">Edit attendee</button>
												<button class="btn btn-outline-primary" type="button" @click="removeAttendeeFromCourse(attendee)">Remove from course</button>
											</div>
										</td>
									</tr>
									<tr v-if="editingAttendeeInCourse === attendee.portal_user_id">
										<td colspan="3">
											<div class="row mt-1">
												<div class="form-group col-md">
													<label class="p-0">
														<input
															ref="scanned_in_timestamp"
															form="attendee-edit-form"
															class="form-control"
															type="datetime-local"
															name="scanned_in_timestamp"
															required
															:value="attendee.scanned_in_timestamp"
															@input="updateAttendancePercentage()"
															title="Please enter the date and time that the attendee checked-in"
															data-msg="Please enter the date and time that the attendee checked-in"
														/>
														<span style="display: block; padding-top: 4px">Course Check-In<span class="required">*</span></span>
													</label>
												</div>
												<div class="form-group col-md">
													<label class="p-0">
														<input
															ref="scanned_out_timestamp"
															form="attendee-edit-form"
															class="form-control"
															type="datetime-local"
															name="scanned_out_timestamp"
															required
															:value="attendee.scanned_out_timestamp"
															@input="updateAttendancePercentage()"
															title="Please enter the date and time that the attendee checked-out"
															data-msg="Please enter the date and time that the attendee checked-out"
														/>
														<span style="display: block; padding-top: 4px">Course Check-Out<span class="required">*</span></span>
													</label>
												</div>
												<div class="form-group col-md">
													<label class="p-0">
														<input
															ref="attendance_percentage"
															form="attendee-edit-form"
															class="form-control"
															type="number"
															min="0"
															max="100"
															name="attendance_percentage"
															required
															:value="attendee.attendance_percentage"
															title="Please enter percentage completed for this course"
															data-msg="Please enter percentage completed for this course"
														/>
														<span style="display: block; padding-top: 4px">Attendance Percentage<span class="required">*</span></span>
													</label>
												</div>
											</div>
											<div class="d-flex justify-content-end mb-1">
												<button class="btn btn-primary" type="submit" form="attendee-edit-form">Save Attendee</button>
											</div>
										</td>
									</tr>
								</template>
							</tbody>
						</table>
					</template>
					<p style="padding-top: 1rem;" v-else>No attendees have completed this course.</p>
				</div>
			</div>
		</cm-course-manage-attendees-modal>
	</div>
</template>

<script>
	export default {
		props: [
			"ceuTypes",
			"ajaxRoute",
			"allSpeakers",
			"allCategories",
			"currentConference",
			"isAfterCourseApprovalDate"
		],
		data() {
			return {
				showForm: true,
				showLoader: false,
				formErrorMessage: "",
				formHasError: false,
				formProcessing: false,
				isAdd: false,
				addingAttendeeToCourse: false,
				attendeesInSelectedCourse: [],
				fetchingAttendeesInConference: false,
				attendeesInConference: [],
				editingAttendeeInCourse: null,
				updateInc: 0,
				currentCourse: {},
				currentAgenda: {},
				courseAttendeesErrorMessage: '',
				courseAttendeesErrors: {},
			};
		},
		computed: {
			selectedConference() {
				return this.currentConference;
			},
			selectedCourse() {
				return{
					ceus: [
						{ceu_type_id: 1, is_checked: 0, value: 0},
						{ceu_type_id: 2, is_checked: 0, value: 0},
						{ceu_type_id: 4, is_checked: 0, value: 0},
						{ceu_type_id: 5, is_checked: 0, value: 0},
						{ceu_type_id: 6, is_checked: 0, value: 0},
						{ceu_type_id: 7, is_checked: 0, value: 0},
						{ceu_type_id: 8, is_checked: 0, value: 0},
					],
					speakerId: [],
				};
			},
			finalizeModal() {
				return document.querySelector('[data-js-id="cm-course-finalize-modal"]');
			},
			imporCeuModal() {
				return document.querySelector('[data-js-id="cm-course-import-ceu-modal"]');
			},
			denyButton() {
				return document.querySelector('[data-js-id="course-deny-button"]');
			},
			finalizeButton() {
				return document.querySelector('[data-js-id="course-finalize-button"]');
			},
			modifyModal() {
				return document.querySelector('[data-js-id="cm-course-modify-modal"]');
			},
			modifyButton() {
				return document.querySelector('[data-js-id="course-modify"]');
			},
			saveButton() {
				return document.querySelector('[data-js-id="save-course-button"]');
			},
			importButton() {
				return document.querySelector('[data-js-id="course-import-ceus-button"]');
			},
			importForm() {
				return document.querySelector('[data-js-id="cm-course-import-ceus-form"]');
			},
			modifyForm() {
				return document.querySelector('[data-js-id="cm-course-modify-form"]');
			},
			approveButton() {
				return document.querySelector('[data-js-id="course-approve-button"]');
			},
			manageAttendeesModal() {
				return document.querySelector('[data-js-id="cm-course-manage-attendees-modal"]');
			},
			datatable() {
				return document.querySelector("#course-datatable");
			}
		},
		methods: {
			openFinalizeModal() {
				$(this.finalizeModal).modal("show");
			},
			closeFinalizeModal() {
				this.hideModalForm();

				$(this.finalizeModal).modal("hide");
			},
			openCourseModifyModal(type) {
				if (type == 'add') {
					this.showModalForm();
					this.isAdd = true;
				} else {
					this.isAdd = false;
					this.hideModalForm();
				}
				$(this.modifyModal).modal("show");
			},
			openImportCeusModal() {
				$(this.imporCeuModal).modal("show");
			},
			closeImportCeuModal() {
				$(this.imporCeuModal).modal("hide");
			},
			closeCourseModifyModal() {
				this.hideModalForm();
				if (this.isAdd === false) {
					// Clear form fields
					for (const name of Object.keys(this.selectedCourse)) {
						this.selectedCourse[name] = ""
					}
				}
				document.querySelector('#course-general-tab').click();
				$(this.modifyModal).modal("hide");
			},
			finalize() {
				const finalizeBtn = this.finalizeButton;
				this.formProcessing = true;
				finalizeBtn.innerText = "Processing...";

				const vm = this;

				axios
					.get("/vpanel/modules/conference/course/finalize")
					.then(res => {
						const data = res.data;
						showNotification(data.message, data.result);

						vm.formProcessing = false;
						finalizeBtn.innerText = "Yes, Finalize these courses/speakers";

						$(vm.finalizeModal).modal("hide");
					})
					.catch(error => {
						if (error.response) {
							openApplicationErrorModal(error, "");
						}

						vm.formProcessing = false;
						finalizeBtn.innerText = "Yes, Finalize these courses/speakers";

						console.error(error);
					});
			},
			hideModalForm() {
				this.showForm = false;
				this.showLoader = true;
			},
			showModalForm() {
				this.showForm = true;
				this.showLoader = false;
			},
			importCeus() {
				const vm = this;

				$(vm.importForm).validate({
					errorPlacement: function(error, element) {
						error.addClass('text-danger');
						error.insertAfter(element);
					}
				});

				if ($(vm.importForm).valid()) {
					vm.formProcessing = true;
					vm.importButton.innerText = "Processing...";

					let formData = new FormData(vm.importForm);

					axios.post('/vpanel/modules/conference/course/import', formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					}).then(res => {
						const data = res.data;
						showNotification(data.message, data.result);

						vm.formProcessing = false;
						vm.importButton.innerText = "Import";
						vm.importForm.querySelector('[name="import_file"]').value = '';

						$(vm.imporCeuModal).modal("hide");

						$(vm.datatable).DataTable().ajax.reload();
						
						if (vm.formHasError === true) {
							vm.formHasError = false;
							vm.formErrorMessage = '';
						}
					})
					.catch(error => {
						if (error.response) {
							if (error.response.status == "422") {
								vm.formHasError = true;
								const errors 	   = error.response.data[0];
								const errorMessage = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-styled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}
								vm.formErrorMessage = errorMessage + errorsUl.outerHTML;
							} else {
								openApplicationErrorModal(error, "");
							}
						}

						vm.formProcessing = false;
						vm.importButton.innerText = "Import";

						console.error(error);
					});
				}
			},
			saveCourse() {
				const courseModifyForm = this.modifyForm;
				const courseModifyBtn  = this.saveButton;

				$(courseModifyForm).validate({
					errorPlacement: function(error, element) {
						if (element.is(":radio")) {
							error.addClass("d-flex");
							error.addClass('text-danger');
							error.prependTo(element.parents("fieldset"));
						} else {
							error.addClass('text-danger');
							error.insertAfter(element);
						}
					}
				});

				if ($(courseModifyForm).valid()) {
					if (event.target.closest(".modal").querySelector(".nav-link.active").id == "course-ceus-tab") {
						this.formProcessing = true;
						courseModifyBtn.innerText = "Processing...";

						const vm = this;
						let url;

						vm.selectedCourse.description = CKEDITOR.instances["description"].getData();

						if (vm.isAdd === true) {
							url = "/vpanel/modules/conference/course/store";
						} else {
							url = "/vpanel/modules/conference/course/update/${id}";
						}

						axios
							.post(url, this.selectedCourse)
							.then(res => {
								const data = res.data;
								showNotification(data.message, data.result);

								vm.formProcessing = false;
								courseModifyBtn.innerText = "Save";

								$(vm.modifyModal).modal("hide");

								$(vm.datatable).DataTable().ajax.reload();

								this.$emit('update-all-courses', data.record);
							})
							.catch(error => {
								if (error.response) {
									if (error.response.status == "422") {
										vm.formHasError = true;
										const errors 	   = error.response.data[0];
										const errorMessage = error.response.data.message;

										let errorsUl = document.createElement("ul");
										errorsUl.classList.add("list-styled");

										for (error in errors) {
											errorsUl.innerHTML += `<li>${errors[error]}</li>`;
										}
										vm.formErrorMessage = errorMessage + errorsUl.outerHTML;
									} else {
										openApplicationErrorModal(error, "");
									}
								}

								vm.formProcessing = false;
								courseModifyBtn.innerText = "Save";

								console.error(error);
							});
					} else {
						let currentActiveTab = event.target.closest(".modal").querySelector(".nav-link.active");

						currentActiveTab.nextElementSibling.click();
					}
				}
			},
			fetchCourseAndAttendees(id) {
				axios.get(`/vpanel/modules/conference/course/attendees/${id}`)
					.then(res => {
						this.attendeesInSelectedCourse = res.data.data.attendees
						this.currentCourse = res.data.data.course
						this.currentAgenda = res.data.data.agenda
					})
					.catch(err => console.error(err))
			},
			openManageAttendeesModal(id) {
				if (id) {
					this.currentCourseId = id
					$(this.manageAttendeesModal).modal("show");
					$(this.manageAttendeesModal).on('hidden.bs.modal', this.closeManageAttendeesModal)
					this.fetchCourseAndAttendees(id)
				}
			},
			closeManageAttendeesModal() {
				this.attendeesInSelectedCourse = []
				this.attendeesInConference = []
				this.currentCourseId = null
				this.editingAttendeeInCourse = null
				this.addingAttendeeToCourse = false
				$(this.datatable).DataTable().ajax.reload()
				$(this.manageAttendeesModal).off('hidden.bs.modal', this.closeManageAttendeesModal)
			},
			removeAttendeeFromCourse({course_id, portal_user_id, conference_uuid}) {
				this.addingAttendeeToCourse = false

				axios.post(`/vpanel/modules/conference/course/attendees/remove`, {course_id, conference_uuid, attendees: [portal_user_id]})
					.then(res => {
						this.attendeesInSelectedCourse = res.data.data
						showNotification(res.data.message, res.data.status)

						axios.get('/vpanel/modules/conference/attendees/publish-transcripts')
							.then(res2 => {
								const data2 = res2.data;
								showNotification(data2.message, data2.result);
							})
							.catch(error => {
								showNotification(error.data.message, error.data.status)
								console.error(error);
							})
					})
					.catch(err => {
						showNotification(err.data.message, err.data.status)
						console.error(err)
					})
			},
			bulkRemoveAttendees(event) {
				this.addingAttendeeToCourse = false

				let {course_id, conference_uuid} = this.attendeesInSelectedCourse[0]
				axios.post(`/vpanel/modules/conference/course/attendees/remove`, {course_id, conference_uuid, attendees: Array.from((new FormData(event.target)).keys()).map(k => Number(k.replaceAll(/\D/g, "")))})
					.then(res => {
						this.attendeesInSelectedCourse = res.data.data
						showNotification(res.data.message, res.data.status)
					})
					.catch(err => {
						showNotification(err.data.message, err.data.status)
						console.error(err)
					})
			},
			editAttendeeInCourse({portal_user_id}) {
				this.editingAttendeeInCourse = this.editingAttendeeInCourse === portal_user_id ? null : portal_user_id
				this.addingAttendeeToCourse = false
			},
			updateAttendancePercentage() {
				const {scanned_in_timestamp, scanned_out_timestamp, attendance_percentage} = this.$refs;
				let value = Math.round(
					(
						(new Date(scanned_out_timestamp[0].value)).getTime() -
						(new Date(scanned_in_timestamp[0].value)).getTime()
					) /
					(
						(new Date(this.currentAgenda.end_date_time)).getTime() -
						(new Date(this.currentAgenda.start_date_time)).getTime()
					) *
					100
				)

				value = (value >= 100 ? 100 : (value <= 0 ? 0 : value))

				attendance_percentage[0].value = value
			},
			submitEditAttendee(event) {
				const formData = new FormData(event.target)

				formData.append('course_id', this.currentCourse.course_id)
				formData.append('conference_uuid', this.currentConference.uuid)
				formData.append('portal_user_id', this.editingAttendeeInCourse)

				axios.post(
					'/vpanel/modules/conference/course/attendees/edit',
					formData
				)
					.then(res => {
						this.attendeesInSelectedCourse = res.data.data
						showNotification(res.data.message, res.data.status)

						axios.get('/vpanel/modules/conference/attendees/publish-transcripts')
							.then(res2 => {
								const data2 = res2.data;
								showNotification(data2.message, data2.result);
								this.editingAttendeeInCourse = null
							})
							.catch(error => {
								showNotification(error.data.message, error.data.status)
								console.error(error);
							})
					})
					.catch(err => {
						showNotification(err.data.message, err.data.status)
						console.error(err)
					})
			},
			addAttendeeToCourse(courseId) {
				if (this.addingAttendeeToCourse) {
					this.addingAttendeeToCourse = false
				} else {
					this.addingAttendeeToCourse = true
					this.editingAttendeeInCourse = null
					this.fetchingAttendeesInConference = true
	
					axios.get(`/vpanel/modules/conference/attendees/listForCourses/${this.currentConference.uuid}/${courseId}`)
						.then(res => this.attendeesInConference = res.data.data)
						.catch(err => console.error(err))
						.finally(_ => this.fetchingAttendeesInConference = false)
				}
			},
			submitAddAttendee(event) {
				const formData = new FormData(event.target)

				formData.append('course_id', this.currentCourse.course_id)
				formData.append('conference_uuid', this.currentConference.uuid)

				axios.post(
					'/vpanel/modules/conference/course/attendees/add',
					formData
				)
					.then(res => {
						this.attendeesInSelectedCourse = res.data.data
						showNotification(res.data.message, res.data.status)

						axios.get('/vpanel/modules/conference/attendees/publish-transcripts')
							.then(res2 => {
								const data2 = res2.data;
								showNotification(data2.message, data2.result);

								this.addingAttendeeToCourse = false
								this.courseAttendeesErrorMessage = ''
								this.courseAttendeesErrors = {}
							})
							.catch(error => {
								if (error.response) {
									this.courseAttendeesErrorMessage = err.response.data.message
									this.courseAttendeesErrors = err.response.data[0]
								}

								console.error(error);
							})
					})
					.catch(err => {
						if (err.response) {
							this.courseAttendeesErrorMessage = err.response.data.message
							this.courseAttendeesErrors = err.response.data[0]
						}
						console.error(err)
					})
			},
			handleSelectAll() {
				let allSelected = this.$refs.attendeeCheckboxes.every(i => i.checked)
				this.$refs.attendeeCheckboxes.forEach(i => i.checked = !allSelected)
				this.updateSelectAllToggle()
			},
			updateSelectAllToggle() {
				this.$refs.selectAllAttendees.indeterminate = false
				if (this.$refs.attendeeCheckboxes.every(i => i.checked)) this.$refs.selectAllAttendees.checked = true
				else if (this.$refs.attendeeCheckboxes.some(i => i.checked)) this.$refs.selectAllAttendees.indeterminate = true
				else this.$refs.selectAllAttendees.checked = false
			}
		}
	};
</script>
