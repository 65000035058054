<template>
	<div class="vp-tab-nav d-flex">
		<a
			class="nav-link"
			title="Optional Offerings Home"
			href="#"
			:class="{'current': checkIfTabIsActive('home')}"
			@click.prevent="changeActiveTab('home')"
		>Home</a>
		<a
			class="nav-link"
			title="Optional Offerings Submissions"
			href="#"
			:class="{'current': checkIfTabIsActive('subs')}"
			@click.prevent="changeActiveTab('subs')"
		>Submissions</a>
	</div>
</template>

<script>
	export default {
		props: ["appStore"],
		data() {
			return {
				store: this.appStore
			};
		},
		methods: {
			checkIfTabIsActive($tab) {
				return this.store.currentTab == $tab;
			},
			changeActiveTab($tab) {
				return (this.store.currentTab = $tab);
			}
		}
	};
</script>
