<template>
    <div>

        <SessionTimeout :sessionTimeout="session" />

    <form data-js-id="speaker-reg-form" enctype="multipart/form-data" @submit.prevent novalidate>

        <div class="alert alert-danger" v-if="formHasError" v-html="formErrorMessage"></div>

        <template v-if="formInProgress">
            <srp-contact-info-section :speaker="speaker"></srp-contact-info-section>

            <srp-speaker-info-section :speaker="speaker"></srp-speaker-info-section>

            <srp-presentation-info-section :speaker="speaker" :additional-speakers="speaker.course.additional_speakers"></srp-presentation-info-section>

            <srp-references-section :speaker="speaker"></srp-references-section>

            <srp-disclaimer-section @submit-speaker-form="saveForm($event)"></srp-disclaimer-section>
        </template>

        <srp-confirmation-section v-else></srp-confirmation-section>
    </form>
</div>
</template>

<script>
    import SessionTimeout from './SessionTimeout.vue';
    export default {
        props: [
            "forbinTestMode", "session"
        ],
        components: {
            SessionTimeout
        },
        data() {
            return {
                speaker: {
                    user: {},
                    speaker_info: {},
                    address: {},
                    course: {
                        audience_level: "Introductory",
                        panel: "No",
                        presentation_preference: 'In-person',
                        additional_speakers: [],
                    }
                },
                current_step: 1,
                formHasError: false,
                formErrorMessage: "",
                formInProgress: true
            };
        },
        computed: {
            form() {
                return document.querySelector('[data-js-id="speaker-reg-form"]');
            },
        },
        methods: {
            saveForm(event) {
                $([document.documentElement, document.body]).animate({
                    scrollTop: $("#speaker-reg-form")
                }, 3000);

                const vm = this;
                const thisEvent = event.event;
                const button = thisEvent.target;

                this.formHasError = false;
                this.formErrorMessage = "";
                let originalButtonText = button.innerHTML;

                $(this.form).validate();

                if ($(this.form).valid()) {
                    button.disabled = true;
                    button.innerHTML = "Processing...";

                    let formData = new FormData(this.form);

                    axios
                        .post("/heartland/portal/submit-speaker-registration", formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(res => {
                            button.disabled = false;
                            button.innerHTML = originalButtonText;

                            vm.formInProgress = false;
                            window.scrollTo(0, 0);
                        })
                        .catch(error => {
                            if (error.response) {
                                if (error.response.status == "422") {
                                    const errors = error.response.data.errors;
                                    const errorMessage = error.response.data.message;

                                    vm.formHasError = true;

                                    let errorsUl = document.createElement("ul");
                                    errorsUl.classList.add("list-unstyled");

                                    for (error in errors) {
                                        errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                    }

                                    vm.formErrorMessage = errorMessage + errorsUl.outerHTML;
                                } else {
                                    logApplicationError(error, "");
                                    console.error(error);
                                }
                            }

                            button.disabled = false;
                            button.innerHTML = originalButtonText;
                            window.scrollTo(0, 0);

                            console.error(error);
                        });
                }
            },
            godModeEnabled(newSpeaker) {
                this.speaker = newSpeaker;
            }
        },
    };
</script>
