<template>
	<div class="card">
		<div class="card-header">
			<p class="card-title font-weight-bold m-0">Please choose your payment method.</p>
		</div>
		<div class="card-body">
			<div class="alert alert-danger" v-if="paymentProcessError">
				<p class="m-0" v-if="fatalProcessError">
					<strong>There was an error processing your payment.</strong>
					<br />Please try again later. If you need assistance, call
					<a :href="`tel:+877-484-6901`">877-484-6901</a>
				</p>
				<p class="m-0" v-if="paymentProcessErrorMessage">{{ paymentProcessErrorMessage }}</p>
			</div>

			<div class="row">
				<div class="col-md">
					<form data-js-id="payment-form">
						<!-- Card Type -->
						<div class="form-group">
							<p class="mb-1">
								Card Type
								<span class="required">*</span>
							</p>
							<div class="custom-inline-radio-buttons">
								<fieldset>
									<legend class="sr-only">Card Type</legend>
									<div class="custom-control custom-radio custom-control-inline">
										<input
											class="custom-control-input"
											type="radio"
											name="card_type"
											required
											data-msg="Please select a card type"
											title="Card Type"
											value="Visa"
											id="card_type_visa"
											v-model="payment_method.card_type"
										/>
										<label class="custom-control-label" for="card_type_visa">Visa</label>
									</div>
									<div class="custom-control custom-radio custom-control-inline">
										<input
											class="custom-control-input"
											type="radio"
											name="card_type"
											required
											data-msg="Please select a card type"
											title="Card Type"
											value="Mastercard"
											id="card_type_mastercard"
											v-model="payment_method.card_type"
										/>
										<label class="custom-control-label" for="card_type_mastercard">Mastercard</label>
									</div>
									<div class="custom-control custom-radio custom-control-inline">
										<input
											class="custom-control-input"
											type="radio"
											name="card_type"
											required
											data-msg="Please select a card type"
											title="Card Type"
											value="Discover"
											id="card_type_discover"
											v-model="payment_method.card_type"
										/>
										<label class="custom-control-label" for="card_type_discover">Discover</label>
									</div>
									<div class="custom-control custom-radio custom-control-inline">
										<input
											class="custom-control-input"
											type="radio"
											name="card_type"
											required
											data-msg="Please select a card type"
											title="Card Type"
											value="American Express"
											id="card_type_amex"
											v-model="payment_method.card_type"
										/>
										<label class="custom-control-label" for="card_type_amex">American Express</label>
									</div>
								</fieldset>
							</div>
						</div>

						<div class="row">
							<!-- Credit Card Number -->
							<div class="form-group col-md" style="min-width: max-content">
								<label for="credit_card">
									Credit Card Number
									<span class="required">*</span>
								</label>
								<input
									class="form-control"
									type="text"
									name="credit_card"
									title="Credit Card Number"
									id="credit_card"
									v-model="payment_method.credit_card_number"
									v-mask="'#### #### #### ####'"
									required
									data-msg="Please enter a Credit card Number"
									maxlength="19"
								/>
							</div>

							<!-- CVV Code -->
							<div class="form-group col-md" style="min-width: max-content">
								<label for="cvv_code">
									CVV Code
									<span class="required">*</span>
								</label>
								<input
									class="form-control"
									type="text"
									name="cvv_code"
									title="CVV Code"
									id="cvv_code"
									v-model="payment_method.cvv_code"
									v-mask="'####'"
									required
									data-msg="Please enter a CVV Code"
									maxlength="4"
								/>
							</div>

							<!-- Expiration Date -->
							<div class="form-group col-md" style="min-width: max-content">
								<label for="expiration_date">
									Expiration Date
									<span class="required">*</span>
								</label>
								<input
									class="form-control js-flatpickr-exp-date"
									type="text"
									id="expiration_date"
									name="expiration_date"
									title="Expiration Date"
									v-model="payment_method.expiration_date"
									v-mask="'##/##'"
									data-msg-expdate="Please enter valid date"
									data-rule-expdate="true"
									placeholder="mm/yy"
									required
									data-msg-required="Please enter a date"
									maxlength="5"
								/>
							</div>
						</div>

						<div class="row">
							<!-- First Name -->
							<div class="form-group col">
								<label for="card_first_name">
									First Name
									<span class="required">*</span>
								</label>
								<input
									class="form-control"
									type="text"
									name="card_first_name"
									title="first name"
									id="card_first_name"
									v-model="payment_method.first_name"
									required
									data-msg="Please enter a first name"
									maxlength="50"
								/>
							</div>

							<!-- Last Name -->
							<div class="form-group col">
								<label for="card_last_name">
									Last Name
									<span class="required">*</span>
								</label>
								<input
									class="form-control"
									type="text"
									name="card_last_name"
									title="ast name"
									id="card_last_name"
									v-model="payment_method.last_name"
									required
									data-msg="Please enter a last name"
									maxlength="50"
								/>
							</div>
						</div>

						<div class="row">
							<div class="form-group col">
								<label for="card_address_1">
									Address
									<span class="required">*</span>
								</label>
								<input
									class="form-control"
									type="text"
									name="card_address_1"
									title="Address"
									id="card_address_1"
									v-model="payment_method.address.address_1"
									required
									data-msg="Please enter a Address"
									maxlength="30"
								/>
							</div>
							<div class="form-group col">
								<label for="card_address_2">Address 2</label>
								<input
									class="form-control"
									type="text"
									name="card_address_2"
									title="Address"
									id="card_address_2"
									v-model="payment_method.address.address_2"
									maxlength="30"
								/>
							</div>
						</div>

						<div class="row">
							<div class="form-group col">
								<label for="card_city">
									City
									<span class="required">*</span>
								</label>
								<input
									class="form-control"
									type="text"
									name="card_city"
									title="City"
									id="card_city"
									v-model="payment_method.address.city"
									required
									data-msg="Please enter a City"
									maxlength="30"
								/>
							</div>
							<div class="form-group col">
								<label for="card_state_province">
									State/Province
									<span class="required">*</span>
								</label>
								<input
									class="form-control"
									type="text"
									name="card_state_province"
									id="card_state_province"
									title="State/Province"
									v-model="payment_method.address.state_province"
									required
									data-msg="Please enter a State/Province"
									maxlength="2"
								/>
							</div>

							<div class="form-group col">
								<label for="card_postal_code">
									Postal Code
									<span class="required">*</span>
								</label>
								<input
									class="form-control"
									type="text"
									name="card_postal_code"
									id="card_postal_code"
									title="Postal Code"
									v-model="payment_method.address.postal_code"
									required
									data-msg="Please enter a postal code"
									maxlength="9"
								/>
							</div>
						</div>
					</form>
				</div>
				<div class="col-md-4">
					<div class="card">
						<div class="card-header">
							<p class="card-title font-weight-bold m-0" v-if="!promoIsApplied">Have a promo code?</p>
							<p class="card-title font-weight-bold m-0" v-else>
								Promo code: {{ promoCodeUsed }} has been applied!
								<button
									class="btn btn-danger"
									@click.prevent="removePromoCode"
								>
									<span class="fa-icon fas-close">&times;</span>
									<div class="sr-only">Remove Code</div>
								</button>
							</p>
						</div>
						<div class="card-body" v-if="!promoIsApplied">
							<div class="form-group m-0">
								<div class="alert" :class="promoCodeErrorClass" v-if="promoCodeError">{{ promoCodeError }}</div>
								<label for="promo_code">Promo Code</label>
								<div class="input-group">
									<input
										class="form-control"
										type="text"
										name="promo_code"
										id="promo_code"
										v-mask="promoCodeMask"
										title="Promo Code"
										v-model="payment_method.promo_code"
									/>
									<div class="input-group-append">
										<button
											class="btn btn-primary"
											:disabled="promoCodeValidationProcessing"
											@click="validateAndApplyPromoCode()"
										>{{ promoCodeButton }}</button>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="card">
						<div class="card-header">
							<p class="card-title font-weight-bold m-0">Your Order</p>
						</div>
						<div class="card-body">
							<div class="d-flex justify-content-between">
								<p class="m-0">
									<strong>Subtotal</strong>
								</p>
								<p class="m-0">${{ parseFloat(subtotal).toFixed(2) }}</p>
							</div>
							<div class="d-flex justify-content-between">
								<p class="m-0">
									<strong>Discount</strong>
								</p>
								<p class="m-0">${{ parseFloat(discount).toFixed(2) }}</p>
							</div>
							<div class="d-flex justify-content-between">
								<p class="m-0">
									<strong>Processing Fee</strong>
								</p>
								<p class="m-0">${{ parseFloat(total * (processingFee / 100)).toFixed(2) }}</p>
							</div>
							<div class="d-flex justify-content-between border-top mt-1 pt-1">
								<p class="m-0">
									<strong>Total</strong>
								</p>
								<p class="m-0">${{ parseFloat(total * ((processingFee / 100)+1)).toFixed(2) }}</p>
							</div>
						</div>
					</div>
					<p class="small">A {{ processingFee }}% credit card processing fee will be added to your order.</p>
					<div class="d-flex justify-content-end mt-1 mx-n1">
						<button class="btn btn-outline-primary m-1" @click="goBack()">Go Back and Edit</button>

						<button
							class="btn btn-primary m-1"
							:disabled="paymentProcessing"
							@click.prevent="processPayment()"
						>{{ confirmButtonText }}</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["goBack", "subtotal", "optionalOffering", "promoCodeType", "processingFee", "user"],
		data() {
			return {
				paymentProcessError: false,
				paymentProcessErrorMessage: "",
				confirmButtonText: "Confirm Purchase",
				paymentProcessing: false,
				promoCode: "",
				promoCodeButton: "Add Promo Code",
				promoCodeValidationProcessing: false,
				promoCodeMask: {
					mask: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC",
					tokens: {
						C: {
							pattern: /[0-9a-zA-Z]/,
							transform: v => v.toLocaleUpperCase()
						}
					}
				},
				promoCodeError: null,
				promoCodeErrorClass: "warning",
				fatalProcessError: false,
				payment_method: {
					address: {}
				},
				discount: 0,
				promo_code: ""
			};
		},
		computed: {
			total() {
				let total = parseFloat(this.subtotal) - parseFloat(this.discount);

				if (total <= 0) {
					return 0.0;
				} else {
					return total;
				}
			},
			currentSubtotal() {
				return this.subtotal;
			},
			promoDiscount() {
				return parseFloat(this.discount);
			},
			promoIsApplied() {
				return this.discount != "";
			},
			paymentForm() {
				return document.querySelector('[data-js-id="payment-form"]');
			}
		},
		methods: {
			processPayment() {
				this.paymentProcessError = false;
				this.fatalProcessError = false;
				this.paymentProcessErrorMessage = "";

				if ($(this.paymentForm).valid()) {
					this.paymentProcessing = true;
					this.confirmButtonText = "Processing...";

					let data = {
						optional_offering: this.optionalOffering,
						payment_method: this.payment_method,
						registrationTotal: this.total,
						subtotal: this.subtotal,
						discount: this.discount,
						promoCode: this.payment_method.promo_code,
						promoCodeType: this.promoCodeType,
						user: this.user
					};

					const vm = this;

					// Make request to process payment
					axios
						.post("/heartland/portal/submit-optional-offering", data)
						.then(res => {
							window.location.reload();
						})
						.catch(error => {
							if (error.response) {
								if (error.response.status == 422) {
									const data = error.response.data;

									for (const error in data.errors) {
										let prop = `${error}`;

										$(adminForm)
											.validate()
											.showErrors({
												[prop]: `${data.errors[error]}`
											});
									}
								} else if (error.response.status == 400) {
									vm.paymentProcessError = true;
									vm.paymentProcessErrorMessage = error.response.data.message;
								} else {
									vm.paymentProcessError = true;
									vm.fatalProcessError = true;
								}
							}

							vm.paymentProcessing = false;
							vm.confirmButtonText = "Confirm Purchase";

							$("html, body").animate({ scrollTop: 0 }, "fast");
						});
				} else {
					this.paymentProcessing = false;
					this.confirmButtonText = "Confirm Purchase";
				}
			},
			validateAndApplyPromoCode() {
				this.promoCodeButton = "Validating Promo Code...";
				this.promoCodeValidationProcessing = true;
				this.promoCodeError = null;

				let data = {code: this.payment_method.promo_code, subtotal: this.subtotal};

				switch (this.promoCodeType) {
					case "attendee":
						data = Object.assign(data, {number_of_attendees: 1, groups: [1,2]})
						break
					case "vendor":
						data = Object.assign(data, {groups: [3]})
						break;
					case "speaker":
						data = Object.assign(data, {groups: [4]})
						break;
				}

				// Send the validation request
				axios
					.post("/heartland/portal/validate-promo-code", data)
					.then(res => {
						this.resetPromoCodeForm();

						const data = res.data;

						if (data.result != "success") {
							this.promoCodeError = data.message;
							this.promoCodeErrorClass = `alert-${data.result}`;
						} else {
							// If valid update subtotal
							this.discount = data.discount_amount;
							this.promoCodeUsed = data.promo_code;
							this.payment_method.promo_code = data.promo_code;
							
							// Need to check balance due and disable the CC Form is balance due = 0.
							this.disableCreditCardForm();
						}
					})
					.catch(error => {
						this.resetPromoCodeForm();

						logApplicationError(error);
						console.error(error);
					});
			},
			removePromoCode() {
				this.resetPromoCodeForm();

				this.discount = 0;
                this.promoCodeUsed = "";
                this.payment_method.promo_code = "";

				this.enableCreditCardForm();
			},
			resetPromoCodeForm() {
				this.promoCodeButton = "Add Promo Code";
				this.promoCodeValidationProcessing = false;
				this.payment_method.promo_code = "";

				this.enableCreditCardForm();
			},
			disableCreditCardForm() {
				if (this.total <= 0) {
					// Lets disable the credit card form
					$('[data-js-id="payment-form"] input, [data-js-id="payment-form"] select').prop( "disabled", true );
					$('[data-js-id="payment-form"] input, [data-js-id="payment-form"] select').prop( "required", false );
				}
			},
			enableCreditCardForm() {
				if (this.total > 0) {
					// Lets enable the credit card form
					$('[data-js-id="payment-form"] input, [data-js-id="payment-form"] select').prop( "disabled", false );
					$('[data-js-id="payment-form"] input, [data-js-id="payment-form"] select').prop( "required", true );
					$("#card_address_2").prop( "required", false );
				}
			}
		},
		mounted() {
			// Add date of birth validation
			$.validator.addMethod(
				"dob",
				function(value, element) {
					let isDateFormatValid = moment(value, "MM/DD/YYYY", true).isValid();

					let isDateAfter = moment(value).isAfter("01/01/1900");

					let isAtLeast18 = moment().diff(moment(value), "years") >= 18;

					return isDateFormatValid && isDateAfter && isAtLeast18;
				},
				"Please enter valid date"
			);

			$.validator.addMethod("expdate", function(value, element) {
				let isDateFormatValid = moment(value, "MM/YY", true).isValid();

				let submittedMonth = moment(value, "MM/YY", true).month();
				let currentMonth = moment().month();

				let submittedYear = moment(value, "MM/YY", true).year();
				let currentYear = moment().year();

				let isDateAfter = false;

				let yearIsValid = submittedYear >= currentYear;

				if (yearIsValid) {
					if (submittedYear > currentYear) {
						isDateAfter = true;
					} else if (submittedMonth >= currentMonth) {
						isDateAfter = true;
					} else {
						isDateAfter = false;
					}
				} else {
					isDateAfter = false;
				}

				return isDateFormatValid && isDateAfter;
			});
		}
	};
</script>
