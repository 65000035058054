<template>
  <div>
      <progress :value="appData.current_step" max="8"></progress>
  </div>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData
        }
    }
}
</script>