<template>
    <div class="modal-body">
        <form id="vendor-golfers-form" novalidate>
            <div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>
            <input type="hidden" name="id" v-model="currentUser.id">

            <span class="text-uppercase text-primary-green font-weight-bold">Golfers</span>
            <div class="row" v-for="(golfer, index) in golfers" :key="index">
                <div class="form-group col-md">
                    <label :for="`golfer_${golfer.id}_fname`">First Name<span class="required" v-if="index == 0">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`golfer_${golfer.id}_fname`"
                        :id="`golfer_${golfer.id}_fname`"
                        title="First Name"
                        v-model="golfers[index].first_name"
                        :required="index == 0"
                    />
                </div>
                <div class="form-group col-md">
                    <label :for="`golfer_${golfer.id}_lname`">Last Name<span class="required" v-if="index == 0">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :name="`golfer_${golfer.id}_lname`"
                        :id="`golfer_${golfer.id}_lname`"
                        title="Last Name"
                        v-model="golfers[index].last_name"
                        :required="index == 0"
                    />
                </div>
            </div>

            <div class="d-flex justify-content-end m-n1">
                <button class="btn btn-outline-primary m-1" @click.prevent="closeModal()">Cancel</button>
                <button class="btn btn-primary m-1" data-js-id="golfers-modal-save-change-button" :disabled="formProcessing" @click.prevent="saveForm()">{{ saveChangesButtonText }}</button>
            </div>
        </form>
    </div>
</template>

<script>
    import { logApplicationError } from '../../../../../../core/global/functions';

	export default {
		props: [
            "user",
			"golfers",
            "closeModal",
        ],
		data() {
			return {
                currentUser: {...this.user},
                showErrorDiv: false,
                errorMessage: "",
                formProcessing: false,
                saveChangesButtonText: "Save My Changes",
			};
        },
        methods: {
            saveForm() {
                let golfers = this.golfers;
                const formData = {id: this.currentUser.id, golfers: [...golfers]};
                const vm = this;

                this.formProcessing = true;
                this.saveChangesButtonText = 'Processing...';

                this.showErrorDiv = false;
                this.errorMessage = '';

                axios.post('/portal/dashboard/vendor/update-golfer-info', formData)
                    .then(res => {
                        window.location.reload();
                    })
                    .catch(error => {
                        logApplicationError(error);
                        console.error(error);

                        if (error.response) {
                            if (error.response.status == "422") {
                                const errors = error.response.data.message;

                                let errorsUl = document.createElement('ul');
                                errorsUl.classList.add('list-unstyled');

                                for (error in errors) {
                                    errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                }

                                vm.showErrorDiv = true;
                                vm.errorMessage =  errorsUl.outerHTML;
                            } else {
                                vm.showErrorDiv = true;
                                vm.errorMessage = "There was an error updating your request, please call <a href=\"tel:877-484-6901\">877-484-6901</a> for assistance.";
                            }
                        }

                        vm.formProcessing = false;
                        vm.saveChangesButtonText = 'Save My Changes';

                        console.error(error);
                    });
            }
        }
	};
</script>

<style>
</style>