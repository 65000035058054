<template>
	<div>
		<template v-for="(course, index) in attendeeCourses">
			<div :key="index" class="pt-2">
				<div class="row">
					<p class="col-md h6">{{	course.course_title }}</p>
				</div>
				<div class="row">
					<div class="form-group col-md mt-1 mb-0">
						<div class="radio-legend d-flex justify-content-between align-items-center">
							<p class="mr-3">Course Evaluation Complete<span class="required">*</span></p>
						</div>
						<fieldset>
							<legend class="label sr-only">Course Evaluation Complete</legend>
							<div
								class="form-check form-check-inline form-check-radio"
							>
								<label class="form-check-label mr-1" :for="`evaluation_complete_yes_${index}`">
									<input
										class="form-check-input"
										type="radio"
										:name="`evaluation_complete_${index}`"
										title="Select an option"
										value="1"
										:id="`evaluation_complete_yes_${index}`"
										required
										data-msg="Please select an option"
										v-model="currentAttendee.courses[index].evaluation_complete"
									/>
									<span class="form-check-sign"></span>
									Yes
								</label>
								<label class="form-check-label" :for="`evaluation_complete_no_${index}`">
									<input
										class="form-check-input"
										type="radio"
										:name="`evaluation_complete_${index}`"
										title="Select an option"
										value="0"
										:id="`evaluation_complete_no_${index}`"
										required
										data-msg="Please select an option"
										v-model="currentAttendee.courses[index].evaluation_complete"
									/>
									<span class="form-check-sign"></span>
									No
								</label>
							</div>
						</fieldset>
					</div>
					<div class="form-group col-md attendance-percentage">
						<label :for="`attendance_percentage_${index}`">Course Attendance Percentage<span class="required">*</span></label>
						<input
							class="form-control"
							type="text"
							:name="`attendance_percentage_${index}`"
							:id="`attendance_percentage_${index}`"
							title="Course Attendence Percentage"
							required
							data-msg="Please a percentage"
							v-model="currentAttendee.courses[index].attendance_percentage"
						/>
					</div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		props: ["selectedAttendee"],
		data() {
			return {
				currentAttendee: this.selectedAttendee,
			}
		},
		computed: {
			attendeeCourses() {
				let courses = this.selectedAttendee.courses;

				if (courses.length > 0) {
					return courses.sort((a, b) => a.course_title.localeCompare(b.course_title));
				}
			}
		}
	};
</script>
<style lang="scss" scoped>
	.attendance-percentage{ margin-bottom: 1.25rem; }
</style>
