<template>
    <div class="alert alert-danger">
        There has been an error. Please try again later. If you need assistance, call
        <a :href="`tel:+${phoneNumber}`">{{phoneNumber}}.</a>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                appData: this.$root.appData
            };
        },
        props: ["phoneNumber"]
    };
</script>