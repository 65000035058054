export const dtSpeakerOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        {
            data: "name",
            width: "20%"
        },
        {
            data: "email",
            width: "20%",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "company_name",
            width: "20%",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "courses",
            width: "20%",
            render: function(data, type, row) {
                let html = '-';
                if (data.length) {
                    html = '<p class="m-0 lh-xs">';
                    data.forEach(function(course, index) {
                        html += `${course.title}`;
                        html += (index !== data.length - 1) ? ', ' : '';
                    });
                    html += '</p>'
                }
                return html;
            }
        },
        {
            data: "id",
            width: '30%',
            render: function(data, type, row) {
                let resendButton = '';
                let html = `<div class="speaker-options" data-id="${row.id}">
                            <button class="btn btn-outline-danger" data-js-id="speaker-recycle">Recycle</button>
                            <button class="btn btn-secondary-blue" data-js-id="speaker-modify">Modify</button>`;

                            if (row.password === false) {
                                resendButton = '<button class="btn btn-outline-secondary-blue ml-1" data-js-id="speaker-resend-email">Resend Profile Email</button>';
                            }
                return html + resendButton + '</div>';
            }
        }
    ]
}

