export const dtPortalAuditLogsOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    rowId: 'id',
    order: [[3, "desc"]],
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        {
            data: 'id',
            render: function (data, type, row) {
                return '<p id="portal-audit-logs-show-details"  data-id="'+ row.id +'" class="btn btn-info m-0 text-white"><span class="fas fa-chevron-down mr-1"></span>Details</p>'
            },
            "orderable": false,
            "targets": 0,
            "className": "show-details"
        },
        {
            data: 'message',
            width: "30%",
            render: function (data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        { data: "event" },
        { data: "username" },
        {
            data: "created_at",
            render: function (data, type, row) {
                return moment(data.created_at).format('M/D/YYYY h:mm A');
            }
        },
    ]
}