<template>
	<form class="modal-body booth-options-form" id="vendor-booth-options-form" novalidate>
		<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>

		
		<div v-if="!orderComplete && !showCreditCardModal">
			<table style="font-size: 14px;">
				<thead>
					<tr>
						<th></th>
						<th>Price:</th>
						<th style="width: 80px;">Color:</th>
						<th style="width: 80px;">Qty:</th>
					</tr>
				</thead>
				<tbody>
					<template
						v-for="(category, catIndex) in categories"
					>
						<tr>
							<td class="font-weight-bold pt-2" colspan="4">{{ category.name }}: {{ category.description }}</td>
						</tr>
						<tr
							v-for="(product, prodIndex) in category.products"
						>
							<td>{{ product.name + (product.description ? `: ${product.description}` : '') }}</td>
							<td>${{ product.cost }}</td>
							<td>
								<label
									class="form-group m-0"
									v-for="(productOption, poIndex) in product.product_options"
								>
									<span
										style="
											border: 0;
											clip: rect(0 0 0 0);
											height: 1px;
											margin: -1px;
											overflow: hidden;
											padding: 0;
											position: absolute;
											width: 1px;
										"
									>{{ productOption.name }}</span>
									<select
										class="form-control"
										v-model="productOption.value"
										:required="product.quantity > 0"
										@change="updateInc++"
										:name="`dropdown_${catIndex}_${prodIndex}_${poIndex}`"
									>
										<option
											v-for="option in productOption.product_options"
											:key="option.name"
											:value="option.name || ''"
										>{{option.name}}</option>
									</select>
								</label>
							</td>
							<td>
								<label class="form-group m-0">
									<span
										style="
											border: 0;
											clip: rect(0 0 0 0);
											height: 1px;
											margin: -1px;
											overflow: hidden;
											padding: 0;
											position: absolute;
											width: 1px;
										"
									>Quantity:</span>
									<input
										class="form-control"
										type="number"
										v-model="product.quantity"
										@change="quantityChanged()"
										:min="quantityRequired(catIndex, prodIndex)? 1 : 0"
										:required="quantityRequired(catIndex, prodIndex)"
										:key="`force_update_${updateInc}`"
										:name="`qty_${catIndex}_${prodIndex}`"
									>
								</label>
							</td>
						</tr>
					</template>
				</tbody>
				<tfoot class="font-weight-bold">
					<tr>
						<td colspan="4">
							<div class="mt-2 border-top pt-2"></div>
						</td>
					</tr>
					<tr>
						<td>Booth Order Total</td>
						<td>${{ orderTotal.toFixed(2) }}</td>
					</tr>
					<tr>
						<td class="pt-1">Order Difference</td>
						<td class="pt-1">${{ orderDifference.toFixed(2) }}</td>
					</tr>
				</tfoot>
			</table>

			<div class="d-flex justify-content-end">
				<button
					class="btn btn-secondary" id="close1"
					@click.prevent="closeScreenButton()"
				>Cancel</button> 
				
				<button
					class="btn btn-primary ml-1" id="next1" 
					@click.prevent="nextStepButtonClicked()"
				>Next</button>
			</div>

		</div>

		<div v-if="orderComplete && !showCreditCardModal">
			<b v-if="!showRefundMessage">Your booth options have been updated.</b>
		    <b v-if="showRefundMessage">Your booth options have been updated and our team has been notified about the price difference. We will be issuing you a refund shortly.</b>

			<button
				class="btn btn-primary" style="float: right;" id="close2" 
				@click.prevent="closeScreenButton()"
			>Close Window</button>
		</div>

		<div v-if="showCreditCardModal">
			<div class="card">
				<div class="card-header">
					<p class="card-title font-weight-bold m-0">Please choose your payment method.</p>
				</div>
				<div class="card-body">
					<div class="row">
						<div class="col-md">
							<div class="alert alert-danger" v-if="registrationProcessError">
								<p class="m-0" v-if="fatalProcessError">
									<strong>There was an error processing your registration</strong>
									<br> Please try again later. If you need assistance, call
									<a :href="`tel:+${ccInfo.phone_number}`">{{ccInfo.phone_number}}</a>
								</p>
								<p class="m-0" v-else>{{ registrationProcessErrorMessage }}</p>
							</div>

							<div v-if="showPaymentOptions">
								<div class="form-group">
									<p class="m-0">Payment Options<span class="required">*</span></p>
									<div class="custom-inline-radio-buttons">
										<fieldset>
											<legend class="sr-only">Payment Options</legend>
												<div
													class="custom-control custom-radio custom-control-inline"
												>
												<input
													class="custom-control-input"
													type="radio"
													name="payment_option"
													required
													data-msg="Please select a payment option"
													title="Pay with invoice"
													value="invoice"
													id="payment_option_invoice"
													v-model="ccInfo.payment_method.method"
												/>
												<label
													class="custom-control-label"
													for="payment_option_invoice"
												>Invoice</label>
											</div>
												<div
													class="custom-control custom-radio custom-control-inline"
												>
												<input
													class="custom-control-input"
													type="radio"
													name="payment_option"
													required
													data-msg="Please select a payment option"
													title="Pay with card"
													value="credit_card"
													id="payment_option_credit_card"
													v-model="ccInfo.payment_method.method"
												/>
												<label
													class="custom-control-label"
													for="payment_option_credit_card"
												>Credit Card</label>
											</div>
										</fieldset>
									</div>
								</div>
							</div>

							<form data-js-id="payment-form">
								<template v-if="showCreditCardInputs">
									<!-- Card Type -->

									<div class="form-group">
										<p class="m-0 required">Card Type<span class="required">*</span></p>
										<div class="custom-inline-radio-buttons">
											<fieldset>
												<legend class="sr-only">Card Type</legend>
												<div class="custom-control custom-radio custom-control-inline">
													<input
														class="custom-control-input"
														type="radio"
														name="card_type"
														required
														data-msg="Please select a card type"
														title="Card Type"
														value="Visa"
														id="card_type_visa"
														v-model="ccInfo.payment_method.card_type"
													/>
													<label class="custom-control-label" for="card_type_visa">Visa</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input
														class="custom-control-input"
														type="radio"
														name="card_type"
														required
														data-msg="Please select a card type"
														title="Card Type"
														value="Mastercard"
														id="card_type_mastercard"
														v-model="ccInfo.payment_method.card_type"
													/>
													<label class="custom-control-label" for="card_type_mastercard">Mastercard</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input
														class="custom-control-input"
														type="radio"
														name="card_type"
														required
														data-msg="Please select a card type"
														title="Card Type"
														value="Discover"
														id="card_type_discover"
														v-model="ccInfo.payment_method.card_type"
													/>
													<label class="custom-control-label" for="card_type_discover">Discover</label>
												</div>
												<div class="custom-control custom-radio custom-control-inline">
													<input
														class="custom-control-input"
														type="radio"
														name="card_type"
														required
														data-msg="Please select a card type"
														title="Card Type"
														value="American Express"
														id="card_type_amex"
														v-model="ccInfo.payment_method.card_type"
													/>
													<label class="custom-control-label" for="card_type_amex">American Express</label>
												</div>
											</fieldset>
										</div>
									</div>

									<div class="row">
										<!-- Credit Card Number -->
										<div class="form-group col-md">
											<label for="credit_card">Credit Card Number<span class="required">*</span></label>
											<input
												class="form-control"
												type="text"
												name="credit_card"
												title="Credit Card Number"
												id="credit_card"
												v-model="ccInfo.payment_method.credit_card_number"
												v-mask="'#### #### #### ####'"
												required
												data-msg="Please enter a Credit card Number"
												maxlength="19"
											/>
										</div>

										<!-- CVV Code -->
										<div class="form-group col-md">
											<label for="cvv_code">CVV Code<span class="required">*</span></label>
											<input
												class="form-control"
												type="text"
												name="cvv_code"
												title="CVV Code"
												id="cvv_code"
												v-model="ccInfo.payment_method.cvv_code"
												v-mask="'####'"
												required
												data-msg="Please enter a CVV Code"
												maxlength="4"
											/>
										</div>

										<!-- Expiration Date -->
										<div class="form-group col-md">
											<label for="expiration_date">Expiration Date<span class="required">*</span></label>
											<input
												class="form-control js-flatpickr"
												type="text"
												id="expiration_date"
												name="expiration_date"
												title="Expiration Date"
												v-model="ccInfo.payment_method.expiration_date"
												required
												v-mask="'##/##'"
												data-msg-required="Please enter a date"
												data-msg-expdate="Please enter valid date"
												data-rule-expdate="true"
												placeholder="mm/yy"
												maxlength="5"
											/>
										</div>
									</div>
								</template>

								<div class="row">
									<!-- First Name -->
									<div class="form-group col-md">
										<label for="card_first_name">First Name<span class="required">*</span></label>
										<input
											class="form-control"
											type="text"
											name="card_first_name"
											title="first name"
											id="card_first_name"
											v-model="ccInfo.payment_method.first_name"
											required
											data-msg="Please enter a first name"
											maxlength="50"
										/>
									</div>

									<!-- Last Name -->
									<div class="form-group col-md">
										<label for="card_last_name">Last Name<span class="required">*</span></label>
										<input
											class="form-control"
											type="text"
											name="card_last_name"
											title="ast name"
											id="card_last_name"
											v-model="ccInfo.payment_method.last_name"
											required
											data-msg="Please enter a last name"
											maxlength="50"
										/>
									</div>
								</div>

								<div class="row">
									<div class="form-group col-md">
										<label for="card_address_1">Address<span class="required">*</span></label>
										<input
											class="form-control"
											type="text"
											name="card_address_1"
											title="Address"
											id="card_address_1"
											v-model="ccInfo.payment_method.address.address_1"
											required
											data-msg="Please enter a Address"
											maxlength="30"
										/>
									</div>
									<div class="form-group col-md">
										<label for="card_address_2">Address 2</label>
										<input
											class="form-control"
											type="text"
											name="card_address_2"
											title="Address"
											id="card_address_2"
											v-model="ccInfo.payment_method.address.address_2"
											maxlength="30"
										/>
									</div>
								</div>

								<div class="row">
									<div class="form-group col-md">
										<label for="card_city">City<span class="required">*</span></label>
										<input
											class="form-control"
											type="text"
											name="card_city"
											title="City"
											id="card_city"
											v-model="ccInfo.payment_method.address.city"
											required
											data-msg="Please enter a City"
											maxlength="30"
										/>
									</div>
									<div class="form-group col-md">
										<label for="card_state_province">State/Province<span class="required">*</span></label>
										<input
											class="form-control"
											type="text"
											name="card_state_province"
											id="card_state_province"
											title="State/Province"
											v-model="ccInfo.payment_method.address.state_province"
											required
											data-msg="Please enter a State/Province"
											maxlength="2"
										/>
									</div>

									<div class="form-group col-md">
										<label for="card_postal_code">Postal Code<span class="required">*</span></label>
										<input
											class="form-control"
											type="text"
											name="card_postal_code"
											id="card_postal_code"
											title="Postal Code"
											v-model="ccInfo.payment_method.address.postal_code"
											required
											data-msg="Please enter a postal code"
											maxlength="9"
										/>
									</div>
								</div>
							</form>
						</div>
						<div class="col-md-4">
							<div class="card">
								<div class="card-header">
									<p class="card-title font-weight-bold m-0">Your Order</p>
								</div>
								<div class="card-body">
									<div class="d-flex justify-content-between">
										<p class="m-0"><strong>Subtotal</strong></p>
										<p class="m-0">${{ parseFloat(ccInfo.subtotal).toFixed(2) }}</p>
									</div>
									<div class="d-flex justify-content-between">
										<p class="m-0"><strong>Discount</strong></p>
										<p class="m-0">${{ parseFloat(ccInfo.promo_discount_total).toFixed(2) }}</p>
									</div>
									<div class="d-flex justify-content-between" v-if="ccInfo.payment_method.method == 'credit_card'">
										<p class="m-0"><strong>{{ processingFee }}% Processing Fee</strong></p>
										<p class="m-0">${{ parseFloat(ccInfo.total * (processingFee / 100)).toFixed(2) }}</p>
									</div>
									<div class="d-flex justify-content-between border-top mt-1 pt-1">
										<p class="m-0"><strong>Total</strong></p>
										<p class="m-0" v-if="ccInfo.payment_method.method == 'credit_card'">${{ parseFloat(ccInfo.total * ((processingFee / 100) + 1)).toFixed(2) }}</p>
                                		<p class="m-0" v-else>${{ parseFloat(ccInfo.total).toFixed(2) }}</p>
									</div>
								</div>
							</div>
							<p class="small">A {{ processingFee }}% credit card processing fee will be added to your order.</p>
							<div class="d-flex justify-content-end mt-1 mx-n1">
								<button
									class="btn btn-outline-primary m-1"
									@click="goBack()"
								>Go Back and Edit</button>

								<button
									class="btn btn-primary m-1"
									:disabled="paymentProcessing"
									@click.prevent="processPayment()"
								>{{ confirmButtonText }}</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			<button
				class="btn btn-secondary" style="float: right;" id="close3" 
				@click.prevent="closeScreenButton()"
			>Cancel</button>
		</div>
	</form>
</template>

<script>
	import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
		props: [
			"user",
			"closeModal",
			"categories",
			"company",
			"processingFee"
		],
		data() {
			return {
				updateInc: 0,
				showErrorDiv: false,
				errorMessage: "",
				oldTotal: 0,
				orderComplete: false,
				showRefundMessage: false,
				showCreditCardModal: false,

				confirmButtonText: 'Confirm Purchase',
				paymentProcessing: false,
				registrationProcessError: false,
				showPaymentOptions: false,
				showCreditCardInputs: true,
				ccInfo: {
					phone_number: '',
					payment_method: {
						method: 'credit_card',
						card_type: '',
						credit_card_number: '',
						cvv_code: '',
						expiration_date: '',
						first_name: '',
						last_name: '',
						address: {
							address_1: '',
							address_2: '',
							city: '',
							state_province: '',
							postal_code: '',
						},
					},
					subtotal: 0,
					promo_discount_total: 0,
					total: 0,
				},
				inc:0
			};
		},
		computed: {
			orderDifference() {
				const inc = this.inc
				return this.orderTotal - this.oldTotal
			},
			orderTotal() {
				const inc = this.inc
				const reduceProductTotal  = (acc, product) => acc += product.cost * Math.max(product.quantity, 0)
				const reduceCategoryTotal = (acc, category) => acc += category.products.reduce(reduceProductTotal, 0)
				return this.categories.reduce(reduceCategoryTotal, 0)
			}
		},
		methods: {
			quantityRequired(catIndex, prodIndex) {
				return [undefined, ''].every(failCondition => this.categories[catIndex].products[prodIndex]?.product_options?.[0]?.value != failCondition)
			},
			goBack() {
				this.orderComplete = false;
				this.showRefundMessage = false;
				this.showCreditCardModal = false;
			},
			nextStepButtonClicked() {
				let form = document.querySelector('#vendor-booth-options-form');
				
				try{
					$(form).validate({
						errorPlacement: function(error, element) {
							if (element.is(":radio")) {
								error.addClass("d-flex");
								error.addClass('text-danger');
								error.prependTo(element.parents("fieldset"));
							} else {
								error.addClass('text-danger');
								error.insertAfter(element);
							}
						}
					});
				} catch(e) {
					console.error(e);
				}
				
				$('[data-js-id="vendor-dashboard-booth-options-modal"]').animate({ scrollTop: 0 }, 'fast');
				if ($(form).valid()) {
					this.ccInfo.subtotal = this.orderDifference;
					this.ccInfo.total = this.orderDifference;

					switch (Math.sign(this.orderDifference)) {
						case 1: this.showCreditCardModal = true; break;
						case -1: this.processOrder(); this.showRefundMessage = true; break;
						default: this.processOrder();
					}
				} 
			},
			closeScreenButton() {
				this.orderComplete = false;
				this.showRefundMessage = false;
				this.showCreditCardModal = false;

				this.closeModal();
				
				location.reload();
			},
			quantityChanged() {
				// Recalculate the total
				this.inc++
			},
			processPayment() {
				let form = document.querySelector(`[data-js-id="payment-form"]`);

				if ($(form).valid()) {
					this.paymentProcessing = true;
					this.confirmButtonText = "Processing...";
					this.registrationProcessError = false;

					// Make request to process payment
					axios
						.post("/portal/dashboard/vendor/update-booth-options", {
							ccInfo: this.ccInfo,
							categories: this.categories,
							user: this.user,
							company: this.company,
						})
						.then(res => {
							$('html, body').animate({ scrollTop: 0 }, 'fast');
							this.paymentProcessing = false;
							this.confirmButtonText = "Confirm Purchase";
							this.orderComplete = true;
							this.showCreditCardModal = false;
						})
						.catch(error => {
							$('html, body').animate({ scrollTop: 0 }, 'fast');

							this.registrationProcessError = true;
							this.registrationProcessErrorMessage = error.response.data.message;

							logApplicationError(error);
							console.error(error);

							this.paymentProcessing = false;
							this.confirmButtonText = "Confirm Purchase";
						});
				}
			},
			processOrder() {
				axios
					.post("/portal/dashboard/vendor/update-booth-options", {
						ccInfo: this.ccInfo,
						categories: this.categories,
						user: this.user,
						company: this.company,
					})
					.then(res => {
						$('html, body').animate({ scrollTop: 0 }, 'fast');
						this.paymentProcessing = false;
						this.confirmButtonText = "Confirm Purchase";
						this.showCreditCardModal = false;
						this.orderComplete = true;
					})
					.catch(error => {
						console.error(error);
					});
			},
		},
		mounted() {
			this.oldTotal = this.orderTotal;
        }
	};
</script>