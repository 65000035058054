<script>
import { dataTableMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtDocumentCategoriesListOptions } from "./dtDocumentCategoriesListOptions";


export default {
    mixins: [ dataTableMixin ],
    props: [ 'selectedCategory' ],
    data() {
        return {
            moduleDatatableOptions: dtDocumentCategoriesListOptions
        };
    },
    mounted(){
        //add event listeners to datatable recycle/modify buttons
        const dlm       = this;
        const dataTable = dlm.$el;

        dataTable.addEventListener('click', evt => {
            let elementClicked = evt.target;
            let id             = elementClicked.parentElement.dataset.id;
            //set record id
            dlm.selectedCategory.id = id;

            switch(elementClicked.dataset.jsId){
                //modify button
                case 'document-category-modify':
                    //open modal
                    dlm.$parent.openAddModifyModal();

                    //get record data
                    axios.get(window.vpanel.document_library.category.show + `/${id}`)
                        .then(res => {
                            try {
                                let category = res.data.category;

                                //fill data
                                for(const prop in category){
                                    if(category.hasOwnProperty(prop)){
                                        let value = category[prop];
                                        dlm.selectedCategory[prop] = value;
                                    }
                                }

                                //show form
                                dlm.$parent.showAddModifyForm();
                                dlm.$parent.actionIsAdd = false;
                            } catch(error){
                                openApplicationErrorModal(error);
                                console.error(error);
                            }
                        })
                        .catch(error => {
                            openApplicationErrorModal(error);
                            console.error(error);
                        });
                break;

                //recycle button
                case 'document-category-recycle':
                    this.$parent.openRecycleModal();
                break;
            }
        });
    }
};
</script>