<template>
    <div class="doc-library-section p-0">

        <document-library-nav :app-store="store"></document-library-nav>

        <document-library-section
            v-if="store.currentTab == 'documents'"
            :crsf="crsf"
            :portal-groups="portalGroups"
            :all-categories="allUpdatedCategories"
            :current-module="currentModule"
            :ajax-route="documentsAjaxRoute"
            :portal-installed="portalInstalled"
        >
        </document-library-section>

        <document-categories-section
            v-if="store.currentTab == 'categories'"
            :crsf="crsf"
            :portal-groups="portalGroups"
            :all-documents="allUpdatedDocuments"
            :current-module="currentModule"
            :ajax-route="categoriesAjaxRoute"
            :portal-installed="portalInstalled"
        >
        </document-categories-section>
    </div>
</template>
<script>
    export default {
        props: [
            "crsf",
            "portalGroups",
            "allDocuments",
            "allCategories",
            "currentModule",
            "portalInstalled",
            "documentsAjaxRoute",
            "categoriesAjaxRoute",
        ],
        data() {
            return {
                store: {
                    currentTab: "documents"
                },
                allUpdatedDocuments: this.allDocuments,
                allUpdatedCategories: this.allCategories
            }
        },
        methods: {
            updateRecords(type, updatedRecords){
                if(type == 'documents'){
                    this.allUpdatedDocuments = updatedRecords;
                } else {
                    this.allUpdatedCategories = updatedRecords;
                }
            }
        }
    }
</script>