<template>
	<div class="card-body">
		<form action enctype="multipart/form-data" novalidate data-js-id="form-image">
			<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>

			<vendor-dashboard-profile-image-form-preview v-model="imageFile" />

			<button class="btn btn-primary-green" @click.prevent="updateImage()">Update My Profile Image</button>
		</form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				imageFile: null,
				showErrorDiv: false,
				errorMessage: false
			};
		},
		methods: {
			updateImage() {
				// Get file input
				const input = document.querySelector("#profile_image");

				const vm = this;

				const form = document.querySelector('[data-js-id="form-image"]');
				let formData = new FormData(form);

				let thisEvent = event;

				this.showErrorDiv = false;
				this.errorMessage = "";

				thisEvent.target.disabled = true;
				thisEvent.target.innerHTML = "Processing...";

				if (this.imageInputIsEmpty(input)) {
					this.showErrorDiv = true;
					this.errorMessage = "Please upload an image";

					thisEvent.target.disabled = false;
					thisEvent.target.innerHTML = "Update My Profile Image";

					return false;
				}

				if (this.inputIsNotAnImage(input)) {
					this.showErrorDiv = true;
					this.errorMessage = "Please upload an image";

					thisEvent.target.disabled = false;
					thisEvent.target.innerHTML = "Update My Profile Image";

					return false;
				}

				axios
					.post("/portal/dashboard/vendor/update-profile-image", formData, {
						headers: {
							"Content-Type": "multipart/form-data"
						}
					})
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						logApplicationError(error);
						console.error(error);

						thisEvent.target.disabled = false;
						thisEvent.target.innerHTML = "Update My Profile Image";

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								vm.showErrorDiv = true;
								vm.errorMessage = errorsUl.outerHTML;
							} else {
								vm.showErrorDiv = true;
								vm.errorMessage =
									'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
							}
						}

						console.error(error);
					});
			},
			imageInputIsEmpty(input) {
				if (input.files.length == 0) {
					return true;
				}
			},
			inputIsNotAnImage(input) {
				let acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];

    			return !acceptedImageTypes.includes(input.files[0]['type']);
			}
		}
	};
</script>

<style>
</style>