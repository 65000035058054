<template>
    <div>
        <div class="row">
            <div class="col form-group pt-1 border-top">
                <fieldset>
                    <legend class="label sr-only">CEU</legend>
                    <p class="h5">Continuing Education Credits</p>
                    <div class="row">
                        <template v-for="(ceu, index) in this.ceuTypes">
                        <div
                            class="col-md"
                            :key="index"
                        >
                            <div class="custom-control custom-switch pt-1 mr-1">
                                <input
                                    type="checkbox"
                                    class="custom-control-input js-boolean-control"
                                    :id="`ceus_${index}`"
                                    :name="`ceus_${index}`"
                                    :value="ceu.id"
                                    :checked="currentCourse.ceus[index].is_checked"
                                >
                                <label
                                    :for="`ceus_${index}`"
                                    class="custom-control-label"
                                >
                                    {{ ceu.name }}
                                </label>
                            </div>
                            <div class="form-group m-0">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label
                                        :for="`ceus_${index}_value`"
                                    >
                                        Credit Quantity
                                    </label>
                                </div>
                                <input
                                    class="form-control"
                                    type="text"
                                    :id="`ceus_${index}_value`"
                                    :name="`ceus_${index}_value`"
                                    v-model="currentCourse.ceus[index].value"
                                >
                            </div>
                        </div>
                        </template>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: [
        "ceuTypes",
        "selectedCourse"
    ],
    data() {
        return {
            currentCourse: this.selectedCourse,
        }
    }
}
</script>