<template>
    <div class="card my-2">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">Speaker Info</p>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="form-group col-lg">
                    <button type="button"  class="btn btn-primary-green upload-btn" @click="openProfileImageModal">Upload Speaker Headshot </button>
                    <input type="text" class="form-control-file" name="speaker[speaker_info][headshot]" id="headshot"  hidden />
                    <small>(optional) File must be a gif, jpg, or png</small>
                </div>
                <speaker-dashboard-profile-image-modal
                    :modal-id="profileImageModalId"
                    modal-title="Profile Image"
                    header-icon="image"
                    modal-size="md"
                >
                <speaker-dashboard-profile-image-form   :user="null" :close-modal="closeProfileImageModal">
                    </speaker-dashboard-profile-image-form>
                </speaker-dashboard-profile-image-modal>
                <div class="form-group col-md">
                    <label for="credentials">Credentials</label>
                    <input
                        class="form-control"
                        id="credentials"
                        name="speaker[speaker_info][credentials]"
                        title="Credentials"
                        type="text"
                        v-model="speaker.speaker_info.credentials"
                    />
                </div>
                <div class="form-group col-md">
                    <label for="date_of_birth">
                        Date Of Birth
                    </label>
                    <input
                        class="form-control"
                        id="date_of_birth"
                        name="speaker[user][date_of_birth]"
                        title="Date of Birth"
                        type="date"
                        v-model="speaker.user.date_of_birth"
                    />
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md">
                    <label for="biography">
                        Speaker Biography
                        <span class="required">*</span>
                    </label>
                    <textarea
                        class="form-control"
                        id="biography"
                        name="speaker[speaker_info][biography]"
                        required
                        rows="7"
                        title="Speaker Biography"
                        data-msg="Please enter the speaker's biography."
                        v-model="speaker.speaker_info.biography"
                        maxlength="5000"
                    ></textarea>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["speaker"],
        data() {
            return{
                profileImageModalId: "speaker-dashboard-update-image-modal",
            }
        },
        methods: {
            openProfileImageModal() {
                $(`[data-js-id="${this.profileImageModalId}"]`).modal("show");
            },
            closeProfileImageModal() {
                $(`[data-js-id="${this.profileImageModalId}"]`).modal("hide");
            }
        }
    };
</script>
