<template>
    <div class="card d-inline-flex" style="flex-basis: 200px;" v-if="needsGolfers || selectedSponsorships.length || productCategories.length">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">Addon Information</p>
        </div>
        <div class="d-flex flex-column" style="gap: 1.25rem; padding-block: 1.25rem">
            <div class="card-body py-0" v-if="needsGolfers">
                <p class="m-0 text-14 d-flex justify-content-between flex-wrap">
                    Attending Golf Outing?
                    <span class="text-primary font-weight-bold">{{ goingGolfingOption }}</span>
                </p>
                <div class="m-0 d-flex justify-content-between flex-wrap" v-if="needsGolfers">
                    <div class="d-flex flex-column flex-grow-1">
                        <div class="d-flex justify-content-between flex-wrap border-bottom">
                            <p class="mb-0 text-14 text-quaternary">Golfers</p>
                            <p class="mb-0 text-14 text-quaternary">Price</p>
                        </div>
                        <div class="d-flex justify-content-between flex-wrap" v-for="(golfer, index) in golfers" :key="index">
                            <p class="mb-0 text-14 font-weight-bold text-primary">{{ golfer.first_name }} {{ golfer.last_name }}</p>
                            <p class="mb-0 text-14 font-weight-bold text-primary">$75.00</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card-body py-0" v-if="selectedSponsorships.length">
                <div class="d-flex flex-column">
                    <div class="d-flex justify-content-between flex-wrap border-bottom">
                        <p class="mb-0 text-14 text-quaternary">Sponsorship Level</p>
                        <p class="mb-0 text-14 text-quaternary ml-2">Price</p>
                    </div>
                    <div class="d-flex justify-content-between flex-wrap" v-for="sponsorship in selectedSponsorships">
                        <p class="mb-0 text-14 font-weight-bold text-primary">{{sponsorship.level}}</p>
                        <p class="mb-0 text-14 font-weight-bold text-primary ml-2">${{sponsorship.cost}}</p>
                    </div>
                </div>
            </div>
    
            <div class="card-body text-14 py-0" v-for="category in productCategories">
                <p class="m-0 border-bottom text-quaternary">
                    {{ category.name }}
                    <template v-if="category.description">
                        <br>
                        <small>{{ category.description }}</small>
                    </template>
                </p>
                <table style="width: 100%">
                    <tbody>
                        <template v-for="product in category.products">
                            <tr>
                                <td colspan="2" class="font-weight-bold text-primary">{{ product.name }}{{ product.description ? ` - ${product.description}` : '' }}</td>
                                <td class="text-right font-weight-bold text-primary">${{ (parseFloat(product.cost) * product.quantity).toFixed(2) }}</td>
                            </tr>
                            <tr>
                                <template v-if="product.selected_option_label">
                                    <td>Qty: {{ product.quantity }}</td>
                                    <td>Option: {{ product.selected_option_label }} - {{ product.selected_option_value }}</td>
                                </template>
                                <template v-else>
                                    <td>Qty: {{ product.quantity }}</td>
                                </template>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData
        }
    },
    computed: {
        goingGolfingOption() {
            return this.appData.add_ons.golf_outing ? 'Yes' : 'No';
        },
        needsGolfers() {
            return this.appData.add_ons.golf_outing;
        },
        golfers() {
            return this.appData.add_ons.golfers;
        },
        selectedSponsorships() {
            return this.appData.sponsorship_packages.filter(sponsorship => this.appData.selected_sponsorship_ids.some(v => v === sponsorship.id))
        },
        productCategories() {
            return this.appData.selected_products.map((category, categoryIndex) => {
                let currentCategory = this.appData.categories_products_options[categoryIndex]
                return {
                    name: currentCategory.name,
                    description: currentCategory.description,
                    products: category.products.map((product, productIndex) => {
                        let currentProduct = currentCategory.products[productIndex]
                        let hasOption = product.product_options.length
                        let selectedOption = hasOption ? currentProduct.product_options.find(option => option.id === product.product_options[0].id) : false
                        let result = {
                            name: currentProduct.name,
                            cost: product.cost,
                            quantity: product.quantity,
                        }

                        if (currentProduct.description) {
                            result['description'] = currentProduct.description
                        }

                        if (hasOption) {
                            result['selected_option_label'] = selectedOption.name
                            result['selected_option_value'] = product.product_options[0].value
                        }

                        return result
                    }).filter(product => product.quantity)
                }
            }).filter(category => category.products.length)
        }
    }
}
</script>