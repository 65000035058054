<template>
	<div id="attendee-registration-container">
		<h2>VGM Heartland Attendee Registration Form</h2>
		<span style="font-weight: bold;">This page will timeout after 48 hours. If you are unable to submit your form before the page times out, you won't be able to submit and you'll need to start over.</span>
		<ArpApplicationErrorSection :phone-number="phoneNumber" />

		<template v-if="showRegForm">
			<ArpRegistrationProgress />
			<ArpStartSection :attendee-registration-notification-asset="attendeeRegistrationNotificationAsset" />
			<ArpAdminInfoSection :phone-number="phoneNumber" />
			<ArpMyselfReservationOptionsSection :reservation-properties="reservationProperties" />

			<ArpAttendeeReservationInformationSection
				:phone-number="phoneNumber"
				:reservation-properties="reservationProperties"
			/>

			<ArpRegistrationConfirmation />
			<ArpPaymentSection :processing-fee="processingFee" />
			<ArpPaymentConfirmation :attendee-registration-notification-asset="attendeeRegistrationNotificationAsset" />
			<ArpNoPaymentConfirmation />
		</template>

		<template v-else>
			<div class="alert alert-warning">
				Registration is now closed, please call
				<a :href="`tel:+${phoneNumber}`">{{ phoneNumber }}</a>
				if you need assistance.
			</div>
		</template>
	</div>
</template>

<script>
	import { mapActions, mapState } from 'vuex';
	export default {
		name: "ArpApplication",
		data() {
			return {
				appData: this.$root.appData
			};
		},
		props: [
			"phoneNumber",
			"reservationProperties",
			"showRegForm",
			"currentConference",
			"applyEarlyBirdDollarOff",
			"attendeeRegistrationNotificationAsset",
			"optionalOfferings",
			"initialOptionalOfferings",
			"registrationStartDate",
			"processingFee"
		],
		computed: {
			...mapState("registration", {
				reservationProps: (state) => state.appData.reservationProperties,
			})
		},
		methods: {
			...mapActions("registration", ["setReservation", "setVar", "setNestedResValue"]),
            getQueryParams() {
                const id = (new URLSearchParams(window.location.search)).get('id');

				if (id) {
					axios.get(`/heartland/portal/verify-speaker-attendee/${id}`)
						.then(res => {
							this.setVar({ob: "appData", variable: "isValidatedSpeaker", value: true})
							this.setVar({ob: "appData", variable: "current_step", value: 2})
							this.setVar({ob: "appData", variable: "showStartSection", value: false})
							this.setVar({ob: "appData", variable: "showAdminInfoSection", value: true})
							this.setNestedResValue({attribute: "user", target: "first_name", value: res.data.data.first_name})
							this.setNestedResValue({attribute: "user", target: "last_name", value: res.data.data.last_name})
							this.setNestedResValue({attribute: "user", target: "email", value: res.data.data.email})
							this.setNestedResValue({attribute: "user", target: "phone", value: res.data.data.phone})
						});
				}
            }
		},
		created() {
			this.$store.dispatch({type: 'registration/setVar', ob: "appData", variable: "reservationProperties", value: this.reservationProperties});
			this.$store.dispatch({type: 'registration/setVar', ob: "appData", variable: "showRegForm", value: this.showRegForm});
			this.$store.dispatch({type: 'registration/setVar', ob: "appData", variable: "currentConference", value: this.currentConference});
			this.$store.dispatch({type: 'registration/setVar', ob: "appData", variable: "phoneNumber", value: this.phoneNumber});
			this.$store.dispatch({type: 'registration/setVar', ob: "appData", variable: "applyEarlyBirdDollarOff", value: this.applyEarlyBirdDollarOff});
			this.$store.dispatch({type: 'registration/setVar', ob: "appData", variable: "optionalOfferings", value: this.optionalOfferings});
			this.$store.dispatch({type: 'registration/setVar', ob: "appData", variable: "registrationStartDate", value: this.registrationStartDate});
			this.$store.dispatch({type: 'registration/setVar', ob: "appData", variable: "initialOptionalOfferings", value: this.initialOptionalOfferings});
		},
		mounted() {
			setTimeout(() => alert('This page will timeout in 30 minutes!'), 171000000); // Set to 47 Hours and 30 Minutes (171000000).  1, 000 ms = 1 second
            this.getQueryParams();
			// Add date of birth validation
			$.validator.addMethod(
				"dob",
				function(value, element) {
					let isDateFormatValid = moment(value, "MM/DD/YYYY", true).isValid();

					let isDateAfter = moment(value).isAfter("01/01/1900");

					let isAtLeast18 = moment().diff(moment(value), "years") >= 18;

					return isDateFormatValid && isDateAfter && isAtLeast18;
				},
				"Please enter valid date"
			);

			$.validator.addMethod("expdate", function(value, element) {
				let isDateFormatValid = moment(value, "MM/YY", true).isValid();

				let submittedMonth = moment(value, "MM/YY", true).month();
				let currentMonth = moment().month();

				let submittedYear = moment(value, "MM/YY", true).year();
				let currentYear = moment().year();

				let isDateAfter = ((submittedYear >= currentYear) && ((submittedYear > currentYear) || (submittedMonth >= currentMonth)))

				return isDateFormatValid && isDateAfter;
			});
		}
	};
</script>
