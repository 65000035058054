<template>

	 <form class="modal-body password-criteria-form" id="attendee-dashboard-reservation-edit-form" novalidate>
		<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>
			<div class="alert alert-danger">Are you sure you want to delete this registration?</div>

		  <div class="d-flex justify-content-end m-n1">
                <button class="btn btn-outline-primary m-1" @click.prevent="closeModal()">Cancel</button>
                <button class="btn btn-primary m-1" data-js-id="contact-modal-save-change-button" :disabled="formProcessing" @click.prevent="saveForm()">{{saveChangesButton}}</button>
            </div>
	</form>
</template>

<script>
	import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
		props: {
            selectedAttendee: Object
        },
		data() {
			return {
                formData: {},
				errorMessage: "",
				showErrorDiv: false,
				formProcessing: false,
                saveChangesButton: "Yes Delete  Registration",
			};
		},
		methods: {
			saveForm() {
                const vm = this;

				this.formProcessing = true;
				this.saveChangesButton = "Processing...";

				this.showErrorDiv = false;
				this.errorMessage = "";

				this.formData = this.selectedAttendee;
				this.formData['requestIsVpanel'] = true;

				axios
					.post("/heartland/portal/cancel-registration", this.formData)
					.then(res => {
						this.formProcessing = false;

						showNotification(res.data.message, res.data.result);

                        vm.$parent.$parent.closeAttendeeDeleteModal();
						$(vm.$parent.$parent.datatable).DataTable().ajax.reload();

						this.saveChangesButton="Yes Cancel My Registration"
					})
					.catch(error => {
						this.saveChangesButton="Yes Cancel My Registration",
						logApplicationError(error);
						console.error(error);

						if (error.response) {
							if (error.response.status == "422") {
								const errors = error.response.data.message;

								let errorsUl = document.createElement("ul");
								errorsUl.classList.add("list-unstyled");

								for (error in errors) {
									errorsUl.innerHTML += `<li>${errors[error]}</li>`;
								}

								vm.showErrorDiv = true;
								vm.errorMessage = errorsUl.outerHTML;
							} else {
								vm.showErrorDiv = true;
								vm.errorMessage =
									'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
							}
						}

						vm.formProcessing = false;
						this.saveChangesButton="Yes Cancel My Registration"

						console.error(error);
					});
			},
			closeModal() {
				this.$parent.$parent.closeAttendeeDeleteModal();
			}
		},
	};
</script>