<template>
    <div>
        <div class="vp-status-bar-list">
            <p>Submitted By: <span>{{ currentOffering.user.username }}</span></p>
            <p>Submitted On: <span>{{ viewOfferingCreatedDate }}</span></p>
            <p v-if="currentOffering.order">Order Number: <span>{{ currentOffering.order.order_number }}</span></p>
        </div>

        <h5 class="m-0">{{ currentOffering.offering_definition.name }}</h5>

        <hr>
        <h5>Input<template v-if="currentOffering.submitted_inputs.length > 1">s</template></h5>
        <div class="row" v-for="(input, index) in currentOffering.submitted_inputs" :key="index">
            <div class="col">Name: {{ input.name }}</div>
            <div class="col">
                <div class="form-group">
                    <label for="quantity">Quantity</label>
                    <select
                        class="form-control"
                        name="quantity"
                        id="quantity"
                        title="Quantity"
                        required
                        data-msg="required_message"
                        v-model="currentOffering.submitted_inputs[index].quantity"
                        >
                        <option value="0">0</option>
                        <option v-for="(quantity, i) in input.quantity" :key="`quantity_${i}`"> {{ quantity }}</option>
                    </select>
                </div>
            </div>
            <div class="col">
                <template v-if="parseFloat(input.price_per_unit) > 0">
                    <p class="col-md py-1 m-0 lh-md">@ ${{ parseFloat(input.price_per_unit).toFixed(2) }}/unit</p>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'selectedOffering'
    ],
    data() {
        return {
            currentOffering: this.selectedOffering
        }
    },
    computed: {
        viewOfferingCreatedDate() {
            return moment(this.currentOffering.created_at, 'YYYY-MM-DD HH:mm:ss').format('M/D/YYYY h:mm A');
        }
    },
    methods: {
        inputLabelHtml(name, required) {
            return name + (required == 0 ? '<span class="required">*</span>' : '');
        }
    }
}
</script>
