<template>
	<div class="mt-6" id="speaker-optional-offerings">
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1">
			<em>Optional Offerings</em>
		</h4>

        <div class="card my-2" v-for="(offering, index) in optionalOfferings" :key="index">
            <div class="card-header bg-primary-green text-white">
                <p class="card-title d-flex justify-content-between align-items-center m-0">
                    {{ offering.name }}
                </p>
            </div>
            <div class="card-body">
                <div class="d-flex justify-content-between align-items-start">
                    <p class="m-0">{{ offering.description }}</p>
                    <button class="btn btn-primary" type="button" @click.prevent="openOfferingModal(index)">View</button>
                </div>
            </div>
        </div>

		<portal-dashboard-optional-offering-modal
			:modal-id="optionalOfferingModal"
			modal-title="Optional Offering"
			header-icon="stream"
			:modal-size="1000"
		>
            <div class="alert alert-danger" v-if="formProcessError">
				<p class="m-0" v-if="fatalProcessError">
					<strong>There was an error processing your </strong>
					<br />Please try again later. If you need assistance, call
					<a
						:href="`tel:+877-484-6901`"
					>877-484-6901</a>
				</p>
				<p class="m-0" v-if="formProcessErrorMessage">{{ formProcessErrorMessage }}</p>
			</div>

            <portal-dashboard-optional-offering-form
                :offering-form-id="offeringFormId"
                :offerings="optionalOfferings"
                :offering-index="offeringIndex"
                :offering="optional_offering"
                :subtotal="subtotal"
                :update-subtotal="updateSubtotal"
                :show-subtotal="hasPaymentOptions"
                v-if="showOptionalForm"
            ></portal-dashboard-optional-offering-form>

            <portal-dashboard-optional-offerings-payment-form
                v-if="showPaymentForm"
                :optional-offering="optional_offering"
                :subtotal="subtotal"
                :go-back="goBack"
                promo-code-type="speaker"
                :processing-fee="processingFee"
                :user="user"
            >
            </portal-dashboard-optional-offerings-payment-form>
            <div class="modal-footer justify-content-end" v-if="showOptionalForm">
                <button class="btn btn-primary" @click="goToPayment()" v-if="hasPaymentOptions" :disabled="!subtotal">Continue to payment</button>
                <button class="btn btn-primary" @click="submitForm()" v-if="!hasPaymentOptions" :disabled="!hasSelectedOptions">Submit form</button>
            </div>
        </portal-dashboard-optional-offering-modal>
	</div>
</template>

<script>
    import { bindOptionalOfferingInputModelObject, updateOptionalOfferingSubtotal } from '../../../portal-functions.js'

	export default {
        props: ["optionalOfferings", "processingFee", "user"],
        data() {
            return {
                optionalOfferingModal: 'optional-offering-modal',
                offeringFormId: 'offering-form',
                activeOptionalOffering: {
                    inputs: {}
                },
                optional_offering: {
                    inputs: []
                },
                showPaymentForm: false,
                showOptionalForm: true,
                subtotal: 0,
                formProcessError: false,
                formProcessErrorMessage: '',
                offeringIndex: 0,
            }
        },
        computed: {
            offeringModal() {
                return document.querySelector(`[data-js-id="${this.optionalOfferingModal}"]`);
            },
            offeringForm() {
                return document.querySelector(`[data-js-id="${this.offeringFormId}"]`);
            },
            hasPaymentOptions() {
                return !!this.optional_offering?.inputs?.some(input => parseInt(input.quantity) && parseFloat(input.price_per_unit))
            },
            hasSelectedOptions() {
                return !!this.optional_offering?.inputs?.some(input => parseInt(input.quantity))
            }
        },
        methods: {
            openOfferingModal(offeringIndex) {
                $(this.offeringModal).modal('show');

                this.offeringIndex = offeringIndex;

                let offering = this.optionalOfferings[offeringIndex]

                $(this.offeringModal).find('.form-control.valid, .form-control.error').removeClass(['valid', 'error'])

                $(this.offeringModal).modal('show');

                bindOptionalOfferingInputModelObject(offering, this.optional_offering)
            },
            goToPayment() {
                if ($(this.offeringForm).valid()) {
                    this.showPaymentForm = true;
                    this.showOptionalForm = false;
                }
            },
            goBack() {
                this.showPaymentForm = false;
                this.showOptionalForm = true;
            },
            updateSubtotal() {
                updateOptionalOfferingSubtotal(this)
            },
            submitForm() {
                // Make request to process form
                if ($(this.offeringForm).valid()) {
                    event.target.disabled = true;
                    event.target.innerText = 'Processing...';

                    axios
						.post("/heartland/portal/submit-optional-offering", {
                            optional_offering: this.optional_offering,
                            promoCodeType: 'speaker',
                            user: this.user
                        })
						.then(res => {
                            window.location.reload();
						})
						.catch(error => {
							if (error.response) {
                                const data = error.response.data

                                switch (error.response.status) {
                                    case 422:
                                        for (const error in data.errors) {
                                            let prop = `${error}`;

                                            $(this.offeringForm)
                                                .validate()
                                                .showErrors({
                                                    [prop]: `${data.errors[error]}`
                                                });
                                        }
                                        break;
                                    case 400:
                                        this.formProcessError = true;
                                        this.formProcessErrorMessage = data.message;
                                        break;
                                    default:
                                        this.formProcessError = true;
                                        this.fatalProcessError = true;
                                }
                            }

                            event.target.disabled = false;
                            event.target.innerText = 'Submit form';

							$("html, body").animate({ scrollTop: 0 }, "fast");
						});
                }
            },
        },
        mounted() {
            $(this.offeringModal).on('hidden.bs.modal', (evt) => {
                if (this.offeringForm) {
                    this.offeringForm.reset();
                    $(this.offeringForm).validate().resetForm();
                    this.subtotal = 0;
                    this.showPaymentForm = false;
                    this.showOptionalForm = true;
                }
            });
        }
	};
</script>
