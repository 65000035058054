<template>
    <vpanel-modal
        modal-id="complete-account-email"
        modal-size="lg"
        header-icon="exclamation-triangle"
        modal-title="Complete Account Email">
        <div class="modal-content">
            <div class="modal-body pb-3">
                <div class="d-flex flex-column align-items-center">
                    <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
                    <p class="h4 font-weight-bold">Are you sure you want to send out emails? </p>
                    <p class="m-0">This will send out the complete profile emails to <strong>all</strong> attendees that haven't already completed their account. </p>
					<div class="modal-footer d-flex justify-content-end mx-n1">
                        <button type="button" class="btn btn-outline-primary" @click="closeMassCompleteModal()">Cancel</button>
                        <button type="button" class="btn btn-primary ml-2" @click="sendMassCompleteEmail()">Yes, Send</button>
                    </div>
                </div>
            </div>
        <div class="modal-footer"></div>
        </div>
    </vpanel-modal>
</template>
<script>
    export default {
        computed: {
			massCompleteModal() {
				return document.querySelector('[data-js-id="complete-account-email"]');
			}
        },
        methods: {
            closeMassCompleteModal() {
				$(this.massCompleteModal).modal("hide");
			},
			sendMassCompleteEmail() {
				const vm = this;
				const button = event.target;

				let originalButtonText = event.target.innerHTML;
				button.disabled = true;
				button.innerHTML = 'Processing...';

				axios.post('/vpanel/modules/conference/attendees/send-mass-complete-account-email')
					.then(res => {
						let data = res.data;
						button.disabled = false;
						button.innerHTML = originalButtonText;
						showNotification(data.message, data.result);
                        this.closeMassCompleteModal();
					})
					.catch(error => {
						openApplicationErrorModal(error, "");
						console.error(error);

						button.disabled = false;
						button.innerHTML = originalButtonText;
					});
			}
        }
    }
</script>