export const dtOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom: "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>" +
        "<'dt-body'tr>" +
        "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    order: [[5, "desc"]],
    columns: [
        {
            data: 'company',
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "email",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "vgm_member_number",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "package_selected",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            type: "date",
            data: "invitation_date",
            render: function(data, type, row) {
                return data ? moment(data).format('MM/DD/YYYY h:mm A') : '-';
            }
        },
        {
            type: "date",
            data: "registration_date",
            render: function(data, type, row) {
                return data ? moment(data).format('MM/DD/YYYY h:mm A') : '-';
            }
        },
    ]
}
