export const dtCourseOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    order: [1, 'asc'],
    columns: [
        {
            data: 'course_id',
            render: function(data, type, row) {
                return  '<p style="display: inline-block">'+row.course_id+'</p>'
            }
        },
        {
            data: 'title_description',
            render: function(data, type, row) {
                return  '<div class="asset-details">'+
                            '<div class="asset-name-desc">'+
                                '<p class="asset-name">'+row.title+'</p>'+
                                '<div class="asset-desc">'+row.description+'</div>'+
                            '</div>'+
                        '</div>'
            }
        },
        {
            data: "speakers",
            render: function(data, type, row) {
                let html = 'N/A';
                if (data.length) {
                    html = '<p class="m-0">';
                    data.forEach(function(speaker, index) {
                        html += `${speaker.name}`;
                        html += (index !== data.length - 1) ? ', ' : '';
                    });
                    html += '</p>'
                }
                return html;
            }
        },
        {
            data: "approved",
            render: function(data, type, row) {
                return data == 1 ? '<p class="text-success m-0"><strong>Approved</strong></p>' : '<p class="text-danger m-0"><strong>Denied</strong></p>';
            }
        },
        {
            data: "attendees_attending_count",
            render: function(data, type, row) {
                return `<p style="display: inline-block">${row.attendees_attending_count}</p>`
            }
        },
        {
            data: "id",
            orderable: false,
            searchable: false,
            render: function(data, type, row) {
                return  `<div class="course-options" data-id="${row.id}" style="display: flex; flex-wrap: wrap; gap: .25rem;">
                            <button class="btn btn-secondary-blue" data-js-id="course-approve-button">Approve</button>
                            <button class="btn btn-danger" data-js-id="course-deny-button">Deny</button>
                            <button class="btn btn-outline-primary" data-js-id="course-modify">Modify</button>
                            ${row.agenda.length ? '<button class="btn btn-primary" data-js-id="course-manage-attendees">Attendees</button>' : ''}
                        </div>`;
            }
        }
    ]
}

