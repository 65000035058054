<template>
    <div class="my-n2">
        <div v-for="(reservation, index) in activeReservation.reservations" :key="index">
            <arp-reservation-list-item
                :key="index"
                :reservation="reservation"
                :attendeeIndex="index"
            ></arp-reservation-list-item>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    computed: {
        ...mapState("registration", {
            activeReservation: (state) => state.activeReservation
        })
    },
}
</script>