<template>
    <div>
        <!-- Attendee Order Items -->
        <vom-attendee-order-items
            v-if="orderType == 'attendee'"
            :order-items="selectedOrder.order_items"
            :selected-order="selectedOrder"
        ></vom-attendee-order-items>

        <!-- Vendor Order Items -->
        <vom-vendor-order-items
            v-if="orderType == 'vendor'"
            :order-items="selectedOrder.order_items"
            :selected-order="selectedOrder"
        ></vom-vendor-order-items>

        <!-- Optional Offering Order Items  -->
        <vom-optional-offering-order-items
            v-if="orderType == 'optional_offering'"
            :order-items="selectedOrder.order_items"
            :selected-order="selectedOrder"
        ></vom-optional-offering-order-items>

        <!-- Order Line Items  -->
        <template v-if="selectedOrder.order_line_items.length">
            <h6 class="border-top-1 pt-1 mt-2 mb-2">Order Line Items</h6>

            <div class="d-flex flex-wrap" style="margin: -4px;">
                <div v-for="(item, index) in selectedOrder.order_line_items" class="flex-grow-1" style="padding: 4px;">
                    <div class="card p-1 rounded h-100 m-0 d-flex flex-column justify-content-between">
                        <div class="mb-1">
                            <h6 class="m-0 d-flex align-items-center justify-content-between">{{ item.name }} <span class="badge badge-primary mb-0 ml-1" style="padding-block: 2px;" v-if="item.qty > 1">{{ item.qty }}</span></h6>
                            <small>{{ item.description }}</small>
                            <small v-for="option in item.order_line_item_options"><strong>{{ option.name }}:</strong> {{ option.value }}</small>
                        </div>
                        <p class="m-0" :key="`item_${index}`">Total <span class="font-weight-bold text-primary">${{ (parseFloat(item.cost) * item.qty).toFixed(2) }}</span></p>
                    </div>
                </div>
            </div>
        </template>

        <!-- Optional Offerings  -->
        <template v-if="optionalOfferings">
            <h6 class="border-top-1 pt-1 mt-2 mb-2">Optional Offerings</h6>

            <div v-for="(optionalOffering) in optionalOfferings" class="d-flex flex-wrap" style="margin: -4px;">
                <template v-for="(offering, offeringIndex) in optionalOffering">
                    <div v-for="(input, inputIndex) in offering.inputs" class="flex-grow-1" style="padding: 4px;">
                        <div class="card p-1 rounded h-100 m-0 d-flex flex-column justify-content-between">
                            <div class="mb-1">
                                <h6 class="m-0 d-flex align-items-center justify-content-between">{{ offering.name }} <span class="badge badge-primary mb-0 ml-1" style="padding-block: 2px;" v-if="input.quantity > 1">{{ input.quantity }}</span></h6>
                                <small>{{ offering.description }}</small>
                                <small><strong>{{ input.name }}:</strong>{{ getAdditionalInputItems(input) ? ` ${getAdditionalInputItems(input)}` : '' }}</small>
                            </div>
                            <p class="m-0" :key="`item_${offeringIndex}_${inputIndex}`">Total <span class="font-weight-bold text-primary">${{ (parseFloat(input.price_per_unit) * input.quantity).toFixed(2) }}</span></p>
                        </div>
                    </div>
                </template>
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        props: ["selectedOrder"],
        computed: {
            orderType() {
                return this.selectedOrder.order_type;
            },
            optionalOfferings() {
                let orderItems = this.selectedOrder.order_items
                let multipleOfferings = [];

                if (orderItems.length)
                {
                    orderItems.forEach((item) => {
                        let offering = item.optional_offerings ? item.optional_offerings : false;
                        if (offering)
                        {
                            multipleOfferings.push(offering);
                        }
                    });
                }
                else if (orderItems && orderItems['id'] > 0)
                {
                    multipleOfferings.push(orderItems);
                }
                return multipleOfferings.length ? multipleOfferings : false
            }
        },
        methods: {
            getAdditionalInputItems(input) {
                if (!input.additional_input_label && !input.additional_input_value.length) return false
                return [input.additional_input_label, `(${input.additional_input_value.join(', ')})`].join(' - ');
            }
        }
    }
</script>
