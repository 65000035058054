<template>
	<div>
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1"><em>My Profile</em></h4>
        <!-- Contact Info -->
        <div class="card my-2">
            <portal-dashboard-toggle-bar
                toggle-bar-text="Contact Information"
                @click.native="toggleContactInfo()"
                :is-toggled="showContactInfo"
            ></portal-dashboard-toggle-bar>
            <div class="card-body d-flex justify-content-between align-items-start" v-if="showContactInfo">
                <div class="my-n2">
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Name on record</small>
                        {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm" v-if="user.title">
                        <small class="text-uppercase text-primary-green font-weight-bold">Title</small>
                        {{ user.title }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Company Name</small>
                        {{ company.name }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Address</small>
                        {{ address }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Email</small>
                        {{ user.email }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Phone</small>
                        {{ user.phone }}
                    </p>
                </div>

                <button class="btn btn-primary" v-if="isAfterBadgeCutoff == 0" @click="openEditContactModal()">Edit</button>
            </div>

        <admin-dashboard-contact-info-modal
            :modal-id="contactInfoModalId"
            modal-title="Edit Contact Information"
            header-icon="user"
            modal-size="lg">

            <admin-dashboard-contact-form
                :user="user"
                :company="company"
                :close-modal="closeEditContactModal">
            </admin-dashboard-contact-form>
        </admin-dashboard-contact-info-modal>
        </div>

        <!-- User ID and Password -->
        <div class="card my-2">
            <portal-dashboard-toggle-bar
                toggle-bar-text="User ID &amp; Password"
                @click.native="toggleUserIdAndPasswordInfo()"
                :is-toggled="showUserIdAndPasswordInfo"
            ></portal-dashboard-toggle-bar>
            <div class="card-body d-flex justify-content-between align-items-start" v-if="showUserIdAndPasswordInfo">
                <div class="my-n2">
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Username</small>
                        {{ user.username }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Password</small>
                        ⚹⚹⚹⚹⚹⚹⚹⚹
                    </p>
                </div>

                <button class="btn btn-primary" @click="openEditPasswordModal()">Edit</button>
            </div>

            <attendee-dashboard-user-id-password-modal
                :modal-id="userIdAndPasswordModalId"
                modal-title="Change Password"
                header-icon="lock"
                modal-size="lg">

                <attendee-dashboard-user-id-password-form
                    :user="user"
                    :close-modal="closeEditPasswordModal">
                </attendee-dashboard-user-id-password-form>
            </attendee-dashboard-user-id-password-modal>
        </div>

        <!-- Registration Info -->
        <div class="card my-2">
            <portal-dashboard-toggle-bar
                toggle-bar-text="Registration Information"
                @click.native="toggleRegistrationInfo()"
                :is-toggled="showRegistrationInfo"
                v-if="hasReservationData"
            ></portal-dashboard-toggle-bar>
            <div class="card-body d-flex justify-content-between align-items-start" v-if="showRegistrationInfo">
                <div class="my-n2">
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Conference Pass</small>
                        {{ getConferencePassOption }}
                    </p>

                    <p class="my-2 d-flex flex-column lh-sm" v-if="getConferencePassOption == 'Live At Home Training' && getLAHPassSessionOption.length">
                        <small class="text-uppercase text-primary-green font-weight-bold">Live At Home Training Sessions Selected</small>
                        <span v-for="(session, index) in getLAHPassSessionOption" :key="index">
                            {{ session.name }}
                        </span>
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm" v-if="getConferencePassOption == 'Virtual Pass'">
                        <small class="text-uppercase text-primary-green font-weight-bold">Virtual Pass</small>
                        {{ getOneDayPassOption }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm" v-if="getConferencePassOption == 'Tech Training'">
                        <small class="text-uppercase text-primary-green font-weight-bold">Tech Training</small>
                        {{ getConferencePassOption }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm" v-if="reservationIsGolfing">
                        <small class="text-uppercase text-primary-green font-weight-bold">Golf Outing</small>
                        Attending
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm" v-if="reservationNeedsRentalClubs">
                        <small class="text-uppercase text-primary-green font-weight-bold">Golf Club Rental</small>
                        {{ getGolfClubRentalOption }}
                    </p>

                    <template v-if="reservationHasAdditionalOptions">
                        <p class="my-2 d-flex flex-column lh-sm">
                            <small class="text-uppercase text-primary-green font-weight-bold">Additional Options</small>
                            <span v-for="(option, index) in getAdditionalOptions" :key="index">
                                {{ option.name }}
                                <template v-if="option.name == 'Spouse/Guest'"> - {{ getSpouseGuestName }}</template>
                                <br />
                            </span>
                        </p>
                    </template>

                    <template v-if="reservation.reservation_options.Dietary">
                        <p class="my-2 d-flex flex-column lh-sm">
                            <small class="text-uppercase text-primary-green font-weight-bold">Dietary Options</small>
                            <span v-for="(option, index) in reservation.reservation_options.Dietary.options_selected" :key="index">
                                {{ option[0].name }}
                                <!-- <template v-if="option.name == 'Spouse/Guest'"> - {{ getSpouseGuestName }}</template> -->
                                <br />
                            </span>
                        </p>
                          <button class="btn btn-primary" v-if="isAfterBadgeCutoff == 0" @click="openEditDietModal()">edit dietary</button>
                    </template>
                </div>
                <button class="btn btn-warning" v-if="isAfterBadgeCutoff == 0" @click="openEditReservationModal()">cancel registration</button>
            </div>

            <div class="card-body border-top" v-if="showRegistrationInfo && showPlatinumRegistrationOptions">
                <!-- Platinum Options -->
                <p class="pb-1 m-0 text-uppercase font-weight-bold text-dark lh-sm">Platinum Member Options</p>
                <div class="my-n2">
                    <div v-if="showPlatinumRegistrationOptions">
                        <!-- If arriving on sunday, what activity? -->
                        <p class="my-2 d-flex flex-column lh-sm" v-if="reservationArrivingSunday">
                            <small class="text-uppercase text-primary-green font-weight-bold">Sunday Activity</small>
                            {{ getSundayActivityOption }}
                        </p>

                        <!-- If sunday activity is golfing and attendee is renting, which club type? -->
                        <p class="my-2 d-flex flex-column lh-sm" v-if="reservationSundayActivityIsGolfing && reservationNeedsGolfClubsAtSunnyside">
                            <small class="text-uppercase text-primary-green font-weight-bold">Golf Club Rental Option</small>
                            {{ getGolfClubRentalOptionAtSunnyside }}
                        </p>

                        <!-- If arriving on sunday, is attendee attending dinner? -->
                        <p class="my-2 d-flex flex-column lh-sm" v-if="reservationArrivingSunday">
                            <small class="text-uppercase text-primary-green font-weight-bold">Attending Dinner?</small>
                            {{ getAttendingDinnerOption }}
                        </p>

                        <!-- If attending dinner and bringing a guest, what is their name? -->
                        <p class="my-2 d-flex flex-column lh-sm" v-if="reservationAttendingDinner && reservationHasDinnerGuest">
                            <small class="text-uppercase text-primary-green font-weight-bold">Dinner Guest</small>
                            {{ getDinnerGuestName }}
                        </p>

                        <!-- Is the attendee attending the monday meeting? -->
                        <p class="my-2 d-flex flex-column lh-sm" v-if="reservationAttendingMondayMeeting">
                            <small class="text-uppercase text-primary-green font-weight-bold">Attending Monday Meeting</small>
                            {{ getMondayMeetingAttendance }}
                        </p>

                        <!-- Does attendee need a hotel? -->
                        <p class="my-2 d-flex flex-column lh-sm" v-if="reservationNeedsHotel">
                            <small class="text-uppercase text-primary-green font-weight-bold">Hotel Room?</small>
                            {{ getHotelRoomOption }}
                        </p>

                        <!-- What are the details regarding the hotel reservation? -->
                        <p class="my-2 d-flex flex-column lh-sm" v-if="reservationNeedsHotel">
                            <small class="text-uppercase text-primary-green font-weight-bold">Hotel Room Reservation Notes</small>
                            {{ getHotelRoomReservationNotes }}
                        </p>
                    </div>
                </div>
            </div>

            <attendee-dashboard-registration-modal
                :modal-id="attendeeDashboardRegistrationModalId"
                modal-title="Edit Registration"
                header-icon="user"
                modal-size="lg"
            >
                <attendee-dashboard-reservation-edit-form
                    :reservation="reservation"
                    :user="user"
                    :company="company"
                    :order="orders"
                    :isAdmin = false
                    :close-modal="closeReservationEditModal"
                ></attendee-dashboard-reservation-edit-form>
            </attendee-dashboard-registration-modal>
            
            <attendee-dashboard-diet-modal
                :modal-id="attendeeDietModalId"
                modal-title="Edit Dietary"
                header-icon="user"
                modal-size="lg"
            >
                <attendee-dashboard-diet-form
                    :reservation="reservation"
                    :props="props"
                    :user="user"
                    :company="company"
                    :order="orders"
                    :isAdmin = false
                    :close-modal="closeReservationEditModal">
                </attendee-dashboard-diet-form>
            </attendee-dashboard-diet-modal>
        </div>

        <!-- Certifications -->
        <div class="card my-2">
            <portal-dashboard-toggle-bar
                toggle-bar-text="Certifications"
                @click.native="toggleCertifications()"
                :is-toggled="showCertifications"
            ></portal-dashboard-toggle-bar>
            <div class="card-body d-flex justify-content-between align-items-start" v-if="showCertifications">
                <div class="my-n2">
                    <p class="my-2 d-flex flex-column lh-sm" v-for="(cert, index) in certifications" :key="index">
                        <small class="text-uppercase text-primary-green font-weight-bold">{{ cert.name }}</small>
                        {{ user.certifications[cert.id] || 'Not Provided' }}

                    </p>
                    <p>VGM Heartland will provide any transcripts directly to the certification bodies for those users with valid certification numbers supplied.</p>
                </div>

                <button class="btn btn-primary" @click="openCertificationsModal()">Edit</button>
            </div>

            <attendee-dashboard-certifications-modal
                :modal-id="certificationsModalId"
                modal-title="Update Certifications"
                header-icon="stamp"
                modal-size="lg"
            >
                <attendee-dashboard-certifications-form
                    :close-modal="closeCertificationsModal"
                    :certifications="certifications"
                    :user="user"
                ></attendee-dashboard-certifications-form>
            </attendee-dashboard-certifications-modal>
        </div>

		<!-- Order Info -->
        <div class="card my-2" v-if="orders.length">
            <portal-dashboard-toggle-bar
                toggle-bar-text="Order Info"
                @click.native="toggleOrderInfo()"
                :is-toggled="showOrderInfo"
            ></portal-dashboard-toggle-bar>
            <template v-for="(order, order_index) in orders">
                <div class="card-body d-flex justify-content-between align-items-start" :class="{'border-top': order_index > 0}" v-if="showOrderInfo" :key="order_index">
                    <div class="orders-section">
                        <div class="order-info">
                            <div class="oi-detail border-0 p-0">
                                <p class="oi-key">Order Date:</p>
                                <p class="oi-value">{{ formatOrderDate(order.created_at) }}</p>
                            </div>
                            <div class="oi-detail">
                                <p class="oi-key">Order Number:</p>
                                <p class="oi-value">#{{ order.order_number }}</p>
                            </div>
                            <div class="oi-detail">
                                <p class="oi-key">Billing Info:</p>
                                <div class="oi-value">
                                    <p class="m-0">{{ ifUseElseNot(order.billing_first_name) }} {{ ifUseElseNot(order.billing_last_name) }}</p>
                                    <p class="m-0">{{ ifUseElseNot(order.billing_address_1) }} {{ ifUseElseNot(order.billing_address_2) }}</p>
                                    <p class="m-0">{{ ifUseElseNot(order.billing_city) }}, {{ ifUseElseNot(order.billing_state_province) }} {{ ifUseElseNot(order.billing_postal_code) }}</p>
                                    <p class="m-0" v-if="order.vendor_details">VGM #: {{ ifUseElseNot(order.vendor_details.vgm_member_number) }}</p>
                                </div>
                            </div>

                            <template v-if="order.attendee_details.length">
                                <div class="oi-detail" v-for="(attendee, attendee_index) in order.attendee_details" :key="attendee_index">
                                    <p class="oi-key flex-grow-1">Attendee #{{ attendee_index + 1 }}</p>
                                    <div class="oi-value ml-2">
                                        <div class="d-flex justify-content-between">
                                            <p class="my-0 font-weight-bold">Option</p>
                                            <p class="my-0 font-weight-bold ml-2">Choice</p>
                                        </div>
                                        <div class="d-flex justify-content-between" v-for="(option, propertyName, option_index) in attendee" :key="option_index">
                                            <p class="m-0 font-weight-bold">{{ propertyName }}</p>
                                            <p class="my-0 ml-2">{{ option.value }}</p>
                                        </div>
                                    </div>
                                    <div class="oi-value ml-2">
                                        <div class="d-flex justify-content-between">
                                            <p class="my-0 font-weight-bold text-left">Price</p>
                                        </div>
                                        <div v-for="(option, option_price_index) in attendee" :key="option_price_index">
                                            <p class="m-0 text-left">{{ option.price ? `$${option.price}` : '-' }}</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            
                            <template v-if="order.offering_detail">
                                <div class="oi-detail">
                                    <p class="oi-key flex-shrink-0">Additional Offering:</p>
                                    <div class="oi-value ml-2 text-left">
                                        <p class="mt-0 font-weight-bold mb-1">{{ order.offering_detail.name }}</p>
                                        <p class="mt-0 mb-1 pb-1 border-bottom">{{ order.offering_detail.description }}</p>
                                        <template v-for="(input, index) in order.offering_detail.inputs">
                                            <template v-if="input.quantity > 0">
                                                <p class="mt-0 mb-1 pb-1" v-if="input.additional_input_label && input.additional_input_value.length">
                                                    {{ input.additional_input_label }}: {{ input.additional_input_value.join(', ') }}
                                                </p>
                                                <p class="my-0 text-right">
                                                    {{ input.name }}(${{ parseFloat(input.price_per_unit).toFixed(2) }})
                                                    <span class="fas mx-1 fa-times"></span>
                                                    {{ input.quantity }}
                                                    <span class="fas mx-1 fa-equals"></span>
                                                    ${{ parseFloat(input.line_item_total).toFixed(2) }}
                                                </p>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </template>

                            <portal-dashboard-itemized-receipt :order="order" />

                            <div v-if="!('offering_detail' in order) && optionalOfferingsSansAdditionalOrderOfferings.length">
                                <p class="oi-key flex-shrink-0 mb-0 mt-2">Optional Offerings:</p>
                                <div class="ml-2 mb-2 pb-2 border-bottom" style="font-family: monospace; color: #767676; font-size: 12px" v-for="offering in optionalOfferingsSansAdditionalOrderOfferings">
                                    <p class="m-0"><strong>{{ offering.name }}</strong> - {{ offering.description }}</p>
                                    <template v-for="input in offering.inputs">
                                        <div class="oi-detail border-0 p-0" >
                                            <div class="oi-value text-left">
                                                {{ input.name }} (${{ parseFloat(input.price_per_unit).toFixed(2) }})
                                                {{ input.quantity > 1 ? `x${input.quantity}` : '' }}
                                            </div>
                                            <p class="oi-value" >
                                                ${{ parseFloat(input.line_item_total || 0).toFixed(2) }}
                                            </p>
                                        </div>
                                        <p class="m-0" v-if="input.additional_input_label && input.additional_input_value.length">{{ input.additional_input_label }}: {{ input.additional_input_value.join(', ') }}</p>
                                    </template>
                                </div>
                            </div>

                            <div class="align-self-end d-flex flex-column align-items-stretch mt-2 border-top-2 border-wcag-grey">
                                <div class="oi-detail">
                                    <p class="oi-key">Subtotal:</p>
                                    <p class="oi-value ml-2">{{ `$${order.subtotal}` }}</p>
                                </div>
                                <div class="oi-detail" v-if="parseFloat(order.processing_fee)">
                                    <p class="oi-key">Processing Fee:</p>
                                    <p class="oi-value ml-2">{{ order.processing_fee ? `$${order.processing_fee}` : '' }}</p>
                                </div>
                                <div class="oi-detail" v-if="parseFloat(order.discount)">
                                    <p class="oi-key">Discount:</p>
                                    <p class="oi-value ml-2">{{ order.discount ? `-$${order.discount}` : '' }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Total:</p>
                                    <p class="oi-value ml-2">{{ `$${order.total}` }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
	</div>
</template>

<script>
import AttendeeDashboardDietModal from './modals/AttendeeDashboardDietModal.vue'
import AttendeeDashboardDietForm from './forms/AttendeeDashboardDietForm.vue'
	export default {
        components: {AttendeeDashboardDietForm, AttendeeDashboardDietModal},
        props: [
            'user',
            'company',
            'isAfterBadgeCutoff',
            "reservation",
            "props",
            "certifications",
            "orders"
        ],
        data() {
            return {
                contactInfoModalId: 'admin-dashboard-edit-info-modal',

                userIdAndPasswordModalId: 'admin-dashboard-user-id-password-modal',
                certificationsModalId: 'attendee-dashboard-certifications-modal',
                attendeeDashboardRegistrationModalId: 'attendee-dashboard-registration-modal',
                attendeeDietModalId: 'attendee-diet-modal',

                showContactInfo: false,
                showUserIdAndPasswordInfo: false,
                showRegistrationInfo: false,
                showCertifications: false,
                showOrderInfo: false,
                hasReservationData: false
            }
        },
        computed: {
            showPlatinumRegistrationOptions() {
				return (
					this.company.is_valid_vgm_member && this.company.is_platinum_vgm_member
				);
			},
            address() {
                if (this.user.address) {
                    return `${this.user.address.address_1  || ''} ${this.user.address.address_2  || ''} ${this.user.address.city  || ''}, ${this.user.address.state_province  || ''} ${this.user.address.postal_code  || ''}`;
                } else {
                    return `${this.company.address_1  || ''} ${this.company.address_2  || ''} ${this.company.city  || ''}, ${this.company.state_province  || ''} ${this.company.postal_code  || ''}`;
                }
            },
            getConferencePassOption() {
				return this.reservation.reservation_options["Conference Pass Options"]
					? this.reservation.reservation_options["Conference Pass Options"].value
					: "";
			},
			getGolfOutingOption() {
				return this.reservation.reservation_options["Golf Outing"]
					? this.reservation.reservation_options["Golf Outing"].value
					: "";
			},
			getGolfClubRentalNeededOption() {
				return this.reservation.reservation_options["Golf Club Rental"]
					? this.reservation.reservation_options["Golf Club Rental"].value
					: "";
			},
			getGolfClubRentalOption() {
				return this.reservation.reservation_options["Golf Club Rental Options"]
					? this.reservation.reservation_options["Golf Club Rental Options"].value
					: "";
			},
			getAdditionalOptions() {
				return this.reservation.reservation_options["Additional Options"]
					? this.reservation.reservation_options["Additional Options"].options_selected
					: "";
			},
			getSpouseGuestName() {
				return this.reservation.reservation_options["Spouse/Guest Name"]
					? this.reservation.reservation_options["Spouse/Guest Name"].value
					: "";
			},
			getTechTrainingOption() {
				return this.reservation.reservation_options["Tech Training Level"]
					? this.reservation.reservation_options["Tech Training Level"].value
					: "";
			},
			getOneDayPassOption() {
				return this.reservation.reservation_options["1-Day Pass Date"]
					? this.reservation.reservation_options["1-Day Pass Date"].value
					: "";
			},
			getLAHPassSessionOption() {
				return this.reservation.reservation_options["Live At Home Training Sessions"]
					? this.reservation.reservation_options["Live At Home Training Sessions"].options_selected
					: [];
			},
			getSundayArrivalOption() {
				return this.reservation.reservation_options["Sunday Arrival"]
					? this.reservation.reservation_options["Sunday Arrival"].value
					: "";
			},
			getSundayActivityOption() {
				return this.reservation.reservation_options["Sunday Arrival Options"]
					? this.reservation.reservation_options["Sunday Arrival Options"].value
					: "";
			},
			getGolfClubRentalAtSunnyside() {
				return this.reservation.reservation_options["Golf at Sunnyside Club Rental"]
					? this.reservation.reservation_options["Golf at Sunnyside Club Rental"].value
					: "";
			},
			getGolfClubRentalOptionAtSunnyside() {
				return this.reservation.reservation_options[
					"Golf at Sunnyside Club Rental Options"
				]
					? this.reservation.reservation_options[
							"Golf at Sunnyside Club Rental Options"
					  ].value
					: "";
			},
			getAttendingDinnerOption() {
				return this.reservation.reservation_options["Group Dinner"]
					? this.reservation.reservation_options["Group Dinner"].value
					: "";
			},
			getDinnerGuestOption() {
				return this.reservation.reservation_options["Spouse/Guest for Dinner"]
					? this.reservation.reservation_options["Spouse/Guest for Dinner"].value
					: "";
			},
			getDinnerGuestName() {
				return this.reservation.reservation_options["Spouse/Guest Name for Dinner"]
					? this.reservation.reservation_options["Spouse/Guest Name for Dinner"].value
					: "";
			},
			getDinnerGuestName() {
				return this.reservation.reservation_options["Spouse/Guest Name for Dinner"]
					? this.reservation.reservation_options["Spouse/Guest Name for Dinner"].value
					: "";
			},
			getMondayMeetingAttendance() {
				return this.reservation.reservation_options["Monday Meeting Attendance"]
					? this.reservation.reservation_options["Monday Meeting Attendance"].value
					: "";
			},
			getHotelRoomReservationNotes() {
				return this.reservation.reservation_options["Hotel Room Reservation Notes"]
					? this.reservation.reservation_options["Hotel Room Reservation Notes"].value
					: "";
			},
			getHotelRoomOption() {
				return this.reservation.reservation_options["Hotel Room"]
					? this.reservation.reservation_options["Hotel Room"].value
					: "";
			},
			reservationIsGolfing() {
				return this.getGolfOutingOption == "Yes";
			},
			reservationNeedsRentalClubs() {
				return this.getGolfClubRentalNeededOption == "Yes";
			},
			reservationHasAdditionalOptions() {
				return this.getAdditionalOptions.length;
			},
			reservationArrivingSunday() {
				return this.getSundayArrivalOption == "Yes";
			},
			reservationSundayActivityIsGolfing() {
				return this.getSundayActivityOption == "Golf";
			},
			reservationNeedsGolfClubsAtSunnyside() {
				return this.getGolfClubRentalAtSunnyside == "Yes";
			},
			reservationAttendingDinner() {
				return this.getAttendingDinnerOption == "Yes";
			},
			reservationHasDinnerGuest() {
				return this.getDinnerGuestOption == "Yes";
			},
			reservationAttendingMondayMeeting() {
				return this.getDinnerGuestOption == "Yes";
			},
			reservationNeedsHotel() {
				return this.getHotelRoomOption == "Yes";
			},
            optionalOfferingsSansAdditionalOrderOfferings() {
                let newOfferings = []
                let additionalOrderOfferings = this.orders.reduce((acc,cur) => {
                    if ('offering_detail' in cur) acc.push(cur.offering_detail)
                    return acc
                }, [])

                // Sometimes this.reservation.optional_offerings is null and the forEach bombs.
                if (this.reservation?.optional_offerings) { 
                    this.reservation.optional_offerings.forEach(offering => {
                        let match = additionalOrderOfferings.some(additionalOrderOffering => _.isEqual(offering, additionalOrderOffering))
                        if (match) return
                        newOfferings.push(offering)
                    })
                }

                return newOfferings
            }
        },
        methods: {
            formatOrderDate(date) {
                return moment(date).format('MM/DD/YYYY');
            },
            getVendorOrderPackage(order) {
                return order.vendor_details.package_info.name;
            },
            getVendorOrderPackagePrice(order) {
                return `$${order.vendor_details.package_info.base_package_price}`;
            },
            openEditReservationModal() {
                $(`[data-js-id="${this.attendeeDashboardRegistrationModalId}"]`).modal('show');
            },
            closeReservationEditModal() {
                $(`[data-js-id="${this.attendeeDashboardRegistrationModalId}"]`).modal('hide');
            },
            openEditDietModal() {
                $(`[data-js-id="${this.attendeeDietModalId}"]`).modal('show');
            },
            closeEditDietModal() {
                $(`[data-js-id="${this.attendeeDietModalId}"]`).modal('hide');
            },
            openEditContactModal() {
                $(`[data-js-id="${this.contactInfoModalId}"]`).modal('show');
            },
            closeEditContactModal() {
                $(`[data-js-id="${this.contactInfoModalId}"]`).modal('hide');
            },
            openEditPasswordModal() {
                $(`[data-js-id="${this.userIdAndPasswordModalId}"]`).modal('show');
            },
            closeEditPasswordModal() {
                $(`[data-js-id="${this.userIdAndPasswordModalId}"]`).modal('hide');
            },
            openCertificationsModal() {
                $(`[data-js-id="${this.certificationsModalId}"]`).modal('show');
            },
            closeCertificationsModal() {
                $(`[data-js-id="${this.certificationsModalId}"]`).modal('hide');
            },
            toggleContactInfo() {
                this.showContactInfo = !this.showContactInfo;
            },
            toggleUserIdAndPasswordInfo() {
                this.showUserIdAndPasswordInfo = !this.showUserIdAndPasswordInfo;
            },
            toggleRegistrationInfo() {
                this.showRegistrationInfo = !this.showRegistrationInfo;
		    },
            toggleCertifications() {
                this.showCertifications = !this.showCertifications;
            },
            toggleOrderInfo() {
                this.showOrderInfo = !this.showOrderInfo;
            },
            ifUseElseNot(value) {
                return value ? value : '';
            },
        },
        mounted() {
            if (this.reservation) {
                this.hasReservationData = true;
            }
        }
    };
</script>