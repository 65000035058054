<template>
	<div v-if="appData.showRegistrationConfirmation">
		<div v-if="attendeeRegistrationNotificationAsset" v-html="attendeeRegistrationNotificationAsset.html"></div>
		<div class="card">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Please confirm your registration details</p>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col">
						<arp-reservation-list></arp-reservation-list>
					</div>
					<div class="col">
						<div class="card">
							<div class="card-header">
								<p class="card-title font-weight-bold m-0" v-if="!getPromoDiscount">Have a promo code?</p>
								<p
									class="card-title font-weight-bold m-0 d-flex justify-content-between align-items-center"
									v-else
								>
									Promo code: {{ activeReservation.promoCodeUsed }} has been applied!
									<button class="btn btn-danger" @click.prevent="removePromoCode">
										<span class="fa-icon fas-close">&times;</span>
										<div class="sr-only">Remove Code</div>
									</button>
								</p>
							</div>

							<div class="card-body" v-if="!getPromoDiscount">
								<div class="form-group m-0">
									<div class="alert" :class="promoCodeErrorClass" v-if="promoCodeError">{{ promoCodeError }}</div>
									<label for="promo_code">Promo Code</label>
									<div class="input-group">
										<input
											class="form-control"
											type="text"
											name="promo_code"
											id="promo_code"
											v-mask="promoCodeMask"
											title="Promo Code"
											v-model="promoCodeModel"
										/>
										<div class="input-group-append">
											<button
												class="btn btn-primary"
												:disabled="promoCodeValidationProcessing"
												@click="validateAndApplyPromoCode()"
											>{{ promoCodeButton }}</button>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div class="card my-2">
							<div class="card-footer border-top-0 d-inline-flex flex-column align-items-stretch">
								<div class="align-self-end">
									<div class="d-flex justify-content-between align-items-center mx-n1">
										<p class="my-0 mx-1">
											<strong>Subtotal</strong>
										</p>
										<p class="my-0 mx-1">${{ (getSubTotal).toFixed(2) }}</p>
									</div>

									<div
										class="d-flex justify-content-between align-items-center mx-n1"
										v-if="getPromoDiscount"
									>
										<p class="my-0 mx-1">
											<strong>Promo Discount {{activeReservation.promoCodeUsed}} Applied</strong>
										</p>
										<p class="my-0 mx-1">$-{{ (getPromoDiscount).toFixed(2) }}</p>
									</div>
									<div
										class="d-flex justify-content-between align-items-center mx-n1"
										v-if="getGroupDiscount"
									>
										<p class="my-0 mx-1">
											<strong>Group Discount Applied</strong>
										</p>
										<p class="my-0 mx-1">$-{{ (getGroupDiscount).toFixed(2) }}</p>
									</div>
									<div
										class="d-flex justify-content-between align-items-center mx-n1"
										v-if="getEarlyBirdDiscount"
									>
										<p class="my-0 mx-1">
											<strong>Earlybird Discount</strong>
										</p>
										<p class="my-0 mx-1">-${{ (getEarlyBirdDiscount).toFixed(2) }}</p>
									</div>
                                    <div
										class="d-flex justify-content-between align-items-center mx-n1"
										v-if="appData.isValidatedSpeaker"
									>
										<p class="my-0 mx-1">
											<strong>Speaker Discount </strong>
										</p>
										<p class="my-0 mx-1">-${{ (getSubTotal).toFixed(2) }}</p>
									</div>
									<div class="d-flex justify-content-between align-items-center mx-n1 border-top mt-1 pt-1">
										<p class="my-0 mx-1">
											<strong>Total</strong>
										</p>
										<p class="my-0 mx-1">${{ (getTotal).toFixed(2) }}</p>
									</div>
								</div>
							</div>
						</div>

						<div class="d-flex justify-content-end align-items-center mx-n1">
							<button
								class="btn btn-outline-primary mx-1"
								:disabled="promoCodeValidationProcessing"
								@click="goBack()"
							>Go Back and Edit</button>
							<button
								class="btn btn-primary mx-1"
								data-js-id="process-payment-button"
								:disabled="promoCodeValidationProcessing"
								@click.prevent="goToNextStep()"
							>{{ appData.paymentButtonText }}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { logApplicationError } from "../../../../../../../core/global/functions";
	import { mask } from "vue-the-mask";
	import { mapActions, mapState, mapGetters } from 'vuex';
	import PromoCode from "../../../../promo-codes";

	export default {
		props: [ "attendeeRegistrationNotificationAsset" ],
		data() {
			return {
				promoCodeModel: "",
				promoCodeButton: "Add Promo Code",
				promoCodeValidationProcessing: false,
				promoCodeMask: {
					mask: "CCCCCCCCCCCCCCCCCCCCCCCCCCCCCC",
					tokens: {
						C: {
							pattern: /[0-9a-zA-Z]/,
							transform: v => v.toLocaleUpperCase()
						}
					}
				},
				promoCodeError: null,
				promoCodeErrorClass: "warning"
			};
		},
		watch: {
			currentStep(n,o) {
				if (o < n && n === 4) {
					this.resetPromoCodeForm()
					this.getQualifiedPromoCodes()
				}
			}
		},
		computed: {
			...mapState("registration", [
				'activeReservation',
				'appData',
			]),
			...mapGetters('registration', [
				'getEarlyBirdDiscount',
				'getGroupDiscount',
				'getPromoDiscount',
				'getSubTotal',
				'getDiscount',
				'getTotal'
			]),
			currentStep() {
				return this.appData.current_step
			}
		},
		directives: { mask },
		methods: {
			...mapActions("registration", [
				"setVar",
			]),
			buildPromoCode() {
				return new PromoCode({
					code: this.promoCodeModel || this.activeReservation.promoCodeUsed,
					total: this.getSubTotal,
					reservationCount: this.activeReservation.reservations.length,
					groups: [1,2]
				})
			},
			getQualifiedPromoCodes() {
				this.buildPromoCode()
					.getQualifiedPromoCodes({
						onSuccess: (res) => {
							if (res.data.data) {
								this.activeReservation.promoCodeUsed = res.data.data.code
								this.validateAndApplyPromoCode()
							}
						}
					})
			},
			validateAndApplyPromoCode() {
				this.buildPromoCode()
					.validateAndApplyPromoCode({
						onBefore: () => {
							this.promoCodeButton = "Validating Promo Code...";
							this.promoCodeValidationProcessing = true;
							this.promoCodeError = null;
						},
						onSuccess: (res) => {
							const data = res.data;

							if (data.result != "success") {
								this.promoCodeError = data.message;
								this.promoCodeErrorClass = `alert-${data.result}`;
							} else {
								this.$store.dispatch({type: 'registration/setVar', ob: "activeReservation", variable: "promoDiscount", value: parseFloat(data.discount_amount)});
								this.$store.dispatch({type: 'registration/setVar', ob: "activeReservation", variable: "promoCodeUsed", value: data.promo_code});
							}
						},
						onFinally: () => this.resetPromoCodeForm()
					})
            },
			validateGroupDiscount() {
				// Send the validation request
				axios
					.post("/heartland/portal/validate-attendee-group-discount", {
						number_of_attendees: this.activeReservation.reservations.length,
						subtotal: this.getSubTotal,
						reservations: this.activeReservation.reservations
					})
					.then(res => {
						const data = res.data;

						this.setVar({ob: "activeReservation", variable: "groupDiscount", value: data.discountAmountOff});
						this.setVar({ob: "activeReservation", variable: "groupDiscountCodes", value: data.groupDiscountCodes});
						this.setVar({ob: "appData", variable: "paymentButtonText", value: "Complete Registration"});
					})
					.catch(error => {
						this.resetPromoCodeForm();
						logApplicationError(error);
						console.error(error);
					});
			},
            removePromoCode() {
                this.resetPromoCodeForm();

                // If valid update subtotal
				this.setVar({ob: "activeReservation", variable: "promoDiscount", value: 0});
				this.setVar({ob: "activeReservation", variable: "promoCodeUsed", value: ''});
				this.setVar({ob: "appData", variable: "alreadyAppliedEarlyBirdDollarOff", value: false});
				this.setVar({ob: "activeReservation", variable: "groupDiscount", value: 0});
				this.setVar({ob: "activeReservation", variable: "groupCodeApplied", value: ''});
                this.setVar({ob: "appData", variable: "paymentButtonText", value: "Continue to payment"});
            },
			goBack() {
				this.setVar({ob: "appData", variable: "showRegistrationConfirmation", value: false});
				this.setVar({ob: "appData", variable: "showAttendeeReservationInformationSection", value: true});
				this.setVar({ob: "appData", variable: "current_step", value: 3});

				$("html, body").animate({ scrollTop: 0 }, "fast");
			},
			goToNextStep() {
				if (!this.getGroupDiscount) this.validateGroupDiscount();

				this.setVar({ob: "appData", variable: "showRegistrationConfirmation", value: false});
				this.setVar({ob: "appData", variable: "current_step", value: 5});
				this.setVar({ob: "appData", variable: "showPaymentSection", value: true});

				$("html, body").animate({ scrollTop: 0 }, "fast");
			},
			resetPromoCodeForm() {
				this.promoCodeButton = "Add Promo Code"
				this.promoCodeValidationProcessing = false
				this.promoCodeModel = ""
			}
		}
	}
</script>