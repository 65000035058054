export const dtPromoCodeOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        { data: 'code' },
        { data: "portal_group" },
        {
            data: "active",
            render: function(data, type, row) {
                return data == 1 ? `<span class="text-success"><strong>Active</strong></span>` : `<span class="text-danger"><strong>Inactive</strong></span>`;
            }
        },
        {
            data: "expiration_date",
            render: function(data, type, row) {
                return moment(data, 'M/D/YYYY').format('M/D/YYYY');
            }
        },
        {
            data: "created_at",
            render: function(data, type, row) {
                return moment(data).format('M/D/YYYY h:mm A');
            }
        },
        {
            data: "id",
            orderable: false,
            searchable: false,
            render: function(data, type, row) {
                return  `<div class="promo-code-options" data-id="${row.id}">
                            <button class="btn btn-danger" data-js-id="promo-code-recycle">Recycle</button>
                            <button class="btn btn-secondary-blue" data-js-id="promo-code-modify">Modify</button>
                        </div>`;
            }
        }
    ]
}

