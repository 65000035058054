<template>
	<div>
		<!-- Personal Info -->
		<div class="row">
			<div class="col-12">
				<p class="font-weight-bold m-0">Attendee Info</p>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="first_name">First Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee first name"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="first_name"
					id="first_name"
					title="Enter Attendees First name"
					required
					data-msg="Please provide a firstname"
					v-model="expoDetails.user.first_name"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="last_name">Last Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee last name"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="last_name"
					id="last_name"
					title="Enter Attendees last name"
					required
					data-msg="Please provide a last name"
					v-model="expoDetails.user.last_name"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="title">Title</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee title"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="title"
					id="title"
					title="Enter Attendees title"
					v-model="expoDetails.user.title"
				/>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="email">Email</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee Email"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="email"
					id="email"
					title="Enter Attendees Email"
					required
					data-msg="Please provide a Email"
					data-rule-email="true"
					data-msg-email="Please provide a valid email address"
					v-model="expoDetails.user.email"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="phone">Phone Number</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee phone number"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="phone"
					id="phone"
                    required
					title="Enter Attendees phone number"
					v-mask="'(###) ###-####'"
					v-model="expoDetails.user.phone"
				/>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="date_of_birth">Date Of Birth</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee date of birth"
					></a>
				</div>
				<input
					class="form-control js-flatpickr"
					type="text"
					name="date_of_birth"
					id="date_of_birth"
					title="Enter Attendees date of birth"
					required
					data-msg="Please enter in date of birth"
					v-mask="'##/##/####'"
					v-model="expoDetails.user.date_of_birth"
				/>
			</div>
		</div>
		<div class="form-group has-label">
			<div class="d-flex justify-content-between align-items-center">
				<label for="special_requests">Special Requests</label>
				<a
					href="#"
					data-toggle="tooltip"
					data-placement="right"
					data-boundary="window"
					title="Attendee special requests"
				></a>
			</div>
			<textarea
				class="form-control"
				name="special_requests"
				id="special_requests"
				title="Enter Attendees special requests"
				v-model="expoDetails.reservations[0].attendee_information.special_requests"
			></textarea>
		</div>

		<!-- Company Info -->
		<div class="row">
			<div class="col-12">
				<p class="font-weight-bold m-0">Company Info</p>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="company">Company Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee company"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company"
					id="company"
					required
					title="Enter Attendees company"
					v-model="expoDetails.company.address.name"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="company">Company VGM Number</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee company vgm number"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company"
					id="company"
					title="Enter Attendees company vgm number"
					@blur="validateVGMNumber()"
					v-model="expoDetails.company.vgm_member_number"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="company">Industry Focus</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee company industry focus"
					></a>
				</div>
				<select
                    class="form-control"
                    name="address.primary_industry_focus"
                    id="company.address.primary_industry_focus"
                    title="Primary Industry Focus"
                    v-model="expoDetails.company.primary_industry_focus"
                    required
                    data-msg="Please enter a primary industry focus"
                >
                    <option value="No Focus">No Focus</option>
                    <option value="CRT / Mobility">CRT / Mobility</option>
                    <option value="Respiratory / Sleep">Respiratory / Sleep</option>
                    <option value="HME">HME</option>
                    <option value="Specialty (Women's Health, Wound Care, Retail)">Specialty (Women's Health, Wound Care, Retail)</option>
                </select>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label class="required" for="company_address">Company Address</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee company"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company_address"
					id="company_address"
					required
					title="Enter Attendees company address"
					v-model="expoDetails.company.address.address_1"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="company_address_2">Company Address 2</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Attendee company 2"
					></a>
				</div>
				<input
					class="form-control"
					type="text"
					name="company_address_2"
					id="company_address_2"
					title="Enter Attendees company address"
					v-model="expoDetails.company.address.address_2"
				/>
			</div>
		</div>

		<div class="row">
			<div class="form-group col-md">
				<label for="company.address.city">
					City
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="address.city"
					id="company.address.city"
					title="City"
					v-model="expoDetails.company.address.city"
					required
					data-msg="Please enter a city"
				/>
			</div>

			<div class="form-group col-md">
				<label for="company.address.state_province">
					State/Province
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="address.state_province"
					id="company.address.state_province"
					title="State/Province"
					v-model="expoDetails.company.address.state_province"
					required
					data-msg="Please enter a State/Province"
				/>
			</div>

			<div class="form-group col-md">
				<label for="company.address.postal_code">
					Postal Code
					<span class="required">*</span>
				</label>
				<input
					class="form-control"
					type="text"
					name="address.postal_code"
					id="company.address.postal_code"
					title="Postal Code"
					v-model="expoDetails.company.address.postal_code"
					required
					data-msg="Please enter a postal code"
				/>
			</div>
		</div>

		<!-- Registration Options -->
		<div class="row">
			<div class="col-12">
				<p class="font-weight-bold m-0">Registration Options</p>
			</div>
			<div class="col-md form-group">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<span class="mr-3">
						Golf Outing
						<span class="required">*</span>
					</span>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Golf Outing"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Golf Outing</legend>
					<div
						class="form-check form-check-inline form-check-radio"
						v-for="(option, index) in reservationProperties['Golf Outing'].options"
						:key="index"
					>
						<label class="form-check-label" :for="`expo_option_id_${option.id}`">
							<input
								class="form-check-input"
								type="radio"
								name="golf_outing"
								required
								data-msg="Please select a if attending golf outing"
								:title="option.option_name"
								:value="option.id"
								:id="`expo_option_id_${option.id}`"
                                @change="updateSubtotalGolfOutingOption(getCurrentPrice(option))"
								v-model="expoDetails.reservations[0].reservation_options[golfOutingId]"
							/>
							<span class="form-check-sign"></span>
							{{option.option_name}}
						</label>
					</div>
				</fieldset>
			</div>
			<div class="col-md form-group" v-if="golfOutingSelected">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<span class="mr-3">
						Golf Club Rental
						<span class="required">*</span>
					</span>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Golf Club Rental"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Golf Club Rental</legend>
					<div
						class="form-check form-check-inline form-check-radio"
						v-for="(option, index) in reservationProperties['Golf Club Rental'].options"
						:key="index"
					>
						<label class="form-check-label" :for="`expo_option_id_${option.id}`">
							<input
								class="form-check-input"
								type="radio"
								name="golf_outing_rental"
								required
								data-msg="Please select a if need golf club rental"
								:title="option.option_name"
								:value="option.id"
								:id="`expo_option_id_${option.id}`"
								v-model="expoDetails.reservations[0].reservation_options[golfClubRentalId]"
							/>
							<span class="form-check-sign"></span>
							{{option.option_name}}
						</label>
					</div>
				</fieldset>
			</div>
		</div>
		<div class="row" v-if="golfOutingSelected && golfClubRentalSelected">
			<div class="col-md form-group">
				<div class="radio-legend d-flex justify-content-between align-items-center">
					<span class="mr-3">
						Golf Club Rental Options
						<span class="required">*</span>
					</span>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Golf Club Rental Options"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<fieldset>
					<legend class="label sr-only">Golf Club Rental Options</legend>
					<div
						class="form-check form-check-inline form-check-radio"
						v-for="(option, index) in reservationProperties['Golf Club Rental Options'].options"
						:key="index"
					>
						<label class="form-check-label" :for="`expo_option_id_${option.id}`">
							<input
								class="form-check-input"
								type="radio"
								name="golf_outing_rental_options"
								required
								data-msg="Please select golf club rental option"
								:title="option.option_name"
								:value="option.id"
								:id="`expo_option_id_${option.id}`"
								v-model="expoDetails.reservations[0].reservation_options[reservationProperties['Golf Club Rental Options'].id]"
							/>
							<span class="form-check-sign"></span>
							{{option.option_name}}
						</label>
					</div>
				</fieldset>
			</div>
		</div>

		<div class="form-group has-label d-flex flex-row align-items-center mx-n1">
			<div
				class="custom-control custom-switch m-1"
				v-for="(option, index) in reservationProperties['Additional Options'].options"
				:key="index"
			>
				<input
					type="checkbox"
					class="custom-control-input js-boolean-control"
					:title="option.option_name"
					:value="option.id"
					:id="`expo_option_id_${option.id}`"
					:name="`expo_option_id_${option.id}[]`"
					@change="updateAdditionalOptions($event, option)"
					v-model="expoDetails.reservations[0].reservation_options[additionalOptionsId]"
				/>
				<label class="custom-control-label" :for="`expo_option_id_${option.id}`">{{option.option_name}}</label>
			</div>
		</div>

		<div class="form-group has-label" v-if="showSpouseGuestName || spouseOptionSelected">
			<div class="d-flex justify-content-between align-items-center">
				<label class="required" for="spouse_guest_name">Spouse/Guest Name</label>
				<a
					href="#"
					data-toggle="tooltip"
					data-placement="right"
					data-boundary="window"
					title="Spouse/Guest Name"
				>
					<span class="fas fa-question-circle text-info cursor-pointer"></span>
				</a>
			</div>
			<input
				class="form-control"
				type="text"
				name="spouse_guest_name"
				id="spouse_guest_name"
				title="Spouse/Guest Name"
				required
				data-msg="Please provide a spouse guest name"
				v-model="expoDetails.reservations[0].reservation_options[reservationProperties['Spouse/Guest Name'].id]"
			/>
		</div>

		<!-- Platinum Member options -->
		<template v-if="showPlatinumRegistrationOptions">
			<h5>Platinum Member Options</h5>
			<!-- Sunday Arrival and Options -->
			<div class="row">
				<div class="col-md form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Sunday Arrival
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Sunday Arrival"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Sunday Arrival</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Sunday Arrival'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`expo_option_id_${option.id}`">
								<input
									class="form-check-input"
									type="radio"
									name="sunday_arrival"
									required
									data-msg="Please select if arriving Sunday"
									:title="option.option_name"
									:value="option.id"
									:id="`expo_option_id_${option.id}`"
									v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Sunday Arrival`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>

				<div class="col-md-8 form-group" v-if="attendeeArrivingSunday">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Sunday Arrival Options
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Sunday Arrival Options"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Sunday Arrival Options?</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Sunday Arrival Options'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`expo_option_id_${option.id}`">
								<input
									class="form-check-input"
									type="radio"
									name="sunday_arrival_options"
									required
									data-msg="Please select an option"
									:title="option.option_name"
									:value="option.id"
									:id="`expo_option_id_${option.id}`"
									v-model="expoDetails.reservations[0].reservation_options[reservationProperties['Sunday Arrival Options'].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>
			</div>

			<!-- If golf, then ask for golf options -->
			<div class="row" v-if="attendeeArrivingSunday && attendeeWantsGolfOutingAtSunnySide">
				<div class="col-md form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Golf at Sunnyside Club Rental
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Golf at Sunnyside Club Rental"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Golf at Sunnyside Club Rental</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Golf at Sunnyside Club Rental'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`expo_option_id_${option.id}`">
								<input
									class="form-check-input"
									type="radio"
									name="golf_club_sunnyside_rental"
									required
									data-msg="Please select if attendee needs to rent clubs"
									:title="option.option_name"
									:value="option.id"
									:id="`expo_option_id_${option.id}`"
									v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Golf at Sunnyside Club Rental`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>
				<div class="col-md-8 form-group" v-if="attendeeNeedsClubRentalAtSunnyside">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Golf at Sunnyside Club Rental Options
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Golf at Sunnyside Club Rental Options"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Golf at Sunnyside Club Rental Options</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Golf at Sunnyside Club Rental Options'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`expo_option_id_${option.id}`">
								<input
									class="form-check-input"
									type="radio"
									name="golf_club_sunnyside_rental_options"
									required
									data-msg="Please select from club rental options"
									:title="option.option_name"
									:value="option.id"
									:id="`expo_option_id_${option.id}`"
									v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Golf at Sunnyside Club Rental Options`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>
			</div>

			<!-- If arriving Sunday, ask if joining dinner -->
			<div class="row" v-if="attendeeArrivingSunday">
				<div class="col-md form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Group Dinner
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Group Dinner"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Group Dinner</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Group Dinner'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`expo_option_id_${option.id}`">
								<input
									class="form-check-input"
									type="radio"
									name="group_dinner"
									required
									data-msg="Please select if joining dinner"
									:title="option.option_name"
									:value="option.id"
									:id="`expo_option_id_${option.id}`"
									v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Group Dinner`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>

				<!-- If joining for dinner, ask if they are bringing a guest -->
				<div class="col-md-8 form-group" v-if="attendeeArrivingSunday && attendeeJoiningDinner">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Spouse/Guest for Dinner
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Spouse/Guest for Dinner"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Spouse/Guest for Dinner</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Spouse/Guest for Dinner'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`expo_option_id_${option.id}`">
								<input
									class="form-check-input"
									type="radio"
									name="spouse/guest_dinner"
									required
									data-msg="Please select if attendee is bringing spouse or guest for dinner"
									:title="option.option_name"
									:value="option.id"
									:id="`expo_option_id_${option.id}`"
									v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Spouse/Guest for Dinner`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>
			</div>

			<div
				class="form-group"
				v-if="attendeeArrivingSunday && attendeeJoiningDinner && attendeeBringingSpouseGuestForDinner"
			>
				<div class="d-flex justify-content-between align-items-center">
					<label :class="{required: showSpouseGuestName}" for="spouse_guest_name">Spouse/Guest Name</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Spouse guest name"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<input
					class="form-control"
					type="text"
					id="spouse_guest_name_dinner"
					name="spouse_guest_name_dinner"
					title="Spouse/Guest Name"
					data-msg="Please enter spouse/guest name"
					required
					v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Spouse/Guest Name for Dinner`].id]"
				/>
			</div>

			<!-- Ask if attending monday meeting -->
			<div class="row">
				<div class="col-md form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Monday Meeting Attendance
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Monday Meeting Attendance"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Monday Meeting Attendance</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Monday Meeting Attendance'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`expo_option_id_${option.id}`">
								<input
									class="form-check-input"
									type="radio"
									name="monday_meeting_attendance"
									required
									data-msg="Please select if attendee is going to monday meeting"
									:title="option.option_name"
									:value="option.id"
									:id="`expo_option_id_${option.id}`"
									v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Monday Meeting Attendance`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>

				<!-- Ask if need hotel -->
				<div class="col-md-8 form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">
							Hotel Room
							<span class="required">*</span>
						</p>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Hotel Room"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<fieldset>
						<legend class="label sr-only">Hotel Room</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(option, index) in reservationProperties['Hotel Room'].options"
							:key="index"
						>
							<label class="form-check-label" :for="`expo_option_id_${option.id}`">
								<input
									class="form-check-input"
									type="radio"
									name="hotel_room"
									required
									data-msg="Please select if attendee is going to need hotel room"
									:title="option.option_name"
									:value="option.id"
									:id="`expo_option_id_${option.id}`"
									v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Hotel Room`].id]"
								/>
								<span class="form-check-sign"></span>
								{{option.option_name}}
							</label>
						</div>
					</fieldset>
				</div>
			</div>

			<!-- If yes, then ask them to provide hotel info -->
			<div class="form-group has-label" v-if="attendeeNeedsHotel">
				<div class="d-flex justify-content-between align-items-center">
					<div class="d-flex flex-row justify-content-start align-items-center flex-wrap">
						<label class="required" for="hotel_reservation_notes">Hotel Room Reservation Notes</label>
					</div>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Hotel Room Reservation Notes"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<textarea
					class="form-control always-validate"
					name="hotel_reservation_notes"
					title="Please enter meta keywords"
					id="hotel_room_reservation_notes"
					v-model="expoDetails.reservations[0].reservation_options[reservationProperties[`Hotel Room Reservation Notes`].id]"
				></textarea>
			</div>
		</template>
	</div>
</template>

<script>
	export default {
		props: [
			"reservationProperties",
			"expoDetails",
		],
		data() {
			return {
				showSpouseGuestName: false
			};
		},
		computed: {
			golfOutingSelected() {
				const golfOutingYesId = this.findResPropByOptionName(
					"Golf Outing",
					"Yes"
				);

				return (
					this.expoDetails.reservations[0].reservation_options[
						this.golfOutingId
					] == golfOutingYesId.id
				);
			},
			golfClubRentalSelected() {
				const golfOutingYesId = this.findResPropByOptionName(
					"Golf Club Rental",
					"Yes"
				);

				return (
					this.expoDetails.reservations[0].reservation_options[
						this.golfClubRentalId
					] == golfOutingYesId.id
				);
			},
			conferencePassId() {
				return this.reservationProperties[`Conference Pass Options`].id;
			},
			golfOutingId() {
				return this.reservationProperties[`Golf Outing`].id;
			},
			golfClubRentalId() {
				return this.reservationProperties[`Golf Club Rental`].id;
			},
			spouseGuestId() {
				return this.findResPropByOptionName("Additional Options", "Spouse/Guest")
					.id;
			},
			additionalOptionsId() {
				return this.reservationProperties[`Additional Options`].id;
			},
			spouseOptionSelected() {
				let additionalOptionsProp = this.expoDetails.reservations[0]
					.reservation_options[this.additionalOptionsId];

				if (additionalOptionsProp) {
					const indexOfOption = additionalOptionsProp.indexOf(this.spouseGuestId);

					if (indexOfOption !== -1) {
						return true;
					} else {
						return false;
					}
				}
			},
			showPlatinumRegistrationOptions() {
				return (
					this.expoDetails.company &&
					this.expoDetails.company.is_valid_vgm_member &&
					this.expoDetails.company.is_platinum_vgm_member
				);
			},
			hotelRoomId() {
				return this.reservationProperties[`Hotel Room`].id;
			},
			attendeeNeedsHotel() {
				const yesOption = this.findResPropByOptionName("Hotel Room", "Yes");

				return (
					this.expoDetails.reservations[0].reservation_options[
						this.hotelRoomId
					] == yesOption.id
				);
			},
			sundayArrivalId() {
				return this.reservationProperties[`Sunday Arrival`].id;
			},
			attendeeArrivingSunday() {
				const yesOption = this.findResPropByOptionName("Sunday Arrival", "Yes");

				return (
					this.expoDetails.reservations[0].reservation_options[
						this.sundayArrivalId
					] == yesOption.id
				);
			},
			sundayArrivalOptionsId() {
				return this.reservationProperties[`Sunday Arrival Options`].id;
			},
			attendeeWantsGolfOutingAtSunnySide() {
				const golfAtSunnySideOption = this.findResPropByOptionName(
					"Sunday Arrival Options",
					"Golf at Sunnyside"
				);

				return (
					this.expoDetails.reservations[0].reservation_options[
						this.sundayArrivalOptionsId
					] == golfAtSunnySideOption.id
				);
			},
			groupDinnerId() {
				return this.reservationProperties[`Group Dinner`].id;
			},
			attendeeJoiningDinner() {
				const yesOption = this.findResPropByOptionName("Group Dinner", "Yes");

				return (
					this.expoDetails.reservations[0].reservation_options[
						this.groupDinnerId
					] == yesOption.id
				);
			},
			golfSunnySideClubRentalId() {
				return this.reservationProperties[`Golf at Sunnyside Club Rental`].id;
			},
			attendeeNeedsClubRentalAtSunnyside() {
				const yesOption = this.findResPropByOptionName(
					"Golf at Sunnyside Club Rental",
					"Yes"
				);

				return (
					this.expoDetails.reservations[0].reservation_options[
						this.golfSunnySideClubRentalId
					] == yesOption.id
				);
			},
			spouseGuestForDinnerId() {
				return this.reservationProperties[`Spouse/Guest for Dinner`].id;
			},
			attendeeBringingSpouseGuestForDinner() {
				const yesOption = this.findResPropByOptionName(
					"Spouse/Guest for Dinner",
					"Yes"
				);

				return (
					this.expoDetails.reservations[0].reservation_options[
						this.spouseGuestForDinnerId
					] == yesOption.id
				);
			},
			showPlatinumRegistrationOptions() {
				return this.expoDetails.company.is_platinum_vgm_member;
            },
            expoPassOption() {
                return this.findResPropByOptionName("Conference Pass Options", "Expo Only");
            }
		},
		methods: {
			updateSubtotalConferencePassOption(price) {
				this.expoDetails.conference_pass_subtotal = parseFloat(price);
				this.updateReservationSubtotal();
			},
			updateSubtotalGolfOutingOption(price) {
				this.expoDetails.golf_outing_subtotal = parseFloat(price);
				this.updateReservationSubtotal();
			},
			updateSubtotalSpouseGuest(price) {
				this.expoDetails.spouse_guest_subtotal = parseFloat(price);

				this.updateReservationSubtotal();
			},
			updateReservationSubtotal() {
				let conferencePassSubtotal = this.expoDetails.pass_total;
				let golfOutingSubtotal = this.expoDetails.golf_outing_subtotal || 0;
				let spouseGuestPrice = this.expoDetails.spouse_guest_subtotal || 0;

				this.expoDetails.subtotal =
					parseFloat(conferencePassSubtotal) +
					parseFloat(spouseGuestPrice) +
					parseFloat(golfOutingSubtotal);
			},
			getCurrentPrice(option) {
				return this.expoDetails.company.vgm_member_number.length &&
					this.expoDetails.company.is_vgm_member
					? parseFloat(option.vgm_member_price)
					: parseFloat(option.price);
			},
			findResPropByOptionName(propName, optionName) {
				const resProp = this.reservationProperties[`${propName}`].options.filter(
					option => {
						return option.option_name == `${optionName}`;
					}
				);

				return { ...resProp[0] };
			},
			updateAdditionalOptions(event, option) {
				let additionalOptionsProp = this.expoDetails.reservations[0].reservation_options[this.additionalOptionsId];
				// Check to see if the additional options object exists
				if (!additionalOptionsProp) {
					additionalOptionsProp = [];
				}

				const indexOfOptionSpouseOption = additionalOptionsProp.indexOf(this.spouseGuestId);

                if (indexOfOptionSpouseOption !== -1) {
                    let option = this.findResPropByOptionName("Additional Options", "Spouse/Guest");

                    this.updateSubtotalSpouseGuest(option.price);
                    this.showSpouseGuestName = true;
                } else {
                    this.updateSubtotalSpouseGuest(0);
                    this.showSpouseGuestName = false;
                }
			},
			findResPropByOptionName(propName, optionName) {
				const resProp = this.reservationProperties[`${propName}`].options.filter(
					option => {
						return option.option_name == `${optionName}`;
					}
				);

				return { ...resProp[0] };
			},
			findResPropByOptionId(propName, optionId) {
				const resProp = this.reservationProperties[`${propName}`].options.filter(
					option => {
						return option.id == optionId;
					}
				);

				return { ...resProp[0] };
			},
			validateVGMNumber() {
				const vgmMemberNumber = this.expoDetails.company.vgm_member_number;

				let vm = this;

				if (this.expoDetails.company.vgm_member_number.length) {
					axios
						.post(`/heartland-api/validate-vgm-number`, {
							accountNumber: vgmMemberNumber
						})
						.then(response => {
							const data = JSON.parse(response.data.message);

							vm.expoDetails.company.is_valid_vgm_member =
								data.isAMember == "Yes" ? true : false;
							vm.expoDetails.company.is_platinum_vgm_member =
								data.isPlatinumMember == "Yes" ? true : false;
                            vm.expoDetails.company.ram = data.ram;

                            if (data.isAMember == 'Yes') {
                                vm.expoDetails.company.is_vgm_member = 1;
                                vm.expoDetails.pass_total = vm.expoPassOption.vgm_member_price;
                            } else {
                                vm.expoDetails.company.is_vgm_member = 0;
                                vm.expoDetails.pass_total = vm.expoPassOption.price;
                            }
						})
						.catch(error => {
							logApplicationError(error);

							vm.expoDetails.company.is_valid_vgm_member = false;
							vm.expoDetails.company.is_platinum_vgm_member = false;
						});
				}
			},
			toggleSpouseField(event, option) {
				if (
					!this.expoDetails.reservations[0].reservation_options[
						this.additionalOptionsId
					].length
				) {
					this.expoDetails.reservations[0].reservation_options[
						this.additionalOptionsId
					] = [];
				}

				let additionalOptionsProp = this.expoDetails.reservations[0]
					.reservation_options[this.additionalOptionsId];

				const indexOfOption = additionalOptionsProp.indexOf(option.id);

				if (event.target.checked) {
					if (indexOfOption !== -1) {
						if (option.id == this.spouseGuestId) {
							this.showSpouseGuestName = true;
						}
					}
				} else {
					if (indexOfOption == -1) {
						if (option.id == this.spouseGuestId) {
							this.showSpouseGuestName = false;
						}
					}
				}
            },
        },
        mounted() {
            this.expoDetails.reservations[0].reservation_options[this.reservationProperties[`Conference Pass Options`].id] = this.expoPassOption.id;
            this.expoDetails.reservations[0].reservation_options[this.reservationProperties[`Additional Options`].id] = [];

            this.expoDetails.pass_total = this.expoPassOption.price;
        }
	};
</script>
