<template>
    <div>
        <small>To modify vendor registration details, please go to Conference Management > Vendors</small>

        <div class="row">
            <div class="col-12">
                <h6 class="border-top-1 pt-1">Package Info</h6>
            </div>

            <div class="col-2">
                {{ orderItems.package_info.name }}
            </div>

            <div class="col-2">
                Booth Size: {{ orderItems.package_info.booth_size }}
            </div>

            <div class="col">
                Included Badges: {{ orderItems.package_info.number_of_included_badges }}
            </div>
        </div>

        <div class="row mt-1" v-if="orderItems.golfers.length">
            <div class="col-12">
                <h6 class="border-top-1 pt-1">Golfers ($75 per)</h6>
            </div>

            <div class="col-2" v-for="(golfer, index) in orderItems.golfers" :key="`golfer_${index}`">
                {{ golfer.first_name }} {{ golfer.last_name }}
            </div>
        </div>

        <div class="row mt-1">
            <div class="col-12">
                <h6 class="border-top-1 pt-1">Badges</h6>
            </div>

            <div class="col-12">
                <div class="row" v-for="(badge, index) in orderItems.badges" :key="`badge_${index}`">
                    <div class="col-2">
                        {{ badge.first_name || '-' }} {{ badge.last_name || '-' }}
                    </div>

                    <div class="col">
                        {{ badge.is_additional_badge ? '$199.00' : 'Included' }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: ["orderItems", "selectedOrder"]
    };
</script>