<template>
    <div class="form-group" :class="{'pt-2 border-top': index > 0}">
        <div class="radio-legend d-flex justify-content-between align-items-center">
            <p class="m-0 font-weight-bold">{{ question.name }}<span class="required" v-if="question.input_type == 'radio'">*</span></p>
        </div>
        <template v-if="question.input_type == 'radio'">
            <fieldset>
                <legend class="label sr-only">{{ question.name }}</legend>
                <div
                    class="form-check form-check-inline form-check-radio"
                    v-for="(radioOption, index) in whichRadioGroup(index)"
                    :key="index"
                >
                    <label class="form-check-label mr-1" :for="`question_${question.id}_input${index}`">
                        <input
                            class="form-check-input"
                            type="radio"
                            :name="`question_${question.id}`"
                            title="Select an option"
                            :value="`${radioOption}`"
                            :id="`question_${question.id}_input${index}`"
                            required
                            data-msg="Please Select an Option"
                        />
                        <span class="form-check-sign"></span>
                        {{ radioOption }}
                    </label>
                </div>
            </fieldset>
        </template>
        <template v-else-if="question.input_type == 'textarea'">
            <textarea
                class="form-control"
                :name="`question_${question.id}`"
                :id="`question_${question.id}_input${index}`"
                title="Please enter comments for presenter"
                aria-label="Comments for Presenter"
            ></textarea>
        </template>
    </div>
</template>

<script>
	export default {
        props: [
            'question',
            'index'
        ],
        data() {
            return {
                optionsGroup1: ["Strongly Agree", "Agree", "Neutral", "Disagree", "Strongly Disagree"],
                optionsGroup2: ["Excellent", "Very Good", "Good", "Fair", "Poor"],
                optionsGroup3: ["Yes", "No"],
            }
        },
        methods: {
            whichRadioGroup(index) {
                if (index <= 2) {
                    return this.optionsGroup1;
                } else if ( index > 2 && index <= 8) {
                    return this.optionsGroup2;
                } else {
                    return this.optionsGroup3;
                }
            }
        }
    }
</script>