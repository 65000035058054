<template>
	<div>
		<h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1"><em>My Profile</em></h4>
        <!-- Contact Info -->
        <div class="card my-2">
            <portal-dashboard-toggle-bar
                toggle-bar-text="Contact Information"
                @click.native="toggleContactInfo()"
                :is-toggled="showContactInfo"
            ></portal-dashboard-toggle-bar>
            <div class="card-body d-flex justify-content-between align-items-start" v-if="showContactInfo">
                <div class="my-n2">
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Name on record</small>
                        {{ user.first_name }} {{ user.last_name }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm" v-if="user.title">
                        <small class="text-uppercase text-primary-green font-weight-bold">Title</small>
                        {{ user.title }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Company Name</small>
                        {{ company.name }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Address</small>
                        {{ address }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Email</small>
                        {{ user.email }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Phone</small>
                        {{ user.phone }}
                    </p>
                </div>

                <button class="btn btn-primary" v-if="isAfterBadgeCutoff == 0" @click="openEditContactModal()">Edit</button>
            </div>

            <admin-dashboard-contact-info-modal
                :modal-id="contactInfoModalId"
                modal-title="Edit Contact Information"
                header-icon="user"
                modal-size="lg">

                <admin-dashboard-contact-form
                    :user="user"
                    :company="company"
                    :close-modal="closeEditContactModal">
                </admin-dashboard-contact-form>
            </admin-dashboard-contact-info-modal>
        </div>

        <!-- User ID and Password -->
        <div class="card my-2">
            <portal-dashboard-toggle-bar
                toggle-bar-text="User ID &amp; Password"
                @click.native="toggleUserIdAndPasswordInfo()"
                :is-toggled="showUserIdAndPasswordInfo"
            ></portal-dashboard-toggle-bar>
            <div class="card-body d-flex justify-content-between align-items-start" v-if="showUserIdAndPasswordInfo">
                <div class="my-n2">
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Username</small>
                        {{ user.username }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Password</small>
                        ⚹⚹⚹⚹⚹⚹⚹⚹
                    </p>
                </div>

                <button class="btn btn-primary" @click="openEditPasswordModal()">Edit</button>
            </div>

            <admin-dashboard-user-id-password-modal
                :modal-id="userIdAndPasswordModalId"
                modal-title="Change Password"
                header-icon="lock"
                modal-size="lg">

                <admin-dashboard-user-id-password-form
                    :user="user"
                    :close-modal="closeEditPasswordModal">
                </admin-dashboard-user-id-password-form>
            </admin-dashboard-user-id-password-modal>
        </div>

		<!-- Order Info -->
        <div class="card my-2" v-if="orders.length">
            <portal-dashboard-toggle-bar
                toggle-bar-text="Order Info"
                @click.native="toggleOrderInfo()"
                :is-toggled="showOrderInfo"
            ></portal-dashboard-toggle-bar>
            <template v-for="(order, order_index) in orders">
                <div class="card-body d-flex justify-content-between align-items-start" :class="{'border-top': order_index > 0}" v-if="showOrderInfo" :key="order_index">
                    <div class="orders-section">
                        <div class="order-info">
                            <div class="oi-detail border-0 p-0">
                                <p class="oi-key">Order Date:</p>
                                <p class="oi-value">{{ formatOrderDate(order.created_at) }}</p>
                            </div>
                            <div class="oi-detail">
                                <p class="oi-key">Order Number:</p>
                                <p class="oi-value">#{{ order.order_number }}</p>
                            </div>
                            <div class="oi-detail">
                                <p class="oi-key">Billing Info:</p>
                                <div class="oi-value">
                                    <p class="m-0">{{ ifUseElseNot(order.billing_first_name) }} {{ ifUseElseNot(order.billing_last_name) }}</p>
                                    <p class="m-0">{{ ifUseElseNot(order.billing_address_1) }} {{ ifUseElseNot(order.billing_address_2) }}</p>
                                    <p class="m-0">{{ ifUseElseNot(order.billing_city) }}, {{ ifUseElseNot(order.billing_state_province) }} {{ ifUseElseNot(order.billing_postal_code) }}</p>
                                    <p class="m-0" v-if="order.vendor_details">VGM #: {{ ifUseElseNot(order.vendor_details.vgm_member_number) }}</p>
                                </div>
                            </div>

                            <template v-if="order.attendee_details.length">
                                <div v-for="(attendee, attendee_index) in order.attendee_details" :key="attendee_index" class="border-bottom border-1 border-black pb-1">
                                    <div class="oi-detail border-0">
                                        <p class="oi-key flex-grow-1">Attendee #{{ attendee_index + 1 }}</p>
                                        <div class="oi-value ml-2">
                                            <div class="d-flex justify-content-between">
                                                <p class="my-0 font-weight-bold">Option</p>
                                                <p class="my-0 font-weight-bold ml-2">Choice</p>
                                            </div>
                                            <div class="d-flex justify-content-between" v-for="(option, propertyName, option_index) in attendee" :key="option_index">
                                                <p class="m-0 font-weight-bold">{{ propertyName }}</p>
                                                <p class="my-0 ml-2">{{ option.value }}</p>
                                            </div>
                                        </div>
                                        <!-- we may add this back in
                                        <div class="oi-value ml-2">
                                            <div class="d-flex justify-content-between">
                                                <p class="my-0 font-weight-bold text-left">Price</p>
                                            </div>
                                            <div v-for="(option, option_price_index) in attendee" :key="option_price_index">
                                                <p class="m-0 text-left">{{ option.price ? `$${option.price}` : '-' }}</p>
                                            </div>
                                        </div> -->


                                        
                                    </div>

                                    <div class="oi-detail border-0">
                                        <template v-if="order.admin_reservations">
                                            <p class="oi-key flex-grow-1">Optional Offerings</p>
                                            <template v-for="offering in order.admin_reservations[attendee_index].optional_offerings">
                                                <div class="oi-value ml-2">
                                                    <div class="d-flex justify-content-between">
                                                        <p class="my-0 font-weight-bold">{{ offering.name }}</p>
                                                    </div>
                                                    <template v-for="input in offering.inputs">
                                                        <template v-for="value in input.additional_input_value">
                                                            <div class="d-flex justify-content-between">
                                                                <p class="m-0 font-weight-bold">{{ input.additional_input_label }}:</p>
                                                                <p class="my-0 ml-2">{{ value }}</p>
                                                            </div>
                                                        </template>
                                                        <div class="d-flex justify-content-between">
                                                            <p class="m-0 font-weight-bold">Total:</p>
                                                            <p class="my-0 ml-2">${{ input.line_item_total }}</p>
                                                        </div>
                                                    </template>
                                                </div>
                                            </template>
                                        </template>
                                    </div>


                                </div>
                            </template>

                            <template v-if="order.vendor_details">
                                <div class="oi-detail">
                                    <p class="oi-key flex-grow-1">Booth Package:</p>
                                    <p class="oi-value">Package {{ getVendorOrderPackage(order) }}</p>
                                    <p class="oi-value ml-2">{{ getVendorOrderPackagePrice(order) }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Included Badges:</p>
                                    <p class="oi-value">{{ order.vendor_details.package_info.number_of_included_badges }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Booth Size:</p>
                                    <p class="oi-value">{{ order.vendor_details.package_info.booth_size }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key flex-grow-1">Badges</p>
                                    <div class="oi-value ml-2">
                                        <div class="d-flex justify-content-between">
                                            <p class="my-0 font-weight-bold">Badge Holder</p>
                                            <p class="my-0 font-weight-bold ml-2">Badge ID</p>
                                        </div>
                                        <div class="d-flex justify-content-between" v-for="(badge, included_badge_index) in order.vendor_details.included_badges" :key="included_badge_index">
                                            <p class="my-0">{{ badge.first_name }} {{ badge.last_name }}</p>
                                            <p class="my-0 ml-2">{{ badge.badge_id }}</p>
                                        </div>
                                        <template v-if="order.vendor_details.additional_badges.length">
                                            <div class="d-flex justify-content-between" v-for="(badge, additional_badge_index) in order.vendor_details.additional_badges" :key="order.vendor_details.included_badges.length + additional_badge_index">
                                                <p class="my-0">{{ badge.first_name }} {{ badge.last_name }}</p>
                                                <p class="my-0 ml-2">{{ badge.badge_id }}</p>
                                            </div>
                                        </template>
                                    </div>
                                    <div class="oi-value ml-2">
                                        <div class="d-flex justify-content-between">
                                            <p class="my-0 font-weight-bold text-left">Price</p>
                                        </div>
                                        <div v-for="(badge, included_badge_price_index) in order.vendor_details.included_badges" :key="included_badge_price_index">
                                            <p class="m-0 text-left">Included</p>
                                        </div>
                                        <template v-if="order.vendor_details.additional_badges.length">
                                            <div v-for="(badge, additional_badge_price_index) in order.vendor_details.additional_badges" :key="order.vendor_details.included_badges.length + additional_badge_price_index">
                                                <p class="m-0 text-left">$199.00</p>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <template v-if="order.vendor_details.golfers.length">
                                    <div class="oi-detail">
                                        <p class="oi-key flex-grow-1">Golfers</p>
                                        <div class="oi-value ml-2">
                                            <div class="d-flex justify-content-between">
                                                <p class="my-0 font-weight-bold">Golfer Name</p>
                                                <p class="my-0 font-weight-bold ml-2">Club Rental</p>
                                            </div>
                                            <div class="d-flex justify-content-between" v-for="(golfer, golfer_index) in order.vendor_details.golfers" :key="golfer_index">
                                                <p class="my-0">{{ golfer.first_name }} {{ golfer.last_name }}</p>
                                                <p class="my-0 ml-2">{{ golfer.club_rental == 1 ? golfer.club_rental_option : '-' }}</p>
                                            </div>
                                        </div>
                                        <div class="oi-value ml-2">
                                            <div class="d-flex justify-content-between">
                                                <p class="my-0 font-weight-bold text-left">Price</p>
                                            </div>
                                            <div v-for="(golfer, golfer_price_index) in order.vendor_details.golfers" :key="golfer_price_index">
                                                <p class="m-0 text-left">$75.00</p>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-if="order.offering_detail">
                                <div class="oi-detail">
                                    <p class="oi-key flex-shrink-0">Optional Offering:</p>
                                    <div class="oi-value ml-2 text-left">
                                        <p class="mt-0 font-weight-bold mb-1">{{ order.offering_detail.name }}</p>
                                        <p class="mt-0 mb-1 pb-1 border-bottom">{{ order.offering_detail.description }}</p>
                                        <template v-for="(input, index) in order.offering_detail.inputs">
                                            <p class="my-0 text-right">
                                                {{ input.name }}(${{ parseFloat(input.price_per_unit).toFixed(2) }})
                                                <span class="fas mx-1 fa-times"></span>
                                                {{ input.quantity }}
                                                <span class="fas mx-1 fa-equals"></span>
                                                ${{ parseFloat(input.line_item_total).toFixed(2) }}
                                            </p>
                                        </template>
                                    </div>
                                </div>
                            </template>

                            <portal-dashboard-itemized-receipt :order="order" />
                            
                            <div class="align-self-end d-flex flex-column align-items-stretch mt-2 border-top-2 border-wcag-grey">
                                <div class="oi-detail">
                                    <p class="oi-key">Subtotal:</p>
                                    <p class="oi-value ml-2">{{ `$${order.subtotal}` }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Discount:</p>
                                    <p class="oi-value ml-2">{{ order.discount ? `-$${order.discount}` : '' }}</p>
                                </div>
                                <div class="oi-detail">
                                    <p class="oi-key">Total:</p>
                                    <p class="oi-value ml-2">{{ `$${order.total}` }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
        </div>
	</div>
</template>

<script>
	export default {
        props: [
            'user',
            'company',
            'isAfterBadgeCutoff',
            'orders',
        ],
        data() {
            return {
                contactInfoModalId: 'admin-dashboard-edit-info-modal',
                userIdAndPasswordModalId: 'admin-dashboard-user-id-password-modal',
                showContactInfo: false,
                showUserIdAndPasswordInfo: false,
                showOrderInfo: false,
            }
        },
        computed: {
            address() {
                if (this.user.address) {
                    return `${this.user.address_1  || ''} ${this.user.address_2  || ''} ${this.user.city  || ''}, ${this.user.state_province  || ''} ${this.user.postal_code  || ''}`;
                } else {
                    return `${this.company.address_1  || ''} ${this.company.address_2  || ''} ${this.company.city  || ''}, ${this.company.state_province  || ''} ${this.company.postal_code  || ''}`;
                }
            },
            optionalOfferingsSansAdditionalOrderOfferings() {
                let newOfferings = []
                let additionalOrderOfferings = this.orders.reduce((acc,cur) => {
                    if ('offering_detail' in cur) acc.push(cur.offering_detail)
                    return acc
                }, [])

                this.reservation.optional_offerings.forEach(offering => {
                    let match = additionalOrderOfferings.some(additionalOrderOffering => _.isEqual(offering, additionalOrderOffering))
                    if (match) return
                    newOfferings.push(offering)
                })

                return newOfferings
            }
        },
        methods: {
            formatOrderDate(date) {
                return moment(date).format('MM/DD/YYYY');
            },
            getVendorOrderPackage(order) {
                return order.vendor_details.package_info.name;
            },
            getVendorOrderPackagePrice(order) {
                return `$${order.vendor_details.package_info.base_package_price}`;
            },
            openEditContactModal() {
                $(`[data-js-id="${this.contactInfoModalId}"]`).modal('show');
            },
            closeEditContactModal() {
                $(`[data-js-id="${this.contactInfoModalId}"]`).modal('hide');
            },
            openEditPasswordModal() {
                $(`[data-js-id="${this.userIdAndPasswordModalId}"]`).modal('show');
            },
            closeEditPasswordModal() {
                $(`[data-js-id="${this.userIdAndPasswordModalId}"]`).modal('hide');
            },
            toggleContactInfo() {
			    this.showContactInfo = !this.showContactInfo;
            },
            toggleUserIdAndPasswordInfo() {
                this.showUserIdAndPasswordInfo = !this.showUserIdAndPasswordInfo;
		    },
            toggleOrderInfo() {
                this.showOrderInfo = !this.showOrderInfo;
            },
            ifUseElseNot(value) {
                return value ? value : '';
            }
        }
    };
</script>