<template>
	<div class="card my-2">
		<portal-dashboard-toggle-bar
			:toggle-bar-text="`Attendee #${index + 1} - ${attendeeStatus}`"
			:is-toggled="showAttendeeInfo"
			@click.native="showAttendeeInfo = !showAttendeeInfo"
		></portal-dashboard-toggle-bar>

		<!-- Contact Info -->
		<div class="card-body" v-if="showAttendeeInfo">
			<div class="alert alert-danger" v-if="showErrorDiv" v-html="errorMessage"></div>
			<div class="d-flex justify-content-between align-items-start my-n2">
				<div>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Name on Record</small>
						<template v-if="nameHasBeenSet">
							{{ reservation.first_name }} {{ reservation.last_name }}
						</template>
						<template v-else>Name has not been provided</template>
					</p>
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservation.title">
						<small class="text-uppercase text-primary-green font-weight-bold">Title</small>
						{{ reservation.title }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm" v-if="company.name">
						<small class="text-uppercase text-primary-green font-weight-bold">Company Name</small>
						{{ company.name }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Address</small>
						<template v-if="addressHasBeenSet">
							{{ address }}
						</template>
						<template v-else>Address has not been provided</template>
					</p>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Email</small>
						{{ reservation.email || 'Email is required' }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservation.phone">
						<small class="text-uppercase text-primary-green font-weight-bold">Phone</small>
						{{ reservation.phone }}
					</p>
				</div>
				<button
					class="btn btn-primary my-2"
					@click.prevent="openModal(reservation)"
					v-if="!isAfterBadgeCutoff"
				>Edit</button>
			</div>
			<div
				class="d-flex justify-content-end m-n1"
				v-if="checkIfReservationIsReady || (reservation.user && reservation.user.id != reservation.admin_user_id)"
			>
				<button
					class="btn btn-primary m-1"
					v-if="checkIfReservationIsReady"
					@click.prevent="sendReservationEmail($event)"
				>Send Registration Email</button>
				<button
					class="btn btn-outline-primary m-1"
					v-if="reservation.user && reservation.user.id != reservation.admin_user_id"
					@click.prevent="sendResetPasswordEmail($event)"
				>Reset Password</button>
			</div>
		</div>

		<div class="card-body border-top" v-if="showAttendeeInfo">
			<!-- Registration Info -->
			<div class="my-n2">
				<div>
					<p class="my-2 d-flex flex-column lh-sm">
						<small class="text-uppercase text-primary-green font-weight-bold">Conference Pass</small>
						{{ conferencePassOption }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm" v-if="conferencePassOption == '1-Day Pass'">
						<small class="text-uppercase text-primary-green font-weight-bold">1-Day</small>
						{{ oneDayPassOption }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm" v-if="conferencePassOption == 'Tech Training'">
						<small class="text-uppercase text-primary-green font-weight-bold">Tech Training</small>
						{{ techTrainingOption }}
					</p>
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationIsGolfing">
						<small class="text-uppercase text-primary-green font-weight-bold">Golf Outing</small>
						Attending
					</p>
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationNeedsRentalClubs">
						<small class="text-uppercase text-primary-green font-weight-bold">Golf Club Rental</small>
						{{ golfClubRentalOption }}
					</p>
					<template v-if="reservationHasAdditionalOptions">
						<p class="my-2 d-flex flex-column lh-sm">
							<small class="text-uppercase text-primary-green font-weight-bold">Additional Options</small>
							<span v-for="(option, index) in additionalOptions" :key="index">
								{{ option.name }}
								<template v-if="option.name == 'Spouse/Guest'"> - {{ spouseGuestName }}</template>
								<br />
							</span>
						</p>
					</template>
				</div>
			</div>
		</div>

		<div class="card-body border-top" v-if="showAttendeeInfo">
			<!-- Platinum Options -->
			<div class="my-n2">
				<div v-if="showPlatinumRegistrationOptions">
					<!-- If arriving on sunday, what activity? -->
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationArrivingSunday">
						<small class="text-uppercase text-primary-green font-weight-bold">Sunday Activity</small>
						{{ sundayActivityOption }}
					</p>

					<!-- If sunday activity is golfing and attendee is renting, which club type? -->
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationIsGolfingAtSunnyside && reservationNeedsGolfClubsAtSunnyside">
						<small class="text-uppercase text-primary-green font-weight-bold">Sunnyside Golf Club Rental Option</small>
						{{ golfClubRentalOptionAtSunnyside }}
					</p>

					<!-- If arriving on sunday, is attendee attending dinner? -->
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationArrivingSunday">
						<small class="text-uppercase text-primary-green font-weight-bold">Attending Dinner?</small>
						{{ attendingDinnerOption }}
					</p>

					<!-- If attending dinner and bringing a guest, what is their name? -->
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationAttendingDinner && reservationHasDinnerGuest">
						<small class="text-uppercase text-primary-green font-weight-bold">Dinner Guest</small>
						{{ dinnerGuestName }}
					</p>

					<!-- Is the attendee attending the monday meeting? -->
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationAttendingMondayMeeting">
						<small class="text-uppercase text-primary-green font-weight-bold">Attending Monday Meeting</small>
						{{ mondayMeetingAttendance }}
					</p>

					<!-- Does attendee need a hotel? -->
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationNeedsHotel">
						<small class="text-uppercase text-primary-green font-weight-bold">Hotel Room?</small>
						{{ hotelRoomOption }}
					</p>

					<!-- What are the details regarding the hotel reservation? -->
					<p class="my-2 d-flex flex-column lh-sm" v-if="reservationNeedsHotel">
						<small class="text-uppercase text-primary-green font-weight-bold">Hotel Room Reservation Notes</small>
						{{ hotelRoomReservationNotes }}
					</p>
				</div>
			</div>
			<attendee-dashboard-registration-modal
                :modal-id="attendeeDashboardRegistrationModalId"
                modal-title="Edit Registration"
                header-icon="user"
                modal-size="lg"
			>
                <attendee-dashboard-reservation-edit-form
                    :reservation="reservation"
                    :user="reservation"
                    :company="company"
                    :order="orders"
					:isAdmin="true"
                    :close-modal="closeReservationEditModal">
                </attendee-dashboard-reservation-edit-form>
            </attendee-dashboard-registration-modal>
			<button class="btn btn-warning" @click="openEditReservationModal()">cancel registration</button>

		</div>
	</div>
</template>

<script>
	export default {
		props: [
			"reservation",
			"company",
			"isAfterBadgeCutoff",
			"openModal",
			"index",
			"user",
			"orders"

		],
		data() {
			return {
				showAttendeeInfo: false,
				showErrorDiv: false,
				errorMessage: null,
				attendeeDashboardRegistrationModalId: 'attendee-dashboard-registration-modal',
			};
		},
		computed: {
			showPlatinumRegistrationOptions() {
				return (
					this.company.is_valid_vgm_member && this.company.is_platinum_vgm_member
				);
			},
			reservationArrivingSunday() {
				return this.sundayArrivalOption == "Yes";
			},
			reservationIsGolfingAtSunnyside() {
				return this.sundayActivityOption == "Golf at Sunnyside";
			},
			reservationNeedsGolfClubsAtSunnyside() {
				return this.golfClubRentalAtSunnyside == "Yes";
			},
			reservationAttendingDinner() {
				return this.getAttendingDinnerOption == "Yes";
			},
			reservationHasDinnerGuest() {
				return this.getDinnerGuestOption == "Yes";
			},
			reservationAttendingMondayMeeting() {
				return this.getDinnerGuestOption == "Yes";
			},
			reservationNeedsHotel() {
				return this.hotelRoomOption == "Yes";
			},
			reservationIsGolfing() {
				return this.getGolfOutingOption == "Yes";
			},
			reservationNeedsRentalClubs() {
				return this.getGolfClubRentalNeededOption == "Yes";
			},
			reservationHasAdditionalOptions() {
				return this.additionalOptions.length;
			},
			reservationNeedsGolfClubsAtSunnyside() {
				return this.golfClubRentalAtSunnyside == "Yes";
			},
			sundayArrivalOption() {
				return this.reservation.reservation_options["Sunday Arrival"]
					? this.reservation.reservation_options["Sunday Arrival"].value
					: "";
			},
			sundayActivityOption() {
				return this.reservation.reservation_options["Sunday Arrival Options"]
					? this.reservation.reservation_options["Sunday Arrival Options"].value
					: "";
			},
			golfClubRentalAtSunnyside() {
				return this.reservation.reservation_options["Golf at Sunnyside Club Rental"]
					? this.reservation.reservation_options["Golf at Sunnyside Club Rental"].value
					: "";
			},
			golfClubRentalOptionAtSunnyside() {
				return this.reservation.reservation_options["Golf at Sunnyside Club Rental Options"]
					? this.reservation.reservation_options["Golf at Sunnyside Club Rental Options"].value
					: "";
			},
			attendingDinnerOption() {
				return this.reservation.reservation_options["Group Dinner"]
					? this.reservation.reservation_options["Group Dinner"].value
					: "";
			},
			conferencePassOption() {
				return this.reservation.reservation_options["Conference Pass Options"]
					? this.reservation.reservation_options["Conference Pass Options"].value
					: "";
			},
			getGolfOutingOption() {
				return this.reservation.reservation_options["Golf Outing"]
					? this.reservation.reservation_options["Golf Outing"].value
					: "";
			},
			getGolfClubRentalNeededOption() {
				return this.reservation.reservation_options["Golf Club Rental"]
					? this.reservation.reservation_options["Golf Club Rental"].value
					: "";
			},
			golfClubRentalOption() {
				return this.reservation.reservation_options["Golf Club Rental Options"]
					? this.reservation.reservation_options["Golf Club Rental Options"].value
					: "";
			},
			additionalOptions() {
				return this.reservation.reservation_options["Additional Options"]
					? this.reservation.reservation_options["Additional Options"]
							.options_selected
					: "";
			},
			spouseGuestName() {
				return this.reservation.reservation_options["Spouse/Guest Name"]
					? this.reservation.reservation_options["Spouse/Guest Name"].value
					: "";
			},
			techTrainingOption() {
				return this.reservation.reservation_options["Tech Training Level"]
					? this.reservation.reservation_options["Tech Training Level"].value
					: "";
			},
			oneDayPassOption() {
				return this.reservation.reservation_options["1-Day Pass Date"]
					? this.reservation.reservation_options["1-Day Pass Date"].value
					: "";
			},
			getDinnerGuestOption() {
				return this.reservation.reservation_options["Spouse/Guest for Dinner"]
					? this.reservation.reservation_options["Spouse/Guest for Dinner"].value
					: "";
			},
			dinnerGuestName() {
				return this.reservation.reservation_options[
					"Spouse/Guest Name for Dinner"
				]
					? this.reservation.reservation_options["Spouse/Guest Name for Dinner"]
							.value
					: "";
			},
			mondayMeetingAttendance() {
				return this.reservation.reservation_options["Monday Meeting Attendance"]
					? this.reservation.reservation_options["Monday Meeting Attendance"]
							.value
					: "";
			},
			hotelRoomReservationNotes() {
				return this.reservation.reservation_options[
					"Hotel Room Reservation Notes"
				]
					? this.reservation.reservation_options["Hotel Room Reservation Notes"]
							.value
					: "";
			},
			hotelRoomOption() {
				return this.reservation.reservation_options["Hotel Room"]
					? this.reservation.reservation_options["Hotel Room"].value
					: "";
			},
			nameHasBeenSet() {
				return this.reservation.first_name && this.reservation.last_name;
			},
			addressHasBeenSet() {
				return (
					this.reservation.address_1 &&
					this.reservation.city &&
					this.reservation.state_province &&
					this.reservation.postal_code
				);
			},
			address() {
				if (this.addressHasBeenSet) {
					return `${this.reservation.address_1} ${this.reservation.address_2 ||
						""} ${this.reservation.city}, ${this.reservation.state_province} ${
						this.reservation.postal_code
					}`;
				}
			},
			checkIfReservationIsReady() {
				return (
					this.reservation.email &&
					this.reservation.first_name &&
					this.reservation.last_name &&
					!this.reservation.user
				);
			},
			attendeeStatus() {
				if (this.reservation.user) {
					return "Known";
				} else {
					return "TBD";
				}
			}
		},
		methods: {
			 openEditReservationModal() {
                $(`[data-js-id="${this.attendeeDashboardRegistrationModalId}"]`).modal('show');
            },
            closeReservationEditModal() {
                $(`[data-js-id="${this.attendeeDashboardRegistrationModalId}"]`).modal('hide');
            },
			sendReservationEmail(event) {
				const vm = this;
				let button = event.target;

				vm.showErrorDiv = false;

				button.innerText = "Processing...";
				button.disabled = true;

				axios
					.post(
						"/portal/dashboard/admin/send-reservation-email",
						this.reservation
					)
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						button.innerText = "Send Reservation Email";
						button.disabled = false;

						if (error.response.status == "422") {
							const errors = error.response.data.message;

							let errorsUl = document.createElement("ul");
							errorsUl.classList.add("list-unstyled");

							for (error in errors) {
								errorsUl.innerHTML += `<li>${errors[error]}</li>`;
							}

							vm.showErrorDiv = true;
							vm.errorMessage = errorsUl.outerHTML;
						} else {
							vm.showErrorDiv = true;
							vm.errorMessage =
								'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
						}

						logApplicationError(error);
						console.error(error);
					});
			},
			sendResetPasswordEmail(event) {
				const vm = this;
				let button = event.target;

				vm.showErrorDiv = false;

				button.innerText = "Processing...";
				button.disabled = true;

				axios
					.post(
						"/portal/dashboard/admin/send-reset-password-email",
						this.reservation.user
					)
					.then(res => {
						window.location.reload();
					})
					.catch(error => {
						button.innerText = "Reset Password";
						button.disabled = false;

						if (error.response.status == "422") {
							const errors = error.response.data.message;

							let errorsUl = document.createElement("ul");
							errorsUl.classList.add("list-unstyled");

							for (error in errors) {
								errorsUl.innerHTML += `<li>${errors[error]}</li>`;
							}

							vm.showErrorDiv = true;
							vm.errorMessage = errorsUl.outerHTML;
						} else {
							vm.showErrorDiv = true;
							vm.errorMessage =
								'There was an error updating your request, please call <a href="tel:877-484-6901">877-484-6901</a> for assistance.';
						}

						logApplicationError(error);
						console.error(error);
					});
			}
		}
	};
</script>