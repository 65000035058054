<template>
	<div>
        <div class="document-modify-details" v-if="!actionIsAdd">
            <p class="md-modified">Last Modified: <span>{{ getLastModifiedDate }}</span></p>
            <p class="md-status">Status: <span>{{ getStatus }}</span></p>
        </div>
        <div class="row">
            <div class="form-group col-md">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="document_path">Document<span class="required">*</span></label>
                </div>
                <div class="d-flex flex-column">
                    <div class="mt-2">
                        <input
                            class="form-control"
                            type="text"
                            name="document_path"
                            :id="documentPathInputId"
                            value=""
                            disabled
                            required
                            data-msg="Please provide a document"
                        />
                        <div class="d-flex mt-2">
                            <button
                                class="btn btn-tertiary mr-1"
                                :id="chooseDocumentButtonId"
                                :data-input="documentPathInputId"
                                title="Opens the file manager to choose to upload a new document or choose from your document library"
                                @click.prevent="openFileManager()"
                            >
                                Choose a Document
                            </button>
                            <button
                                class="btn btn-outline-primary"
                                :id="removeButtonId"
                                title="Removes the current document"
                                @click.prevent="removeDocument()"
                            >
                                Delete Document
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md">
                <label for="name">Document Name<span class="required">*</span></label>
                <input
                    class="form-control"
                    type="text"
                    name="name"
                    id="name"
                    title="Please enter name for the document"
                    autocomplete="off"
                    required
                    data-msg="Please provide a name"
                    v-model="currentDocument.name"
                />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-md">
                <div class="d-flex justify-content-between align-items-center">
                    <label for="description">Description</label>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="Brief Description of what this document is"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <textarea
                    class="form-control"
                    name="description"
                    id="description"
                    title="Enter a description"
                    rows="5"
                    v-model="currentDocument.description"
                >
                </textarea>
            </div>
        </div>
        <div class="row">
            <div class="col-md form-group">
                <div class="radio-legend d-flex justify-content-between align-items-center">
                    <p class="mr-3">Active?<span class="required">*</span></p>
                    <a
                        href="#"
                        data-toggle="tooltip"
                        data-placement="right"
                        data-boundary="window"
                        title="'This will switch the document to active or inactive'"
                    >
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <fieldset>
                    <legend class="label sr-only">Active?</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="active_yes">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="active"
                                id="active_yes"
                                title="Show this document"
                                value="1"
                                checked
                                required
                                data-msg="Please choose active or inactive"
                                v-model="currentDocument.active"
                            />
                            <span class="form-check-sign"></span>
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="active_no">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="active"
                                id="active_no"
                                title="Don't show this document"
                                value="0"
                                required
                                data-msg="Please choose active or inactive"
                                v-model="currentDocument.active"
                            />
                            <span class="form-check-sign"></span>
                            No
                        </label>
                    </div>
                </fieldset>
            </div>
            <div class="col-md form-group" v-if="portalInstalled">
                <div class="radio-legend d-flex justify-content-between align-items-center">
                    <p class="mr-3">Secure Only?</p>
                    <a href="#" data-toggle="tooltip" data-placement="right" data-boundary="window" title="This will be accessible behind a login only">
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <fieldset>
                    <legend class="label sr-only">Secure Only?</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="secure_yes">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="secure"
                                id="secure_yes"
                                title="Display behind login"
                                value="1"
                                checked
                                v-model="currentDocument.secure"
                            />
                            <span class="form-check-sign"></span>
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="secure_no">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="secure"
                                id="secure_no"
                                title="Display to everyone"
                                value="0"
                                v-model="currentDocument.secure"
                            />
                            <span class="form-check-sign"></span>
                            No
                        </label>
                    </div>
                </fieldset>
            </div>
            <div class="col-md form-group">
                <div class="radio-legend d-flex justify-content-between align-items-center">
                    <p class="mr-3">Show Description<span class="required">*</span></p>
                    <a href="#" data-toggle="tooltip" data-placement="right" data-boundary="window" title="If yes, this will display the description in the document listing">
                        <span class="fas fa-question-circle text-info cursor-pointer"></span>
                    </a>
                </div>
                <fieldset>
                    <legend class="label sr-only">Show Description</legend>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="description_yes">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="display_description"
                                id="description_yes"
                                title="Show description"
                                value="1"
                                checked
                                required
                                data-msg="Please choose to show description or not"
                                v-model="currentDocument.display_description"
                            />
                            <span class="form-check-sign"></span>
                            Yes
                        </label>
                    </div>
                    <div class="form-check form-check-inline form-check-radio">
                        <label class="form-check-label" for="description_no">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="display_description"
                                id="description_no"
                                title="Don't show description"
                                value="0"
                                required
                                data-msg="Please choose to show description or not"
                                v-model="currentDocument.display_description"
                            />
                            <span class="form-check-sign"></span>
                            No
                        </label>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="row">
            <div class="col-sm form-group" v-if="allCategories.length">
                <p class="m-0 lh-xs" v-if="selectedCategory.length">
                    <small>
						<strong>Selected categor<span v-if="selectedCategory.length <= 1">y</span><span v-else>ies</span>:</strong>
						<template v-for="(value, index) in selectedCategory"> {{ getSelectedCategory(value, index) }} </template>
					</small>
				</p>
				<div class="d-flex justify-content-between align-items-center">
					<label for="document_categories">Categories</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Use Ctrl + Click to select multiple categoires"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
                <select
                    class="form-control flex-grow-1"
                    name="categories[]"
                    id="document_categories"
                    title="Select a Category"
                    multiple
                    v-model="selectedCategory"
                >
                    <option
                        v-for="(category, index) in allCategories"
                        :key="index"
                        :value="category.id"
                    >
                        {{ category.name }}
                    </option>
                </select>
            </div>
            <div class="col-sm form-group" v-if="showPortalGroups">
                <p class="m-0 lh-xs" v-if="selectedPortalGroups.length">
                    <small>
						<strong>Selected Group<span v-if="selectedPortalGroups.length > 1">s</span>:</strong>
						<template v-for="(value, index) in selectedPortalGroups"> {{ getSelectedPortalGroups(value, index) }} </template>
					</small>
				</p>
				<div class="d-flex justify-content-between align-items-center">
					<label for="document_portal_groups">Portal Groups</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Use Ctrl + Click to select multiple groups"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
                <select
                    class="form-control flex-grow-1"
                    name="portal_groups[]"
                    id="document_portal_groups"
                    title="Select a Portal Group"
                    multiple
                    v-model="selectedPortalGroups"
                >
                    <option
                        v-for="(group, index) in portalGroups"
                        :key="index"
                        :value="group.id"
                    >
                        {{ group.name }}
                    </option>
                </select>
            </div>
        </div>
	</div>
</template>

<script>
	import { openFileManager } from "../../../../../vpanel-functions";
	export default {
		props: [
            'actionIsAdd',
            "portalGroups",
            "currentModule",
            "allCategories",
            "portalInstalled",
            "selectedDocument"
        ],
		data() {
			return {
                selectedCategory: [],
                selectedPortalGroups: [],
                documentPathInputId: 'document_path',
                currentDocument: this.selectedDocument,
                removeButtonId: 'remove-document-button',
                chooseDocumentButtonId: 'choose-document-button',
			}
        },
        mounted(){
            if(this.currentDocument.document_path){
                document.querySelector(`[id="${this.documentPathInputId}"]`).value = this.currentDocument.document_path;
            }

            if(this.selectedDocument.categories){
                let savedCategories = this.currentDocument.categories;
                savedCategories.forEach(category => {
                    this.selectedCategory.push(category.id);
                });
            }

            if(this.selectedDocument.portal_groups){
                let savedPortalGroups = this.currentDocument.portal_groups;
                savedPortalGroups.forEach(portalGroups => {
                    this.selectedPortalGroups.push(portalGroups.id);
                });
            }
        },
		computed: {
            chooseDocumentButton(){
				return document.querySelector(`[id="${this.chooseDocumentButtonId}"]`);
			},
            documentPathInput(){
				return document.querySelector(`[id="${this.documentPathInputId}"]`);
            },
            getLastModifiedDate(){
                if(this.currentDocument.updated_at){
                    return moment(this.currentDocument.updated_at).format('MM/D/YYYY h:mm a');
                } else {
                    return 'N/A';
                }
            },
            getStatus(){
                if(this.currentDocument.active){
                    return 'Active';
                } else {
                    return 'Inactive';
                }
            },
            showPortalGroups(){
                if(this.portalInstalled && this.currentDocument.secure == 1){
                    return true;
                } else {
                    return false;
                }
            }
        },
		methods: {
            openFileManager(){
                openFileManager(this.chooseDocumentButton, null);
            },
			removeDocument(){
				this.documentPathInput.value = '';
            },
            categoryNameFromId(value){
				let allCategories = this.allCategories;

				for(i = 0; i < allCategories.length; ++i){
					if(allCategories[i].id == value){
						return allCategories[i].name;
						break;
					}
				}
			},
            getSelectedCategory(id, index){
                let array = this.selectedCategory;
                this.currentDocument.categories = array;
                return this.categoryNameFromId(id) + (index !== array.length -1 ? ', ' : '');
            },
            groupsNameFromId(value){
				let portalGroups = this.portalGroups;

				for(i = 0; i < portalGroups.length; ++i){
					if(portalGroups[i].id == value){
						return portalGroups[i].name;
						break;
					}
				}
			},
            getSelectedPortalGroups(id, index){
                let array = this.selectedPortalGroups;
                this.currentDocument.portal_groups = array;
                return this.groupsNameFromId(id) + (index !== array.length -1 ? ', ' : '');
            }
		}
	};
</script>