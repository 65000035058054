<template>
    <div id="my-courses-section">
        <h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1"><em>Courses</em></h4>

        <!-- My Courses Card -->
        <attendee-dashboard-my-courses-card :favorite-courses="favoriteCourses"></attendee-dashboard-my-courses-card>

        <!-- My Credits Card -->
        <attendee-dashboard-my-credits-card
			:attendee-id="attendeeId"
            :attendee-name="attendeeName"
            :attended-courses="attendedCourses"
            :is-transcript-published="isTranscriptPublished"
            :selected-course-evaluation="selectedCourseEvaluation"
            :conferences="conferences"
        >
        </attendee-dashboard-my-credits-card>

        <!-- Course Evaluation Modal -->
		<attendee-dashboard-course-evaluation-modal
			modal-id="attendee-dashboard-course-evaluation-modal"
			modal-title="Course Evaluation"
			header-icon="apple-alt"
			modal-size="lg"
		>

			<div class="modal-body">
                <!-- Errors Div -->
				<div class="row" v-if="formHasError">
					<div class="col">
						<div class="alert alert-danger" v-html="formErrorMessage"></div>
					</div>
				</div>

                <!-- Form -->
				<form class="d-flex flex-column" data-js-id="cm-atn-courses-form" novalidate>
					<attendee-dashboard-course-evaluation-form-input
                        v-for="(question, index) in evaluationQuestions"
                        :key="index"
                        :index="index"
                        :question="question"
                    ></attendee-dashboard-course-evaluation-form-input>

					<div class="modal-footer d-flex justify-content-end mx-n1">
						<button class="btn btn-outline-primary m-1" @click.prevent="closeCourseEvaluationModal()" :disabled="formProcessing">Cancel</button>
						<button class="btn btn-primary m-1" data-js-id="course-evaluation-button" @click.prevent="saveCourseEvaluation()" :disabled="formProcessing">Submit</button>
					</div>
				</form>
			</div>
		</attendee-dashboard-course-evaluation-modal>
    </div>
</template>

<script>
import { logApplicationError } from "../../../../../core/global/functions";
export default {
    props: [
        'attendeeId',
        'attendeeName',
        'favoriteCourses',
        'attendedCourses',
        'isEvaluationExpired',
        'evaluationQuestions',
        'isTranscriptPublished',
        'conferences'
    ],
    data() {
        return {
            formHasError: false,
            formErrorMessage: '',
            formProcessing: false,
            selectedCourseEvaluation: '',
            formId: 'cm-atn-courses-form',
            modalId: 'attendee-dashboard-course-evaluation-modal'
        }
    },
    computed: {
        courseEvaluationModal() {
            return document.querySelector(`[data-js-id="${this.modalId}"]`);
        },
        courseEvaluationForm() {
            return document.querySelector(`[data-js-id="${this.formId}"]`);
        }
    },
    methods: {
        openCourseEvaluationModal(courseId) {
            this.selectedCourseEvaluation = courseId;
            $(this.courseEvaluationModal).modal('show');
        },
        closeCourseEvaluationModal() {
            $(this.courseEvaluationModal).modal('hide');
        },
        saveCourseEvaluation() {
            const vm = this;
            const button = event.target;
            const courseEvalForm = vm.courseEvaluationForm;

            let originalButtonText = event.target.innerHTML;

            $(courseEvalForm).validate({
                errorPlacement: function(error, element) {
                    if (element.is(":radio")) {
                        error.addClass("d-flex");
                        error.addClass('text-danger');
                        error.prependTo(element.parents("fieldset"));
                    } else {
                        error.addClass('text-danger');
                        error.insertAfter(element);
                    }
                }
            });

            if ($(courseEvalForm).valid()) {
                button.disabled = true;
                button.innerHTML = 'Processing...';
                let formData = new FormData(courseEvalForm);
                formData.append('course_id', this.selectedCourseEvaluation);
                formData.append('user_id', this.attendeeId);

                axios.post('/portal/dashboard/attendee/submit-evaluation', formData)
                    .then(res => {
						window.location.reload();
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == '422') {
                                const errors   = error.response.data.errors;
                                const errorMessage = error.response.data.message;

                                vm.formHasError    = true;

                                let errorsUl = document.createElement("ul");
                                errorsUl.classList.add("list-unstyled");

                                for (error in errors) {
                                    errorsUl.innerHTML += `<li>${errors[error]}</li>`;
                                }

                                vm.formErrorMessage = errorMessage + errorsUl.outerHTML;
                            } else {
                                vm.formHasError = true;
                                vm.formErrorMessage = 'An unknown error occured.';

                                logApplicationError(error);
                                console.error(error);
                            }
                        }

                        button.disabled = false;
                        button.innerHTML = originalButtonText;

                        console.error(error);
                    });
            }
        }
    }
}
</script>