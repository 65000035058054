<template>
	<div>
		<div class="row">
			<div class="form-group col-md">
				<label for="title">Title<span class="required">*</span></label>
				<input
					class="form-control"
					type="text"
					name="title"
					id="title"
					title="Title"
					required
					data-msg="Please provide title"
					v-model="selectedCourse.title"
				/>
			</div>
			<div class="col-md form-group has-label">
				<div class="d-flex justify-content-between align-items-center">
					<label for="category">Category</label>
				</div>
				<select
					class="form-control"
					name="categories[]"
					id="category"
					title="Select a category"
					v-model="selectedCourse.category"
				>
					<option value>-- Select a category --</option>
					<option
						v-for="(category, index) in allCategories"
						:key="index"
						:value="category.id"
					>{{ category.name }}</option>
				</select>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-md">
				<label for="description">Description<span class="required">*</span></label>
				<textarea
					class="form-control"
					name="description"
					id="description"
					title="Description"
					rows="5"
					required
					data-msg="Please provide description"
					v-model="selectedCourse.description"
				></textarea>
			</div>
		</div>
		<div class="row">
			<div class="col-md form-group has-label">
				<p class="m-0 lh-xs" v-if="currentCourseIds.length">
					<small>
						<strong>Selected speaker<template v-if="currentCourseIds.length > 1">s</template>: </strong>
						<template v-for="(speakerId, index) in currentCourseIds">
							{{ selectedSpeakers(speakerId, index, currentCourseIds) }}
						</template>
					</small>
				</p>
				<div class="d-flex justify-content-between align-items-center">
					<label for="course_speakers">Speaker(s)</label>
					<a
						href="#"
						data-toggle="tooltip"
						data-placement="right"
						data-boundary="window"
						title="Use Ctrl + Click to select multiple speakers"
					>
						<span class="fas fa-question-circle text-info cursor-pointer"></span>
					</a>
				</div>
				<select
					multiple
					class="form-control flex-grow-1"
					name="speakers[]"
					id="course_speakers"
					title="Select a category"
					v-model="currentCourse.speakerId"
				>
					<option
						v-for="(speaker, index) in speakersByName"
						:key="index"
						:value="speaker.speakerId.id"
					>{{ speaker.name }}</option>
				</select>
			</div>
			<div class="col-md">
				<div class="form-group">
					<div class="d-flex justify-content-between align-items-center">
						<label for="audience_level">Audience Level<span class="required">*</span></label>
						<a
							href="#"
							data-toggle="tooltip"
							data-placement="right"
							data-boundary="window"
							title="Options include Introductory, Intermediate, Advanced"
						>
							<span class="fas fa-question-circle text-info cursor-pointer"></span>
						</a>
					</div>
					<input
						class="form-control"
						type="text"
						name="audience_level"
						id="audience_level"
						title="Audience Level"
						required
						data-msg="Please provide an audience level"
						v-model="selectedCourse.audience_level"
					/>
				</div>
				<div class="form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3 required">Presentation Preference<span class="required">*</span></p>
					</div>
					<fieldset>
						<legend class="label sr-only">Panel</legend>
						<div
							class="form-check form-check-inline form-check-radio"
							v-for="(preference, index) in presentation_preference"
							:key="index"
						>
							<label class="form-check-label mr-1" :for="`presentation_preference_${index}`">
								<input
									class="form-check-input"
									type="radio"
									name="presentation_preference"
									title="Select an option"
									:value="`${preference}`"
									:id="`presentation_preference_${index}`"
									required
									data-msg="Please Select an Option"
									v-model="selectedCourse.presentation_preference"
								/>
								<span class="form-check-sign"></span>
								{{ preference }}
							</label>
						</div>
					</fieldset>
				</div>
				<div class="form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3 required">Panel<span class="required">*</span></p>
					</div>
					<fieldset>
						<legend class="label sr-only">Panel</legend>
						<div
							class="form-check form-check-inline form-check-radio"
						>
							<label class="form-check-label mr-1" for="panel_yes">
								<input
									class="form-check-input"
									type="radio"
									name="panel"
									title="Select an option"
									value="1"
									id="panel_yes"
									required
									data-msg="Please Select an Option"
									v-model="selectedCourse.panel"
								/>
								<span class="form-check-sign"></span>
								Yes
							</label>
							<label class="form-check-label" for="panel_no">
								<input
									class="form-check-input"
									type="radio"
									name="panel"
									title="Select an option"
									value="0"
									id="panel_no"
									required
									data-msg="Please Select an Option"
									v-model="selectedCourse.panel"
								/>
								<span class="form-check-sign"></span>
								No
							</label>
						</div>
					</fieldset>
				</div>
				<div class="form-group">
					<div class="radio-legend d-flex justify-content-between align-items-center">
						<p class="mr-3">Approved<span class="required">*</span></p>
					</div>
					<fieldset>
						<legend class="label sr-only">Approved</legend>
						<div
							class="form-check form-check-inline form-check-radio"
						>
							<label class="form-check-label mr-1" for="approved_yes">
								<input
									class="form-check-input"
									type="radio"
									name="approved"
									title="Select an option"
									value="1"
									id="approved_yes"
									required
									data-msg="Please Approve or Deny"
									v-model="selectedCourse.approved"
								/>
								<span class="form-check-sign"></span>
								Yes
							</label>
							<label class="form-check-label" for="approved_no">
								<input
									class="form-check-input"
									type="radio"
									name="approved"
									title="Select an option"
									value="0"
									id="approved_no"
									required
									data-msg="Please Approve or Deny"
									v-model="selectedCourse.approved"
								/>
								<span class="form-check-sign"></span>
								No
							</label>
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: ["selectedCourse", "allSpeakers", "allCategories"],
		data() {
			return {
				currentCourse: this.selectedCourse,
				presentation_preference: ['In-person', 'Virtual', 'Either']
			}
		},
		computed: {
			speakersByName() {
				return this.allSpeakers.sort((a, b) => a.name.localeCompare(b.name));
			},
			currentCourseIds() {
				return this.currentCourse.speakerId;
			}
		},
		mounted() {
            CKEDITOR.replace('description', {
                removePlugins: 'maximize,resize',
			});
		},
		methods: {
			speakerNameFromId(value) {
				let allSpeakers = this.allSpeakers;

				for (i = 0; i < allSpeakers.length; ++i) {
					if (allSpeakers[i].speakerId.id == value) {
						return allSpeakers[i].name;
						break;
					}
				}
			},
			selectedSpeakers(item, index, parent) {
				return this.speakerNameFromId(item) + ( index !== parent.length - 1 ? ', ' : '' );
			}
		}
	};
</script>