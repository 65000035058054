<script>
import { dataTableMixin } from "../../../../../../../Vue/Core/Vpanel/Mixins/datatableMixin";
import { dtPortalGroupOptions } from "./options//dtPortalGroupOptions";

export default {
    mixins: [dataTableMixin],
    data() {
        return {
            moduleDatatableOptions: dtPortalGroupOptions
        }
    }
}
</script>

<style>

</style>