let passCriteriaForm = document.querySelectorAll('.password-criteria-form');

if (passCriteriaForm) {

    for (let i = 0; i < passCriteriaForm.length; i++) {
        let passwordCriteriaList = passCriteriaForm[i].querySelector('.password-criteria-list');
        let passwordInput        = passCriteriaForm[i].querySelector('input[name="password"]');
        let confirmPasswordInput = passCriteriaForm[i].querySelector('input[name="password_confirmation"]');
        let submitBtn       = passCriteriaForm[i].querySelector('[type="submit"]');

        passwordInput.addEventListener('keyup', evt => {
            let value = passwordInput.value;
            let uppercaseCheck  = /[A-Z]/;
            let uppercaseLi     = passCriteriaForm[i].querySelector('.uppercase');

            let lowercaseCheck  = /[a-z]/;
            let lowercaseLi     = passCriteriaForm[i].querySelector('.lowercase');

            let numericCheck    = /\d/;
            let numericLi       = passCriteriaForm[i].querySelector('.number');

            let specialCheck    = /[!@#$%^&*()+]/;
            let specialLi       = passCriteriaForm[i].querySelector('.special-char');

            let charLengthLi    = passCriteriaForm[i].querySelector('.character-length');


            // Check to see if password has at least one uppercase
            if (uppercaseCheck.test(value)) {
                uppercaseLi.classList.add('met');
                uppercaseLi.classList.remove('un-met');
            } else {
                uppercaseLi.classList.add('un-met');
                uppercaseLi.classList.remove('met');
            }

            // Check to see if password has at least one lowercase
            if (lowercaseCheck.test(value)) {
                lowercaseLi.classList.add('met');
                lowercaseLi.classList.remove('un-met');
            } else {
                lowercaseLi.classList.add('un-met');
                lowercaseLi.classList.remove('met')
            }

            // Check to see if password has at least one digit
            if (numericCheck.test(value)) {
                numericLi.classList.add('met')
                numericLi.classList.remove('un-met')
            } else {
                numericLi.classList.add('un-met')
                numericLi.classList.remove('met')
            }

            // Check to see if password has at least one special character
            if (specialCheck.test(value)) {
                specialLi.classList.remove('un-met')
                specialLi.classList.add('met')
            } else {
                specialLi.classList.add('un-met')
                specialLi.classList.remove('met')
            }

            //Check to make sure that the character length
            if (value.length >= 8) {
                charLengthLi.classList.remove('un-met')
                charLengthLi.classList.add('met')
            } else {
                charLengthLi.classList.add('un-met')
                charLengthLi.classList.remove('met')
            }

            if (!passwordCriteriaList.querySelector('.un-met')) {
                submitBtn.disabled = false;
            } else {
                submitBtn.disabled = true;
            }


        });

        confirmPasswordInput.addEventListener('keyup', evt => {
            let passwordMatchLi = passCriteriaForm[i].querySelector('.passwords-match');

            //Check to see the passwords match
            if (passwordInput.value == confirmPasswordInput.value) {
                passwordMatchLi.classList.remove('un-met')
                passwordMatchLi.classList.add('met')
            } else {
                passwordMatchLi.classList.add('un-met')
                passwordMatchLi.classList.remove('met')
            }

            if (!passwordCriteriaList.querySelector('.un-met')) {
                submitBtn.disabled = false;
            } else {
                submitBtn.disabled = true;
            }
        })
    }
}