export const dtVendorOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        { data: "company.name" },
        {
            data: "username",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "email",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "company.vgm_member_number",
            render: function(data, type, row) {
                return `<p class="m-0 text-truncate" title="${data}">${data}</p>`
            }
        },
        {
            data: "registration.email_sent_at",
            type: "date",
            render: function(data, type, row) {
                return moment(data).format('M/D/YYYY h:mm A');
            }
        },
        {
            data: "created_at",
            type: "date",
            render: function(data, type, row) {
                return data ? moment(data).format('M/D/YYYY h:mm A') : 'N/A';
            }
        },
        {
            data: "id",
            width: "275px",
            orderable: false,
            searchable: false,
            render: function(data, type, row) {
                let html = `<div class="vendor-options d-flex justify-content-end" data-id="${row.id}">`;

                if (row.registration_completed) {
                    let link = row.complete_account_link ? `<a class="btn btn-tertiary" href="${row.complete_account_link}" target="_blank">Complete Account</a>` : '';
                    html += `<button class="btn btn-secondary-blue mr-1" data-js-id="vendor-modify">Modify</button>${link}`;
                    html +=  row.complete_account_link == null ? `&nbsp;&nbsp;<a class="btn btn-warning" href="/vpanel/modules/impersonate/vendor/${row.portal_user_id}" target="_blank">Impersonate</a>` : '';
                } else {
                    let link = row.link_code ? `<a class="btn btn-tertiary mr-1" href="${row.link_code}" target="_blank">Vendor Registration</a>` : '';
                    html += `${link}<button class="btn btn-primary" data-js-id="vendor-cancel-invite">Cancel Invite</button>`;
                }

                html += `</div>`;
                return html;
            }
        }
    ]
}

