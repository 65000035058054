<template>
	<form data-js-id="package-selection-form">
		<div class="card my-2">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Booth Package Options<span class="required">*</span></p>
			</div>
			<div class="card-body">
				<div class="form-group m-0">
					<fieldset>
						<legend class="sr-only">Booth Package Options?</legend>
						<div class="my-n1">
							<div
								class="custom-control custom-radio my-1 d-flex flex-column-reverse"
								v-for="(vendorPackage, index) in appData.eligible_packages"
								:key="index"
							>
								<input
									class="custom-control-input"
									type="radio"
									name="package_selection"
									required
									data-msg="Please select which package you would like"
									:title="vendorPackage.name"
									:value="vendorPackage.id"
									:id="`package_id_${vendorPackage.id}`"
									v-model="appData.registration.selected_package_id"
									@change="appData.updatePackage(vendorPackage)"
								/>
								<label
									class="custom-control-label"
									:for="`package_id_${vendorPackage.id}`"
								>
									<span>{{vendorPackage.name}} - includes {{vendorPackage.number_of_included_badges}} badges and a {{vendorPackage.booth_size}} booth - ${{ vendorPackage.base_package_price }}</span>
									<ul v-if="vendorPackage.details && vendorPackage.details.length" style="font-size: 14px; padding-left: 1.5rem;">
										<li v-for="detail in vendorPackage.details" style="line-height: 1.25;">{{ detail }}</li>
									</ul>
								</label>
							</div>
						</div>
					</fieldset>
				</div>
			</div>
		</div>

		<div class="alert alert-warning">
			Booth attendee badge names can be added at a later date via the Vendor Portal.  All badge names MUST be added no later than {{appData.registration.badge_end_date}}.
		</div>

		<vendor-registration-badge-information
			v-for="(badge, index) in appData.number_of_base_badges"
			:key="index"
			:index="index"
			:badges-array="appData.badges"
			:is-additional="false"
		></vendor-registration-badge-information>

		<div class="card my-2">
			<div class="card-header">
				<p class="card-title font-weight-bold m-0">Additional Booth Attendees</p>
			</div>
			<div class="card-body">
				<vendor-registration-badge-information
					v-for="(badge, index) in appData.additional_badges.length"
					:key="index + 10"
					:index="index"
					:badges-array="appData.additional_badges"
					:is-additional="true"
				></vendor-registration-badge-information>

				<div class="d-flex align-items-center m-n1">
					<p class="m-1">Need more Booth Attendees? Each Booth Attendee is {{ additional_badge_price }}.</p>
					<button
						class="btn btn-outline-primary m-1"
						@click.prevent="appData.addAdditionalBadge()"
					>Add Another Booth Attendee</button>
					<button v-if="appData.additional_badges.length > 0"
						class="btn btn-outline-primary m-1"
						@click.prevent="appData.deleteAdditionalBadge()"
					>Delete Booth Attendee</button>
				</div>
			</div>
		</div>

		<vendor-registration-subtotal></vendor-registration-subtotal>

		<div class="d-flex justify-content-end m-n1">
			<button class="btn btn-outline-primary m-1" @click.prevent="moveToLastStep()">Go Back &amp; Edit</button>
			<button class="btn btn-primary-green m-1" @click.prevent="moveToNextStep()">Continue</button>
		</div>
	</form>
</template>

<script>
export default {
	data() {
		return {
			appData: this.$root.appData
		};
	},
	computed: {
		form() {
			return document.querySelector(
				'[data-js-id="package-selection-form"]'
			);
		},
		additional_badge_price() {
			return `$${parseFloat(this.appData.additional_badge_price).toFixed(
				2
			)}`;
		}
	},
	methods: {
		addAdditionalBadge() {
			this.appData.addition_badges;
		},
		deleteAdditionalBadge() {
			this.appData.addition_badges;
		},
		moveToNextStep() {
			$('html, body').animate({ scrollTop: 0 }, 'fast');
			if ($(this.form).valid()) {
				this.appData.hideAllSections();
				this.appData.sections.show_sponsorship_selection_form = 1;
				this.appData.current_step = 3;
			}
		},
		moveToLastStep() {
			$('html, body').animate({ scrollTop: 0 }, 'fast');

			this.appData.hideAllSections();
			this.appData.sections.show_contact_info_form = 1;
			this.appData.current_step = 1;
		}
	}
};
</script>