<template>
    <div class="card d-inline-flex" style="flex-basis: 200px;">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">Company Information</p>
        </div>
        <div class="card-body">
            <div class="d-flex flex-column text-14">
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    Name
                    <span class="ml-2 text-primary font-weight-bold">{{ appData.company.name }}</span>
                </p>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    Address
                    <span class="ml-2 text-primary font-weight-bold">{{ appData.company.address_1 }}</span>
                </p>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    Address 2
                    <span class="ml-2 text-primary font-weight-bold">{{ appData.company.address_2 }}</span>
                </p>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    City
                    <span class="ml-2 text-primary font-weight-bold">{{ appData.company.city }}</span>
                </p>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    State/Province
                    <span class="ml-2 text-primary font-weight-bold">{{ appData.company.state_province }}</span>
                </p>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    Postal Code
                    <span class="ml-2 text-primary font-weight-bold">{{ appData.company.postal_code }}</span>
                </p>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    Email
                    <span class="ml-2 text-primary font-weight-bold">{{ appData.registration.email }}</span>
                </p>
                <p class="m-0 d-flex justify-content-between flex-wrap">
                    VGM Member Number
                    <span class="ml-2 text-primary font-weight-bold">{{ appData.registration.vgm_member_number }}</span>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            appData: this.$root.appData
        };
    }
};
</script>