<template>
  <div class="card my-2">
    <arp-toggle-bar
      :toggle-bar-text="`Attendee #${attendeeIndex + 1} Registration`"
      @click.native="isToggled = !isToggled"
      :is-toggled="isToggled"
    ></arp-toggle-bar>

    <template v-if="isToggled">
      <div class="card-body">
        <div class="d-flex justify-content-between align-items-start my-1">
          <p class="m-0">Name:</p>
          <p class="m-0">{{ attendeeFullName }}</p>
        </div>
  
        <div
          class="d-flex justify-content-between align-items-start my-1"
          v-if="reservation.attendee_information.title"
        >
          <p class="m-0">Title:</p>
          <p class="m-0">{{ reservation.attendee_information.title }}</p>
        </div>
  
        <div class="d-flex justify-content-between align-items-start my-1">
          <p class="m-0">Address:</p>
          <p class="text-right m-0" v-html="attendeeAddress"></p>
        </div>
  
        <div
          class="d-flex justify-content-between align-items-start my-1"
          v-if="reservation.attendee_information.date_of_birth"
        >
          <p class="m-0">Date of Birth:</p>
          <p class="m-0">{{ reservation.attendee_information.date_of_birth }}</p>
        </div>
  
        <div
          class="d-flex justify-content-between align-items-start my-1"
          v-if="reservation.attendee_information.is_vgm_member"
        >
          <p class="m-0">VGM Number:</p>
          <p class="m-0">{{ reservation.attendee_information.vgm_member_number }}</p>
        </div>
      </div>
  
      <div class="card-body border-top">
        <div class="d-flex justify-content-between align-items-start">
          <p class="m-0">Conference:</p>
          <p class="m-0">${{ getReservationIndexSubTotal(attendeeIndex).toFixed(2) }}</p>
        </div>
      </div>
  
      <div class="card-body border-top">
        <template
          v-for="(option, index) in activeReservation.reservations[attendeeIndex].reservation_options"
        >
          <div class="d-flex justify-content-between flex-wrap my-1 mx-n1" v-if="option.item_name">
            <p class="my-0 mx-1">{{ option.item_name }}</p>
            <p class="my-0 mx-1">{{ option.option_name }}</p>
          </div>
        </template>
  
        <template
          v-if="activeReservation.reservations[attendeeIndex].reservation_options.additional_options"
        >
          <div
            class="d-flex justify-content-between flex-wrap mx-n1"
            v-for="(option, index) in activeReservation.reservations[attendeeIndex].reservation_options.additional_options"
            :key="index"
          >
            <p class="my-0 mx-1">{{ option.item_name }}</p>
            <p class="my-0 mx-1">{{ option.option_name }}</p>
          </div>
        </template>
      </div>
      
      <template v-if="optionalOfferings.length">
        <div
          class="card-body border-top"
          v-for="(offering, offeringIndex) in optionalOfferings"
          :key="offeringIndex"
        >
          <p class="m-0">{{ offering.name }}</p>
          <template v-for="input in offering.inputs">
            <small class="d-flex justify-content-between mx-n1">
              <span class="my-0 mx-1">{{ input.name }} (${{ parseFloat(input.price_per_unit).toFixed(2) }}){{ input.quantity > 1 ? ` x${input.quantity}` : '' }}</span>
              <span class="my-0 mx-1 text-right">${{ (input.quantity * parseFloat(input.price_per_unit)).toFixed(2) }}</span>
            </small>
            <small class="d-flex justify-content-between mx-n1" v-if="input.additional_input_label && input.additional_input_value.length">
              <span class="my-0 mx-1 flex-shrink-0">{{ input.additional_input_label }}</span>
              <span class="my-0 mx-1 text-right">{{ input.additional_input_value.join(', ') }}</span>
            </small>
          </template>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  props: ["reservation", "attendeeIndex"],
  data() {
    return {
      isToggled: false,
    };
  },

  computed: {
    ...mapState("registration", {
      activeReservation: (state) => state.activeReservation,
      showPlatinumRegistrationOptions: (state) => state.activeReservation.company.is_platinum_vgm_member,
    }),
    ...mapGetters("registration", [
      'getReservationIndexSubTotal'
    ]),
    attendeeFullName() {
      let {first_name, last_name} = this.reservation.attendee_information
      return [first_name, last_name].filter(v => v).join(" ") || "Unset"
    },
    attendeeAddress() {
      let address = this.reservation.attendee_information.address
      if (!address) return "Unset"

      let {address_1, address_2, city, state_province, postal_code} = address
      return [
        [
          address_1,
          address_2
        ].filter(v => v).join(', '),
        [
          [
            city,
            state_province
          ].filter(v => v).join(" "),
          postal_code
        ].filter(v => v).join(', ')
      ].filter(v => v).join('<br>')
    },
    optionalOfferings() {
      return this.activeReservation.reservations[this.attendeeIndex].optional_offerings.filter(offering => offering.selected)
    }
  },
};
</script>

<style></style>
