export const dtPortalGroupOptions = {
    scrollY: '0',
    scrollCollapse: 'true',
    order: [[3, "desc"]],
    dom:    "<'dt-header d-flex justify-content-between'<'dt-search'f><'dt-length'l>>"+
            "<'dt-body'tr>"+
            "<'dt-footer d-flex justify-content-between'<'dt-info'i><'dt-pages'p>>",
    columns: [
        { data: 'name' },
        {
            data: 'parent_groups',
            render: function (data, type, row) {
                let sort = data.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'})).join(', ');
                return `<p class="m-0 text-truncate" title="${sort}">${sort}</p>`
            }
        },
        {
            data: "created_at",
            render: function (data, type, row) {
                return moment(data.created_at).format('M/D/YYYY h:mm A');
            }
        },
        {
            data: 'users',
            render: function (data, type, row) {
                let sort = data.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'})).join(', ');
                return `<p class="m-0 text-truncate" title="${sort}">${sort}</p>`
            }
        },
        {
            data: "pages",
            render: function (data, type, row) {
                let sort = data.sort((a, b) => a.localeCompare(b, undefined, {sensitivity: 'base'})).join(', ');
                return `<p class="m-0 text-truncate" title="${sort}">${sort}</p>`
            }
        },
        // Options
        {
            data: 'options',
            width: '15%',
            render: function (data, type, row) {
                return `<div class="portal-groups-options">
                                <button class="portal-groups-recycle btn btn-outline-danger" data-id="${row.id}" data-js-id="portal-groups-recycle-button">Recycle</button>
                                <button class="portal-groups-modify btn btn-primary" data-id="${row.id}" data-js-id="portal-groups-modify-button">Modify</button>
                            </div>`
            }
        }
    ]
};