<template>
	<div class="card my-2" v-if="sessionStatus == 'Active'">
		<portal-dashboard-toggle-bar
			:toggle-bar-text="toggleBarText"
			@click.native="toggleSessionData()"
			:is-toggled="showSessionData"
		></portal-dashboard-toggle-bar>

		<div class="card-body d-flex justify-content-between align-items-start" v-if="showSessionData">
            <div class="my-n2">
                <template v-if="sessionStatus == 'Active'">
                    <div v-if="course.has_evals && isCourseEvaluationsPublished" class="d-flex justify-content-end align-items-end pt-2">
                        <button class="btn btn-outline-primary" @click.prevent="getCourseExport(course.course_id)">Export Course Evaluations</button>
                    </div>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Session Status</small>
                        {{ sessionStatus }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Presentation Title</small>
                        {{ course.title }}
                    </p>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Presentation Description</small>
                        {{ course.description }}
                    </p>
                    <div class="m-0 d-inline-flex flex-column lh-sm">
                        <p class="text-uppercase text-primary-green m-0"><small class="font-weight-bold">Learning Objectives ({{ sessionObjectives.length }})</small></p>
                        <p class="m-0 d-flex justify-content-between align-items-center" v-for="(objective, index) in sessionObjectives" :key="index">{{ objective }}</p>
                    </div>
                    <p class="my-2 d-flex flex-column lh-sm">
                        <small class="text-uppercase text-primary-green font-weight-bold">Audience level of understanding</small>
                        {{ course.audience_level }}
                    </p>
                    <div class="m-0 d-inline-flex flex-column lh-sm">
                        <p class="text-uppercase text-primary-green m-0"><small class="font-weight-bold">Categories ({{ course.categories.length }})</small></p>
                        <p class="m-0 d-flex justify-content-between align-items-center" v-for="(category, index) in course.categories" :key="index">{{ category.name }}</p>
                    </div>
                    <div class="my-2 d-flex flex-column lh-sm">
                        <p class="text-uppercase text-primary-green m-0 cursor-pointer" @click.prevent="openHandoutUploadModal(course.id)"><small class="font-weight-bold">Course Materials <span class="fas fa-upload ml-1"></span></small></p>
                        <div class="m-0 d-flex justify-content-start align-items-center" v-for="(handout, index) in course.handouts" :key="index" v-if="isHandoutRecycled(handout)">
                            <a
                                :href="'/storage/shares/' + handout.handout_path"
                                :title="'Internal link in a new tab to view ' + handout.display_name"
                                target="_blank"
                            >
                                <span class="fas fa-file mr-1"></span>{{ handout.display_name }}
                            </a>
                            <p class="m-0" @click.prevent="deleteHandout(handout.id)"><span class="fas fa-times-circle cursor-pointer ml-2"></span></p>
                        </div>
                    </div>
                </template>
                <div class="alert alert-secondary mt-2" v-html="statusMessage"></div>
            </div>
		</div>
	</div>
</template>

<script>
    import { logApplicationError } from "../../../../../../core/global/functions";
	export default {
        props: [
            'course',
            'index',
            'deleteHandout',
            'openHandoutUploadModal',
            'isCourseEvaluationsPublished'
        ],
        data() {
            return {
                showSessionData: false,
            }
        },
        computed: {
            sessionStatus() {
                if (this.course.approved) {
                    return 'Active';
                } else {
                    return 'Denied';
                }
            },
			courseId() {
				return this.course.id;
			},
            toggleBarText() {
                return this.sessionStatus + ` - ${this.sessionTitle} - ${this.sessionTime}`
            },
            sessionTitle() {
                return this.course.agenda.length ? this.course.agenda[0].title : this.course.title;
            },
            sessionTime() {
                return this.course.agenda.length ? this.course.agenda[0].start_date_time : 'N/A';
            },
            sessionObjectives() {
                return [this.course.objective_1, this.course.objective_2, this.course.objective_3, this.course.objective_4];
            },
            statusMessage() {
                switch (this.sessionStatus) {
                    case 'Active':
                        return `<p class="m-0">If you need to make adjustments to this presentation, please contact us at 855-755-6922.</p>`;
                        break;
                    case 'Pending':
                        return `<p class="m-0">This presentation is pending and needs your attention. Please contact us at 855-755-6922 for more information.</p>`;
                        break;
                    case 'Denied':
                        return `<p class="m-0">This presentation has been declined. Please contact us at 855-755-6922 for more information.</p>`;
                        break;
                    default:
                        break;
                }
            }
        },
        methods: {
            toggleSessionData() {
                this.showSessionData = !this.showSessionData;
            },
            isHandoutRecycled(handout) {
                return handout.recycled == 0 ? handout : '';
            },
            getCourseExport(courseId) {
                const vm = this;
                const thisEvent = event;
                const attendeeId = vm.attendeeId;

                let button = thisEvent.target;

                button.innerText = 'Processing...';
                button.disabled = true;

                axios({url: `/portal/dashboard/speaker/export-course-evaluation/${courseId}`, method: "GET", responseType: "blob"})
					.then(response => {
                        let blob = new Blob([response.data]);
                        let date = moment().format('L');

						// For IE
						if (typeof window.navigator.msSaveBlob !== "undefined") {
							window.navigator.msSaveBlob(blob, filename);
						} else {
                            // Creates a link with a url to the created blob, clicks that link, then removes it from the page
							const url  = window.URL.createObjectURL(blob);
							const link = document.createElement("a");
							link.href  = url;
							link.setAttribute("download", `${date}-${courseId}-course-evaluations.xlsx`);
							document.body.appendChild(link);
							link.click();

							link.remove();
						}

						button.innerText = "Export Course Evaluations";
						button.disabled = false;
					})
					.catch(error => {
						logApplicationError(error);
						console.error(error);
						button.innerText = "Export Course Evaluations";
						button.disabled = false;
					});
            }
        }
    };
</script>
