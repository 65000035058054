<template>
    <div class="mt-6">
        <h4 class="text-uppercase border-bottom-4 border-primary-yellow pb-1"><em>My Sessions</em></h4>

        <speaker-dashboard-session-card
            v-for="(course, index) in user.courses" :key="index"
            :course="course"
            :index="index"
            :open-handout-upload-modal="openHandoutUploadModal"
            :delete-handout="deleteHandout"
            :is-course-evaluations-published="isCourseEvaluationsPublished"
        ></speaker-dashboard-session-card>

        <speaker-dashboard-course-handout-upload-modal
            :modal-id="courseHandoutUploadModalId"
            modal-title="Upload Handout"
            header-icon="chalkboard-teacher"
            modal-size="lg"
        >
            <speaker-dashboard-course-handouts-upload-form :close-modal="closeHandoutUploadModal" :course-id="currentCourseId"></speaker-dashboard-course-handouts-upload-form>
        </speaker-dashboard-course-handout-upload-modal>
    </div>
</template>

<script>
export default {
    data() {
        return {
            courseHandoutUploadModalId: "course-handouts-upload-modal",
            currentCourseId: '',
            courseHandoutUploadFormId: 'handout-form'
        }
    },
    props: [
        'user',
        'isCourseEvaluationsPublished'
    ],
    methods: {
        openHandoutUploadModal(courseId) {
            $(`[data-js-id="${this.courseHandoutUploadModalId}"]`).modal("show");
            this.currentCourseId = courseId;
        },
        closeHandoutUploadModal() {
            $(`[data-js-id="${this.courseHandoutUploadModalId}"]`).modal("hide");
            $(`[data-js-id="${this.courseHandoutUploadFormId}"]`).reset();
        },
        deleteHandout(handoutId) {
            axios.post(`/portal/dashboard/speaker/recycle-course-handout/${handoutId}`)
                .then(res => {
                    try {
                        window.location.reload();
                    } catch(error) {
                        logApplicationError(error);
                        console.error(error);
                    }
                })
                .catch(error => {
                    logApplicationError(error);
                    console.error(error);
                });
        }
    }
}
</script>