<template>
    <VueModal :visible="isModalOpen" @on-close="$emit('close-sponsor-delete-modal')" id="sponsor-delete-modal">
        <template #header>
            <h5 class="modal-title"><span class="fas fa-trash mr-1 text-tertiary"></span>Confirm Delete Sponsor</h5>
        </template>

        <div class="d-flex flex-column align-items-center">
            <span class="fas fa-exclamation-triangle text-danger fa-7x"></span>
            <p class="h4 font-weight-bold">Are you sure you want to delete this?</p>
            <p class="text-center m-0">This action is permanent. You will not be able to restore this sponsor.</p>
            <p class="text-center">Consider marking as inactive instead.</p>
        </div>

        <template #footer>
            <div class="d-flex justify-content-center">
                <button type="button" class="btn btn-outline-primary cancel-btn" @click="$emit('close-sponsor-delete-modal')">Cancel</button>
                <button type="button" class="btn btn-primary confirm-btn ml-2" @click.prevent="confirmSponsorDelete" :disabled="isModalProcessing">{{ isModalProcessing ? 'Processing...' : 'Confirm' }}</button>
            </div>
        </template>
    </VueModal>
</template>
<script>
    export default {
        name: 'CmSponsorConfirmDeleteModal',
        props: [
            "isModalOpen",
            "currentSponsor"
        ],
		data() {
			return {
                isModalProcessing: false,
			};
		},
        methods: {
            confirmSponsorDelete() {
                this.isModalProcessing = true;

                axios.post(`/vpanel/modules/conference/sponsors/recycle/${this.currentSponsor.id}`)
                    .then(res => {
                        const data = res.data;
                        this.isModalProcessing = false;

                        showNotification(data.message, data.result);

                        $(document.getElementById('sponsor-datatable')).DataTable().ajax.reload();

                        this.$emit('close-sponsor-delete-modal');
                    })
                    .catch(error => {
                        this.isModalProcessing = false;
                        openApplicationErrorModal(error);
                        console.error(error);
                    });
            },
        }
    }
</script>