<template>
    <div class="vp-tab-nav d-flex">
        <a
			class="nav-link"
			title="Opens tab to view all documents"
			href="#"
			:class="{'current': checkIfTabIsActive('documents')}"
			@click.prevent="changeActiveTab('documents')"
		>Documents</a>

		<a
			class="nav-link"
			title="Opens tab to view all documents categories"
			href="#"
			:class="{'current': checkIfTabIsActive('categories')}"
			@click.prevent="changeActiveTab('categories')"
		>Categories</a>
    </div>
</template>
<script>
    export default {
        props: [ "appStore" ],
        data(){
            return {
                store: this.appStore
            }
        },
        methods: {
            checkIfTabIsActive(tab){
                return this.store.currentTab == tab;
            },
            changeActiveTab(tab){
                return this.store.currentTab = tab;
            }
        }
    }
</script>