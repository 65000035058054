<template>
    <div class="card my-2">
        <div class="card-header">
            <p class="card-title font-weight-bold m-0">{{ additionalText }}Booth Attendee #{{ index + 1 }} Information</p>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="form-group col-md mb-0">
                    <label :for="`badge_${index}_first_name`">First Name<span v-if="index == 0" class="required">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :required="index == 0"
                        :name="`badge_${index}_first_name`"
                        :id="`badge_${index}_first_name`"
                        title="First Name"
                        v-model="currentBadgesArray.first_name"
                    />
                </div>
                <div class="form-group col-md mb-0">
                    <label :for="`badge_${index}_last_name`">Last Name<span v-if="index == 0" class="required">*</span></label>
                    <input
                        class="form-control"
                        type="text"
                        :required="index == 0"
                        :name="`badge_${index}_last_name`"
                        :id="`badge_${index}_last_name`"
                        title="Last Name"
                        v-model="currentBadgesArray.last_name"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["index", "badgesArray", "isAdditional"],
    data() {
        return {
            appData: this.$root.appData
        };
    },
    computed: {
        currentBadgesArray() {
            return this.badgesArray[this.index];
        },
        additionalText() {
            return this.isAdditional ? "Additional " : "";
        }
    }
};
</script>